export const GET_USER_REQUEST = 'GET_USER_REQUEST';
export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
export const GET_USER_FAILURE = 'GET_USER_FAILURE';
export const GET_USER_INTG_REQUEST = 'GET_USER_INTG_REQUEST';
export const GET_USER_INTG_SUCCESS = 'GET_USER_INTG_SUCCESS';
export const GET_USER_INTG_FAILURE = 'GET_USER_INTG_FAILURE';
export const POST_USER_REQUEST = 'POST_USER_REQUEST';
export const POST_USER_SUCCESS = 'POST_USER_SUCCESS';
export const POST_USER_FAILURE = 'POST_USER_FAILURE';
export const PATCH_USER_REQUEST = 'PATCH_USER_REQUEST';
export const PATCH_USER_SUCCESS = 'PATCH_USER_SUCCESS';
export const PATCH_USER_FAILURE = 'PATCH_USER_FAILURE';
export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';
export const PATCH_USER_INTG_REQUEST = 'PATCH_USER_INTG_REQUEST';
export const PATCH_USER_INTG_SUCCESS = 'PATCH_USER_INTG_SUCCESS';
export const PATCH_USER_INTG_FAILURE = 'PATCH_USER_INTG_FAILURE';
export const GET_USER_CSV_REQUEST = 'GET_USER_CSV_REQUEST';
export const GET_USER_CSV_SUCCESS = 'GET_USER_CSV_SUCCESS';
export const GET_USER_CSV_FAILURE = 'GET_USER_CSV_FAILURE';
export const POST_NA_SET_PASSWORD_REQUEST = 'POST_NA_SET_PASSWORD_REQUEST';
export const POST_NA_SET_PASSWORD_SUCCESS = 'POST_NA_SET_PASSWORD_SUCCESS';
export const POST_NA_SET_PASSWORD_FAILURE = 'POST_NA_SET_PASSWORD_FAILURE';