/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.jsx';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import PostAddRounded from '@mui/icons-material/PostAddRounded';
import { Link } from 'react-router-dom';

const CommNameComp = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogButton, setDialogButton] = useState('');
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [dialogType, setDialogType] = useState('add');
  const [data, setData] = useState([]);
  const [message, setMessage] = useState('');
  const [id, setID] = useState('');
  const [title, setTitle] = useState('');
  const [alias, setAlias] = useState('');
  const [taskID, setTaskID] = useState('');
  const [numMembers, setNumMembers] = useState('');
  const [hasChair, setHasChair] = useState(false);
  const [type, setType] = useState('');

  const { getCommNames, getNormalTasks, getPhases } = props;

  useEffect(() => {
    getCommNames();
    getNormalTasks();
    getPhases();
  }, [getCommNames, getNormalTasks, getPhases]);

  const handleEditClick = (e) => {
    setID(e[0]);
    setTitle(e[1]);
    setNumMembers(e[2]);
    setHasChair(e[3]);
    setTaskID(e[4]);
    setType(e[5]);
    setAlias(e[6]);
    setMessage('');
    setDialogTitle('Edit');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('update');
    setDialogOpen(true);
  };

  const handleDeleteClick = (e) => {
    setID(e[0]);
    setTitle(e[1]);
    setNumMembers(e[2]);
    setHasChair(e[3]);
    setTaskID(e[4]);
    setType(e[5]);
    setAlias(e[6]);
    setMessage('');
    setDialogTitle('Delete');
    setDialogButton('Confirm Delete');
    setFieldDisabled(true);
    setDialogType('delete');
    setDialogOpen(true);
  };

  const handleNewClick = (e) => {
    setTitle('');
    setMessage('');
    setNumMembers('');
    setHasChair(false);
    setTaskID('');
    setType('');
    setAlias('');
    setDialogTitle('Add New');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('add');
    setDialogOpen(true);
  };
  useEffect(() => {
    setMessage(props.message);
    if (props.refreshCommName) {
      props.getCommNames();
      setDialogOpen(false);
    }
    var rowItems = [];
    for (let i = 0; i < props.commName_list.length; i++) {
      var colItems = [];
      var link = `/admin/comm-name-perm/${props.commName_list[i].id}`;
      colItems.push(i + 1);
      colItems.push(props.commName_list[i].title);
      colItems.push(props.commName_list[i].alias);
      colItems.push(props.commName_list[i].type === 1 ? 'Adhoc Committee' : 'Permanent Committee');
      colItems.push(props.commName_list[i].num_members);
      colItems.push(props.commName_list[i].has_chair ? 'Yes' : 'No');
      var phaseObj = props.commName_phase_list.find((o) => o.id === props.commName_list[i].phase_id);
      phaseObj === undefined ? colItems.push('') : colItems.push(phaseObj.title);
      var taskObj = props.commName_task_list.find((o) => o.id === props.commName_list[i].task_id);
      taskObj === undefined ? colItems.push('') : colItems.push(taskObj.title);
      colItems.push(
        <div>
          <IconButton
            onClick={handleEditClick.bind(this,
              [props.commName_list[i].id,
                props.commName_list[i].title,
                props.commName_list[i].num_members,
                props.commName_list[i].has_chair,
                props.commName_list[i].task_id,
                props.commName_list[i].type,
                props.commName_list[i].alias,
              ])}
            title="Edit row"
          >
            {' '}
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={handleDeleteClick.bind(this,
              [props.commName_list[i].id,
                props.commName_list[i].title,
                props.commName_list[i].num_members,
                props.commName_list[i].has_chair,
                props.commName_list[i].task_id,
                props.commName_list[i].type,
                props.commName_list[i].alias,
              ])}
            title="Delete row"
          >
            {' '}
            <DeleteIcon />
          </IconButton>
          {props.commName_list[i].type === 2 ? <IconButton title="Permanent Committee"><Link to={link}><PostAddRounded /></Link></IconButton> : ''}

        </div>,
      );
      rowItems.push(colItems);
    }

    setData(rowItems);
  }, [props]);

  const handleDialogClose = (e) => {
    setDialogOpen(false);
    setMessage('');
  };

  const handleDialogConfirm = (e) => {
    if (dialogType === 'add') {
      props.createCommName({
        title,
        task_id: taskID.toString(),
        num_members: numMembers.toString(),
        has_chair: hasChair ? '1' : '0',
        type: type.toString(),
        alias: alias,
      });
    } else if (dialogType === 'update') {
      props.updateCommName(id, {
        title,
        task_id: taskID.toString(),
        num_members: numMembers.toString(),
        has_chair: hasChair ? '1' : '0',
        type: type.toString(),
        alias: alias,
      });
    } else if (dialogType === 'delete') {
      props.deleteCommName(id);
    }
  };
  const onTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const onAliasChange = (e) => {
    setAlias(e.target.value);
  };
  const onTaskIDChange = (e) => {
    setTaskID(e.target.value);
  };
  const onNumMembersChange = (e) => {
    var message = '';
    var letters = /^[0-9]+$/;
    if (!e.target.value.match(letters)) {
      message = 'Invalid Number of Members';
    }
    if (e.target.value < 1) {
      message = 'Number of Members should be greater than 0';
    }
    setNumMembers(e.target.value);
    setMessage(message);
  };
  const onTypeChange = (e) => {
    setType(e.target.value);
  };
  const onHasChairChange = (e) => {
    setHasChair(e.target.checked);
  };

  const { classes } = props;
  // const columns = [
  //   "Serial",
  //   "Title",
  //   "Type",
  //   "Members",
  //   "Has Chairman",
  //   ""
  // ];
  const columns = [
    {
      name: 'Serial',
      label: 'Serial',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Title',
      label: 'Title',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Alias',
      label: 'Alias',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Type',
      label: 'Type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Members',
      label: 'Members',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Has Chairman',
      label: 'Has Chairman',
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'Phase',
      label: 'Phase',
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'Task',
      label: 'Task',
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },

    {
      name: '',
      label: '',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
  ];
  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    filter: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    selectToolbarPlacement: 'none',
  };
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Committee Name</b>
          <p className={classes.cardCategoryWhite}>
            Here is the list of name of committee/council/team which are used in the permanent committees or adhoc committees .
          </p>
        </CardHeader>
        <CardBody>
          <MUIDataTable
            title={(
              <Button
                onClick={handleNewClick.bind(this)}
                color="primary"
                title="Create a new Committee Name"
                round
                disabled={parseInt(localStorage.getItem('workflow_state'), 10) > 0}
              >
                {' '}
                New Committee Name
              </Button>
)}
            data={data}
            columns={columns}
            options={options}
          />
          <Dialog fullWidth open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              <Card>
                <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                  <b>{dialogTitle}</b>
                </CardHeader>
                <CardBody>
                  <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        required
                        fullWidth
                        label="Title"
                        value={title}
                        onChange={onTitleChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        required
                        fullWidth
                        label="Alias"
                        value={alias}
                        onChange={onAliasChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled || parseInt(localStorage.getItem('workflow_state'), 10) > 0}>
                        <InputLabel id="typeLabel">Type</InputLabel>
                        <Select
                          labelId="typeLabel"
                          id="typeSelect"
                          value={type}
                          onChange={onTypeChange.bind(this)}
                        >
                          <MenuItem value={1}>Adhoc Committee</MenuItem>
                          <MenuItem value={2}>Permanent Committee</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        required
                        fullWidth
                        label="Number of Members"
                        value={numMembers}
                        onChange={onNumMembersChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <InputLabel id="hasChairLabel" shrink>Has Chairman</InputLabel>
                      <Grid item component="label" container alignItems="center" spacing={1}>
                        <Grid item>No</Grid>
                        <Grid item>
                          <Switch
                            labelid="hasChairLabel"
                            checked={hasChair}
                            onChange={onHasChairChange.bind(this)}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled={fieldDisabled}
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                            }}
                          />
                        </Grid>
                        <Grid item>Yes</Grid>
                      </Grid>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled}>
                        <InputLabel id="taskLabel">Task</InputLabel>
                        <Select
                          labelId="taskLabel"
                          id="taskSelect"
                          value={taskID}
                          onChange={onTaskIDChange.bind(this)}
                        >
                          {props.commName_task_list.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {props.commName_task_list.find((p) => p.id === item.id).title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <br />
                </CardBody>
                <CardFooter style={{ textAlign: 'left' }} />
              </Card>
            </DialogContent>
            <br />
            <DialogActions>
              <Button onClick={handleDialogClose.bind(this)} color="primary" round>
                Cancel
              </Button>
              <Button
                onClick={handleDialogConfirm.bind(this)}
                color="primary"
                round
                disabled={message === '' && dialogButton !== 'Save' && parseInt(localStorage.getItem('workflow_state'), 10) > 0}
              >
                {dialogButton}
              </Button>
            </DialogActions>
          </Dialog>
        </CardBody>
      </Card>
    </div>
  );
};
export default withStyles(extendedFormsStyle)(CommNameComp);
