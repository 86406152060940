import API from 'Api/api.js';
import { commonBackendCall, getAdminConfig } from 'Api/common.js';
import {
  GET_WORKFLOW_ATTR_REQUEST,
  GET_WORKFLOW_ATTR_SUCCESS,
  GET_WORKFLOW_ATTR_FAILURE,
  POST_WORKFLOW_ATTR_REQUEST,
  POST_WORKFLOW_ATTR_SUCCESS,
  POST_WORKFLOW_ATTR_FAILURE,
  PATCH_WORKFLOW_ATTR_REQUEST,
  PATCH_WORKFLOW_ATTR_SUCCESS,
  PATCH_WORKFLOW_ATTR_FAILURE,
  DELETE_WORKFLOW_ATTR_REQUEST,
  DELETE_WORKFLOW_ATTR_SUCCESS,
  DELETE_WORKFLOW_ATTR_FAILURE,
} from 'constants/admin/WorkflowAttr';

export function getWorkflowAttr() {
  return commonBackendCall(
    GET_WORKFLOW_ATTR_REQUEST,
    GET_WORKFLOW_ATTR_SUCCESS,
    GET_WORKFLOW_ATTR_FAILURE,
    API.get('admin/workflow-attrs', getAdminConfig()),
  );
}
export function createWorkflowAttr(obj) {
  return commonBackendCall(
    POST_WORKFLOW_ATTR_REQUEST,
    POST_WORKFLOW_ATTR_SUCCESS,
    POST_WORKFLOW_ATTR_FAILURE,
    API.post('admin/workflow-attrs', obj, getAdminConfig()),
  );
}
export function updateWorkflowAttr(workflowAttrID, obj) {
  return commonBackendCall(
    PATCH_WORKFLOW_ATTR_REQUEST,
    PATCH_WORKFLOW_ATTR_SUCCESS,
    PATCH_WORKFLOW_ATTR_FAILURE,
    API.patch(`admin/workflow-attrs/${workflowAttrID}`, obj, getAdminConfig()),
  );
}
export function deleteWorkflowAttr(workflowAttrID) {
  return commonBackendCall(
    DELETE_WORKFLOW_ATTR_REQUEST,
    DELETE_WORKFLOW_ATTR_SUCCESS,
    DELETE_WORKFLOW_ATTR_FAILURE,
    API.delete(`admin/workflow-attrs/${workflowAttrID}`, getAdminConfig()),
  );
}
