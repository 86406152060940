import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import * as formCategoryActions from 'actions/admin/FormCategoryActions';
import * as defaultActions from 'actions/admin/DefaultActions';
import FormCategoryComp from './FormCategory/FormCategoryComp';

const FormCategory = (props) => {
  const { classes } = props;
  const { formCategory, defaultStates } = props;
  const {
    getFormCategories,
    createFormCategory,
    updateFormCategory,
    deleteFormCategory,
  } = props.formCategoryActions;
  const { resetMessage } = props.defaultActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <FormCategoryComp
        getFormCategories={getFormCategories}
        resetMessage={resetMessage}
        formCategory_list={formCategory.formCategory_list.list === undefined ? [] : formCategory.formCategory_list.list}
        createFormCategory={createFormCategory}
        updateFormCategory={updateFormCategory}
        deleteFormCategory={deleteFormCategory}
        refreshFormCategory={formCategory.refreshFormCategory}
        requesting={formCategory.requesting}
        message={formCategory.message}
        error_core={formCategory.error_core}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    formCategory: state.adFormCategory,
    defaultStates: state.adDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    formCategoryActions: bindActionCreators(formCategoryActions, dispatch),
    defaultActions: bindActionCreators(defaultActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormCategory);
