import {
  GET_ROLESREPLACEMENT_REQUEST,
  GET_ROLESREPLACEMENT_SUCCESS,
  GET_ROLESREPLACEMENT_FAILURE,
  POST_ROLESREPLACEMENT_REQUEST,
  POST_ROLESREPLACEMENT_SUCCESS,
  POST_ROLESREPLACEMENT_FAILURE,
  PATCH_ROLESREPLACEMENT_REQUEST,
  PATCH_ROLESREPLACEMENT_SUCCESS,
  PATCH_ROLESREPLACEMENT_FAILURE,
  DELETE_ROLESREPLACEMENT_REQUEST,
  DELETE_ROLESREPLACEMENT_SUCCESS,
  DELETE_ROLESREPLACEMENT_FAILURE,
} from '../constants/admin/RolesReplacement';

const initialState = {
  requesting: false,
  message: '',
  rolesReplacement_list: '',
  refreshRolesReplacement: false,
};

export default function rolesReplacement(state = initialState, action) {
  switch (action.type) {
    case GET_ROLESREPLACEMENT_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshRolesReplacement: false,
        message: '',
      };

    case GET_ROLESREPLACEMENT_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        rolesReplacement_list: action.payload.data,
      };

    case GET_ROLESREPLACEMENT_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case POST_ROLESREPLACEMENT_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshRolesReplacement: false,
      };

    case POST_ROLESREPLACEMENT_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshRolesReplacement: true,
      };

    case POST_ROLESREPLACEMENT_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case PATCH_ROLESREPLACEMENT_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshRolesReplacement: false,
      };

    case PATCH_ROLESREPLACEMENT_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshRolesReplacement: true,
      };

    case PATCH_ROLESREPLACEMENT_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case DELETE_ROLESREPLACEMENT_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshRolesReplacement: false,
      };

    case DELETE_ROLESREPLACEMENT_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshRolesReplacement: true,
      };

    case DELETE_ROLESREPLACEMENT_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
