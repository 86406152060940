import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import * as taskActions from 'actions/admin/TaskActions';
import * as actionActions from 'actions/admin/ActionActions';
import * as rolesActions from 'actions/admin/RolesActions';
import * as phasesActions from 'actions/admin/PhasesActions';
import * as defaultActions from 'actions/admin/DefaultActions';
import TaskComp from './Task/TaskComp';

const Tasks = (props) => {
  const { classes } = props;
  const {
    task, role, phase, action, defaultStates,
  } = props;
  const {
    getTasks,
    createTask,
    updateTask,
    deleteTask,
  } = props.taskActions;
  const { resetMessage } = props.defaultActions;
  const { getRoles } = props.rolesActions;
  const { getPhases } = props.phasesActions;
  const { getActions } = props.actionActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <TaskComp
        getTasks={getTasks}
        getActions={getActions}
        getRoles={getRoles}
        resetMessage={resetMessage}
        getPhases={getPhases}
        task_list={task.task_list.list === undefined ? [] : task.task_list.list}
        role_list={role.role_list.list === undefined ? [] : role.role_list.list}
        phase_list={phase.phase_list.list === undefined ? [] : phase.phase_list.list}
        action_list={action.action_list.list === undefined ? [] : action.action_list.list.filter((obj) => ((obj.type === 'Return' || obj.type === 'Close' || obj.type === 'Forward') && obj.require_complete_task === false && obj.att_action_id === 0 && obj.rule === '[]'))}
        createTask={createTask}
        updateTask={updateTask}
        deleteTask={deleteTask}
        refreshTask={task.refreshTask}
        requesting={task.requesting}
        message={task.message}
        error_code={task.error_code}
        // classes={props}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    task: state.adTask,
    action: state.adAction,
    role: state.adRole,
    phase: state.adPhase,
    defaultStates: state.adDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actionActions: bindActionCreators(actionActions, dispatch),
    taskActions: bindActionCreators(taskActions, dispatch),
    rolesActions: bindActionCreators(rolesActions, dispatch),
    phasesActions: bindActionCreators(phasesActions, dispatch),
    defaultActions: bindActionCreators(defaultActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Tasks);
