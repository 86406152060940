import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.jsx';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import extendedFormsStyle from 'assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import Typography from '@mui/material/Typography';

const ActionMsgComp = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogButton, setDialogButton] = useState('');
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [dialogType, setDialogType] = useState('add');
  const [data, setData] = useState([]);
  const [message, setMessage] = useState('');
  const [ccEmail, setCcEmail] = useState('');
  const [ccSender, setCcSender] = useState(false);
  const [id, setID] = useState('');
  const [nextTaskID, setNextTaskID] = useState('');
  const [intgExtAPIID, setIntgExtAPIID] = useState('');
  const [actionMessage, setActionMessage] = useState('');
  const [reminderMessage, setReminderMessage] = useState('');
  const [senderMessage, setSenderMessage] = useState('');
  const [smsMessage, setSMSMessage] = useState('');
  const [jSONMessage, setJSONMessage] = useState('');
  const [variableCopied, setVariableCopied] = useState('');
  const [filteredNextTask, setFilteredNextTask] = useState([]);
  const [variableList, setVariableList] = useState([]);
  const [linkDataActionID, setLinkDataActionID] = useState('');
  const [linkDataOptionID, setLinkDataOptionID] = useState('');
  const [linkDataFormID, setLinkDataFormID] = useState('');
  const [linkDataExActionID, setLinkDataExActionID] = useState('');
  const [visibility, setVisibility] = useState(0);

  const {
    getActionMessageTaskAction, getActionMessageTaskForm, getActionMsgTasks, getActions, getActionActionMessages, getActionMessageVariables, getActionMessageLinkDataList, getActionMessageWFAttr, getIntgExtAPI,
  } = props;
  useEffect(() => {
    getActionMsgTasks(props.action_id);
    getActions();
    getActionActionMessages(props.action_id);
    getActionMessageVariables(props.action_id);
    getActionMessageLinkDataList(props.action_id);
    getActionMessageWFAttr();
    getIntgExtAPI();
    if (props.action_actionmessage_list.task_id !== undefined) {
      getActionMessageTaskForm(props.action_actionmessage_list.task_id)
      getActionMessageTaskAction(props.action_actionmessage_list.task_id)
    }
  }, [props.action_actionmessage_list.task_id, getActionMessageTaskAction, getActionMessageTaskForm, getActionMsgTasks, getActions, getActionActionMessages, getActionMessageVariables, getActionMessageLinkDataList, getActionMessageWFAttr, getIntgExtAPI]);

  const handleEditClick = (e) => {
    var nextTaskListEdit = props.action_msg_task_list.filter((obj) => obj.action_message_id === e[0]);
    var filteredNextTaskTemp = props.action_msg_task_list.filter((obj) => obj.action_message_id === 0);
    var tempFilteredNextTask = [...filteredNextTaskTemp, ...nextTaskListEdit];
    setID(e[0]);
    // setActionID(e[1])
    setNextTaskID(e[2]);
    setActionMessage(e[3]);
    setCcEmail(e[4]);
    setSMSMessage(e[5]);
    setIntgExtAPIID(e[6]);
    setJSONMessage(e[7]);
    setVisibility(e[8]);
    setReminderMessage(e[9]);
    setCcSender(e[10]);
    setSenderMessage(e[11]);
    setMessage('');
    setDialogTitle('Edit');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('update');
    setDialogOpen(true);
    setFilteredNextTask(tempFilteredNextTask);
  };
  const handleDeleteClick = (e) => {
    var nextTaskListEdit = props.action_msg_task_list.filter((obj) => obj.action_message_id === e[0]);
    var filteredNextTaskTemp = props.action_msg_task_list.filter((obj) => obj.action_message_id === 0);
    var tempFilteredNextTask = [...filteredNextTaskTemp, ...nextTaskListEdit];
    setID(e[0]);
    // setActionID(e[1])
    setNextTaskID(e[2]);
    setActionMessage(e[3]);
    setCcEmail(e[4]);
    setSMSMessage(e[5]);
    setIntgExtAPIID(e[6]);
    setJSONMessage(e[7]);
    setVisibility(e[8]);
    setReminderMessage(e[9]);
    setCcSender(e[10]);
    setSenderMessage(e[11]);
    setMessage('');
    setDialogTitle('Delete');
    setDialogButton('Confirm Delete');
    setFieldDisabled(true);
    setDialogType('delete');
    setDialogOpen(true);
    setFilteredNextTask(tempFilteredNextTask);
  };
  const handleNewClick = (e) => {
    var filteredNextTaskTemp = props.action_msg_task_list.filter((obj) => obj.action_message_id === 0);
    // setActionID('')
    setNextTaskID('');
    setActionMessage('');
    setMessage('');
    setCcEmail('');
    setCcSender(false);
    setSMSMessage('');
    setIntgExtAPIID('');
    setJSONMessage('');
    setReminderMessage('');
    setSenderMessage('');
    setVisibility(0);
    setDialogTitle('Add New');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('add');
    setDialogOpen(true);
    setFilteredNextTask(filteredNextTaskTemp);
  };

  useEffect(() => {
    setMessage(props.message);

    if (props.refreshActionMsg) {
      props.getActionActionMessages(props.action_id);
      props.getActionMsgTasks(props.action_id);
      setDialogOpen(false);
    }
    var rowItems = [];
    if (props.actionMsg_list.length > 0 && props.action_msg_task_list.length > 0 && props.action_list.length > 0) {
      for (let i = 0; i < props.actionMsg_list.length; i++) {
        var colItems = [];
        colItems.push(i + 1);
        colItems.push(props.actionMsg_list[i].next_task_title_alias === "" ? props.actionMsg_list[i].next_task_title : props.actionMsg_list[i].next_task_title + " (" + props.actionMsg_list[i].next_task_title_alias + ")");
        colItems.push(props.actionMsg_list[i].message);
        colItems.push(
          <div>
            <IconButton
              onClick={handleEditClick.bind(this,
                [props.actionMsg_list[i].id,
                props.actionMsg_list[i].action_id,
                props.actionMsg_list[i].next_task_id,
                props.actionMsg_list[i].message,
                props.actionMsg_list[i].cc_email,
                props.actionMsg_list[i].sms_message,
                props.actionMsg_list[i].intg_ext_api_id,
                props.actionMsg_list[i].json_message,
                props.actionMsg_list[i].visibility,
                props.actionMsg_list[i].reminder_message,
                props.actionMsg_list[i].cc_sender,
                props.actionMsg_list[i].sender_message,
                ])}
              title="Edit row"
            >
              {' '}
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={handleDeleteClick.bind(this,
                [props.actionMsg_list[i].id,
                props.actionMsg_list[i].action_id,
                props.actionMsg_list[i].next_task_id,
                props.actionMsg_list[i].message,
                props.actionMsg_list[i].cc_email,
                props.actionMsg_list[i].sms_message,
                props.actionMsg_list[i].intg_ext_api_id,
                props.actionMsg_list[i].json_message,
                props.actionMsg_list[i].visibility,
                props.actionMsg_list[i].reminder_message,
                props.actionMsg_list[i].cc_sender,
                props.actionMsg_list[i].sender_message,
                ])}
              title="Delete row"
            >
              {' '}
              <DeleteIcon />
            </IconButton>
          </div>,
        );
        rowItems.push(colItems);
      }
    }


    var filteredNextTaskTemp = props.action_msg_task_list.filter((obj) => obj.action_message_id === 0);
    setData(rowItems);
    setFilteredNextTask(filteredNextTaskTemp);

    var tempVariableList = [];
    props.actionMsgVar_list.forEach((element) => {
      tempVariableList.push(element.name);
    });
    props.actionMsgWFAttr_list.forEach((element) => {
      tempVariableList.push(element.attribute);
    });

    tempVariableList.push('link_for_external_user');
    tempVariableList.push('applicant_name');
    tempVariableList.push('application_name');
    tempVariableList.push('sender_name');
    tempVariableList.push('organization_name');
    tempVariableList.push('network_name');
    tempVariableList.push('workflow_name');
    tempVariableList.push('sender_dept');
    tempVariableList.push('frontend_url');
    tempVariableList.push('link_to_detail');
    tempVariableList.push('recipient_email');
    if (nextTaskID !== parseInt(localStorage.getItem('task_id'), 10)) {
      tempVariableList.push('recipient_name');
      tempVariableList.push('recipient_dept');
    }
    setVariableList(tempVariableList);



  }, [props]);

  const handleVariableClick = (e) => {
    setVariableCopied(`Copied: ${e[0]}`);
  };
  const handleDialogClose = (e) => {
    setDialogOpen(false);
    setMessage('');
  };
  const handleDialogConfirm = (e) => {
    if ((dialogType === 'add') || (dialogType === 'update')) {
      if (actionMessage === '') {
        setMessage('Message can not be empty');
        return;
      }
      var placeholders = actionMessage.match(/\@\w+\@/g) !== null ? actionMessage.match(/\@\w+\@/g).map((s) => s.slice(1, -1)) : [];
      var placeholdersSMS = smsMessage.match(/\@\w+\@/g) !== null ? smsMessage.match(/\@\w+\@/g).map((s) => s.slice(1, -1)) : [];

      for (let i = 0; i < placeholders.length; i++) {
        if (variableList.findIndex((object) => object === placeholders[i]) === -1) {
          setMessage(`Invalid placeholder variable:${placeholders[i]}`);
          return;
        }
      }
      for (let i = 0; i < placeholdersSMS.length; i++) {
        if (variableList.findIndex((object) => object === placeholdersSMS[i]) === -1) {
          setMessage(`Invalid SMS placeholder variable:${placeholdersSMS[i]}`);
          return;
        }
      }
    }
    if (dialogType === 'add') {
      props.createActionMsg({
        action_id: props.action_id.toString(),
        next_task_id: nextTaskID.toString(),
        message: actionMessage,
        sms_message: smsMessage,
        reminder_message: reminderMessage,
        sender_message: senderMessage,
        cc_email: ccEmail,
        cc_sender: ccSender ? '1' : '0',
        intg_ext_api_id: intgExtAPIID.toString(),
        json_message: jSONMessage,
        visibility: visibility.toString(),
      });
    } else if (dialogType === 'update') {
      props.updateActionMsg(id, {
        action_id: props.action_id.toString(),
        next_task_id: nextTaskID.toString(),
        message: actionMessage,
        sms_message: smsMessage,
        reminder_message: reminderMessage,
        sender_message: senderMessage,
        cc_email: ccEmail,
        cc_sender: ccSender ? '1' : '0',
        intg_ext_api_id: intgExtAPIID.toString(),
        json_message: jSONMessage,
        visibility: visibility.toString(),
      });
    } else if (dialogType === 'delete') {
      props.deleteActionMsg(id);
    }
  };

  const onNextTaskIDChange = (e) => {
    setNextTaskID(e.target.value);
  };
  const onVisibilityChange = (e) => {
    setVisibility(e.target.value);
  };
  const onIntgExtAPIIDChange = (e) => {
    setIntgExtAPIID(e.target.value);
  };
  const onJSONMessageChange = (e) => {
    setJSONMessage(e.target.value);
  };
  const onMessageChange = (e) => {
    setActionMessage(e.target.value);
  };
  const onReminderMessageChange = (e) => {
    setReminderMessage(e.target.value);
  };
  const onSenderMessageChange = (e) => {
    setSenderMessage(e.target.value);
  };
  const onCCEmailChange = (e) => {
    setCcEmail(e.target.value);
  };
  const onCCSenderChange = (e) => {
    setCcSender(e.target.checked);
  }
  const onSMSMessageChange = (e) => {
    setSMSMessage(e.target.value);
  };
  const onLinkDataActionIDChange = (e) => {
    setLinkDataActionID(e.target.value);
  };
  const onLinkDataOptionIDChange = (e) => {
    setLinkDataOptionID(e.target.value);
  };
  const onLinkDataFormIDChange = (e) => {
    setLinkDataFormID(e.target.value);
  };
  const onLinkDataExActionIDChange = (e) => {
    setLinkDataExActionID(e.target.value);
  };
  const handleLinkDataClick = (e) => {
    if (linkDataOptionID !== "" && linkDataActionID !== "") {
      setVariableCopied("Email Link Data Copied")
    } else {
      setVariableCopied("Please Select Option and Action")
    }
  };
  const handleExternalLinkDataClick = (e) => {
    if (linkDataFormID !== "" && linkDataExActionID !== "") {
      setVariableCopied("External Email Link Data Copied")
    } else {
      setVariableCopied("Please Select Form and Aciton")
    }
  };
  const { classes } = props;
  const columns = [
    'Serial',
    'Next Task',
    'Message',
    '',
  ];
  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    filter: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    selectToolbarPlacement: 'none',
  };
  const rowItems = [];
  props.actionMsgVar_list.forEach((element) => {
    rowItems.push(
      <GridItem xs={12} sm={12} md={6}>
        <div style={{ display: 'inline-block' }}>
          <IconButton size="small" onClick={handleVariableClick.bind(this, [`@${element.name}@`])}>
            <CopyToClipboard text={`@${element.name}@`}>
              <FileCopyIcon style={{ height: '20px', width: '20px' }} />
            </CopyToClipboard>
          </IconButton>
          <Typography variant="caption">{`@${element.name}@`}</Typography>
        </div>
      </GridItem>,
    );
  });
  const rowWFAttrItems = [];
  props.actionMsgWFAttr_list.forEach((element) => {
    rowWFAttrItems.push(
      <GridItem xs={12} sm={12} md={6} key={element.attribute}>
        <div style={{ display: 'inline-block' }}>
          <IconButton size="small" onClick={handleVariableClick.bind(this, [`@${element.attribute}@`])}>
            <CopyToClipboard text={`@${element.attribute}@`}>
              <FileCopyIcon style={{ height: '20px', width: '20px' }} />
            </CopyToClipboard>
          </IconButton>
          <Typography variant="caption">{`@${element.attribute}@`}</Typography>
        </div>
      </GridItem>,
    );
  });
  /*
  const nextTaskList = filteredNextTask.map((item) => (
    <MenuItem key={item.id} value={item.id}>
      {filteredNextTask.find((p) => p.id === item.id).title}
    </MenuItem>
  ));
  var nextTaskListEdit = props.action_msg_task_list.filter((obj) => obj.action_message_id === id).map((item) => (
    <MenuItem key={item.id} value={item.id}>
      {props.action_msg_task_list.find((p) => p.id === item.id).title}
    </MenuItem>
  ));
  */
  var filteredLinkDataList = props.actionMsg_link_data_list.filter((obj) => obj.next_task_id === nextTaskID)
  const linkDataUI = []
  filteredLinkDataList.forEach((element) => {
    var optionObj = JSON.parse(element.options)
    var optionMenuItem = []
    optionObj.options.forEach((option) => {
      optionMenuItem.push(
        <MenuItem key={option.id} value={option.id}>
          {option.value}
        </MenuItem>
      );
    });
    var actionMenuItem = []
    element.actions.forEach((action) => {
      actionMenuItem.push(
        <MenuItem key={action.id} value={action.id}>
          {action.title}
        </MenuItem>
      );
    });

    linkDataUI.push(
      <GridItem xs={12} sm={12} md={5}>
        <FormControl fullWidth className={classes.formControl} >
          <InputLabel id="optionLabel">Select Option</InputLabel>
          <Select
            labelId="optionLabel"
            id="optionSelect"
            value={linkDataOptionID}
            onChange={onLinkDataOptionIDChange.bind(this)}
          >
            {optionMenuItem}
          </Select>
        </FormControl>
      </GridItem>,
      <GridItem xs={12} sm={12} md={5}>
        <FormControl fullWidth className={classes.formControl} >
          <InputLabel id="actionLabel">Select Action</InputLabel>
          <Select
            labelId="actionLabel"
            id="actionnSelect"
            value={linkDataActionID}
            onChange={onLinkDataActionIDChange.bind(this)}
          >
            {actionMenuItem}
          </Select>
        </FormControl>
      </GridItem>,
      <GridItem xs={12} sm={12} md={2}>
        <div style={{ display: 'inline-block' }}>
          <IconButton onClick={handleLinkDataClick.bind(this)}>
            <CopyToClipboard text={linkDataOptionID !== "" && linkDataActionID !== "" ? `@@{"task_id":"${nextTaskID}","form_id":"${element.form_id}","field_id":"${element.field_id}", "option_id":"${linkDataOptionID}", "action_id":"${linkDataActionID}"}@@` : ""}>
              <FileCopyIcon style={{ height: '20px', width: '20px' }} />
            </CopyToClipboard>
          </IconButton>
          <Typography variant="caption">Link Action</Typography>
        </div>
      </GridItem >
    );
  });
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Action Message</b>
          <p className={classes.cardCategoryWhite}>
            Here is the list of email templates used in each action.
          </p>
        </CardHeader>
        <CardBody>
          <MUIDataTable
            title={(
              <Button
                onClick={handleNewClick.bind(this)}
                color="primary"
                title="Create a new Action Message"
                disabled={parseInt(localStorage.getItem('workflow_state'), 10) > 0 || filteredNextTask.length === 0}
                round
              >
                New Action Message
              </Button>
            )}
            data={data}
            columns={columns}
            options={options}
          />
          <Dialog fullWidth maxWidth="lg" open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              <Card>
                <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                  <b>{dialogTitle}</b>
                </CardHeader>
                <CardBody>
                  <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        id="idMessage"
                        label="Message"
                        type="text"
                        multiline
                        rows={10}
                        fullWidth
                        value={actionMessage}
                        onChange={onMessageChange.bind(this)}
                        disabled={fieldDisabled}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled}>
                        <InputLabel id="nextTaskIDLabel">Next Task</InputLabel>
                        <Select
                          labelid="nextTaskIDLabel"
                          id="nextTaskIDSelect"
                          value={nextTaskID}
                          onChange={onNextTaskIDChange.bind(this)}
                        >
                          {filteredNextTask.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {filteredNextTask.find((p) => p.id === item.id).alias === "" ? filteredNextTask.find((p) => p.id === item.id).title : filteredNextTask.find((p) => p.id === item.id).title + " (" + filteredNextTask.find((p) => p.id === item.id).alias + ")"}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6} >
                      <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled}>
                        <InputLabel id="visibilityLabel">Visibility</InputLabel>
                        <Select
                          labelid="visibilityLabel"
                          id="visibilitySelect"
                          value={visibility}
                          onChange={onVisibilityChange.bind(this)}
                        >
                          <MenuItem key={0} value={0}>Editable  </MenuItem>
                          <MenuItem key={1} value={1}>Read Only  </MenuItem>
                          <MenuItem key={2} value={2}>Hide  </MenuItem>
                          <MenuItem key={2} value={3}>Do Not Send Email </MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6} >
                      <InputLabel id="ccSenderLabel" shrink>CC Sender</InputLabel>
                      <Grid item component="label" container alignItems="center" spacing={1}>
                        <Grid item>No</Grid>
                        <Grid item>
                          <Switch
                            labelid="ccSenderLabel"
                            checked={ccSender}
                            onChange={onCCSenderChange.bind(this)}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled={fieldDisabled}
                            classes={{
                              switchBase: props.classes.switchBase,
                              checked: props.classes.switchChecked,
                            }}
                          />
                        </Grid>
                        <Grid item>Yes</Grid>
                      </Grid>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        id="idSMSMessage"
                        label="SMS Message"
                        type="text"
                        multiline
                        rows={2}
                        fullWidth
                        value={smsMessage}
                        onChange={onSMSMessageChange.bind(this)}
                        disabled={fieldDisabled}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        id="idReminderMessage"
                        label="Reminder Message"
                        type="text"
                        multiline
                        rows={4}
                        fullWidth
                        value={reminderMessage}
                        onChange={onReminderMessageChange.bind(this)}
                        disabled={fieldDisabled}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        id="idSenderMessage"
                        label="Sender Message"
                        type="text"
                        multiline
                        rows={4}
                        fullWidth
                        value={senderMessage}
                        onChange={onSenderMessageChange.bind(this)}
                        disabled={fieldDisabled}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        id="idccEmail"
                        label="CC Emails (each email separated by space)"
                        type="text"
                        multiline
                        rows={3}
                        fullWidth
                        value={ccEmail}
                        onChange={onCCEmailChange.bind(this)}
                        disabled={fieldDisabled}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />

                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <InputLabel shrink id="formVariable">Form Variables</InputLabel>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8}>
                      <div style={{ color: 'blue', textAlign: 'left' }}>{variableCopied}</div>
                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <div style={{ display: 'inline-block' }}>
                        <IconButton size="small" onClick={handleVariableClick.bind(this, ['@applicant_name@'])}>
                          <CopyToClipboard text="@applicant_name@">
                            <FileCopyIcon style={{ height: '20px', width: '20px' }} />
                          </CopyToClipboard>
                        </IconButton>
                        <Typography variant="caption">@applicant_name@</Typography>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div style={{ display: 'inline-block' }}>
                        <IconButton size="small" onClick={handleVariableClick.bind(this, ['@application_name@'])}>
                          <CopyToClipboard text="@application_name@">
                            <FileCopyIcon style={{ height: '20px', width: '20px' }} />
                          </CopyToClipboard>
                        </IconButton>
                        <Typography variant="caption">@application_name@</Typography>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div style={{ display: 'inline-block' }}>
                        <IconButton size="small" onClick={handleVariableClick.bind(this, ['@sender_name@'])}>
                          <CopyToClipboard text="@sender_name@">
                            <FileCopyIcon style={{ height: '20px', width: '20px' }} />
                          </CopyToClipboard>
                        </IconButton>
                        <Typography variant="caption">@sender_name@</Typography>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div style={{ display: 'inline-block' }}>
                        <IconButton size="small" onClick={handleVariableClick.bind(this, ['@organization_name@'])}>
                          <CopyToClipboard text="@organization_name@">
                            <FileCopyIcon style={{ height: '20px', width: '20px' }} />
                          </CopyToClipboard>
                        </IconButton>
                        <Typography variant="caption">@organization_name@</Typography>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div style={{ display: 'inline-block' }}>
                        <IconButton size="small" onClick={handleVariableClick.bind(this, ['@network_name@'])}>
                          <CopyToClipboard text="@network_name@">
                            <FileCopyIcon style={{ height: '20px', width: '20px' }} />
                          </CopyToClipboard>
                        </IconButton>
                        <Typography variant="caption">@network_name@</Typography>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div style={{ display: 'inline-block' }}>
                        <IconButton size="small" onClick={handleVariableClick.bind(this, ['@workflow_name@'])}>
                          <CopyToClipboard text="@workflow_name@">
                            <FileCopyIcon style={{ height: '20px', width: '20px' }} />
                          </CopyToClipboard>
                        </IconButton>
                        <Typography variant="caption">@workflow_name@</Typography>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div style={{ display: 'inline-block' }}>
                        <IconButton size="small" onClick={handleVariableClick.bind(this, ['@sender_dept@'])}>
                          <CopyToClipboard text="@sender_dept@">
                            <FileCopyIcon style={{ height: '20px', width: '20px' }} />
                          </CopyToClipboard>
                        </IconButton>
                        <Typography variant="caption">@sender_dept@</Typography>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div style={{ display: 'inline-block' }}>
                        <IconButton size="small" onClick={handleVariableClick.bind(this, ['@recipient_email@'])}>
                          <CopyToClipboard text="@recipient_email@">
                            <FileCopyIcon style={{ height: '20px', width: '20px' }} />
                          </CopyToClipboard>
                        </IconButton>
                        <Typography variant="caption">@recipient_email@</Typography>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div style={{ display: nextTaskID !== parseInt(localStorage.getItem('task_id'), 10) ? 'inline-block' : 'none' }}>
                        <IconButton size="small" onClick={handleVariableClick.bind(this, ['@recipient_name@'])}>
                          <CopyToClipboard text="@recipient_name@">
                            <FileCopyIcon style={{ height: '20px', width: '20px' }} />
                          </CopyToClipboard>
                        </IconButton>
                        <Typography variant="caption">@recipient_name@</Typography>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div style={{ display: nextTaskID !== parseInt(localStorage.getItem('task_id'), 10) ? 'inline-block' : 'none' }}>
                        <IconButton size="small" onClick={handleVariableClick.bind(this, ['@recipient_dept@'])}>
                          <CopyToClipboard text="@recipient_dept@">
                            <FileCopyIcon style={{ height: '20px', width: '20px' }} />
                          </CopyToClipboard>
                        </IconButton>
                        <Typography variant="caption">@recipient_dept@</Typography>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div style={{ display: nextTaskID !== parseInt(localStorage.getItem('task_id'), 10) ? 'inline-block' : 'none' }}>
                        <IconButton size="small" onClick={handleVariableClick.bind(this, ['@frontend_url@'])}>
                          <CopyToClipboard text="@frontend_url@">
                            <FileCopyIcon style={{ height: '20px', width: '20px' }} />
                          </CopyToClipboard>
                        </IconButton>
                        <Typography variant="caption">@frontend_url@</Typography>
                      </div>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div style={{ display: 'inline-block' }}>
                        <IconButton size="small" onClick={handleVariableClick.bind(this, ['@link_to_detail@'])}>
                          <CopyToClipboard text="@link_to_detail@">
                            <FileCopyIcon style={{ height: '20px', width: '20px' }} />
                          </CopyToClipboard>
                        </IconButton>
                        <Typography variant="caption">@link_to_detail@</Typography>
                      </div>
                    </GridItem>
                    {rowItems}
                    {rowWFAttrItems}
                  </GridContainer>
                  <br />
                  <GridContainer>
                    {linkDataUI}
                  </GridContainer>
                  <br />
                  {props.action_actionmessage_list.type === 'External' ?
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={5}>
                        <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled || parseInt(localStorage.getItem('workflow_state'), 10) > 0}>
                          <InputLabel id="formIdLabel">Select Form</InputLabel>
                          <Select
                            labelid="formIdLabel"
                            id="formIdSelect"
                            value={linkDataFormID}
                            onChange={onLinkDataFormIDChange.bind(this)}
                          >
                            {props.actionMsg_task_form.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {props.actionMsg_task_form.find((p) => p.id === item.id).title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={5}>
                        <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled || parseInt(localStorage.getItem('workflow_state'), 10) > 0}>
                          <InputLabel id="exActionIdLabel">Select Action </InputLabel>
                          <Select
                            labelid="exActonIdLabel"
                            id="exActionIdSelect"
                            value={linkDataExActionID}
                            onChange={onLinkDataExActionIDChange.bind(this)}
                          >
                            {props.actionMsg_task_action.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {props.actionMsg_task_action.find((p) => p.id === item.id).title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2}>
                        <div style={{ display: 'inline-block' }}>
                          <IconButton onClick={handleExternalLinkDataClick.bind(this)}>
                            <CopyToClipboard text={linkDataFormID !== "" && linkDataExActionID !== "" ? `@@{"task_id":"${props.action_actionmessage_list.task_id}","form_id":"${linkDataFormID}","field_id":"", "option_id":"", "action_id":"${linkDataExActionID}"}@@` : ""}>
                              <FileCopyIcon style={{ height: '20px', width: '20px' }} />
                            </CopyToClipboard>
                          </IconButton>
                          <Typography variant="caption">External User Link</Typography>
                        </div>
                      </GridItem >
                    </GridContainer>
                    : ""}
                  <br />
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled}>
                        <InputLabel id="intgExtApiIDLabel">Integration External API</InputLabel>
                        <Select
                          labelid="intgExtApiIDLabel"
                          id="intgExtAPISelect"
                          value={intgExtAPIID}
                          onChange={onIntgExtAPIIDChange.bind(this)}
                        >
                          {props.intg_ext_api_list.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {props.intg_ext_api_list.find((p) => p.id === item.id).api_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        id="idJsonMessage"
                        label="JSON Message"
                        type="text"
                        multiline
                        rows={10}
                        fullWidth
                        value={jSONMessage}
                        onChange={onJSONMessageChange.bind(this)}
                        disabled={fieldDisabled}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter style={{ textAlign: 'left' }} />
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose.bind(this)} color="primary" round>
                Cancel
              </Button>
              <Button onClick={handleDialogConfirm.bind(this)} color="primary" round>
                {dialogButton}
              </Button>
            </DialogActions>
          </Dialog>
        </CardBody>
      </Card>
    </div >
  );
};
export default withStyles(extendedFormsStyle)(ActionMsgComp);
