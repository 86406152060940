import {
  GET_ACTION_MSG_REQUEST,
  GET_ACTION_MSG_SUCCESS,
  GET_ACTION_MSG_FAILURE,
  POST_ACTION_MSG_REQUEST,
  POST_ACTION_MSG_SUCCESS,
  POST_ACTION_MSG_FAILURE,
  PATCH_ACTION_MSG_REQUEST,
  PATCH_ACTION_MSG_SUCCESS,
  PATCH_ACTION_MSG_FAILURE,
  DELETE_ACTION_MSG_REQUEST,
  DELETE_ACTION_MSG_SUCCESS,
  DELETE_ACTION_MSG_FAILURE,
  GET_ACTION_ACTIONMSG_REQUEST,
  GET_ACTION_ACTIONMSG_SUCCESS,
  GET_ACTION_ACTIONMSG_FAILURE,
  GET_ACTION_MSG_VAR_REQUEST,
  GET_ACTION_MSG_VAR_SUCCESS,
  GET_ACTION_MSG_VAR_FAILURE,
  GET_ACTION_MSG_LINK_DATA_LIST_REQUEST,
  GET_ACTION_MSG_LINK_DATA_LIST_SUCCESS,
  GET_ACTION_MSG_LINK_DATA_LIST_FAILURE,
  GET_ACTION_MSG_WF_ATTR_REQUEST,
  GET_ACTION_MSG_WF_ATTR_SUCCESS,
  GET_ACTION_MSG_WF_ATTR_FAILURE,
  GET_ACTION_MSG_TASK_REQUEST,
  GET_ACTION_MSG_TASK_SUCCESS,
  GET_ACTION_MSG_TASK_FAILURE,
  GET_INTG_EXT_API_EXTERNAL_REQUEST,
  GET_INTG_EXT_API_EXTERNAL_SUCCESS,
  GET_INTG_EXT_API_EXTERNAL_FAILURE,
  GET_ACTION_MSG_TASK_FORM_REQUEST,
  GET_ACTION_MSG_TASK_FORM_SUCCESS,
  GET_ACTION_MSG_TASK_FORM_FAILURE,
  GET_ACTION_MSG_TASK_ACTION_REQUEST,
  GET_ACTION_MSG_TASK_ACTION_SUCCESS,
  GET_ACTION_MSG_TASK_ACTION_FAILURE,
} from '../constants/admin/ActionMsg';

const initialState = {
  requesting: false,
  message: '',
  actionMsg_list: '',
  actionMsgVar_list: [],
  actionMsg_link_data_list: [],
  actionMsg_task_form: [],
  actionMsg_task_action: [],
  actionMsgWFAttr_list: [],
  refreshActionMsg: false,
  action_actionmessage_list: '',
  action_msg_task_list: [],
  intg_ext_api_list: [],

};

export default function actionMsg(state = initialState, action) {
  switch (action.type) {
    case GET_ACTION_MSG_TASK_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
      };

    case GET_ACTION_MSG_TASK_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        action_msg_task_list: action.payload.data,
      };

    case GET_ACTION_MSG_TASK_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_INTG_EXT_API_EXTERNAL_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
      };

    case GET_INTG_EXT_API_EXTERNAL_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        intg_ext_api_list: action.payload.data,
      };

    case GET_INTG_EXT_API_EXTERNAL_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_ACTION_MSG_WF_ATTR_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
      };

    case GET_ACTION_MSG_WF_ATTR_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        actionMsgWFAttr_list: action.payload.data,
      };

    case GET_ACTION_MSG_WF_ATTR_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_ACTION_MSG_VAR_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
      };

    case GET_ACTION_MSG_VAR_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        actionMsgVar_list: action.payload.data,
      };

    case GET_ACTION_MSG_VAR_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case GET_ACTION_MSG_LINK_DATA_LIST_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
      };

    case GET_ACTION_MSG_LINK_DATA_LIST_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        actionMsg_link_data_list: action.payload.data,
      };

    case GET_ACTION_MSG_LINK_DATA_LIST_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case GET_ACTION_MSG_TASK_FORM_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
      };

    case GET_ACTION_MSG_TASK_FORM_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        actionMsg_task_form: action.payload.data,
      };

    case GET_ACTION_MSG_TASK_FORM_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case GET_ACTION_MSG_TASK_ACTION_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
      };

    case GET_ACTION_MSG_TASK_ACTION_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        actionMsg_task_action: action.payload.data,
      };

    case GET_ACTION_MSG_TASK_ACTION_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case GET_ACTION_MSG_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshActionMsg: false,
        message: '',
      };

    case GET_ACTION_MSG_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        actionMsg_list: action.payload.data,
      };

    case GET_ACTION_MSG_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case POST_ACTION_MSG_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshActionMsg: false,
      };

    case POST_ACTION_MSG_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshActionMsg: true,
      };

    case POST_ACTION_MSG_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case PATCH_ACTION_MSG_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshActionMsg: false,
      };

    case PATCH_ACTION_MSG_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshActionMsg: true,
      };

    case PATCH_ACTION_MSG_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case DELETE_ACTION_MSG_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshActionMsg: false,
      };

    case DELETE_ACTION_MSG_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshActionMsg: true,
      };

    case DELETE_ACTION_MSG_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case GET_ACTION_ACTIONMSG_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshActionMsg: false,
        message: '',
      };

    case GET_ACTION_ACTIONMSG_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        action_actionmessage_list: action.payload.data,
      };

    case GET_ACTION_ACTIONMSG_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    default:
      return state;
  }
}
