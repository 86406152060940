import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';

import Logout from '@mui/icons-material/SettingsPower';

import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import StateUI from 'components/StateUI/StateUI.jsx';

const ChooseWorkflowComp = (props) => {
  const [message, setMessage] = useState('');
  const [cardAnimaton, setCardAnimaton] = useState('cardHidden');
  const [workflowList, setWorkflowList] = useState([]);

  const { getNetworkWorkflows } = props;
  useEffect(() => {
    getNetworkWorkflows();
    setTimeout(() => {
      setCardAnimaton('');
    }, 500);
  }, [getNetworkWorkflows]);

  useEffect(() => {
    setMessage(props.message);
    setWorkflowList(props.workflow_list);
    if(props.workflow_list.length < 1){
      setMessage("Only workflow admins can access the admin console. Please log out and log in with admin credentials to continue.")
    }
  }, [props]);

  const handleListItemClick = (event, index) => {
    if (localStorage.getItem('admin_token')) {
      props.onLogin(
        { admin_token: localStorage.getItem('admin_token'), workflow_id: index.toString() },
      );
    } else {
      props.onLogin(
        { network_user_token: localStorage.getItem('network_user_token'), workflow_id: index.toString() },
      );
    }
  };

  const handleLogout = (e) => {
    localStorage.removeItem('network_user_token');
    localStorage.removeItem('admin_token');
    window.location.href = '/admin/public/login';
  };

  const listItems = [];
  workflowList.forEach((workflow) => {
    listItems.push(
      /*
      <ListItem key={workflow.id} id={workflow.id} button
        onClick={event => handleListItemClick(event, workflow.id)}>
        <ListItemIcon >
          <Avatar alt="Remy Sharp" src={workflow.image} />
        </ListItemIcon>
        <ListItemText primary={workflow.title} secondary={workflow.short_desc} />
      </ListItem>
      */
      <GridItem xs={12} sm={12} md={6} onClick={(event) => handleListItemClick(event, workflow.id)} key={workflow.id}>
        <Card>
          <CardActionArea>
            {/*
              <CardMedia
                component="img"
                alt="Contemplative Reptile"
                height="40"
                image={workflow.image}
              />
              */}
            <CardContent>
              <div style={{ display: 'inline-block', marginRight: 16 }}>
                {workflow.image === ''
                  ? <Avatar>{(workflow.name).substring(0, 2).toUpperCase()}</Avatar>
                  : <Avatar src={workflow.image} />}
              </div>
              <div style={{ display: 'inline-block' }}>
                <Typography variant="body1">
                  {workflow.alias === "" ? workflow.name : workflow.alias}
                </Typography>
              </div>
              <br />
              <div style={{ float: 'right' }}>
                {StateUI(workflow.state, 'small')}
              </div>
              <br />
              <Typography style={{ display: 'block' }} variant="caption">
                {workflow.short_desc}
              </Typography>
              <br />
            </CardContent>
          </CardActionArea>
        </Card>
        <br />
      </GridItem>,
    );
  });

  const { classes } = props;
  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={6} md={8}>
        <Card className={classes[cardAnimaton]}>
          _
          <CardHeader color="primary" plain style={{ textAlign: 'center' }}>
            <b>WORKFLOW SELECTOR </b>
          </CardHeader>
          <CardBody>
            <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
            <GridContainer>
              <GridItem xs={12} sm={6} md={12}>
                <IconButton style={{ float: 'right' }} edge="end" onClick={handleLogout.bind(this)} className={classes.menuButton} color="inherit" aria-label="menu">
                  <Logout style={{ color: '#e30c05', paddingLeft: '1px' }} />
                </IconButton>
              </GridItem>
            </GridContainer>
            <GridContainer>
              {listItems}
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default ChooseWorkflowComp;
