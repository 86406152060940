export const GET_ITEM_ADMIN_REQUEST = 'GET_ITEM_ADMIN_REQUEST';
export const GET_ITEM_ADMIN_SUCCESS = 'GET_ITEM_ADMIN_SUCCESS';
export const GET_ITEM_ADMIN_FAILURE = 'GET_ITEM_ADMIN_FAILURE';
export const GET_ITEM_CSV_ADMIN_REQUEST = 'GET_ITEM_CSV_ADMIN_REQUEST';
export const GET_ITEM_CSV_ADMIN_SUCCESS = 'GET_ITEM_CSV_ADMIN_SUCCESS';
export const GET_ITEM_CSV_ADMIN_FAILURE = 'GET_ITEM_CSV_ADMIN_FAILURE';
export const POST_ITEM_ADMIN_REQUEST = 'POST_ITEM_ADMIN_REQUEST';
export const POST_ITEM_ADMIN_SUCCESS = 'POST_ITEM_ADMIN_SUCCESS';
export const POST_ITEM_ADMIN_FAILURE = 'POST_ITEM_ADMIN_FAILURE';
export const PATCH_ITEM_ADMIN_REQUEST = 'PATCH_ITEM_ADMIN_REQUEST';
export const PATCH_ITEM_ADMIN_SUCCESS = 'PATCH_ITEM_ADMIN_SUCCESS';
export const PATCH_ITEM_ADMIN_FAILURE = 'PATCH_ITEM_ADMIN_FAILURE';
export const DELETE_ITEM_SELECT_ADMIN_REQUEST = 'DELETE_ITEM_SELECT_ADMIN_REQUEST';
export const DELETE_ITEM_SELECT_ADMIN_SUCCESS = 'DELETE_ITEM_SELECT_ADMIN_SUCCESS';
export const DELETE_ITEM_SELECT_ADMIN_FAILURE = 'DELETE_ITEM_SELECT_ADMIN_FAILURE';
export const GET_ITEM_INTG_ADMIN_REQUEST = 'GET_ITEM_INTG_ADMIN_REQUEST';
export const GET_ITEM_INTG_ADMIN_SUCCESS = 'GET_ITEM_INTG_ADMIN_SUCCESS';
export const GET_ITEM_INTG_ADMIN_FAILURE = 'GET_ITEM_INTG_ADMIN_FAILURE';
export const PATCH_ITEM_INTG_ADMIN_REQUEST = 'PATCH_ITEM_INTG_ADMIN_REQUEST';
export const PATCH_ITEM_INTG_ADMIN_SUCCESS = 'PATCH_ITEM_INTG_ADMIN_SUCCESS';
export const PATCH_ITEM_INTG_ADMIN_FAILURE = 'PATCH_ITEM_INTG_ADMIN_FAILURE';