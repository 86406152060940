import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import * as rolesActions from 'actions/admin/RolesActions';
import * as commNameActions from 'actions/admin/CommNameActions';
import * as departmentActions from 'actions/admin/DepartmentActions';
import * as userActions from 'actions/UserActions';
import * as defaultActions from 'actions/admin/DefaultActions';
import RolesComp from './Roles/RolesComp';
import { withRouter } from 'react-router-dom'

const Roles = (props) => {
  const { classes } = props;
  const {
    department, commName, role, user, defaultStates,
  } = props;
  const {
    getRoles,
    getAdhocRoles,
    createRole,
    updateRole,
    deleteRole,
  } = props.rolesActions;
  const { resetMessage } = props.defaultActions;
  const { getCommNames } = props.commNameActions;
  const { getDepartments } = props.departmentActions;
  const { setSelectedUser } = props.userActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <RolesComp
        getRoles={getRoles}
        getAdhocRoles={getAdhocRoles}
        resetMessage={resetMessage}
        getCommNames={getCommNames}
        getDepartments={getDepartments}
        // findUsers={findUsers}
        role_list={role.role_list.list === undefined ? [] : role.role_list.list}
        role_adhoc_list={role.role_adhoc_list === undefined ? [] : role.role_adhoc_list}
        // user_list={role.user_list.list === undefined ? [] : role.user_list.list}
        department_list={department.department_list.list === undefined ? [] : department.department_list.list}
        commName_list={commName.commName_list.list === undefined ? [] : commName.commName_list.list}
        createRole={createRole}
        updateRole={updateRole}
        deleteRole={deleteRole}
        setSelectedUser={setSelectedUser}
        selected_user_id={user.selected_user_id}
        selected_user={user.selected_user}
        refreshRole={role.refreshRole}
        requesting={role.requesting}
        message={role.message}
        error_core={role.error_core}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    role: state.adRole,
    user: state.user,
    commName: state.adCommName,
    department: state.adDepartment,
    defaultStates: state.adDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    rolesActions: bindActionCreators(rolesActions, dispatch),
    commNameActions: bindActionCreators(commNameActions, dispatch),
    departmentActions: bindActionCreators(departmentActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    defaultActions: bindActionCreators(defaultActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Roles);
