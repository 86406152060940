export const GET_FORM_REQUEST = 'GET_FORM_REQUEST';
export const GET_FORM_SUCCESS = 'GET_FORM_SUCCESS';
export const GET_FORM_FAILURE = 'GET_FORM_FAILURE';
export const POST_FORM_REQUEST = 'POST_FORM_REQUEST';
export const POST_FORM_SUCCESS = 'POST_FORM_SUCCESS';
export const POST_FORM_FAILURE = 'POST_FORM_FAILURE';
export const PATCH_FORM_REQUEST = 'PATCH_FORM_REQUEST';
export const PATCH_FORM_SUCCESS = 'PATCH_FORM_SUCCESS';
export const PATCH_FORM_FAILURE = 'PATCH_FORM_FAILURE';
export const DELETE_FORM_REQUEST = 'DELETE_FORM_REQUEST';
export const DELETE_FORM_SUCCESS = 'DELETE_FORM_SUCCESS';
export const DELETE_FORM_FAILURE = 'DELETE_FORM_FAILURE';
export const POST_FORM_ADHOC_REQUEST = 'POST_FORM_ADHOC_REQUEST';
export const POST_FORM_ADHOC_SUCCESS = 'POST_FORM_ADHOC_SUCCESS';
export const POST_FORM_ADHOC_FAILURE = 'POST_FORM_ADHOC_FAILURE';
export const GET_FORM_PRINT_FILE_REQUEST = 'GET_FORM_PRINT_FILE_REQUEST';
export const GET_FORM_PRINT_FILE_SUCCESS = 'GET_FORM_PRINT_FILE_SUCCESS';
export const GET_FORM_PRINT_FILE_FAILURE = 'GET_FORM_PRINT_FILE_FAILURE';
