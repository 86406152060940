import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import Button from 'components/CustomButtons/Button.jsx';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';

const ItemSearchMultiSelectComp = (props) => {
  const [itemRelationLevel, setItemRelationLevel] = useState('0');
  const [searchItemList, setSearchItemList] = useState([]);
  const [itemMUIData, setItemMUIData] = useState([]);
  const [message, setMessage] = useState('');
  const [pageSize, setPageSize] = useState(10);
  const [pageNum, setPageNum] = useState(1);
  const [orderBy, setOrderBy] = useState('item_name');
  const [orderByDirec, setOrderByDirec] = useState('asc');
  const [searchLevel, setSearchLevel] = useState('0');
  const [parentIDs, setParentIDs] = useState([]);

  const [searchItemStr, setSearchItemStr] = useState('');
  const { t } = useTranslation();

  const { item_relation_level, search_level } = props;
  useEffect(() => {
    setItemRelationLevel(item_relation_level);
    setSearchLevel(search_level);
  }, [item_relation_level, search_level]);

  useEffect(() => {
    setMessage(props.message);

    var itemRowItems = [];
    if (props.search_item_list.length > 0) {
      for (let i = 0; i < props.search_item_list.length; i++) {
        var colItems = [];
        colItems.push(`${props.search_item_list[i].name} `);
        colItems.push(`${props.search_item_list[i].item_sku}`);
        itemRowItems.push(colItems);
      }
    }
    setItemMUIData(itemRowItems);
    setSearchItemList(props.search_item_list);
  }, [props]);

  const handleSearchItemClick = (e) => {
    if (searchItemStr !== '') {
      props.SearchItem("", searchItemStr, pageSize, pageNum, orderBy, orderByDirec, itemRelationLevel, searchLevel);
    }
    e.preventDefault();
  };

  const onSearchItemStrChange = (e) => {
    setSearchItemStr(e.target.value);
    if (e.target.value.length > 1) {
      props.SearchItem("", e.target.value, pageSize, pageNum, orderBy, orderByDirec, itemRelationLevel, searchLevel);
    }
  };

  const handleSelectParentItemClick = (e) => {
    props.setSelectedParentItems(parentIDs)
  };
  const itemMUIDataColumns = [
    t('app_detail_item_search_name_label'),
    t('app_detail_item_search_sku_label')
  ];
  const itemMUIDataOptions = {
    selectableRowsHideCheckboxes: false,
    filterType: 'dropdown',
    //responsive: 'standard',
    selectableRows: 'multiple',
    selectableRowsOnClick: true,
    selectToolbarPlacement: 'none',
    pagination: true,
    print: false,
    filter: false,
    download: false,
    search: false,
    sortFilterList: false,
    viewColumns: false,
    //rowsSelected: selectedParentRows,
    serverSide: true,
    count: props.search_item_count,
    rowsPerPage: pageSize,
    page: pageNum - 1,
    rowsPerPageOptions: [5, 10, 20, 100],

    onRowSelectionChange: (rowsSelected, allRows) => {
      var tempParentItemIDs = []
      allRows.forEach(element => {
        tempParentItemIDs.push(searchItemList[element.dataIndex].id)
      });
      setParentIDs(tempParentItemIDs)
    },
    onTableChange: (action, tableState) => {
      //console.log(action, tableState);
      switch (action) {
        case 'changeRowsPerPage':
          setPageSize(tableState.rowsPerPage)
          setPageNum(1)
          props.SearchItem("", searchItemStr, tableState.rowsPerPage, 1, orderBy, orderByDirec, itemRelationLevel, searchLevel)
          break;
        case 'changePage':
          setPageNum(tableState.page + 1)
          props.SearchItem("", searchItemStr, pageSize, tableState.page + 1, orderBy, orderByDirec, itemRelationLevel, searchLevel)
          break;
        case 'sort':
          var sortString = 'item_name'
          setOrderByDirec(tableState.sortOrder.direction)
          if (tableState.sortOrder.name === 'Name') {
            setOrderBy('item_name')
            sortString = 'item_name'
          } else if (tableState.sortOrder.name === 'SKU') {
            setOrderBy('item_sku')
            sortString = 'item_sku'
          }
          props.SearchItem("", searchItemStr, pageSize, pageNum, sortString, tableState.sortOrder.direction, itemRelationLevel, searchLevel)
          break;
      }
    },
    textLabels: {
      body: {
        noMatch: t('mui_table_no_match'),
        toolTip: t('mui_table_tool_tip'),
        columnHeaderTooltip: column => t('mui_table_header_tool_tip') + " " + column.label
      },
      pagination: {
        next: t('mui_table_next'),
        previous: t('mui_table_previous'),
        rowsPerPage: t('mui_table_rows_per_page'),
        displayRows: t('mui_table_display_rows'),
      },
      toolbar: {
        search: t('mui_table_search'),
        downloadCsv: t('mui_table_download_csv'),
        print: t('mui_table_print'),
        viewColumns: t('mui_table_view_columns'),
        filterTable: t('mui_table_filter_table'),
      },
      filter: {
        all: t('mui_table_filter_all'),
        title: t('mui_table_filter_title'),
        reset: t('mui_table_filter_reset'),
      },
      viewColumns: {
        title: t('mui_table_column_title'),
        titleAria: t('mui_table_column_title_aria'),
      },
      selectedRows: {
        text: t('mui_table_selected_text'),
        delete: t('mui_table_selected_delete'),
        deleteAria: t('mui_table_selected_delete_aria'),
      },
    },
  };

  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <TextField
            required={false}
            fullWidth
            label={t('app_detail_item_search_search_label')}
            value={searchItemStr}
            onChange={onSearchItemStrChange.bind(this)}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Button
            onClick={handleSearchItemClick.bind(this)}
            color="primary"
            round
          >
            <SearchIcon />
            &nbsp;&nbsp; {t('app_detail_item_search_search_button')}
          </Button>
        </GridItem>
      </GridContainer>
      <br />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div style={{ display: itemMUIData.length < 1 ? 'none' : 'block' }}>
            <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
            <MUIDataTable
              data={itemMUIData}
              columns={itemMUIDataColumns}
              options={itemMUIDataOptions}
            />
          </div>
          <div style={{ display: itemMUIData.length > 0 ? 'none' : 'block' }}>
            {t('app_detail_item_search_no_record')}
          </div>
        </GridItem>
      </GridContainer>
      <br />
      <div>
        {props.search_item_list.length > 0 ?
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Button
                onClick={handleSelectParentItemClick.bind(this)}
                color="primary"
                round
              >
                Select Item
              </Button>
            </GridItem>
          </GridContainer>
          :
          ""
        }
      </div>
      <br />
    </div>
  );
};
export default ItemSearchMultiSelectComp;
