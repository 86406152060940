import React, { Component } from 'react';
import { Link, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import Button from 'components/CustomButtons/Button.jsx';
import * as formActions from 'actions/admin/FormActions';
import * as fieldActions from 'actions/admin/FieldActions';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import PostAddRounded from '@mui/icons-material/PostAddRounded';
import FormPreviewComp from './FormPreview/FormPreviewComp';

const FormFieldPreview = (props) => {

  const { classes } = props;
  const { field, form, defaultStates } = props;
  const {
    getFormFields,
    createField,
    updateField,
    deleteField,
  } = props.fieldActions;
  const { getForms } = props.formActions;
  const { id } = useParams();

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/admin/form">
          <Button size="sm" round color="primary">
            {' '}
            <PostAddRounded fontSize="small" />
            {' '}
            Form
          </Button>
        </Link>
        {/*
          <Link to={link} >
            <Button size="sm" round color="primary"> <PostAddRounded /> Task Form</Button>
          </Link>
          */}
        <Link to="#">
          <Button size="sm" round color="primary" block>
            {' '}
            {field.form_field_list.title}
            {' '}
            (PREVIEW)
          </Button>
        </Link>
      </Breadcrumbs>
      <br />
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <FormPreviewComp
        form_id={id}
        getForms={getForms}
        getFormFields={getFormFields}
        form_list={form.form_list.list === undefined ? [] : form.form_list.list}
        form_field_list={field.form_field_list === undefined ? [] : field.form_field_list}
        form_fields={field.form_field_list.fields === undefined ? [] : field.form_field_list.fields}
        createField={createField}
        updateField={updateField}
        deleteField={deleteField}
        refreshField={field.refreshField}
        requesting={field.requesting}
        message={field.message}
        error_core={field.error_core}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    form: state.adForm,
    field: state.adField,
    defaultStates: state.adDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    formActions: bindActionCreators(formActions, dispatch),
    fieldActions: bindActionCreators(fieldActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormFieldPreview);
