export const POST_APPLICATION_CREATE_REQUEST = 'POST_APPLICATION_CREATE_REQUEST';
export const POST_APPLICATION_CREATE_SUCCESS = 'POST_APPLICATION_CREATE_SUCCESS';
export const POST_APPLICATION_CREATE_FAILURE = 'POST_APPLICATION_CREATE_FAILURE';
export const GET_ACTIVE_APPLICATION_REQUEST = 'GET_ACTIVE_APPLICATION_REQUEST';
export const GET_ACTIVE_APPLICATION_SUCCESS = 'GET_ACTIVE_APPLICATION_SUCCESS';
export const GET_ACTIVE_APPLICATION_FAILURE = 'GET_ACTIVE_APPLICATION_FAILURE';
export const GET_ARCHIVE_APPLICATION_REQUEST = 'GET_ARCHIVE_APPLICATION_REQUEST';
export const GET_ARCHIVE_APPLICATION_SUCCESS = 'GET_ARCHIVE_APPLICATION_SUCCESS';
export const GET_ARCHIVE_APPLICATION_FAILURE = 'GET_ARCHIVE_APPLICATION_FAILURE';
export const GET_ISALLOWED_APPLICATION_REQUEST = 'GET_ISALLOWED_APPLICATION_REQUEST';
export const GET_ISALLOWED_APPLICATION_SUCCESS = 'GET_ISALLOWED_APPLICATION_SUCCESS';
export const GET_ISALLOWED_APPLICATION_FAILURE = 'GET_ISALLOWED_APPLICATION_FAILURE';
export const PATCH_APPLICATION_REQUEST = 'PATCH_APPLICATION_REQUEST';
export const PATCH_APPLICATION_SUCCESS = 'PATCH_APPLICATION_SUCCESS';
export const PATCH_APPLICATION_FAILURE = 'PATCH_APPLICATION_FAILURE';
export const GET_APPLICATION_FORM_WITHOUT_APP_REQUEST = 'GET_APPLICATION_FORM_WITHOUT_APP_REQUEST';
export const GET_APPLICATION_FORM_WITHOUT_APP_SUCCESS = 'GET_APPLICATION_FORM_WITHOUT_APP_SUCCESS';
export const GET_APPLICATION_FORM_WITHOUT_APP_FAILURE = 'GET_APPLICATION_FORM_WITHOUT_APP_FAILURE';
export const POST_MULTI_APPLICATIONS_REQUEST = 'POST_MULTI_APPLICATIONS_REQUEST';
export const POST_MULTI_APPLICATIONS_UPDATE = 'POST_MULTI_APPLICATIONS_UPDATE';
export const POST_MULTI_APPLICATIONS_SUCCESS = 'POST_MULTI_APPLICATIONS_SUCCESS';
export const POST_MULTI_APPLICATIONS_FAILURE = 'POST_MULTI_APPLICATIONS_FAILURE';
