import startOfWeek from 'date-fns/startOfWeek';
import format from 'date-fns/format';


import {
  AUTHORIZATION_REQUIRED,
  UNDEFINED_ERROR,
  RESET_UNDEFINED_ERROR,
} from 'constants/Default';

import {
  TEXT_FIELD,
  TEXT_AREA,
  DROP_DOWN,
  RADIO_BUTTON,
  CHECK_BOX,
  DATE,
  TIME,
  HELPTEXT,
  COMMITTEE,
  FILE_SHARING,
  WEEK_CALENDAR,
  ITEM_SEARCH,
  ITEM_SEARCH2,
  ITEM_DATATABLE,
  ITEM_OPT_DETAILS,
} from 'constants/FieldTypes';

export function getConfig() {
  const config = {
    headers: {
      Token: localStorage.getItem('token'),
      Apikey: localStorage.getItem('network_api_key'),
    },
  };
  return config;
}
export function getAdminConfig() {
  const config = {
    headers: {
      Token: localStorage.getItem('admin_token'),
      Apikey: localStorage.getItem('network_api_key'),
    },
  };
  return config;
}
export function getNetworkAdminConfig() {
  const config = {
    headers: {
      Token: localStorage.getItem('networkadmin_token'),
      Apikey: localStorage.getItem('network_api_key'),
    },
  };
  return config;
}

export function commonBackendCall(REQUEST, SUCCESS, FAILURE, requestedAPI) {
  return (dispatch) => {
    dispatch({
      type: REQUEST,
      payload: {
        requesting: true,
      },
    });
    dispatch({
      type: RESET_UNDEFINED_ERROR,
    });
    requestedAPI
      .then((response) => {
        dispatch({
          type: SUCCESS,
          payload: {
            requesting: false,
            data: response.data.data,
          },
        });
      })
      .catch((error) => {
        if (error.response === undefined) {
          dispatch({
            type: UNDEFINED_ERROR,
            payload: {
              error: error.request.status,
            },
          });
          dispatch({
            type: FAILURE,
            payload: {
              requesting: false,
              message: '',
              error_code: '',
            },
          });
        } else if (error.request.status === 401) {
          dispatch({
            type: AUTHORIZATION_REQUIRED,
          });
        } else {
          dispatch({
            type: FAILURE,
            payload: {
              requesting: false,
              message: error.response.data.data.message,
              error_code: error.response.data.data.error_code,
            },
          });
        }
      });
  };
}

export function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !re.test(email);
}

export function SaveFormToAPI(formID, propsRequesting, propsFields, propsRole_user, propsShare_file, propsWeekSelect, postApplicationDetailCommittee, postApplicationDetail, application_id, taskID) {
  if (!propsRequesting) {
    var isCommittee = false;
    var tempFieldArray = propsFields.filter((item) => item.formId === formID);
    var tempCommitteeFieldArray = [];
    tempFieldArray.forEach((field) => {
      if (field.type === COMMITTEE) {
        isCommittee = true;
        tempCommitteeFieldArray.push({ id: field.id, value: propsRole_user === '' ? [] : propsRole_user });
      }
      if (field.type === CHECK_BOX) {
        field.value = field.valueArray;
      } else if (field.type === ITEM_SEARCH || field.type === ITEM_SEARCH2 || field.type === ITEM_DATATABLE || field.type === ITEM_OPT_DETAILS) {
        field.value = field.valueArray
      } else if (field.type === FILE_SHARING) {
        if (propsShare_file !== undefined && propsShare_file.length > 0) {
          var tempObj = propsShare_file.find((o) => o.field_id === field.id);
          if (tempObj !== undefined) {
            var tempArray = [tempObj.file_id, tempObj.file_url, tempObj.user_id, tempObj.file_name, tempObj.file_path];
            field.value = tempArray;
          } else {
            field.value = propsFields.find((o) => o.id === field.id).valueArray;
          }
        } else {
          field.value = propsFields.find((o) => o.id === field.id).valueArray;
        }
      } else if (field.type === WEEK_CALENDAR) {
        var tempValueArray = [];
        if (propsWeekSelect === '') {
          tempValueArray.push(format(startOfWeek(new Date()), 'M-d-yyyy').toString());
        } else {
          tempValueArray.push(propsWeekSelect);
        }
        field.value = tempValueArray;
      } else {
        var tempValueArray1 = [];
        if (typeof field.value === 'string' || field.value instanceof String) {
          tempValueArray1.push(field.value.trim());
        } else {
          tempValueArray1.push(field.value);
        }
        field.value = tempValueArray1;
      }
    });
    tempFieldArray = tempFieldArray.map(({ label, ...item }) => item);
    tempFieldArray = tempFieldArray.map(({ required, ...item }) => item);
    tempFieldArray = tempFieldArray.map(({ field_error_message, ...item }) => item);
    tempFieldArray = tempFieldArray.map(({ type, ...item }) => item);
    tempFieldArray = tempFieldArray.map(({ formId, ...item }) => item);
    tempFieldArray = tempFieldArray.map(({ valueArray, ...item }) => item);
    tempFieldArray = tempFieldArray.map(({ error, ...item }) => item);

    if (isCommittee) {
      postApplicationDetailCommittee(application_id, { form_id: formID, task_id: taskID, fields: tempCommitteeFieldArray });
    } else {
      postApplicationDetail(application_id, { form_id: formID, task_id: taskID, fields: tempFieldArray });
    }
  }
}
