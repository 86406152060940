import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import Button from 'components/CustomButtons/Button.jsx';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';

const UserSearchComp = (props) => {
  const [userMUIData, setUserMUIData] = useState([]);
  const [searchUserList, setSearchUserList] = useState([]);
  const [userType, setUserType] = useState('');
  const [message, setMessage] = useState('');
  const [pageSize, setPageSize] = useState(5);
  const [pageNum, setPageNum] = useState(1);
  const [orderBy, setOrderBy] = useState('user_name');
  const [orderByDirec, setOrderByDirec] = useState('asc');

  const [searchUserStr, setSearchUserStr] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    setMessage(props.message);
    var userRowItems = [];
    if (props.search_user_list.length > 0) {
      for (let i = 0; i < props.search_user_list.length; i++) {
        var colItems = [];
        colItems.push(`${props.search_user_list[i].name} ${props.search_user_list[i].email}`);
        colItems.push(props.search_user_list[i].dept_alias === "" ? props.search_user_list[i].dept_name : props.search_user_list[i].dept_alias);
        userRowItems.push(colItems);
      }
    }
    setUserMUIData(userRowItems);
    setUserType(props.user_type);
    setSearchUserList(props.search_user_list);
  }, [props]);

  const handleSearchUserClick = (e) => {
    if (searchUserStr !== '') {
      props.SearchUser(userType, pageSize, pageNum, orderBy, orderByDirec, searchUserStr, props.application_id);
    }
    e.preventDefault();
  };

  const onSearchUserStrChange = (e) => {
    // props.setSelectedUser("", "")
    setSearchUserStr(e.target.value);
    if (e.target.value.length > 1) {
      props.SearchUser(userType, pageSize, pageNum, orderBy, orderByDirec, e.target.value, props.application_id);
    }
  };
  const userMUIDataColumns = [
    t('app_detail_user_search_name_label'),
    t('app_detail_user_search_dept_label')
  ];
  const userMUIDataOptions = {
    selectableRowsHideCheckboxes: true,
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'single',
    selectableRowsOnClick: true,
    selectToolbarPlacement: 'none',
    pagination: true,
    print: false,
    filter: false,
    download: false,
    search: false,
    sortFilterList: false,
    viewColumns: false,
    rowsSelected: [],
    serverSide: true,
    count: props.search_user_count,
    rowsPerPage: pageSize,
    page: pageNum - 1,
    rowsPerPageOptions: [5, 10, 20, 100],

    onRowSelectionChange: (rowsSelected, allRows) => {
      props.setSelectedUser(
        `${searchUserList[rowsSelected[0].dataIndex].name} 
        (${searchUserList[rowsSelected[0].dataIndex].email}
          ${searchUserList[rowsSelected[0].dataIndex].dept_alias === "" ? searchUserList[rowsSelected[0].dataIndex].dept_name : searchUserList[rowsSelected[0].dataIndex].dept_alias})`,
        searchUserList[rowsSelected[0].dataIndex].id,
      );
    },
    onTableChange: (action, tableState) => {
      //console.log(action, tableState);
      switch (action) {
        case 'changeRowsPerPage':
          setPageSize(tableState.rowsPerPage)
          setPageNum(1)
          props.SearchUser(userType, tableState.rowsPerPage, 1, orderBy, orderByDirec, searchUserStr, props.application_id)
          break;
        case 'changePage':
          setPageNum(tableState.page + 1)
          props.SearchUser(userType, pageSize, tableState.page + 1, orderBy, orderByDirec, searchUserStr, props.application_id)
          break;
        case 'sort':
          var sortString = 'user_name'
          setOrderByDirec(tableState.sortOrder.direction)
          if (tableState.sortOrder.name === 'Name') {
            setOrderBy('user_name')
            sortString = 'user_name'
          } else if (tableState.sortOrder.name === 'Department') {
            setOrderBy('department_name')
            sortString = 'department_name'
          }
          props.SearchUser(userType, pageSize, pageNum, sortString, tableState.sortOrder.direction, searchUserStr, props.application_id)
          break;
      }
    },
    textLabels: {
      body: {
        noMatch: t('mui_table_no_match'),
        toolTip: t('mui_table_tool_tip'),
        columnHeaderTooltip: column => t('mui_table_header_tool_tip') + " " + column.label
      },
      pagination: {
        next: t('mui_table_next'),
        previous: t('mui_table_previous'),
        rowsPerPage: t('mui_table_rows_per_page'),
        displayRows: t('mui_table_display_rows'),
      },
      toolbar: {
        search: t('mui_table_search'),
        downloadCsv: t('mui_table_download_csv'),
        print: t('mui_table_print'),
        viewColumns: t('mui_table_view_columns'),
        filterTable: t('mui_table_filter_table'),
      },
      filter: {
        all: t('mui_table_filter_all'),
        title: t('mui_table_filter_title'),
        reset: t('mui_table_filter_reset'),
      },
      viewColumns: {
        title: t('mui_table_column_title'),
        titleAria: t('mui_table_column_title_aria'),
      },
      selectedRows: {
        text: t('mui_table_selected_text'),
        delete: t('mui_table_selected_delete'),
        deleteAria: t('mui_table_selected_delete_aria'),
      },
    },
  };
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <GridContainer>
        <GridItem xs={12} sm={12} md={8}>
          <TextField
            required={false}
            fullWidth
            label={t('app_detail_user_search_search_label')}
            value={searchUserStr}
            onChange={onSearchUserStrChange.bind(this)}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <Button
            onClick={handleSearchUserClick.bind(this)}
            color="primary"
            round
          >
            <SearchIcon />
            &nbsp;&nbsp; {t('app_detail_user_search_search_button')}
          </Button>
        </GridItem>
      </GridContainer>
      <br />
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <div style={{ display: userMUIData.length < 1 ? 'none' : 'block' }}>
            <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
            <MUIDataTable
              data={userMUIData}
              columns={userMUIDataColumns}
              options={userMUIDataOptions}
            />
          </div>
          <div style={{ display: userMUIData.length > 0 ? 'none' : 'block' }}>
            {t('app_detail_user_search_no_record')}
          </div>
        </GridItem>
      </GridContainer>
    </div>
  );
};
export default UserSearchComp;
