import React, { useState, useEffect } from 'react';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { DefaultEditor } from 'react-simple-wysiwyg';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';

import AggregateRuleComp from './AggregateRuleComp';
import DatatableRuleComp from './DatatableRuleComp';
import OptionsComp from './OptionsComp';
import RequiredRuleComp from './RequiredRuleComp';
import ValidationRuleComp from './ValidationRuleComp';
import VisibleRuleComp from './VisibleRuleComp';

import {
  TEXT_FIELD,
  TEXT_AREA,
  DROP_DOWN,
  RADIO_BUTTON,
  CHECK_BOX,
  DATE,
  TIME,
  HELPTEXT,
  COMMITTEE,
  FILE_SHARING,
  AGGREGATE_NUM,
  WEEK_CALENDAR,
  ITEM_SEARCH,
  ITEM_SEARCH2,
  ITEM_DATATABLE,
} from 'constants/admin/FieldTypes';

import {
  INTEGER_NUMBER,
  DECIMAL_NUMBER,
  STRING,
  DATE_STRING,
  EMAIL_STRING,
  NONE,
} from 'constants/admin/FieldDataTypes';
import { TRANS_TEXT_FIELD } from 'constants/FieldTypes';


function LabelComp(props) {
  return (
    <div>
      <TextField
        required={false}
        fullWidth
        label="Label"
        value={props.label}
        onChange={props.onChangeLabel.bind(this)}
        disabled={props.fieldDisabled}
      />
      <br />
    </div>);
}

function AppListCustomIndexComp(props) {
  const { classes } = props;
  return (
    <div>
      <FormControl fullWidth className={classes.formControl} disabled={props.fieldDisabled}>
        <InputLabel id="appListRowsLabel">Application List Custom Index</InputLabel>
        <Select
          labelid="appListRowsLabel"
          id="rowsAppListSelect"
          value={props.appListCustomIndex}
          onChange={props.onChangeAppListCustomIndex.bind(this)}
        >
          <MenuItem value="0">Not Defined</MenuItem>
          <MenuItem value="1">1</MenuItem>
          <MenuItem value="2">2</MenuItem>
          <MenuItem value="3">3</MenuItem>
          <MenuItem value="4">4</MenuItem>
          <MenuItem value="5">5</MenuItem>
        </Select>
      </FormControl>
    </div >);
}

function SortComp(props) {
  return (
    <div>
      <TextField
        required={false}
        fullWidth
        label="Sort Order"
        value={props.sortOrder}
        onChange={props.onChangeSortOrder.bind(this)}
        disabled={props.fieldDisabled}
      />
    </div>);
}

function AliasComp(props) {
  return (
    <div>
      <TextField
        required={false}
        fullWidth
        label="Alias"
        value={props.alias}
        onChange={props.onChangeAlias.bind(this)}
        disabled={props.fieldDisabled}
      />
    </div>);
}

function TagComp(props) {
  return (
    <div>
      <TextField
        required={false}
        fullWidth
        label="Tag"
        value={props.tag}
        onChange={props.onChangeTag.bind(this)}
        disabled={props.fieldDisabled}
      />
    </div>);
}

function CommitteeComp(props) {
  return (
    <div>
      <FormControl fullWidth className={classes.formControl} disabled>
        <InputLabel id="adhocCommLabel">Adhoc Committee</InputLabel>
        <Select
          labelid="adhocCommLabel"
          id="adhocCommSelect"
          value={props.committeeNameID}
        // onChange={onCommitteeNameIDChange.bind(this)}
        >
          {props.adhoc_comm_list.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {props.adhoc_comm_list.find((p) => p.id === item.id).title}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>

  );
}

function ColspanComp(props) {
  const { classes } = props;
  return (
    <div>
      <FormControl fullWidth className={classes.formControl} disabled={props.fieldDisabled}>
        <InputLabel id="colspanLabel">Field Width</InputLabel>
        <Select
          labelid="colspanLabel"
          id="colspanSelect"
          value={props.colspan}
          onChange={props.onChangeColspan.bind(this)}
        >
          <MenuItem value="2">2 of 12 columns</MenuItem>
          <MenuItem value="4">4 of 12 columns</MenuItem>
          <MenuItem value="6">6 of 12 columns</MenuItem>
          <MenuItem value="8">8 of 12 columns</MenuItem>
          <MenuItem value="10">10 of 12 columns</MenuItem>
          <MenuItem value="12">Full row (12 columns)</MenuItem>
        </Select>
      </FormControl>
    </div>);
}
function IsReadOnlyComp(props) {
  const { classes } = props;
  return (
    <div>
      <InputLabel id="readOnlyLabel" shrink>Is Read Only</InputLabel>
      <Grid item component="label" container alignItems="center" spacing={1}>
        <Grid item>No</Grid>
        <Grid item>
          <Switch
            labelid="readOnlyLabel"
            checked={props.isReadOnly}
            onChange={props.onChangeIsReadOnly.bind(this)}
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            disabled={props.fieldDisabled}
            classes={{
              switchBase: classes.switchBase,
              checked: classes.switchChecked,
            }}
          />
        </Grid>
        <Grid item>Yes</Grid>
      </Grid>
    </div>);
}

function RequiredComp(props) {
  const { classes } = props;
  return (
    <div>
      <InputLabel id="requiredLabel" shrink>Required</InputLabel>
      <Grid item component="label" container alignItems="center" spacing={1}>
        <Grid item>No</Grid>
        <Grid item>
          <Switch
            labelid="requiredLabel"
            checked={props.required}
            onChange={props.onChangeRequired.bind(this)}
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            disabled={props.fieldDisabled}
            classes={{
              switchBase: classes.switchBase,
              checked: classes.switchChecked,
              // icon: classes.switchIcon,
              // iconChecked: classes.switchIconChecked,
              // bar: classes.switchBar
            }}
          />
        </Grid>
        <Grid item>Yes</Grid>
      </Grid>
    </div>);
}

function DataTypeComp(props) {
  const { classes } = props;
  return (
    <div>
      <FormControl fullWidth className={classes.formControl} disabled={props.fieldDisabled}>
        <InputLabel id="dataTypeLabel">Field Data Type</InputLabel>
        <Select
          labelid="dataTypeLabel"
          id="dataTypeSelect"
          value={props.fieldDataType}
          onChange={props.onChangeFieldDataType.bind(this)}
        >
          {getFieldDataType(props.type)}
        </Select>
      </FormControl>
    </div>);
}

function ErrorMessageComp(props) {
  return (
    <div>
      <TextField
        required={false}
        fullWidth
        label="Error Message"
        value={props.errorMessage}
        onChange={props.onChangeErrorMessage.bind(this)}
        disabled={props.fieldDisabled}
      />
    </div>);
}

function DefaultValueComp(props) {
  return (
    <div>
      <TextField
        required={false}
        fullWidth
        label="Default Value"
        value={props.defaultValue}
        onChange={props.onChangeDefaultValue.bind(this)}
        disabled={props.fieldDisabled}
        type={props.type === DATE ? "date" : "text"}
        rows={props.type === TEXT_AREA ? 5 : 1}
        multiline={props.type === TEXT_AREA ? true : false}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </div>);
}

function DefaultSysteDataValueComp(props) {
  const { classes } = props;
  return (
    <div>
      <FormControl fullWidth className={classes.formControl} disabled={props.fieldDisabled}>
        <InputLabel id="systemLabel">System Variable</InputLabel>
        <Select
          labelid="systemLabel"
          id="systemSelect"
          value={props.defaultValue}
          onChange={props.onChangeDefaultValue.bind(this)}
        >
          <MenuItem value="APPLICATION_TITLE">Application Title</MenuItem>
          <MenuItem value="APPLICATION_DATE_TIME">Application Date Time</MenuItem>
          <MenuItem value="FORM_NAME">Form Name</MenuItem>
          <MenuItem value="WORKFLOW_NAME">Workflow Name</MenuItem>
          <MenuItem value="WORKFLOW_ALIAS">Workflow Alias</MenuItem>
          <MenuItem value="NETWORK_NAME">Network Name</MenuItem>
        </Select>
      </FormControl>
    </div>);
}


function SelectTransactionTypeComp(props) {
  const { classes } = props;
  return (
    <div>
      <FormControl fullWidth className={classes.formControl} disabled={props.fieldDisabled}>
        <InputLabel id="transTypeLabel">Transaction Type</InputLabel>
        <Select
          labelid="transTypeLabel"
          id="transTypeSelect"
          value={props.transType}
          onChange={props.onChangeTransType.bind(this)}
        >
          <MenuItem value="ITEM_TRANS_INCREASE">Increase</MenuItem>
          <MenuItem value="ITEM_TRANS_DECREASE">Decrease</MenuItem>
          <MenuItem value="ITEM_DIRECT_UPDATE">Item Direct Update</MenuItem>
        </Select>
      </FormControl>
    </div>);
}



function RowNumComp(props) {
  const { classes } = props;
  return (
    <div>
      <FormControl fullWidth className={classes.formControl} disabled={props.fieldDisabled}>
        <InputLabel id="rowsLabel">Rows in text area</InputLabel>
        <Select
          labelid="rowsLabel"
          id="rowsSelect"
          value={props.rows}
          onChange={props.onChangeRows.bind(this)}
        >
          <MenuItem value="2">2</MenuItem>
          <MenuItem value="3">3</MenuItem>
          <MenuItem value="4">4</MenuItem>
          <MenuItem value="5">5</MenuItem>
          <MenuItem value="6">6</MenuItem>
          <MenuItem value="7">7</MenuItem>
          <MenuItem value="8">8</MenuItem>
          <MenuItem value="9">9</MenuItem>
          <MenuItem value="10">10</MenuItem>
        </Select>
      </FormControl>
    </div>);
}

function HelpTextComp(props) {
  return (
    <div>
      {props.type === HELPTEXT ?
        <DefaultEditor value={props.helpText} onChange={props.onChangeHelpText.bind(this)} />
        :
        <TextField
          required={false}
          fullWidth={true}
          label="Help Text"
          multiline={true}
          rows={"5"}
          value={props.helpText}
          onChange={props.onChangeHelpText.bind(this)}
          disabled={props.fieldDisabled}
          InputLabelProps={{
            shrink: true,
          }}
        />
      }
    </div>);
}

function AggregateFieldRuleComp(props) {
  return (
    <div>
      <InputLabel id="aggregateRuleLabel" shrink>Aggregate Number Rule</InputLabel>
      <br />
      <AggregateRuleComp
        fieldDisabled={props.fieldDisabled}
        num_fields={props.num_fields}
        setAggregateRule={props.setAggregateRule}
        field_aggregate_rule={props.field_aggregate_rule}
      />
    </div>);
}

function UpdateIntgComp(props) {
  const { classes } = props;
  return (
    <div>
      <InputLabel id="agrUpdateIntgLabel" shrink>Update Integration Field</InputLabel>
      <Grid item component="label" container alignItems="center" spacing={1}>
        <Grid item>No</Grid>
        <Grid item>
          <Switch
            labelid="agrUpdateIntgLabel"
            checked={props.updateIntg}
            onChange={props.onUpdateIntgChange.bind(this)}
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            disabled={props.fieldDisabled || parseInt(localStorage.getItem('workflow_state'), 10) > 0}
            classes={{
              switchBase: classes.switchBase,
              checked: classes.switchChecked,
              // icon: classes.switchIcon,
              // iconChecked: classes.switchIconChecked,
              // bar: classes.switchBar
            }}
          />
        </Grid>
        <Grid item>Yes</Grid>
      </Grid>
    </div>);
}

function ItemIntgFieldComp(props) {
  const { classes } = props;
  return (
    <div>
      <FormControl fullWidth className={classes.formControl} disabled={props.fieldDisabled}>
        <InputLabel id="itemIntgLabel">Select Integration Field</InputLabel>
        <Select
          labelId="itemIntgLabel"
          id="itemIntgSelect"
          value={props.intgFieldID}
          onChange={props.onChangeIntgFieldID.bind(this)}
        >
          {props.field_item_intg_list.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {props.field_item_intg_list.find((p) => p.id === item.id).field_type}
              {' '}
              -
              {props.field_item_intg_list.find((p) => p.id === item.id).field_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>);
}



function ItemRelationLevelComp(props) {
  const { classes } = props;
  return (
    <div>
      <FormControl fullWidth className={classes.formControl} disabled={props.fieldDisabled}>
        <InputLabel id="itemRelationIntgLabel">Select Item Relation Level</InputLabel>
        <Select
          labelId="itemRelationIntgLabel"
          id="itemRelationIntgSelect"
          value={props.itemRelationLevel}
          onChange={props.onChangeItemRelationLevel.bind(this)}
        >
          <MenuItem key={0} value={0}>{"Self"}</MenuItem>
          <MenuItem key={1} value={1}>{"Relative"}</MenuItem>
        </Select>
      </FormControl>
    </div>);
}

function RelatedItemSearchComp(props) {
  const { classes } = props;
  return (
    <div>
      <FormControl fullWidth className={classes.formControl} disabled={props.fieldDisabled}>
        <InputLabel id="relatedItemFieldIDLabel">Related Item Search</InputLabel>
        <Select
          labelId="relatedItemFieldIDLabel"
          id="relatedItemFieldIDSelect"
          value={props.relatedFieldID}
          onChange={props.onChangeRelatedFieldID.bind(this)}
        >
          {props.field_list.filter((obj) => (obj.type === ITEM_SEARCH || obj.type === ITEM_SEARCH2) && obj.id !== props.id).map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {props.field_list.find((p) => p.id === item.id).label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>);
}

function ValidateRuleComp(props) {
  return (
    <div>
      <InputLabel id="validationRuleLabel" shrink>Validation Rule</InputLabel>
      <ValidationRuleComp
        field_data_type={props.field_data_type}
        disabled={props.fieldDisabled}
        field_validate_rule={props.field_validate_rule}
        setValidateRule={props.setValidateRule}
      />
    </div>);
}

function RequiredFieldRuleComp(props) {
  return (
    <div>
      <InputLabel id="requiredRuleLabel" shrink>Required Rule</InputLabel>
      <RequiredRuleComp
        fields={props.fields}
        role_list={props.role_list}
        disabled={props.fieldDisabled}
        field_required_rule={props.field_required_rule}
        setRequiredRule={props.setRequiredRule}
      />
    </div>);
}

function VisibleFieldRuleComp(props) {
  return (
    <div>
      <InputLabel id="visibleRuleLabel" shrink>Visible Rule</InputLabel>
      <VisibleRuleComp
        fields={props.fields}
        role_list={props.role_list}
        disabled={props.fieldDisabled}
        field_visible_rule={props.field_visible_rule}
        setVisibleRule={props.setVisibleRule}
      />
    </div>);
}


function IntgComp(props) {
  const renderIntgTypeSelection = (intgType) => {
    switch (intgType) {
      case "1":
        return (
          <div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <UserIntgComp
                  fieldDisabled={props.fieldDisabled}
                  classes={props.classes}
                  roleID={props.roleID}
                  onChangeRoleID={props.onChangeRoleID}
                  role_list={props.role_list}
                />
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <IntegrationFieldComp
                  fieldDisabled={props.fieldDisabled}
                  classes={props.classes}
                  intgFieldID={props.intgFieldID}
                  onChangeIntgFieldID={props.onChangeIntgFieldID}
                  intg_field_List={props.intg_field_List}
                />
              </GridItem>
            </GridContainer>
          </div>

        );
      case "2":
        return (
          <div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <DepartmentComp
                  fieldDisabled={props.fieldDisabled}
                  classes={props.classes}
                  departmentID={props.departmentID}
                  onChangeDepartmentID={props.onChangeDepartmentID}
                  dept_list={props.dept_list}
                />
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <IntegrationFieldComp
                  fieldDisabled={props.fieldDisabled}
                  classes={props.classes}
                  intgFieldID={props.intgFieldID}
                  onChangeIntgFieldID={props.onChangeIntgFieldID}
                  intg_field_List={props.intg_field_List}
                />
              </GridItem>
            </GridContainer>
          </div>
        );
      case "3":
        return (
          <div>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <ItemIntgFieldComp
                  fieldDisabled={props.fieldDisabled}
                  classes={props.classes}
                  intgFieldID={props.intgFieldID}
                  onChangeIntgFieldID={props.onChangeIntgFieldID}
                  field_item_intg_list={props.field_item_intg_list}
                />
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <ItemRelationLevelComp
                  fieldDisabled={props.fieldDisabled}
                  classes={props.classes}
                  itemRelationLevel={props.itemRelationLevel}
                  onChangeItemRelationLevel={props.onChangeItemRelationLevel}
                />
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={12} md={12}>
                <RelatedItemSearchComp
                  fieldDisabled={props.fieldDisabled}
                  classes={props.classes}
                  id={props.id}
                  relatedFieldID={props.relatedFieldID}
                  onChangeRelatedFieldID={props.onChangeRelatedFieldID}
                  field_list={props.field_list}
                />
              </GridItem>
            </GridContainer>
            <br />
          </div>
        );
      case "0":
        return (
          <div></div>
        );
      default:
        return null;
    }
  };
  const { classes } = props;
  return (
    <div>
      <FormControl fullWidth className={classes.formControl} disabled={props.fieldDisabled}>
        <FormLabel id="intgType">Select Integration Type</FormLabel>
        <RadioGroup
          aria-label="radio"
          name="radio"
          value={props.intgType}
          onChange={props.onChangeIntgType.bind(this)}
        >
          <FormControlLabel
            key="none"
            control={<Radio id="none" color="primary" />}
            label="User Entered Data"
            value={"0"}
          />
          <FormControlLabel
            key="user"
            control={<Radio id="user" color="primary" />}
            label="User Integration Data"
            value={"1"}
          />
          <FormControlLabel
            key="dept"
            control={<Radio id="dept" color="primary" />}
            label="Department Integration Data"
            value={"2"}
          />
          <FormControlLabel
            key="item"
            control={<Radio id="item" color="primary" />}
            label="Item Integration Data"
            value={"3"}
          />
        </RadioGroup>
      </FormControl>
      <br />
      <br />
      {renderIntgTypeSelection(props.intgType)}
      <br />
    </div>);
}


function UserIntgComp(props) {
  const { classes } = props;
  return (
    <div>
      <FormControl fullWidth className={classes.formControl} disabled={props.fieldDisabled}>
        <InputLabel id="roleIntgLabel">Role / Department / User</InputLabel>
        <Select
          labelId="roleIntgLabel"
          id="roleIntgSelect"
          value={props.roleID}
          onChange={props.onChangeRoleID.bind(this)}
        >
          {props.role_list.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {props.role_list.find((p) => p.id === item.id).alias === '' ? props.role_list.find((p) => p.id === item.id).title : `${props.role_list.find((p) => p.id === item.id).title} (${props.role_list.find((p) => p.id === item.id).alias})`}
              {props.role_list.find((p) => p.id === item.id).department_name !== '' ? ` / ${props.role_list.find((p) => p.id === item.id).department_name}` : ''}
              {props.role_list.find((p) => p.id === item.id).user_name !== '' ? ` / ${props.role_list.find((p) => p.id === item.id).user_name}` : ''}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>);
}


function IntegrationFieldComp(props) {
  const { classes } = props;
  return (
    <div>
      <FormControl fullWidth className={classes.formControl} disabled={props.fieldDisabled}>
        <InputLabel id="userIntgLabel">Select Integration Field</InputLabel>
        <Select
          labelId="userIntgLabel"
          id="userIntgSelect"
          value={props.intgFieldID}
          onChange={props.onChangeIntgFieldID.bind(this)}
        >
          {props.intg_field_List.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {props.intg_field_List.find((p) => p.id === item.id).field_type}
              {' '}
              -
              {props.intg_field_List.find((p) => p.id === item.id).field_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>);
}

function DatatableRuleFieldComp(props) {
  return (
    <div>
      <InputLabel id="intgFieldsLabel" shrink>Datatable Rule1</InputLabel>
      <DatatableRuleComp
        // fields={props.field_list.filter((obj) => obj.id !== id)}
        field_item_intg_list={props.field_item_intg_list}
        disabled={props.fieldDisabled}
        setDatatableRule={props.setDatatableRule}
        field_datatable_rule={props.field_datatable_rule}
        datatable_rule_array_index={0}

      />
      <br />
      <br />
      <InputLabel id="intgFieldsLabel" shrink>Datatable Rule2</InputLabel>
      <DatatableRuleComp
        // fields={props.field_list.filter((obj) => obj.id !== id)}
        field_item_intg_list={props.field_item_intg_list}
        disabled={props.fieldDisabled}
        setDatatableRule={props.setDatatableRule}
        field_datatable_rule={props.field_datatable_rule}
        datatable_rule_array_index={1}

      />
      <br />
      <br />
      <InputLabel id="intgFieldsLabel" shrink>Datatable Rule3</InputLabel>
      <DatatableRuleComp
        // fields={props.field_list.filter((obj) => obj.id !== id)}
        field_item_intg_list={props.field_item_intg_list}
        disabled={props.fieldDisabled}
        setDatatableRule={props.setDatatableRule}
        field_datatable_rule={props.field_datatable_rule}
        datatable_rule_array_index={2}

      />
    </div>);
}


function ItemSearchTypeComp(props) {
  const { classes } = props;
  return (
    <div>
      <FormControl fullWidth className={classes.formControl} disabled={props.fieldDisabled}>
        <InputLabel id="itemSearchTypeLabel">Item Search Type</InputLabel>
        <Select
          labelId="itemSearchTypeLabel"
          id="itemSearchTypeSelect"
          value={props.itemSearchType}
          onChange={props.onChangeItemSearchType.bind(this)}
        >
          <MenuItem key={"0"} value={"0"}>All</MenuItem>
          <MenuItem key={"1"} value={"1"}>Exclude</MenuItem>
        </Select>
      </FormControl>
    </div>);
}

function SearchTableComp(props) {
  const { classes } = props;
  let itemAlias = localStorage.getItem("network_item_alias")
  const itemAliasArray = itemAlias.split(" ");
  return (
    <div>
      <FormControl fullWidth className={classes.formControl} disabled={props.fieldDisabled}>
        <InputLabel id="searchTableLabel">Search Table</InputLabel>
        <Select
          labelId="searchTableLabel"
          id="searchTableSelect"
          value={props.searchTable}
          onChange={props.onChangeSearchTable.bind(this)}
        >
          <MenuItem key={"10"} value={"10"}>User</MenuItem>
          <MenuItem key={"20"} value={"20"}>Department</MenuItem>
          {itemAliasArray.map((item, i) => (
            <MenuItem key={30 + i} value={30 + i}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>);
}

function SearchTableRelatedComp(props) {
  const { classes } = props;
  let itemAlias = localStorage.getItem("network_item_alias")
  const itemAliasArray = itemAlias.split(" ");
  return (
    <div>
      <FormControl fullWidth className={classes.formControl} disabled={props.fieldDisabled}>
        <InputLabel id="searchTableRelatedLabel">Search Table Related</InputLabel>
        <Select
          labelId="searchTableRelatedLabel"
          id="searchTableRelatedSelect"
          value={props.searchTableRelated}
          onChange={props.onChangeSearchTableRelated.bind(this)}
        >
          {itemAliasArray.map((item, i) => (
            <MenuItem key={30 + i} value={30 + i}>{item}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>);
}

function DepartmentComp(props) {
  const { classes } = props;
  return (
    <div>
      <FormControl fullWidth className={classes.formControl} disabled={props.fieldDisabled}>
        <InputLabel id="deptLabel">Select Department</InputLabel>
        <Select
          labelId="deptLabel"
          id="deptSelect"
          value={props.departmentID}
          onChange={props.onChangeDepartmentID.bind(this)}
        >
          {props.dept_list.map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {props.dept_list.find((p) => p.id === item.id).name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>);
}


function OptionsFieldComp(props) {
  const { classes } = props;
  return (
    <div>
      <OptionsComp
        options={props.options}
        optionsDefaultValue={props.optionsDefaultValue}
        setOptions={props.setOptions}
        setOptionsDefaultValue={props.setOptionsDefaultValue}
        option_intg_field_id={props.option_intg_field_id}
        setOptionsIntgFieldID={props.setOptionsIntgFieldID}
        disabled={props.fieldDisabled}
        field_data_type={props.fieldDataType}
        type={props.type}
        field_cust_intg_list={props.field_cust_intg_list}
      />
    </div>);
}


function UseAsVariableComp(props) {
  const { classes } = props;
  return (
    <div>
      <InputLabel id="requiredFrmVar" shrink>Use as Variable</InputLabel>
      <Grid item component="label" container alignItems="center" spacing={1}>
        <Grid item>No</Grid>
        <Grid item>
          <Switch
            labelid="requiredFrmVar"
            checked={props.useAsFormVar}
            onChange={props.onUseAsFormVarChange.bind(this)}
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox' }}
            disabled={props.fieldDisabled}
            classes={{
              switchBase: classes.switchBase,
              checked: classes.switchChecked,
            }}
          />
        </Grid>
        <Grid item>Yes</Grid>
      </Grid>
    </div>);
}

function UseAsVariableNameComp(props) {
  return (
    <div>
      <TextField
        required={false}
        fullWidth
        label="Variable Name"
        value={props.name}
        onChange={props.onChangeName.bind(this)}
        disabled={props.fieldDisabled}
      />
    </div>);
}


function RelatedFieldComp(props) {
  const { classes } = props;
  return (
    <div>
      <FormControl fullWidth className={classes.formControl} disabled={props.fieldDisabled}>
        <InputLabel id="relatedFieldIDLabel">Related Field</InputLabel>
        <Select
          labelId="relatedFieldIDLabel"
          id="relatedFieldIDSelect"
          value={props.relatedFieldID}
          onChange={props.onChangeRelatedFieldID.bind(this)}
        >
          <MenuItem key={""} value={""}> No Related Field </MenuItem>
          {props.field_list.filter((obj) => (obj.type !== CHECK_BOX) && (obj.type !== ITEM_SEARCH) && obj.id !== props.id).map((item) => (
            <MenuItem key={item.id} value={item.id}>
              {`${item.form_name}->${item.label}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>);
}



export {
  LabelComp, AppListCustomIndexComp, SortComp, AliasComp, TagComp, CommitteeComp, ColspanComp,
  IsReadOnlyComp, RequiredComp,
  DataTypeComp, ErrorMessageComp, DefaultValueComp, DefaultSysteDataValueComp, RowNumComp,
  HelpTextComp, AggregateFieldRuleComp, UpdateIntgComp, ItemIntgFieldComp,
  ItemRelationLevelComp, RelatedItemSearchComp, ValidateRuleComp,
  RequiredFieldRuleComp, VisibleFieldRuleComp, IntgComp, UserIntgComp,
  IntegrationFieldComp, DatatableRuleFieldComp, SelectTransactionTypeComp,
  ItemSearchTypeComp, SearchTableComp, SearchTableRelatedComp, DepartmentComp, OptionsFieldComp,
  UseAsVariableComp, UseAsVariableNameComp, RelatedFieldComp
}




const getFieldDataType = (value) => {
  var rtnComp = [];
  switch (value) {
    case TEXT_FIELD:
      rtnComp.push(<MenuItem value={INTEGER_NUMBER}>Integer Number</MenuItem>);
      rtnComp.push(<MenuItem value={DECIMAL_NUMBER}>Decimal Number</MenuItem>);
      rtnComp.push(<MenuItem value={STRING}>String</MenuItem>);
      rtnComp.push(<MenuItem value={DATE_STRING}>Date</MenuItem>);
      rtnComp.push(<MenuItem value={EMAIL_STRING}>Email</MenuItem>);
      rtnComp.push(<MenuItem value={NONE}>None</MenuItem>);
      break;
    case AGGREGATE_NUM:
      rtnComp.push(<MenuItem value={DECIMAL_NUMBER}>Decimal Number</MenuItem>);
      rtnComp.push(<MenuItem value={NONE}>None</MenuItem>);
      break;
    case TEXT_AREA:
      rtnComp.push(<MenuItem value={NONE}>None</MenuItem>);
      break;
    case DROP_DOWN:
      rtnComp.push(<MenuItem value={INTEGER_NUMBER}>Integer Number</MenuItem>);
      rtnComp.push(<MenuItem value={DECIMAL_NUMBER}>Decimal Number</MenuItem>);
      rtnComp.push(<MenuItem value={STRING}>String</MenuItem>);
      rtnComp.push(<MenuItem value={NONE}>None</MenuItem>);
      break;
    case RADIO_BUTTON:
      rtnComp.push(<MenuItem value={INTEGER_NUMBER}>Integer Number</MenuItem>);
      rtnComp.push(<MenuItem value={DECIMAL_NUMBER}>Decimal Number</MenuItem>);
      rtnComp.push(<MenuItem value={STRING}>String</MenuItem>);
      rtnComp.push(<MenuItem value={NONE}>None</MenuItem>);
      break;
    case CHECK_BOX:
      rtnComp.push(<MenuItem value={NONE}>None</MenuItem>);
      break;
    case DATE:
      rtnComp.push(<MenuItem value={DATE_STRING}>Date</MenuItem>);
      rtnComp.push(<MenuItem value={NONE}>None</MenuItem>);
      break;
    case WEEK_CALENDAR:
      rtnComp.push(<MenuItem value={DATE_STRING}>Date</MenuItem>);
      rtnComp.push(<MenuItem value={NONE}>None</MenuItem>);
      break;
    case TIME:
      rtnComp.push(<MenuItem value={NONE}>None</MenuItem>);
      break;
    case HELPTEXT:
      rtnComp.push(<MenuItem value={NONE}>None</MenuItem>);
      break;
    case COMMITTEE:
      rtnComp.push(<MenuItem value={NONE}>None</MenuItem>);
      break;
    case FILE_SHARING:
      rtnComp.push(<MenuItem value={NONE}>None</MenuItem>);
      break;
    case TRANS_TEXT_FIELD:
      rtnComp.push(<MenuItem value={INTEGER_NUMBER}>Integer Number</MenuItem>);
      rtnComp.push(<MenuItem value={DECIMAL_NUMBER}>Decimal Number</MenuItem>);
      rtnComp.push(<MenuItem value={DATE_STRING}>Date</MenuItem>);
      break;
    default:
  }
  return rtnComp;
};