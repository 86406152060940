import API from 'Api/api.js';
import { commonBackendCall, getNetworkAdminConfig } from 'Api/common.js';
import {
  GET_DEPT_CSV_REQUEST,
  GET_DEPT_CSV_SUCCESS,
  GET_DEPT_CSV_FAILURE,
  GET_DEPARTMENT_REQUEST,
  GET_DEPARTMENT_SUCCESS,
  GET_DEPARTMENT_FAILURE,
  GET_DEPT_INTG_REQUEST,
  GET_DEPT_INTG_SUCCESS,
  GET_DEPT_INTG_FAILURE,
  POST_DEPT_REQUEST,
  POST_DEPT_SUCCESS,
  POST_DEPT_FAILURE,
  PATCH_DEPT_REQUEST,
  PATCH_DEPT_SUCCESS,
  PATCH_DEPT_FAILURE,
  DELETE_DEPT_REQUEST,
  DELETE_DEPT_SUCCESS,
  DELETE_DEPT_FAILURE,
  PATCH_DEPT_INTG_REQUEST,
  PATCH_DEPT_INTG_SUCCESS,
  PATCH_DEPT_INTG_FAILURE,
} from 'constants/networkadmin/Department';

export function getDepartmentsCSV() {
  return commonBackendCall(
    GET_DEPT_CSV_REQUEST,
    GET_DEPT_CSV_SUCCESS,
    GET_DEPT_CSV_FAILURE,
    API.get('admin/departments/csv', getNetworkAdminConfig()),
  );
}
export function getDepartments() {
  return commonBackendCall(
    GET_DEPARTMENT_REQUEST,
    GET_DEPARTMENT_SUCCESS,
    GET_DEPARTMENT_FAILURE,
    API.get('admin/networkadmin-departments', getNetworkAdminConfig()),
  );
}

export function getDeptIntgs(deptID) {
  return commonBackendCall(
    GET_DEPT_INTG_REQUEST,
    GET_DEPT_INTG_SUCCESS,
    GET_DEPT_INTG_FAILURE,
    API.get(`admin/departments/intg/${deptID}`, getNetworkAdminConfig()),
  );
}

export function createDepartment(obj) {
  return commonBackendCall(
    POST_DEPT_REQUEST,
    POST_DEPT_SUCCESS,
    POST_DEPT_FAILURE,
    API.post('admin/departments', obj, getNetworkAdminConfig()),
  );
}

export function updateDepartment(deptID, obj) {
  return commonBackendCall(
    PATCH_DEPT_REQUEST,
    PATCH_DEPT_SUCCESS,
    PATCH_DEPT_FAILURE,
    API.patch(`admin/departments/${deptID}`, obj, getNetworkAdminConfig()),
  );
}

export function deleteDepartment(deptID) {
  return commonBackendCall(
    DELETE_DEPT_REQUEST,
    DELETE_DEPT_SUCCESS,
    DELETE_DEPT_FAILURE,
    API.delete(`admin/departments/${deptID}`, getNetworkAdminConfig()),
  );
}

export function updateDeptIntgFieldValue(deptIntgID, obj) {
  return commonBackendCall(
    PATCH_DEPT_INTG_REQUEST,
    PATCH_DEPT_INTG_SUCCESS,
    PATCH_DEPT_INTG_FAILURE,
    API.patch(`admin/departments/intg/${deptIntgID}`, obj, getNetworkAdminConfig()),
  );
}