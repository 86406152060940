export const GET_CUSTOM_INTG_REQUEST = 'GET_CUSTOM_INTG_REQUEST';
export const GET_CUSTOM_INTG_SUCCESS = 'GET_CUSTOM_INTG_SUCCESS';
export const GET_CUSTOM_INTG_FAILURE = 'GET_CUSTOM_INTG_FAILURE';
export const POST_CUSTOM_INTG_REQUEST = 'POST_CUSTOM_INTG_REQUEST';
export const POST_CUSTOM_INTG_SUCCESS = 'POST_CUSTOM_INTG_SUCCESS';
export const POST_CUSTOM_INTG_FAILURE = 'POST_CUSTOM_INTG_FAILURE';
export const PATCH_CUSTOM_INTG_REQUEST = 'PATCH_CUSTOM_INTG_REQUEST';
export const PATCH_CUSTOM_INTG_SUCCESS = 'PATCH_CUSTOM_INTG_SUCCESS';
export const PATCH_CUSTOM_INTG_FAILURE = 'PATCH_CUSTOM_INTG_FAILURE';
export const DELETE_CUSTOM_INTG_REQUEST = 'DELETE_CUSTOM_INTG_REQUEST';
export const DELETE_CUSTOM_INTG_SUCCESS = 'DELETE_CUSTOM_INTG_SUCCESS';
export const DELETE_CUSTOM_INTG_FAILURE = 'DELETE_CUSTOM_INTG_FAILURE';
export const GET_CUSTOM_CSV_REQUEST = 'GET_CUSTOM_CSV_REQUEST';
export const GET_CUSTOM_CSV_SUCCESS = 'GET_CUSTOM_CSV_SUCCESS';
export const GET_CUSTOM_CSV_FAILURE = 'GET_CUSTOM_CSV_FAILURE';