export const GET_PHASE_REQUEST = 'GET_PHASE_REQUEST';
export const GET_PHASE_SUCCESS = 'GET_PHASE_SUCCESS';
export const GET_PHASE_FAILURE = 'GET_PHASE_FAILURE';
export const POST_PHASE_REQUEST = 'POST_PHASE_REQUEST';
export const POST_PHASE_SUCCESS = 'POST_PHASE_SUCCESS';
export const POST_PHASE_FAILURE = 'POST_PHASE_FAILURE';
export const PATCH_PHASE_REQUEST = 'PATCH_PHASE_REQUEST';
export const PATCH_PHASE_SUCCESS = 'PATCH_PHASE_SUCCESS';
export const PATCH_PHASE_FAILURE = 'PATCH_PHASE_FAILURE';
export const DELETE_PHASE_REQUEST = 'DELETE_PHASE_REQUEST';
export const DELETE_PHASE_SUCCESS = 'DELETE_PHASE_SUCCESS';
export const DELETE_PHASE_FAILURE = 'DELETE_PHASE_FAILURE';
