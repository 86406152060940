import React from 'react';

import Login from 'views/admin/Login.jsx';
import Network from 'views/Network.jsx';
import ChooseWorkflow from 'views/admin/ChooseWorkflow.jsx';
import ChangeWorkflow from 'views/admin/ChangeWorkflow.jsx';

const AdminPublicRoutes = [
  {
    path: '/admin/public/network',
    sidebarName: 'Table List',
    navbarName: 'Table List',
    icon: 'content_paste',
    component: <Network />,
  },
  {
    path: '/admin/public/login/:workflow_id',
    sidebarName: 'Table List',
    navbarName: 'Table List',
    icon: 'content_paste',
    component: <Login />,
  },
  {
    path: '/admin/public/login',
    sidebarName: 'Table List',
    navbarName: 'Table List',
    icon: 'content_paste',
    component: <Login />,
  },
  {
    path: '/admin/public/choose-workflow',
    sidebarName: 'Table List',
    navbarName: 'Table List',
    icon: 'content_paste',
    component: <ChooseWorkflow />,
  },
  {
    path: '/admin/public/change-workflow',
    sidebarName: 'Table List',
    navbarName: 'Table List',
    icon: 'content_paste',
    component: <ChangeWorkflow />,
  },
];

export default AdminPublicRoutes;
