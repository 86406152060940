import API from 'Api/api.js';
import { commonBackendCall, getAdminConfig } from 'Api/common.js';
import {
  GET_ADMIN_DASH_REQUEST,
  GET_ADMIN_DASH_SUCCESS,
  GET_ADMIN_DASH_FAILURE,
  PATCH_WORKFLOW_STATE_REQUEST,
  PATCH_WORKFLOW_STATE_SUCCESS,
  PATCH_WORKFLOW_STATE_FAILURE,
  GET_SENT_EMAIL_ADMIN_REQUEST,
  GET_SENT_EMAIL_ADMIN_SUCCESS,
  GET_SENT_EMAIL_ADMIN_FAILURE,
} from 'constants/admin/Dashboard';

export function getAdminDashboard() {
  return commonBackendCall(
    GET_ADMIN_DASH_REQUEST,
    GET_ADMIN_DASH_SUCCESS,
    GET_ADMIN_DASH_FAILURE,
    API.get('admin/dashboard-admins', getAdminConfig()),
  );
}

export function updateWorkflowState(workflowID, obj) {
  return commonBackendCall(
    PATCH_WORKFLOW_STATE_REQUEST,
    PATCH_WORKFLOW_STATE_SUCCESS,
    PATCH_WORKFLOW_STATE_FAILURE,
    API.patch(`admin/workflows-state/${workflowID}`, obj, getAdminConfig()),
  );
}

export function getSentAdminEmailList(searchStr, pageSize, pageNum, orderby, sort) {
	return commonBackendCall(
    GET_SENT_EMAIL_ADMIN_REQUEST,
    GET_SENT_EMAIL_ADMIN_SUCCESS,
    GET_SENT_EMAIL_ADMIN_FAILURE,
		API.get(`admin/sent-email-admins?search=${searchStr}&page=${pageNum}&limit=${pageSize}&orderby=${orderby}&sort=${sort}`, getAdminConfig()),
	);
}