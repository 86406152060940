import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as applicationsListActions from 'actions/ApplicationsListActions';
import * as defaultActions from 'actions/DefaultActions';
import * as lookupActions from 'actions/LookupActions';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import ApplicationsListComp from './ApplicationsList/ApplicationsListComp';

const ApplicationsList = (props) => {
  const { classes } = props;
  const { applicationsList, defaultStates, lookup } = props;
  const {
    isAllowedToCreateApplication,
    createApplication,
    updateApplication,
    getActiveApplications,
    getArchiveApplications,
    resetApplicationDetail,
    getApplicationDetailFormWithoutApp,
    processMultipleApp,
  } = props.applicationsListActions;
  const { getWorkflowByUser } = props.lookupActions;

  return (
    <div>
      {defaultStates.unDefinedError
        ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <ApplicationsListComp
        processMultipleApp={processMultipleApp}
        getApplicationDetailFormWithoutApp={getApplicationDetailFormWithoutApp}
        getWorkflowByUser={getWorkflowByUser}
        workflow={lookup.workflow === undefined ? [] : lookup.workflow}
        isAllowedToCreateApplication={isAllowedToCreateApplication}
        createApplication={createApplication}
        updateApplication={updateApplication}
        resetApplicationDetail={resetApplicationDetail}
        getActiveApplications={getActiveApplications}
        getArchiveApplications={getArchiveApplications}
        active_application_list={applicationsList.active_application_list.list === undefined ? [] : applicationsList.active_application_list.list}
        active_application_count={applicationsList.active_application_list.count === undefined ? 0 : applicationsList.active_application_list.count}
        archive_application_list={applicationsList.archive_application_list.list === undefined ? [] : applicationsList.archive_application_list.list}
        archive_application_count={applicationsList.archive_application_list.count === undefined ? 0 : applicationsList.archive_application_list.count}
        refreshApplication={applicationsList.refreshApplication}
        newApplicationID={applicationsList.newApplicationID}
        application_detail_form_without_app={applicationsList.application_detail_form_without_app}
        isAllowed={applicationsList.isAllowed === '' ? false : applicationsList.isAllowed.is_allowed}
        requesting={applicationsList.requesting}
        app_create_with_detail_msg={applicationsList.app_create_with_detail_msg}
        message={applicationsList.message}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    applicationsList: state.applicationsList,
    defaultStates: state.defaultStates,
    lookup: state.lookup,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    applicationsListActions: bindActionCreators(applicationsListActions, dispatch),
    defaultActions: bindActionCreators(defaultActions, dispatch),
    lookupActions: bindActionCreators(lookupActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationsList);
