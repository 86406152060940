import React, { useState, useEffect } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import MUIDataTable from "mui-datatables";
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from "components/Card/CardFooter.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.jsx';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@material-ui/core/Select';
import UserSearch from 'components/UserSearch/UserSearch.jsx';
import extendedFormsStyle from "assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx"

const AdminComp = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogButton, setDialogButton] = useState("");
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [dialogType, setDialogType] = useState("add");
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [id, setID] = useState("");
  const [showUserSearch, setShowUserSearch] = useState(false)
  const [selectedUserNameEmail, setSelectedUserNameEmail] = useState("")
  const [selectedUserID, setSelectedUserID] = useState("")
  const [workflowID, setWorkflowID] = useState('')

  const { getWorkflows, getAdmins } = props;
  useEffect(() => {
    getWorkflows();
    getAdmins();
  }, [getWorkflows, getAdmins])

  useEffect(() => {
    setMessage(props.message)
    if (props.refreshAdmin) {
      props.getAdmins();
      setDialogOpen(false);
    }
    var rowItems = [];
    for (let i = 0; i < props.admin_list.length; i++) {
      if (props.admin_list[i].name !== "" && props.workflow_list.length > 0) {
        var colItems = [];
        colItems.push(i + 1);
        colItems.push(props.admin_list[i].user_name + " (" + props.admin_list[i].user_email + ")");
        var workflowObj = props.workflow_list.find(o => o.id === props.admin_list[i].workflow_id);
        colItems.push(workflowObj.name);
        colItems.push(<div>
          <IconButton onClick={handleDeleteClick.bind(this,
            [props.admin_list[i].id,
            props.admin_list[i].workflow_id,
            props.admin_list[i].user_id,
            props.admin_list[i].user_id + " " +
            props.admin_list[i].user_name + " (" +
            props.admin_list[i].user_email + ")",
            ])} title="Delete row"> <DeleteIcon /></IconButton>
        </div>);
        rowItems.push(colItems);
      }
    }
    setData(rowItems)
    if (props.selected_user_id !== 0) {
      setSelectedUserNameEmail(props.selected_user)
      setSelectedUserID(props.selected_user_id)
      setShowUserSearch(false)
    }

  }, [props])

  const handleDeleteClick = (e) => {
    setID(e[0])
    setWorkflowID(e[1])
    setSelectedUserID(e[2])
    setSelectedUserNameEmail(e[3])
    setMessage('')
    setDialogTitle('Delete')
    setDialogButton('Confirm Delete')
    setFieldDisabled(true)
    setDialogType('delete')
    setDialogOpen(true)
  }
  const handleNewClick = (e) => {
    setWorkflowID('')
    setSelectedUserID()
    setSelectedUserNameEmail('')
    setMessage('')
    setDialogTitle('Add New')
    setDialogButton('Save')
    setFieldDisabled(false)
    setDialogType('add')
    setDialogOpen(true)
  }
  const handleDialogClose = (e) => {
    setDialogOpen(false)
    setMessage('')
  }
  const handleDialogConfirm = (e) => {
    if (dialogType === 'add') {
      props.createAdmin({
        workflow_id: workflowID.toString(),
        user_id: selectedUserID.toString()
      })
    } else if (dialogType === 'delete') {
      props.deleteAdmin(id)
    }
  }




  const onWorkflowIDChange = (e) => {
    setWorkflowID(e.target.value)
  }
  const handleShowSearchClick = (e) => {
    props.setSelectedUser("", 0)
    setMessage("")
    setShowUserSearch(true)
  }

  const handleRemoveUserClick = (e) => {
    setSelectedUserNameEmail("")
    setSelectedUserID("")
  }

  const { classes } = props;
  const columns = [
    "Serial",
    "User",
    "Workflow",
    ""
  ];
  const options = {
    filterType: "dropdown",
    responsive: "standard",
    filter: true,
    selectableRows: "none",
    rowsPerPage: 100,
    selectToolbarPlacement: "none"
  };


  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Admin</b>
        </CardHeader>
        <CardBody>
          <MUIDataTable
            title={<Button onClick={handleNewClick.bind(this)} color="primary" title="Create a new Admin" round > New Admin </Button>}
            data={data}
            columns={columns}
            options={options}
          />
          <Dialog fullWidth={true} open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              <Card>
                <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                  {dialogTitle}
                </CardHeader>
                <CardBody>
                  <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl fullWidth={true} className={classes.formControl} disabled={fieldDisabled}>
                        <InputLabel id="workflowIdLabel">Workflow</InputLabel>
                        <Select
                          labelId="workflowIdLabel"
                          id="workflowIdSelect"
                          value={workflowID}
                          onChange={onWorkflowIDChange.bind(this)}
                        >
                          {props.workflow_list.map(item => (
                            < MenuItem key={item.id} value={item.id} >
                              {props.workflow_list.find(p => p.id === item.id).name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <div style={{ display: !showUserSearch ? "block" : "none" }} >
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={10}>
                        <TextField
                          required={false}
                          fullWidth={true}
                          label="Selected User"
                          onClick={handleShowSearchClick.bind(this)}
                          value={selectedUserNameEmail}
                          disabled={true}
                          InputProps={{ readOnly: true }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={1}>
                        <IconButton
                          onClick={handleShowSearchClick.bind(this)}
                          title="Change User">
                          <EditIcon />
                        </IconButton>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={1}>
                        <IconButton
                          onClick={handleRemoveUserClick.bind(this)}
                          title="Remove User">
                          <DeleteIcon />
                        </IconButton>
                      </GridItem>
                    </GridContainer>
                  </div>
                  <div style={{ display: showUserSearch ? "block" : "none" }} >
                    <UserSearch user_type="networkadmin" />
                  </div>

                </CardBody>
                <CardFooter style={{ textAlign: 'left' }}>
                </CardFooter>
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose.bind(this)} color="primary" round>
                Cancel
              </Button>
              <Button onClick={handleDialogConfirm.bind(this)} color="primary" round>
                {dialogButton}
              </Button>
            </DialogActions>
          </Dialog>
        </CardBody>
      </Card>
    </div>
  );
}
export default withStyles(extendedFormsStyle)(AdminComp);