import React, { useState, useEffect } from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import MUIDataTable from 'mui-datatables';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.jsx';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@material-ui/core/Select';
import extendedFormsStyle from 'assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx';

const AllowCreateAppComp = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogButton, setDialogButton] = useState('');
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [dialogType, setDialogType] = useState('add');
  const [data, setData] = useState([]);
  const [message, setMessage] = useState('');
  const [id, setID] = useState('');
  const [departmentID, setDepartmentID] = useState('');

  const { getDepartments, getAllowAppCreateRole, getRolesExcludeAdhocAndGeneric } = props;
  useEffect(() => {
    getAllowAppCreateRole();
    //getRolesExcludeAdhocAndGeneric();
    getDepartments();
  }, [getDepartments, getAllowAppCreateRole, getRolesExcludeAdhocAndGeneric]);
  const handleDeleteClick = (e) => {
    setID(e[0]);
    setDepartmentID(e[1]);
    setMessage('');
    setDialogTitle('Delete');
    setDialogButton('Confirm Delete');
    setFieldDisabled(true);
    setDialogType('delete');
    setDialogOpen(true);
  };
  const handleNewClick = (e) => {
    setDepartmentID();
    setMessage('');
    setDialogTitle('Add New');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('add');
    setDialogOpen(true);
  };
  useEffect(() => {
    setMessage(props.message);
    if (props.refreshAllowAppCreate) {
      props.getAllowAppCreateRole();
      setDialogOpen(false);
    }
    var rowItems = [];
    for (let i = 0; i < props.allow_app_create_list.length; i++) {
      var colItems = [];
      colItems.push(i + 1);
      colItems.push(props.allow_app_create_list[i].department_name);
      colItems.push(props.allow_app_create_list[i].user_name);
      colItems.push(props.allow_app_create_list[i].user_email);
      colItems.push(
        <div>
          <IconButton
            onClick={handleDeleteClick.bind(this,
              [props.allow_app_create_list[i].id,
                props.allow_app_create_list[i].department_id,
              ])}
            title="Delete row"
          >
            {' '}
            <DeleteIcon />
          </IconButton>
        </div>,
      );
      rowItems.push(colItems);
    }
    setData(rowItems);
  }, [props]);

  const handleDialogClose = (e) => {
    setDialogOpen(false);
    setMessage('');
  };
  const handleDialogConfirm = (e) => {
    if (dialogType === 'add') {
      props.createAllowAppCreateRole({
        department_id: departmentID.toString(),
      });
    } else if (dialogType === 'delete') {
      props.deleteAllowAppCreateRole(id);
    }
  };
  const onDepartmentIDChange = (e) => {
    setDepartmentID(e.target.value);
  };

  const { classes } = props;
  const columns = [
    'Serial',
    'Department',
    'Head Name',
    'Head Email',
    '',
  ];
  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    filter: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    selectToolbarPlacement: 'none',
  };
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Deparment to allow to create new application</b>
        </CardHeader>
        <CardBody>
          <MUIDataTable
            title={<Button onClick={handleNewClick.bind(this)} color="primary" title="Select Department to allow to create new application in this workflow" round> Select Department to allow to create new application in this workflow </Button>}
            data={data}
            columns={columns}
            options={options}
          />
          <Dialog fullWidth open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              <Card>
                <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                  {dialogTitle}
                </CardHeader>
                <CardBody>
                  <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl fullWidth className={props.classes.formControl} disabled={fieldDisabled || parseInt(localStorage.getItem('workflow_state'), 10) > 0}>
                        <InputLabel id="departmentLabel">Department</InputLabel>
                        <Select
                          labelid="departmentLabel"
                          id="departmentSelect"
                          value={departmentID}
                          onChange={onDepartmentIDChange.bind(this)}
                        >
                          {props.department_list.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {props.department_list.find((p) => p.id === item.id).alias === ''
                                ? props.department_list.find((p) => p.id === item.id).name 
                                : `${props.department_list.find((p) => p.id === item.id).name
                                } (${props.department_list.find((p) => p.id === item.id).alias})`} ({props.department_list.find((p) => p.id === item.id).user_name})
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter style={{ textAlign: 'left' }} />
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose.bind(this)} color="primary" round>
                Cancel
              </Button>
              <Button onClick={handleDialogConfirm.bind(this)} color="primary" round>
                {dialogButton}
              </Button>
            </DialogActions>
          </Dialog>
        </CardBody>
      </Card>
    </div>
  );
};
export default withStyles(extendedFormsStyle)(AllowCreateAppComp);
