export const GET_WORKFLOW_REQUEST = 'GET_WORKFLOW_REQUEST';
export const GET_WORKFLOW_SUCCESS = 'GET_WORKFLOW_SUCCESS';
export const GET_WORKFLOW_FAILURE = 'GET_WORKFLOW_FAILURE';
export const PATCH_WORKFLOW_REQUEST = 'PATCH_WORKFLOW_REQUEST';
export const PATCH_WORKFLOW_SUCCESS = 'PATCH_WORKFLOW_SUCCESS';
export const PATCH_WORKFLOW_FAILURE = 'PATCH_WORKFLOW_FAILURE';
export const POST_WORKFLOW_VERSION_REQUEST = 'POST_WORKFLOW_VERSION_REQUEST';
export const POST_WORKFLOW_VERSION_SUCCESS = 'POST_WORKFLOW_VERSION_SUCCESS';
export const POST_WORKFLOW_VERSION_FAILURE = 'POST_WORKFLOW_VERSION_FAILURE';
export const POST_WORKFLOW_VERSION_UPLOAD_REQUEST = 'POST_WORKFLOW_VERSION_UPLOAD_REQUEST';
export const POST_WORKFLOW_VERSION_UPLOAD_SUCCESS = 'POST_WORKFLOW_VERSION_UPLOAD_SUCCESS';
export const POST_WORKFLOW_VERSION_UPLOAD_FAILURE = 'POST_WORKFLOW_VERSION_UPLOAD_FAILURE';
