import API from 'Api/api.js';
import { commonBackendCall, getAdminConfig } from 'Api/common.js';
import {
  GET_FIELD_REQUEST,
  GET_FIELD_SUCCESS,
  GET_FIELD_FAILURE,
  GET_FORM_FIELD_REQUEST,
  GET_FORM_FIELD_SUCCESS,
  GET_FORM_FIELD_FAILURE,
  POST_FIELD_REQUEST,
  POST_FIELD_SUCCESS,
  POST_FIELD_FAILURE,
  PATCH_FIELD_REQUEST,
  PATCH_FIELD_SUCCESS,
  PATCH_FIELD_FAILURE,
  DELETE_FIELD_REQUEST,
  DELETE_FIELD_SUCCESS,
  DELETE_FIELD_FAILURE,
  SET_OPTIONS,
  SET_OPTIONS_DEFAULT_VALUE,
  SET_OPTIONS_INTG_FIELD_ID,
  SET_FIELD_AGGREGATE_RULE,
  SET_FIELD_VALIDATE_RULE,
  SET_FIELD_REQUIRED_RULE,
  SET_FIELD_VISIBLE_RULE,
  SET_DATATABLE_RULE,
  GET_USER_INTG_FIELD_REQUEST,
  GET_USER_INTG_FIELD_SUCCESS,
  GET_USER_INTG_FIELD_FAILURE,
  GET_DEPT_INTG_FIELD_REQUEST,
  GET_DEPT_INTG_FIELD_SUCCESS,
  GET_DEPT_INTG_FIELD_FAILURE,
  GET_CUST_INTG_FIELD_REQUEST,
  GET_CUST_INTG_FIELD_SUCCESS,
  GET_CUST_INTG_FIELD_FAILURE,
  GET_ITEM_INTG_FIELD_REQUEST,
  GET_ITEM_INTG_FIELD_SUCCESS,
  GET_ITEM_INTG_FIELD_FAILURE,
  GET_FIELD_ROLE_REQUEST,
  GET_FIELD_ROLE_SUCCESS,
  GET_FIELD_ROLE_FAILURE,
  GET_FIELD_DEPT_REQUEST,
  GET_FIELD_DEPT_SUCCESS,
  GET_FIELD_DEPT_FAILURE,
  GET_FIELD_ITEMS_REQUEST,
  GET_FIELD_ITEMS_SUCCESS,
  GET_FIELD_ITEMS_FAILURE,
  GET_FIELD_ADHOC_COMMS_REQUEST,
  GET_FIELD_ADHOC_COMMS_SUCCESS,
  GET_FIELD_ADHOC_COMMS_FAILURE,
} from 'constants/admin/Field';

export function setOptions(value) {
  return (dispatch) => {
    dispatch({
      type: SET_OPTIONS,
      payload: {
        value,
      },
    });
  };
}
export function setOptionsDefaultValue(value) {
  return (dispatch) => {
    dispatch({
      type: SET_OPTIONS_DEFAULT_VALUE,
      payload: {
        value,
      },
    });
  };
}
export function setOptionsIntgFieldID(value) {
  return (dispatch) => {
    dispatch({
      type: SET_OPTIONS_INTG_FIELD_ID,
      payload: {
        value,
      },
    });
  };
}
export function setAggregateRule(value) {
  return (dispatch) => {
    dispatch({
      type: SET_FIELD_AGGREGATE_RULE,
      payload: {
        value,
      },
    });
  };
}

export function setValidateRule(value) {
  return (dispatch) => {
    dispatch({
      type: SET_FIELD_VALIDATE_RULE,
      payload: {
        value,
      },
    });
  };
}


export function setRequiredRule(value) {
  return (dispatch) => {
    dispatch({
      type: SET_FIELD_REQUIRED_RULE,
      payload: {
        value,
      },
    });
  };
}

export function setVisibleRule(value) {
  return (dispatch) => {
    dispatch({
      type: SET_FIELD_VISIBLE_RULE,
      payload: {
        value,
      },
    });
  };
}

export function setDatatableRule(value) {
  return (dispatch) => {
    dispatch({
      type: SET_DATATABLE_RULE,
      payload: {
        value,
      },
    });
  };
}

export function getFormFields(formID) {
  return commonBackendCall(
    GET_FORM_FIELD_REQUEST,
    GET_FORM_FIELD_SUCCESS,
    GET_FORM_FIELD_FAILURE,
    API.get(`admin/form-fields/${formID}`, getAdminConfig()),
  );
}
export function getNumFields() {
  return commonBackendCall(
    GET_FIELD_REQUEST,
    GET_FIELD_SUCCESS,
    GET_FIELD_FAILURE,
    API.get('admin/fields', getAdminConfig()),
  );
}
export function getUserIntgFields() {
  return commonBackendCall(
    GET_USER_INTG_FIELD_REQUEST,
    GET_USER_INTG_FIELD_SUCCESS,
    GET_USER_INTG_FIELD_FAILURE,
    API.get('admin/intg-fields-admin?type=user', getAdminConfig()),
  );
}

export function getDeptIntgFields() {
  return commonBackendCall(
    GET_DEPT_INTG_FIELD_REQUEST,
    GET_DEPT_INTG_FIELD_SUCCESS,
    GET_DEPT_INTG_FIELD_FAILURE,
    API.get('admin/intg-fields-admin?type=dept', getAdminConfig()),
  );
}

export function getItemIntgFields() {
  return commonBackendCall(
    GET_ITEM_INTG_FIELD_REQUEST,
    GET_ITEM_INTG_FIELD_SUCCESS,
    GET_ITEM_INTG_FIELD_FAILURE,
    API.get('admin/intg-fields-admin?type=item', getAdminConfig()),
  );
}
export function getCustIntgFields() {
  return commonBackendCall(
    GET_CUST_INTG_FIELD_REQUEST,
    GET_CUST_INTG_FIELD_SUCCESS,
    GET_CUST_INTG_FIELD_FAILURE,
    API.get('admin/intg-fields-admin?type=custom', getAdminConfig()),
  );
}

export function getAllDepts() {
  return commonBackendCall(
    GET_FIELD_DEPT_REQUEST,
    GET_FIELD_DEPT_SUCCESS,
    GET_FIELD_DEPT_FAILURE,
    API.get('admin/departments', getAdminConfig()),
  );
}


export function getAllItems() {
  return commonBackendCall(
    GET_FIELD_ITEMS_REQUEST,
    GET_FIELD_ITEMS_SUCCESS,
    GET_FIELD_ITEMS_FAILURE,
    API.get('admin/items', getAdminConfig()),
  );
}

export function getAllRoles() {
  return commonBackendCall(
    GET_FIELD_ROLE_REQUEST,
    GET_FIELD_ROLE_SUCCESS,
    GET_FIELD_ROLE_FAILURE,
    API.get('admin/roles', getAdminConfig()),
  );
}
export function getAllAdhocComms() {
  return commonBackendCall(
    GET_FIELD_ADHOC_COMMS_REQUEST,
    GET_FIELD_ADHOC_COMMS_SUCCESS,
    GET_FIELD_ADHOC_COMMS_FAILURE,
    API.get('admin/comm-names/adhoc', getAdminConfig()),
  );
}

export function createField(obj) {
  return commonBackendCall(
    POST_FIELD_REQUEST,
    POST_FIELD_SUCCESS,
    POST_FIELD_FAILURE,
    API.post('admin/fields', obj, getAdminConfig()),
  );
}
export function updateField(fieldID, obj) {
  return commonBackendCall(
    PATCH_FIELD_REQUEST,
    PATCH_FIELD_SUCCESS,
    PATCH_FIELD_FAILURE,
    API.patch(`admin/fields/${fieldID}`, obj, getAdminConfig()),
  );
}
export function deleteField(fieldID) {
  return commonBackendCall(
    DELETE_FIELD_REQUEST,
    DELETE_FIELD_SUCCESS,
    DELETE_FIELD_FAILURE,
    API.delete(`admin/fields/${fieldID}`, getAdminConfig()),
  );
}
