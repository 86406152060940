import {
  GET_ALL_APPLICATION_REQUEST,
  GET_ALL_APPLICATION_SUCCESS,
  GET_ALL_APPLICATION_FAILURE,
  DELETE_APPLICATION_REQUEST,
  DELETE_APPLICATION_SUCCESS,
  DELETE_APPLICATION_FAILURE,
  GET_APP_CORRES_REQUEST,
  GET_APP_CORRES_SUCCESS,
  GET_APP_CORRES_FAILURE,
  GET_APP_TRACK_REPORT_REQUEST,
  GET_APP_TRACK_REPORT_SUCCESS,
  GET_APP_TRACK_REPORT_FAILURE,
  GET_APP_TRACK_REPORT_FORM_DATA_REQUEST,
  GET_APP_TRACK_REPORT_FORM_DATA_SUCCESS,
  GET_APP_TRACK_REPORT_FORM_DATA_FAILURE,
  POST_ADMIN_RESTORE_APP_REQUEST,
  POST_ADMIN_RESTORE_APP_SUCCESS,
  POST_ADMIN_RESTORE_APP_FAILURE,
} from '../constants/admin/Application';

const initialState = {
  requesting: false,
  message: '',
  application_list: '',
  application_corres_list: '',
  application_track_report: '',
  application_track_form_data_report: '',
  refreshApplication: false,
};

export default function application(state = initialState, action) {
  switch (action.type) {
    case GET_APP_CORRES_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshApplication: false,
        message: '',
      };

    case GET_APP_CORRES_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        application_corres_list: action.payload.data,
      };

    case GET_APP_CORRES_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_APP_TRACK_REPORT_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshApplication: false,
        message: '',
      };

    case GET_APP_TRACK_REPORT_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        application_track_report: action.payload.data,
      };

    case GET_APP_TRACK_REPORT_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_APP_TRACK_REPORT_FORM_DATA_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshApplication: false,
        message: '',
      };

    case GET_APP_TRACK_REPORT_FORM_DATA_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        application_track_form_data_report: action.payload.data,
      };

    case GET_APP_TRACK_REPORT_FORM_DATA_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_ALL_APPLICATION_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshApplication: false,
        message: '',
      };

    case GET_ALL_APPLICATION_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        application_list: action.payload.data,
      };

    case GET_ALL_APPLICATION_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case DELETE_APPLICATION_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshApplication: false,
        message: '',
      };

    case DELETE_APPLICATION_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshApplication: true,
      };

    case DELETE_APPLICATION_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case POST_ADMIN_RESTORE_APP_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshApplication: false,
        message: '',
      };

    case POST_ADMIN_RESTORE_APP_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshApplication: true,
      };

    case POST_ADMIN_RESTORE_APP_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    default:
      return state;
  }
}
