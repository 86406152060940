import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Button from 'components/CustomButtons/Button.jsx';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Switch from "@material-ui/core/Switch";
import withStyles from "@material-ui/core/styles/withStyles";
import extendedFormsStyle from "assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx";
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import defaultTimezones from './timezones.json';

const NetworkSettingComp = (props) => {
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState(true)
  const [sms, setSms] = useState(true)
  const [whatsApp, setWhatsApp] = useState(true)
  const [inWebhookEnabled, setInWebhookEnabled] = useState(true)
  const [superPassword, setSuperPassword] = useState("")
  const [superPasswordEnabled, setSuperPasswordEnabled] = useState(false)
  const [showPassword, setShowPassword] = useState(false);
  const [addNewUserFromSSO, setAddNewUserFromSSO] = useState(false)
  const [icon, setIcon] = useState("")
  const [image, setImage] = useState("")
  const [bgImage, setBgImage] = useState("")
  const [theme, setTheme] = useState("")
  const [frontendURL, setFrontendURL] = useState("")
  const [title, setTitle] = useState("")
  const [googleKey, setGoogleKey] = useState("")
  const [selectedTimezone, setSelectedTimezone] = useState("")
  const [langSupports, setLangSupports] = useState([])


  const { getNetworkSettings } = props;
  useEffect(() => {
    getNetworkSettings();
  }, [getNetworkSettings])

  useEffect(() => {
    setMessage(props.message)
    if (props.refreshNetworkSettings) {
      props.getNetworkSettings();
    }
    setEmail(props.networkSetting.email === undefined ? false : props.networkSetting.email)
    setSms(props.networkSetting.sms === undefined ? false : props.networkSetting.sms)
    setWhatsApp(props.networkSetting.whats_app === undefined ? false : props.networkSetting.whats_app)
    setInWebhookEnabled(props.networkSetting.in_webhook_enabled === undefined ? false : props.networkSetting.in_webhook_enabled)
    setSuperPassword(props.networkSetting.super_password === undefined ? false : props.networkSetting.super_password)
    setSuperPasswordEnabled(props.networkSetting.super_password_enabled === undefined ? false : props.networkSetting.super_password_enabled)
    setAddNewUserFromSSO(props.networkSetting.add_new_user_from_sso === undefined ? false : props.networkSetting.add_new_user_from_sso)
    setIcon(props.networkSetting.icon)
    setImage(props.networkSetting.image)
    setBgImage(props.networkSetting.bg_image)
    setTheme(props.networkSetting.theme)
    setFrontendURL(props.networkSetting.frontend_url)
    setTitle(props.networkSetting.title)
    setGoogleKey(props.networkSetting.google_key)
    if (props.networkSetting.lang_support !== undefined) {
      setLangSupports(JSON.parse(props.networkSetting.lang_support))
    }
    setSelectedTimezone(props.networkSetting.timezone)
  }, [props])

  const handleConfirm = (e) => {
    props.updateNetworkSettings({
      email: email ? "1" : "0",
      sms: sms ? "1" : "0",
      whats_app: whatsApp ? "1" : "0",
      in_webhook_enabled: inWebhookEnabled ? "1" : "0",
      add_new_user_from_sso: addNewUserFromSSO ? "1" : "0",
      super_password_enabled: superPasswordEnabled ? "1" : "0",
      super_password: superPassword,
      icon: icon,
      image: image,
      bg_image: bgImage,
      theme: theme,
      frontend_url: frontendURL,
      title: title,
      google_key: googleKey,
      timezone: selectedTimezone
    })
  }

  const onEmailChange = (e) => {
    setEmail(e.target.checked)
  }

  const onSmsChange = (e) => {
    setSms(e.target.checked)
  }
  const onWhatsAppChange = (e) => {
    setWhatsApp(e.target.checked)
  }
  const onInWebhookEnabledChange = (e) => {
    setInWebhookEnabled(e.target.checked)
  }
  const onSuperPasswordEnabledChange = (e) => {
    setSuperPasswordEnabled(e.target.checked)
  }
  const onSuperPasswordChange = (e) => {
    setSuperPassword(e.target.value)
  }
  const handleClickShowPassword = () => {
    setShowPassword(showPassword ? false : true);
    //setShowPassword(true);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const onAddNewUserFromSSOChange = (e) => {
    setAddNewUserFromSSO(e.target.checked)
  }
  const onIconChange = (e) => {
    setIcon(e.target.value)
  }
  const onImageChange = (e) => {
    setImage(e.target.value)
  }
  const onBgImageChange = (e) => {
    setBgImage(e.target.value)
  }
  const onThemeChange = (e) => {
    setTheme(e.target.value)
  }
  const onFrontendURLChange = (e) => {
    setFrontendURL(e.target.value)
  }
  const onTitleChange = (e) => {
    setTitle(e.target.value)
  }
  const onGoogleKeyChange = (e) => {
    setGoogleKey(e.target.value)
  }
  const onSelectedTimezoneChange = (e) => {
    setSelectedTimezone(e.target.value)
  }

  const langSupportItems = [];
  langSupports.forEach((langSupport, index) => {
    langSupportItems.push(
      <GridContainer key={index}>
        <GridItem xs={12} sm={12} md={1}>
          <Checkbox
            disabled
            size="large"
            color="primary"
            checked={langSupport.default}
            inputProps={{ 'title': 'Default Value' }}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={11}>
          <div>{langSupport.lang}</div>
        </GridItem>
      </GridContainer>
    )
  })
  const { classes } = props;
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Network Settings</b>
          <p className={classes.cardCategoryWhite}> </p>
        </CardHeader>
        <CardBody>
          <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
          <Card>
            <CardBody>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel id="emailEnabledLabel" shrink={true}>Email Enabled</InputLabel>
                  <Grid item={true} component="label" container alignItems="center" spacing={1}>
                    <Grid item>No</Grid>
                    <Grid item>
                      <Switch
                        labelid="emailEnabledLabel"
                        checked={email}
                        onChange={onEmailChange.bind(this)}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                        }}
                      />
                    </Grid>
                    <Grid item>Yes</Grid>
                  </Grid>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel id="smsEnabledLabel" shrink={true}>SMS Enabled</InputLabel>
                  <Grid item={true} component="label" container alignItems="center" spacing={1}>
                    <Grid item>No</Grid>
                    <Grid item>
                      <Switch
                        labelid="smsEnabledLabel"
                        checked={sms}
                        onChange={onSmsChange.bind(this)}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                        }}
                      />
                    </Grid>
                    <Grid item>Yes</Grid>
                  </Grid>
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel id="whatsAppEnabledLabel" shrink={true}>WhatsApp Enabled</InputLabel>
                  <Grid item={true} component="label" container alignItems="center" spacing={1}>
                    <Grid item>No</Grid>
                    <Grid item>
                      <Switch
                        labelid="whatsAppEnabledLabel"
                        checked={whatsApp}
                        onChange={onWhatsAppChange.bind(this)}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                        }}
                      />
                    </Grid>
                    <Grid item>Yes</Grid>
                  </Grid>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel id="inWebhookEnabledLabel" shrink={true}>BOT Request Enabled</InputLabel>
                  <Grid item={true} component="label" container alignItems="center" spacing={1}>
                    <Grid item>No</Grid>
                    <Grid item>
                      <Switch
                        labelid="inWebhookEnabledLabel"
                        checked={inWebhookEnabled}
                        onChange={onInWebhookEnabledChange.bind(this)}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                        }}
                      />
                    </Grid>
                    <Grid item>Yes</Grid>
                  </Grid>
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel id="superPasswordEnabledLabel" shrink={true}>Super Password Enabled</InputLabel>
                  <Grid item={true} component="label" container alignItems="center" spacing={1}>
                    <Grid item>No</Grid>
                    <Grid item>
                      <Switch
                        labelid="superPasswordEnabledLabel"
                        checked={superPasswordEnabled}
                        onChange={onSuperPasswordEnabledChange.bind(this)}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                        }}
                      />
                    </Grid>
                    <Grid item>Yes</Grid>
                  </Grid>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth className={classes.formControl} >
                    <InputLabel htmlFor="na-adornment-password">Super Password</InputLabel>
                    <Input
                      id="na-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      value={superPassword}
                      onChange={onSuperPasswordChange.bind(this)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel id="addNewUserFromSSOLabel" shrink={true}>Add New User From SSO</InputLabel>
                  <Grid item={true} component="label" container alignItems="center" spacing={1}>
                    <Grid item>No</Grid>
                    <Grid item>
                      <Switch
                        labelid="addNewUserFromSSOLabel"
                        checked={addNewUserFromSSO}
                        onChange={onAddNewUserFromSSOChange.bind(this)}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                        }}
                      />
                    </Grid>
                    <Grid item>Yes</Grid>
                  </Grid>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required={false}
                    fullWidth={true}
                    label="Google Key"
                    value={googleKey}
                    onChange={onGoogleKeyChange.bind(this)}
                  />
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required={false}
                    fullWidth={true}
                    label="Image"
                    value={image}
                    onChange={onImageChange.bind(this)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required={false}
                    fullWidth={true}
                    label="Icon"
                    value={icon}
                    onChange={onIconChange.bind(this)}
                  />
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required={false}
                    fullWidth={true}
                    label="Background Image"
                    value={bgImage}
                    onChange={onBgImageChange.bind(this)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required={false}
                    fullWidth={true}
                    label="Frontend URL"
                    value={frontendURL}
                    onChange={onFrontendURLChange.bind(this)}
                  />
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth={true} className={classes.formControl} >
                    <InputLabel id="themeLabel">Theme</InputLabel>
                    <Select
                      labelId="themeLabel"
                      id="themeSelect"
                      value={theme}
                      onChange={onThemeChange.bind(this)}
                    >
                      < MenuItem key={"green"} value={"green"} >Green</MenuItem>
                      < MenuItem key={"pinkred"} value={"pinkred"} >PinkRed</MenuItem>
                      < MenuItem key={"purple"} value={"purple"} >Purple</MenuItem>
                      < MenuItem key={"mergenta"} value={"mergenta"} >Magenta</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required={false}
                    fullWidth={true}
                    label="Title"
                    value={title}
                    onChange={onTitleChange.bind(this)}
                  />
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth className={classes.formControl} disabled={true}>
                    <InputLabel id="langSupport" shrink>Language Support</InputLabel>
                  </FormControl>
                  <br />
                  {langSupportItems}
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth className={classes.formControl} disabled={true}>
                    <InputLabel id="itemAlias" shrink>Item Alias</InputLabel>
                  </FormControl>
                  <br />
                  {localStorage.getItem("network_item_alias")}
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth={true} className={classes.formControl} >
                    <InputLabel id="timezoneLabel">Timezone</InputLabel>
                    <Select
                      labelId="timezoneLabel"
                      id="timezoneSelect"
                      value={selectedTimezone}
                      onChange={onSelectedTimezoneChange.bind(this)}
                    >
                      {Object.entries(defaultTimezones).map(([label, value]) => (
                        <MenuItem key={value} value={value}>
                          {label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <Button style={{ float: 'right' }}
                onClick={handleConfirm.bind(this)}
                color="primary"
                round
              >
                Save
              </Button>
              <br />
            </CardBody>
            <br />
          </Card>
        </CardBody>
      </Card>
    </div>
  );
}

export default withStyles(extendedFormsStyle)(NetworkSettingComp)
