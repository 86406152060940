import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import HeaderPublic from 'components/Header/HeaderPublic';
import Footer from 'components/Footer/Footer';
import pagesStyle from 'assets/jss/material-dashboard-react/layouts/pagesStyle';
import logo from 'assets/img/logo/molecule-72-192917.png';

const NetworkAdminPublic = (props) => {
  const { classes, ...rest } = props;
  const bgImage = localStorage.getItem("network_bg_image");
  return (
    <div>
      <HeaderPublic logo={logo} {...rest} />
      <div className={classes.wrapper}>
        <div className={classes.fullPage}>
          <Footer white />
          <Outlet />
          <div
            className={classes.fullPageBackground}
            style={{ backgroundImage: `url(${bgImage})` }}
          />
        </div>
      </div>
    </div>
  );
}

NetworkAdminPublic.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(pagesStyle)(NetworkAdminPublic);
