import API from 'Api/api.js';
import { commonBackendCall, getNetworkAdminConfig } from 'Api/common.js';
import {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_INTG_REQUEST,
  GET_USER_INTG_SUCCESS,
  GET_USER_INTG_FAILURE,
  POST_USER_REQUEST,
  POST_USER_SUCCESS,
  POST_USER_FAILURE,
  PATCH_USER_REQUEST,
  PATCH_USER_SUCCESS,
  PATCH_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  PATCH_USER_INTG_REQUEST,
  PATCH_USER_INTG_SUCCESS,
  PATCH_USER_INTG_FAILURE,
  GET_USER_CSV_REQUEST,
  GET_USER_CSV_SUCCESS,
  GET_USER_CSV_FAILURE,
  POST_NA_SET_PASSWORD_REQUEST,
  POST_NA_SET_PASSWORD_SUCCESS,
  POST_NA_SET_PASSWORD_FAILURE,
} from 'constants/networkadmin/User';

export function getUsersCSV() {
  return commonBackendCall(
    GET_USER_CSV_REQUEST,
    GET_USER_CSV_SUCCESS,
    GET_USER_CSV_FAILURE,
    API.get('admin/users/csv', getNetworkAdminConfig()),
  );
}

export function getUsers(searchStr, pageSize, pageNum, orderby, sort) {
  return commonBackendCall(
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    GET_USER_FAILURE,
    API.get(`admin/networkadmin/users?search=${searchStr}&page=${pageNum}&limit=${pageSize}&orderby=${orderby}&sort=${sort}`, getNetworkAdminConfig()),
  );
}

export function getUserIntgs(userID) {
  return commonBackendCall(
    GET_USER_INTG_REQUEST,
    GET_USER_INTG_SUCCESS,
    GET_USER_INTG_FAILURE,
    API.get(`admin/users/intg/${userID}`, getNetworkAdminConfig()),
  );
}

export function createUser(obj) {
  return commonBackendCall(
    POST_USER_REQUEST,
    POST_USER_SUCCESS,
    POST_USER_FAILURE,
    API.post('admin/users', obj, getNetworkAdminConfig()),
  );
}

export function updateUser(userID, obj) {
  return commonBackendCall(
    PATCH_USER_REQUEST,
    PATCH_USER_SUCCESS,
    PATCH_USER_FAILURE,
    API.patch(`admin/users/${userID}`, obj, getNetworkAdminConfig()),
  );
}

export function deleteUser(userID) {
  return commonBackendCall(
    DELETE_USER_REQUEST,
    DELETE_USER_SUCCESS,
    DELETE_USER_FAILURE,
    API.delete(`admin/users/${userID}`, getNetworkAdminConfig()),
  );
}


export function updateUserIntgFieldValue(userIntgID, obj) {
  return commonBackendCall(
    PATCH_USER_INTG_REQUEST,
    PATCH_USER_INTG_SUCCESS,
    PATCH_USER_INTG_FAILURE,
    API.patch(`admin/users/intg/${userIntgID}`, obj, getNetworkAdminConfig()),
  );
}

resetPassword

export function resetPassword(obj) {
  return commonBackendCall(
    POST_NA_SET_PASSWORD_REQUEST,
    POST_NA_SET_PASSWORD_SUCCESS,
    POST_NA_SET_PASSWORD_FAILURE,
    API.post(`admin/networkadmin/set-password`, obj, getNetworkAdminConfig()),
  );
}