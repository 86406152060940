import React from 'react';

import Chip from '@material-ui/core/Chip';
import DraftIcon from '@mui/icons-material/Build';
import TestIcon from '@mui/icons-material/PlaylistAddCheck';
import PublishIcon from '@mui/icons-material/Public';
import DiscontinueIcon from '@mui/icons-material/PanTool';
import ArchiveIcon from '@mui/icons-material/HighlightOff';
import StateText from 'components/StateText/StateText.jsx';
import { Translation } from 'react-i18next'

const StateUI = (value, size) => {
  // eslint-disable-next-line default-case
  switch (value) {
    case 0:
      return <Translation>{(t, { i18n }) => <Chip size={size} icon={<DraftIcon style={{ color: '#fb8c00', paddingLeft: '5px' }} />} label={t(StateText(value))} />}</Translation>;
    case 1:
      return <Translation>{(t, { i18n }) => <Chip size={size} icon={<TestIcon style={{ color: '#00acc1', paddingLeft: '5px' }} />} label={t(StateText(value))} />}</Translation>;
    case 2:
      return <Translation>{(t, { i18n }) => <Chip size={size} icon={<PublishIcon style={{ color: '#43a047', paddingLeft: '5px' }} />} label={t(StateText(value))} />}</Translation>;
    case 3:
      return <Translation>{(t, { i18n }) => <Chip size={size} icon={<DiscontinueIcon style={{ color: '#e53935', paddingLeft: '5px' }} />} label={t(StateText(value))} />}</Translation>;
    case 4:
      return <Translation>{(t, { i18n }) => <Chip size={size} icon={<ArchiveIcon style={{ color: '#e91e63', paddingLeft: '5px' }} />} label={t(StateText(value))} />}</Translation>;
  }
};

export default StateUI;