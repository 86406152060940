import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import AccountTree from '@mui/icons-material/AccountTree';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx';
import { useTranslation } from 'react-i18next';


const UserComp = (props) => {
  const [message, setMessage] = useState('');
  // const [resetPasswordEmail, setResetPasswordEmail] = useState("");
  const [pageSize, setPageSize] = useState(100);
  const [pageNum, setPageNum] = useState(1);
  const [orderBy, setOrderBy] = useState('user_name');
  const [orderByDirec, setOrderByDirec] = useState('asc');
  const [searchStr, setSearchStr] = useState('');
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const { t } = useTranslation();

  const { getUsers } = props;
  useEffect(() => {
    getUsers(searchStr, pageSize, pageNum, orderBy, orderByDirec);
  }, [getUsers]);

  useEffect(() => {
    setMessage(props.message);

    var rowItems = [];
    for (let i = 0; i < props.user_list.length; i++) {
      if (props.user_list[i].name !== '') {
        var colItems = [];
        var link = `/admin/user-intg/${props.user_list[i].id}`;
        colItems.push(i + 1);
        colItems.push(props.user_list[i].id);
        colItems.push(props.user_list[i].name);
        colItems.push(props.user_list[i].dept_name);
        colItems.push(props.user_list[i].email);
        colItems.push(props.user_list[i].rank);
        colItems.push(props.user_list[i].organization);
        colItems.push(props.user_list[i].whats_app_opt ? 'Enabled' : 'Disbaled');
        colItems.push(props.user_list[i].whats_app);
        colItems.push(props.user_list[i].sms_opt ? 'Enabled' : 'Disbaled');
        colItems.push(props.user_list[i].sms);
        colItems.push(props.user_list[i].is_network_admin ? 'Yes' : 'No');

        colItems.push(
          <div>
            {/*
            <IconButton
              onClick={handleResetPasswordClick.bind(this,
                [props.user_list[i].email,
                ])}
              title="Reset Password"
            >
              {' '}
              <Lock />
            </IconButton>
              */}
            <IconButton title="Integration Fields" onClick={() => navigate(`/admin/user-intg/${props.user_list[i].id}`)}><AccountTree /></IconButton>
          </div>,
        );
        rowItems.push(colItems);
      }
    }
    setData(rowItems);
  }, [props]);

  const { classes } = props;
  // const columns = [
  //   "ID",
  //   "Name",
  //   "Department",
  //   "Email",
  //   "",
  // ];
  const columns = [
    {
      name: 'Serial',
      label: 'Serial',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'ID',
      label: 'ID',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Department',
      label: 'Department',
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'Email',
      label: 'Email',
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'Rank',
      label: 'Rank',
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'Organization',
      label: 'Organization',
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'Whats App Option',
      label: 'Whats App Option',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'Whats App No',
      label: 'Whats App No',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'SMS Option',
      label: 'SMS Option',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'SMS No',
      label: 'SMS No',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'Is Network Admin',
      label: 'Is Network Admin',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: '',
      label: '',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
  ];
  const options = {
    selectableRowsHideCheckboxes: true,
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    selectableRowsOnClick: true,
    selectToolbarPlacement: 'none',
    pagination: true,
    print: true,
    filter: false,
    download: false,
    search: true,
    sortFilterList: false,
    viewColumns: true,
    rowsSelected: [],
    serverSide: true,
    count: props.user_count,
    rowsPerPage: pageSize,
    page: pageNum - 1,
    rowsPerPageOptions: [10, 20, 100],

    onTableChange: (action, tableState) => {
      //console.log(action, tableState);
      switch (action) {
        case 'changeRowsPerPage':
          setPageSize(tableState.rowsPerPage)
          setPageNum(1)
          props.getUsers(searchStr, tableState.rowsPerPage, 1, orderBy, orderByDirec)
          break;
        case 'changePage':
          setPageNum(tableState.page + 1)
          props.getUsers(searchStr, pageSize, tableState.page + 1, orderBy, orderByDirec)
          break;
        case 'sort':
          console.log(action, tableState);
          console.log(tableState);
          var sortString = 'user_name'
          setOrderByDirec(tableState.sortOrder.direction)
          if (tableState.sortOrder.name === 'Name') {
            setOrderBy('user_name')
            sortString = 'user_name'
          } else if (tableState.sortOrder.name === 'Department') {
            setOrderBy('department_name')
            sortString = 'department_name'
          }
          props.getUsers(searchStr, pageSize, pageNum, sortString, tableState.sortOrder.direction)
          break;
        case 'search':
          setSearchStr(tableState.searchText)
          setPageNum(1)
          props.getUsers(tableState.searchText, pageSize, pageNum, orderBy, orderByDirec)
          break;
      }
    },
    textLabels: {
      body: {
        noMatch: t('mui_table_no_match'),
        toolTip: t('mui_table_tool_tip'),
        columnHeaderTooltip: column => t('mui_table_header_tool_tip') + " " + column.label
      },
      pagination: {
        next: t('mui_table_next'),
        previous: t('mui_table_previous'),
        rowsPerPage: t('mui_table_rows_per_page'),
        displayRows: t('mui_table_display_rows'),
      },
      toolbar: {
        search: t('mui_table_search'),
        downloadCsv: t('mui_table_download_csv'),
        print: t('mui_table_print'),
        viewColumns: t('mui_table_view_columns'),
        filterTable: t('mui_table_filter_table'),
      },
      filter: {
        all: t('mui_table_filter_all'),
        title: t('mui_table_filter_title'),
        reset: t('mui_table_filter_reset'),
      },
      viewColumns: {
        title: t('mui_table_column_title'),
        titleAria: t('mui_table_column_title_aria'),
      },
      selectedRows: {
        text: t('mui_table_selected_text'),
        delete: t('mui_table_selected_delete'),
        deleteAria: t('mui_table_selected_delete_aria'),
      },
    },
  };
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Users</b>
          <p className={classes.cardCategoryWhite} />
        </CardHeader>
        <CardBody>
          <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
          <MUIDataTable
            data={data}
            columns={columns}
            options={options}
          />
        </CardBody>
      </Card>
    </div>
  );
};
export default withStyles(extendedFormsStyle)(UserComp);
