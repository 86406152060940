import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as itemActions from 'actions/ItemActions';
import ItemComp from './Item/ItemComp';

const Item = (props) => {
  const { classes } = props;
  const { setSelectedItem } = props.itemActions;
  const { item, } = props;

  return (
    <ItemComp
      error={props.error}
      helperText={props.helperText}
      form_checkable={props.form_checkable}
      field_value={props.field_value}
      field_label={props.field_label}
      search_table={props.search_table}
      search_level={props.search_level}
      setSelectedItem={setSelectedItem}
      selected_item={item.selected_item}
      selected_item_id={item.selected_item_id}
      selected_field_id={item.field_id}
      fields={props.fields}
      application_id={props.application_id}
      form_id={props.form_id}
      field_id={props.field_id}
      task_id={props.task_id}
      task_completed={props.task_completed}
      requesting={props.requesting}
      postApplicationDetail={props.postApplicationDetail}
      message={item.message}
      classes={classes}
    />
  );
}

function mapStateToProps(state) {
  return {
    item: state.item,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    itemActions: bindActionCreators(itemActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Item);
