import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import * as phasesActions from 'actions/admin/PhasesActions';
import * as defaultActions from 'actions/admin/DefaultActions';
import PhasesComp from './Phases/PhasesComp';

const Phases = (props) => {
  const { classes } = props;
  const { phase, defaultStates } = props;
  const {
    getPhases,
    createPhase,
    updatePhase,
    deletePhase,
  } = props.phasesActions;
  const { resetMessage } = props.defaultActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <PhasesComp
        getPhases={getPhases}
        resetMessage={resetMessage}
        phase_list={phase.phase_list.list === undefined ? [] : phase.phase_list.list}
        createPhase={createPhase}
        updatePhase={updatePhase}
        deletePhase={deletePhase}
        refreshPhase={phase.refreshPhase}
        requesting={phase.requesting}
        message={phase.message}
        error_core={phase.error_core}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    phase: state.adPhase,
    defaultStates: state.adDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    phasesActions: bindActionCreators(phasesActions, dispatch),
    defaultActions: bindActionCreators(defaultActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Phases);
