import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import ItemComp from './Item/ItemComp';
import * as itemActions from 'actions/admin/ItemActions';
import * as itemCommonActions from 'actions/ItemActions';

const Item = (props) => {
  const { classes } = props;
  const { item, itemCommon, defaultStates } = props;
  const {
    getItemsWithRelation,
    getItemsCSV,
    createItem,
    updateItem,
    deleteSelectedItem,
  } = props.itemActions;
  const {
    setSelectedItem,
  } = props.itemCommonActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <ItemComp
        getItemsWithRelation={getItemsWithRelation}
        getItemsCSV={getItemsCSV}
        createItem={createItem}
        updateItem={updateItem}
        deleteSelectedItem={deleteSelectedItem}
        setSelectedItem={setSelectedItem}
        selected_item_id={itemCommon === undefined ? "" : itemCommon.selected_item_id}
        selected_item={itemCommon === undefined ? "" : itemCommon.selected_item}
        item_list={item.item_list.list === undefined ? [] : item.item_list.list}
        item_count={item.item_list.count === undefined ? [] : item.item_list.count}
        item_list_csv={item.item_list_csv === undefined ? [] : item.item_list_csv}
        item_delete_error_list={item.item_delete_error_list === undefined ? [] : item.item_delete_error_list}
        refreshItem={item.refreshItem}
        requesting={item.requesting}
        message={item.message}
        error_code={item.error_code}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    item: state.adItem,
    itemCommon: state.item,
    defaultStates: state.adDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    itemActions: bindActionCreators(itemActions, dispatch),
    itemCommonActions: bindActionCreators(itemCommonActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Item);
