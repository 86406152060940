import API from 'Api/api.js';
import { commonBackendCall, getAdminConfig } from 'Api/common.js';
import {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_INTG_REQUEST,
  GET_USER_INTG_SUCCESS,
  GET_USER_INTG_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
} from 'constants/admin/User';

export function getUsers(searchStr, pageSize, pageNum, orderby, sort) {
  return commonBackendCall(
    GET_USER_REQUEST,
    GET_USER_SUCCESS,
    GET_USER_FAILURE,
    API.get(`admin/users?search=${searchStr}&page=${pageNum}&limit=${pageSize}&orderby=${orderby}&sort=${sort}`, getAdminConfig()),

  );
}

export function getUserIntgs(userID) {
  return commonBackendCall(
    GET_USER_INTG_REQUEST,
    GET_USER_INTG_SUCCESS,
    GET_USER_INTG_FAILURE,
    API.get(`admin/users/intg-admin/${userID}`, getAdminConfig()),
  );
}

export function ResetPassword(obj) {
  return commonBackendCall(
    RESET_PASSWORD_REQUEST,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    API.post('admin/reset-password', obj, getAdminConfig()),
  );
}
