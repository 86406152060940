export const GET_ALLOW_APP_CREATE_REQUEST = 'GET_ALLOW_APP_CREATE_REQUEST';
export const GET_ALLOW_APP_CREATE_SUCCESS = 'GET_ALLOW_APP_CREATE_SUCCESS';
export const GET_ALLOW_APP_CREATE_FAILURE = 'GET_ALLOW_APP_CREATE_FAILURE';
export const POST_ALLOW_APP_CREATE_REQUEST = 'POST_ALLOW_APP_CREATE_REQUEST';
export const POST_ALLOW_APP_CREATE_SUCCESS = 'POST_ALLOW_APP_CREATE_SUCCESS';
export const POST_ALLOW_APP_CREATE_FAILURE = 'POST_ALLOW_APP_CREATE_FAILURE';
export const DELETE_ALLOW_APP_CREATE_REQUEST = 'DELETE_ALLOW_APP_CREATE_REQUEST';
export const DELETE_ALLOW_APP_CREATE_SUCCESS = 'DELETE_ALLOW_APP_CREATE_SUCCESS';
export const DELETE_ALLOW_APP_CREATE_FAILURE = 'DELETE_ALLOW_APP_CREATE_FAILURE';
export const GET_ROLES_EXCLUDE_REQUEST = 'GET_ROLES_EXCLUDE_REQUEST';
export const GET_ROLES_EXCLUDE_SUCCESS = 'GET_ROLES_EXCLUDE_SUCCESS';
export const GET_ROLES_EXCLUDE_FAILURE = 'GET_ROLES_EXCLUDE_FAILURE';
