import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Button from 'components/CustomButtons/Button.jsx';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import * as formActions from 'actions/admin/FormActions';
import * as taskFormActions from 'actions/admin/TaskformActions';
import * as taskActions from 'actions/admin/TaskActions';
import * as defaultActions from 'actions/admin/DefaultActions';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Assignment from '@mui/icons-material/Assignment';
import TaskTaskFormComp from './TaskTaskForm/TaskTaskFormComp';

const TaskTaskForm = (props) => {

  const { classes } = props;
  const {
    form, taskForm, task, defaultStates,
  } = props;
  const {
    getTaskForms,
    createTaskForm,
    updateTaskForm,
    deleteTaskForm,
    getTaskTaskForms,
  } = props.taskFormActions;
  const { resetMessage } = props.defaultActions;
  const { getForms } = props.formActions;
  const { getTasks } = props.taskActions;
  const { id } = useParams();

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/admin/task">
          <Button size="sm" round color="primary">
            {' '}
            <Assignment />
            {' '}
            Task
          </Button>
        </Link>
        <Link to="#">
          <Button size="sm" round color="primary" block>
            {' '}
            {taskForm.task_taskforms_list.title}
          </Button>
        </Link>
      </Breadcrumbs>
      <br />
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <TaskTaskFormComp
        task_id={id}
        getTaskForms={getTaskForms}
        resetMessage={resetMessage}
        getTaskTaskForms={getTaskTaskForms}
        getForms={getForms}
        getTasks={getTasks}
        form_list={form.form_list.list === undefined ? [] : form.form_list.list}
        task_list={task.task_list.list === undefined ? [] : task.task_list.list}
        task_taskforms_list={taskForm.task_taskforms_list.task_forms === undefined ? [] : taskForm.task_taskforms_list.task_forms}
        task_taskforms={task.task_taskforms_list === undefined ? [] : task.task_taskforms_list}
        taskForm_list={taskForm.taskForm_list.list === undefined ? [] : taskForm.taskForm_list.list}
        createTaskForm={createTaskForm}
        updateTaskForm={updateTaskForm}
        deleteTaskForm={deleteTaskForm}
        refreshTaskForm={taskForm.refreshTaskForm}
        requesting={taskForm.requesting}
        message={taskForm.message}
        error_core={taskForm.error_core}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    form: state.adForm,
    task: state.adTask,
    taskForm: state.adTaskForm,
    defaultStates: state.adDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    formActions: bindActionCreators(formActions, dispatch),
    taskActions: bindActionCreators(taskActions, dispatch),
    taskFormActions: bindActionCreators(taskFormActions, dispatch),
    defaultActions: bindActionCreators(defaultActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskTaskForm);
