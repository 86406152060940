import React from 'react';

import DashboardIcon from '@mui/icons-material/Dashboard';
import Dashboard from 'views/admin/Dashboard.jsx';
import GroupWork from '@mui/icons-material/GroupWork';
import RecentActorsOutlined from '@mui/icons-material/RecentActorsOutlined';
import BlockOutlined from '@mui/icons-material/BlockOutlined';
import GroupAddOutlined from '@mui/icons-material/GroupAddOutlined';
import DeviceHubRounded from '@mui/icons-material/DeviceHubRounded';
import PostAddRounded from '@mui/icons-material/PostAddRounded';
import AccountTree from '@mui/icons-material/AccountTree';
import Category from '@mui/icons-material/Category';
import Assignment from '@mui/icons-material/Assignment';
import TrendingFlat from '@mui/icons-material/TrendingFlat';
import SyncAlt from '@mui/icons-material/SyncAlt';
import AutoModeIcon from '@mui/icons-material/AutoMode';
import EmojiPeople from '@mui/icons-material/EmojiPeople';
import Grain from '@mui/icons-material/Grain';
import Roles from 'views/admin/Roles.jsx';
import AppCorres from 'views/admin/AppCorres.jsx';
import FormCategory from 'views/admin/FormCategory.jsx';
import CommName from 'views/admin/CommName.jsx';
import Phases from 'views/admin/Phases.jsx';
import Task from 'views/admin/Task.jsx';
import TaskTaskForm from 'views/admin/TaskTaskForm.jsx';
import TaskFormPreview from 'views/admin/TaskFormPreview.jsx';
import TaskFormField from 'views/admin/TaskFormField.jsx';
import Action from 'views/admin/Action.jsx';
import ActionActionMsg from 'views/admin/ActionActionMsg.jsx';
import Form from 'views/admin/Form.jsx';
import Application from 'views/admin/Application.jsx';
import Department from 'views/admin/Department.jsx';
import User from 'views/admin/User.jsx';
import UserIntg from 'views/admin/UserIntg.jsx';
import DeptIntg from 'views/admin/DeptIntg.jsx';
import FormField from 'views/admin/FormField.jsx';
import FormPreview from 'views/admin/FormPreview.jsx';
import NameExclusion from 'views/admin/NameExclusion.jsx';
import TaskDependency from 'views/admin/TaskDependency.jsx';
import ActionDependency from 'views/admin/ActionDependency.jsx';
import WorkflowAttr from 'views/admin/WorkflowAttr.jsx';
import WorkflowSetting from 'views/admin/WorkflowSetting.jsx';
import CommPermComm from 'views/admin/CommPermComm.jsx';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import CustomIntg from 'views/admin/CustomIntg.jsx';
import Item from 'views/admin/Item.jsx';
import ItemIntg from 'views/admin/ItemIntg.jsx';
import ListAlt from '@mui/icons-material/ListAlt';
import ItemIcon from '@mui/icons-material/Bookmarks';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import SentEmailAdmin from 'views/admin/SentEmailAdmin.jsx';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';




const dashboardRoutes = [
  {
    path: '/admin/dashboard',
    sidebarName: 'Dashboard',
    mini: 'DA',
    navbarName: 'Dashboard',
    icon: DashboardIcon,
    component: <Dashboard />,
    hidden: false,
  },
  {
    path: '/admin/roles',
    sidebarName: 'Roles',
    mini: 'RO',
    navbarName: 'Roles',
    icon: RecentActorsOutlined,
    component: <Roles />,
    hidden: false,
  },
  {
    path: '/admin/task',
    sidebarName: 'Task',
    mini: 'TA',
    navbarName: 'Task',
    icon: Assignment,
    component: <Task />,
    hidden: false,
  },
  {
    path: '/admin/action',
    sidebarName: 'Action',
    mini: 'AC',
    navbarName: 'Action',
    icon: SyncAlt,
    component: <Action />,
    hidden: false,
  },
  {
    path: '/admin/form',
    sidebarName: 'Form',
    mini: 'FO',
    navbarName: 'Form',
    icon: PostAddRounded,
    component: <Form />,
    hidden: false,
  },
  {
    path: '/admin/application',
    sidebarName: 'Applications',
    mini: 'AP',
    navbarName: 'Applications',
    icon: FormatListBulletedIcon,
    component: <Application />,
    hidden: false,
  },
  {
    path: '/admin/application-correspondences/:id',
    sidebarName: 'Correspondence',
    mini: 'AB',
    navbarName: 'Applications',
    icon: FormatListBulletedIcon,
    component: <AppCorres />,
    hidden: true,
  },
  {
    path: '/admin/sent-email',
    sidebarName: 'Sent Email',
    mini: 'SE',
    navbarName: 'Sent Email',
    icon: MarkEmailReadIcon,
    component: <SentEmailAdmin />,
    hidden: false,
  },
  {
    path: '/admin/users',
    sidebarName: 'Users',
    mini: 'EM',
    navbarName: 'User',
    icon: EmojiPeople,
    component: <User />,
    hidden: false,
  },
  {
    path: '/admin/department',
    sidebarName: 'Department',
    mini: 'DE',
    navbarName: 'Department',
    icon: Grain,
    component: <Department />,
    hidden: false,
  },
  {
    path: '/admin/custom',
    sidebarName: 'Custom',
    mini: 'AD',
    navbarName: 'Custom',
    icon: ListAlt,
    component: <CustomIntg />,
    hidden: false,
  },
  {
    path: '/admin/item',
    sidebarName: 'Item',
    mini: 'IT',
    navbarName: 'Item',
    icon: ItemIcon,
    component: <Item />,
    hidden: false,
  },
  {
    path: '/admin/action-messages/:id',
    sidebarName: 'Action Message',
    mini: 'AB',
    navbarName: 'Action Messages',
    icon: TrendingFlat,
    component: <ActionActionMsg />,
    hidden: true,
  },
  {
    path: '/admin/form-fields/:id',
    sidebarName: 'Form Fields',
    mini: 'AB',
    navbarName: 'Form Fields',
    icon: GroupWork,
    component: <FormField />,
    hidden: true,
  },
  {
    path: '/admin/task-form-fields/:id/:task_id',
    sidebarName: 'Task Form Fields',
    mini: 'AB',
    navbarName: 'Task Form Fields',
    icon: GroupWork,
    component: <TaskFormField />,
    hidden: true,
  },
  {
    path: '/admin/form-preview/:id',
    sidebarName: 'Form Preview',
    mini: 'AB',
    navbarName: 'Form Fields',
    icon: GroupWork,
    component: <FormPreview />,
    hidden: true,
  },
  {
    path: '/admin/task-form-preview/:id/:task_id',
    sidebarName: 'Task Form Preview',
    mini: 'AB',
    navbarName: 'Task Form Preview',
    icon: GroupWork,
    component: <TaskFormPreview />,
    hidden: true,
  },
  {
    path: '/admin/task-forms/:id',
    sidebarName: 'Task Forms',
    mini: 'AB',
    navbarName: 'Task Forms',
    icon: GroupWork,
    component: <TaskTaskForm />,
    hidden: true,
  },
  {
    path: '/admin/user-intg/:id',
    sidebarName: 'User Intg',
    mini: 'AD',
    navbarName: 'Batch Data Upload',
    icon: Grain,
    component: <UserIntg />,
    hidden: true,
  },
  {
    path: '/admin/item-intg/:id',
    sidebarName: 'Item Intg',
    mini: 'AD',
    navbarName: 'Batch Data Upload',
    icon: Grain,
    component: <ItemIntg />,
    hidden: true,
  },
  {
    path: '/admin/dept-intg/:id',
    sidebarName: 'Dept Intg',
    mini: 'AD',
    navbarName: 'Batch Data Upload',
    icon: Grain,
    component: <DeptIntg />,
    hidden: true,
  },
  {
    path: '/admin/comm-name',
    sidebarName: 'Committee Name',
    mini: 'PCT',
    navbarName: 'Comm Name',
    icon: Category,
    component: <CommName />,
    hidden: false,
  },
  {
    path: '/admin/comm-name-perm/:id',
    sidebarName: 'Perm Comm',
    mini: 'AB',
    navbarName: 'Perm Comm',
    icon: GroupWork,
    component: <CommPermComm />,
    hidden: true,
  },
  {
    path: '/admin/phases',
    sidebarName: 'Phase',
    mini: 'PH',
    navbarName: 'Phase',
    icon: AccountTree,
    component: <Phases />,
    hidden: false,
  },
  {
    path: '/admin/name-exclusion',
    sidebarName: 'Name Exclusion',
    mini: 'NE',
    navbarName: 'Name Exclusion',
    icon: BlockOutlined,
    component: <NameExclusion />,
    hidden: false,
  },
  {
    path: '/admin/tskdependency',
    sidebarName: 'Task Dependency',
    mini: 'TD',
    navbarName: 'Task Dependency',
    icon: DeviceHubRounded,
    component: <TaskDependency />,
    hidden: false,
  },
  {
    path: '/admin/actdependency',
    sidebarName: 'Action Dependency',
    mini: 'AD',
    navbarName: 'Action Dependency',
    icon: AutoModeIcon,
    component: <ActionDependency />,
    hidden: false,
  },
  {
    path: '/admin/frm-category',
    sidebarName: 'Form Category',
    mini: 'FC',
    navbarName: 'Form Category',
    icon: Category,
    component: <FormCategory />,
    hidden: false,
  },
  {
    path: '/admin/workflow-attr',
    sidebarName: 'Workflow Attr',
    mini: 'WT',
    navbarName: 'Perm Comm',
    icon: GroupAddOutlined,
    component: <WorkflowAttr />,
    hidden: false,
  },
  {
    path: '/admin/workflow-setting',
    sidebarName: 'Workflow Setting',
    mini: 'WS',
    navbarName: '',
    icon: PermDataSettingIcon,
    component: <WorkflowSetting />,
    hidden: false,
  },
];

export default dashboardRoutes;
