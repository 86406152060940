import {
  GET_SPECIALCASE_REQUEST,
  GET_SPECIALCASE_SUCCESS,
  GET_SPECIALCASE_FAILURE,
  POST_SPECIALCASE_REQUEST,
  POST_SPECIALCASE_SUCCESS,
  POST_SPECIALCASE_FAILURE,
  PATCH_SPECIALCASE_REQUEST,
  PATCH_SPECIALCASE_SUCCESS,
  PATCH_SPECIALCASE_FAILURE,
  DELETE_SPECIALCASE_REQUEST,
  DELETE_SPECIALCASE_SUCCESS,
  DELETE_SPECIALCASE_FAILURE,
} from '../constants/admin/SpecialCase';

const initialState = {
  requesting: false,
  message: '',
  specialCase_list: '',
  refreshSpecialCase: false,
};

export default function specialCase(state = initialState, action) {
  switch (action.type) {
    case GET_SPECIALCASE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshSpecialCase: false,
        message: '',
      };

    case GET_SPECIALCASE_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        specialCase_list: action.payload.data,
      };

    case GET_SPECIALCASE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case POST_SPECIALCASE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshSpecialCase: false,
      };

    case POST_SPECIALCASE_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshSpecialCase: true,
      };

    case POST_SPECIALCASE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case PATCH_SPECIALCASE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshSpecialCase: false,
      };

    case PATCH_SPECIALCASE_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshSpecialCase: true,
      };

    case PATCH_SPECIALCASE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case DELETE_SPECIALCASE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshSpecialCase: false,
      };

    case DELETE_SPECIALCASE_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshSpecialCase: true,
      };

    case DELETE_SPECIALCASE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
