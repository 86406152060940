import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom'
import Button from 'components/CustomButtons/Button.jsx';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import DeptIntgComp from './DeptIntg/DeptIntgComp';
import * as deptActions from 'actions/networkadmin/DepartmentActions';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Assignment from '@mui/icons-material/Assignment';

const DeptIntg = (props) => {
  const { classes } = props;
  const { department, defaultStates } = props;
  const {
    getDeptIntgs,
    updateDeptIntgFieldValue
  } = props.deptActions;
  const { id } = useParams();

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/networkadmin/department" >
          <Button size="sm" round color="primary"> <Assignment />Departments</Button>
        </Link>
        <Link to="#" >
          <Button size="sm" round color="primary" block={true}> </Button>
        </Link>
      </Breadcrumbs>
      <br />
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <DeptIntgComp
        dept_id={id}
        getDeptIntgs={getDeptIntgs}
        updateDeptIntgFieldValue={updateDeptIntgFieldValue}
        dept_intg_list={department.dept_intg_list === undefined ? [] : department.dept_intg_list}
        requesting={department.requesting}
        message={department.message}
        refreshDeptIntg={department.refreshDeptIntg}
        error_code={department.error_code}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    department: state.naDepartment,
    defaultStates: state.naDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deptActions: bindActionCreators(deptActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(DeptIntg);
