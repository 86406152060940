import React, { useState, useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from 'components/CustomButtons/Button.jsx';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import UserSearch from 'components/UserSearch/UserSearch.jsx';
import { useTranslation } from 'react-i18next';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ItemSearch from 'components/ItemSearch/ItemSearch.jsx';
import { SaveFormToAPI } from 'Api/common.js';

const ItemComp = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [itemID, setItemID] = useState('');
  const [selectedItemName, setSelectedItemName] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (props.selected_item_id > 0) {
      if (props.selected_field_id === props.field_id) {
        setDialogOpen(false);
        props.setSelectedItem('', 0);
        var itemFieldValue = [];
        itemFieldValue.push(props.selected_item_id.toString())
        itemFieldValue.push(props.selected_item)
        itemFieldValue.push(props.field_id.toString())
        var tempFields = props.fields;
        var foundIndex = tempFields.findIndex((x) => x.id === props.field_id);
        tempFields[foundIndex].valueArray = itemFieldValue;
        tempFields[foundIndex].error = false;
        SaveFormToAPI(props.form_id, props.requesting, tempFields, [], [], "", "", props.postApplicationDetail, props.application_id, props.task_id)
      }
    } else {
      if (props.field_value.length > 1) {
        if (props.field_id.toString() === props.field_value[2]) {
          setSelectedItemName(props.field_value[1])
        }
      }
    }
  }, [props]);

  const handleDialogClose = (e) => {
    setDialogOpen(false);
    setMessage('');
  };

  const handleShowItemSearchClick = (e) => {
    if (props.form_checkable && !props.task_completed) {
      props.setSelectedItem('', 0);
      setDialogOpen(true);
    }
  };

  return (
    <div >
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={11}>
              <TextField
                error={props.error}
                helperText={props.helperText}
                required={false}
                fullWidth
                label={props.field_label}
                onClick={handleShowItemSearchClick.bind(this)}
                value={selectedItemName}
                disabled
                InputProps={{ readOnly: true }}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={1}>
              <IconButton
                onClick={handleShowItemSearchClick.bind(this)}
                title="Change Item"
                disabled={!props.form_checkable || props.task_completed}
              >
                <EditIcon />
              </IconButton>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <br />
      <Dialog fullWidth open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
        <DialogContent>
          <Card>
            <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
              <b>{t('app_detail_item_search_search_label')}</b>
            </CardHeader>
            <CardBody>
              <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
              <ItemSearch
                search_table={props.search_table}
                search_level={props.search_level}
                field_id={props.field_id}
              />
            </CardBody>
            <CardFooter style={{ textAlign: 'left' }} />
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose.bind(this)} color="primary" round>
            {t('app_detail_item_search_cancel_label')}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ItemComp;
