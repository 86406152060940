import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import IntgExtAPIComp from './IntgExtAPI/IntgExtAPIComp';
import * as intgextapiActions from 'actions/networkadmin/IntgExtAPIActions';

const IntgExtAPI = (props) => {
  const { classes } = props;
  const { intgextapi, defaultStates } = props;
  const {
    getIntgExtAPIs,
    createIntgExtAPI,
    updateIntgExtAPI,
    deleteIntgExtAPI,
  } = props.intgextapiActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <IntgExtAPIComp
        getIntgExtAPIs={getIntgExtAPIs}
        intg_ext_api_list={intgextapi.intg_ext_api_list === undefined ? [] : intgextapi.intg_ext_api_list}
        createIntgExtAPI={createIntgExtAPI}
        updateIntgExtAPI={updateIntgExtAPI}
        deleteIntgExtAPI={deleteIntgExtAPI}
        refreshIntgExtAPI={intgextapi.refreshIntgExtAPI}
        requesting={intgextapi.requesting}
        message={intgextapi.message}
        error_core={intgextapi.error_core}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    intgextapi: state.naIntgextapi,
    defaultStates: state.naDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    intgextapiActions: bindActionCreators(intgextapiActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IntgExtAPI);
