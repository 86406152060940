export const GET_WORKFLOW_ATTR_REQUEST = 'GET_WORKFLOW_ATTR_REQUEST';
export const GET_WORKFLOW_ATTR_SUCCESS = 'GET_WORKFLOW_ATTR_SUCCESS';
export const GET_WORKFLOW_ATTR_FAILURE = 'GET_WORKFLOW_ATTR_FAILURE';
export const POST_WORKFLOW_ATTR_REQUEST = 'POST_WORKFLOW_ATTR_REQUEST';
export const POST_WORKFLOW_ATTR_SUCCESS = 'POST_WORKFLOW_ATTR_SUCCESS';
export const POST_WORKFLOW_ATTR_FAILURE = 'POST_WORKFLOW_ATTR_FAILURE';
export const PATCH_WORKFLOW_ATTR_REQUEST = 'PATCH_WORKFLOW_ATTR_REQUEST';
export const PATCH_WORKFLOW_ATTR_SUCCESS = 'PATCH_WORKFLOW_ATTR_SUCCESS';
export const PATCH_WORKFLOW_ATTR_FAILURE = 'PATCH_WORKFLOW_ATTR_FAILURE';
export const DELETE_WORKFLOW_ATTR_REQUEST = 'DELETE_WORKFLOW_ATTR_REQUEST';
export const DELETE_WORKFLOW_ATTR_SUCCESS = 'DELETE_WORKFLOW_ATTR_SUCCESS';
export const DELETE_WORKFLOW_ATTR_FAILURE = 'DELETE_WORKFLOW_ATTR_FAILURE';
