import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import * as workflowSettingActions from 'actions/admin/WorkflowSettingActions';
import * as allowAppCreateActions from 'actions/admin/AllowAppCreateActions';
import * as userActions from 'actions/UserActions';
import * as departmentActions from 'actions/admin/DepartmentActions';
import AllowCreateAppComp from './AllowCreateApp/AllowCreateAppComp';
import WorkflowSettingComp from './WorkflowSetting/WorkflowSettingComp';

const WorkflowSetting = (props) => {
  const { classes } = props;
  const {
    department, allowAppCreate, user, workflowSetting, defaultStates,
  } = props;
  const {
    getWorkflowSetting,
    updateWorkflowSetting,
    addUserToWorkflowUser,
    removeUserFromWorkflowUser,
    getWorkflowUsers,
    setSelectedUser,
    postWorkflowUserFileUpload,
  } = props.workflowSettingActions;
  const {
    getAllowAppCreateRole,
    createAllowAppCreateRole,
    deleteAllowAppCreateRole,
    getRolesExcludeAdhocAndGeneric,
  } = props.allowAppCreateActions;
  const { getDepartments } = props.departmentActions;
  const { getUsers, findUsers } = props.userActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <WorkflowSettingComp
        getUsers={getUsers}
        findUsers={findUsers}
        setSelectedUser={setSelectedUser}
        selected_user_id={user.selected_user_id}
        selected_user={user.selected_user}
        addUserToWorkflowUser={addUserToWorkflowUser}
        removeUserFromWorkflowUser={removeUserFromWorkflowUser}
        postWorkflowUserFileUpload={postWorkflowUserFileUpload}
        getWorkflowUsers={getWorkflowUsers}
        getWorkflowSetting={getWorkflowSetting}
        workflow_setting_list={workflowSetting.workflow_setting_list === undefined ? [] : workflowSetting.workflow_setting_list}
        updateWorkflowSetting={updateWorkflowSetting}
        refreshWorkflowSetting={workflowSetting.refreshWorkflowSetting}
        requesting={workflowSetting.requesting}
        message={workflowSetting.message}
        message_wu={workflowSetting.message_wu}
        error_code={workflowSetting.error_core}
        classes={classes}
        workflow_user_list={workflowSetting.workflow_user_list.list === undefined ? [] : workflowSetting.workflow_user_list.list}
        workflow_user_count={workflowSetting.workflow_user_list.count === undefined ? 0 : workflowSetting.workflow_user_list.count}
        refreshWorkflowUser={workflowSetting.refreshWorkflowUser}
      />
      <br />
      <AllowCreateAppComp
        getDepartments={getDepartments}
        department_list={department.department_list.list === undefined ? [] : department.department_list.list}
        createAllowAppCreateRole={createAllowAppCreateRole}
        getRolesExcludeAdhocAndGeneric={getRolesExcludeAdhocAndGeneric}
        allow_app_create_list={allowAppCreate.allow_app_create_list.list === undefined ? [] : allowAppCreate.allow_app_create_list.list}
        role_exclude_list={allowAppCreate.role_exclude_list === undefined ? [] : allowAppCreate.role_exclude_list}
        getAllowAppCreateRole={getAllowAppCreateRole}
        deleteAllowAppCreateRole={deleteAllowAppCreateRole}
        refreshAllowAppCreate={allowAppCreate.refreshAllowAppCreate}
        requesting={allowAppCreate.requesting}
        message={allowAppCreate.message}
        error_code={allowAppCreate.error_code}
        classes={classes}
      />
    </div>
  );
}


function mapStateToProps(state) {
  return {
    workflowSetting: state.adWorkflowSetting,
    defaultStates: state.adDefaultStates,
    allowAppCreate: state.adAllowAppCreate,
    user: state.user,
    department: state.adDepartment,

  };
}

function mapDispatchToProps(dispatch) {
  return {
    workflowSettingActions: bindActionCreators(workflowSettingActions, dispatch),
    allowAppCreateActions: bindActionCreators(allowAppCreateActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    departmentActions: bindActionCreators(departmentActions, dispatch),

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowSetting);
