import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx';
import moment from 'moment';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Divider from '@mui/material/Divider';
import Chip from '@mui/material/Chip';

import {
  INTEGER_NUMBER,
  DECIMAL_NUMBER,
  STRING,
  DATE_STRING,
  NONE,
} from 'constants/admin/FieldDataTypes';

const ActionRuleComp = (props) => {
  const [rules, setRules] = useState([]);
  const [condition, setCondition] = useState('==');
  const [disabled, setDisabled] = useState(false);
  const [ruleValue, setRuleValue] = useState('');
  const [selectedField, setSelectedField] = useState('');
  const [selectedTargetField, setSelectedTargetField] = useState('');
  const [selectedDataType, setSelectedDataType] = useState(NONE);
  const [selectedTargetDataType, setSelectedTargetDataType] = useState(NONE);
  const [currentDate, setCurrentDate] = useState(false);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (props.rule) {
      var rules = JSON.parse(props.rule);
      setRules(rules);
      props.setActionRule(rules);
    }
    setDisabled(props.disabled);
  }, []);

  const handleDeleteClick = (index, e) => {
    const tempRules = rules.slice();
    tempRules.splice(index, 1);
    setRules(tempRules);
    setMessage('');
    props.setActionRule(tempRules);
  };

  function isIntegerNumeric(value) {
    return /^\d+$/.test(value);
  }
  function isDecimalNumeric(value) {
    return /^\d+\.\d+$/.test(value);
  }
  const handleAddClick = (e) => {

    if (selectedField !== '' && condition !== '') {
      if (selectedTargetField !== "") {
        if (selectedDataType !== selectedTargetDataType) {
          setMessage('Invalid Target Field. Must have same datatype');
          return;
        }
      } else {
        if (selectedDataType === INTEGER_NUMBER) {
          if (!isIntegerNumeric(ruleValue)) {
            setMessage('Invalid Rule Value. Must be an Integer number');
            return;
          }
        } else if (selectedDataType === DECIMAL_NUMBER) {
          if (!isDecimalNumeric(ruleValue)) {
            setMessage('Invalid Rule Value. Must be Decimal number');
            return;
          }
        } else if (selectedDataType == DATE_STRING) {
          if (!currentDate) {
            if (!moment(ruleValue, 'YYYY-MM-DD', true).isValid()) {
              setMessage("Invalid Rule Value. Must be a valid date with the format: 'YYYY-MM-DD'");
              return;
            }
          }
        }
      }

      var rule = {
        field_id: selectedField.toString(),
        condition,
        value: currentDate ? "CURRENT_DATE" : ruleValue,
        target_field_id: selectedTargetField.toString(),
      };
      setRules([...rules, rule]);
      setMessage('');
      setRuleValue('');
      setSelectedField('');
      setSelectedTargetField('');
      props.setActionRule([...rules, rule]);
    } else {
      setMessage('Invalid rule');
    }
  };

  const onSelectedFieldChange = (e) => {
    setSelectedField(e.target.value);
    setSelectedDataType(props.field_list.find((obj) => obj.id === e.target.value).field_data_type);
  };
  const onSelectedTargetFieldChange = (e) => {
    setSelectedTargetField(e.target.value);
    setSelectedTargetDataType(props.field_list.find((obj) => obj.id === e.target.value).field_data_type);
  };
  const onConditionChange = (e) => {
    setCondition(e.target.value);
  };
  const onRuleValueChange = (e) => {
    setRuleValue(e.target.value);
  };
  const onCurrentDateChange = (e) => {
    setCurrentDate(e.target.checked);
  };

  const getRuleConditionBasedOnDataType = (value) => {
    var rtnComp = [];
    switch (value) {
      case NONE:
        break;
      case INTEGER_NUMBER:
        rtnComp.push(<MenuItem key="==" value="=="> equal </MenuItem>);
        rtnComp.push(<MenuItem key="!=" value="!="> not equal</MenuItem>);
        rtnComp.push(<MenuItem key=">" value=">"> greater than </MenuItem>);
        rtnComp.push(<MenuItem key="<" value="<"> less than </MenuItem>);
        rtnComp.push(<MenuItem key=">=" value=">="> greater than equal </MenuItem>);
        rtnComp.push(<MenuItem key="<=" value="<="> less than equal </MenuItem>);
        break;
      case DECIMAL_NUMBER:
        rtnComp.push(<MenuItem key="==" value="=="> equal </MenuItem>);
        rtnComp.push(<MenuItem key="!=" value="!="> not equal</MenuItem>);
        rtnComp.push(<MenuItem key=">" value=">"> greater than </MenuItem>);
        rtnComp.push(<MenuItem key="<" value="<"> less than </MenuItem>);
        rtnComp.push(<MenuItem key=">=" value=">="> greater than equal </MenuItem>);
        rtnComp.push(<MenuItem key="<=" value="<="> less than equal </MenuItem>);
        break;
      case STRING:
        rtnComp.push(<MenuItem key="==" value="=="> equal </MenuItem>);
        rtnComp.push(<MenuItem key="!=" value="!="> not equal</MenuItem>);
        rtnComp.push(<MenuItem key="LIKE" value="LIKE"> like</MenuItem>);
        rtnComp.push(<MenuItem key="NOT_LIKE" value="NOT_LIKE">not like</MenuItem>);
        break;
      case DATE_STRING:
        rtnComp.push(<MenuItem key="==" value="=="> equal </MenuItem>);
        rtnComp.push(<MenuItem key="!=" value="!="> not equal</MenuItem>);
        rtnComp.push(<MenuItem key=">" value=">"> greater than </MenuItem>);
        rtnComp.push(<MenuItem key="<" value="<"> less than </MenuItem>);
        rtnComp.push(<MenuItem key=">=" value=">="> greater than equal </MenuItem>);
        rtnComp.push(<MenuItem key="<=" value="<="> less than equal </MenuItem>);
        break;
      default:
    }
    return rtnComp;
  };

  const Root = styled('div')(({ theme }) => ({
    width: '100%',
    ...theme.typography.body2,
    '& > :not(style) + :not(style)': {
      marginTop: theme.spacing(2),
    },
  }));

  const { classes } = props;
  const ruleItems = [];
  rules.forEach((rule, index) => {
    ruleItems.push(
      <Root>
        <br />
        <Divider >
          <Chip color="primary" label="Action Rule" />
        </Divider>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <FormControl fullWidth className={classes.formControl} disabled>
              <InputLabel id="fieldlabel">Field</InputLabel>
              <Select
                labelId="fieldlabel"
                id="fieldsselect"
                value={rule.field_id}
              >
                {props.field_list.map((field) => (
                  <MenuItem key={field.id} value={field.id}>
                    {`${field.form_name}->${field.label}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
        <br />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <FormControl fullWidth className={classes.formControl} disabled>
              <InputLabel id="targetfieldlabel">Target Field</InputLabel>
              <Select
                labelId="targetfieldlabel"
                id="targetfieldsselect"
                value={rule.target_field_id}
              >
                {props.field_list.map((field) => (
                  <MenuItem key={field.id} value={field.id}>
                    {`${field.form_name}->${field.label}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
        <br />
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <FormControl fullWidth className={classes.formControl} disabled>
              <InputLabel id="typelabel">Condition</InputLabel>
              <Select
                labelId="typelabel"
                id="typeselect"
                value={rule.condition}
              >
                <MenuItem key="LIKE" value="LIKE"> like </MenuItem>
                <MenuItem key="NOT_LIKE" value="NOT_LIKE">not like</MenuItem>
                <MenuItem key="==" value="=="> equal </MenuItem>
                <MenuItem key="!=" value="!="> not equal</MenuItem>
                <MenuItem key=">" value=">"> greater than </MenuItem>
                <MenuItem key="<" value="<"> less than </MenuItem>
                <MenuItem key=">=" value=">="> greater than equal </MenuItem>
                <MenuItem key="<=" value="<="> less than equal </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <TextField
              required={false}
              fullWidth
              label="Value"
              value={rule.value}
              // onChange={onRuleValueChange.bind(this)}
              disabled
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            {disabled ? '' : (
              <IconButton onClick={handleDeleteClick.bind(this, index)}>
                <DeleteIcon />
              </IconButton>
            )}
          </GridItem>
        </GridContainer>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>

          </GridItem>
        </GridContainer>
      </Root>,
    );
    ruleItems.push(<br key={`linebreak${index}`} />);
  });
  return (
    <div>
      {ruleItems}
      <br />
      <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>

      <br />
      <Divider >
        <Chip color="primary" label="New Action Rule" />
      </Divider>
      <GridContainer key="new rule">
        <GridItem xs={12} sm={12} md={12}>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel id="fieldlabel">Field</InputLabel>
            <Select
              labelId="fieldlabel"
              id="fieldsselect"
              value={selectedField}
              onChange={onSelectedFieldChange.bind(this)}
            >
              {props.field_list.map((field) => (
                <MenuItem key={field.id} value={field.id}>
                  {`${field.form_name}->${field.label}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
      </GridContainer>
      <br />
      <GridContainer key="target field">
        <GridItem xs={12} sm={12} md={12}>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel id="targetfieldlabel">Target Field</InputLabel>
            <Select
              labelId="targetfieldlabel"
              id="targetfieldsselect"
              value={selectedTargetField}
              onChange={onSelectedTargetFieldChange.bind(this)}
            >
              {props.field_list.map((field) => (
                <MenuItem key={field.id} value={field.id}>
                  {`${field.form_name}->${field.label}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
      </GridContainer>
      <br />
      <GridContainer>
        <GridItem xs={12} sm={12} md={4}>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel id="typelabel">Condition</InputLabel>
            <Select
              labelId="typelabel"
              id="typeselect"
              value={condition}
              onChange={onConditionChange.bind(this)}
            >
              {getRuleConditionBasedOnDataType(selectedDataType)}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={selectedDataType === DATE_STRING ? 4 : 6}>
          <TextField
            required={false}
            fullWidth
            type={selectedDataType == DATE_STRING ? "date" : "text"}
            label="Value"
            value={ruleValue}
            onChange={onRuleValueChange.bind(this)}
            disabled={disabled}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </GridItem>
        <div style={{ display: selectedDataType === DATE_STRING ? "block" : "none" }}>
          <GridItem xs={12} sm={12} md={3}>
            <FormControlLabel
              value=""
              control={<Checkbox color="primary" size='small' checked={currentDate} onChange={onCurrentDateChange} />}
              label={<Typography style={{ fontSize: 10 }} >Current</Typography>}
              labelPlacement="top"
            />
          </GridItem>
        </div>
        <GridItem xs={12} sm={12} md={1}>
          {disabled ? '' : (
            <IconButton onClick={handleAddClick.bind(this)}>
              <AddIcon />
            </IconButton>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
};
export default withStyles(extendedFormsStyle)(ActionRuleComp);
