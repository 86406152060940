import React, { useState, useEffect } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import MUIDataTable from "mui-datatables";
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from "components/Card/CardFooter.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.jsx';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Switch from "@material-ui/core/Switch";
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import ListAltIcon from '@mui/icons-material/ListAlt';

import extendedFormsStyle from "assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx"

const SentEmailComps = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("Email Logs");
  const [dialogButton, setDialogButton] = useState("");
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [dialogType, setDialogType] = useState("add");
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [emailLogList, setEmailLogList] = useState([]);
  const [subject, setSubject] = useState("");
  const [body, setBody] = useState("");
  const [changeEmail, setChangeEmail] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [sentEmailID, setSentEmailID] = useState("");
  const [pageSize, setPageSize] = useState(100);
  const [pageNum, setPageNum] = useState(1);
  const [orderBy, setOrderBy] = useState('Sent At');
  const [orderByDirec, setOrderByDirec] = useState('desc');
  const [searchStr, setSearchStr] = useState('');


  const { getSentEmailList } = props;
  useEffect(() => {
    getSentEmailList(searchStr, pageSize, pageNum, orderBy, orderByDirec);
  }, [getSentEmailList])

  useEffect(() => {
    setMessage(props.message)
    var rowItems = [];
    for (let i = 0; i < props.sent_email_list.length; i++) {
      var colItems = [];
      colItems.push(i + 1);
      colItems.push(props.sent_email_list[i].workflow_name);
      colItems.push(props.sent_email_list[i].application_name);
      colItems.push(props.sent_email_list[i].subject);
      colItems.push(new Date(props.sent_email_list[i].insert_date).
        toLocaleString('en', {
          weekday: "short", year: "numeric",
          month: "2-digit", day: "numeric", hour: "2-digit", minute: "2-digit"
        }));
      colItems.push(props.sent_email_list[i].to_name);
      colItems.push(props.sent_email_list[i].to_email);
      colItems.push(<div>
        <IconButton onClick={handleShowLogClick.bind(this,
          [props.sent_email_list[i].sent_email_log,
          props.sent_email_list[i].id,
          props.sent_email_list[i].subject,
          props.sent_email_list[i].body,
          ])} title="Show Log"> <ListAltIcon /></IconButton>
      </div>);
      rowItems.push(colItems);
    }
    setData(rowItems)

  }, [props])

  const { classes } = props;
  const columns = [
    "Serial",
    "Workflow",
    "Request",
    "Subject",
    "Sent At",
    "To Name",
    "To Email",
    ""
  ];
  const options = {
    selectableRowsHideCheckboxes: true,
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    selectableRowsOnClick: true,
    selectToolbarPlacement: 'none',
    pagination: true,
    print: true,
    filter: false,
    download: false,
    search: true,
    sortFilterList: false,
    viewColumns: true,
    rowsSelected: [],
    serverSide: true,
    count: props.sent_email_count,
    rowsPerPage: pageSize,
    page: pageNum - 1,
    rowsPerPageOptions: [10, 20, 100],


    onTableChange: (action, tableState) => {
      //console.log(action, tableState);
      switch (action) {
        case 'changeRowsPerPage':
          setPageSize(tableState.rowsPerPage)
          setPageNum(1)
          props.getSentEmailList(searchStr, tableState.rowsPerPage, 1, orderBy, orderByDirec)
          break;
        case 'changePage':
          setPageNum(tableState.page + 1)
          props.getSentEmailList(searchStr, pageSize, tableState.page + 1, orderBy, orderByDirec)
          break;
        case 'sort':
          setOrderByDirec(tableState.sortOrder.direction)
          setOrderBy(tableState.sortOrder.name)
          props.getSentEmailList(searchStr, pageSize, pageNum, tableState.sortOrder.name, tableState.sortOrder.direction)
          break;
        case 'search':
          setSearchStr(tableState.searchText)
          setPageNum(1)
          props.getSentEmailList(tableState.searchText, pageSize, 1, orderBy, orderByDirec)
          break;
      }
    },


  };


  const handleShowLogClick = (e) => {
    setEmailLogList(e[0])
    setSentEmailID(e[1])
    setSubject(e[2])
    setBody(e[3])
    setDialogTitle('Email Log')
    setDialogOpen(true)
    setNewEmail("")
  }

  const handleDialogClose = (e) => {
    setDialogOpen(false)
    setMessage('')
  }

  const logItems = [];
  emailLogList.forEach((obj) => {
    logItems.push(
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            {new Date(obj.insert_date).toLocaleString('en', { weekday: "short", year: "numeric", month: "2-digit", day: "numeric", hour: "2-digit", minute: "2-digit" })}
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            {obj.email_log}
          </GridItem>
        </GridContainer>
        <br />
      </div>
    );

  });


  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Sent Email List</b>
        </CardHeader>
        <CardBody>
          <MUIDataTable
            data={data}
            columns={columns}
            options={options}
          />
          <Dialog fullWidth={true} open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
              <Card>
                <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                  {dialogTitle}
                </CardHeader>
                <br />
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        id="idSubject"
                        label="Subject"
                        type="text"
                        fullWidth
                        value={subject}
                        disabled={true}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        id="idMessageBody"
                        label="Message Body"
                        type="text"
                        multiline
                        rows={10}
                        fullWidth
                        value={body}
                        disabled={true}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <b>Sent At</b>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <b>Log</b>
                    </GridItem>
                  </GridContainer>
                  <br />
                  {logItems}
                </CardBody>
                <CardFooter style={{ textAlign: 'left' }}>
                </CardFooter>
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose.bind(this)} color="primary" round>
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </CardBody>
      </Card>
    </div>
  );
}
export default withStyles(extendedFormsStyle)(SentEmailComps);

