import API from 'Api/api.js';
import { commonBackendCall, getNetworkAdminConfig } from 'Api/common.js';
import {
  GET_INTG_EXT_API_REQUEST,
  GET_INTG_EXT_API_SUCCESS,
  GET_INTG_EXT_API_FAILURE,
  POST_INTG_EXT_API_REQUEST,
  POST_INTG_EXT_API_SUCCESS,
  POST_INTG_EXT_API_FAILURE,
  PATCH_INTG_EXT_API_REQUEST,
  PATCH_INTG_EXT_API_SUCCESS,
  PATCH_INTG_EXT_API_FAILURE,
  DELETE_INTG_EXT_API_REQUEST,
  DELETE_INTG_EXT_API_SUCCESS,
  DELETE_INTG_EXT_API_FAILURE,
} from 'constants/networkadmin/IntgExtAPI';

export function getIntgExtAPIs() {
  return commonBackendCall(
    GET_INTG_EXT_API_REQUEST,
    GET_INTG_EXT_API_SUCCESS,
    GET_INTG_EXT_API_FAILURE,
    API.get('admin/intg-ext-apis', getNetworkAdminConfig()),
  );
}
export function createIntgExtAPI(obj) {
  return commonBackendCall(
    POST_INTG_EXT_API_REQUEST,
    POST_INTG_EXT_API_SUCCESS,
    POST_INTG_EXT_API_FAILURE,
    API.post('admin/intg-ext-apis', obj, getNetworkAdminConfig()),
  );
}
export function updateIntgExtAPI(intgextapiId, obj) {
  return commonBackendCall(
    PATCH_INTG_EXT_API_REQUEST,
    PATCH_INTG_EXT_API_SUCCESS,
    PATCH_INTG_EXT_API_FAILURE,
    API.patch(`admin/intg-ext-apis/${intgextapiId}`, obj, getNetworkAdminConfig()),
  );
}
export function deleteIntgExtAPI(intgextapiId) {
  return commonBackendCall(
    DELETE_INTG_EXT_API_REQUEST,
    DELETE_INTG_EXT_API_SUCCESS,
    DELETE_INTG_EXT_API_FAILURE,
    API.delete(`admin/intg-ext-apis/${intgextapiId}`, getNetworkAdminConfig()),
  );
}
