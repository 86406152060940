import API from 'Api/api.js';
import { commonBackendCall, getAdminConfig } from 'Api/common.js';
import {
  GET_ACTIONTYPE_REQUEST,
  GET_ACTIONTYPE_SUCCESS,
  GET_ACTIONTYPE_FAILURE,
} from 'constants/admin/ActionType';

export function getActionTypes() {
  return commonBackendCall(
    GET_ACTIONTYPE_REQUEST,
    GET_ACTIONTYPE_SUCCESS,
    GET_ACTIONTYPE_FAILURE,
    API.get('admin/action-types', getAdminConfig()),
  );
}
