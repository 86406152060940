import React, { useEffect } from 'react';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

const LinkActionComp = (props) => {

  useEffect(() => {
    props.onLinkAction({ shortcut_token: props.token }, props.redirect);
  }, []);

  const { classes } = props;
  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={6} md={12}>
        Processing...
      </GridItem>
    </GridContainer>
  );
};

export default LinkActionComp;
