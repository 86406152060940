import {
  GET_TASKFORM_REQUEST,
  GET_TASKFORM_SUCCESS,
  GET_TASKFORM_FAILURE,
  POST_TASKFORM_REQUEST,
  POST_TASKFORM_SUCCESS,
  POST_TASKFORM_FAILURE,
  PATCH_TASKFORM_REQUEST,
  PATCH_TASKFORM_SUCCESS,
  PATCH_TASKFORM_FAILURE,
  DELETE_TASKFORM_REQUEST,
  DELETE_TASKFORM_SUCCESS,
  DELETE_TASKFORM_FAILURE,
  GET_TASK_TASKFORMS_REQUEST,
  GET_TASK_TASKFORMS_SUCCESS,
  GET_TASK_TASKFORMS_FAILURE,
} from '../constants/admin/TaskForm';
import {
  RESET_MESSAGE,
} from '../constants/admin/Default';

const initialState = {
  requesting: false,
  message: '',
  taskForm_list: '',
  task_taskforms_list: '',
  refreshTaskForm: false,
};

export default function form(state = initialState, action) {
  switch (action.type) {
    case RESET_MESSAGE:
      return {
        ...state,
        message: action.payload.value,
      };
    case GET_TASK_TASKFORMS_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshTaskForm: false,
        message: '',
      };

    case GET_TASK_TASKFORMS_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        task_taskforms_list: action.payload.data,
      };

    case GET_TASK_TASKFORMS_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_TASKFORM_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshTaskForm: false,
        message: '',
      };

    case GET_TASKFORM_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        taskForm_list: action.payload.data,
      };

    case GET_TASKFORM_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case POST_TASKFORM_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshTaskForm: false,
      };

    case POST_TASKFORM_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshTaskForm: true,
      };

    case POST_TASKFORM_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case PATCH_TASKFORM_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshTaskForm: false,
      };

    case PATCH_TASKFORM_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshTaskForm: true,
      };

    case PATCH_TASKFORM_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case DELETE_TASKFORM_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshTaskForm: false,
      };

    case DELETE_TASKFORM_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshTaskForm: true,
      };

    case DELETE_TASKFORM_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
