import API from 'Api/api.js';
import { commonBackendCall, getAdminConfig } from 'Api/common.js';
import {
  GET_WORKFLOW_SETTING_REQUEST,
  GET_WORKFLOW_SETTING_SUCCESS,
  GET_WORKFLOW_SETTING_FAILURE,
  PATCH_WORKFLOW_SETTING_REQUEST,
  PATCH_WORKFLOW_SETTING_SUCCESS,
  PATCH_WORKFLOW_SETTING_FAILURE,
  GET_WORKFLOW_USER_REQUEST,
  GET_WORKFLOW_USER_SUCCESS,
  GET_WORKFLOW_USER_FAILURE,
  POST_WORKFLOW_USER_REQUEST,
  POST_WORKFLOW_USER_SUCCESS,
  POST_WORKFLOW_USER_FAILURE,
  DELETE_WORKFLOW_USER_REQUEST,
  DELETE_WORKFLOW_USER_SUCCESS,
  DELETE_WORKFLOW_USER_FAILURE,
  SET_SELECTED_USER,
  POST_WORKFLOWUSER_FILE_UPLOAD_REQUEST,
  POST_WORKFLOWUSER_FILE_UPLOAD_SUCCESS,
  POST_WORKFLOWUSER_FILE_UPLOAD_FAILURE,
} from 'constants/admin/WorkflowSetting';

export function getFileUploadConfig() {
  const config = {
    headers: {
      Token: localStorage.getItem('admin_token'),
      Apikey: localStorage.getItem('network_api_key'),
      'content-type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
    }
  };
  return config;
}

export function setSelectedUser(user, userID) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_USER,
      payload: {
        user, userID
      },
    });
  };
}

export function getWorkflowSetting() {
  return commonBackendCall(
    GET_WORKFLOW_SETTING_REQUEST,
    GET_WORKFLOW_SETTING_SUCCESS,
    GET_WORKFLOW_SETTING_FAILURE,
    API.get('admin/workflow-settings', getAdminConfig()),
  );
}

export function updateWorkflowSetting(obj) {
  return commonBackendCall(
    PATCH_WORKFLOW_SETTING_REQUEST,
    PATCH_WORKFLOW_SETTING_SUCCESS,
    PATCH_WORKFLOW_SETTING_FAILURE,
    API.patch('admin/workflow-settings', obj, getAdminConfig()),
  );
}


export function addUserToWorkflowUser(obj) {
  return commonBackendCall(
    POST_WORKFLOW_USER_REQUEST,
    POST_WORKFLOW_USER_SUCCESS,
    POST_WORKFLOW_USER_FAILURE,
    API.post('admin/workflows/users', obj, getAdminConfig()),
  );
}

export function removeUserFromWorkflowUser(userID) {
  return commonBackendCall(
    DELETE_WORKFLOW_USER_REQUEST,
    DELETE_WORKFLOW_USER_SUCCESS,
    DELETE_WORKFLOW_USER_FAILURE,
    API.delete(`admin/workflows/users/${userID}`, getAdminConfig()),
  );
}

export function getWorkflowUsers(pageNum,pageSize,orderby,sort) {
  return commonBackendCall(
    GET_WORKFLOW_USER_REQUEST,
    GET_WORKFLOW_USER_SUCCESS,
    GET_WORKFLOW_USER_FAILURE,
    API.get(`admin/workflows/users?page=${pageNum}&limit=${pageSize}&orderby=${orderby}&sort=${sort}`, getAdminConfig()),
  );
}

export function postWorkflowUserFileUpload(obj) {
  return commonBackendCall(
    POST_WORKFLOWUSER_FILE_UPLOAD_REQUEST,
    POST_WORKFLOWUSER_FILE_UPLOAD_SUCCESS,
    POST_WORKFLOWUSER_FILE_UPLOAD_FAILURE,
    API.post('admin/workflows/users/files', obj, getFileUploadConfig()),
  );
}