export const GET_ROLE_REQUEST = 'GET_ROLE_REQUEST';
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS';
export const GET_ROLE_FAILURE = 'GET_ROLE_FAILURE';
export const GET_ADHOC_ROLE_REQUEST = 'GET_ADHOC_ROLE_REQUEST';
export const GET_ADHOC_ROLE_SUCCESS = 'GET_ADHOC_ROLE_SUCCESS';
export const GET_ADHOC_ROLE_FAILURE = 'GET_ADHOC_ROLE_FAILURE';
export const POST_ROLE_REQUEST = 'POST_ROLE_REQUEST';
export const POST_ROLE_SUCCESS = 'POST_ROLE_SUCCESS';
export const POST_ROLE_FAILURE = 'POST_ROLE_FAILURE';
export const PATCH_ROLE_REQUEST = 'PATCH_ROLE_REQUEST';
export const PATCH_ROLE_SUCCESS = 'PATCH_ROLE_SUCCESS';
export const PATCH_ROLE_FAILURE = 'PATCH_ROLE_FAILURE';
export const DELETE_ROLE_REQUEST = 'DELETE_ROLE_REQUEST';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAILURE = 'DELETE_ROLE_FAILURE';
