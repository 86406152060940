import {
  GET_ADMIN_DASH_REQUEST,
} from 'constants/admin/Dashboard';
import {
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAILURE,
  ADMIN_LOGIN_CHOOSE_WORKFLOW,
  GET_WORKFLOWS_REQUEST,
  GET_WORKFLOWS_SUCCESS,
  GET_WORKFLOWS_FAILURE,
} from '../constants/admin/Login';
import {
  UNDEFINED_ERROR,
} from '../constants/admin/Default';

const initialState = {
  requesting: false,
  message: '',
  error_code: '',
  chooseWorkflow: false,
  workflow_list: [],
  login_success: false,
};

export default function login(state = initialState, action) {
  switch (action.type) {
    case UNDEFINED_ERROR:
      return {
        ...state,
        requesting: false,
      };

    case GET_ADMIN_DASH_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        error_code: '',
        login_success: false,
      };

    case ADMIN_LOGIN_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        error_code: '',
        login_success: false,
      };

    case ADMIN_LOGIN_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        login_success: true,
      };

    case ADMIN_LOGIN_CHOOSE_WORKFLOW:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        workflow_list: [],
        chooseWorkflow: true,
      };

    case ADMIN_LOGIN_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        error_code: action.payload.error_code,
      };

    case GET_WORKFLOWS_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
      };

    case GET_WORKFLOWS_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        workflow_list: action.payload.data,
      };

    case GET_WORKFLOWS_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    default:
      return state;
  }
}
