/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react'

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import loginPageStyle from 'assets/jss/material-dashboard-react/views/loginPageStyle.jsx';
import { Navigate ,useParams} from 'react-router-dom';
import * as loginActions from 'actions/networkadmin/LoginActions';
import * as networkActions from 'actions/NetworkActions';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import LoginComp from './Login/LoginComp';
import withStyles from '@material-ui/core/styles/withStyles';

const Login = (props) => {
  const { classes } = props;
  const { login, defaultStates } = props;
  const { onLogin } = props.loginActions;
  const { getNetworkByName, getNetworkByAPIKey, } = props.networkActions;
  const [redirectToNetwork, setRedirectToNetwork] = useState(false)
  const [refreshLogin, setRefreshLogin] = useState(false)
  const [showLoadingPage, setShowLoadingPage] = useState(true)
  const { network_name } = useParams();


  useEffect(() => {
    if (localStorage.getItem('network_api_key')) {
      setShowLoadingPage(false)
    } else {
      setShowLoadingPage(true)
      var networkNameParam = network_name
      if (networkNameParam !== undefined) {
        getNetworkByName(networkNameParam)
        setRefreshLogin(true)
      } else {
        var envApiKey = process.env.REACT_APP_API_KEY
        if (envApiKey !== undefined) {
          getNetworkByAPIKey(envApiKey)
          setRefreshLogin(true)
        } else {
          setRedirectToNetwork(true)
        }
      }
    }
  }, [])



  if (localStorage.getItem('networkadmin_token')) {
    return <Navigate to="/networkadmin/dashboard" />;
  }

  if (redirectToNetwork) {
    return <Navigate to="/networkadmin/public/network?app=networkadmin" />;
  }

  if (refreshLogin) {
    setTimeout(function () {
      window.location.reload();
    }, 500);
  }


  if (showLoadingPage) {
    return (
      <div>Loading...</div>
    );
  } else {
    return (
      <div className={classes.content}>
        <div className={classes.container}>
          {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
          <LoginComp
            email={login.email}
            password={login.password}
            requesting={login.requesting}
            showPassword={login.showPassword}
            message={login.message}
            error_code={login.error_code}
            onLogin={onLogin}
            classes={classes}
            loggedIn={login.loggedIn}
          />
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    login: state.naLogin,
    defaultStates: state.naDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loginActions: bindActionCreators(loginActions, dispatch),
    networkActions: bindActionCreators(networkActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(loginPageStyle)(Login));
