export const GET_TASKFORM_REQUEST = 'GET_TASKFORM_REQUEST';
export const GET_TASKFORM_SUCCESS = 'GET_TASKFORM_SUCCESS';
export const GET_TASKFORM_FAILURE = 'GET_TASKFORM_FAILURE';
export const POST_TASKFORM_REQUEST = 'POST_TASKFORM_REQUEST';
export const POST_TASKFORM_SUCCESS = 'POST_TASKFORM_SUCCESS';
export const POST_TASKFORM_FAILURE = 'POST_TASKFORM_FAILURE';
export const PATCH_TASKFORM_REQUEST = 'PATCH_TASKFORM_REQUEST';
export const PATCH_TASKFORM_SUCCESS = 'PATCH_TASKFORM_SUCCESS';
export const PATCH_TASKFORM_FAILURE = 'PATCH_TASKFORM_FAILURE';
export const DELETE_TASKFORM_REQUEST = 'DELETE_TASKFORM_REQUEST';
export const DELETE_TASKFORM_SUCCESS = 'DELETE_TASKFORM_SUCCESS';
export const DELETE_TASKFORM_FAILURE = 'DELETE_TASKFORM_FAILURE';
export const GET_TASK_TASKFORMS_REQUEST = 'GET_TASK_TASKFORMS_REQUEST';
export const GET_TASK_TASKFORMS_SUCCESS = 'GET_TASK_TASKFORMS_SUCCESS';
export const GET_TASK_TASKFORMS_FAILURE = 'GET_TASK_TASKFORMS_FAILURE';
