import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import * as dashboardActions from 'actions/admin/DashboardActions';
import * as workflowActions from 'actions/admin/WorkflowActions';
import * as loginActions from 'actions/admin/LoginActions';
import DashboardComp from './Dashboard/DashboardComp';

const Dashboard = (props) => {
  const { classes } = props;
  const {
    login, workflow, dashboard, defaultStates,
  } = props;
  const { getAdminDashboard, updateWorkflowState } = props.dashboardActions;
  const { onLogin } = props.loginActions;
  const { getWorkflowByAdmin, updateWorkflowByAdmin, createWorkflowVersion } = props.workflowActions;


  if (login.login_success) {
    window.location.reload();
  }

  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <DashboardComp
        onLogin={onLogin}
        getWorkflowByAdmin={getWorkflowByAdmin}
        updateWorkflowByAdmin={updateWorkflowByAdmin}
        createWorkflowVersion={createWorkflowVersion}
        getAdminDashboard={getAdminDashboard}
        updateWorkflowState={updateWorkflowState}
        workflow={workflow.workflow}
        admin_dashboard={dashboard.admin_dashboard === undefined ? [] : dashboard.admin_dashboard}
        app_opened_year={dashboard.admin_dashboard.app_opened_year === undefined ? [] : dashboard.admin_dashboard.app_opened_year}
        app_started_year={dashboard.admin_dashboard.app_started_year === undefined ? [] : dashboard.admin_dashboard.app_started_year}
        app_archived_year={dashboard.admin_dashboard.app_archived_year === undefined ? [] : dashboard.admin_dashboard.app_archived_year}
        app_opened_week={dashboard.admin_dashboard.app_opened_week === undefined ? [] : dashboard.admin_dashboard.app_opened_week}
        sanityProblems={dashboard.sanityProblems === undefined ? [] : dashboard.sanityProblems}
        refreshWorkflow={workflow.refreshWorkflow}
        patchCompleted={dashboard.patchCompleted}
        requesting={dashboard.requesting}
        message={dashboard.message}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    dashboard: state.adDashboard,
    workflow: state.adWorkflow,
    login: state.adLogin,
    defaultStates: state.adDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dashboardActions: bindActionCreators(dashboardActions, dispatch),
    workflowActions: bindActionCreators(workflowActions, dispatch),
    loginActions: bindActionCreators(loginActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
