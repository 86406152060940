export const GET_APPLICATION_FORM_REQUEST = 'GET_APPLICATION_FORM_REQUEST';
export const GET_APPLICATION_FORM_SUCCESS = 'GET_APPLICATION_FORM_SUCCESS';
export const GET_APPLICATION_FORM_FAILURE = 'GET_APPLICATION_FORM_FAILURE';
export const GET_APPLICATION_ACTION_REQUEST = 'GET_APPLICATION_ACTION_REQUEST';
export const GET_APPLICATION_ACTION_SUCCESS = 'GET_APPLICATION_ACTION_SUCCESS';
export const GET_APPLICATION_ACTION_FAILURE = 'GET_APPLICATION_ACTION_FAILURE';
export const POST_APPLICATION_REQUEST = 'POST_APPLICATION_REQUEST';
export const POST_APPLICATION_SUCCESS = 'POST_APPLICATION_SUCCESS';
export const POST_APPLICATION_FAILURE = 'POST_APPLICATION_FAILURE';
export const POST_APPLICATION_ACTION_REQUEST = 'POST_APPLICATION_ACTION_REQUEST';
export const POST_APPLICATION_ACTION_SUCCESS = 'POST_APPLICATION_ACTION_SUCCESS';
export const POST_APPLICATION_ACTION_FAILURE = 'POST_APPLICATION_ACTION_FAILURE';
export const POST_APPLICATION_COMMITTEE_REQUEST = 'POST_APPLICATION_COMMITTEE_REQUEST';
export const POST_APPLICATION_COMMITTEE_SUCCESS = 'POST_APPLICATION_COMMITTEE_SUCCESS';
export const POST_APPLICATION_COMMITTEE_FAILURE = 'POST_APPLICATION_COMMITTEE_FAILURE';
export const GET_APPLICATION_MESSAGES_REQUEST = 'GET_APPLICATION_MESSAGES_REQUEST';
export const GET_APPLICATION_MESSAGES_SUCCESS = 'GET_APPLICATION_MESSAGES_SUCCESS';
export const GET_APPLICATION_MESSAGES_FAILURE = 'GET_APPLICATION_MESSAGES_FAILURE';
export const GET_APPLICATION_ROLES_REQUEST = 'GET_APPLICATION_ROLES_REQUEST';
export const GET_APPLICATION_ROLES_SUCCESS = 'GET_APPLICATION_ROLES_SUCCESS';
export const GET_APPLICATION_ROLES_FAILURE = 'GET_APPLICATION_ROLES_FAILURE';
export const SET_FORM_SUCCESS_MSG = 'SET_FORM_SUCCESS_MSG';
export const SET_FORM_ERROR_MSG = 'SET_FORM_ERROR_MSG';
export const SET_FIELDS = 'SET_FIELDS';
export const SET_SHOW_FORM = 'SET_SHOW_FORM';
export const RESET_APPLICATION_DETAIL = 'RESET_APPLICATION_DETAIL';
export const POST_APP_ITEM_INBOUND_REQUEST = 'POST_APP_ITEM_INBOUND_REQUEST';
export const POST_APP_ITEM_INBOUND_SUCCESS = 'POST_APP_ITEM_INBOUND_SUCCESS';
export const POST_APP_ITEM_INBOUND_FAILURE = 'POST_APP_ITEM_INBOUND_FAILURE';
export const PATCH_APP_ITEM_PERCENT_REQUEST = 'PATCH_APP_ITEM_PERCENT_REQUEST';
export const PATCH_APP_ITEM_PERCENT_SUCCESS = 'PATCH_APP_ITEM_PERCENT_SUCCESS';
export const PATCH_APP_ITEM_PERCENT_FAILURE = 'PATCH_APP_ITEM_PERCENT_FAILURE';
export const POST_APP_ITEM_DETAIL_REQUEST = 'POST_APP_ITEM_DETAIL_REQUEST';
export const POST_APP_ITEM_DETAIL_SUCCESS = 'POST_APP_ITEM_DETAIL_SUCCESS';
export const POST_APP_ITEM_DETAIL_FAILURE = 'POST_APP_ITEM_DETAIL_FAILURE';
