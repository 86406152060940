import {
  GET_NAMEEXCLUSION_REQUEST,
  GET_NAMEEXCLUSION_SUCCESS,
  GET_NAMEEXCLUSION_FAILURE,
  POST_NAMEEXCLUSION_REQUEST,
  POST_NAMEEXCLUSION_SUCCESS,
  POST_NAMEEXCLUSION_FAILURE,
  PATCH_NAMEEXCLUSION_REQUEST,
  PATCH_NAMEEXCLUSION_SUCCESS,
  PATCH_NAMEEXCLUSION_FAILURE,
  DELETE_NAMEEXCLUSION_REQUEST,
  DELETE_NAMEEXCLUSION_SUCCESS,
  DELETE_NAMEEXCLUSION_FAILURE,
} from '../constants/admin/NameExclusion';
import {
  RESET_MESSAGE,
} from '../constants/admin/Default';

const initialState = {
  requesting: false,
  message: '',
  nameExclusion_list: '',
  refreshNameExclusion: false,
};

export default function nameExclusion(state = initialState, action) {
  switch (action.type) {
    case RESET_MESSAGE:
      return {
        ...state,
        message: action.payload.value,
      };
    case GET_NAMEEXCLUSION_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshNameExclusion: false,
        message: '',
      };

    case GET_NAMEEXCLUSION_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        nameExclusion_list: action.payload.data,
      };

    case GET_NAMEEXCLUSION_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case POST_NAMEEXCLUSION_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshNameExclusion: false,
      };

    case POST_NAMEEXCLUSION_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshNameExclusion: true,
      };

    case POST_NAMEEXCLUSION_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case PATCH_NAMEEXCLUSION_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshNameExclusion: false,
      };

    case PATCH_NAMEEXCLUSION_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshNameExclusion: true,
      };

    case PATCH_NAMEEXCLUSION_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case DELETE_NAMEEXCLUSION_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshNameExclusion: false,
      };

    case DELETE_NAMEEXCLUSION_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshNameExclusion: true,
      };

    case DELETE_NAMEEXCLUSION_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
