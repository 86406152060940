import React, { useState, useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from 'components/CustomButtons/Button.jsx';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import AccessibilityIcon from '@mui/icons-material/Accessibility';
import UserSearch from 'components/UserSearch/UserSearch.jsx';
import { useTranslation } from 'react-i18next';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@mui/material/IconButton';
import CommentIcon from '@mui/icons-material/Comment';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import TextField from '@material-ui/core/TextField';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ItemSearchMultiSelect from 'components/ItemSearchMultiSelect/ItemSearchMultiSelect.jsx';
import MUIDataTable from 'mui-datatables';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { TableFooter, TableCell, TableRow } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import AddIcon from '@mui/icons-material/Add';
import PercentIcon from '@mui/icons-material/Percent';


import { SaveFormToAPI } from 'Api/common.js';

const Item2Comp = (props) => {
  const [dialogItemOpen, setDialogItemOpen] = useState(false);
  const [addItemDialogOpen, setAddItemDialogOpen] = useState(false);
  const [percentItemDialogOpen, setPercentItemDialogOpen] = useState(false);
  const [poPercent, setPoPercent] = useState(0);
  const [poPercentDesc, setPoPercentDesc] = useState("");
  const [message, setMessage] = useState('');
  const [itemID, setItemID] = useState('');
  const [selectedItemName, setSelectedItemName] = useState('');
  const [itemRelatedMUIData, setItemRelatedMUIData] = useState([]);
  const [pageSize, setPageSize] = useState(20);
  const [pageNum, setPageNum] = useState(1);
  const [orderBy, setOrderBy] = useState('item_name');
  const [orderByDirec, setOrderByDirec] = useState('asc');
  const [itemRelationLevel, setItemRelationLevel] = useState('0');
  const [selectedItem, setSelectedItem] = useState([]);
  const [relatedItemList, setRelatedItemList] = useState([]);
  const [tableColumn, setTableColumn] = useState([]);
  const [parentTableColumn, setParentTableColumn] = useState([]);
  const [parentData, setParentData] = useState([]);
  const [searchResultItemIDArray, setSearchResultItemIDArray] = useState([]);
  const [searchResultParentItemIDArray, setSearchResultParentItemIDArray] = useState([]);
  const [searchResultParentItemIDStr, setSearchResultParentItemIDStr] = useState([]);
  const [parentSearchResult, setParentSearchResult] = useState([]);
  const [parentCollectionUI, setParentCollectionUI] = useState([]);
  const [addItemCollectionUI, setAddItemCollectionUI] = useState([]);
  const [dataruleValue, setDataruleValue] = useState([]);
  const [newItemUniqueChar, setNewItemUniqueChar] = useState('');
  const [newItemName, setNewItemName] = useState('');
  const [addItemForParentID, setAddItemForParentID] = useState('');
  const [data, setData] = useState([]);


  const [subData, setSubData] = useState([]);
  const [subTableColumn, setSubTableColumn] = useState([]);

  const [mulTransTotal, setMulTransTotal] = useState(0);
  const [mulTransLabel, setMulTransLabel] = useState("");
  const [mulValue, setMulValue] = useState(0.0);
  const [mulLabel, setMulLabel] = useState("");
  const [radioTransType, setRadioTransType] = useState(0);
  const [mulTransTotalResult, setMulTransTotalResult] = useState(0);
  const [mulTransArrayHistory, setMulTransArrayHistory] = useState([])
  const [mulTransSelectedTotal, setMulTransSelectedTotal] = useState(0)
  const [mulTransSelectedIndex, setMulTransSelectedIndex] = useState([])


  const [dialogOpen, setDialogOpen] = useState(false);
  const [description, setDescription] = useState('');
  const [historyTotal, setHistoryTotal] = useState([]);
  const [remainingTotal, setRemainingTotal] = useState([]);
  const [numOfSelectedRecord, setNumOfSelectedRecord] = useState(0);
  const [initialValue, setIntitialValue] = useState("");
  const [enteredAmount, setEnteredAmount] = useState(0.00);
  const [calculatedAmount, setCalculatedAmount] = useState(0.00);
  const [newRemainingAmount, setNewRemainingAmount] = useState(0.00);
  const [selectedItemID, setSelectedItemID] = useState(0);
  const [selectedIntgFieldID, setSelectedIntgFieldID] = useState(0);
  const [percentage, setPercentage] = useState('');
  const [selectedSubTableIDs, setSelectedSubTableIDs] = useState([]);
  const [itemSearchLevelRowIndex, setItemSearchLevelRowIndex] = useState(-1);

  const { t } = useTranslation();

  useEffect(() => {
    if (props.refreshRelatedItemSearch) {
      props.FindRelatedItems(pageSize, pageNum, orderBy, orderByDirec, props.search_table_related, searchResultParentItemIDStr)
    } else if (props.selected_parent_items.length > 0) {
      var tempParentIDsStr = props.selected_parent_items.join()
      props.FindRelatedItems(pageSize, pageNum, orderBy, orderByDirec, props.search_table_related, tempParentIDsStr)
      setDialogItemOpen(false)
    } else if (props.search_related_item_list.length > 0) {
      var tempSearchResultItemIDArray = []
      props.search_related_item_list.forEach(element => {
        tempSearchResultItemIDArray.push(element.id)
      });
      var tempItemValue = [];
      var tempEmpty = []
      var metaData = { "parents": props.search_related_item_parents, "parent_search_result": "", "items": tempSearchResultItemIDArray, "item_search_result": tempEmpty, "selected_item": tempEmpty }
      tempItemValue.push("")
      tempItemValue.push("")
      tempItemValue.push(JSON.stringify(metaData))
      setSearchResultItemIDArray(tempSearchResultItemIDArray)
      setSearchResultParentItemIDArray(props.search_related_item_parents)
      var tempFields = props.fields;
      var foundIndex = tempFields.findIndex((x) => x.id === props.field_id);
      tempFields[foundIndex].valueArray = tempItemValue
      tempFields[foundIndex].error = false;
      SaveFormToAPI(props.form_id, props.requesting, tempFields, [], [], "", "", props.postApplicationDetail, props.application_id, props.task_id)
    } else if (props.field_value.length > 0) {
      if (props.datatable_rule) {
        var masterRules = JSON.parse(props.datatable_rule);
        var rules = masterRules[1].datatable_rule
        var tempLabel = []
        rules.forEach((rule, i) => {
          if (rule.label.slice(0, 6) === "trans_") {
            tempLabel.push({
              name: rule.label.slice(6), label: rule.label.slice(6),
              options: {
                setCellProps: () => ({
                  align: "right"
                }),
                setCellHeaderProps: (value) => ({
                  style: { textAlign: 'right' }
                })
              }
            })
          } else if (rule.label.slice(0, 10) === "mul_trans_") {
          } else if (rule.label.slice(0, 4) === "mul_") {
          } else {
            tempLabel.push({ name: rule.label, label: rule.label })
          }
        })
        setTableColumn(tempLabel)

        var parentRules = masterRules[0].datatable_rule
        var tempParentLabel = []
        parentRules.forEach((rule, i) => {
          if (rule.label.slice(0, 6) === "trans_") {
            tempParentLabel.push({
              name: rule.label.slice(6), label: rule.label.slice(6),
              options: {
                setCellProps: () => ({
                  align: "left"
                }),
                setCellHeaderProps: (value) => ({
                  style: { textAlign: 'left' }
                })
              }
            })
          } else {
            tempParentLabel.push({ name: rule.label, label: rule.label })
          }
        })
        tempParentLabel.push({ name: "", label: "" })
        setParentTableColumn(tempParentLabel)

      }

      if (props.field_value[1] !== "" && props.field_value[2] !== "") {
        var fieldValue = JSON.parse(props.field_value[1])
        //console.log("fieldValue", fieldValue)
        var rowItems = [];
        var masterRules = JSON.parse(props.datatable_rule);
        var rules = masterRules[1].datatable_rule

        /*************Exception for Normal Use */
        var checkMasterRules = JSON.parse(props.datatable_rule);
        var checkRules = checkMasterRules[1].datatable_rule
        var isMulTransExists = false
        checkRules.forEach((rule, i) => {
          if (rule.label.slice(0, 10) === "mul_trans_") {
            isMulTransExists = true
          }
        })
        /*************Exception for Normal Use */

        for (let j = 0; j < fieldValue.length; j++) {
          //console.log("fieldValue itemID", fieldValue[j]["item_id"])
          var colItems = [];
          rules.forEach((rule, i) => {
            if (rule.label.slice(0, 6) === "trans_") {
              var transFieldValue = JSON.parse(fieldValue[j][rule.label]);
              if (isMulTransExists) {
                if (transFieldValue.trans_value === "") {
                  colItems.push(transFieldValue.initial_value + " - " + transFieldValue.item_history_total + " = " + transFieldValue.item_remaining_total)
                } else {
                  colItems.push(transFieldValue.initial_value + " - " + transFieldValue.item_history_total + " = " + transFieldValue.item_remaining_total + " (" + transFieldValue.trans_value + ") ")
                }
              } else {
                colItems.push(transFieldValue.initial_value)
              }
            } else if (rule.label.slice(0, 10) === "mul_trans_") {
            } else if (rule.label.slice(0, 4) === "mul_") {
            } else {
              if (fieldValue[j]["item_search_level"] === 1) {
                setItemSearchLevelRowIndex(j)
              }
              colItems.push(fieldValue[j][rule.label])
            }
          })
          rowItems.push(colItems)
        }

        var colItemsTotal = [];
        rules.forEach((rule, i) => {
          if (rule.label.slice(0, 6) === "trans_") {
            colItemsTotal.push(props.field_value[0])
          } else {
            colItemsTotal.push("")
          }
        })
        rowItems.push(colItemsTotal)
        setData(rowItems);

        var parentFieldValue = JSON.parse(props.field_value[2])
        setNumOfSelectedRecord(parentFieldValue.selected_item.length)
        var tempSelectedIndex = []

        for (let j = 0; j < parentFieldValue.selected_item.length; j++) {
          const tempIndex = fieldValue.findIndex(object => object.item_id === parentFieldValue.selected_item[j]);
          tempSelectedIndex.push(tempIndex)
        }
        setSelectedItem(tempSelectedIndex)
        setSearchResultItemIDArray(parentFieldValue.items)
        setSearchResultParentItemIDArray(parentFieldValue.parents)
        setParentSearchResult(parentFieldValue.parent_search_result)

        const tempParentCollection = [];
        var parentSearchResultArray = JSON.parse(parentFieldValue.parent_search_result)


        var parentRowItems = [];
        var parentRules = masterRules[0].datatable_rule

        /*************Exception for Normal Use */
        var isTransExists = false
        parentRules.forEach((rule, i) => {
          if (rule.label.slice(0, 6) === "trans_") {
            isTransExists = true
          }
        })
        /*************Exception for Normal Use */

        for (let j = 0; j < parentSearchResultArray.length; j++) {
          var parentColItems = [];
          parentRules.forEach((rule, i) => {
            parentColItems.push(parentSearchResultArray[j][rule.label])
          })
          if (isTransExists) {
            parentColItems.push(
              <div style={{ display: props.is_read_only || !props.form_checkable || props.task_completed ? 'none' : 'block' }}>
                <IconButton
                  onClick={handleAddItem.bind(this, [parentSearchResultArray[j].item_id])}
                  title={`Add`}
                >
                  {' '}
                  <AddIcon />
                </IconButton>
                <IconButton
                  onClick={handlePercentItem.bind(this, [parentSearchResultArray[j].item_id])}
                  title={`Percent`}
                >
                  {' '}
                  <PercentIcon />
                </IconButton>
              </div >
            )
          }
          parentRowItems.push(parentColItems)
        }


        setParentData(parentRowItems);
        setParentCollectionUI(tempParentCollection)
        if (props.datatable_rule) {
          var masterRules = JSON.parse(props.datatable_rule);
          var rules = masterRules[2].datatable_rule
          const tempAddItemCollectionUI = [];
          const tempDataRuleValue = [];
          rules.forEach((rule, i) => {
            var ruleIntgFieldID = parseInt(rule.intgfield_id)
            tempDataRuleValue.push({ "intgfield_id": ruleIntgFieldID, "value": "" })
            tempAddItemCollectionUI.push(
              <div>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      value={dataruleValue.value}
                      fullWidth
                      label={rule.label.slice(0, 6) === "trans_" ? rule.label.slice(6) : rule.label}
                      onChange={onFieldValueChange.bind(this, ruleIntgFieldID)}
                    />
                  </GridItem>
                </GridContainer>
                <br />
              </div>
            )

          })
          setAddItemCollectionUI(tempAddItemCollectionUI)
          setDataruleValue(tempDataRuleValue)
        }
        var tempStrParentIDs = ""
        searchResultParentItemIDArray.forEach(element => {
          if (tempStrParentIDs != "") {
            tempStrParentIDs = tempStrParentIDs + "," + element
          } else {
            tempStrParentIDs = element
          }
        });
        setSearchResultParentItemIDStr(tempStrParentIDs)
        setItemRelationLevel("3")
      }
    }
  }, [props]);

  const handleEditClick = (e) => {
    setDialogOpen(true);

    setIntitialValue(e[0])
    var lclInitialValue = e[0]
    setCalculatedAmount(e[1])
    setSelectedItemID(e[2])
    setSelectedIntgFieldID(e[3])
    setRadioTransType(parseInt(e[4], 10))
    var lclRadioTransType = parseInt(e[4], 10)
    setPercentage(e[5])
    setDescription(e[6])
    setHistoryTotal(e[7])
    var lclHistoryTotal = e[7]


    setMulTransLabel(e[8])
    setMulValue(e[9])
    var lclMulValue = e[9]
    setMulLabel(e[10])

    setMulTransArrayHistory(JSON.parse(e[11]))
    setMulTransTotal(e[12])

    var lclMulTransHistoryArray = JSON.parse(e[11])
    var lclMulTransTotal = e[12]
    var lctSelectedSubTableIDs = e[13]

    var tempItemIndex = []
    var seletedItemTotal = 0.0
    lctSelectedSubTableIDs.forEach(element => {
      var tempElement = parseInt(element, 10)
      var foundIndex = lclMulTransHistoryArray.findIndex((x) => x.id === tempElement);
      if (foundIndex > -1) {
        tempItemIndex.push(foundIndex)
      }
    })
    setMulTransSelectedIndex(tempItemIndex)

    setSubTableColumn(["Date", "Description", "Tag", e[8]])

    var rowItems = [];
    for (let j = 0; j < lclMulTransHistoryArray.length; j++) {
      var colItems = [];
      colItems.push(new Date(lclMulTransHistoryArray[j].insert_date).toLocaleString(localStorage.getItem('network_lang'), { year: "numeric", month: "2-digit", day: "numeric" }))
      colItems.push(lclMulTransHistoryArray[j].trans_desc)
      colItems.push(lclMulTransHistoryArray[j].trans_tag)
      colItems.push(lclMulTransHistoryArray[j].trans_value)
      rowItems.push(colItems)
    }

    var colItemsTotal = [];
    colItemsTotal.push("")
    colItemsTotal.push("")
    colItemsTotal.push("")
    colItemsTotal.push(lclMulTransTotal)
    rowItems.push(colItemsTotal)
    setSubData(rowItems);


    var tempInitialAmount = parseFloat(e[0]).toFixed(2)
    var tempHistoryAmount = parseFloat(e[7]).toFixed(2)
    var tempRemainingTotal = (tempInitialAmount - tempHistoryAmount).toFixed(2)
    setRemainingTotal(tempRemainingTotal)


    if (lclRadioTransType == "2") {
      var tempSelectedValue = 0.0
      var tempSelectedID = []
      lctSelectedSubTableIDs.forEach(element => {
        var tempElement = parseInt(element, 10)
        var foundIndex = lclMulTransHistoryArray.findIndex((x) => x.id === tempElement);
        if (foundIndex > -1) {
          tempSelectedValue = tempSelectedValue + parseFloat(lclMulTransHistoryArray[foundIndex].trans_value)
          tempSelectedID.push(foundIndex)
        }
      })
      setMulTransSelectedIndex(tempSelectedID)

      tempSelectedValue = tempSelectedValue.toFixed(2)
      setMulTransSelectedTotal(tempSelectedValue)
      var tempMulValue = parseFloat(lclMulValue).toFixed(2)
      var tempCalc = 0.0
      tempCalc = (tempSelectedValue * tempMulValue).toFixed(2)
      setMulTransTotalResult(tempCalc)

      setCalculatedAmount(tempCalc)

      var tempInitialAmount = parseFloat(lclInitialValue).toFixed(2)
      var tempHistoryAmount = parseFloat(lclHistoryTotal).toFixed(2)
      var tempRemainingTotal = (tempInitialAmount - tempHistoryAmount).toFixed(2)

      setNewRemainingAmount((tempRemainingTotal - tempCalc).toFixed(2))
      //console.log("tempSelectedID:", tempSelectedID)
      setSelectedSubTableIDs(lctSelectedSubTableIDs)

    } else if (lclRadioTransType == 0) {
      var tempSelectedValue = parseFloat(e[1]).toFixed(2)
      if (tempSelectedValue === 'NaN') {
        setNewRemainingAmount(tempRemainingTotal)
        setCalculatedAmount(0.00)
      } else {
        setEnteredAmount(tempSelectedValue)
        setCalculatedAmount(tempSelectedValue)
        setNewRemainingAmount((tempRemainingTotal - tempSelectedValue).toFixed(2))
      }
    } else if (lclRadioTransType == 1) {

      var tempPercentage = parseFloat(e[5]).toFixed(2)
      var tempCalc = (tempPercentage * tempRemainingTotal) / 100
      setCalculatedAmount(tempCalc.toFixed(2))
      setNewRemainingAmount((tempRemainingTotal - tempCalc).toFixed(2))
    }
  };

  const handleDialogClose = (e) => {
    setDialogOpen(false);
    setMessage('');
  };
  const handleDialogConfirm = (e) => {

    var tempInitialAmount = parseFloat(initialValue).toFixed(2)
    var tempHistoryAmount = parseFloat(historyTotal).toFixed(2)
    var tempCalculatedAmount = parseFloat(calculatedAmount).toFixed(2)
    var tempRemainingTotal = (tempInitialAmount - tempHistoryAmount).toFixed(2)

    if (fp_greater_than(tempCalculatedAmount, tempRemainingTotal)) {
      setMessage("Invalid amount")
      return
    }
    setDialogOpen(false);
    setMessage('');

    var rtnField = []
    var tempFields = props.fields;
    var foundIndex = tempFields.findIndex((x) => x.id === props.field_id);
    if (tempFields[foundIndex].valueArray.length > 1) {
      rtnField.push(tempFields[foundIndex].valueArray[0])
      var aryField = JSON.parse(tempFields[foundIndex].valueArray[1])
      var tempAryField = aryField
      for (let x = 0; x < tempAryField.length; x++) {
        for (var key in tempAryField[x]) {
          if (key.slice(0, 6) === "trans_") {
            var aryTrans = JSON.parse(tempAryField[x][key])
            var tempAryTrans = aryTrans
            var transOptDetail = []
            if (radioTransType == 1) {
              var tempPercent = parseFloat(percentage).toFixed(2)
              transOptDetail.push(+tempPercent)
            } else if (radioTransType == 2) {
              transOptDetail = selectedSubTableIDs
            }
            var transDetail = { "trans_opt_type": radioTransType, "trans_opt_detail": transOptDetail.map(String) }
            if (tempAryTrans.item_id === selectedItemID && tempAryTrans.intgfield_id == selectedIntgFieldID) {
              tempAryTrans.trans_value = calculatedAmount
              tempAryTrans.trans_desc = description
              tempAryTrans.trans_detail = JSON.stringify(transDetail)
              //tempAryTrans.percentage = percentage
            }
            tempAryField[x][key] = JSON.stringify(tempAryTrans)
          }
        }
      }
      tempFields[foundIndex].valueArray[1] = JSON.stringify(tempAryField)
    }
    //console.log("tempFields:", tempFields)
    SaveFormToAPI(props.form_id, props.requesting, tempFields, [], [], "", "", props.postApplicationDetail, props.application_id, props.task_id)
  };

  const onPercentageChange = (e) => {
    setMessage("")
    setPercentage(e.target.value)
    var tempInitialAmount = parseFloat(initialValue).toFixed(2)
    var tempHistoryAmount = parseFloat(historyTotal).toFixed(2)
    var tempRemainingTotal = (tempInitialAmount - tempHistoryAmount).toFixed(2)

    if (!isNumber(e.target.value.trim())) {
      setMessage("Invalid Percentage Number")
      setNewRemainingAmount(tempRemainingTotal)
      setCalculatedAmount(0.00)
      return
    }

    var tempNum = parseFloat(e.target.value.trim()).toFixed(2);
    if (tempNum === 'NaN') {
      setNewRemainingAmount(tempRemainingTotal)
      setCalculatedAmount(0.00)
      return
    }
    if (tempNum < 0.01 || tempNum > 100) {
      setMessage("Percentage Number should be between 0.01 and 100")
      setNewRemainingAmount(tempRemainingTotal)
      setCalculatedAmount(0.00)
      return
    }

    var tempCalc = (tempNum * tempRemainingTotal) / 100
    setCalculatedAmount(tempCalc.toFixed(2))
    setNewRemainingAmount((tempRemainingTotal - tempCalc).toFixed(2))
  };


  const onRadioValueChange = (e) => {
    if (props.is_read_only || !props.form_checkable || props.task_completed) {
      return
    }
    setRadioTransType(parseInt(e.target.value, 10))
    var tempInitialAmount = parseFloat(initialValue).toFixed(2)
    var tempHistoryAmount = parseFloat(historyTotal).toFixed(2)
    var tempRemainingTotal = (tempInitialAmount - tempHistoryAmount).toFixed(2)
    setEnteredAmount(0.0)
    setPercentage(0.0)
    setCalculatedAmount(0.0)
    setNewRemainingAmount(tempRemainingTotal)
  };
  const onEnteredAmountChange = (e) => {
    setMessage("")
    setEnteredAmount(e.target.value)
    var tempInitialAmount = parseFloat(initialValue).toFixed(2)
    var tempHistoryAmount = parseFloat(historyTotal).toFixed(2)
    var tempRemainingTotal = (tempInitialAmount - tempHistoryAmount).toFixed(2)

    if (!isNumber(e.target.value.trim())) {
      setMessage("Invalid Amount")
      setNewRemainingAmount(tempRemainingTotal)
      setCalculatedAmount(0.00)
      return
    }

    var tempNum = parseFloat(e.target.value.trim()).toFixed(2);
    if (tempNum === 'NaN') {
      setNewRemainingAmount(tempRemainingTotal)
      setCalculatedAmount(0.00)
      return
    }

    setCalculatedAmount(tempNum)
    setNewRemainingAmount((tempRemainingTotal - tempNum).toFixed(2))
  };
  const onDescriptionChange = (e) => {
    setDescription(e.target.value);
  };


  const onFieldValueChange = (intgfieldID, e) => {
    var tempDataRuleValue = dataruleValue
    var index = dataruleValue.findIndex((o) => o.intgfield_id === intgfieldID)
    tempDataRuleValue[index].value = e.target.value
    setDataruleValue(tempDataRuleValue)
  }
  const onPOPercentageDescChange = (e) => {
    setPoPercentDesc(e.target.value);
  }
  const onPOPercentageChange = (e) => {
    setPoPercent(e.target.value)
    setMessage("")
    if (!isNumber(e.target.value.trim())) {
      setMessage("Invalid Percentage Number")
      return
    }
    var tempNum = parseFloat(e.target.value.trim()).toFixed(2);
    if (tempNum === 'NaN') {
      return
    }
    if (tempNum < 0.01 || tempNum > 100) {
      setMessage("Percentage Number should be between 0.01 and 100")
      return
    }
  }

  const ontNewItemUniqueCharChange = (e) => {
    setNewItemUniqueChar(e.target.value);
  }
  const onNewItemNameChange = (e) => {
    setNewItemName(e.target.value);
  }
  const handleAddItem = (param, e) => {
    if (param.length > 0) {
      setAddItemForParentID(param[0])
    }
    setAddItemDialogOpen(true);
  }
  const handlePercentItem = (param, e) => {
    if (param.length > 0) {
      setAddItemForParentID(param[0])
    }
    setPercentItemDialogOpen(true);
  }
  const handleDialogItemSearchClose = (e) => {
    setDialogItemOpen(false);
    setMessage('');
  };

  const handleAddItemDialogClose = (e) => {
    setAddItemDialogOpen(false);
    setMessage('');
  };
  const handlePercentItemDialogClose = (e) => {
    setPercentItemDialogOpen(false);
    setMessage('');
  };

  const handleAddItemDialogConfirm = (e) => {
    setAddItemDialogOpen(false);
    setMessage('');
    if (parentSearchResult.length > 0) {
      var obj = { "item_sku_character": newItemUniqueChar, "name": newItemName, "parent_item_id": addItemForParentID.toString(), "intgfield": dataruleValue }
      var jsonObj = JSON.stringify(obj)
      props.postItem(jsonObj)
    }
  };

  const handlePercentItemDialogConfirm = (e) => {
    setPercentItemDialogOpen(false);
    setMessage('');
    if (parentSearchResult.length > 0) {
      var obj = { "item_id": addItemForParentID.toString(), "application_id": props.application_id.toString(), "field_id": props.field_id.toString(), "intgfield_id": selectedIntgFieldID.toString(), "percent": poPercent, "description": poPercentDesc }
      var jsonObj = JSON.stringify(obj)
      props.patchItemPercent(jsonObj)
    }
  };
  const handleShowItemSearchClick = (e) => {
    if (props.form_checkable) {
      props.setSelectedItem('', 0);
      setDialogItemOpen(true);
    }
  };

  const subTableOptions = {
    filterType: 'dropdown',
    responsive: 'standard',
    filter: false,
    download: false,
    print: false,
    search: false,
    viewColumns: false,
    sort: false,
    pagination: false,
    rowsSelected: mulTransSelectedIndex,
    selectableRowsHeader: false,
    rowsPerPage: 100,
    selectableRows: 'multiple',
    selectToolbarPlacement: 'none',
    expandableRows: false,
    expandableRowsHeader: false,
    expandableRowsOnClick: false,
    onRowSelectionChange: (rowsSelected, allRows, onlySelectedIndex) => {
      if (radioTransType == 2) {
        setMulTransSelectedIndex(onlySelectedIndex)
        var tempSelectedValue = 0.0
        var tempSelectedID = []
        allRows.forEach(element => {
          if (element.dataIndex < mulTransArrayHistory.length) {
            tempSelectedID.push(mulTransArrayHistory[element.dataIndex].id)
            tempSelectedValue = tempSelectedValue + parseFloat(mulTransArrayHistory[element.dataIndex].trans_value)
          }
        })
        tempSelectedValue = tempSelectedValue.toFixed(2)
        setMulTransSelectedTotal(tempSelectedValue)
        var tempMulValue = parseFloat(mulValue).toFixed(2)
        var tempCalc = 0.0
        tempCalc = (tempSelectedValue * tempMulValue).toFixed(2)
        setMulTransTotalResult(tempCalc)

        setCalculatedAmount(tempCalc)

        var tempInitialAmount = parseFloat(initialValue).toFixed(2)
        var tempHistoryAmount = parseFloat(historyTotal).toFixed(2)
        var tempRemainingTotal = (tempInitialAmount - tempHistoryAmount).toFixed(2)

        setNewRemainingAmount((tempRemainingTotal - tempCalc).toFixed(2))
        setSelectedSubTableIDs(tempSelectedID)
      }

    },
    isRowSelectable: (dataIndex, selectedRows) => {
      if (props.is_read_only || !props.form_checkable || props.task_completed) {
        return false
      }
      if (dataIndex < mulTransArrayHistory.length) {
        return true
      }
      return false
    }
    //function(dataIndex: number, selectedRows: object(lookup: {dataindex: boolean}, data: arrayOfObjects: {index, dataIndex})) => boolean
    //selectableRowsOnClick Enable/disable select toggle when row is clicked. When False, only checkbox will trigger this action.
  }
  const parentMUIDataOptions = {
    filterType: 'dropdown',
    //responsive: 'standard',
    selectableRows: 'none',
    selectableRowsOnClick: false,
    selectToolbarPlacement: 'none',
    pagination: false,
    print: false,
    filter: false,
    download: false,
    search: false,
    sortFilterList: false,
    viewColumns: false,
    //rowsSelected: selectedItem,
    //serverSide: true,
    //count: props.search_related_item_count,
    //rowsPerPage: pageSize,
    //page: pageNum - 1,
    //rowsPerPageOptions: [5, 10, 20, 100],
    expandableRows: false,
    expandableRowsOnClick: false,
    selectableRowsHeader: false,
  }
  const relatedItemMUIDataOptions = {
    //selectableRowsHideCheckboxes: false,
    filterType: 'dropdown',
    //responsive: 'standard',
    selectableRows: 'multiple',
    selectableRowsOnClick: false,
    selectToolbarPlacement: 'none',
    pagination: true,
    print: false,
    filter: false,
    download: false,
    search: false,
    sortFilterList: false,
    viewColumns: false,
    rowsSelected: selectedItem,
    serverSide: true,
    count: props.search_related_item_count,
    rowsPerPage: pageSize,
    page: pageNum - 1,
    rowsPerPageOptions: [5, 10, 20, 100],
    expandableRows: true,
    expandableRowsOnClick: true,
    selectableRowsHeader: false,
    setRowProps: (row, index) => {
      if (itemSearchLevelRowIndex === index) {
        return { style: { backgroundColor: '#e6e2f2' }, };
      }
    },
    renderExpandableRow: (rowData, rowMeta) => {

      /*************Exception for Normal Use */
      var checkMasterRules = JSON.parse(props.datatable_rule);
      var checkRules = checkMasterRules[1].datatable_rule
      var isMulTransExists = false
      checkRules.forEach((rule, i) => {
        if (rule.label.slice(0, 10) === "mul_trans_") {
          isMulTransExists = true
        }
      })
      if (!isMulTransExists) {
        return
      }
      /*************Exception for Normal Use */

      let index = selectedItem.findIndex(obj => obj === rowMeta.dataIndex);
      if (index === -1) {
        return
      }
      if (props.field_value.length > 1) {
        if (props.field_value[1] === '') {
          return
        }
      }

      var lclInitialValue = ""
      var lclSelectedValue = ""
      var lclSelectedValueDesc = ""
      var lclSelectedItemID = 0
      var lclSelectedIntgFieldID = 0
      var lclTransType = 1
      var lclTag = false
      var lclSelectedPercentage = 0
      var lclHistory = []
      var lclHistoryUI = []
      var lclHistoryTotal = ""

      var lclHistoryTotalUI = []
      var lclMulTransJSON = ""
      var lclMulTransLabel = ""
      var lclMulValue = ""
      var lclMulLabel = ""
      var lclMulTransJSONArrayHistory = []
      var lclMulTransJSONArrayTotal = []
      var lctSelectedSubTableIDs = []

      const colSpan = rowData.length + 1;
      var fieldValue = JSON.parse(props.field_value[1])
      var masterRules = JSON.parse(props.datatable_rule);
      var rules = masterRules[1].datatable_rule
      for (let j = 0; j < fieldValue.length; j++) {
        if (j === rowMeta.rowIndex) {
          rules.forEach((rule, i) => {
            if (rule.label.slice(0, 6) === "trans_") {
              var transFieldValue = JSON.parse(fieldValue[j][rule.label]);
              var lclTransDetail = JSON.parse(transFieldValue.trans_detail)
              lclTransType = parseInt(lclTransDetail.trans_opt_type, 10)
              if (lclTransDetail.trans_opt_type == 1) {
                if (lclTransDetail.trans_opt_detail.length > 0) {
                  lclSelectedPercentage = lclTransDetail.trans_opt_detail[0]
                }
              } else if (lclTransDetail.trans_opt_type == 2) {
                if (lclTransDetail.trans_opt_detail.length > 0) {
                  lctSelectedSubTableIDs = lclTransDetail.trans_opt_detail
                }
              }
              lclInitialValue = transFieldValue.initial_value
              lclSelectedValue = transFieldValue.trans_value
              lclSelectedValueDesc = transFieldValue.trans_desc
              lclSelectedItemID = transFieldValue.item_id
              lclSelectedIntgFieldID = transFieldValue.intgfield_id
              lclTag = transFieldValue.trans_tag
              lclHistory = JSON.parse(transFieldValue.history)
              lclHistoryTotal = transFieldValue.item_history_total
            } else if (rule.label.slice(0, 10) === "mul_trans_") {
              lclMulTransJSON = fieldValue[j][rule.label]
              var lclMulTransJSONArray = JSON.parse(lclMulTransJSON);
              lclMulTransJSONArrayHistory = lclMulTransJSONArray.history



              lclMulTransJSONArrayTotal = lclMulTransJSONArray.item_history_total
              //setMulTransTotal(lclMulTransJSONArray.item_history_total)
              lclMulTransLabel = rule.label.slice(10)
            } else if (rule.label.slice(0, 4) === "mul_") {
              lclMulValue = fieldValue[j][rule.label]
              lclMulLabel = rule.label.slice(4)
            }
          })
        }
      }




      const tempTransHistoryItems = [];
      //console.log("Khalid Test", JSON.parse(lclHistory))
      lclHistory.forEach((element, i) => {
        tempTransHistoryItems.push(
          <TableRow>
            <TableCell>{new Date(element.insert_date).toLocaleString(localStorage.getItem('network_lang'), { year: "numeric", month: "2-digit", day: "numeric" })}</TableCell>
            <TableCell>{element.trans_tag}</TableCell>
            <TableCell>{element.trans_desc}</TableCell>
            <TableCell>{element.trans_value}</TableCell>
          </TableRow>
        )
      })
      lclHistoryUI = tempTransHistoryItems
      lclHistoryTotalUI.push(<TableRow>
        <TableCell>{ }</TableCell>
        <TableCell>{ }</TableCell>
        <TableCell>{ }</TableCell>
        <TableCell>{lclHistoryTotal}</TableCell>
      </TableRow>)

      var fltInitialAmount = parseFloat(lclInitialValue).toFixed(2)
      var fltSelectedValue = parseFloat(lclSelectedValue).toFixed(2)
      var fltHistoryValue = parseFloat(lclHistoryTotal).toFixed(2)
      var fltRemainingTotal = (fltInitialAmount - fltHistoryValue).toFixed(2)
      var fltNewRemaining = (fltRemainingTotal - fltSelectedValue).toFixed(2)
      if (fltNewRemaining === 'NaN') {
        fltNewRemaining = fltInitialAmount
      }

      return (
        <React.Fragment>
          <tr  >
            <td colSpan={colSpan} >
              <TableContainer component={Paper} style={{ paddingLeft: 150, paddingRight: 150 }} >
                <div style={{ padding: 10, fontWeight: 'bold' }}> New Payment</div>
                <Table style={{ minWidth: "250" }} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell>Actual Amount</TableCell>
                      <TableCell>{lclInitialValue}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>History Total</TableCell>
                      <TableCell>{lclHistoryTotal}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Remaining Total</TableCell>
                      <TableCell>{fltRemainingTotal}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>New Payment Amount</TableCell>
                      <TableCell>{lclSelectedValue}</TableCell>
                      <TableCell>
                        <div style={{
                          cursor: 'pointer'
                        }} onClick={handleEditClick.bind(this, [lclInitialValue, lclSelectedValue, lclSelectedItemID, lclSelectedIntgFieldID, lclTransType, lclSelectedPercentage, lclSelectedValueDesc, lclHistoryTotal, lclMulTransLabel, lclMulValue, lclMulLabel, lclMulTransJSONArrayHistory, lclMulTransJSONArrayTotal, lctSelectedSubTableIDs])} >
                          <IconButton onClick={handleEditClick.bind(this, [lclInitialValue, lclSelectedValue, lclSelectedItemID, lclSelectedIntgFieldID, lclTransType, lclSelectedPercentage, lclSelectedValueDesc, lclHistoryTotal, lclMulTransLabel, lclMulValue, lclMulLabel, lclMulTransJSONArrayHistory, lclMulTransJSONArrayTotal, lctSelectedSubTableIDs])} title="Edit Payment" > {' '}
                            <EditIcon />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Balance After New Payment</TableCell>
                      <TableCell>{fltNewRemaining}</TableCell>
                      <TableCell></TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>Description</TableCell>
                      <TableCell>{lclSelectedValueDesc}</TableCell>
                      <TableCell>
                        <div style={{
                          cursor: 'pointer'
                        }} onClick={handleEditClick.bind(this, [lclInitialValue, lclSelectedValue, lclSelectedItemID, lclSelectedIntgFieldID, lclTransType, lclSelectedPercentage, lclSelectedValueDesc, lclHistoryTotal, lclMulTransLabel, lclMulValue, lclMulLabel, lclMulTransJSONArrayHistory, lclMulTransJSONArrayTotal, lctSelectedSubTableIDs])} >
                          <IconButton onClick={handleEditClick.bind(this, [lclInitialValue, lclSelectedValue, lclSelectedItemID, lclSelectedIntgFieldID, lclTransType, lclSelectedPercentage, lclSelectedValueDesc, lclHistoryTotal, lclMulTransLabel, lclMulValue, lclMulLabel, lclMulTransJSONArrayHistory, lclMulTransJSONArrayTotal, lctSelectedSubTableIDs])} title="Edit Payment" > {' '}
                            <EditIcon />
                          </IconButton>
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
              <br />
              <TableContainer component={Paper} style={{ paddingLeft: 150, paddingRight: 150 }} >
                <div style={{ padding: 10, fontWeight: 'bold' }}>Payment History</div>
                <Table style={{ minWidth: "650" }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Date</TableCell>
                      <TableCell>Tag</TableCell>
                      <TableCell>Description</TableCell>
                      <TableCell>Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lclHistoryUI}
                    {lclHistoryTotalUI}
                  </TableBody>
                </Table>
              </TableContainer>

            </td>
          </tr>
        </React.Fragment>
      );

    },
    onRowSelectionChange: (rowsSelected, allRows) => {
      var tempSelectedItemIDArray = [];
      var fieldValue = JSON.parse(props.field_value[1])
      var lclSelectedItemIndex = [];
      allRows.forEach(element => {
        tempSelectedItemIDArray.push(fieldValue[element.index].item_id)
        lclSelectedItemIndex.push(element.index)
      })

      setSelectedItem(lclSelectedItemIndex)
      var tempEmpty = []
      var tempItemValue = []
      var metaData = { "parents": searchResultParentItemIDArray, "parent_search_result": parentSearchResult, "items": searchResultItemIDArray, "item_search_result": tempEmpty, "selected_item": tempSelectedItemIDArray }
      tempItemValue.push("")
      tempItemValue.push("")
      tempItemValue.push(JSON.stringify(metaData))
      var tempFields = props.fields;
      var foundIndex = tempFields.findIndex((x) => x.id === props.field_id);
      tempFields[foundIndex].valueArray = tempItemValue
      tempFields[foundIndex].error = false;
      //console.log("tempFields in onRowSelectionChange: ", tempFields)
      SaveFormToAPI(props.form_id, props.requesting, tempFields, [], [], "", "", props.postApplicationDetail, props.application_id, props.task_id)
    },
    isRowSelectable: (dataIndex, selectedRows) => {
      if (props.is_read_only || !props.form_checkable || props.task_completed) {
        return false
      }
      if (props.field_value !== "") {
        if (props.field_value[1] !== "") {
          var fieldValue = JSON.parse(props.field_value[1])
          if (dataIndex < fieldValue.length) {
            return true
          }
        }
      }
      return false
    },
    isRowExpandable: (dataIndex, expandRows) => {
      if (props.field_value !== "") {
        if (props.field_value[1] !== "") {
          var fieldValue = JSON.parse(props.field_value[1])
          if (dataIndex < fieldValue.length) {
            return true
          }
        }
      }
      return false
    },
    onTableChange: (action, tableState) => {

      switch (action) {
        case 'changeRowsPerPage':
          setPageSize(tableState.rowsPerPage)
          setPageNum(1)
          props.FindRelatedItems(tableState.rowsPerPage, 1, orderBy, orderByDirec, props.search_table_related, searchResultParentItemIDStr)
          break;
        case 'changePage':
          setPageNum(tableState.page + 1)
          props.FindRelatedItems(pageSize, tableState.page + 1, orderBy, orderByDirec, props.search_table_related, searchResultParentItemIDStr)
          break;
        case 'sort':
          var sortString = 'item_name'
          setOrderByDirec(tableState.sortOrder.direction)
          if (tableState.sortOrder.name === 'Name') {
            setOrderBy('item_name')
            sortString = 'item_name'
          } else if (tableState.sortOrder.name === 'SKU') {
            setOrderBy('item_sku')
            sortString = 'item_sku'
          }
          props.FindRelatedItems(pageSize, pageNum, sortString, tableState.sortOrder.direction, props.search_table_related, searchResultParentItemIDStr)
          break;
      }
    },
    textLabels: {
      body: {
        noMatch: t('mui_table_no_match'),
        toolTip: t('mui_table_tool_tip'),
        columnHeaderTooltip: column => t('mui_table_header_tool_tip') + " " + column.label
      },
      pagination: {
        next: t('mui_table_next'),
        previous: t('mui_table_previous'),
        rowsPerPage: t('mui_table_rows_per_page'),
        displayRows: t('mui_table_display_rows'),
      },
      toolbar: {
        search: t('mui_table_search'),
        downloadCsv: t('mui_table_download_csv'),
        print: t('mui_table_print'),
        viewColumns: t('mui_table_view_columns'),
        filterTable: t('mui_table_filter_table'),
      },
      filter: {
        all: t('mui_table_filter_all'),
        title: t('mui_table_filter_title'),
        reset: t('mui_table_filter_reset'),
      },
      viewColumns: {
        title: t('mui_table_column_title'),
        titleAria: t('mui_table_column_title_aria'),
      },
      selectedRows: {
        text: t('mui_table_selected_text'),
        delete: t('mui_table_selected_delete'),
        deleteAria: t('mui_table_selected_delete_aria'),
      },
    },
  };

  return (
    <div >
      <Card>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={11}>
              <TextField
                //error={props.error}
                //helperText={props.helperText}
                required={false}
                fullWidth
                label={props.field_label}
                onClick={handleShowItemSearchClick.bind(this)}
                value={selectedItemName}
                disabled
                InputProps={{ readOnly: true }}
                multiline
                rows={props.field_value.length > 1 ? props.field_value.length : 1}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={1}>
              <IconButton
                onClick={handleShowItemSearchClick.bind(this)}
                title="Change Item"
                disabled={props.is_read_only || !props.form_checkable || props.task_completed}
              >
                <EditIcon />
              </IconButton>
            </GridItem>
          </GridContainer>
          {parentSearchResult.length > 0 ?
            <div>
              <br />
              <MUIDataTable
                data={parentData}
                columns={parentTableColumn}
                options={parentMUIDataOptions}
              />
              <br />
            </div>
            : <div style={{ color: 'red', textAlign: 'left' }}><br />Please select a PO</div>}
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              {data.length > 0 ?
                <div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
                      <MUIDataTable
                        title={(selectedItem.length < 1) ? <div style={{ color: 'red', textAlign: 'left' }}>Please select an Order Item</div> : ""}
                        data={data}
                        columns={tableColumn}
                        options={relatedItemMUIDataOptions}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                </div>
                : ""
              }
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
      <br />



      <Dialog fullWidth maxWidth="lg" open={dialogItemOpen} onClose={handleDialogItemSearchClose.bind(this)} aria-labelledby="form-dialog-title">
        <DialogContent>
          <Card>
            <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
              <b>{t('app_detail_item_search_search_label')}</b>
            </CardHeader>
            <CardBody>
              <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
              <ItemSearchMultiSelect
                search_level={props.search_level}
                item_relation_level={props.search_table}
              />
            </CardBody>
            <CardFooter style={{ textAlign: 'left' }} />
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogItemSearchClose.bind(this)} color="primary" round>
            {t('app_detail_item_search_cancel_label')}
          </Button>
        </DialogActions>
      </Dialog>



      <Dialog fullWidth maxWidth="lg" open={addItemDialogOpen} onClose={handleAddItemDialogClose.bind(this)} aria-labelledby="form-dialog-title">
        <DialogContent>
          <Card>
            <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
              <b>Add Item</b>
            </CardHeader>
            <CardBody>
              <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
              <div>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      value={newItemUniqueChar}
                      fullWidth
                      label={"Unique Chararcter"}
                      onChange={ontNewItemUniqueCharChange.bind(this)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      value={newItemName}
                      fullWidth
                      label={"Name"}
                      onChange={onNewItemNameChange.bind(this)}
                    />
                  </GridItem>
                </GridContainer>
                <br />
              </div>
              {addItemCollectionUI}
            </CardBody>
            <CardFooter style={{ textAlign: 'left' }} />
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddItemDialogClose.bind(this)} color="primary" round>
            Cancel
          </Button>
          <Button onClick={handleAddItemDialogConfirm.bind(this)} color="primary" round>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>


      <Dialog fullWidth maxWidth="lg" open={percentItemDialogOpen} onClose={handlePercentItemDialogClose.bind(this)} aria-labelledby="form-dialog-title">
        <DialogContent>
          <Card>
            <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
              <b>Percent Item</b>
            </CardHeader>
            <CardBody>
              <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
              <GridContainer>
                <GridItem xs={12} sm={12} md={4}>
                  <TextField
                    value={poPercent}
                    fullWidth
                    label={"Percentage"}
                    onChange={onPOPercentageChange.bind(this)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <TextField
                    value={poPercentDesc}
                    fullWidth
                    label={"Description"}
                    onChange={onPOPercentageDescChange.bind(this)}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter style={{ textAlign: 'left' }} />
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePercentItemDialogClose.bind(this)} color="primary" round>
            Cancel
          </Button>
          <Button onClick={handlePercentItemDialogConfirm.bind(this)} color="primary" round>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>



      <Dialog fullWidth open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
        <DialogContent>
          <Card>
            <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
              <b>Edit New Payment Amount</b>
            </CardHeader>
            <CardBody>
              <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
              <br />
              <RadioGroup
                aria-labelledby="demo-radio-buttons-group-label"
                //defaultValue="female"
                name="radio-buttons-group"
                value={radioTransType}
                onChange={onRadioValueChange.bind(this)}
              >
                {mulTransArrayHistory.length > 0 ?
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={2}>
                      <FormControlLabel value={2} control={<Radio />} />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={10}>
                      <MUIDataTable
                        data={subData}
                        columns={subTableColumn}
                        options={subTableOptions}
                      />
                      <br />
                      <div style={{ display: (radioTransType == 2) ? 'block' : 'none' }}>
                        {mulLabel} ({mulValue})  X  Selected {mulTransLabel} ({mulTransSelectedTotal}) = {mulTransTotalResult}
                      </div>
                    </GridItem>
                  </GridContainer>
                  : ""}
                <br />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2}>
                    <FormControlLabel value={0} control={<Radio />} />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={10}>
                    <TextField
                      required={false}
                      fullWidth
                      label="Entered Amount"
                      value={enteredAmount}
                      onChange={onEnteredAmountChange.bind(this)}
                      disabled={radioTransType != 0}
                    />
                  </GridItem>
                </GridContainer>
                <br />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={2}>
                    <FormControlLabel value={1} control={<Radio />} />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={10}>
                    <TextField
                      required={false}
                      fullWidth
                      label="Percentage"
                      value={percentage}
                      onChange={onPercentageChange.bind(this)}
                      disabled={radioTransType != 1}
                    />
                  </GridItem>
                </GridContainer>
              </RadioGroup>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  Actual Amount
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  {initialValue}
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  History Total
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  {historyTotal}
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  Remaining Total
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  {remainingTotal}
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  {(radioTransType == 1 || radioTransType == 2) ? "New Calculated " : "New Entered "} {radioTransType == 1 && percentage > 0 ? percentage + "%" : ""} Amount
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  {calculatedAmount}
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  Balance After New Payment
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  {newRemainingAmount}
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    required={false}
                    fullWidth
                    label="Description"
                    value={description}
                    onChange={onDescriptionChange.bind(this)}
                    disabled={false}
                  />
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter style={{ textAlign: 'left' }} />
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose.bind(this)} color="primary" round>
            Cancel
          </Button>
          <Button onClick={handleDialogConfirm.bind(this)} style={{ display: props.is_read_only || !props.form_checkable || props.task_completed ? 'none' : 'block' }} color="primary" round>
            Confirm Change
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default Item2Comp;

function isNumber(value) {
  return value.match(/^-?\d*(\.\d+)?$/)
}
var EPSILON = 0.000001;

function fp_less_than(A, B, Epsilon) {
  Epsilon = Epsilon || EPSILON;
  return (A - B < Epsilon) && (Math.abs(A - B) > Epsilon);
};

function fp_greater_than(A, B, Epsilon) {
  Epsilon = Epsilon || EPSILON;
  return (A - B > Epsilon) && (Math.abs(A - B) > Epsilon);
};