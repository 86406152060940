export const GET_TASKDEPENDENCY_REQUEST = 'GET_TASKDEPENDENCY_REQUEST';
export const GET_TASKDEPENDENCY_SUCCESS = 'GET_TASKDEPENDENCY_SUCCESS';
export const GET_TASKDEPENDENCY_FAILURE = 'GET_TASKDEPENDENCY_FAILURE';
export const POST_TASKDEPENDENCY_REQUEST = 'POST_TASKDEPENDENCY_REQUEST';
export const POST_TASKDEPENDENCY_SUCCESS = 'POST_TASKDEPENDENCY_SUCCESS';
export const POST_TASKDEPENDENCY_FAILURE = 'POST_TASKDEPENDENCY_FAILURE';
export const PATCH_TASKDEPENDENCY_REQUEST = 'PATCH_TASKDEPENDENCY_REQUEST';
export const PATCH_TASKDEPENDENCY_SUCCESS = 'PATCH_TASKDEPENDENCY_SUCCESS';
export const PATCH_TASKDEPENDENCY_FAILURE = 'PATCH_TASKDEPENDENCY_FAILURE';
export const DELETE_TASKDEPENDENCY_REQUEST = 'DELETE_TASKDEPENDENCY_REQUEST';
export const DELETE_TASKDEPENDENCY_SUCCESS = 'DELETE_TASKDEPENDENCY_SUCCESS';
export const DELETE_TASKDEPENDENCY_FAILURE = 'DELETE_TASKDEPENDENCY_FAILURE';
