import { combineReducers } from 'redux';
import adDefaultStates from './adDefaultStates';
import adLogin from './adLogin';
import adTask from './adTask';
import adActionMsg from './adActionMsg';
import adAction from './adAction';
import adPhase from './adPhase';
import adRole from './adRole';
import adActionType from './adActionType';
import adFormCategory from './adFormCategory';
import adCommName from './adCommName';
import adForm from './adForm';
import adField from './adField';
import adApplication from './adApplication';
import adTaskForm from './adTaskForm';
import adNameExclusion from './adNameExclusion';
import adSpecialCase from './adSpecialCase';
import adRolesReplacement from './adRolesReplacement';
import adTaskDependency from './adTaskDependency';
import adActionDependency from './adActionDependency';
import adPermComm from './adPermComm';
import adUser from './adUser';
import adItem from './adItem';
import adDepartment from './adDepartment';
import adDashboard from './adDashboard';
import adWorkflowAttr from './adWorkflowAttr';
import adWorkflow from './adWorkflow';
import adWorkflowSetting from './adWorkflowSetting';
import adAllowAppCreate from './adAllowAppCreate';
import adCustom from './adCustom';
import defaultStates from './defaultStates';
import login from './login';
import applicationsList from './applicationsList';
import application from './application';
import lookup from './lookup';
import applicationStatus from './applicationStatus';
import applicationDetail from './applicationDetail';
import naDefaultStates from './naDefaultStates';
import naLogin from './naLogin';
import naWorkflow from './naWorkflow';
import naAdmin from './naAdmin';
import naUser from './naUser';
import naCustom from './naCustom';
import naIntegration from './naIntegration';
import naDepartment from './naDepartment';
import naItem from './naItem';
import naIntgextapi from './naIntgextapi';
import naNetworkSetting from './naNetworkSetting';
import naDashboard from './naDashboard';
import naMessageTemplate from './naMessageTemplate';
import network from './network';

import user from './user';
import item from './item';

export default combineReducers({
  adDefaultStates,
  adLogin,
  adTask,
  adActionMsg,
  adAction,
  adPhase,
  adRole,
  adActionType,
  adFormCategory,
  adPermComm,
  adCommName,
  adForm,
  adNameExclusion,
  adSpecialCase,
  adRolesReplacement,
  adTaskDependency,
  adActionDependency,
  adUser,
  adItem,
  adDepartment,
  adField,
  adApplication,
  adTaskForm,
  adDashboard,
  adWorkflowAttr,
  adWorkflowSetting,
  adAllowAppCreate,
  adWorkflow,
  adCustom,
  defaultStates,
  login,
  applicationsList,
  application,
  lookup,
  applicationDetail,
  applicationStatus,
  naDefaultStates,
  naLogin,
  naWorkflow,
  naAdmin,
  naUser,
  naIntegration,
  naDepartment,
  naItem,
  naIntgextapi,
  naNetworkSetting,
  naDashboard,
  naMessageTemplate,
  naCustom,
  network,

  user,
  item,
});
