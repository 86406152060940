import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom'
import Button from 'components/CustomButtons/Button.jsx';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import ItemIntgComp from './ItemIntg/ItemIntgComp';
import * as itemActions from 'actions/networkadmin/ItemActions';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Assignment from '@mui/icons-material/Assignment';

const ItemIntg = (props) => {
  const { classes } = props;
  const { item, defaultStates } = props;
  const {
    getItemIntgs,
    updateItemIntgFieldValue
  } = props.itemActions;
  const { id } = useParams();

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/networkadmin/item" >
          <Button size="sm" round color="primary"> <Assignment />Items</Button>
        </Link>
        <Link to="#" >
          <Button size="sm" round color="primary" block={true}> </Button>
        </Link>
      </Breadcrumbs>
      <br />
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <ItemIntgComp
        item_id={id}
        getItemIntgs={getItemIntgs}
        updateItemIntgFieldValue={updateItemIntgFieldValue}
        item_intg_list={item.item_intg_list === undefined ? [] : item.item_intg_list}
        requesting={item.requesting}
        message={item.message}
        refreshItemIntg={item.refreshItemIntg}
        error_code={item.error_code}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    item: state.naItem,
    defaultStates: state.naDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    itemActions: bindActionCreators(itemActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ItemIntg);