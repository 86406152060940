import API from 'Api/api.js';
import { commonBackendCall, getAdminConfig } from 'Api/common.js';
import {
  GET_FORM_REQUEST,
  GET_FORM_SUCCESS,
  GET_FORM_FAILURE,
  POST_FORM_REQUEST,
  POST_FORM_SUCCESS,
  POST_FORM_FAILURE,
  PATCH_FORM_REQUEST,
  PATCH_FORM_SUCCESS,
  PATCH_FORM_FAILURE,
  DELETE_FORM_REQUEST,
  DELETE_FORM_SUCCESS,
  DELETE_FORM_FAILURE,
  POST_FORM_ADHOC_REQUEST,
  POST_FORM_ADHOC_SUCCESS,
  POST_FORM_ADHOC_FAILURE,
  GET_FORM_PRINT_FILE_REQUEST,
  GET_FORM_PRINT_FILE_SUCCESS,
  GET_FORM_PRINT_FILE_FAILURE,
} from 'constants/admin/Form';

export function getForms() {
  return commonBackendCall(
    GET_FORM_REQUEST,
    GET_FORM_SUCCESS,
    GET_FORM_FAILURE,
    API.get('admin/forms', getAdminConfig()),
  );
}
export function createForm(obj) {
  return commonBackendCall(
    POST_FORM_REQUEST,
    POST_FORM_SUCCESS,
    POST_FORM_FAILURE,
    API.post('admin/forms', obj, getAdminConfig()),
  );
}
export function createAdhocForm(obj) {
  return commonBackendCall(
    POST_FORM_ADHOC_REQUEST,
    POST_FORM_ADHOC_SUCCESS,
    POST_FORM_ADHOC_FAILURE,
    API.post('admin/forms/adhoc-comm', obj, getAdminConfig()),
  );
}
export function updateForm(formID, obj) {
  return commonBackendCall(
    PATCH_FORM_REQUEST,
    PATCH_FORM_SUCCESS,
    PATCH_FORM_FAILURE,
    API.patch(`admin/forms/${formID}`, obj, getAdminConfig()),
  );
}
export function deleteForm(formID) {
  return commonBackendCall(
    DELETE_FORM_REQUEST,
    DELETE_FORM_SUCCESS,
    DELETE_FORM_FAILURE,
    API.delete(`admin/forms/${formID}`, getAdminConfig()),
  );
}

export function getFormPrintFile(formID) {
  return commonBackendCall(
    GET_FORM_PRINT_FILE_REQUEST,
    GET_FORM_PRINT_FILE_SUCCESS,
    GET_FORM_PRINT_FILE_FAILURE,
    API.get(`admin/form-print-file/${formID}`, getAdminConfig()),
  );
}