import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter.jsx';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.jsx';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from "@material-ui/core/Switch";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import withStyles from "@material-ui/core/styles/withStyles";
import extendedFormsStyle from "assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx";

const CustomIntgComp = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogButton, setDialogButton] = useState('');
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [dialogType, setDialogType] = useState('add');
  const [id, setID] = useState('')
  const [intgFieldID, setIntgFieldID] = useState('')
  const [intgFieldName, setIntgFieldName] = useState('')
  const [key, setKey] = useState('')
  const [value, setValue] = useState('')
  const [valueDisplay, setValueDisplay] = useState('')
  const [status, setStatus] = useState('1')
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("")
  const [downloadFile, setDownloadFile] = useState(false)

  const { getCustomCSV, getCustomIntgs, getCustomIntgFields } = props;
  useEffect(() => {
    getCustomIntgs()
    getCustomIntgFields()
  }, [getCustomCSV, getCustomIntgs, getCustomIntgFields])

  const handleEditClick = (e) => {
    setID(e[0]);
    setIntgFieldID(e[1]);
    setKey(e[2]);
    setValue(e[3]);
    setIntgFieldName(e[4]);
    setStatus(e[5]);
    setValueDisplay(e[6]);
    setMessage('');
    setDialogTitle('Edit');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('update');
    setDialogOpen(true);
  };
  const handleDeleteClick = (e) => {
    setID(e[0]);
    setIntgFieldID(e[1]);
    setKey(e[2]);
    setValue(e[3]);
    setIntgFieldName(e[4]);
    setStatus(e[5]);
    setValueDisplay(e[6]);
    setMessage('');
    setDialogTitle('Delete');
    setDialogButton('Confirm Delete');
    setFieldDisabled(true);
    setDialogType('delete');
    setDialogOpen(true);
  };
  const handleNewClick = (e) => {
    setIntgFieldID("")
    setKey("")
    setValue("")
    setIntgFieldName("")
    setStatus("1");
    setMessage('');
    setDialogTitle('Add New');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('add');
    setDialogOpen(true);
  };

  useEffect(() => {
    setMessage(props.message)
    if (props.custom_list_csv.length > 0 && (!props.requesting) && downloadFile) {
      setDownloadFile(false)
      let filename = "CustomData.csv";
      let contentType = "application/csv;charset=utf-8;";
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        var blob = new Blob([decodeURIComponent(props.custom_list_csv)], { type: contentType });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        var a = document.createElement('a');
        a.download = filename;
        a.href = 'data:' + contentType + ',' + encodeURIComponent(props.custom_list_csv);
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    }
    if (props.refreshCustomIntg) {
      getCustomIntgs()
      setDialogOpen(false);
    }
    var rowItems = [];
    if (props.custom_intg_list.length > 0) {
      for (let i = 0; i < props.custom_intg_list.length; i++) {
        var colItems = [];
        colItems.push(i + 1);
        colItems.push(props.custom_intg_list[i].id);
        colItems.push(props.custom_intg_list[i].intg_field_name);
        colItems.push(props.custom_intg_list[i].key);
        colItems.push(props.custom_intg_list[i].value);
        colItems.push(props.custom_intg_list[i].value_display);
        colItems.push(props.custom_intg_list[i].status ? 'Active' : 'Inactive');
        colItems.push(
          <div>
            <IconButton
              onClick={handleEditClick.bind(this,
                [props.custom_intg_list[i].id,
                props.custom_intg_list[i].intg_field_id,
                props.custom_intg_list[i].key,
                props.custom_intg_list[i].value,
                props.custom_intg_list[i].intg_field_name,
                props.custom_intg_list[i].status,
                props.custom_intg_list[i].value_display,
                ])}
              title="Edit row"
            >
              {' '}
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={handleDeleteClick.bind(this,
                [props.custom_intg_list[i].id,
                props.custom_intg_list[i].intg_field_id,
                props.custom_intg_list[i].key,
                props.custom_intg_list[i].value,
                props.custom_intg_list[i].intg_field_name,
                props.custom_intg_list[i].status,
                props.custom_intg_list[i].value_display,
                ])}
              title="Delete row"
            >
              {' '}
              <DeleteIcon />
            </IconButton>
          </div>,
        );
        rowItems.push(colItems);
      }
    }
    setData(rowItems)
  }, [props])
  const { classes } = props;
  const columns = [
    "Serial",
    "ID",
    "Field Name",
    "Key",
    "Value",
    "Display Value",
    "Status",
    "",
  ];
  const options = {
    filterType: "dropdown",
    responsive: "standard",
    filter: true,
    selectableRows: "none",
    rowsPerPage: 100,
    selectToolbarPlacement: "none"
  };
  const handleDialogClose = (e) => {
    setDialogOpen(false);
    setMessage('');
  }

  const handleDialogConfirm = (e) => {
    if (dialogType === 'add') {
      props.createCustomIntgFieldValue({
        intg_field_id: intgFieldID.toString(),
        value: value,
        value_display: valueDisplay,
        key: key,
        status: status ? "1" : "0",
      });
    } else if (dialogType === 'update') {
      props.updateCustomIntgFieldValue(id, {
        intg_field_id: intgFieldID.toString(),
        value: value,
        value_display: valueDisplay,
        key: key,
        status: status ? "1" : "0",
      });
    } else if (dialogType === 'delete') {
      props.deleteCustomIntgFieldValue(id);
    }
  };
  const onIntgFieldIDChange = (e) => {
    if (dialogType === 'add') {
      setIntgFieldID(e.target.value);
    }
  }
  const onKeyChange = (e) => {
    setKey(e.target.value);
  }
  const onValueChange = (e) => {
    setValue(e.target.value);
  }
  const onValueDisplayChange = (e) => {
    setValueDisplay(e.target.value);
  }
  const onStatusChange = (e) => {
    setStatus(e.target.checked)
  }
  const handleDownloadCSV = (e) => {
    if (!props.requesting) {
      getCustomCSV();
      setDownloadFile(true)
    }
  }
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Custom Integration Fields</b>
        </CardHeader>
        <CardBody>
          <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
          <MUIDataTable
            title={<div><Button onClick={handleNewClick.bind(this)} color="primary" title="Create a new Custom Record" round> New Custom Record </Button>
              <Button
                onClick={handleDownloadCSV.bind(this)}
                color="primary"
                title="Download CSV"
                round
                disabled={props.requesting}>
                {props.requesting
                  ? <CircularProgress size={20} color="inherit" /> : <CloudDownloadIcon />}
                Download CSV
              </Button>
            </div>}
            data={data}
            columns={columns}
            options={options}
          />
          <Dialog fullWidth open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              <Card>
                <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                  <b>{dialogTitle}</b>
                </CardHeader>
                <CardBody>
                  <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl fullWidth={true} className={classes.formControl} disabled={fieldDisabled}>
                        <InputLabel id="workflowIdLabel">Field Name</InputLabel>
                        <Select
                          labelId="workflowIdLabel"
                          id="workflowIdSelect"
                          value={intgFieldID}
                          onChange={onIntgFieldIDChange.bind(this)}
                          disabled={fieldDisabled}

                        >
                          {props.intg_custom_field_list.map(item => (
                            < MenuItem key={item.id} value={item.id} >
                              {props.intg_custom_field_list.find(p => p.id === item.id).field_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        required={false}
                        fullWidth
                        label="Key "
                        value={key}
                        onChange={onKeyChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        required={false}
                        fullWidth
                        label="Value"
                        value={value}
                        onChange={onValueChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        required={false}
                        fullWidth
                        label="Display Value"
                        value={valueDisplay}
                        onChange={onValueDisplayChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <InputLabel id="statusLabel" shrink={true}>Status</InputLabel>
                      <Grid item={true} component="label" container alignItems="center" spacing={1}>
                        <Grid item>Inactive</Grid>
                        <Grid item>
                          <Switch
                            labelId="statusLabel"
                            checked={status}
                            onChange={onStatusChange.bind(this)}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled={fieldDisabled}
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              icon: classes.switchIcon,
                              iconChecked: classes.switchIconChecked,
                              bar: classes.switchBar
                            }}
                          />
                        </Grid>
                        <Grid item>Active</Grid>
                      </Grid>
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter style={{ textAlign: 'left' }} />
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose.bind(this)} color="primary" round>
                Cancel
              </Button>
              <Button onClick={handleDialogConfirm.bind(this)} color="primary" round>
                {dialogButton}
              </Button>
            </DialogActions>
          </Dialog>
        </CardBody>
      </Card>
    </div >
  );
}
export default withStyles(extendedFormsStyle)(CustomIntgComp)
