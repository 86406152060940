import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CircularProgress from '@mui/material/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx';

const UserIntgComp = (props) => {
  const [message, setMessage] = useState('');
  const [data, setData] = useState([]);

  const { getUserIntgs, user_id } = props;
  useEffect(() => {
    getUserIntgs(user_id);
  }, [getUserIntgs, user_id]);

  useEffect(() => {
    setMessage(props.message);
    var rowItems = [];
    if (props.user_intg_list.length > 0) {
      for (let i = 0; i < props.user_intg_list.length; i++) {
        var colItems = [];
        colItems.push(i + 1);
        colItems.push(props.user_intg_list[i].id);
        colItems.push(props.user_intg_list[i].intg_field_name);
        colItems.push(props.user_intg_list[i].value);
        rowItems.push(colItems);
      }
    }
    setData(rowItems);
  }, [props]);

  // const { classes } = props;
  const columns = [
    'Serial',
    'ID',
    'Field Name',
    'Field Value',
  ];
  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    filter: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    selectToolbarPlacement: 'none',
  };
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>User Integration Fields</b>
        </CardHeader>
        <CardBody>
          <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
          <MUIDataTable
            data={data}
            columns={columns}
            options={options}
          />
        </CardBody>
      </Card>
    </div>
  );
};
export default withStyles(extendedFormsStyle)(UserIntgComp);
