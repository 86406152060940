import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { connect } from 'react-redux';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Hidden from '@material-ui/core/Hidden';

// material-ui icons
import Menu from '@mui/icons-material/Menu';
import MoreVert from '@mui/icons-material/MoreVert';
import ViewList from '@mui/icons-material/ViewList';
import Build from '@mui/icons-material/Build';
import Public from '@mui/icons-material/Public';

// core components
import Button from 'components/CustomButtons/Button.jsx';
import { Link } from 'react-router-dom';
import headerStyle from 'assets/jss/material-dashboard-react/components/headerStyle.jsx';
import StateUI from 'components/StateUI/StateUI.jsx';
import TrackChanges from "@mui/icons-material/TrackChanges";
import IconButton from '@mui/material/IconButton'
import Logout from '@mui/icons-material/SettingsPower';


function Header({ ...props }) {
  useEffect(() => {
    // code to run on component mount
  }, []);
  const { workflow } = props;
  /*
  function makeBrand() {
    var name;
    props.routes.map((prop, key) => {
      if (prop.collapse) {
        prop.views.map((prop, key) => {
          if (prop.path === props.location.pathname) {
            name = prop.name;
          }
          return null;
        });
      }
      if (prop.path === props.location.pathname) {
        name = prop.name;
      }
      return null;
    });
    return name;
  }
  */
  const { classes, color, rtlActive } = props;
  const appBarClasses = cx({
    [` ${classes[color]}`]: color,
  });
  const sidebarMinimize = `${classes.sidebarMinimize
    } ${cx({
      [classes.sidebarMinimizeRTL]: rtlActive,
    })}`;
  var link = '/admin/dashboard';
  const handleLogout = () => {
    localStorage.removeItem('networkadmin_token');
    localStorage.removeItem('admin_token');
    window.location.reload();
  };
  var linkChooseWorkflow = "/admin/public/change-workflow"
  return (
    <AppBar className={classes.appBar + appBarClasses}>
      <Toolbar className={classes.container}>
        <Hidden smDown>
          <div className={sidebarMinimize}>
            {props.miniActive ? (
              <Button
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <ViewList className={classes.sidebarMiniIcon} />
              </Button>
            ) : (
              <Button
                justIcon
                round
                color="white"
                onClick={props.sidebarMinimize}
              >
                <MoreVert className={classes.sidebarMiniIcon} />
              </Button>
            )}
          </div>
        </Hidden>
        <div className={classes.flex}>
          {/* Here we create navbar brand, based on route name */}
          <br />
          <div className={classes.title} style={{ paddingTop: '5px', paddingLeft: '15px', fontSize: '25px' }}>
            <Link to={link} style={{ color: '#555555' }}>
              <b> {localStorage.getItem('workflow_alias') === "" ? localStorage.getItem('workflow_name') : localStorage.getItem('workflow_alias')}</b>
              <br />
              {/* localStorage.getItem('workflow_state') === "0" ?
                <Build style={{ color: '#ff9800', paddingLeft: '5px' }} /> :
            <Public style={{ color: '#4caf50', paddingLeft: '5px' }} /> */}
              {StateUI(parseInt(localStorage.getItem("workflow_state"), 10), 'small')}
            </Link>
          </div>
          {/*
          <Button href="#" className={classes.title} color="transparent">
            {makeBrand()}
          </Button>
          */}
        </div>
        {/*
        <Hidden smDown implementation="css">
          <HeaderLinks rtlActive={rtlActive} />
        </Hidden>
        */}
        <Link to={linkChooseWorkflow}  >
          <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <TrackChanges style={{ color: '#3242a8', paddingLeft: '1px' }} />
          </IconButton>
        </Link>
        <IconButton edge="start" onClick={handleLogout} className={classes.menuButton} color="inherit" aria-label="menu">
          <Logout style={{ color: '#e30c05', paddingLeft: '1px' }} />
        </IconButton>
        <Hidden mdUp>
          <Button
            className={classes.appResponsive}
            color="transparent"
            justIcon
            aria-label="open drawer"
            onClick={props.handleDrawerToggle}
          >
            <Menu />
          </Button>
        </Hidden>

      </Toolbar>
    </AppBar>
  );
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  color: PropTypes.oneOf(['primary', 'info', 'success', 'warning', 'danger']),
  rtlActive: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  workflow: state.adWorkflow,
});

export default connect(mapStateToProps)(withStyles(headerStyle)(Header));
