import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import SentEmailComp from './SentEmail/SentEmailComp';
import * as dashboardActions from 'actions/networkadmin/DashboardActions';

const SentEmails = (props) => {
  const { classes } = props;
  const { dashboard, defaultStates } = props;
  const {
    getSentEmailList,
  } = props.dashboardActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <SentEmailComp
        getSentEmailList={getSentEmailList}
        sent_email_list={dashboard.sent_email_list.list === undefined ? [] : dashboard.sent_email_list.list}
        sent_email_count={dashboard.sent_email_list.count === undefined ? 0 : dashboard.sent_email_list.count}
        requesting={dashboard.requesting}
        message={dashboard.message}
        error_code={dashboard.error_code}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    dashboard: state.naDashboard,
    defaultStates: state.naDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dashboardActions: bindActionCreators(dashboardActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SentEmails);
