import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import * as workflowAttrActions from 'actions/admin/WorkflowAttrActions';
import * as defaultActions from 'actions/admin/DefaultActions';
import WorkflowAttrComp from './WorkflowAttr/WorkflowAttrComp';

const WorkflowAttr = (props) => {
  const { classes } = props;
  const { workflowAttr, defaultStates } = props;
  const {
    getWorkflowAttr,
    createWorkflowAttr,
    updateWorkflowAttr,
    deleteWorkflowAttr,
  } = props.workflowAttrActions;
  const { resetMessage } = props.defaultActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <WorkflowAttrComp
        getWorkflowAttr={getWorkflowAttr}
        resetMessage={resetMessage}
        workflow_attr_list={workflowAttr.workflow_attr_list.list === undefined ? [] : workflowAttr.workflow_attr_list.list}
        createWorkflowAttr={createWorkflowAttr}
        updateWorkflowAttr={updateWorkflowAttr}
        deleteWorkflowAttr={deleteWorkflowAttr}
        refreshWorkflowAttr={workflowAttr.refreshWorkflowAttr}
        requesting={workflowAttr.requesting}
        message={workflowAttr.message}
        error_core={workflowAttr.error_core}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    workflowAttr: state.adWorkflowAttr,
    defaultStates: state.adDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    workflowAttrActions: bindActionCreators(workflowAttrActions, dispatch),
    defaultActions: bindActionCreators(defaultActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkflowAttr);
