import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardBody from 'components/Card/CardBody';
import MUIDataTable from 'mui-datatables';
import { TableFooter, TableCell, TableRow } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CardHeader from 'components/Card/CardHeader';
import CardFooter from 'components/Card/CardFooter.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.jsx';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import FormLabel from '@material-ui/core/FormLabel';

import { SaveFormToAPI } from 'Api/common.js';


const ItemTransactionsComp = (props) => {
	const [data, setData] = useState([]);
	const [tableColumn, setTableColumn] = useState([]);

	useEffect(() => {


		console.log("Khalid Omair ", props.label)
		//if (props.field_value !== "" && props.fieldValue != "[]") {
		if (props.field_value.length > 0) {
			console.log("field_value", props.field_value)
			var fieldValue = JSON.parse(props.field_value[1]);
			console.log(fieldValue)

			var tempLabel = []
			tempLabel.push("Data")
			tempLabel.push("Description")
			tempLabel.push("Tag")
			tempLabel.push("Quantity")
			setTableColumn(tempLabel)

			console.log("fieldValue 1")
			console.log(fieldValue.history)
			console.log("fieldValue 2")
			var rowItems = [];
			if (fieldValue.history !== undefined) {
				for (let j = 0; j < fieldValue.history.length; j++) {
					var colItems = [];
					colItems.push(new Date(fieldValue.history[j].insert_date).toLocaleString(localStorage.getItem('network_lang'), { year: "numeric", month: "2-digit", day: "numeric" }))
					colItems.push(fieldValue.history[j].trans_desc)
					colItems.push(fieldValue.history[j].trans_tag)
					colItems.push(fieldValue.history[j].trans_value)
					rowItems.push(colItems)
				}

				var colItemsTotal = [];
				colItemsTotal.push("")
				colItemsTotal.push("")
				colItemsTotal.push("")
				colItemsTotal.push(fieldValue.item_history_total)
				rowItems.push(colItemsTotal)
				setData(rowItems);
			}
		}



		/*
		if (props.datatable_rule) {
			var rules = JSON.parse(props.datatable_rule);
			var tempLabel = []
			rules.forEach((rule, i) => {
				if (rule.label.slice(0, 6) === "trans_") {
					tempLabel.push({
						name: rule.label.slice(6), label: rule.label.slice(6),
						options: {
							setCellProps: () => ({
								align: "right"
							}),
							setCellHeaderProps: (value) => ({
								style: { textAlign: 'right' }
							})
						}
					})
				} else {
					tempLabel.push({ name: rule.label, label: rule.label })
				}
			})
			setTableColumn(tempLabel)
		}

		if (props.field_value !== "") {
			var fieldValue = JSON.parse(props.field_value)
			setNumOfSelectedRecord(fieldValue.length)
			var rowItems = [];
			var rules = JSON.parse(props.datatable_rule);
			for (let j = 0; j < fieldValue.length; j++) {
				var colItems = [];
				rules.forEach((rule, i) => {
					if (rule.label.slice(0, 6) === "trans_") {
						var transFieldValue = JSON.parse(fieldValue[j][rule.label]);
						if (transFieldValue.selected_value === "") {
							colItems.push(transFieldValue.initial_value)
						} else {
							colItems.push(transFieldValue.selected_value)
						}
					} else {
						colItems.push(fieldValue[j][rule.label])
					}
				})
				rowItems.push(colItems)
			}

			var colItemsTotal = [];
			rules.forEach((rule, i) => {
				if (rule.label.slice(0, 6) === "trans_") {
					colItemsTotal.push(props.item_total)
				} else {
					colItemsTotal.push("")
				}
			})
			rowItems.push(colItemsTotal)
			setData(rowItems);
		}
		*/
	}, [props]);
	const options = {
		filterType: 'dropdown',
		responsive: 'standard',
		filter: false,
		download: false,
		print: false,
		search: false,
		viewColumns: false,
		sort: false,
		pagination: false,
		selectableRows: 'none',
		rowsPerPage: 100,
		selectToolbarPlacement: 'none',
		expandableRows: false,
		expandableRowsHeader: false,
		expandableRowsOnClick: false,
	}
	const { classes } = props;
	return (
		<div >
			<Card>
				<CardBody>
					<FormLabel>{props.label}</FormLabel>
					<br />
					<br />
					<MUIDataTable
						data={data}
						columns={tableColumn}
						options={options}
					/>
				</CardBody>
			</Card>
			<br />
		</div>
	);
};
export default ItemTransactionsComp;
