import {
    GET_NETWORK_SETTINGS_REQUEST,
    GET_NETWORK_SETTINGS_SUCCESS,
    GET_NETWORK_SETTINGS_FAILURE,
    PATCH_NETWORK_SETTINGS_REQUEST,
    PATCH_NETWORK_SETTINGS_SUCCESS,
    PATCH_NETWORK_SETTINGS_FAILURE,
  } from '../constants/networkadmin/NetworkSetting';
  
  const initialState = {
    requesting: false,
    message: '',
    networkSetting: '',
    refreshNetworkSettings: false,
  };
  
  export default function networkSetting(state = initialState, action) {
    switch (action.type) {
      case GET_NETWORK_SETTINGS_REQUEST:
        return {
          ...state,
          requesting: action.payload.requesting,
          refreshNetworkSettings: false,
          message: '',
        };
  
      case GET_NETWORK_SETTINGS_SUCCESS:
        return {
          ...state,
          requesting: action.payload.requesting,
          message: action.payload.message,
          networkSetting: action.payload.data,
        };
  
      case GET_NETWORK_SETTINGS_FAILURE:
        return {
          ...state,
          requesting: action.payload.requesting,
          message: action.payload.message,
        };
      case PATCH_NETWORK_SETTINGS_REQUEST:
        return {
          ...state,
          requesting: action.payload.requesting,
          message: '',
          refreshNetworkSettings: false,
        };
  
      case PATCH_NETWORK_SETTINGS_SUCCESS:
        return {
          ...state,
          requesting: action.payload.requesting,
          message: action.payload.message,
          refreshNetworkSettings: true,
        };
  
      case PATCH_NETWORK_SETTINGS_FAILURE:
        return {
          ...state,
          requesting: action.payload.requesting,
          message: action.payload.message,
        };
  
      default:
        return state;
    }
  }
  
