import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.jsx';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import extendedFormsStyle from 'assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx';

const FormCategoryComp = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogButton, setDialogButton] = useState('');
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [dialogType, setDialogType] = useState('add');
  const [data, setData] = useState([]);
  const [message, setMessage] = useState('');
  const [id, setID] = useState('');
  const [title, setTitle] = useState('');
  const [alias, setAlias] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [status, setStatus] = useState(true);

  const { getFormCategories } = props;
  useEffect(() => {
    getFormCategories();
  }, [getFormCategories]);

  const handleEditClick = (e) => {
    setID(e[0]);
    setTitle(e[1]);
    setSortOrder(e[2]);
    setStatus(e[3]);
    setAlias(e[4]);
    setMessage('');
    setDialogTitle('Edit');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('update');
    setDialogOpen(true);
    props.resetMessage('');
  };
  const handleDeleteClick = (e) => {
    setID(e[0]);
    setTitle(e[1]);
    setSortOrder(e[2]);
    setStatus(e[3]);
    setAlias(e[4]);
    setMessage('');
    setDialogTitle('Delete');
    setDialogButton('Confirm Delete');
    setFieldDisabled(true);
    setDialogType('delete');
    setDialogOpen(true);
    props.resetMessage('');
  };
  const handleNewClick = (e) => {
    const maxValueOfSortOrder = Math.max(...props.formCategory_list.map((o) => o.sort_order), 0);
    setSortOrder(maxValueOfSortOrder + 5);
    setTitle('');
    setStatus(true);
    setAlias('');
    setMessage('');
    setDialogTitle('Add New');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('add');
    setDialogOpen(true);
    props.resetMessage('');
  };
  useEffect(() => {
    setMessage(props.message);
    if (props.refreshFormCategory) {
      props.getFormCategories();
      setDialogOpen(false);
    }
    var rowItems = [];
    for (let i = 0; i < props.formCategory_list.length; i++) {
      var colItems = [];
      colItems.push(i + 1);
      colItems.push(props.formCategory_list[i].title);
      colItems.push(props.formCategory_list[i].alias);
      colItems.push(props.formCategory_list[i].sort_order);
      colItems.push(props.formCategory_list[i].status ? 'Active' : 'Inactive');
      colItems.push(
        <div>
          <IconButton
            onClick={handleEditClick.bind(this,
              [props.formCategory_list[i].id,
                props.formCategory_list[i].title,
                props.formCategory_list[i].sort_order,
                props.formCategory_list[i].status,
                props.formCategory_list[i].alias,
              ])}
            title="Edit row"
          >
            {' '}
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={handleDeleteClick.bind(this,
              [props.formCategory_list[i].id,
                props.formCategory_list[i].title,
                props.formCategory_list[i].sort_order,
                props.formCategory_list[i].status,
                props.formCategory_list[i].alias,
              ])}
            title="Delete row"
          >
            {' '}
            <DeleteIcon />
          </IconButton>
        </div>,
      );
      rowItems.push(colItems);
    }
    setData(rowItems);
  }, [props]);

  const handleDialogClose = (e) => {
    setDialogOpen(false);
    setMessage('');
  };
  const handleDialogConfirm = (e) => {
    if (dialogType === 'add') {
      props.createFormCategory({
        title,
        sort_order: sortOrder.toString(),
        status: status ? '1' : '0',
        alias: alias ,
      });
    } else if (dialogType === 'update') {
      props.updateFormCategory(id, {
        title,
        sort_order: sortOrder.toString(),
        status: status ? '1' : '0',
        alias: alias ,
      });
    } else if (dialogType === 'delete') {
      props.deleteFormCategory(id);
    }
  };
  const onTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const onAliasChange = (e) => {
    setAlias(e.target.value);
  };
  const onStatusChange = (e) => {
    setStatus(e.target.checked);
  };
  const onSortOrderChange = (e) => {
    var messageLocal = '';
    var letters = /^[0-9]+$/;
    if (!e.target.value.match(letters)) {
      messageLocal = 'Invalid sort order';
    }
    setSortOrder(e.target.value);
    setMessage(messageLocal);
  };
  const { classes } = props;
  const columns = [
    'Serial',
    'Title',
    'Alias',
    'Sort Order',
    'Status',
    '',
  ];
  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    filter: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    selectToolbarPlacement: 'none',
  };
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Form Categories</b>
          <p className={classes.cardCategoryWhite}>
            Here is the list of catogries of forms that segragates forms to be displayed in different menus when an application is opened in a workflow.
          </p>
        </CardHeader>
        <CardBody>
          <MUIDataTable
            title={<Button onClick={handleNewClick.bind(this)} color="primary" title="Create a new FormCategory" round> New FormCategory </Button>}
            data={data}
            columns={columns}
            options={options}
          />
          <Dialog fullWidth open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              <Card>
                <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                  <b>{dialogTitle}</b>
                </CardHeader>
                <CardBody>
                  <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        required
                        fullWidth
                        label="Title"
                        value={title}
                        onChange={onTitleChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        required
                        fullWidth
                        label="Alias"
                        value={alias}
                        onChange={onAliasChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        required={false}
                        fullWidth
                        label="SortOrder"
                        value={sortOrder}
                        onChange={onSortOrderChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <InputLabel id="statusLabel" shrink>Status</InputLabel>
                      <Grid item component="label" container alignItems="center" spacing={1}>
                        <Grid item>Inactive</Grid>
                        <Grid item>
                          <Switch
                            labelid="statusLabel"
                            checked={status}
                            onChange={onStatusChange.bind(this)}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled={fieldDisabled}
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                            }}
                          />
                        </Grid>
                        <Grid item>Active</Grid>
                      </Grid>
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter style={{ textAlign: 'left' }} />
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose.bind(this)} color="primary" round>
                Cancel
              </Button>
              <Button onClick={handleDialogConfirm.bind(this)} color="primary" round>
                {dialogButton}
              </Button>
            </DialogActions>
          </Dialog>
        </CardBody>
      </Card>
    </div>
  );
};
export default withStyles(extendedFormsStyle)(FormCategoryComp);
