import API from 'Api/api.js';
import {
  SET_EMAIL,
  SET_PASSWORD,
  SHOW_PASSWORD,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_CHOOSE_WORKFLOW,
} from 'constants/networkadmin/Login';
import { UNDEFINED_ERROR, RESET_UNDEFINED_ERROR } from 'constants/networkadmin/Default';
import { validateEmail } from 'Api/common.js';

export function setEmail(value) {
  return (dispatch) => {
    dispatch({
      type: SET_EMAIL,
      payload: {
        value,
      },
    });
  };
}

export function setPassword(value) {
  return (dispatch) => {
    dispatch({
      type: SET_PASSWORD,
      payload: {
        value,
      },
    });
  };
}

export function onShowPassword(value) {
  return (dispatch) => {
    dispatch({
      type: SHOW_PASSWORD,
      payload: {
        value,
      },
    });
  };
}

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''))
  return JSON.parse(window.atob(base64));
}

export function onLogin(obj, redirect) {
  return (dispatch) => {
    dispatch({
      type: LOGIN_REQUEST,
      payload: {
        requesting: true,
        message: '',
        error_code: '',
      },
    });
    dispatch({
      type: RESET_UNDEFINED_ERROR,
    });

    if (obj.password === '' && obj.email === '') {
      dispatch({
        type: LOGIN_FAILURE,
        payload: {
          requesting: false,
          message: 'Invalid Email and Password',
          error_code: '1093',
        },
      });
      return;
    }

    if (obj.email === '' || validateEmail(obj.email)) {
      dispatch({
        type: LOGIN_FAILURE,
        payload: {
          requesting: false,
          message: 'Invalid Email',
          error_code: '1091',
        },
      });
      return;
    }
    if (obj.password === '') {
      dispatch({
        type: LOGIN_FAILURE,
        payload: {
          requesting: false,
          message: 'Invalid Password',
          error_code: '1092',
        },
      });
      return;
    }

    const apiKey = localStorage.getItem("network_api_key");
    let config = {};
    if (apiKey !== undefined) {
      config = {
        headers: {
          Apikey: apiKey,
        },
      };
    }
    API.post('admin/login-networkadmin', obj, config)
      .then((response) => {
        if (response.data.result === 'success') {
          //localStorage.clear();
          const unixTime = Math.floor(Date.now() / 1000)
          const tokenObj = parseJwt(response.data.data.token);
          localStorage.removeItem('admin_token');
          localStorage.removeItem('token');
          localStorage.setItem('refresh_token', response.data.data.refresh_token);
          localStorage.setItem('login_time', unixTime);
          localStorage.setItem('networkadmin_token', response.data.data.token);
          localStorage.setItem('network_id', tokenObj.network_id);
          localStorage.setItem('network_name', tokenObj.network_name);
          localStorage.setItem('networkadmin_id', tokenObj.networkadmin_id);
          localStorage.setItem('name', tokenObj.name);
          localStorage.setItem('email', tokenObj.email);
          dispatch({
            type: LOGIN_SUCCESS,
            payload: {
              requesting: false,
              redirect,
            },
          });
        }
      })
      .catch((error) => {
        if (error.response === undefined) {
          dispatch({
            type: UNDEFINED_ERROR,
            payload: {
              error: error.request.status,
            },
          });
          /*
        } else if (error.request.status !== 400) {
          dispatch({
            type: UNDEFINED_ERROR,
            payload: {
              error: error.request.status,
            },
          });
          */
        } else {
          dispatch({
            type: LOGIN_FAILURE,
            payload: {
              requesting: false,
              message: error.response.data.data.message,
              error_code: error.response.data.data.error_code,
            },
          });
        }
      });
  };
}

export function onRefresh() {
  var tokenType = ""
  var token = ""
  if (localStorage.getItem('token') !== null) {
    tokenType = "USER"
    token = localStorage.getItem('token')
  } else if (localStorage.getItem('admin_token') !== null) {
    tokenType = "ADMIN"
    token = localStorage.getItem('admin_token')
  } else if (localStorage.getItem('networkadmin_token') !== null) {
    tokenType = "NETWORK_ADMIN"
    token = localStorage.getItem('networkadmin_token')
  }
  const config = {
    headers: {
      Token: token,
      RefreshToken: localStorage.getItem('refresh_token'),
    },
  }
  API.get('applications/refresh-token', config)
    .then((response) => {
      console.log("TOKEN TYPE: " + tokenType)
      const unixTime = Math.floor(Date.now() / 1000)
      localStorage.setItem('login_time', unixTime);
      if (response.data.result === 'success') {
        if (tokenType == "USER") {
          localStorage.setItem('token', response.data.data.token);
        } else if (tokenType == "ADMIN") {
          localStorage.setItem('admin_token', response.data.data.token);
        } else if (tokenType == "NETWORK_ADMIN") {
          localStorage.setItem('networkadmin_token', response.data.data.token);
        }
        console.log("SUCCESS TOKEN : " + response.data.data.token)
        localStorage.setItem('refresh_token', response.data.data.refresh_token);
      } else {
        localStorage.clear();
      }
    })
    .catch((error) => {
      localStorage.clear();
    })
}
