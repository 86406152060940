/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import ChartistGraph from 'react-chartist';
import withStyles from '@material-ui/core/styles/withStyles';
import Icon from '@material-ui/core/Icon';
import Store from '@mui/icons-material/Store';
import Update from '@mui/icons-material/Update';
import AccessTime from '@mui/icons-material/AccessTime';
import Accessibility from '@mui/icons-material/Accessibility';
import DraftIcon from '@mui/icons-material/Build';
import TestIcon from '@mui/icons-material/PlaylistAddCheck';
import PublishIcon from '@mui/icons-material/Public';
import DiscontinueIcon from '@mui/icons-material/PanTool';
import ArchiveIcon from '@mui/icons-material/HighlightOff';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardIcon from 'components/Card/CardIcon.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Error from '@mui/icons-material/Error';
import TextField from '@material-ui/core/TextField';
import { DefaultEditor } from 'react-simple-wysiwyg';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@mui/icons-material/FileCopy';

import StateUI from 'components/StateUI/StateUI.jsx';
import StateButtonUI from 'components/StateButtonUI/StateButtonUI.jsx';
import StateText from 'components/StateText/StateText.jsx';

// import React from 'react';
// import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@mui/icons-material/Check';
import SettingsIcon from '@mui/icons-material/Settings';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import VideoLabelIcon from '@mui/icons-material/VideoLabel';
import StepConnector from '@material-ui/core/StepConnector';
// import Button from '@material-ui/core/Button';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

/*
import {
  //yearlyAppOpenChart,
  //emailsSubscriptionChart,
  //completedTasksChart
} from "variables/charts.jsx";
*/

import dashboardStyle from 'assets/jss/material-dashboard-react/views/dashboardStyle.jsx';
import workflow from 'reducers/adWorkflow';

var Chartist = require('chartist');

var delays = 80;
var durations = 500;
var delays2 = 80;
var durations2 = 500;

const Dashboard = (props) => {
  const [message, setMessage] = useState('');

  const [appOpenedYear, setAppOpenedYear] = useState([]);
  const [appStartedYear, setAppStartedYear] = useState([]);
  const [appArchivedYear, setAppArchivedYear] = useState([]);
  const [appOpenedWeekLabel, setAppOpenedWeekLabel] = useState([]);
  const [appOpenedWeekCount, setAppOpenedWeekCount] = useState([]);
  const [shortDesc, setShortDesc] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState('');
  const [name, setName] = useState('');
  const [id, setID] = useState('');
  const [value, setValue] = useState(0);
  const { t } = useTranslation();

  const { getAdminDashboard, getWorkflowByAdmin } = props;
  useEffect(() => {
    getAdminDashboard();
    getWorkflowByAdmin();
  }, [getAdminDashboard, getWorkflowByAdmin]);

  useEffect(() => {
    setMessage(props.message);
    setShortDesc(props.workflow.short_desc);
    setDescription(props.workflow.description);
    setImage(props.workflow.image);
    setName(props.workflow.name);
    setID(props.workflow.id);
    if (props.patchCompleted) {
      props.getWorkflowByAdmin();
    }
    if (props.refreshWorkflow) {
      props.getWorkflowByAdmin();
    }

    var tempOpenedArray = [];
    props.app_opened_year.forEach((element) => {
      tempOpenedArray.push(element.count);
    });
    var tempStartedArray = [];
    props.app_started_year.forEach((element) => {
      tempStartedArray.push(element.count);
    });
    var tempArchivedArray = [];
    props.app_archived_year.forEach((element) => {
      tempArchivedArray.push(element.count);
    });
    var tempOpenedLabelArray = [];
    var tempOpenedCountArray = [];
    props.app_opened_week.forEach((element) => {
      tempOpenedLabelArray.push(element.date.slice(0, -5));
      tempOpenedCountArray.push(element.count);
    });

    setMessage(props.message);
    setAppOpenedYear(tempOpenedArray);
    setAppStartedYear(tempStartedArray);
    setAppArchivedYear(tempArchivedArray);
    setAppOpenedWeekLabel(tempOpenedLabelArray);
    setAppOpenedWeekCount(tempOpenedCountArray);
  }, [props]);

  const handleChange = (event, value) => {
    setValue(value);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  const handleWorkflowStateChange = (i, e) => {
    props.updateWorkflowState(localStorage.getItem('workflow_id'), { state: i.toString() });
  };

  const handlePublishAndDelete = (e) => {
    props.updateWorkflowState(localStorage.getItem('workflow_id'), { state: "2", delete_test: "1" });
  };

  const handleWorkflowCreateNewVersion = (e) => {
    props.createWorkflowVersion({
      workflow_id: id.toString(),
    });
  };

  const handleVersionChangeClick = (index, event) => {
    props.onLogin(
      { admin_token: localStorage.getItem('admin_token'), workflow_id: index.toString() },
    );
  };

  const { classes } = props;
  var yearlyAppOpenChart = {
    data: {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      series: [appOpenedYear],
    },
    options: {
      lineSmooth: Chartist.Interpolation.cardinal({
        tension: 0,
      }),
      low: 0,
      high: Math.max(appOpenedYear),
      chartPadding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    // for animation
    animation: {
      draw(data) {
        if (data.type === 'line' || data.type === 'area') {
          data.element.animate({
            d: {
              begin: 600,
              dur: 700,
              from: data.path
                .clone()
                .scale(1, 0)
                .translate(0, data.chartRect.height())
                .stringify(),
              to: data.path.clone().stringify(),
              easing: Chartist.Svg.Easing.easeOutQuint,
            },
          });
        } else if (data.type === 'point') {
          data.element.animate({
            opacity: {
              begin: (data.index + 1) * delays,
              dur: durations,
              from: 0,
              to: 1,
              easing: 'ease',
            },
          });
        }
      },
    },
  };
  const yearlyStartedChart = {
    data: {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      series: [appStartedYear],
    },
    options: {
      axisX: {
        showGrid: false,
      },
      low: 0,
      high: 20,
      chartPadding: {
        top: 0,
        right: 5,
        bottom: 0,
        left: 0,
      },
    },
    responsiveOptions: [
      [
        'screen and (max-width: 640px)',
        {
          seriesBarDistance: 5,
          axisX: {
            labelInterpolationFnc(value) {
              return value[0];
            },
          },
        },
      ],
    ],
    animation: {
      draw(data) {
        if (data.type === 'bar') {
          data.element.animate({
            opacity: {
              begin: (data.index + 1) * delays2,
              dur: durations2,
              from: 0,
              to: 1,
              easing: 'ease',
            },
          });
        }
      },
    },
  };
  const yearlyArchivedChart = {
    data: {
      labels: ['Jan', 'Feb', 'Mar', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
      series: [appArchivedYear],
    },
    options: {
      lineSmooth: Chartist.Interpolation.cardinal({
        tension: 0,
      }),
      low: 0,
      high: 30,
      chartPadding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    animation: {
      draw(data) {
        if (data.type === 'line' || data.type === 'area') {
          data.element.animate({
            d: {
              begin: 600,
              dur: 700,
              from: data.path
                .clone()
                .scale(1, 0)
                .translate(0, data.chartRect.height())
                .stringify(),
              to: data.path.clone().stringify(),
              easing: Chartist.Svg.Easing.easeOutQuint,
            },
          });
        } else if (data.type === 'point') {
          data.element.animate({
            opacity: {
              begin: (data.index + 1) * delays,
              dur: durations,
              from: 0,
              to: 1,
              easing: 'ease',
            },
          });
        }
      },
    },
  };
  var weeklyAppOpenChart = {
    data: {
      labels: appOpenedWeekLabel,
      series: [appOpenedWeekCount],
    },
    options: {
      lineSmooth: Chartist.Interpolation.cardinal({
        tension: 0,
      }),
      low: 0,
      high: Math.max(appOpenedWeekCount),
      chartPadding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
    // for animation
    animation: {
      draw(data) {
        if (data.type === 'line' || data.type === 'area') {
          data.element.animate({
            d: {
              begin: 600,
              dur: 700,
              from: data.path
                .clone()
                .scale(1, 0)
                .translate(0, data.chartRect.height())
                .stringify(),
              to: data.path.clone().stringify(),
              easing: Chartist.Svg.Easing.easeOutQuint,
            },
          });
        } else if (data.type === 'point') {
          data.element.animate({
            opacity: {
              begin: (data.index + 1) * delays,
              dur: durations,
              from: 0,
              to: 1,
              easing: 'ease',
            },
          });
        }
      },
    },
  };

  var problemList = [];
  props.sanityProblems.forEach((problem) => {
    problemList.push(
      <ListItem style={{ color: 'red' }}>
        <ListItemIcon>
          {' '}
          <Error color="error" />
          {' '}
        </ListItemIcon>
        <ListItemText primary={problem} />
      </ListItem>,
    );
  });
  const onImageChange = (e) => {
    setImage(e.target.value);
  };
  const onShortDescChange = (e) => {
    setShortDesc(e.target.value);
  };
  const onDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const handleWorkflowEdit = (e) => {
    props.updateWorkflowByAdmin({
      short_desc: shortDesc,
      image,
      description,
    });
  };
  const getStateColor = (value) => {
    var rtnString = 'warning';
    switch (value) {
      case 0:
        rtnString = 'warning';
        break;
      case 1:
        rtnString = 'info';
        break;
      case 2:
        rtnString = 'success';
        break;
      case 3:
        rtnString = 'danger';
        break;
      case 4:
        rtnString = 'rose';
        break;
    }
    return rtnString;
  };
  const getStateIcon = (value) => {
    switch (value) {
      case 0:
        return <DraftIcon />;
      case 1:
        return <TestIcon />;
      case 2:
        return <PublishIcon />;
      case 3:
        return <DiscontinueIcon />;
      case 4:
        return <ArchiveIcon />;
    }
  };
  const stateNextButtonUI = (value) => {
    switch (value) {
      case 0:
        return (
          <Button
            onClick={handleWorkflowStateChange.bind(this, 1)}
            color="primary"
            style={{ float: 'right' }}
            round
          >
            {StateButtonUI(1)}
          </Button>
        );
      case 1:
        return (
          <div>
            <div>
              <Button
                onClick={handleWorkflowStateChange.bind(this, 2)}
                color="primary"
                style={{ float: 'right' }}
                round
              >
                {StateButtonUI(2)}
              </Button>
              <Button
                onClick={handleWorkflowStateChange.bind(this, 0)}
                color="primary"
                style={{ float: 'right' }}
                round
              >
                {StateButtonUI(0)}
              </Button>
            </div>
          </div>
        );
      case 2:
        return (
          <div>
            <Button
              onClick={handleWorkflowCreateNewVersion.bind(this)}
              color="primary"
              style={{ float: 'right' }}
              round
            >
              Create New Version
            </Button>
            <Button
              onClick={handleWorkflowStateChange.bind(this, 0)}
              color="primary"
              style={{ float: 'right' }}
              round
            >
              {StateButtonUI(0)}
            </Button>
          </div>
        );
      case 3:
        return '';
      case 4:
        return '';
    }
  };
  var versionList = [];
  if (props.workflow.versions) {
    props.workflow.versions.forEach((version, index) => {
      if (index === 0) {
        if (version.parent_workflow_id === props.workflow.id) {
          if (props.workflow.versions.length > 1) {
            versionList.push(
              <GridContainer>
                <GridItem xs={12} sm={6} md={12}>
                  <b>Child Workflows</b>
                </GridItem>
              </GridContainer>,
            );
          }
        } else {
          versionList.push(
            <GridContainer>
              <GridItem xs={12} sm={6} md={12}>
                <b>Parent Workflow</b>
              </GridItem>
            </GridContainer>,
          );
        }
      }
      if (version.parent_workflow_id === props.workflow.id) {
        versionList.push(
          <GridContainer>
            <GridItem xs={12} sm={6} md={12}>
              <a onClick={handleVersionChangeClick.bind(this, version.child_workflow_id)} style={{ float: 'left' }}>
                {version.child_name}
              </a>
            </GridItem>
          </GridContainer>,
        );
      } else {
        versionList.push(
          <GridContainer>
            <GridItem xs={12} sm={6} md={12}>
              <a onClick={handleVersionChangeClick.bind(this, version.parent_workflow_id)} style={{ float: 'left' }}>
                {version.parent_name}
              </a>
            </GridItem>
          </GridContainer>,
        );
      }
    });
  }

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={12}>
          <Card>
            <CardHeader stats icon>
              <CardIcon color={getStateColor(props.workflow.state)}>
                {getStateIcon(props.workflow.state)}
              </CardIcon>
              <p className={classes.cardCategory}>Current State</p>
              <h3 className={classes.cardTitle}>
                {t(StateText(props.workflow.state))}
              </h3>
            </CardHeader>
            <CardBody>
              {props.workflow.state < 3
                ? (
                  <div>
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={12}>
                        <WorkflowSteppers workflow_state={props.workflow.state} />
                      </GridItem>
                    </GridContainer>
                    <br />
                    <GridContainer>
                      <GridItem xs={12} sm={6} md={12}>
                        {stateNextButtonUI(props.workflow.state)}
                      </GridItem>
                    </GridContainer>
                  </div>
                )
                : ''}
              <GridContainer>
                <GridItem xs={12} sm={6} md={12}>
                  {versionList}
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={6} md={12}>
                  <a href="#edit_workflow">
                    <Button
                      color="primary"
                      style={{ float: 'left' }}
                      round
                    >
                      Edit Workflow Description
                    </Button>
                  </a>
                  <a target='blank' href={localStorage.getItem("network_frontend_url") + '/public/login/' + props.workflow.title}>
                    <Button
                      color="primary"
                      style={{ float: 'left' }}
                      round
                    >
                      Shortcut Link To System
                    </Button>
                  </a>
                  <div>
                    
                    <div style={{ paddingLeft: '15px', paddingTop: '15px' }}>
                      <CopyToClipboard text={localStorage.getItem("network_frontend_url") + '/public/login/' + props.workflow.title}>
                        <FileCopyIcon />
                      </CopyToClipboard>
                    </div>
                  </div>
                </GridItem>
              </GridContainer>
            </CardBody>
            <CardFooter />
          </Card>
        </GridItem>
      </GridContainer>

      {message !== undefined ? message.length > 0 ?
        <GridContainer>
          <GridItem xs={12} sm={6} md={12}>
            <Card>
              <CardFooter>
                <List>
                  <ListItem style={{ color: 'red' }}>
                    <ListItemIcon>
                      {' '}
                      <Error color="error" />
                      {' '}
                    </ListItemIcon>
                    <ListItemText primary={message} />

                  </ListItem>
                </List>
                <br />
                <Button
                  onClick={handlePublishAndDelete.bind(this)}
                  color="primary"
                  style={{ float: 'right' }}
                  round
                >
                  Publish and Delete All the test applications
                </Button>
              </CardFooter>
            </Card>
          </GridItem>
        </GridContainer>
        : "" : ""}
      {problemList.length > 0
        ? (
          <GridContainer>
            <GridItem xs={12} sm={6} md={12}>
              <Card>
                <CardFooter>
                  <List>
                    {problemList}
                  </List>
                </CardFooter>
              </Card>
            </GridItem>
          </GridContainer>
        )
        : ''}

      <GridContainer>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="warning" stats icon>
              <CardIcon color="warning">
                <Icon>content_copy</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Active Applications</p>
              <h3 className={classes.cardTitle}>
                {props.admin_dashboard.num_active_app}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="success" stats icon>
              <CardIcon color="success">
                <Store />
              </CardIcon>
              <p className={classes.cardCategory}>Archived Applications</p>
              <h3 className={classes.cardTitle}>
                {props.admin_dashboard.num_archived_app}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="danger" stats icon>
              <CardIcon color="danger">
                <Icon>info_outline</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Stuck Applications</p>
              <h3 className={classes.cardTitle}>
                {props.admin_dashboard.app_stuck}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card>
            <CardHeader color="info" stats icon>
              <CardIcon color="info">
                <Accessibility />
              </CardIcon>
              <p className={classes.cardCategory}>Current Users</p>
              <h3 className={classes.cardTitle}>
                {props.admin_dashboard.num_current_user}
              </h3>
            </CardHeader>
            <CardFooter stats>
              <div className={classes.stats}>
                <Update />
                Just Updated
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card chart>
            <CardHeader color="success">
              <ChartistGraph
                className="ct-chart"
                data={yearlyAppOpenChart.data}
                type="Line"
                options={yearlyAppOpenChart.options}
                listener={yearlyAppOpenChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Applications Opened during last year</h4>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime />
                {' '}
                updated now
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card chart>
            <CardHeader color="warning">
              <ChartistGraph
                className="ct-chart"
                data={yearlyStartedChart.data}
                type="Bar"
                options={yearlyStartedChart.options}
                responsiveOptions={yearlyStartedChart.responsiveOptions}
                listener={yearlyStartedChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>New Applications stated during last year</h4>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime />
                {' '}
                updated now
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <Card chart>
            <CardHeader color="danger">
              <ChartistGraph
                className="ct-chart"
                data={yearlyArchivedChart.data}
                type="Line"
                options={yearlyArchivedChart.options}
                listener={yearlyArchivedChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Applications Closed or Archived during last year</h4>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime />
                {' '}
                updated now
              </div>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card chart>
            <CardHeader color="success">
              <ChartistGraph
                className="ct-chart"
                data={weeklyAppOpenChart.data}
                type="Line"
                options={weeklyAppOpenChart.options}
                listener={weeklyAppOpenChart.animation}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Applications Opened during last week</h4>
            </CardBody>
            <CardFooter chart>
              <div className={classes.stats}>
                <AccessTime />
                {' '}
                updated now
              </div>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      {/*
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <CustomTabs
            title="Tasks:"
            headerColor="primary"
            tabs={[
              {
                tabName: 'Bugs',
                tabIcon: BugReport,
                tabContent: (
                  <Tasks
                    checkedIndexes={[0, 3]}
                    tasksIndexes={[0, 1, 2, 3]}
                    tasks={bugs}
                  />
                ),
              },
              {
                tabName: 'Website',
                tabIcon: Code,
                tabContent: (
                  <Tasks
                    checkedIndexes={[0]}
                    tasksIndexes={[0, 1]}
                    tasks={website}
                  />
                ),
              },
              {
                tabName: 'Server',
                tabIcon: Cloud,
                tabContent: (
                  <Tasks
                    checkedIndexes={[1]}
                    tasksIndexes={[0, 1, 2]}
                    tasks={server}
                  />
                ),
              },
            ]}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardHeader color="warning">
              <h4 className={classes.cardTitleWhite}>Reminders Summary</h4>
              <p className={classes.cardCategoryWhite}>
                Autogenerated Email Reminders
              </p>
            </CardHeader>
            <CardBody>
              <Table
                tableHeaderColor="warning"
                tableHead={['ID', 'Name', 'No. of Reminders', 'Task']}
                tableData={[
                  ['1', 'Dakota Rice', '3', 'Lab Clearance'],
                  ['2', 'Minerva Hooper', '1', 'Press Clearance'],
                  ['3', 'Sage Rodriguez', '1', 'Press Clearance'],
                  ['4', 'Philip Chaney', '2', 'Lab Clearance'],
                ]}
              />
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      */}
      <GridContainer id="edit_workflow">
        <GridItem xs={12} sm={6} md={12}>
          <Card>
            <CardHeader> </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    required={false}
                    fullWidth
                    label="Short Description"
                    value={shortDesc}
                    variant="outlined"
                    onChange={onShortDescChange.bind(this)}
                  />
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    required={false}
                    fullWidth
                    label="Logo Image (dimension h:64px w:122px)"
                    value={image}
                    variant="outlined"
                    onChange={onImageChange.bind(this)}
                  />
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <DefaultEditor value={description} onChange={onDescriptionChange} />
                </GridItem>
              </GridContainer>
              <hr />
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <p>
                    <Button
                      onClick={handleWorkflowEdit.bind(this)}
                      color="primary"
                      style={{ float: 'right' }}
                      round
                    >
                      Update Workflow Description
                    </Button>
                  </p>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
};

Dashboard.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(dashboardStyle)(Dashboard);

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#784af4',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#784af4',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#784af4',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginRight: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

function getSteps() {
  return [0, 1, 2];
}

function WorkflowSteppers(props) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = getSteps();

  useEffect(() => {
    setActiveStep(props.workflow_state);
  }, [props]);

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={activeStep} connector={<QontoConnector />}>
        {steps.map((state) => (
          <Step key={state}>
            <StepLabel StepIconComponent={QontoStepIcon}>{StateUI(state, 'large')}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
}
