import {
  GET_CUSTOM_INTG_REQUEST,
  GET_CUSTOM_INTG_SUCCESS,
  GET_CUSTOM_INTG_FAILURE,
  POST_CUSTOM_INTG_REQUEST,
  POST_CUSTOM_INTG_SUCCESS,
  POST_CUSTOM_INTG_FAILURE,
  PATCH_CUSTOM_INTG_REQUEST,
  PATCH_CUSTOM_INTG_SUCCESS,
  PATCH_CUSTOM_INTG_FAILURE,
  DELETE_CUSTOM_INTG_REQUEST,
  DELETE_CUSTOM_INTG_SUCCESS,
  DELETE_CUSTOM_INTG_FAILURE,
  GET_CUSTOM_CSV_REQUEST,
  GET_CUSTOM_CSV_SUCCESS,
  GET_CUSTOM_CSV_FAILURE
} from '../constants/networkadmin/Custom';

const initialState = {
  requesting: false,
  message: '',
  custom_intg_list: [],
  refreshCustomIntg: false,
  custom_list_csv: [],
};

export default function custom(state = initialState, action) {
  switch (action.type) {

    case GET_CUSTOM_INTG_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        custom_intg_list: [],
        refreshCustomIntg: false,
      };

    case GET_CUSTOM_INTG_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        custom_intg_list: action.payload.data,
      };

    case GET_CUSTOM_INTG_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case POST_CUSTOM_INTG_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshCustomIntg: false,
      };

    case POST_CUSTOM_INTG_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshCustomIntg: true,
      };

    case POST_CUSTOM_INTG_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case PATCH_CUSTOM_INTG_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshCustomIntg: false,
      };

    case PATCH_CUSTOM_INTG_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshCustomIntg: true,
      };

    case PATCH_CUSTOM_INTG_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case DELETE_CUSTOM_INTG_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshCustomIntg: false,
      };

    case DELETE_CUSTOM_INTG_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshCustomIntg: true,
      };

    case DELETE_CUSTOM_INTG_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case GET_CUSTOM_CSV_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
      };

    case GET_CUSTOM_CSV_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        custom_list_csv: action.payload.data,
      };

    case GET_CUSTOM_CSV_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    default:
      return state;
  }
}
