export const GET_MESSAGE_TEMPLATE_REQUEST = 'GET_MESSAGE_TEMPLATE_REQUEST';
export const GET_MESSAGE_TEMPLATE_SUCCESS = 'GET_MESSAGE_TEMPLATE_SUCCESS';
export const GET_MESSAGE_TEMPLATE_FAILURE = 'GET_MESSAGE_TEMPLATE_FAILURE';
export const POST_MESSAGE_TEMPLATE_REQUEST = 'POST_MESSAGE_TEMPLATE_REQUEST';
export const POST_MESSAGE_TEMPLATE_SUCCESS = 'POST_MESSAGE_TEMPLATE_SUCCESS';
export const POST_MESSAGE_TEMPLATE_FAILURE = 'POST_MESSAGE_TEMPLATE_FAILURE';
export const PATCH_MESSAGE_TEMPLATE_REQUEST = 'PATCH_MESSAGE_TEMPLATE_REQUEST';
export const PATCH_MESSAGE_TEMPLATE_SUCCESS = 'PATCH_MESSAGE_TEMPLATE_SUCCESS';
export const PATCH_MESSAGE_TEMPLATE_FAILURE = 'PATCH_MESSAGE_TEMPLATE_FAILURE';
export const DELETE_MESSAGE_TEMPLATE_REQUEST = 'DELETE_MESSAGE_TEMPLATE_REQUEST';
export const DELETE_MESSAGE_TEMPLATE_SUCCESS = 'DELETE_MESSAGE_TEMPLATE_SUCCESS';
export const DELETE_MESSAGE_TEMPLATE_FAILURE = 'DELETE_MESSAGE_TEMPLATE_FAILURE';
