import {
  POST_APPLICATION_CREATE_REQUEST,
  POST_APPLICATION_CREATE_SUCCESS,
  POST_APPLICATION_CREATE_FAILURE,
  GET_ACTIVE_APPLICATION_REQUEST,
  GET_ACTIVE_APPLICATION_SUCCESS,
  GET_ACTIVE_APPLICATION_FAILURE,
  GET_ARCHIVE_APPLICATION_REQUEST,
  GET_ARCHIVE_APPLICATION_SUCCESS,
  GET_ARCHIVE_APPLICATION_FAILURE,
  GET_ISALLOWED_APPLICATION_REQUEST,
  GET_ISALLOWED_APPLICATION_SUCCESS,
  GET_ISALLOWED_APPLICATION_FAILURE,
  PATCH_APPLICATION_REQUEST,
  PATCH_APPLICATION_SUCCESS,
  PATCH_APPLICATION_FAILURE,
  GET_APPLICATION_FORM_WITHOUT_APP_REQUEST,
  GET_APPLICATION_FORM_WITHOUT_APP_SUCCESS,
  GET_APPLICATION_FORM_WITHOUT_APP_FAILURE,
  POST_MULTI_APPLICATIONS_REQUEST,
  POST_MULTI_APPLICATIONS_UPDATE,
  POST_MULTI_APPLICATIONS_SUCCESS,
  POST_MULTI_APPLICATIONS_FAILURE,
} from '../constants/ApplicationsList';

const initialState = {
  requesting: false,
  message: '',
  isAllowed: '',
  active_application_list: [],
  archive_application_list: [],
  newApplicationID: "",
  refreshApplication: false,
  application_detail_form_without_app: [],
  app_create_with_detail_msg: "",
};

export default function applicationsList(state = initialState, action) {
  switch (action.type) {
    case GET_ISALLOWED_APPLICATION_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshApplication: false,
      };

    case GET_ISALLOWED_APPLICATION_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        requesting: action.payload.requesting,
        isAllowed: action.payload.data,
      };

    case GET_ISALLOWED_APPLICATION_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case POST_APPLICATION_CREATE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        newApplicationID: "",
      };

    case POST_APPLICATION_CREATE_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        requesting: action.payload.requesting,
        refreshApplication: true,
        newApplicationID: action.payload.data.application_id
      };

    case POST_APPLICATION_CREATE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case GET_ACTIVE_APPLICATION_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshApplication: false,
      };

    case GET_ACTIVE_APPLICATION_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        requesting: action.payload.requesting,
        active_application_list: action.payload.data,
        newApplicationID: "",
      };

    case GET_ACTIVE_APPLICATION_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case GET_ARCHIVE_APPLICATION_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshApplication: false,
      };

    case GET_ARCHIVE_APPLICATION_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        requesting: action.payload.requesting,
        archive_application_list: action.payload.data,
        newApplicationID: "",
      };

    case GET_ARCHIVE_APPLICATION_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case PATCH_APPLICATION_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshApplication: false,
        newApplicationID: "",
      };

    case PATCH_APPLICATION_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        requesting: true,
        refreshApplication: true,
      };

    case PATCH_APPLICATION_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };


    case GET_APPLICATION_FORM_WITHOUT_APP_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
      };

    case GET_APPLICATION_FORM_WITHOUT_APP_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        requesting: action.payload.requesting,
        application_detail_form_without_app: action.payload.data,
        app_create_with_detail_msg: ""
      };

    case GET_APPLICATION_FORM_WITHOUT_APP_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case POST_MULTI_APPLICATIONS_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        newApplicationID: "",
      };

    case POST_MULTI_APPLICATIONS_UPDATE:
      return {
        ...state,
        app_create_with_detail_msg: { id: action.payload.id, message: action.payload.message },
      };

    case POST_MULTI_APPLICATIONS_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        requesting: action.payload.requesting,
        refreshApplication: true,
      };

    case POST_MULTI_APPLICATIONS_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshApplication: true,
      };
    default:
      return state;
  }
}
