import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as applicationStatusActions from 'actions/ApplicationStatusActions';

import AppSelfRemindComp from './AppSelfRemind/AppSelfRemindComp';

const AppHelp = (props) => {

  const { lookup } = props;
  const { patchApplicationRemind } = props.applicationStatusActions;

  return (
    <AppSelfRemindComp
      application_id={props.application_id}
      application_tasklog_id={props.application_tasklog_id}
      task_id={props.task_id}
      remind_message={props.remind_message}
      remind_date={props.remind_date}
      patchApplicationRemind={patchApplicationRemind}
    />
  );
}

function mapStateToProps(state) {
  return {
    lookup: state.lookup,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    applicationStatusActions: bindActionCreators(applicationStatusActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AppHelp);
