export const GET_ROLESREPLACEMENT_REQUEST = 'GET_ROLESREPLACEMENT_REQUEST';
export const GET_ROLESREPLACEMENT_SUCCESS = 'GET_ROLESREPLACEMENT_SUCCESS';
export const GET_ROLESREPLACEMENT_FAILURE = 'GET_ROLESREPLACEMENT_FAILURE';
export const POST_ROLESREPLACEMENT_REQUEST = 'POST_ROLESREPLACEMENT_REQUEST';
export const POST_ROLESREPLACEMENT_SUCCESS = 'POST_ROLESREPLACEMENT_SUCCESS';
export const POST_ROLESREPLACEMENT_FAILURE = 'POST_ROLESREPLACEMENT_FAILURE';
export const PATCH_ROLESREPLACEMENT_REQUEST = 'PATCH_ROLESREPLACEMENT_REQUEST';
export const PATCH_ROLESREPLACEMENT_SUCCESS = 'PATCH_ROLESREPLACEMENT_SUCCESS';
export const PATCH_ROLESREPLACEMENT_FAILURE = 'PATCH_ROLESREPLACEMENT_FAILURE';
export const DELETE_ROLESREPLACEMENT_REQUEST = 'DELETE_ROLESREPLACEMENT_REQUEST';
export const DELETE_ROLESREPLACEMENT_SUCCESS = 'DELETE_ROLESREPLACEMENT_SUCCESS';
export const DELETE_ROLESREPLACEMENT_FAILURE = 'DELETE_ROLESREPLACEMENT_FAILURE';
