import React, { useState, useEffect } from 'react';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx';
import TextField from '@material-ui/core/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Checkbox from '@material-ui/core/Checkbox';


const DatatableRuleComp = (props) => {
  const [selectedIntgField, setSelectedIntgField] = useState('');
  const [labelValue, setLabelValue] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [message, setMessage] = useState('');
  const [lclRules, setLclRules] = useState([]);
  const [masRules, setMasRules] = useState([]);

  const {
    setDatatableRule,
  } = props;
  useEffect(() => {
    setDatatableRule([]);
  }, [setDatatableRule]);

  useEffect(() => {
    if (props.field_datatable_rule && props.field_datatable_rule.length > 0) {
      setMasRules(props.field_datatable_rule)
      if (props.field_datatable_rule[props.datatable_rule_array_index].datatable_rule === undefined) {
        setLclRules([])
      } else {
        setLclRules(props.field_datatable_rule[props.datatable_rule_array_index].datatable_rule);
      }
      props.setDatatableRule(props.field_datatable_rule);
    }
    setDisabled(props.disabled);
  }, []);

  useEffect(() => {
  }, [props]);

  const onLabelValueChange = (e) => {
    setLabelValue(e.target.value);
  };
  const onSelectedIntgFieldIDChange = (e) => {
    setSelectedIntgField(e.target.value);
  };

  const handleAddClick = (e) => {
    setMessage('');
    if (selectedIntgField === '') {
      setMessage('Please Select Field');
      return;
    }
    var rule = {
      intgfield_id: selectedIntgField.toString(),
      label: labelValue,
    };
    setLclRules([...lclRules, rule]);
    setMessage('');
    setLabelValue('');
    setSelectedIntgField('');
    masRules[props.datatable_rule_array_index].datatable_rule = [...lclRules, rule]
    props.setDatatableRule(masRules);
  }

  const handleDeleteClick = (index, e) => {
    const tempRules = lclRules.slice();
    tempRules.splice(index, 1);
    setLclRules(tempRules);
    setMessage('');
    masRules[props.datatable_rule_array_index].datatable_rule = tempRules
    props.setDatatableRule(masRules);
  }

  const { classes } = props;
  const ruleItems = [];
  if (lclRules != undefined) {
    lclRules.forEach((rule, index) => {
      ruleItems.push(
        <div>
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <FormControl fullWidth className={classes.formControl} disabled={disabled}>
                <InputLabel id="itemIntglabel">Integration Field</InputLabel>
                <Select
                  labelId="itemIntgLabel"
                  id="itemIntgSelect"
                  value={rule.intgfield_id}
                  disabled
                >
                  {props.field_item_intg_list.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {props.field_item_intg_list.find((p) => p.id === item.id).field_type}
                      {' '}
                      -
                      {props.field_item_intg_list.find((p) => p.id === item.id).field_name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
            <GridItem xs={12} sm={12} md={4}>
              <TextField
                required={false}
                fullWidth
                label="Label"
                value={rule.label}
                disabled
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={2}>
              {disabled ? '' : (
                <IconButton onClick={handleDeleteClick.bind(this, index)}>
                  <DeleteIcon />
                </IconButton>
              )}
            </GridItem>
          </GridContainer>
        </div>,
      );
      ruleItems.push(<br key={`linebreak${index}`} />);
    });
  }
  return (
    <div>
      {ruleItems}
      <br />
      <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
      <GridContainer key="new validation_rule line 1">
        <GridItem xs={12} sm={12} md={6}>
          <FormControl fullWidth className={classes.formControl} disabled={props.disabled}>
            <InputLabel id="itemIntgLabel">Integration Field</InputLabel>
            <Select
              labelId="itemIntgLabel"
              id="itemIntgSelect"
              value={selectedIntgField}
              onChange={onSelectedIntgFieldIDChange.bind(this)}
            >
              {props.field_item_intg_list.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {props.field_item_intg_list.find((p) => p.id === item.id).field_type}
                  {' '}
                  -
                  {props.field_item_intg_list.find((p) => p.id === item.id).field_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <TextField
            required={false}
            fullWidth
            label="Label"
            value={labelValue}
            onChange={onLabelValueChange.bind(this)}
            disabled={disabled}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          {disabled ? '' : (
            <IconButton onClick={handleAddClick.bind(this)}>
              <AddIcon />
            </IconButton>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
};
export default withStyles(extendedFormsStyle)(DatatableRuleComp);
