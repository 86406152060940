export const GET_INTG_EXT_API_REQUEST = 'GET_INTG_EXT_API_REQUEST';
export const GET_INTG_EXT_API_SUCCESS = 'GET_INTG_EXT_API_SUCCESS';
export const GET_INTG_EXT_API_FAILURE = 'GET_INTG_EXT_API_FAILURE';
export const POST_INTG_EXT_API_REQUEST = 'POST_INTG_EXT_API_REQUEST';
export const POST_INTG_EXT_API_SUCCESS = 'POST_INTG_EXT_API_SUCCESS';
export const POST_INTG_EXT_API_FAILURE = 'POST_INTG_EXT_API_FAILURE';
export const PATCH_INTG_EXT_API_REQUEST = 'PATCH_INTG_EXT_API_REQUEST';
export const PATCH_INTG_EXT_API_SUCCESS = 'PATCH_INTG_EXT_API_SUCCESS';
export const PATCH_INTG_EXT_API_FAILURE = 'PATCH_INTG_EXT_API_FAILURE';
export const DELETE_INTG_EXT_API_REQUEST = 'DELETE_INTG_EXT_API_REQUEST';
export const DELETE_INTG_EXT_API_SUCCESS = 'DELETE_INTG_EXT_API_SUCCESS';
export const DELETE_INTG_EXT_API_FAILURE = 'DELETE_INTG_EXT_API_FAILURE';
