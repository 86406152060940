import API from '../Api/api.js';

import { commonBackendCall, getConfig } from '../Api/common.js';
import {
  POST_APPLICATION_CREATE_REQUEST,
  POST_APPLICATION_CREATE_SUCCESS,
  POST_APPLICATION_CREATE_FAILURE,
  GET_ACTIVE_APPLICATION_REQUEST,
  GET_ACTIVE_APPLICATION_SUCCESS,
  GET_ACTIVE_APPLICATION_FAILURE,
  GET_ARCHIVE_APPLICATION_REQUEST,
  GET_ARCHIVE_APPLICATION_SUCCESS,
  GET_ARCHIVE_APPLICATION_FAILURE,
  GET_ISALLOWED_APPLICATION_REQUEST,
  GET_ISALLOWED_APPLICATION_SUCCESS,
  GET_ISALLOWED_APPLICATION_FAILURE,
  PATCH_APPLICATION_REQUEST,
  PATCH_APPLICATION_SUCCESS,
  PATCH_APPLICATION_FAILURE,
  GET_APPLICATION_FORM_WITHOUT_APP_REQUEST,
  GET_APPLICATION_FORM_WITHOUT_APP_SUCCESS,
  GET_APPLICATION_FORM_WITHOUT_APP_FAILURE,
  POST_MULTI_APPLICATIONS_REQUEST,
  POST_MULTI_APPLICATIONS_UPDATE,
  POST_MULTI_APPLICATIONS_SUCCESS,
  POST_MULTI_APPLICATIONS_FAILURE,
} from '../constants/ApplicationsList';
import {
  RESET_APPLICATION_DETAIL,
} from '../constants/ApplicationDetail';
import { StylesProvider } from '@material-ui/core';

export function updateApplication(applicationID, obj) {
  return commonBackendCall(
    PATCH_APPLICATION_REQUEST,
    PATCH_APPLICATION_SUCCESS,
    PATCH_APPLICATION_FAILURE,
    API.patch(`applications/${applicationID}`, obj, getConfig()),
  );
}


export function isAllowedToCreateApplication() {
  return commonBackendCall(
    GET_ISALLOWED_APPLICATION_REQUEST,
    GET_ISALLOWED_APPLICATION_SUCCESS,
    GET_ISALLOWED_APPLICATION_FAILURE,
    API.get('applications/is-allowed', getConfig()),
  );
}
export function createApplication(obj) {
  return commonBackendCall(
    POST_APPLICATION_CREATE_REQUEST,
    POST_APPLICATION_CREATE_SUCCESS,
    POST_APPLICATION_CREATE_FAILURE,
    API.post('applications', obj, getConfig()),
  );
}

export function getActiveApplications(pageSize, pageNum, orderby, sort, searchStr) {
  return commonBackendCall(
    GET_ACTIVE_APPLICATION_REQUEST,
    GET_ACTIVE_APPLICATION_SUCCESS,
    GET_ACTIVE_APPLICATION_FAILURE,
    API.get(`applications/active?page=${pageNum}&limit=${pageSize}&orderby=${orderby}&sort=${sort}&search=${searchStr}`, getConfig()),
  );
}

export function resetApplicationDetail() {
  return (dispatch) => {
    dispatch({
      type: RESET_APPLICATION_DETAIL,
    });
  };
}
export function getArchiveApplications(pageSize, pageNum, orderby, sort, searchStr) {
  return commonBackendCall(
    GET_ARCHIVE_APPLICATION_REQUEST,
    GET_ARCHIVE_APPLICATION_SUCCESS,
    GET_ARCHIVE_APPLICATION_FAILURE,
    API.get(`applications/archive?page=${pageNum}&limit=${pageSize}&orderby=${orderby}&sort=${sort}&search=${searchStr}`, getConfig()),
  );
}

export function getApplicationDetailFormWithoutApp() {
  return commonBackendCall(
    GET_APPLICATION_FORM_WITHOUT_APP_REQUEST,
    GET_APPLICATION_FORM_WITHOUT_APP_SUCCESS,
    GET_APPLICATION_FORM_WITHOUT_APP_FAILURE,
    API.get(`applications/details-without-app`, getConfig()),
  );
}

export function processMultipleApp(obj) {
  return (dispatch) => {
    dispatch({
      type: POST_MULTI_APPLICATIONS_REQUEST,
      payload: {
        requesting: true,
        message: '',
        error_code: '',
      },
    });

    const apiKey = localStorage.getItem('network_api_key');
    const frontendURL = localStorage.getItem('network_frontend_url');
    let config = {};
    if (apiKey !== undefined && frontendURL !== undefined) {
      config = {
        headers: {
          Apikey: apiKey,
          FrontendURL: frontendURL,
        },
      };
    }
    processAllApp(dispatch, obj)
  };
}


async function processAllApp(dispatch, obj) {

  var isError = false;
  let j = 0;
  for (let i = 0; i < obj.length; i++) {
    var inputObj = { app_name: obj[i].app_title_value, app_desc: obj[i].app_desc_value }
    API.post('applications', inputObj, getConfig())
      .then((response) => {
        console.log(obj[i])
        var applicationID = 0;
        var roleID = localStorage.getItem("role_id");
        var taskID = 0;
        var actionID = 0;
        var actionMessageList = [];
        if (response.data.result === 'success') {
          dispatch({
            type: POST_MULTI_APPLICATIONS_UPDATE,
            payload: {
              message: 'Application Created',
              id: obj[i].id,
            },
          });

          applicationID = response.data.data.application_id;
          console.log("application_id:" + applicationID)

          inputObj = { task_id: obj[i].task_id, form_id: obj[i].form_id, fields: obj[i].fields }
          taskID = obj[i].task_id
          console.log(inputObj)
          API.post(`application/details/${applicationID}`, inputObj, getConfig())
            .then((response) => {
              if (response.data.result === 'success') {
                dispatch({
                  type: POST_MULTI_APPLICATIONS_UPDATE,
                  payload: {
                    message: 'Form Data Saved',
                    id: obj[i].id,
                  },
                });
                console.log("application_detail post:" + response.data.data);

                API.get(`applications/details/${applicationID}/${roleID}`, getConfig())
                  .then((response) => {
                    if (response.data.result === 'success') {
                      console.log("application_detail:" + response.data.data);
                      if (response.data.data.actions !== undefined) {
                        response.data.data.actions.forEach((action, index) => {
                          console.log(action)
                          if (index === 0) {
                            actionID = action.id
                            action.action_messages.forEach((actionMessage, index) => {
                              console.log(actionMessage)
                              if (index === 0) {
                                var actionMessageObj = {
                                  id: actionMessage.id,
                                  next_task_id: actionMessage.next_task_id,
                                  next_task_name: actionMessage.next_task_name,
                                  message: actionMessage.message,
                                  sms_message: actionMessage.sms_message,
                                  cc_email: actionMessage.cc_email,
                                  email: actionMessage.next_task_email,
                                  sms: actionMessage.next_task_sms,
                                  whatsup: actionMessage.next_task_whatsup,
                                  user_id: actionMessage.next_task_user_id,
                                }
                                actionMessageList.push(actionMessageObj)

                              }
                            });
                          }
                        });
                      }
                      
                      inputObj = { action_messages: actionMessageList }
                      API.post(`applications/details/actions/${applicationID}/${taskID}/${actionID}`, inputObj, getConfig())
                        .then((response) => {
                          if (response.data.result === 'success') {
                            dispatch({
                              type: POST_MULTI_APPLICATIONS_UPDATE,
                              payload: {
                                message: 'Action Confirmed',
                                id: obj[i].id,
                              },
                            });
                            if (i + 1 === obj.length) {
                              dispatch({
                                type: POST_MULTI_APPLICATIONS_SUCCESS,
                                payload: {
                                  requesting: false,
                                  message: 'Successfully Completed',
                                  error_code: '',
                                },
                              });
                            }
                            console.log("application_detail action  post:" + response.data.data);
                          } else {
                            isError = true
                          }
                        })
                        .catch((error) => {
                          console.log(error)
                          isError = true
                          dispatch({
                            type: POST_MULTI_APPLICATIONS_FAILURE,
                            payload: {
                              requesting: false,
                              message: 'Error in Processing',
                              error_code: '',
                            },
                          });
                        })

                    } else {
                      isError = true
                    }
                  })
                  .catch((error) => {
                    console.log(error)
                    isError = true
                    dispatch({
                      type: POST_MULTI_APPLICATIONS_FAILURE,
                      payload: {
                        requesting: false,
                        message: 'Error in Processing',
                        error_code: '',
                      },
                    });
                  })


              } else {
                isError = true
              }
            })
            .catch((error) => {
              console.log(error)
              isError = true
              dispatch({
                type: POST_MULTI_APPLICATIONS_FAILURE,
                payload: {
                  requesting: false,
                  message: 'Error in Processing',
                  error_code: '',
                },
              });
            })


        } else {
          isError = true
        }
      })
      .catch((error) => {
        console.log(error)
        isError = true
        dispatch({
          type: POST_MULTI_APPLICATIONS_FAILURE,
          payload: {
            requesting: false,
            message: 'Error in Processing',
            error_code: '',
          },
        });
      })

  }
}
