import API from 'Api/api.js';
import { commonBackendCall, getNetworkAdminConfig } from 'Api/common.js';
import {
  GET_ADMIN_REQUEST,
  GET_ADMIN_SUCCESS,
  GET_ADMIN_FAILURE,
  POST_ADMIN_REQUEST,
  POST_ADMIN_SUCCESS,
  POST_ADMIN_FAILURE,
  DELETE_ADMIN_REQUEST,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAILURE,
  SET_SELECTED_USER
} from 'constants/networkadmin/Admin';

export function setSelectedUser(user, userID) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_USER,
      payload: {
        user, userID
      },
    });
  };
}

export function getAdmins() {
  return commonBackendCall(
    GET_ADMIN_REQUEST,
    GET_ADMIN_SUCCESS,
    GET_ADMIN_FAILURE,
    API.get('admin/admins', getNetworkAdminConfig()),
  );
}

export function createAdmin(obj) {
  return commonBackendCall(
    POST_ADMIN_REQUEST,
    POST_ADMIN_SUCCESS,
    POST_ADMIN_FAILURE,
    API.post('admin/admins', obj, getNetworkAdminConfig()),
  );
}

export function deleteAdmin(adminID) {
  return commonBackendCall(
    DELETE_ADMIN_REQUEST,
    DELETE_ADMIN_SUCCESS,
    DELETE_ADMIN_FAILURE,
    API.delete(`admin/admins/${adminID}`, getNetworkAdminConfig()),
  );
}
