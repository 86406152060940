import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ButtonMUI from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';

import Email from '@mui/icons-material/Email';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';

import { validateEmail } from 'Api/common.js';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';



const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const LoginComp = (props) => {
  const [cardAnimaton, setCardAnimaton] = useState('cardHidden');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [message, setMessage] = useState('');
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [showPassword, setShowPassword] = useState(false);
const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      setCardAnimaton('');
    }, 500);
  }, []);

  useEffect(() => {
    setMessage(props.message);

    if (props.error_code === '1004' || props.error_code === '1005') {
      setEmailError(false);
      setPasswordError(false);
    }
    if (props.error_code === '1006') {
      setEmailError(true);
      setPasswordError(true);
    }
    if (props.error_code === '1091') {
      setEmailError(true);
      setPasswordError(false);
    }
    if (props.error_code === '1092') {
      setEmailError(false);
      setPasswordError(true);
    }
    if (props.error_code === '1093' || props.error_code === '1115') {
      setEmailError(false);
      setPasswordError(true);
    }
    if (props.error_code === '1114') {
      setEmailError(true);
      setPasswordError(false);
    }
    if (props.error_code === '') {
      setEmailError(false);
      setPasswordError(false);
    }
  }, [props]);

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const onKeyPressChange = (e) => {
    if (e.key === "Enter") {
      onLogin()
    }
  };

  const onLoginClick = (e) => {
    onLogin()
  }

  const onLogin = () => {
    if (password === '' && email === '') {
      setMessage('Invalid Email and Password');
      return;
    }

    if (email === '' || validateEmail(email.trim(email))) {
      setMessage('Invalid Email');
      return;
    }
    if (password === '') {
      setMessage('Invalid Password');
      return;
    }
    props.onLogin({ email, password, workflow_id: props.workflow_id }, props.redirect);
  };

  const resetNetwork = (e) => {
    localStorage.clear();
    window.location.reload();
  };
  const handleClickShowPassword = () => {
    setShowPassword(showPassword ? false : true);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const { classes } = props;
  const classesMUI = useStyles();
  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={6} md={5}>
        <Card className={classes[cardAnimaton]}>
          <CardHeader color="primary" plain style={{ textAlign: 'center' }}>
            <b>WORKFLOW ADMIN LOGIN</b>
          </CardHeader>
          <CardBody>
            <div className={classesMUI.root}>
              <ButtonGroup size="small" >
                <ButtonMUI title="Network Admin" onClick={() => navigate(`/networkadmin/public/login`)} >Network Admin</ButtonMUI>
                <ButtonMUI title="Workflow Admin" variant="contained" color="primary" >Workflow Admin</ButtonMUI>
                <ButtonMUI title="User Application" onClick={() => navigate(`/`)}>App</ButtonMUI>
              </ButtonGroup>
              {message !== "" ? <div style={{ color: 'red' }}>{message}</div> : ""}
            </div>
            <CustomInput
              labelText="Email"
              id="email"
              value={email}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: onEmailChange.bind(this),
                onKeyPress: onKeyPressChange.bind(this),
                error: emailError,
                endAdornment: (
                  <InputAdornment position="end">
                    <Email className={classes.inputAdornmentIcon} onClick={resetNetwork} />
                  </InputAdornment>
                ),
              }}
            />

            <CustomInput
              labelText="Password"
              id="password"
              value={password}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                input_type: showPassword ? 'text' : 'password',
                error: passwordError,
                onChange: onPasswordChange.bind(this),
                onKeyPress: onKeyPressChange.bind(this),
                endAdornment: (
                  < InputAdornment position="end" >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </CardBody>

          <CardFooter className={classes.justifyContentCenter}>
            <Button
              color="primary"
              round
              block
              onClick={onLoginClick.bind(this)}
            >
              LOGIN AS WORKFLOW ADMIN
            </Button>
            {props.requesting && <CircularProgress size={24} style={{ position: 'absolute', top: '74%', left: '47%' }} />}
          </CardFooter>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default LoginComp;
