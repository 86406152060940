export const GET_SPECIALCASE_REQUEST = 'GET_SPECIALCASE_REQUEST';
export const GET_SPECIALCASE_SUCCESS = 'GET_SPECIALCASE_SUCCESS';
export const GET_SPECIALCASE_FAILURE = 'GET_SPECIALCASE_FAILURE';
export const POST_SPECIALCASE_REQUEST = 'POST_SPECIALCASE_REQUEST';
export const POST_SPECIALCASE_SUCCESS = 'POST_SPECIALCASE_SUCCESS';
export const POST_SPECIALCASE_FAILURE = 'POST_SPECIALCASE_FAILURE';
export const PATCH_SPECIALCASE_REQUEST = 'PATCH_SPECIALCASE_REQUEST';
export const PATCH_SPECIALCASE_SUCCESS = 'PATCH_SPECIALCASE_SUCCESS';
export const PATCH_SPECIALCASE_FAILURE = 'PATCH_SPECIALCASE_FAILURE';
export const DELETE_SPECIALCASE_REQUEST = 'DELETE_SPECIALCASE_REQUEST';
export const DELETE_SPECIALCASE_SUCCESS = 'DELETE_SPECIALCASE_SUCCESS';
export const DELETE_SPECIALCASE_FAILURE = 'DELETE_SPECIALCASE_FAILURE';
