import React, { useState, useEffect } from 'react';
import InputAdornment from '@mui/material/InputAdornment';

import Email from '@mui/icons-material/Email';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';

const RegisterComp = (props) => {
  const [cardAnimaton, setCardAnimaton] = useState('cardHidden');
  const [emailError, setEmailError] = useState(false);
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    setTimeout(() => {
      setCardAnimaton('');
    }, 2500);
  }, []);

  useEffect(() => {
    setMessage(props.message);

    if (props.error_code === '1004' || props.error_code === '1005') {
      setEmailError(false);
    }
    if (props.error_code === '1006') {
      setEmailError(true);
    }
    if (props.error_code === '1091') {
      setEmailError(true);
    }
    if (props.error_code === '1092') {
      setEmailError(false);
    }
    if (props.error_code === '1093' || props.error_code === '1115') {
      setEmailError(false);
    }
    if (props.error_code === '1114') {
      setEmailError(true);
    }
    if (props.error_code === '') {
      setEmailError(false);
    }
  }, [props]);

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onBackClick = (e) => {
    window.location.href = '/public/login';
  };
  const onRegisterClick = (e) => {
    if (email === '') {
      setMessage('Invalid Email');
      return;
    }

    if (email === '' || validateEmail(email)) {
      setMessage('Invalid Email');
      return;
    }
    if (!props.requesting) {
      props.onRegister({ email });
    }
  };

  const { classes } = props;
  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={6} md={4}>
        <form>
          <Card className={classes[cardAnimaton]}>

            <CardHeader color="primary" plain style={{ textAlign: 'center' }}>
              <b>Register</b>
            </CardHeader>

            <CardBody>
              <div style={{ color: props.register_success ? 'green' : 'red' }}>{message}</div>
              <CustomInput
                labelText="Email"
                id="email"
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  onChange: onEmailChange.bind(this),
                  error: emailError,
                  disabled: props.requesting,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Email className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                }}
              />

            </CardBody>

            <div style={{
              padding: '0',
              paddingTop: '10px',
              margin: '0 15px 10px',
              borderRadius: '0',
              justifyContent: 'space-between',
              alignItems: 'center',
              // display: "flex",
              backgroundColor: 'transparent',
              border: '0',
            }}
            >
              <Button
                color="primary"
                round
                block
                onClick={onRegisterClick.bind(this)}
              >
                Register
              </Button>
              <br />
              <Button
                color="primary"
                round
                block
                onClick={onBackClick.bind(this)}
              >
                Back To Login
              </Button>
            </div>
          </Card>
        </form>
      </GridItem>
    </GridContainer>
  );
};

export default RegisterComp;

export function validateEmail(email) {
  var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !re.test(email);
}