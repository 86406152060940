import React, { useState, useEffect, useReducer } from 'react';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import TextField from '@material-ui/core/TextField';
import Typography from '@mui/material/Typography';
import Box from '@material-ui/core/Box';
import Card from '@mui/material/Card';
import CardBody from 'components/Card/CardBody';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormHelperText from '@material-ui/core/FormHelperText';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Button from 'components/CustomButtons/Button.jsx';

import { SaveFormToAPI } from 'Api/common.js';

import {
  TEXT_FIELD,
  TEXT_AREA,
  DROP_DOWN,
  RADIO_BUTTON,
  CHECK_BOX,
  DATE,
  TIME,
  HELPTEXT,
  COMMITTEE,
  FILE_SHARING,
  AGGREGATE_NUM,
  WEEK_CALENDAR,
  ITEM_SEARCH,
} from 'constants/FieldTypes';

const LinkActionExternalComp = (props) => {
  const [fields, setFields] = useState([]);
  const [formID, setFormID] = useState(0);
  const [taskID, setTaskID] = useState(0);
  const [actions, setActions] = useState('');
  const [formCompleted, setFormCompleted] = useState(false);
  const [formArray, setFormArray] = useState([]);
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);


  useEffect(() => {
    props.onLinkActionExternalGetDetail(props.token, props.redirect);
  }, []);

  useEffect(() => {
    if (props.refreshApplicationDetailForm) {
      props.onLinkActionExternalGetDetail(props.token, props.redirect);
      localStorage.setItem('token', "");
    }
    if (props.refreshApplicationDetailAction) {
      localStorage.setItem('token', "");
      console.log("Process Completed")
    }
    var tempFields = [];

    if (props.application_detail_form !== undefined) {
      setTaskID(props.application_detail_form.id)
      setActions(props.application_detail_form.actions);
      props.application_detail_form.forms.forEach((form, index) => {
        setFormID(form.id)
        setFormCompleted(form.completed)

        form.fields.forEach((field) => {
          var isError = false;
          var value = '';
          var fieldValues = [];
          if (field.value !== '') {
            fieldValues = JSON.parse(field.value);
            if (fieldValues.length > 0) {
              value = fieldValues[0];
            }
          }
          if (field.required && value === '') {
            isError = true;
          }
          if (field.field_error_message !== '') {
            isError = true;
          }
          var fieldLabel = field.label
          if (field.alias !== "") {
            fieldLabel = field.alias
          }

          tempFields.push({
            id: field.id,
            formId: form.id,
            type: field.type,
            valueArray: fieldValues,
            value,
            label: fieldLabel,
            required: field.required,
            error: isError,
            field_error_message: field.field_error_message,
          });
        });
      });
      setFields(tempFields);
      setFormArray(props.application_detail_form.forms)
    }

  }, [props]);

  const handleClickSaveBtn = (e) => {
    localStorage.setItem('token', props.user_token);
    SaveFormToAPI(formID, props.requesting, fields, [], [], "", "", props.postApplicationDetail, props.application_id, taskID)
  }
  const handleClickSubmitBtn = (e) => {
    localStorage.setItem('token', props.user_token);
    var actionObj = actions.find((o) => o.id === props.action_id);
    var actionMsgList = [];
    actionObj.action_messages.forEach((actionMessage) => {
      actionMsgList.push({
        id: actionMessage.id,
        email: actionMessage.next_task_email,
        cc_email: actionMessage.cc_email,
        sms: actionMessage.next_task_sms,
        whatsup: actionMessage.next_task_whatsup,
        user_id: actionMessage.next_task_user_id,
        next_task_id: actionMessage.next_task_id,
        next_task_name: actionMessage.next_task_name,
        message: actionMessage.message,
        sms_message: actionMessage.next_task_sms,
        reminder_message: "",
        reminder_date: actionMessage.remind_date,
      });
    });
    props.postApplicationDetailAction(
      props.application_id,
      taskID, props.action_id, { action_messages: actionMsgList },
    );
  }
  const onFieldValueChange = (fieldID, e) => {
    var tempFields = fields;
    var tempFormID = "";
    var foundIndex = tempFields.findIndex((x) => x.id === fieldID);
    tempFields[foundIndex].value = e.target.value;
    tempFields[foundIndex].error = false;
    tempFormID = tempFields[foundIndex].formId
    if (tempFields[foundIndex].required && e.target.value === '') {
      tempFields[foundIndex].error = true;
    }
    setFields(tempFields);

    /*
    if (fieldIDInRules.indexOf(fieldID.toString()) > -1) {
      SaveFormToAPI(tempFormID, props.requesting, tempFields, [], [], "", "", props.postApplicationDetail, props.application_id, taskID)
    }
    */

    forceUpdate();
  };

  const fieldItems = [];
  formArray.forEach((form) => {
    form.fields.forEach((field) => {
      if (field.visible_rule !== "") {
        var visibleRule = JSON.parse(field.visible_rule);
        if (visibleRule.length > 0) {
          if (!field.visible_rule_cond_met) {
            return;
          }
        }
      }
      var fieldUI = [];
      if (field.type === TEXT_FIELD) {
        var isDisabled = false;
        if (field.is_read_only || !form.checkable) {
          isDisabled = true;
        }
        fieldUI.push(
          <TextField
            required={field.required === 'true'}
            error={fields.find((o) => o.id === field.id).error}
            value={fields.find((o) => o.id === field.id).value}
            key={field.id}
            id={`${field.id}`}
            fullWidth
            label={fields.find((o) => o.id === field.id).label}
            onChange={onFieldValueChange.bind(this, field.id)}
            InputProps={{ readOnly: isDisabled }}
            helperText={fields.find((o) => o.id === field.id).field_error_message}
          />,
        );
      } else if (field.type === AGGREGATE_NUM) {
        fieldUI.push(
          <TextField
            // required={field.required === "true" ? true : false}
            error={fields.find((o) => o.id === field.id).error}
            value={fields.find((o) => o.id === field.id).value}
            key={field.id}
            id={`${field.id}`}
            fullWidth
            label={fields.find((o) => o.id === field.id).label}
            InputProps={{ readOnly: true }}
            onChange={onFieldValueChange.bind(this, field.id)}
          />,
        );
      } else if (field.type === HELPTEXT) {
        if (field.help_text.length > 0) {
          if (field.help_text.substring(0, 1) === '<') {
            fieldUI.push(
              <div
                dangerouslySetInnerHTML={{ __html: field.help_text }}
              />,
            );
          } else {
            fieldUI.push(
              <Box
                style={{ background: '#EEEEE0' }}
                key={field.id}
              >
                <Typography variant="subtitle1" gutterBottom>
                  {field.help_text}
                </Typography>
              </Box>,
            );
          }
        }
      } else if (field.type === TIME) {
        var isDisabled = false;
        if ((field.is_read_only) || !form.checkable) {
          isDisabled = true;
        }
        fieldUI.push(
          <TextField
            required={field.required === 'true'}
            error={fields.find((o) => o.id === field.id).error}
            value={fields.find((o) => o.id === field.id).value}
            key={field.id}
            id={`${field.id}`}
            type="time"
            // InputLabelProps={{ shrink: true, }}
            fullWidth
            label={fields.find((o) => o.id === field.id).label}
            onChange={isDisabled ? doNothing() : onFieldValueChange.bind(this, field.id)}
            // disabled={isDisabled}
            helperText={fields.find((o) => o.id === field.id).field_error_message}
            InputLabelProps={{
              shrink: true,
            }}
          />,
        );
      } else if (field.type === DATE) {
        var isDisabled = false;
        if ((field.is_read_only) || !form.checkable) {
          isDisabled = true;
        }
        fieldUI.push(
          <TextField
            id={`${field.id}`}
            key={field.id}
            label={fields.find((o) => o.id === field.id).label}
            type="date"
            error={fields.find((o) => o.id === field.id).error}
            value={fields.find((o) => o.id === field.id).value}
            onChange={isDisabled ? doNothing() : onFieldValueChange.bind(this, field.id)}
            // disabled={isDisabled}
            helperText={fields.find((o) => o.id === field.id).field_error_message}
            InputLabelProps={{
              shrink: true,
            }}
          />,
        );
      } else if (field.type === TEXT_AREA) {
        var isDisabled = false;
        if (field.is_read_only || !form.checkable) {
          isDisabled = true;
        }
        fieldUI.push(
          <TextField
            required={field.required === 'true'}
            error={fields.find((o) => o.id === field.id).error}
            value={fields.find((o) => o.id === field.id).value}
            key={field.id}
            id={`${field.id}`}
            multiline
            rows={field.rows}
            // InputLabelProps={{ shrink: true, }}
            fullWidth
            label={fields.find((o) => o.id === field.id).label}
            onChange={onFieldValueChange.bind(this, field.id)}
            // disabled={isDisabled}
            InputProps={{ readOnly: isDisabled }}
          />,
        );
      } else if (field.type === RADIO_BUTTON) {
        var radioButtonUI = [];
        var radioButtonOptionJSON = JSON.parse(field.options);
        var isDisabled = false;
        if (field.is_read_only || !form.checkable) {
          isDisabled = true;
        }
        radioButtonOptionJSON.forEach((radioButton, index) => {
          radioButtonUI.push(
            <FormControlLabel
              key={`radioButton${index}${field.id}`}
              control={(
                <Radio
                  id={`radioButton${index}${field.id}`}
                  color="primary"
                />
              )}
              label={radioButton.value}
              value={radioButton.id}
            />,
          );
        });
        fieldUI.push(
          <FormControl
            required={field.required === 'true'}
            error={fields.find((o) => o.id === field.id).error}
            id={field.id}
            key={`radioForm${field.id}`}
            // disabled={isDisabled}
            component="fieldset"
          >
            <FormLabel>{fields.find((o) => o.id === field.id).label}</FormLabel>
            <RadioGroup
              aria-label="radio"
              name="radio"
              value={fields.find((o) => o.id === field.id).value}
              key={`radioGroup${field.id}`}
              onChange={isDisabled ? doNothing() : onFieldValueChange.bind(this, field.id)}
            >
              {radioButtonUI}
            </RadioGroup>
            <FormHelperText>{fields.find((o) => o.id === field.id).field_error_message}</FormHelperText>
          </FormControl>,
        );
      } else if (field.type === DROP_DOWN) {
        var isDisabled = false;
        if (field.is_read_only || !form.checkable) {
          isDisabled = true;
        }
        var dropdownUI = [];
        var dropdownOptionJSON = JSON.parse(field.options);
        dropdownOptionJSON.forEach((dropdown) => {
          dropdownUI.push(
            <MenuItem key={dropdown.id} id={dropdown.id} value={dropdown.id}>{dropdown.value}</MenuItem>,
          );
        });
        fieldUI.push(
          <FormControl
            required={field.required === 'true'}
            error={fields.find((o) => o.id === field.id).error}
            key={field.id}
            fullWidth
            id={field.id}
          >
            <InputLabel id={`label${field.id}`}>{fields.find((o) => o.id === field.id).label}</InputLabel>
            <Select
              labelId={`label${field.id}`}
              id={`${field.id}`}
              value={fields.find((o) => o.id === field.id).value}
              onChange={isDisabled ? doNothing() : onFieldValueChange.bind(this, field.id)}
              autoWidth
            >
              {dropdownUI}
            </Select>
            <FormHelperText>{fields.find((o) => o.id === field.id).field_error_message}</FormHelperText>
          </FormControl>,
        );
      }
      var fieldColspan = 12;
      if (field.colspan !== '') {
        fieldColspan = field.colspan;
      }
      fieldItems.push(
        <GridItem key={`${field.id}`} xs={12} sm={12} md={parseInt(fieldColspan, 10)}>
          <Box mt={2} key={`${field.id}`}>
            {fieldUI}
          </Box>
        </GridItem>,
      );
    });
  });

  const { classes } = props;
  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={6} md={12}>
        <GridContainer >
          <GridItem xs={12} sm={12} md={12}>
            <Card>
              <CardBody>
                {fieldItems}
                <br />
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <Button
                    round
                    color="primary"
                    onClick={handleClickSaveBtn.bind(this)}
                    style={{ float: 'right' }}
                  >
                    Save
                  </Button>
                  <Button
                    round
                    color="primary"
                    onClick={handleClickSubmitBtn.bind(this)}
                    disabled={!formCompleted}
                    style={{ float: 'right' }}
                  >
                    Submit
                  </Button>


                </div>
              </CardBody>
            </Card>
            <br />
          </GridItem>
        </GridContainer>
      </GridItem>
    </GridContainer >
  );
};

export default LinkActionExternalComp;
