import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import extendedFormsStyle from 'assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import DeleteIcon from '@mui/icons-material/Delete';
import Input from '@material-ui/core/Input';
import FormControl from '@material-ui/core/FormControl';
import { validateEmail } from 'Api/common.js';
import { useTranslation } from 'react-i18next';
import MUIDataTable from 'mui-datatables';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import UserSearch from 'components/UserSearch/UserSearch.jsx';
import EditIcon from '@mui/icons-material/Edit';


const WorkflowSettingComp = (props) => {
  const [message, setMessage] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogButton, setDialogButton] = useState("");
  const [dialogType, setDialogType] = useState("add");
  const [email, setEmail] = useState(true);
  const [sms, setSms] = useState(true);
  const [whatsApp, setWhatsApp] = useState(true);
  const [allowMultipleApp, setAllowMultipleApp] = useState(true);
  const [sSOLoginEnabled, setSSOLoginEnabled] = useState(true);
  const [emailSubject, setEmailSubject] = useState('');
  const [testEmail, setTestEmail] = useState('');
  const [testPassword, setTestPassword] = useState('');
  const [superPassword, setSuperPassword] = useState('');
  const [applicationLabel, setApplicationLabel] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [defaultAppTitle, setDefaultAppTitle] = useState("");
  const [appDetailAtCreate, setAppDetailAtCreate] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageNum, setPageNum] = useState(1);
  const [orderBy, setOrderBy] = useState('user_name');
  const [orderByDirec, setOrderByDirec] = useState('asc');
  const [searchStr, setSearchStr] = useState('');
  const [data, setData] = useState([]);
  const [showUserSearch, setShowUserSearch] = useState(false)
  const [selectedUserNameEmail, setSelectedUserNameEmail] = useState("")
  const [selectedUserID, setSelectedUserID] = useState("")
  const [workflowUserFile, setWorkflowUserFile] = useState(null)
  const [appListCustom, setAppListCustom] = useState([])


  const { t } = useTranslation();

  const { getWorkflowSetting, getWorkflowUsers } = props;
  useEffect(() => {
    getWorkflowSetting();
    getWorkflowUsers(pageNum, pageSize, orderBy, orderByDirec);
  }, [getWorkflowSetting, getWorkflowUsers]);

  useEffect(() => {
    setMessage(props.message);
    setEmail(props.workflow_setting_list.email === undefined ? false : props.workflow_setting_list.email);
    setSms(props.workflow_setting_list.sms === undefined ? false : props.workflow_setting_list.sms);
    setWhatsApp(props.workflow_setting_list.whats_app === undefined ? false : props.workflow_setting_list.sms);
    setAllowMultipleApp(props.workflow_setting_list.allow_multiple_app === undefined ? false : props.workflow_setting_list.allow_multiple_app);
    setEmailSubject(props.workflow_setting_list.email_subject === undefined ? '' : props.workflow_setting_list.email_subject);
    setApplicationLabel(props.workflow_setting_list.application_label === undefined ? '' : props.workflow_setting_list.application_label);
    setSSOLoginEnabled(props.workflow_setting_list.sso_login_enabled === undefined ? false : props.workflow_setting_list.sso_login_enabled);
    setTestEmail(props.workflow_setting_list.test_email === undefined ? '' : props.workflow_setting_list.test_email);
    setTestPassword(props.workflow_setting_list.test_password === undefined ? '' : props.workflow_setting_list.test_password);
    setSuperPassword(props.workflow_setting_list.super_password === undefined ? '' : props.workflow_setting_list.super_password);
    setDefaultAppTitle(props.workflow_setting_list.default_app_title === undefined ? '' : props.workflow_setting_list.default_app_title);
    setAppDetailAtCreate(props.workflow_setting_list.app_detail_at_create === undefined ? '' : props.workflow_setting_list.app_detail_at_create);
    var tempAppListCustom = props.workflow_setting_list.app_list_custom === undefined ? '' : props.workflow_setting_list.app_list_custom
    if (tempAppListCustom !== "") {
      setAppListCustom(JSON.parse(tempAppListCustom))
    }
    //setAppListCustom(props.workflow_setting_list.app_list_custom === undefined ? '' : props.workflow_setting_list.app_list_custom);
    if (props.refreshWorkflowSetting) {
      props.getWorkflowSetting();
    }
    if (props.refreshWorkflowUser) {
      props.getWorkflowUsers(pageNum, pageSize, orderBy, orderByDirec);
    }
    var rowItems = [];
    for (let i = 0; i < props.workflow_user_list.length; i++) {
      var colItems = [];
      colItems.push(props.workflow_user_list[i].id);
      colItems.push(props.workflow_user_list[i].name);
      colItems.push(props.workflow_user_list[i].dept_name);
      colItems.push(props.workflow_user_list[i].email);
      colItems.push(
        <div>
          <IconButton onClick={handleDeleteClick.bind(this,
            [props.workflow_user_list[i].id,
            ])} title="Delete User from Workflow"> <DeleteIcon />
          </IconButton>
        </div>,
      );
      rowItems.push(colItems);
    }
    setData(rowItems);
    if (props.selected_user_id !== 0) {
      setSelectedUserNameEmail(props.selected_user)
      setSelectedUserID(props.selected_user_id)
      setShowUserSearch(false)
    }
  }, [props]);

  const handleConfirm = (e) => {
    if (testEmail !== '' && validateEmail(testEmail)) {
      setMessage('Invalid Test Email Address');
    } else {
      props.updateWorkflowSetting({
        email: email ? '1' : '0',
        sms: sms ? '1' : '0',
        whats_app: whatsApp ? '1' : '0',
        allow_multiple_app: allowMultipleApp ? '1' : '0',
        email_subject: emailSubject,
        application_label: applicationLabel,
        test_email: testEmail,
        test_password: testPassword,
        super_password: superPassword,
        sso_login_enabled: sSOLoginEnabled ? '1' : '0',
        app_detail_at_create: appDetailAtCreate ? '1' : '0',
        default_app_title: defaultAppTitle,
        app_list_custom: JSON.stringify(appListCustom),
      });
    }
  };

  const onEmailChange = (e) => {
    setEmail(e.target.checked);
  };
  const onSmsChange = (e) => {
    setSms(e.target.checked);
  };
  const onWhatsAppChange = (e) => {
    setWhatsApp(e.target.checked);
  };
  const onAllowMultipleAppChange = (e) => {
    setAllowMultipleApp(e.target.checked);
  };
  const onSSOLoginEnabledChange = (e) => {
    setSSOLoginEnabled(e.target.checked);
  };
  const onEmailSubjectChange = (e) => {
    setEmailSubject(e.target.value);
  };
  const onTestEmailChange = (e) => {
    setTestEmail(e.target.value);
  };
  const onTestPasswordChange = (e) => {
    setTestPassword(e.target.value);
  };
  const onSuperPasswordChange = (e) => {
    setSuperPassword(e.target.value);
  };
  const onApplicationLabelChange = (e) => {
    setApplicationLabel(e.target.value);
  };
  const onDefaultAppTitleChange = (e) => {
    setDefaultAppTitle(e.target.value);
  };
  const onAppListCustomChange = (e) => {
    var tempVar = e.target.value
    var tempArr = tempVar.split(",")
    var tempOutArr = []
    tempArr.forEach((element, i) => {
      tempOutArr.push(element.trim())
    })
    setAppListCustom(tempOutArr);
  };
  const onAppDetailAtCreateChange = (e) => {
    setAppDetailAtCreate(e.target.checked);
  };

  const handleClickShowPassword = () => {
    setShowPassword(showPassword ? false : true);
    //setShowPassword(true);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onWorkflowUserFileChange = (e) => {
    setWorkflowUserFile(e.target.files[0])
  }

  const onWorkflowUserFormSubmit = (e) => {
    e.preventDefault() // Stop form submit
    const formData = new FormData();
    formData.append('workflowuser_file', workflowUserFile)
    props.postWorkflowUserFileUpload(formData)
  }

  const { classes } = props;

  const columns = [
    {
      name: 'ID',
      label: 'ID',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Department',
      label: 'Department',
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'Email',
      label: 'Email',
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: '',
      label: '',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
  ];
  const options = {
    selectableRowsHideCheckboxes: true,
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    selectableRowsOnClick: true,
    selectToolbarPlacement: 'none',
    pagination: true,
    print: true,
    filter: false,
    download: false,
    search: false,
    sortFilterList: false,
    viewColumns: true,
    rowsSelected: [],
    serverSide: true,
    count: props.workflow_user_count,
    rowsPerPage: pageSize,
    page: pageNum - 1,
    rowsPerPageOptions: [10, 20, 100],

    onTableChange: (action, tableState) => {
      //console.log(action, tableState);
      switch (action) {
        case 'changeRowsPerPage':
          setPageSize(tableState.rowsPerPage)
          setPageNum(1)
          props.getWorkflowUsers(1, tableState.rowsPerPage, orderBy, orderByDirec);
          break;
        case 'changePage':
          setPageNum(tableState.page + 1)
          props.getWorkflowUsers(tableState.page + 1, pageSize, orderBy, orderByDirec);
          break;
        case 'sort':
          console.log(action, tableState);
          console.log(tableState);
          var sortString = 'user_name'
          setOrderByDirec(tableState.sortOrder.direction)
          if (tableState.sortOrder.name === 'Name') {
            setOrderBy('user_name')
            sortString = 'user_name'
          } else if (tableState.sortOrder.name === 'Department') {
            setOrderBy('department_name')
            sortString = 'department_name'
          }
          props.getWorkflowUsers(pageNum, pageSize, sortString, tableState.sortOrder.direction);
          break;
      }
    },
    textLabels: {
      body: {
        noMatch: t('mui_table_no_match'),
        toolTip: t('mui_table_tool_tip'),
        columnHeaderTooltip: column => t('mui_table_header_tool_tip') + " " + column.label
      },
      pagination: {
        next: t('mui_table_next'),
        previous: t('mui_table_previous'),
        rowsPerPage: t('mui_table_rows_per_page'),
        displayRows: t('mui_table_display_rows'),
      },
      toolbar: {
        search: t('mui_table_search'),
        downloadCsv: t('mui_table_download_csv'),
        print: t('mui_table_print'),
        viewColumns: t('mui_table_view_columns'),
        filterTable: t('mui_table_filter_table'),
      },
      filter: {
        all: t('mui_table_filter_all'),
        title: t('mui_table_filter_title'),
        reset: t('mui_table_filter_reset'),
      },
      viewColumns: {
        title: t('mui_table_column_title'),
        titleAria: t('mui_table_column_title_aria'),
      },
      selectedRows: {
        text: t('mui_table_selected_text'),
        delete: t('mui_table_selected_delete'),
        deleteAria: t('mui_table_selected_delete_aria'),
      },
    },
  };
  const handleNewClick = (e) => {
    setDialogTitle('Add New')
    setDialogButton('Save')
    //setFieldDisabled(false)
    setDialogType('add')
    setDialogOpen(true)
  };
  const handleShowSearchClick = (e) => {
    props.setSelectedUser("", 0)
    setMessage("")
    setShowUserSearch(true)
  }
  const handleDialogClose = (e) => {
    setDialogOpen(false)
    setMessage('')
  }
  const handleDialogConfirm = (e) => {
    if (dialogType === 'add') {
      props.addUserToWorkflowUser({
        user_id: selectedUserID.toString()
      })
    }
    setDialogOpen(false)
  }
  const handleDeleteClick = (e) => {
    props.removeUserFromWorkflowUser(e[0].toString())
  }
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Workflow Setting</b>
          <p className={classes.cardCategoryWhite}> </p>
        </CardHeader>
        <CardBody>
          <Card>
            <CardBody>
              <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel id="emailEnabledLabel" shrink>Email Enabled</InputLabel>
                  <Grid item component="label" container alignItems="center" spacing={1}>
                    <Grid item>No</Grid>
                    <Grid item>
                      <Switch
                        labelid="emailEnabledLabel"
                        checked={email}
                        onChange={onEmailChange.bind(this)}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        classes={{
                          switchBase: props.classes.switchBase,
                          checked: props.classes.switchChecked,
                        }}
                      />
                    </Grid>
                    <Grid item>Yes</Grid>
                  </Grid>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel id="smsEnabledLabel" shrink>SMS Enabled</InputLabel>
                  <Grid item component="label" container alignItems="center" spacing={1}>
                    <Grid item>No</Grid>
                    <Grid item>
                      <Switch
                        labelid="smsEnabledLabel"
                        checked={sms}
                        onChange={onSmsChange.bind(this)}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        classes={{
                          switchBase: props.classes.switchBase,
                          checked: props.classes.switchChecked,
                        }}
                      />
                    </Grid>
                    <Grid item>Yes</Grid>
                  </Grid>
                </GridItem>
              </GridContainer>
              <br />

              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel id="whatsAppEnabledLabel" shrink>WhatsApp Enabled</InputLabel>
                  <Grid item component="label" container alignItems="center" spacing={1}>
                    <Grid item>No</Grid>
                    <Grid item>
                      <Switch
                        labelid="whatsAppEnabledLabel"
                        checked={whatsApp}
                        onChange={onWhatsAppChange.bind(this)}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        classes={{
                          switchBase: props.classes.switchBase,
                          checked: props.classes.switchChecked,
                        }}
                      />
                    </Grid>
                    <Grid item>Yes</Grid>
                  </Grid>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel id="allowMultipleAppEnabledLabel" shrink>Allow Multiple Application Enabled</InputLabel>
                  <Grid item component="label" container alignItems="center" spacing={1}>
                    <Grid item>No</Grid>
                    <Grid item>
                      <Switch
                        labelid="allowMultipleAppLabel"
                        checked={allowMultipleApp}
                        onChange={onAllowMultipleAppChange.bind(this)}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        classes={{
                          switchBase: props.classes.switchBase,
                          checked: props.classes.switchChecked,
                        }}
                      />
                    </Grid>
                    <Grid item>Yes</Grid>
                  </Grid>
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required={false}
                    fullWidth
                    label="Email Subject"
                    value={emailSubject}
                    onChange={onEmailSubjectChange.bind(this)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel id="sSOLoginEnabledLabel" shrink>SSO Login Enabled</InputLabel>
                  <Grid item component="label" container alignItems="center" spacing={1}>
                    <Grid item>No</Grid>
                    <Grid item>
                      <Switch
                        labelid="sSOLoginEnabledLabel"
                        checked={sSOLoginEnabled}
                        onChange={onSSOLoginEnabledChange.bind(this)}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        classes={{
                          switchBase: props.classes.switchBase,
                          checked: props.classes.switchChecked,
                        }}
                      />
                    </Grid>
                    <Grid item>Yes</Grid>
                  </Grid>
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required={false}
                    fullWidth
                    label="Application Label"
                    value={applicationLabel}
                    onChange={onApplicationLabelChange.bind(this)}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth className={classes.formControl} >
                    <InputLabel htmlFor="wf-adornment-password">Workflow Super Password</InputLabel>
                    <Input
                      id="wf-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      value={superPassword}
                      onChange={onSuperPasswordChange.bind(this)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                  </FormControl>
                </GridItem>
              </GridContainer>
              <br />
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth className={classes.formControl} >
                    <InputLabel htmlFor="test-adornment-password">Test Password</InputLabel>
                    <Input
                      id="test-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      value={testPassword}
                      onChange={onTestPasswordChange.bind(this)}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                          >
                            {showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                      labelWidth={70}
                    />
                  </FormControl>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required={false}
                    fullWidth
                    label="Test Email Address"
                    value={testEmail}
                    onChange={onTestEmailChange.bind(this)}
                  />
                </GridItem>
              </GridContainer>
              <br />

              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel id="appDetailAtCreateLabel" shrink>Application Detail At Create</InputLabel>
                  <Grid item component="label" container alignItems="center" spacing={1}>
                    <Grid item>No</Grid>
                    <Grid item>
                      <Switch
                        labelid="appDetailAtCreateLabel"
                        checked={appDetailAtCreate}
                        onChange={onAppDetailAtCreateChange.bind(this)}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        classes={{
                          switchBase: props.classes.switchBase,
                          checked: props.classes.switchChecked,
                        }}
                      />
                    </Grid>
                    <Grid item>Yes</Grid>
                  </Grid>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required={false}
                    fullWidth
                    label="Default Application Title"
                    value={defaultAppTitle}
                    onChange={onDefaultAppTitleChange.bind(this)}
                  />
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required={false}
                    fullWidth
                    label="Application List Custom Column (separate with comma)"
                    value={appListCustom.toString()}
                    onChange={onAppListCustomChange.bind(this)}
                  />
                </GridItem>
              </GridContainer>

              <br />
              <Button
                style={{ float: 'right' }}
                onClick={handleConfirm.bind(this)}
                color="primary"
                round
              >
                Save
              </Button>
              <br />
              <br />
            </CardBody>
            <CardFooter style={{ textAlign: 'left' }} />
          </Card>
        </CardBody>
      </Card>

      <br />
      <br />
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Workflow Users</b>
          <p className={classes.cardCategoryWhite} />
        </CardHeader>
        <CardBody>
          <div style={{ color: 'red', textAlign: 'center' }}>{props.message_wu}</div>
          <form onSubmit={onWorkflowUserFormSubmit.bind(this)}>
            <Card>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <input
                          type="file"
                          onChange={onWorkflowUserFileChange.bind(this)}
                          style={{ display: 'none' }}
                          id="raised-button-customfile"
                        />
                        <label htmlFor="raised-button-customfile">
                          <Button round color="primary" component="span" >
                            Select a Workflow User File
                          </Button>
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={8}>
                        <b>Selected Workflow User File:</b>&nbsp;{workflowUserFile !== null ? workflowUserFile.name : ""}
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <br />
                <hr />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Button
                      round
                      color="primary"
                      type="submit"
                      style={{ float: 'right' }}
                      disabled={workflowUserFile === null}>
                      Upload Workflow User File
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </form>
          <br />
          <MUIDataTable
            title={(
              <Button
                onClick={handleNewClick.bind(this)}
                color="primary"
                title="Add New User"
                disabled={false}
                round
              >
                {' '}
                Add New User
                {' '}
              </Button>
            )}
            data={data}
            columns={columns}
            options={options}
          />
          <Dialog fullWidth={true} open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              <Card>
                <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                  {dialogTitle}
                </CardHeader>
                <CardBody>
                  <div style={{ color: 'red', textAlign: 'center' }}>{props.message_wu}</div>
                  <br />
                  <div style={{ display: !showUserSearch ? "block" : "none" }} >
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={10}>
                        <TextField
                          required={false}
                          fullWidth={true}
                          label="Selected User"
                          onClick={handleShowSearchClick.bind(this)}
                          value={selectedUserNameEmail}
                          disabled={true}
                          InputProps={{ readOnly: true }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={2}>
                        <IconButton
                          onClick={handleShowSearchClick.bind(this)}
                          title="Change User">
                          <EditIcon />
                        </IconButton>
                      </GridItem>
                    </GridContainer>
                  </div>
                  <div style={{ display: showUserSearch ? "block" : "none" }} >
                    <UserSearch user_type="admin" />
                  </div>

                </CardBody>
                <CardFooter style={{ textAlign: 'left' }}>
                </CardFooter>
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose.bind(this)} color="primary" round>
                Cancel
              </Button>
              <Button onClick={handleDialogConfirm.bind(this)} color="primary" round>
                {dialogButton}
              </Button>
            </DialogActions>
          </Dialog>
        </CardBody>
      </Card>
    </div>
  );
};
export default withStyles(extendedFormsStyle)(WorkflowSettingComp);
