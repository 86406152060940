export const GET_WORKFLOW_REQUEST = 'GET_WORKFLOW_REQUEST';
export const GET_WORKFLOW_SUCCESS = 'GET_WORKFLOW_SUCCESS';
export const GET_WORKFLOW_FAILURE = 'GET_WORKFLOW_FAILURE';
export const POST_WORKFLOW_REQUEST = 'POST_WORKFLOW_REQUEST';
export const POST_WORKFLOW_SUCCESS = 'POST_WORKFLOW_SUCCESS';
export const POST_WORKFLOW_FAILURE = 'POST_WORKFLOW_FAILURE';
export const PATCH_WORKFLOW_REQUEST = 'PATCH_WORKFLOW_REQUEST';
export const PATCH_WORKFLOW_SUCCESS = 'PATCH_WORKFLOW_SUCCESS';
export const PATCH_WORKFLOW_FAILURE = 'PATCH_WORKFLOW_FAILURE';
export const DELETE_WORKFLOW_REQUEST = 'DELETE_WORKFLOW_REQUEST';
export const DELETE_WORKFLOW_SUCCESS = 'DELETE_WORKFLOW_SUCCESS';
export const DELETE_WORKFLOW_FAILURE = 'DELETE_WORKFLOW_FAILURE';
export const GET_WORKFLOW_EXPORT_REQUEST = 'GET_WORKFLOW_EXPORT_REQUEST';
export const GET_WORKFLOW_EXPORT_SUCCESS = 'GET_WORKFLOW_EXPORT_SUCCESS';
export const GET_WORKFLOW_EXPORT_FAILURE = 'GET_WORKFLOW_EXPORT_FAILURE';
export const POST_WORKFLOW_FILE_UPLOAD_REQUEST = 'POST_WORKFLOW_FILE_UPLOAD_REQUEST';
export const POST_WORKFLOW_FILE_UPLOAD_SUCCESS = 'POST_WORKFLOW_FILE_UPLOAD_SUCCESS';
export const POST_WORKFLOW_FILE_UPLOAD_FAILURE = 'POST_WORKFLOW_FILE_UPLOAD_FAILURE';
