import API from 'Api/api.js';
import { commonBackendCall, getAdminConfig } from 'Api/common.js';
import {
  GET_ALL_APPLICATION_REQUEST,
  GET_ALL_APPLICATION_SUCCESS,
  GET_ALL_APPLICATION_FAILURE,
  DELETE_APPLICATION_REQUEST,
  DELETE_APPLICATION_SUCCESS,
  DELETE_APPLICATION_FAILURE,
  GET_APP_CORRES_REQUEST,
  GET_APP_CORRES_SUCCESS,
  GET_APP_CORRES_FAILURE,
  GET_APP_TRACK_REPORT_REQUEST,
  GET_APP_TRACK_REPORT_SUCCESS,
  GET_APP_TRACK_REPORT_FAILURE,
  GET_APP_TRACK_REPORT_FORM_DATA_REQUEST,
  GET_APP_TRACK_REPORT_FORM_DATA_SUCCESS,
  GET_APP_TRACK_REPORT_FORM_DATA_FAILURE,
  POST_ADMIN_RESTORE_APP_REQUEST,
  POST_ADMIN_RESTORE_APP_SUCCESS,
  POST_ADMIN_RESTORE_APP_FAILURE,
} from 'constants/admin/Application';

export function getAllApplications() {
  return commonBackendCall(
    GET_ALL_APPLICATION_REQUEST,
    GET_ALL_APPLICATION_SUCCESS,
    GET_ALL_APPLICATION_FAILURE,
    API.get('admin/applications', getAdminConfig()),
  );
}

export function deleteApplication(applicationID) {
  return commonBackendCall(
    DELETE_APPLICATION_REQUEST,
    DELETE_APPLICATION_SUCCESS,
    DELETE_APPLICATION_FAILURE,
    API.delete(`admin/applications/${applicationID}`, getAdminConfig()),
  );
}

export function adminRestoreArchiveApplication(applicationID, obj) {
  return commonBackendCall(
    POST_ADMIN_RESTORE_APP_REQUEST,
    POST_ADMIN_RESTORE_APP_SUCCESS,
    POST_ADMIN_RESTORE_APP_FAILURE,
    API.post(`admin/applications/restore-archive/${applicationID}`, obj, getAdminConfig()),
  );
}

export function getApplicationCorres(applicationID) {
  return commonBackendCall(
    GET_APP_CORRES_REQUEST,
    GET_APP_CORRES_SUCCESS,
    GET_APP_CORRES_FAILURE,
    API.get(`admin/application-correspondenses/${applicationID}`, getAdminConfig()),
  );
}

export function getApplicationTrackReport(taskID, startDate, endDate, reminder) {
  return commonBackendCall(
    GET_APP_TRACK_REPORT_REQUEST,
    GET_APP_TRACK_REPORT_SUCCESS,
    GET_APP_TRACK_REPORT_FAILURE,
    API.get(`admin/application-track-report?task=${taskID}&start=${startDate}&end=${endDate}&reminder=${reminder}`, getAdminConfig()),
  );
}


export function getApplicationTrackFormDataReport(taskID, startDate, endDate) {
  return commonBackendCall(
    GET_APP_TRACK_REPORT_FORM_DATA_REQUEST,
    GET_APP_TRACK_REPORT_FORM_DATA_SUCCESS,
    GET_APP_TRACK_REPORT_FORM_DATA_FAILURE,
    API.get(`admin/application-track-report-form-data?task=${taskID}&start=${startDate}&end=${endDate}`, getAdminConfig()),
  );
}