import {
  GET_ADMIN_REQUEST,
  GET_ADMIN_SUCCESS,
  GET_ADMIN_FAILURE,
  POST_ADMIN_REQUEST,
  POST_ADMIN_SUCCESS,
  POST_ADMIN_FAILURE,
  DELETE_ADMIN_REQUEST,
  DELETE_ADMIN_SUCCESS,
  DELETE_ADMIN_FAILURE,
} from '../constants/networkadmin/Admin';

import {
  SET_SELECTED_USER
} from '../constants/User';

const initialState = {
  requesting: false,
  message: '',
  admin_list: '',
  refreshAdmin: false,
};

export default function admin(state = initialState, action) {
  switch (action.type) {

    case SET_SELECTED_USER:
      return {
        ...state,
        message: '',
      };

    case GET_ADMIN_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshAdmin: false,
        message: '',
      };

    case GET_ADMIN_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        admin_list: action.payload.data,
      };

    case GET_ADMIN_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case POST_ADMIN_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshAdmin: false,
      };

    case POST_ADMIN_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshAdmin: true,
      };

    case POST_ADMIN_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case DELETE_ADMIN_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshAdmin: false,
      };

    case DELETE_ADMIN_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshAdmin: true,
      };

    case DELETE_ADMIN_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
