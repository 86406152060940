export const TEXT_FIELD = 'TEXT_FIELD';
export const TEXT_AREA = 'TEXT_AREA';
export const DROP_DOWN = 'DROP_DOWN';
export const RADIO_BUTTON = 'RADIO_BUTTON';
export const CHECK_BOX = 'CHECK_BOX';
export const DATE = 'DATE';
export const TIME = 'TIME';
export const HELPTEXT = 'HELPTEXT';
export const COMMITTEE = 'COMMITTEE';
export const FILE_SHARING = 'FILE_SHARING';
export const AGGREGATE_NUM = 'AGGREGATE_NUM';
export const WEEK_CALENDAR = 'WEEK_CALENDAR';
export const ITEM_SEARCH = 'ITEM_SEARCH';
export const ITEM_SEARCH2 = 'ITEM_SEARCH2';
export const ITEM_DATATABLE = 'ITEM_DATATABLE';
export const ITEM_TRANSACTIONS = 'ITEM_TRANSACTIONS';
export const ITEM_OPT_DETAILS = 'ITEM_OPT_DETAILS';
export const SYSTEM_DATA = 'SYSTEM_DATA';
export const TRANS_TEXT_FIELD = 'TRANS_TEXT_FIELD';