export const GET_NAMEEXCLUSION_REQUEST = 'GET_NAMEEXCLUSION_REQUEST';
export const GET_NAMEEXCLUSION_SUCCESS = 'GET_NAMEEXCLUSION_SUCCESS';
export const GET_NAMEEXCLUSION_FAILURE = 'GET_NAMEEXCLUSION_FAILURE';
export const POST_NAMEEXCLUSION_REQUEST = 'POST_NAMEEXCLUSION_REQUEST';
export const POST_NAMEEXCLUSION_SUCCESS = 'POST_NAMEEXCLUSION_SUCCESS';
export const POST_NAMEEXCLUSION_FAILURE = 'POST_NAMEEXCLUSION_FAILURE';
export const PATCH_NAMEEXCLUSION_REQUEST = 'PATCH_NAMEEXCLUSION_REQUEST';
export const PATCH_NAMEEXCLUSION_SUCCESS = 'PATCH_NAMEEXCLUSION_SUCCESS';
export const PATCH_NAMEEXCLUSION_FAILURE = 'PATCH_NAMEEXCLUSION_FAILURE';
export const DELETE_NAMEEXCLUSION_REQUEST = 'DELETE_NAMEEXCLUSION_REQUEST';
export const DELETE_NAMEEXCLUSION_SUCCESS = 'DELETE_NAMEEXCLUSION_SUCCESS';
export const DELETE_NAMEEXCLUSION_FAILURE = 'DELETE_NAMEEXCLUSION_FAILURE';
