import API from 'Api/api.js';
import {
  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAILURE,
  ADMIN_LOGIN_CHOOSE_WORKFLOW,
  GET_WORKFLOWS_REQUEST,
  GET_WORKFLOWS_SUCCESS,
  GET_WORKFLOWS_FAILURE,
} from 'constants/admin/Login';
import {
  AUTHORIZATION_REQUIRED,
  UNDEFINED_ERROR,
  RESET_UNDEFINED_ERROR,
} from 'constants/admin/Default';

function parseJwt(token) {
  var base64Url = token.split('.')[1];
  var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));

  return JSON.parse(jsonPayload);
}
const apiKey = localStorage.getItem('network_api_key');

export function onLogin(obj, redirect) {
  return (dispatch) => {
    dispatch({
      type: ADMIN_LOGIN_REQUEST,
      payload: {
        requesting: true,
        message: '',
        error_code: '',
      },
    });
    dispatch({
      type: RESET_UNDEFINED_ERROR,
    });
    let config = {};
    config = {
      headers: {
        Apikey: apiKey,
      },
    };

    API.post('admin/login-admin', obj, config)
      .then((response) => {
        if (response.data.result === 'success') {
          // localStorage.clear();
          const tokenObj = parseJwt(response.data.data.token);
          const unixTime = Math.floor(Date.now() / 1000);
          if (tokenObj.workflow_id == undefined) {
            localStorage.setItem('network_user_token', response.data.data.token);
            localStorage.setItem('network_id', tokenObj.network_id);
            localStorage.setItem('network_name', tokenObj.network_name);
            localStorage.setItem('user_id', tokenObj.user_id);
            localStorage.setItem('name', tokenObj.name);
            localStorage.setItem('email', tokenObj.email);
            dispatch({
              type: ADMIN_LOGIN_CHOOSE_WORKFLOW,
              payload: {
                requesting: false,
                data: response.data.data,
                redirect,
              },
            });
          } else {
            localStorage.removeItem('token');
            localStorage.removeItem('networkadmin_token');
            localStorage.setItem('admin_token', response.data.data.token);
            localStorage.setItem('refresh_token', response.data.data.refresh_token);
            localStorage.setItem('network_id', tokenObj.network_id);
            localStorage.setItem('network_name', tokenObj.network_name);
            localStorage.setItem('workflow_id', tokenObj.workflow_id);
            localStorage.setItem('workflow_name', tokenObj.workflow_name);
            localStorage.setItem('workflow_alias', tokenObj.workflow_alias);
            localStorage.setItem('workflow_desc', tokenObj.workflow_desc);
            localStorage.setItem('workflow_state', tokenObj.workflow_state);
            localStorage.setItem('user_id', tokenObj.user_id);
            localStorage.setItem('name', tokenObj.name);
            localStorage.setItem('email', tokenObj.email);
            localStorage.setItem('role_id', tokenObj.role_id);
            localStorage.setItem('task_id', tokenObj.task_id);
            localStorage.setItem('action_id', tokenObj.action_id);
            localStorage.setItem('login_time', unixTime);
            dispatch({
              type: ADMIN_LOGIN_SUCCESS,
              payload: {
                requesting: false,
                redirect,
              },
            });
          }
        }
      })
      .catch((error) => {
        if (error.response === undefined) {
          dispatch({
            type: UNDEFINED_ERROR,
            payload: {
              error: error.request.status,
            },
          });
          /*
        } else if (error.request.status !== 400) {
          dispatch({
            type: UNDEFINED_ERROR,
            payload: {
              error: error.request.status,
            },
          });
          */
        } else {
          dispatch({
            type: ADMIN_LOGIN_FAILURE,
            payload: {
              requesting: false,
              message: error.response.data.data.message,
              error_code: error.response.data.data.error_code,
            },
          });
        }
      });
  };
}

export function onRefresh() {
  var tokenType = '';
  var token = '';
  if (localStorage.getItem('token') !== null) {
    tokenType = 'USER';
    token = localStorage.getItem('token');
  } else if (localStorage.getItem('admin_token') !== null) {
    tokenType = 'ADMIN';
    token = localStorage.getItem('admin_token');
  } else if (localStorage.getItem('networkadmin_token') !== null) {
    tokenType = 'NETWORK_ADMIN';
    token = localStorage.getItem('networkadmin_token');
  }
  const config = {
    headers: {
      Token: token,
      RefreshToken: localStorage.getItem('refresh_token'),
    },
  };
  API.get('applications/refresh-token', config)
    .then((response) => {
      const unixTime = Math.floor(Date.now() / 1000);
      localStorage.setItem('login_time', unixTime);
      if (response.data.result === 'success') {
        if (tokenType == 'USER') {
          localStorage.setItem('token', response.data.data.token);
        } else if (tokenType == 'ADMIN') {
          localStorage.setItem('admin_token', response.data.data.token);
        } else if (tokenType == 'NETWORK_ADMIN') {
          localStorage.setItem('networkadmin_token', response.data.data.token);
        }
        localStorage.setItem('refresh_token', response.data.data.refresh_token);
      } else {
        localStorage.clear();
      }
    })
    .catch((error) => {
      localStorage.clear();
    });
}

export function getNetworkWorkflows() {
  return (dispatch) => {
    dispatch({
      type: GET_WORKFLOWS_REQUEST,
      payload: {
        requesting: true,
        message: '',
        error_code: '',
      },
    });
    dispatch({
      type: RESET_UNDEFINED_ERROR,
    });
    let config = {};
    config = {
      headers: {
        Apikey: apiKey,
      },
    };
    var workflowAdminToken = localStorage.getItem('token') ? localStorage.getItem('token').toString() : '';
    var networkUserToken = localStorage.getItem('network_user_token') ? localStorage.getItem('network_user_token').toString() : '';
    var queryParam = '';
    if (networkUserToken != '') {
      queryParam = `?network_admin_user_token=${networkUserToken}`;
    }
    if (workflowAdminToken != '') {
      queryParam = `?workflow_admin_token=${workflowAdminToken}`;
    }

    API.get(`applications/workflows-public${queryParam}`, config)
      .then((response) => {
        if (response.data.result === 'success') {
          dispatch({
            type: GET_WORKFLOWS_SUCCESS,
            payload: {
              requesting: false,
              data: response.data.data,
            },
          });
        }
      })
      .catch((error) => {
        if (error.response === undefined) {
          dispatch({
            type: UNDEFINED_ERROR,
            payload: {
              error: error.request.status,
            },
          });
        } else if (error.request.status === 405) {
          API.get(`admin/workflows-public${queryParam}`, config)
            .then((response) => {
              if (response.data.result === 'success') {
                dispatch({
                  type: GET_WORKFLOWS_SUCCESS,
                  payload: {
                    requesting: false,
                    data: response.data.data,
                  },
                });
              }
            })
            .catch((error) => {
              if (error.response === undefined) {
                dispatch({
                  type: UNDEFINED_ERROR,
                  payload: {
                    error: error.request.status,
                  },
                });
              } else if (error.request.status !== 400) {
                dispatch({
                  type: UNDEFINED_ERROR,
                  payload: {
                    error: error.request.status,
                  },
                });
              } else {
                dispatch({
                  type: GET_WORKFLOWS_FAILURE,
                  payload: {
                    requesting: false,
                    message: error.response.data.data.message,
                    error_code: error.response.data.data.error_code,
                  },
                });
              }
            });
        } else if (error.request.status !== 400) {
          dispatch({
            type: UNDEFINED_ERROR,
            payload: {
              error: error.request.status,
            },
          });
        } else {
          dispatch({
            type: GET_WORKFLOWS_FAILURE,
            payload: {
              requesting: false,
              message: error.response.data.data.message,
              error_code: error.response.data.data.error_code,
            },
          });
        }
      });
  };
}
