import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CircularProgress from '@mui/material/CircularProgress';
import withStyles from "@material-ui/core/styles/withStyles";
import extendedFormsStyle from "assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx";

const BatchLogsComp = (props) => {
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");

  const { getBatchLogs, batch_id } = props;
  useEffect(() => {
    getBatchLogs(batch_id);
  }, [getBatchLogs, batch_id])

  useEffect(() => {
    setMessage(props.message)
    var rowItems = [];
    if (props.intg_batch_log_list.length > 0) {
      for (let i = 0; i < props.intg_batch_log_list.length; i++) {
        var colItems = [];
        colItems.push(i + 1);
        colItems.push(props.intg_batch_log_list[i].log_data);
        colItems.push(props.intg_batch_log_list[i].insert_date);
        rowItems.push(colItems);
      }
    }
    setData(rowItems)
  }, [props])

  //const { classes } = props;
  const columns = [
    "Serial",
    "Log",
    "Date/Time",
  ];
  const options = {
    filterType: "dropdown",
    responsive: "standard",
    filter: true,
    selectableRows: "none",
    rowsPerPage: 100,
    selectToolbarPlacement: "none"
  };
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Batch Logs</b>
        </CardHeader>
        <CardBody>
          <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
          <MUIDataTable
            data={data}
            columns={columns}
            options={options}
          />
        </CardBody>
      </Card>
    </div >
  );
}
export default withStyles(extendedFormsStyle)(BatchLogsComp)
