import API from 'Api/api.js';
import { commonBackendCall, getAdminConfig } from 'Api/common.js';
import {
  GET_WORKFLOW_REQUEST,
  GET_WORKFLOW_SUCCESS,
  GET_WORKFLOW_FAILURE,
  PATCH_WORKFLOW_REQUEST,
  PATCH_WORKFLOW_SUCCESS,
  PATCH_WORKFLOW_FAILURE,
  POST_WORKFLOW_VERSION_REQUEST,
  POST_WORKFLOW_VERSION_SUCCESS,
  POST_WORKFLOW_VERSION_FAILURE,
} from 'constants/admin/Workflow';

export function getWorkflowByAdmin() {
  return commonBackendCall(
    GET_WORKFLOW_REQUEST,
    GET_WORKFLOW_SUCCESS,
    GET_WORKFLOW_FAILURE,
    API.get('admin/workflows-by-admin', getAdminConfig()),
  );
}
export function updateWorkflowByAdmin(obj) {
  return commonBackendCall(
    PATCH_WORKFLOW_REQUEST,
    PATCH_WORKFLOW_SUCCESS,
    PATCH_WORKFLOW_FAILURE,
    API.patch('admin/workflows-update-by-admin', obj, getAdminConfig()),
  );
}
function replaceStringVar(body, keyValue) {
  var i = body.indexOf('#');
  if (i > -1) {
    var secondIndex = body.indexOf('#', i + 1);
    var bodyPrefix = body.substring(0, i);
    var bodySuffix = body.substring(i + 1).substring(body.substring(i + 1).indexOf('#') + 1);
    var variableName = body.substring(i + 1, secondIndex);
    var variableValue = keyValue.find((o) => o.key === variableName).value;
    body = bodyPrefix + variableValue + bodySuffix;
    return replaceStringVar(bodyPrefix + variableValue + bodySuffix, keyValue);
  }
  return body;
}
function callBackend(apiCalls, keyValue, config, i) {
  if (typeof apiCalls[i] !== 'undefined') {
    var apiCall = '';
    if (apiCalls[i].title_var === '') {
      apiCall = API.post(apiCalls[i].path, replaceStringVar(apiCalls[i].body, keyValue), config);
    } else if (apiCalls[i].title_var === 'PATCH') {
      apiCall = API.patch(replaceStringVar(apiCalls[i].find_path, keyValue), replaceStringVar(apiCalls[i].body, keyValue), config);
    } else {
      apiCall = API.get(encodeURI(`${apiCalls[i].find_path}?title=${apiCalls[i].title_var}`), config);
    }
    apiCall
      .then((response) => {
        if (response.data.result === 'success') {
          var temp = [];
          temp = { key: apiCalls[i].id_var, value: response.data.data[apiCalls[i].resp_var] };
          keyValue.push(temp);
          i++;
          callBackend(apiCalls, keyValue, config, i);
        }
      });
  }
}

export function createWorkflowVersion(obj) {
  return (dispatch) => {
    dispatch({
      type: POST_WORKFLOW_VERSION_REQUEST,
      payload: {
        requesting: true,
      },
    });

    const apiKey = localStorage.getItem('network_api_key');
    var config = {
      headers: {
        Apikey: apiKey,
        Token: localStorage.getItem('admin_token'),
      },
    };
    API.post('admin/workflows-version-by-admin', obj, config)
      .then((response) => {
        if (response.data.result === 'success') {
          var apiCalls = response.data.data.api_requests;
          var newWorkflowID = response.data.data.workflow_id;

          API.post('admin/login-admin', `{"workflow_id":"${newWorkflowID}", "admin_token": "${localStorage.getItem('admin_token')}"}`, config)
            .then((response) => {
              if (response.data.result === 'success') {
                var adminToken = response.data.data.token;
                const config = {
                  headers: {
                    Token: adminToken,
                    Apikey: apiKey,
                  },
                };
                var keyValue = [];
                var i = 0;
                callBackend(apiCalls, keyValue, config, i);
              }
            });
        }
      });
    dispatch({
      type: POST_WORKFLOW_VERSION_SUCCESS,
      payload: {
        requesting: false,
      },

    });
  };
}
