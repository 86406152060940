/* eslint-disable react/jsx-props-no-spreading */
import React /* ,{useState, useEffect } */ from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
// import { makeStyles } from "@material-ui/core/styles";
import AppHeader from 'components/Header/AppHeader.jsx';
import Footer from 'components/Footer/Footer';
import applicationRoutes from 'routes/application';
import appDashboardStyle from 'assets/jss/material-dashboard-react/layouts/appDashboardStyle.jsx';
import PropTypes from 'prop-types';


const PrivateRoute = () => {
  return localStorage.getItem('token') ? <Outlet /> : <Navigate to="/public/login" />;
}
// let ps;

// const useStyles = makeStyles(appDashboardStyle)

function Application({ ...props }) {
  const mainPanelUser = React.createRef();
  const { classes } = props;
  return (
    <div className={classes.wrapper}>
      <div className={classes.mainPanelUser} ref={mainPanelUser}>
        {parseInt(localStorage.getItem('workflow_state'), 10) === 1 ?
          <div style={{ color: "000000", backgroundColor: "#FFFF00", textAlign: 'center' }} > TEST MODE </div>
          : ""}
        <AppHeader />
        <div className={classes.content}>
          {/* <div className={classes.container}> */}
          {/*
          {applicationRoutes.map((prop, key) => {
            <Route path={prop.path} element={prop.component} key={key} />
          })}
        */}
          {/* </div> */}
          {/*
          <Routes>
            <Route path={'app/list'} element={<ApplicationsList />} />
          </Routes>
        */}
          <PrivateRoute />
        </div>
        <Footer />
      </div>
    </div>
  );
}

Application.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(appDashboardStyle)(Application);
