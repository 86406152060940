import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import withStyles from "@material-ui/core/styles/withStyles";
import extendedFormsStyle from "assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx";

const InboundWebhookComp = (props) => {
  const [message, setMessage] = useState("");

  const { getSystemUser } = props;
  useEffect(() => {
    getSystemUser();
  }, [getSystemUser])


  useEffect(() => {
    setMessage(props.message)
  }, [props])

  //const { classes } = props;
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>BOT Request Credential</b>
        </CardHeader>
        <CardBody>
          <Card>
            <CardBody>
              <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
              <GridContainer>
                <GridItem xs={12} sm={12} md={2}>
                  API Key
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  {props.system_user.api_key}
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <IconButton>
                    <CopyToClipboard text={props.system_user.api_key}>
                      <FileCopyIcon />
                    </CopyToClipboard>
                  </IconButton>
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={2}>
                  Token
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  {props.system_user.token !== undefined ? props.system_user.token.substring(0, 78) + "....." : ""}
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <IconButton>
                    <CopyToClipboard text={props.system_user.token}>
                      <FileCopyIcon />
                    </CopyToClipboard>
                  </IconButton>
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={2}>
                  User URL
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  POST /bots/users
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <IconButton>
                    <CopyToClipboard text="/bots/users">
                      <FileCopyIcon />
                    </CopyToClipboard>
                  </IconButton>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={2}>
                  Example
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <code>{"curl --header \"Content-Type: application/json\" -H \"Token:eyJhbGciOiJIUzI1NiIsInR5cCI6IkvLVf-nBp1u9SA7jNDtv2s\" --data '{\"email\":\"test@gmail.com\", \"name\":\"Tim Clancey\"}' --request POST 'http://localhost:8180/bots/users'"}</code>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                </GridItem>
              </GridContainer>
              <br />
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={2}>
                  Department URL
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  POST /bots/departments
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <IconButton>
                    <CopyToClipboard text="/bots/departments">
                      <FileCopyIcon />
                    </CopyToClipboard>
                  </IconButton>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={2}>
                  Example
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <code>{"curl --header \"Content-Type: application/json\" -H \"Token:eyJhbGciOiJIUzI1NiIsInR5cCI6IkvLVf-nBp1u9SA7jNDtv2s\" --data '{\"name\":\"Department of Science\", \"phone\":\"0096612312345\"}' --request POST 'http://localhost:8180/bots/departments'"}</code>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                </GridItem>
              </GridContainer>
              <br />
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={2}>
                  Custom URL
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  POST /bots/customs
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <IconButton>
                    <CopyToClipboard text="/bots/customs">
                      <FileCopyIcon />
                    </CopyToClipboard>
                  </IconButton>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={2}>
                  Example
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <code>{"curl --header \"Content-Type: application/json\" -H \"Token:eyJhbGciOiJIUzI1NiIsInR5cCI6IkvLVf-nBp1u9SA7jNDtv2s\" --data '{\"name\":\"List 1\", \"value\":\"123\"}' --request POST 'http://localhost:8180/bots/customs'"}</code>
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                </GridItem>
              </GridContainer>
              <br />
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={2}>
                  Item URL
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  POST /bots/items
                </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                  <IconButton>
                    <CopyToClipboard text="/bots/items">
                      <FileCopyIcon />
                    </CopyToClipboard>
                  </IconButton>
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={2}>
                  Example
                </GridItem>
                <GridItem xs={12} sm={12} md={8}>
                  <code>{"curl -X POST http://localhost:8185/v1/bots/items    -H 'Content-Type: application/json'    -H 'ApiKey: D74F2E1B9568DC57C64EA1B97ED44A'    -H 'Token: eyJhbGci.eyJuZXR3b3JrX2lkIjoxNSwi.5IwEs-y8XRal8QEkYG2tgtpwb-70OosjK4eHxY'   --data '[{\"item_sku\":\"testitemsku\", \"name\":\"testitemname\", \"related_item_sku\":\"\",\"relation\":\"0\", \"status\":\"1\",\"custom\":[{\"name\":\"LicenseName\", \"value\":\"testitemname\", \"status\":\"1\"}] }]'"}</code> </GridItem>
                <GridItem xs={12} sm={12} md={2}>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
      <br />
    </div >
  );
}

export default withStyles(extendedFormsStyle)(InboundWebhookComp)
