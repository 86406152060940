export const GET_ITEM_NETWORKADMIN_REQUEST = 'GET_ITEM_NETWORKADMIN_REQUEST';
export const GET_ITEM_NETWORKADMIN_SUCCESS = 'GET_ITEM_NETWORKADMIN_SUCCESS';
export const GET_ITEM_NETWORKADMIN_FAILURE = 'GET_ITEM_NETWORKADMIN_FAILURE';
export const GET_ITEM_CSV_NETWORKADMIN_REQUEST = 'GET_ITEM_CSV_NETWORKADMIN_REQUEST';
export const GET_ITEM_CSV_NETWORKADMIN_SUCCESS = 'GET_ITEM_CSV_NETWORKADMIN_SUCCESS';
export const GET_ITEM_CSV_NETWORKADMIN_FAILURE = 'GET_ITEM_CSV_NETWORKADMIN_FAILURE';
export const POST_ITEM_NETWORKADMIN_REQUEST = 'POST_ITEM_NETWORKADMIN_REQUEST';
export const POST_ITEM_NETWORKADMIN_SUCCESS = 'POST_ITEM_NETWORKADMIN_SUCCESS';
export const POST_ITEM_NETWORKADMIN_FAILURE = 'POST_ITEM_NETWORKADMIN_FAILURE';
export const PATCH_ITEM_NETWORKADMIN_REQUEST = 'PATCH_ITEM_NETWORKADMIN_REQUEST';
export const PATCH_ITEM_NETWORKADMIN_SUCCESS = 'PATCH_ITEM_NETWORKADMIN_SUCCESS';
export const PATCH_ITEM_NETWORKADMIN_FAILURE = 'PATCH_ITEM_NETWORKADMIN_FAILURE';
export const DELETE_ITEM_SELECT_NETWORKADMIN_REQUEST = 'DELETE_ITEM_SELECT_NETWORKADMIN_REQUEST';
export const DELETE_ITEM_SELECT_NETWORKADMIN_SUCCESS = 'DELETE_ITEM_SELECT_NETWORKADMIN_SUCCESS';
export const DELETE_ITEM_SELECT_NETWORKADMIN_FAILURE = 'DELETE_ITEM_SELECT_NETWORKADMIN_FAILURE';
export const GET_ITEM_INTG_NETWORKADMIN_REQUEST = 'GET_ITEM_INTG_NETWORKADMIN_REQUEST';
export const GET_ITEM_INTG_NETWORKADMIN_SUCCESS = 'GET_ITEM_INTG_NETWORKADMIN_SUCCESS';
export const GET_ITEM_INTG_NETWORKADMIN_FAILURE = 'GET_ITEM_INTG_NETWORKADMIN_FAILURE';
export const PATCH_ITEM_INTG_NETWORKADMIN_REQUEST = 'PATCH_ITEM_INTG_NETWORKADMIN_REQUEST';
export const PATCH_ITEM_INTG_NETWORKADMIN_SUCCESS = 'PATCH_ITEM_INTG_NETWORKADMIN_SUCCESS';
export const PATCH_ITEM_INTG_NETWORKADMIN_FAILURE = 'PATCH_ITEM_INTG_NETWORKADMIN_FAILURE';