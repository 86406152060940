import API from 'Api/api.js';
import { commonBackendCall, getAdminConfig } from 'Api/common.js';
import {
  GET_TASKDEPENDENCY_REQUEST,
  GET_TASKDEPENDENCY_SUCCESS,
  GET_TASKDEPENDENCY_FAILURE,
  POST_TASKDEPENDENCY_REQUEST,
  POST_TASKDEPENDENCY_SUCCESS,
  POST_TASKDEPENDENCY_FAILURE,
  PATCH_TASKDEPENDENCY_REQUEST,
  PATCH_TASKDEPENDENCY_SUCCESS,
  PATCH_TASKDEPENDENCY_FAILURE,
  DELETE_TASKDEPENDENCY_REQUEST,
  DELETE_TASKDEPENDENCY_SUCCESS,
  DELETE_TASKDEPENDENCY_FAILURE,
} from 'constants/admin/TaskDependency';

export function getTaskDependencies() {
  return commonBackendCall(
    GET_TASKDEPENDENCY_REQUEST,
    GET_TASKDEPENDENCY_SUCCESS,
    GET_TASKDEPENDENCY_FAILURE,
    API.get('admin/task-dependency', getAdminConfig()),
  );
}
export function createTaskDependency(obj) {
  return commonBackendCall(
    POST_TASKDEPENDENCY_REQUEST,
    POST_TASKDEPENDENCY_SUCCESS,
    POST_TASKDEPENDENCY_FAILURE,
    API.post('admin/task-dependency', obj, getAdminConfig()),
  );
}
export function updateTaskDependency(taskDependencyID, obj) {
  return commonBackendCall(
    PATCH_TASKDEPENDENCY_REQUEST,
    PATCH_TASKDEPENDENCY_SUCCESS,
    PATCH_TASKDEPENDENCY_FAILURE,
    API.patch(`admin/task-dependency/${taskDependencyID}`, obj, getAdminConfig()),
  );
}
export function deleteTaskDependency(taskDependencyID) {
  return commonBackendCall(
    DELETE_TASKDEPENDENCY_REQUEST,
    DELETE_TASKDEPENDENCY_SUCCESS,
    DELETE_TASKDEPENDENCY_FAILURE,
    API.delete(`admin/task-dependency/${taskDependencyID}`, getAdminConfig()),
  );
}
