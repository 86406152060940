/* eslint-disable no-redeclare */
/* eslint-disable react/no-danger */
import React, { useState, useEffect, useReducer } from 'react';
import { Link } from 'react-router-dom';
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';
import TextField from '@material-ui/core/TextField';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import SuccessSnackbar from 'components/Snackbar/SuccessSnackbar.jsx';
import ErrorSnackbar from 'components/Snackbar/ErrorSnackbar.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import MenuItem from '@mui/material/MenuItem';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import MessageIcon from '@mui/icons-material/Message';
import TimelineIcon from '@mui/icons-material/Timeline';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import RowingIcon from '@mui/icons-material/Rowing';
import HelpIcon from '@mui/icons-material/HelpOutline';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsOffIcon from '@mui/icons-material/NotificationsOff';
import CloseIcon from '@mui/icons-material/Close';
import CompletedIcon from '@mui/icons-material/FiberManualRecord';
import ErrorIcon from '@mui/icons-material/Error';
import CheckCompletedIcon from '@mui/icons-material/CheckCircle';
import DoneIcon from '@mui/icons-material/Done';
import Fullscreen from '@mui/icons-material/Fullscreen';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import FullscreenExit from '@mui/icons-material/FullscreenExit';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Box from '@material-ui/core/Box';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import SaveIcon from '@mui/icons-material/Save';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FormHelperText from '@material-ui/core/FormHelperText';
import { Accordion } from '@material-ui/core';
import { AccordionSummary } from '@material-ui/core';
import { AccordionDetails } from '@material-ui/core';
import { AccordionActions } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Committee from './Committee';
import Item from './Item';
import Item2 from './Item2';
import FileSharing from './FileSharing';
import WeekSelect from './WeekSelect';
import FormSave from './FormSave';
import AppMessagesComp from './AppMessagesComp';
import ItemDatatable from './ItemDatatableComp';
import ItemTransacitons from './ItemTranscationsComp';
import ItemOptionDetail from './ItemOptionDetailComp';
import AppActiveTask from './AppActiveTask';
import AppTracking from './AppTracking';
import AppHelp from './AppHelp';
import AppSelfRemind from './AppSelfRemind';
import AppRoles from './AppRoles';
import withRoot from 'withRoot';
import { SaveFormToAPI } from 'Api/common.js';

import {
  TEXT_FIELD,
  TEXT_AREA,
  DROP_DOWN,
  RADIO_BUTTON,
  CHECK_BOX,
  DATE,
  TIME,
  HELPTEXT,
  COMMITTEE,
  FILE_SHARING,
  AGGREGATE_NUM,
  WEEK_CALENDAR,
  ITEM_SEARCH,
  ITEM_SEARCH2,
  ITEM_DATATABLE,
  ITEM_TRANSACTIONS,
  ITEM_OPT_DETAILS,
  SYSTEM_DATA,
  TRANS_TEXT_FIELD,
} from 'constants/FieldTypes';
import FormPrint from './FormPrint';

const ApplicationDetailComp = (props) => {
  const [fullscreen, setFullscreen] = useState(false);
  const [taskID, setTaskID] = useState(0);
  const [formCategoryArray, setFormCategoryArray] = useState([]);
  const [formArray, setFormArray] = useState([]);
  const [formIDs, setFormIDs] = useState([]);
  const [fieldArray, setFieldArray] = useState([]);
  const [fieldCheckboxArray, setFieldCheckboxArray] = useState([]);
  const [fieldIDsError, setFieldIDsError] = useState([]);
  const [fieldIDInRules, setFieldIDInRules] = useState([]);
  const [actionMsgIDs, setActionMsgIDs] = useState([]);
  const [actions, setActions] = useState([]);
  const [currentForm, setCurrentForm] = useState(0);
  const [displayActionForm, setDisplayActionForm] = useState(false);
  const [displayActionMessages, setDisplayActionMessages] = useState(true);
  const [displayAppActiveTask, setDisplayAppActiveTask] = useState(false);
  const [displayAppTracking, setDisplayAppTracking] = useState(false);
  const [displayAppRoles, setDisplayAppRoles] = useState(false);
  const [displayAppHelp, setDisplayAppHelp] = useState(false);
  const [displaySelfRemind, setDisplaySelfRemind] = useState(false);
  const [fields, setFields] = useState([]);
  const [submitableFormList, setSubmitableFormList] = useState([]);
  const [allFormsCompleted, setAllFormsCompleted] = useState(false);
  const [currentExpansionPanel, setCurrentExpansionPanel] = useState('');
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);
  const [activeStep, setActiveStep] = React.useState(2);
  const [firstForm, setFirstForm] = useState("");
  const { t } = useTranslation();

  const { getApplicationDetailForm, getApplication, getRole, getApplicationDetailCompletedTask } = props;
  useEffect(() => {
    if (props.task_id === undefined) {
      getApplicationDetailForm(props.application_id, props.role_id);
    } else {
      getApplicationDetailCompletedTask(props.application_id, props.role_id, props.task_id);
    }
    getApplication(props.application_id);
    getRole(props.role_id);
  }, [getApplicationDetailForm, getApplication, getRole, getApplicationDetailCompletedTask]);

  const hideForm = (nodeID) => {
    if (nodeID !== 0) {
      var tempFormIDs = formIDs;
      tempFormIDs.forEach((element) => {
        element.display = false;
      });
      var objIndex = tempFormIDs.findIndex((x) => x.id === parseInt(nodeID, 10));
      if (tempFormIDs[objIndex] !== undefined) {
        tempFormIDs[objIndex].display = true;
      }
      setFormIDs(tempFormIDs);
      setDisplayActionForm(false);
      setDisplayActionMessages(false);
      setDisplayAppActiveTask(false);
      setDisplayAppTracking(false);
      setDisplayAppRoles(false);
      setDisplayAppHelp(false);
      if (nodeID === "selfReminder") {
        setDisplaySelfRemind(true);
      } else {
        setDisplaySelfRemind(false);
      }
    }
  };

  const hideAllForm = () => {
    var tempFormIDs = formIDs;
    tempFormIDs.forEach((element) => {
      element.display = false;
    });
    setFormIDs(tempFormIDs);
  };
  const handleActionClick = () => {
    setCurrentForm('');
    hideAllForm();
    setDisplayActionForm(true);
    setDisplayActionMessages(false);
    setDisplayAppActiveTask(false);
    setDisplayAppTracking(false);
    setDisplayAppRoles(false);
    setDisplayAppHelp(false);
    setDisplaySelfRemind(false);
  };
  useEffect(() => {
    if (props.refreshApplicationDetailForm) {
      props.getApplicationDetailForm(props.application_id, props.role_id);
    }
    var tempFormCategoryArray = [];
    var tempFormIDArray = [];
    var tempActionMsgIDArray = [];
    var tempAllFormsCompleted = true;
    var tempFields = [];
    var tempSubmitableFormList = [];

    if (props.application_detail_form.field_id_used_in_rules !== undefined) {
      setFieldIDInRules(props.application_detail_form.field_id_used_in_rules)
    }

    if (props.application_detail_form.forms !== undefined) {
      props.application_detail_form.forms.forEach((form, index) => {
        if (!props.application.application_closed) {
          if (form.checkable) {
            if (/*localStorage.getItem("role_id") === props.role_id &&*/ index === 0) {
              setFirstForm(form.id)
            }
            tempSubmitableFormList.push({
              id: form.id, title: form.alias === "" ? form.title : form.alias, form_completed: form.completed, is_submit: false,
            });
            if (!form.completed) {
              tempAllFormsCompleted = false;
            }
          }
        }

        tempFormCategoryArray.push(form.form_category_alias === "" ? form.form_category_title : form.form_category_alias);
        tempFormIDArray.push({ id: form.id, display: false });
        form.fields.forEach((field) => {
          var isError = false;
          var value = '';
          var fieldValues = [];
          if (field.value !== '') {
            fieldValues = JSON.parse(field.value);
            if (fieldValues.length > 0) {
              value = fieldValues[0];
            }
          }
          if (field.required && value === '') {
            isError = true;
          }
          if (field.field_error_message !== '') {
            isError = true;
          }

          var fieldLabel = field.label
          if (field.alias !== "") {
            fieldLabel = field.alias
          }

          /*
          var tempFieldsInRule = fieldIDInRules
          if (field.required_rule !== "") {
            var requiredRules = JSON.parse(field.required_rule);
            requiredRules.forEach((requiredRule, index) => {
              if (tempFieldsInRule.indexOf(requiredRule.field_id) === -1) {
                tempFieldsInRule.push(requiredRule.field_id);
              }
            })
          }
          if (field.visible_rule !== "") {
            var visibleRules = JSON.parse(field.visible_rule);
            visibleRules.forEach((visibleRule, index) => {
              if (tempFieldsInRule.indexOf(visibleRule.field_id) === -1) {
                tempFieldsInRule.push(visibleRule.field_id);
              }
            })
          }
          */
          tempFields.push({
            id: field.id,
            formId: form.id,
            type: field.type,
            valueArray: fieldValues,
            value,
            label: fieldLabel,
            help_text: field.help_text,
            required: field.required,
            error: isError,
            field_error_message: field.field_error_message,
            is_read_only: field.is_read_only,
          });
        });
      });
      tempFormCategoryArray = Array.from(new Set(tempFormCategoryArray));
      setSubmitableFormList(tempSubmitableFormList);
      setTaskID(props.application_detail_form.id);
      setFormCategoryArray(tempFormCategoryArray);
      setFormArray(props.application_detail_form.forms);
      setFormIDs(tempFormIDArray);
      setFields(tempFields);
      setAllFormsCompleted(tempAllFormsCompleted);
      // setInterval(hideForm(currentForm), 50);
    }
    if (props.application_detail_form.actions !== undefined && props.application_detail_form.actions !== null) {
      props.application_detail_form.actions.forEach((action, index) => {
        if (index === 0) {
          setActiveStep(tempSubmitableFormList.length);
          tempSubmitableFormList.push({
            id: 0, title: t('app_detail_stepper_submit'), form_completed: false, is_submit: true,
          });
        }
        action.action_messages.forEach((actionMessage) => {
          tempActionMsgIDArray.push({
            id: actionMessage.id,
            error: false,
            to_email: actionMessage.next_task_name + " <" + actionMessage.next_task_email + ">",
            cc_email: actionMessage.cc_email,
            subject: action.title,
            body: actionMessage.message,
            next_task_title: actionMessage.next_task_title_alias === '' ? actionMessage.next_task_title : actionMessage.next_task_title_alias,
            next_task_sms: actionMessage.next_task_sms,
            sms_message: actionMessage.sms_message,
            remind_message: actionMessage.reminder_message,
            remind_date: "",
          });
        });
      });
      setActions(props.application_detail_form.actions);
      setActionMsgIDs(tempActionMsgIDArray);
    }
    /*
    console.log('+++++++++++++++********************++++++++1');
    console.log('showForm', props.showForm);
    console.log('FORM', props.application_detail_form);
    console.log('taskID', taskID);
    console.log('formCategoryArray', formCategoryArray);
    console.log('formArray', formArray);
    console.log('formIDs', formIDs);
    console.log('actionMsgIDs', actionMsgIDs);
    console.log('actions', actions);
    console.log('currentForm', currentForm);
    console.log('feilds', fields);
    console.log('SubmitableFormList', submitableFormList);
    console.log('+++++++++++++++********************++++++++2');
    */

    /*
            if (props.savedFormID !== "") {
                var objIndex = submitableFormList.findIndex(obj => obj.id === props.savedFormID);
                if ((submitableFormList[objIndex + 1]) !== undefined) {
                    if ((submitableFormList[objIndex + 1]).is_submit) {
                        handleActionClick()
                    } else {
                        hideForm(submitableFormList[objIndex + 1].id)
                        setCurrentForm(submitableFormList[objIndex + 1].id)
                    }
                }
            }
            if (props.showPendingApproval && props.savedFormID === "") {
                if (submitableFormList.length > 0) {
                    hideForm(submitableFormList[0].id)
                    setCurrentForm(submitableFormList[0].id)
                }
            }
            */
    if (props.showForm.type == 'next') {
      var objIndex = submitableFormList.findIndex((obj) => obj.id === props.showForm.form_id);
      if ((submitableFormList[objIndex + 1]) !== undefined) {
        if ((submitableFormList[objIndex + 1]).is_submit) {
          handleActionClick();
        } else {
          hideForm(submitableFormList[objIndex + 1].id);
          setCurrentForm(submitableFormList[objIndex + 1].id);
        }
      }
    }
    if (props.showForm.type == 'previous') {
      var objIndex = submitableFormList.findIndex((obj) => obj.id === props.showForm.form_id);
      if ((submitableFormList[objIndex - 1]) !== undefined) {
        if ((submitableFormList[objIndex - 1]).is_submit) {
          handleActionClick();
        } else {
          hideForm(submitableFormList[objIndex - 1].id);
          setCurrentForm(submitableFormList[objIndex - 1].id);
        }
      }
    }
    if (props.showForm.type == 'save') {
      hideForm(props.showForm.form_id);
      setCurrentForm(props.showForm.form_id);
    }
    if (props.showForm.type == 'required_form') {
      if (submitableFormList.length > 0) {
        hideForm(submitableFormList[0].id);
        setCurrentForm(submitableFormList[0].id);
      }
    }
    if (props.showForm.type == 'pending_approval') {
      var unCompleteForm = 0;
      if (submitableFormList.length > 0) {
        // eslint-disable-next-line no-restricted-syntax
        for (const element of submitableFormList) {
          if (element.form_completed === false) {
            unCompleteForm = element.id;
            break;
          }
        }
      }
      if (unCompleteForm === 0) {
        hideForm(submitableFormList[0].id);
        setCurrentForm(submitableFormList[0].id);
      } else {
        hideForm(unCompleteForm);
        setCurrentForm(unCompleteForm);
      }
    }
    if (props.showForm.type == '') {
      if (firstForm !== "") {
        hideForm(firstForm);
      } else {
        hideForm(currentForm);
      }
    }
  }, [props]);

  const formatStringToCamelCase = (str) => {
    const splitted = str.split('-');
    if (splitted.length === 1) return splitted[0];
    return (
      splitted[0]
      + splitted
        .slice(1)
        .map((word) => word[0].toUpperCase() + word.slice(1))
        .join('')
    );
  };

  const getStyleObjectFromString = (str) => {
    const style = {};
    str.split(';').forEach((el) => {
      const [property, value] = el.split(':');
      if (!property) return;

      const formattedProperty = formatStringToCamelCase(property.trim());
      style[formattedProperty] = value.trim();
    });

    return style;
  };

  const handleConfirmAction = (e) => {
    if (!props.requesting) {
      var actionObj = actions.find((o) => o.id === e);
      var tempActionMsgIDArray = [];
      var actionMsgList = [];
      actionObj.action_messages.forEach((actionMessage) => {
        var actionMessageMessageID = `actionMessageMessage${actionMessage.id}`;
        var x = document.getElementById(actionMessageMessageID);
        if (x.value === '') {
          tempActionMsgIDArray.push(actionMessage.id);
        }
        var actionMessageSMSMessageID = `actionMessageSMSMessage${actionMessage.id}`;
        var y = document.getElementById(actionMessageSMSMessageID);
        if (actionMsgIDs.find((o) => o.id === actionMessage.id).next_task_sms !== '') {
          if (y.value === '') {
            tempActionMsgIDArray.push(actionMessage.id);
          }
        }
        var actionMessageRemindDateID = `actionMessageRemindDate${actionMessage.id}`;
        var z = document.getElementById(actionMessageRemindDateID);
        if (actionMsgIDs.find((o) => o.id === actionMessage.id).remind_date !== '') {
          if (z.value === '') {
            tempActionMsgIDArray.push(actionMessage.id);
          }
        }
        actionMsgList.push({
          id: actionMessage.id,
          email: actionMessage.next_task_email,
          cc_email: actionMessage.cc_email,
          sms: actionMessage.next_task_sms,
          whatsup: actionMessage.next_task_whatsup,
          user_id: actionMessage.next_task_user_id,
          next_task_id: actionMessage.next_task_id,
          next_task_name: actionMessage.next_task_name,
          message: x.value,
          sms_message: y.value,
          reminder_message: "",
          reminder_date: z.value,
          visibility: actionMessage.visibility,
        });
      });

      var tempActionMsgIDs = actionMsgIDs;
      tempActionMsgIDArray.forEach((actionMessage) => {
        var objIndex = tempActionMsgIDs.findIndex(((obj) => obj.id === actionMessage));
        tempActionMsgIDs[objIndex].error = true;
      });
      setActionMsgIDs(tempActionMsgIDs);
      forceUpdate();
      if (tempActionMsgIDArray.length < 1) {
        props.postApplicationDetailAction(
          props.application_id,
          taskID, actionObj.id, { action_messages: actionMsgList },
        );
      }
    }
  };
  const onCheckboxChange = (fieldID, e) => {
    var tempFields = fields;

    var tempFormID = "";
    var foundIndex = tempFields.findIndex((x) => x.id === fieldID);
    tempFields[foundIndex].value = e.target.value;
    tempFields[foundIndex].error = false;
    tempFormID = tempFields[foundIndex].formId

    var foundIndex = tempFields.findIndex((x) => x.id === fieldID);
    var valueArray = tempFields[foundIndex].valueArray;
    var foundValueIndex = valueArray.findIndex((x) => x === e.target.value);
    if (foundValueIndex === -1 && e.target.checked) {
      valueArray.push(e.target.value);
    }
    if (foundValueIndex !== -1 && !e.target.checked) {
      valueArray = valueArray.filter((item) => item !== e.target.value);
    }
    tempFields[foundIndex].error = false;
    tempFields[foundIndex].valueArray = valueArray;
    if (valueArray.length < 1 && tempFields[foundIndex].required) {
      tempFields[foundIndex].error = true;
    }
    setFields(tempFields);
    if (fieldIDInRules.indexOf(fieldID.toString()) > -1) {
      SaveFormToAPI(tempFormID, props.requesting, tempFields, [], [], "", "", props.postApplicationDetail, props.application_id, taskID)
    }

    forceUpdate();
  };
  const doNothing = (e) => { };

  const onFieldLostFocus = (fieldID, e) => {
    var tempFields = fields;
    var tempFormID = "";
    var foundIndex = tempFields.findIndex((x) => x.id === fieldID);
    tempFields[foundIndex].value = e.target.value;
    tempFields[foundIndex].error = false;
    tempFormID = tempFields[foundIndex].formId
    if (tempFields[foundIndex].required && e.target.value === '') {
      tempFields[foundIndex].error = true;
    }
    var formCheckable = formArray.find((o) => o.id === tempFormID).checkable
    if (formCheckable && !tempFields[foundIndex].is_read_only) {
      setFields(tempFields);
      SaveFormToAPI(tempFormID, props.requesting, tempFields, [], [], "", "", props.postApplicationDetail, props.application_id, taskID)
      forceUpdate();
    }
  };
  const onFieldValueChange = (fieldID, e) => {
    var tempFields = fields;
    var tempFormID = "";
    var foundIndex = tempFields.findIndex((x) => x.id === fieldID);
    tempFields[foundIndex].value = e.target.value;
    tempFields[foundIndex].error = false;
    tempFormID = tempFields[foundIndex].formId
    if (tempFields[foundIndex].required && e.target.value === '') {
      tempFields[foundIndex].error = true;
    }
    setFields(tempFields);

    if (fieldIDInRules.indexOf(fieldID.toString()) > -1) {
      SaveFormToAPI(tempFormID, props.requesting, tempFields, [], [], "", "", props.postApplicationDetail, props.application_id, taskID)
    }

    forceUpdate();
  };

  const onActionMessageChange = (actionMessageID, e) => {
    var tempActionMsgIDs = actionMsgIDs;
    var objIndex = tempActionMsgIDs.findIndex(((obj) => obj.id === actionMessageID));
    tempActionMsgIDs[objIndex].body = e.target.value;
    tempActionMsgIDs[objIndex].error = false;
    if (e.target.value === '') {
      tempActionMsgIDs[objIndex].error = true;
    }
    setActionMsgIDs(tempActionMsgIDs);
    forceUpdate();
  };

  const onActionMessageSMSChange = (actionMessageID, e) => {
    var tempActionMsgIDs = actionMsgIDs;
    var objIndex = tempActionMsgIDs.findIndex(((obj) => obj.id === actionMessageID));
    tempActionMsgIDs[objIndex].sms_message = e.target.value;
    tempActionMsgIDs[objIndex].error = false;
    if (e.target.value === '') {
      tempActionMsgIDs[objIndex].error = true;
    }
    setActionMsgIDs(tempActionMsgIDs);
    forceUpdate();
  };

  const onActionMessageRemindDateChange = (actionMessageID, e) => {
    var tempActionMsgIDs = actionMsgIDs;
    var objIndex = tempActionMsgIDs.findIndex(((obj) => obj.id === actionMessageID));
    tempActionMsgIDs[objIndex].remind_date = e.target.value;
    setActionMsgIDs(tempActionMsgIDs);
    forceUpdate();
  };

  const isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };
  const isArray = (what) => Object.prototype.toString.call(what) === '[object Array]';
  const handleTreeNodeSelect = (event, nodeID) => {
    // eslint-disable-next-line no-restricted-globals
    if (!isNaN(nodeID)) {
      hideForm(nodeID);
      setCurrentForm(nodeID);
      props.setShowForm('save', nodeID);
    }
  };

  const handleActionMessagesClick = () => {
    hideAllForm();
    setCurrentForm('');
    setDisplayActionForm(false);
    setDisplayActionMessages(true);
    setDisplayAppActiveTask(false);
    setDisplayAppTracking(false);
    setDisplayAppRoles(false);
    setDisplayAppHelp(false);
    setDisplaySelfRemind(false);
  };
  const handleAppActiveTaskClick = () => {
    hideAllForm();
    setCurrentForm('');
    setDisplayActionForm(false);
    setDisplayActionMessages(false);
    setDisplayAppActiveTask(true);
    setDisplayAppTracking(false);
    setDisplayAppRoles(false);
    setDisplayAppHelp(false);
    setDisplaySelfRemind(false);
  };

  const handleAppTrackingClick = () => {
    hideAllForm();
    setCurrentForm('');
    setDisplayActionForm(false);
    setDisplayActionMessages(false);
    setDisplayAppActiveTask(false);
    setDisplayAppTracking(true);
    setDisplayAppRoles(false);
    setDisplayAppHelp(false);
    setDisplaySelfRemind(false);
  };
  const handleAppRolesClick = () => {
    hideAllForm();
    setCurrentForm('');
    setDisplayActionForm(false);
    setDisplayActionMessages(false);
    setDisplayAppActiveTask(false);
    setDisplayAppTracking(false);
    setDisplayAppRoles(true);
    setDisplayAppHelp(false);
    setDisplaySelfRemind(false);
  };
  const handleAppHelpClick = () => {
    hideAllForm();
    setCurrentForm('');
    setDisplayActionForm(false);
    setDisplayActionMessages(false);
    setDisplayAppActiveTask(false);
    setDisplayAppTracking(false);
    setDisplayAppRoles(false);
    setDisplayAppHelp(true);
    setDisplaySelfRemind(false);
  };
  const onSelfReminderClick = () => {
    //props.setShowForm('', 0);
    hideAllForm();
    setCurrentForm('selfReminder');
    setDisplayActionForm(false);
    setDisplayActionMessages(false);
    setDisplayAppActiveTask(false);
    setDisplayAppTracking(false);
    setDisplayAppRoles(false);
    setDisplayAppHelp(false);
    setDisplaySelfRemind(true);

  };
  const onStatusPageViewReminderClick = (taskID, e) => {
    window.location.href = `/status/${props.application_id}/${props.role_id}`
  };
  const handleListItemClick = (event, index) => {
    props.setShowForm('save', index);
    hideForm(index);
    setCurrentForm(index);
  };
  const handleFullScreen = () => {
    setFullscreen(!fullscreen);
  };
  const handleExpansionPanelChange = (panel) => (event, newExpanded) => {
    /// setExpanded(newExpanded ? panel : false);
    setCurrentExpansionPanel(newExpanded ? panel : false);
  };
  const onBackStatusClick = (e) => {
    window.location.href = `/status/${props.application_id}/${props.role_id}`
  };
  const treeCatItems = [];
  const formItems = [];
  const useTreeItemStyles = makeStyles((theme) => ({
    group: {
      marginLeft: 2,
    },
    iconContainer: {
      width: '0px',
      display: 'flex',
      flexShrink: 0,
      marginRight: '0px',
      justifyContent: 'center',
    },
  }));
  const classes = useTreeItemStyles();
  formCategoryArray.forEach((element, i) => {
    const treeItems = [];
    formArray.forEach((form) => {
      if ((form.form_category_alias === "" ? form.form_category_title : form.form_category_alias) === element) {
        var objIndex = submitableFormList.findIndex((obj) => obj.id === form.id);
        if (objIndex === -1 || props.application_detail_form.task_completed) {
          treeItems.push(
            <div key={form.id}>
              <TreeItem
                icon={<CompletedIcon />}
                key={`${form.id}`}
                nodeId={`${form.id}`}
                label={(
                  <div style={{ display: 'flex' }}>
                    <Typography style={{ fontSize: '14px' }} variant="body1" color="inherit">{form.alias === "" ? form.title : form.alias}</Typography>
                  </div>
                )}
              />
              <br />
            </div>,
          );
        } else {
          treeItems.push(
            <div key={form.id}>
              <TreeItem
                key={`${form.id}`}
                icon={form.completed ? <CheckCompletedIcon style={{ color: 'green' }} /> : <ErrorIcon style={{ color: 'purple' }} />}
                nodeId={`${form.id}`}
                label={(
                  <div style={{ display: 'flex' }}>
                    <Typography style={{ color: form.completed ? 'green' : 'purple', fontSize: '14px' }} variant="body1" color="inherit">{form.alias === "" ? form.title : form.alias}</Typography>
                  </div>
                )}
              />
              <br />
            </div>,
          );
        }
      }
    });
    treeCatItems.push(
      <div key={element + i} >
        <TreeItem
          classes={{ group: classes.group, iconContainer: classes.iconContainer }}
          key={element + i}
          nodeId={element}
          label={(
            <div style={{ display: 'flex', paddingLeft: '1px' }}>
              <Typography style={{ fontSize: '16px', fontWeight: 'bold' }} variant="body1" color="inherit">{element}</Typography>
            </div>
          )}
        >
          <br />
          {treeItems}
        </TreeItem>
      </div>,
    );
  });
  formArray.forEach((form) => {
    const fieldItems = [];
    form.fields.forEach((field) => {
      if (field.visible_rule !== "") {
        var visibleRule = JSON.parse(field.visible_rule);
        if (visibleRule.length > 0) {
          if (!field.visible_rule_cond_met) {
            return;
          }
        }
      }
      var fieldUI = [];

      if (field.type === TEXT_FIELD) {
        var isDisabled = false;
        if (field.is_read_only || !form.checkable || props.application_detail_form.task_completed) {
          isDisabled = true;
        }
        fieldUI.push(
          <TextField
            required={field.required === 'true'}
            error={(fields.find((o) => o.id === field.id).error) && isDisabled === false}
            value={fields.find((o) => o.id === field.id).value}
            key={"textfield" + field.id}
            id={`${field.id}`}
            fullWidth
            label={fields.find((o) => o.id === field.id).label}
            onChange={onFieldValueChange.bind(this, field.id)}
            //onBlur={isDisabled ? doNothing(this) : onFieldLostFocus.bind(this, field.id)}
            // disabled={isDisabled}
            InputProps={{ readOnly: isDisabled, backgroundcolor: 'red' }}
            variant={isDisabled ? "filled" : "standard"}
            helperText={fields.find((o) => o.id === field.id).field_error_message}
          />,
        );
      } else if (field.type === TRANS_TEXT_FIELD) {
        var isDisabled = false;
        if (field.is_read_only || !form.checkable || props.application_detail_form.task_completed) {
          isDisabled = true;
        }
        var typeText = "text"
        if (field.field_data_type == "DATE_STRING") {
          typeText = "date"
        }
        fieldUI.push(
          <TextField
            required={field.required === 'true'}
            error={(fields.find((o) => o.id === field.id).error) && isDisabled === false}
            value={fields.find((o) => o.id === field.id).value}
            key={"textfield" + field.id}
            id={`${field.id}`}
            type={typeText}
            fullWidth
            label={fields.find((o) => o.id === field.id).label}
            onChange={onFieldValueChange.bind(this, field.id)}
            //onBlur={isDisabled ? doNothing(this) : onFieldLostFocus.bind(this, field.id)}
            // disabled={isDisabled}
            InputProps={{ readOnly: isDisabled, backgroundcolor: 'red' }}
            variant={isDisabled ? "filled" : "standard"}
            helperText={fields.find((o) => o.id === field.id).field_error_message}
            InputLabelProps={{ shrink: true, }}
          />,
        );
      } else if (field.type === ITEM_SEARCH) {
        fieldUI.push(
          <Item
            form_checkable={form.checkable}
            field_required={field.required}
            search_table={field.search_table}
            search_level={field.item_search_type}
            field_value={fields.find((o) => o.id === field.id).valueArray}
            field_label={fields.find((o) => o.id === field.id).label}
            error={fields.find((o) => o.id === field.id).error}
            helperText={fields.find((o) => o.id === field.id).field_error_message}
            key={field.id}
            fields={fields}
            application_id={props.application_id}
            form_id={form.id}
            field_id={field.id}
            task_id={taskID}
            requesting={props.requesting}
            postApplicationDetail={props.postApplicationDetail}
            task_completed={props.application_detail_form.task_completed}
          />,

        );
      } else if (field.type === ITEM_SEARCH2) {
        var strItemTotal = ""
        var strItemJSON = ""
        if ((fields.find((o) => o.id === field.id).valueArray).length > 1) {
          strItemTotal = fields.find((o) => o.id === field.id).valueArray[0]
          strItemJSON = fields.find((o) => o.id === field.id).valueArray[1]
        }
        fieldUI.push(
          <Item2
            postItem={props.postItem}
            patchItemPercent={props.patchItemPercent}
            refreshRelatedItemSearch={props.refreshRelatedItemSearch}
            datatable_rule={field.datatable_rule}
            search_table={field.search_table}
            search_table_related={field.search_table_related}
            search_level={field.item_search_type}
            field_required={field.required}
            item_relation_level={field.item_relation_level}
            field_value={fields.find((o) => o.id === field.id).valueArray}
            field_label={fields.find((o) => o.id === field.id).label}
            error={fields.find((o) => o.id === field.id).error}
            helperText={fields.find((o) => o.id === field.id).field_error_message}
            key={field.id}
            fields={fields}
            application_id={props.application_id}
            form_id={form.id}
            field_id={field.id}
            task_id={taskID}
            requesting={props.requesting}
            postApplicationDetail={props.postApplicationDetail}
            task_completed={props.application_detail_form.task_completed}
            form_checkable={form.checkable}
            is_read_only={field.is_read_only}
            item_total={strItemTotal}
          //field_value={strItemJSON}
          />,

        );
      } else if (field.type === ITEM_DATATABLE) {
        var strItemTotal = ""
        var strItemJSON = ""
        if ((fields.find((o) => o.id === field.id).valueArray).length > 1) {
          strItemTotal = fields.find((o) => o.id === field.id).valueArray[0]
          strItemJSON = fields.find((o) => o.id === field.id).valueArray[1]
        }
        fieldUI.push(
          <ItemDatatable
            datatable_rule={field.datatable_rule}
            field_value={strItemJSON}
            fields={fields}
            application_id={props.application_id}
            item_total={strItemTotal}
            form_id={form.id}
            field_id={field.id}
            postApplicationDetail={props.postApplicationDetail}
            task_id={taskID}
            requesting={props.requesting}
          />,

        );
      } else if (field.type === ITEM_TRANSACTIONS) {
        fieldUI.push(
          <ItemTransacitons
            field_value={fields.find((o) => o.id === field.id).valueArray}
            fields={fields}
            application_id={props.application_id}
            form_id={form.id}
            field_id={field.id}
            postApplicationDetail={props.postApplicationDetail}
            task_id={taskID}
            label={fields.find((o) => o.id === field.id).label}
            requesting={props.requesting}
          />,
        );
      } else if (field.type === ITEM_OPT_DETAILS) {
        fieldUI.push(
          <ItemOptionDetail
            level={form.level}
            postUpdateItemDetail={props.postUpdateItemDetail}
            field_value={fields.find((o) => o.id === field.id).valueArray}
            fields={fields}
            application_id={props.application_id}
            form_id={form.id}
            field_id={field.id}
            postApplicationDetail={props.postApplicationDetail}
            task_id={taskID}
            label={fields.find((o) => o.id === field.id).label}
            requesting={props.requesting}
            task_completed={props.application_detail_form.task_completed}
            form_checkable={form.checkable}
            is_read_only={field.is_read_only}
          />,
        );

      } else if (field.type === SYSTEM_DATA) {
        fieldUI.push(
          <TextField
            required={false}
            error={false}
            value={fields.find((o) => o.id === field.id).value}
            key={"textfield" + field.id}
            id={`${field.id}`}
            fullWidth
            label={fields.find((o) => o.id === field.id).label}
            //onChange={onFieldValueChange.bind(this, field.id)}
            //onBlur={isDisabled ? doNothing(this) : onFieldLostFocus.bind(this, field.id)}
            disabled={true}
            InputProps={{ readOnly: isDisabled, backgroundcolor: 'red' }}
            variant={isDisabled ? "filled" : "standard"}
          //helperText={fields.find((o) => o.id === field.id).field_error_message}
          />,
        );

      } else if (field.type === WEEK_CALENDAR) {
        var isDisabled = false;
        if (field.is_read_only || (field.intg_field_id !== 0) || !form.checkable || props.application_detail_form.task_completed) {
          isDisabled = true;
        }
        fieldUI.push(
          <WeekSelect
            id={field.id}
            label={fields.find((o) => o.id === field.id).label}
            disabled={isDisabled}
            required={field.required}
            value={fields.find((o) => o.id === field.id).value}
            error={fields.find((o) => o.id === field.id).error}
            setWeekSelectField={props.setWeekSelectField}
            application_id={props.application_id}
            form_id={form.id}
            requesting={props.requesting}
            postApplicationDetail={props.postApplicationDetail}
            task_id={taskID}
            fields={fields}
          />,
        );
      } else if (field.type === AGGREGATE_NUM) {
        fieldUI.push(
          <TextField
            // required={field.required === "true" ? true : false}
            error={fields.find((o) => o.id === field.id).error}
            value={fields.find((o) => o.id === field.id).value}
            key={field.id}
            id={`${field.id}`}
            fullWidth
            label={fields.find((o) => o.id === field.id).label}
            InputProps={{ readOnly: true }}
            variant={"filled"}
            onChange={onFieldValueChange.bind(this, field.id)}
            helperText={fields.find((o) => o.id === field.id).help_text}
          />,
        );
      } else if (field.type === HELPTEXT) {
        if (field.help_text.length > 0) {
          if (field.help_text.substring(0, 1) === '<') {
            fieldUI.push(
              <div
                dangerouslySetInnerHTML={{ __html: field.help_text }}
              />,
            );
          } else {
            fieldUI.push(
              <Box
                style={{ background: '#EEEEE0' }}
                key={field.id}
              >
                <Typography variant="subtitle1" gutterBottom>
                  {field.help_text}
                </Typography>
              </Box>,
            );
          }
        }
      } else if (field.type === TIME) {
        var isDisabled = false;
        if ((field.is_read_only) || !form.checkable || props.application_detail_form.task_completed) {
          isDisabled = true;
        }
        fieldUI.push(
          <TextField
            required={field.required === 'true'}
            error={(fields.find((o) => o.id === field.id).error) && isDisabled === false}
            value={fields.find((o) => o.id === field.id).value}
            key={field.id}
            id={`${field.id}`}
            type="time"
            variant={isDisabled ? "filled" : "standard"}
            // InputLabelProps={{ shrink: true, }}
            fullWidth
            label={fields.find((o) => o.id === field.id).label}
            onChange={isDisabled ? doNothing() : onFieldValueChange.bind(this, field.id)}
            // disabled={isDisabled}
            helperText={fields.find((o) => o.id === field.id).field_error_message}
            InputLabelProps={{
              shrink: true,
            }}
          />,
        );
      } else if (field.type === DATE) {
        var isDisabled = false;
        if ((field.is_read_only) || !form.checkable || props.application_detail_form.task_completed) {
          isDisabled = true;
        }
        fieldUI.push(
          <TextField
            id={`${field.id}`}
            key={field.id}
            label={fields.find((o) => o.id === field.id).label}
            type="date"
            variant={isDisabled ? "filled" : "standard"}
            fullWidth
            error={(fields.find((o) => o.id === field.id).error) && isDisabled === false}
            value={fields.find((o) => o.id === field.id).value}
            onChange={isDisabled ? doNothing() : onFieldValueChange.bind(this, field.id)}
            // disabled={isDisabled}
            helperText={fields.find((o) => o.id === field.id).field_error_message}
            InputLabelProps={{
              shrink: true,
            }}
          />,
        );
      } else if (field.type === TEXT_AREA) {
        var isDisabled = false;
        if (field.is_read_only || !form.checkable || props.application_detail_form.task_completed) {
          isDisabled = true;
        }
        fieldUI.push(
          <TextField
            required={field.required === 'true'}
            error={(fields.find((o) => o.id === field.id).error) && isDisabled === false}
            value={fields.find((o) => o.id === field.id).value}
            key={field.id}
            id={`${field.id}`}
            multiline
            variant={isDisabled ? "filled" : "standard"}
            rows={field.rows}
            // InputLabelProps={{ shrink: true, }}
            fullWidth
            label={fields.find((o) => o.id === field.id).label}
            onChange={onFieldValueChange.bind(this, field.id)}
            // disabled={isDisabled}
            InputProps={{ readOnly: isDisabled }}
          />,
        );
      } else if (field.type === CHECK_BOX) {
        var checkboxUI = [];
        var checkboxOptionJSON = JSON.parse(field.options);
        var isDisabled = false;
        if (field.is_read_only || !form.checkable || props.application_detail_form.task_completed) {
          isDisabled = true;
        }
        checkboxOptionJSON.forEach((checkbox, index) => {
          var valueArray = fields.find((o) => o.id === field.id).valueArray;
          checkboxUI.push(
            <FormControlLabel
              key={index + checkbox.id}
              control={(
                <Checkbox
                  id={`${index}${field.id}`}
                  key={`${index}${field.id}`}
                  color="primary"
                  checked={!!valueArray.find((q) => q === checkbox.id)}
                  value={checkbox.id}
                  onChange={isDisabled ? doNothing() : onCheckboxChange.bind(this, field.id)}
                />
              )}
              label={<Typography style={{ color: "#000000", opacity: "0.8", fontSize: 14 }} >{checkbox.value}</Typography>}
              value={checkbox.id}
            />,
          );
        });
        fieldUI.push(
          <Box
            sx={{ p: 1, backgroundColor: isDisabled ? "#e8e8e8" : 'transparent' }}
          >
            <FormControl
              required={field.required === 'true' && isDisabled === false}
              error={(fields.find((o) => o.id === field.id).error) && isDisabled === false}
              key={`${field.id}`}
              id={`${field.id}`}
              component="fieldset"
            >
              <FormLabel>{fields.find((o) => o.id === field.id).label}</FormLabel>
              <FormGroup
                row={field.display_direction === "vertical" ? false : true}
                key={field.id}
              >
                {checkboxUI}
              </FormGroup>
              <FormHelperText>{fields.find((o) => o.id === field.id).field_error_message}</FormHelperText>
            </FormControl>
          </Box>
        );
      } else if (field.type === RADIO_BUTTON) {
        var radioButtonUI = [];
        var radioButtonOptionJSON = JSON.parse(field.options);
        var isDisabled = false;
        if (field.is_read_only || !form.checkable || props.application_detail_form.task_completed) {
          isDisabled = true;
        }
        radioButtonOptionJSON.forEach((radioButton, index) => {
          radioButtonUI.push(
            <FormControlLabel
              key={`formControlLabel${index}${field.id}`}
              control={(
                <Radio
                  id={`radioButton${index}${field.id}`}
                  key={`radioButton${index}${field.id}`}
                  color="primary"
                />
              )}
              label={<Typography style={{ color: "#000000", opacity: "0.8", fontSize: 14 }} >{radioButton.value}</Typography>}
              value={radioButton.id}
            //disabled={true}
            />,
          );
        });
        fieldUI.push(
          <Box
            key={`radioBox${field.id}`}
            sx={{ p: 1, backgroundColor: isDisabled ? "#e8e8e8" : 'transparent' }}
          >
            <FormControl
              required={field.required === 'true' && isDisabled === false}
              error={(fields.find((o) => o.id === field.id).error) && isDisabled === false}
              id={field.id}
              key={`radioForm${field.id}`}
              // disabled={isDisabled}
              variant={isDisabled ? "filled" : "standard"}
              component="fieldset"
            >
              <FormLabel
              >{fields.find((o) => o.id === field.id).label}</FormLabel>
              <RadioGroup
                row={field.display_direction === "vertical" ? false : true}
                aria-label="radio"
                name="radio"
                value={fields.find((o) => o.id === field.id).value}
                key={`radioGroup${field.id}`}
                onChange={isDisabled ? doNothing() : onFieldValueChange.bind(this, field.id)}
              >
                {radioButtonUI}
              </RadioGroup>
              <FormHelperText>{fields.find((o) => o.id === field.id).field_error_message}</FormHelperText>
            </FormControl>
          </Box>
        );
      } else if (field.type === DROP_DOWN) {
        var isDisabled = false;
        if (field.is_read_only || !form.checkable || props.application_detail_form.task_completed) {
          isDisabled = true;
        }
        var dropdownUI = [];
        var dropdownOptionJSON = JSON.parse(field.options);
        dropdownOptionJSON.forEach((dropdown) => {
          dropdownUI.push(
            <MenuItem key={dropdown.id} id={dropdown.id} value={dropdown.value}>{dropdown.value_display === "" ? dropdown.value : dropdown.value_display}</MenuItem>,
          );
        });
        fieldUI.push(
          <FormControl
            required={field.required === 'true'}
            error={(fields.find((o) => o.id === field.id).error) && isDisabled === false}
            key={field.id}
            fullWidth
            id={field.id}
          >
            <InputLabel id={`label${field.id}`}>{fields.find((o) => o.id === field.id).label}</InputLabel>
            <Select
              labelId={`label${field.id}`}
              id={`${field.id}`}
              value={fields.find((o) => o.id === field.id).value}
              onChange={isDisabled ? doNothing() : onFieldValueChange.bind(this, field.id)}
              autoWidth
              variant={isDisabled ? "filled" : "standard"}
            >
              {dropdownUI}
            </Select>
            <FormHelperText>{fields.find((o) => o.id === field.id).field_error_message}</FormHelperText>
          </FormControl>,
        );
      } else if (field.type === COMMITTEE) {
        fieldUI.push(
          <Committee
            form_checkable={form.checkable}
            field_required={field.required}
            field_value={fields.find((o) => o.id === field.id).valueArray}
            field_label={fields.find((o) => o.id === field.id).label}
            field_committee_id={field.committee_name_id}
            application_id={props.application_id}
            task_completed={props.application_detail_form.task_completed}
            key={field.id}
          />,

        );
      } else if (field.type === FILE_SHARING) {
        fieldUI.push(
          <FileSharing
            field_required={field.required}
            field_value={fields.find((o) => o.id === field.id).valueArray}
            field_label={fields.find((o) => o.id === field.id).label}
            field_id={field.id}
            field_role_id={field.role_id.toString()}
            role_id={props.role_id}
            application_id={props.application_id}
            share_file={props.share_file}
            key={field.id}
            fields={fields}
            form_id={form.id}
            form_checkable={formArray.find((o) => o.id === form.id).checkable}
            requesting={props.requesting}
            postApplicationDetail={props.postApplicationDetail}
            task_id={taskID}
            application_status={props.application.status}
            task_completed={props.application_detail_form.task_completed}
          />
        );
      }
      var fieldColspan = 12;
      if (field.colspan !== '') {
        fieldColspan = field.colspan;
      }
      fieldItems.push(
        <GridItem key={`${field.id}`} xs={12} sm={12} md={parseInt(fieldColspan, 10)}>
          <Box mt={2} key={`${field.id}`}>
            {fieldUI}
          </Box>
        </GridItem>,
      );
    });
    formItems.push(
      <div key={form.id} style={{ display: formIDs.find((o) => o.id === form.id).display ? 'block' : 'none' }}>
        <Card>
          <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
            <GridContainer>
              <GridItem xs={12} sm={12} md={6}>
                <Typography variant="h5">{form.alias === "" ? form.title : form.alias}</Typography>
              </GridItem>
              <GridItem xs={12} sm={12} md={6}>
                <IconButton
                  color="inherit"
                  aria-label="Full Screen"
                  onClick={handleFullScreen}
                  edge="end"
                  style={{ float: 'right' }}
                >
                  <Fullscreen style={{ display: !fullscreen ? 'block' : 'none' }} />
                  <FullscreenExit style={{ display: fullscreen ? 'block' : 'none' }} />
                </IconButton>
                <FormPrint
                  fields={fields}
                  form_print={form.form_print}
                  form_id={form.id}
                  application_id={props.application_id}
                  form_array={formArray}
                />
              </GridItem>
            </GridContainer>
          </CardHeader>
          <div style={{ display: 'block' }}>
            <CardBody>
              <GridContainer>
                {fieldItems}
              </GridContainer>
            </CardBody>
            <div style={{ textAlign: 'right', paddingTop: '10px', margin: '0 15px 10px' }}>
              <div style={{ display: props.application.status === 0 && form.checkable && !props.application_detail_form.task_completed ? 'block' : 'none' }}>
                <FormSave
                  setSavedFormID={props.setSavedFormID}
                  setShowForm={props.setShowForm}
                  form_id={form.id}
                  fields={fields}
                  task_id={taskID}
                  application_id={props.application_id}
                  requesting={props.requesting}
                  submitableFormList={submitableFormList}
                />
              </div>
            </div>
          </div>
        </Card>
      </div>,
    );
  });
  var checkList = [];
  formArray.forEach((form) => {
    if (form.checkable) {
      checkList.push(
        <ListItem button key={form.id} onClick={(event) => handleListItemClick(event, form.id)}>
          <ListItemIcon>
            {form.completed
              ? <DoneIcon color="primary" />
              : <CloseIcon color="error" />}
          </ListItemIcon>
          <ListItemText primary={form.alias === "" ? form.title : form.alias} secondary={t(getChecklistStatusDisplay(form.message))} />
        </ListItem>,
      );
    }
  });
  var actionRuleDetail = [];
  if (props.application_detail_form.info_message != "") {
    actionRuleDetail.push(
      <Accordion style={{ border: '1px solid rgba(0, 0, 0, .125)', boxShadow: 'none', }} key={"accordionRuleDetail"} >
        <AccordionSummary key={"accordionSummaryRuleDetail"} style={{ backgroundColor: 'rgba(0, 0, 0, .05)' }} expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content">
          <div> <Typography style={{ display: 'inline-block', marginRight: 16 }}>Action Rule Details</Typography> </div>
        </AccordionSummary>
        <AccordionDetails key={"accordionDetailRuleDetail"}>
          <Card style={{ width: '100%' }}>
            <CardBody>
              <div style={{ whiteSpace: 'pre-wrap' }}>
                {props.application_detail_form.info_message}
              </div>
            </CardBody>
          </Card>
        </AccordionDetails>
      </Accordion>
    )
  }

  var actionList = [];
  actions.forEach((action) => {
    var actionMessageList = [];
    action.action_messages.forEach((actionMessage) => {
      actionMessageList.push(
        <div key={actionMessage.id} style={{ display: (props.application_detail_form.role_hide_menu) ? 'none' : 'block' }}>
          <div style={{ display: (actionMessage.visibility > 1) ? 'none' : 'block' }}>
            <AccordionDetails key={`expansioneDetail${actionMessage.id}`}>
              <Card style={{ width: '100%' }}>
                <CardBody>
                  <GridContainer style={{ width: '100%' }}>
                    <GridItem key={`to${actionMessage.id}`} xs={12} sm={12} md={12}>
                      <TextField
                        disabled
                        id={`actionMessageNextTask${actionMessage.id}`}
                        key={`actionMessageNextTask${actionMessage.id}`}
                        label={t('app_detail_act_msg_next_task')}
                        variant="outlined"
                        value={actionMsgIDs.find((o) => o.id === actionMessage.id).next_task_title}
                        InputLabelProps={{ shrink: true }}
                        style={{ width: '100%' }}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer style={{ width: '100%' }}>
                    <GridItem key={`nextTask${actionMessage.id}`} xs={12} sm={12} md={12}>
                      <TextField
                        disabled
                        id={`actionMessageTo${actionMessage.id}`}
                        key={`actionMessageTo${actionMessage.id}`}
                        label={t('app_detail_act_msg_to')}
                        variant="outlined"
                        value={actionMsgIDs.find((o) => o.id === actionMessage.id).to_email}
                        InputLabelProps={{ shrink: true }}
                        style={{ width: '100%' }}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <div style={{ display: (actionMsgIDs.find((o) => o.id === actionMessage.id).cc_email !== '') ? 'block' : 'none' }}>
                    <GridContainer style={{ width: '100%' }}>
                      <GridItem key={`ccemail${actionMessage.id}`} xs={12} sm={12} md={12}>
                        <TextField
                          disabled
                          id={`actionMessageCCEmail${actionMessage.id}`}
                          key={`actionMessageCCEmail${actionMessage.id}`}
                          label={t('app_detail_act_msg_cc_email')}
                          variant="outlined"
                          value={actionMsgIDs.find((o) => o.id === actionMessage.id).cc_email}
                          InputLabelProps={{ shrink: true }}
                          style={{ width: '100%' }}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                  </div>
                  <div style={{ display: 'none' }}>
                    <GridContainer style={{ width: '100%' }}>
                      <GridItem key={`subject${actionMessage.id}`} xs={12} sm={12} md={12}>
                        <TextField
                          disabled
                          id={`actionMessageSubject${actionMessage.id}`}
                          key={`actionMessageSubject${actionMessage.id}`}
                          label={t('app_detail_act_msg_subject')}
                          variant="outlined"
                          value={actionMsgIDs.find((o) => o.id === actionMessage.id).subject}
                          InputLabelProps={{ shrink: true }}
                          style={{ width: '100%' }}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                  </div>
                  <GridContainer style={{ width: '100%' }}>
                    <GridItem key={`message${actionMessage.id}`} xs={12} sm={12} md={12}>
                      <TextField
                        disabled={actionMessage.visibility === 1 ? true : false}
                        id={`actionMessageMessage${actionMessage.id}`}
                        key={`actionMessageMessage${actionMessage.id}`}
                        label={t('app_detail_act_msg_message')}
                        multiline
                        rows="15"
                        onChange={onActionMessageChange.bind(this, actionMessage.id)}
                        InputLabelProps={{ shrink: true }}
                        variant="outlined"
                        value={actionMsgIDs.find((o) => o.id === actionMessage.id).body}
                        style={{ width: '100%' }}
                        error={actionMsgIDs.find((o) => o.id === actionMessage.id).error}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <div style={{ display: (actionMsgIDs.find((o) => o.id === actionMessage.id).next_task_sms !== '') ? 'block' : 'none' }}>
                    <GridContainer style={{ width: '100%' }}>
                      <GridItem key={`sms${actionMessage.id}`} xs={12} sm={12} md={12}>
                        <TextField
                          disabled
                          id={`actionMessageSMS${actionMessage.id}`}
                          key={`actionMessageSMS${actionMessage.id}`}
                          label={t('app_detail_act_msg_sms_to')}
                          variant="outlined"
                          value={actionMsgIDs.find((o) => o.id === actionMessage.id).next_task_sms}
                          InputLabelProps={{ shrink: true }}
                          style={{ width: '100%' }}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                    <GridContainer style={{ width: '100%' }}>
                      <GridItem key={`smsMessage${actionMessage.id}`} xs={12} sm={12} md={12}>
                        <TextField
                          id={`actionMessageSMSMessage${actionMessage.id}`}
                          key={`actionMessageSMSMessage${actionMessage.id}`}
                          label={t('app_detail_act_msg_sms_message')}
                          variant="outlined"
                          multiline
                          rows="2"
                          onChange={onActionMessageSMSChange.bind(this, actionMessage.id)}
                          value={actionMsgIDs.find((o) => o.id === actionMessage.id).sms_message}
                          InputLabelProps={{ shrink: true }}
                          style={{ width: '100%' }}
                          error={actionMsgIDs.find((o) => o.id === actionMessage.id).error}
                        />
                      </GridItem>
                    </GridContainer>
                  </div>
                  <br />
                  <div style={{ display: (actionMsgIDs.find((o) => o.id === actionMessage.id).remind_message !== '' && props.application_detail_form.role_allow_send_remind) ? 'block' : 'none' }}>
                    <GridContainer style={{ width: '100%' }}>
                      <GridItem key={`remind_date${actionMessage.id}`} xs={12} sm={12} md={3}>
                        <TextField
                          id={`actionMessageRemindDate${actionMessage.id}`}
                          key={`actionMessageRemindDate${actionMessage.id}`}
                          label={t('app_detail_act_msg_remind_date')}
                          variant="outlined"
                          type="date"
                          onChange={onActionMessageRemindDateChange.bind(this, actionMessage.id)}
                          value={actionMsgIDs.find((o) => o.id === actionMessage.id).remind_date}
                          InputLabelProps={{ shrink: true }}
                          style={{ width: '100%' }}
                          error={actionMsgIDs.find((o) => o.id === actionMessage.id).error}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                  </div>
                </CardBody>
              </Card>
            </AccordionDetails>
          </div>
        </div>,
      );
    });
    var dependentTaskCompleted = true;
    var actionDependencyList = [];
    action.action_dependency.forEach((actionDependency) => {
      if (dependentTaskCompleted) {
        dependentTaskCompleted = actionDependency.child_action_completed
      }
      actionDependencyList.push(
        <ListItem button key={actionDependency.id} >
          <ListItemIcon>
            {actionDependency.child_action_completed
              ? <DoneIcon color="primary" />
              : <CloseIcon color="error" />}
          </ListItemIcon>
          <ListItemText primary={actionDependency.child_action_task_alias === "" ? actionDependency.child_action_task_title : actionDependency.child_action_task_alias} secondary={actionDependency.child_action_completed ? "Completed" : "Not Completed"} />
          <ListItemText primary={actionDependency.child_action_alias === "" ? actionDependency.child_action_title : actionDependency.child_action_alias} secondary={"Assigned To: " + actionDependency.child_action_user_name} />
        </ListItem>,
      );
    });

    actionList.push(
      <div key={action.id} style={{ display: (props.application_detail_form.role_hide_menu) ? 'none' : 'block' }}>
        <Accordion
          style={{
            border: '1px solid rgba(0, 0, 0, .125)', boxShadow: 'none',
          }}
          key={action.id}
          expanded={currentExpansionPanel === action.id}
          onChange={handleExpansionPanelChange(action.id)}
        >
          <AccordionSummary key={`sum${action.id}`} style={{ backgroundColor: 'rgba(0, 0, 0, .05)' }} expandIcon={<ExpandMoreIcon />} aria-controls="panel1d-content">
            <div>
              <Typography style={{ display: 'inline-block', marginRight: 16 }}>{action.alias === "" ? action.title : action.alias}</Typography>
              <Typography style={{ display: 'inline-block', color: 'orange' }} variant="caption">{action.require_complete_task ? t('app_detail_act_require_all_form_completed') : ''}</Typography>
              <Typography variant="caption" display="block">{t(action.type)}</Typography>

              {action.action_dependency.length > 0 ? <div><br /><Typography style={{ display: 'inline-block', marginRight: 16 }} >{"Actions Required"}</Typography><br />
                {actionDependencyList}
              </div> : ""}
            </div>
          </AccordionSummary>
          {actionMessageList}
          <AccordionActions>
            <div style={{ display: (props.application.status === 0) ? 'block' : 'none' }}>
              <Button
                onClick={handleConfirmAction.bind(this, action.id)}
                color="primary"
                round
                disabled={dependentTaskCompleted ? action.require_complete_task ? !allFormsCompleted : false : true}
              >
                {props.requesting ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}
                &nbsp;&nbsp;
                {t('app_detail_act_msg_confirm_action')}
              </Button>
            </div>
          </AccordionActions>
        </Accordion>
      </div>,
    );
    if (props.application_detail_form.role_hide_menu) {
      actionList.push(
        <div key={action.id + 100}>
          <div style={{ display: (props.application.status === 0) ? 'block' : 'none' }}>
            <Button
              onClick={handleConfirmAction.bind(this, action.id)}
              color="primary"
              round
              disabled={dependentTaskCompleted ? action.require_complete_task ? !allFormsCompleted : false : true}
            >
              {props.requesting ? <CircularProgress size={20} color="inherit" /> : <SaveIcon />}
              &nbsp;&nbsp;
              {t('app_detail_act_msg_submit')}
            </Button>
          </div>
        </div>,
      );
    }
  });

  const handleStep = (step) => () => {
    if (step.is_submit) {
      handleActionClick();
    } else {
      hideForm(step.id);
      setCurrentForm(step.id);
      props.setShowForm('save', step.id);
    }
  };
  const handleSelfReminderToggle = (e) => {
    if (!e.target.checked) {
      props.patchApplicationRemind(props.application_id, props.application_detail_form.application_tasklog_id, { "remind_message": props.application_detail_form.remind_message, "remind_date": props.application_detail_form.remind_date, "send_now": "0", "reset_date": "1" })
    }
  };
  // const { classes } = props;
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <SuccessSnackbar
        open={props.showFormSuccessMsg}
        setFormSuccessMsg={props.setFormSuccessMsg}
      />
      <ErrorSnackbar
        open={props.showFormErrorMsg}
        setFormErrorMsg={props.setFormErrorMsg}
      />
      <GridContainer>
        {/* LeftSide */}
        <GridItem key="left" xs={12} sm={12} md={2} style={{ paddingLeft: '5px', paddingRight: '5px', display: fullscreen ? 'none' : 'block' }}>
          <TreeView
            expanded={formCategoryArray}
            onNodeSelect={handleTreeNodeSelect}
            selected={`${currentForm}`}
            key="treeView"
          >
            {treeCatItems}
          </TreeView>
        </GridItem>
        {/* Middle Content */}
        <GridItem key="middle" xs={12} sm={12} md={fullscreen ? 12 : 8}>
          <div style={{ display: (submitableFormList.length === 0 || props.application_detail_form.task_completed) ? 'none' : 'block' }}>
            <Stepper activeStep={activeStep}>
              {submitableFormList.map((step) => (
                <Step key={step.id}>
                  <StepButton onClick={handleStep(step)} completed={step.form_completed}>
                    {step.title}
                  </StepButton>
                </Step>
              ))}

            </Stepper>
          </div>
          <div key="backToStatus" style={{ display: props.application_detail_form.task_completed ? 'block' : 'none' }}>
            <Card>
              <CardBody>
                <div>
                  <Button
                    color="primary"
                    round
                    onClick={onBackStatusClick.bind(this)}
                  >
                    <ArrowBackIcon /> Back To Status
                  </Button>

                </div>
              </CardBody>
            </Card>
          </div>
          <br />
          {formItems}
          <div key="actionForm" style={{ display: displayActionForm ? 'block' : 'none' }}>
            <Card>
              <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                <Typography variant="h5" gutterBottom>{t('app_detail_submit_checklist_title')}</Typography>
              </CardHeader>
              <CardBody>
                <List component="nav" aria-label="main mailbox folders">
                  {checkList}
                </List>
              </CardBody>
            </Card>
            <br />
            {props.application_detail_form.action_required === undefined ? '' : props.application_detail_form.action_required
              ? (
                <div>

                  <Card>
                    <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }} title={props.application_detail_form.info_message}>
                      <Typography variant="h5" gutterBottom>{t('app_detail_submit_action_title')}</Typography>
                    </CardHeader>
                    <CardBody>
                      {actionList}
                    </CardBody>
                  </Card>
                  <br />
                  {props.application_detail_form.info_message != "" ?
                    <Card>
                      <CardBody>
                        {actionRuleDetail}
                      </CardBody>
                    </Card>
                    : ""}
                </div>
              )
              : ''}
          </div>
          <div key="actionMessages" style={{ display: displayActionMessages && !props.application_detail_form.task_completed ? 'block' : 'none' }}>
            {taskID !== 0
              ? (
                <div>
                  <AppMessagesComp
                    getApplicationMessages={props.getApplicationMessages}
                    application_id={props.application_id}
                    task_id={taskID}
                    application_detail_messages={props.application_detail_messages === '' ? [] : props.application_detail_messages}
                    setShowForm={props.setShowForm}
                    showHideMenu={props.application_detail_form.role_hide_menu}
                    submitableFormList={submitableFormList}
                  />
                </div>
              )
              : ''}
          </div>
          <div key="appActiveTask" style={{ display: displayAppActiveTask ? 'block' : 'none' }}>
            {taskID !== 0
              ? (
                <AppActiveTask
                  application_id={props.application_id}
                  role_id={props.role_id}
                />
              )
              : ''}
          </div>
          <div key="appTracking" style={{ display: displayAppTracking ? 'block' : 'none' }}>
            {taskID !== 0
              ? (
                <AppTracking
                  application_id={props.application_id}
                  role_id={props.role_id}
                  role_show_msgs_in_status={props.application_detail_form.role_show_msgs_in_status}
                />
              )
              : ''}
          </div>
          <div key="appRoles" style={{ display: displayAppRoles ? 'block' : 'none' }}>
            {taskID !== 0
              ? (
                <AppRoles
                  application_id={props.application_id}
                  role_id={props.role_id}
                  refreshApplicationDetailForm={props.refreshApplicationDetailForm}
                />
              )
              : ''}
          </div>
          <div key="appHelp" style={{ display: displayAppHelp ? 'block' : 'none' }}>
            <AppHelp />
          </div>
          <div key="appSelfRemind" style={{ display: displaySelfRemind ? 'block' : 'none' }}>
            <AppSelfRemind
              application_id={props.application_id}
              application_tasklog_id={props.application_detail_form.application_tasklog_id}
              task_id={props.application_detail_form.id}
              remind_message={props.application_detail_form.remind_message}
              remind_date={props.application_detail_form.remind_date}
            />
          </div>
        </GridItem>
        {/* RightSide */}
        <GridItem key="right" xs={12} sm={12} md={2} style={{ display: fullscreen || props.application_detail_form.task_completed ? 'none' : 'block' }}>
          <List>
            <ListItem button onClick={handleActionClick} selected={displayActionForm}>
              <ListItemIcon style={{ display: props.application.application_closed ? 'none' : 'block' }}>
                <SyncAltIcon color="primary" />
              </ListItemIcon>
              <ListItemText style={{ display: props.application.application_closed ? 'none' : 'block' }} primary={t('app_detail_right_side_submit')} />
            </ListItem>
            <ListItem button onClick={handleActionMessagesClick} selected={displayActionMessages}>
              <ListItemIcon>
                <MessageIcon color="primary" />
              </ListItemIcon>
              <ListItemText primary={t('app_detail_right_side_messages')} />
            </ListItem>
            <div key="hideList" style={{ display: (props.application_detail_form.role_hide_menu) ? 'none' : 'block' }}>
              <ListItem button onClick={handleAppTrackingClick} selected={displayAppTracking}>
                <ListItemIcon>
                  <TimelineIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography type="caption">{t('app_detail_right_side_tracking')}</Typography>}
                />
              </ListItem>
              <ListItem button onClick={handleAppRolesClick} selected={displayAppRoles}>
                <ListItemIcon>
                  <PeopleOutlineIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography type="caption">{t('app_detail_right_side_roles')}</Typography>}
                />
              </ListItem>
              <ListItem button onClick={handleAppActiveTaskClick} selected={displayAppActiveTask}>
                <ListItemIcon>
                  <RowingIcon color="primary" />
                </ListItemIcon>
                <ListItemText primary={t('app_detail_right_side_active_tasks')} />
              </ListItem>
              <ListItem button onClick={handleAppHelpClick} selected={displayAppHelp}>
                <ListItemIcon>
                  <HelpIcon color="primary" />
                </ListItemIcon>
                <ListItemText
                  primary={<Typography type="caption">{t('app_detail_right_side_help')}</Typography>}
                />
              </ListItem>
              {props.application_detail_form.role_allow_send_remind ?
                <ListItem button onClick={onSelfReminderClick} >
                  <ListItemIcon>
                    {props.application_detail_form.remind_date === "" ?
                      <NotificationsOffIcon color="primary" /> :
                      <NotificationsActiveIcon color="primary" />}
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography type="caption">Self Reminder</Typography>}
                  />
                  <Switch
                    edge="end"
                    onChange={handleSelfReminderToggle.bind(this)}
                    checked={props.application_detail_form.remind_date !== ""}
                    color="primary"
                    inputProps={{ 'aria-label': 'primary checkbox' }}
                  />
                </ListItem>
                : ""}
              {/*
              {props.application_detail_form.escalate_action_id !== '' ?
                <ListItem button >
                  <ListItemIcon>
                    <AltRouteIcon color="primary" />
                  </ListItemIcon>
                  <ListItemText
                    primary={<Typography type="caption">Escalate</Typography>}
                  />
                  <TextField
                    type='datetime-local'
                    disabled
                  />
                </ListItem>
                : ""}
              */}
            </div>

          </List>
        </GridItem>
      </GridContainer>
    </div>
  );
};

function getChecklistStatusDisplay(status) {
  switch (status) {
    case "FIELDS_COMPLETED":
      return 'app_detail_submit_checklist_completed';
      break;
    case "COMPLETED_FORM":
      return 'app_detail_submit_checklist_completed';
      break;
    case "completed":
      return 'app_detail_submit_checklist_completed';
      break;
    case "NO_MEMBER_SELECTED":
      return 'app_detail_submit_checklist_no_member_selected';
      break;
    case "SOME_FIELDS_MISSING":
      return 'app_detail_submit_checklist_some_missing';
      break;
    case "MANY_FIELDS_MISSING":
      return 'app_detail_submit_checklist_many_missing';
      break;
    default:
      return 'app_detail_submit_checklist_many_missing';
      break;
  }
  return status
}
export default withRoot(ApplicationDetailComp);


