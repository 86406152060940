import API from 'Api/api.js';
import { commonBackendCall, getNetworkAdminConfig } from 'Api/common.js';
import {
  GET_NETWORK_SETTINGS_REQUEST,
  GET_NETWORK_SETTINGS_SUCCESS,
  GET_NETWORK_SETTINGS_FAILURE,
  PATCH_NETWORK_SETTINGS_REQUEST,
  PATCH_NETWORK_SETTINGS_SUCCESS,
  PATCH_NETWORK_SETTINGS_FAILURE,
} from 'constants/networkadmin/NetworkSetting';

export function getNetworkSettings() {
  return commonBackendCall(
    GET_NETWORK_SETTINGS_REQUEST,
    GET_NETWORK_SETTINGS_SUCCESS,
    GET_NETWORK_SETTINGS_FAILURE,
    API.get('admin/network-settings', getNetworkAdminConfig()),
  );
}
export function updateNetworkSettings(obj) {
  return commonBackendCall(
    PATCH_NETWORK_SETTINGS_REQUEST,
    PATCH_NETWORK_SETTINGS_SUCCESS,
    PATCH_NETWORK_SETTINGS_FAILURE,
    API.patch('admin/network-settings', obj, getNetworkAdminConfig()),
  );
}
