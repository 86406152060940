import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import * as formActions from 'actions/admin/FormActions';
import * as formCategoryActions from 'actions/admin/FormCategoryActions';
import * as fieldActions from 'actions/admin/FieldActions';
import * as defaultActions from 'actions/admin/DefaultActions';
import FormComp from './Form/FormComp';

const Form = (props) => {
  const { classes } = props;
  const {
    form, formCategory, field, defaultStates,
  } = props;
  const {
    getForms,
    createForm,
    updateForm,
    deleteForm,
    createAdhocForm,
    getFormPrintFile,
  } = props.formActions;
  const { resetMessage } = props.defaultActions;
  const { getAllAdhocComms } = props.fieldActions;
  const { getFormCategories } = props.formCategoryActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <FormComp
        getForms={getForms}
        resetMessage={resetMessage}
        createAdhocForm={createAdhocForm}
        getFormCategories={getFormCategories}
        getAllAdhocComms={getAllAdhocComms}
        form_list={form.form_list.list === undefined ? [] : form.form_list.list}
        formCategory_list={formCategory.formCategory_list.list === undefined ? [] : formCategory.formCategory_list.list}
        adhoc_comm_list={field.adhoc_comm_list === undefined ? [] : field.adhoc_comm_list}
        createForm={createForm}
        updateForm={updateForm}
        deleteForm={deleteForm}
        getFormPrintFile={getFormPrintFile}
        form_print_file={form.form_print_file}
        refreshForm={form.refreshForm}
        requesting={form.requesting}
        message={form.message}
        error_core={form.error_core}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    form: state.adForm,
    field: state.adField,
    formCategory: state.adFormCategory,
    defaultStates: state.adDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    formActions: bindActionCreators(formActions, dispatch),
    fieldActions: bindActionCreators(fieldActions, dispatch),
    formCategoryActions: bindActionCreators(formCategoryActions, dispatch),
    defaultActions: bindActionCreators(defaultActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Form);
