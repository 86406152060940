import React, { Component } from 'react';
import { Link, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import Button from 'components/CustomButtons/Button.jsx';
import * as applicationActions from 'actions/admin/ApplicationActions';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import PostAddRounded from '@mui/icons-material/PostAddRounded';
import AppCorresComp from './AppCorres/AppCorresComp';

const AppCorres = (props) => {
  const { classes } = props;
  const { application, defaultStates } = props;
  const { getApplicationCorres } = props.applicationActions;
  const { id } = useParams();

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/admin/application">
          <Button size="sm" round color="primary">
            {' '}
            <PostAddRounded />
            {' '}
            Applications
          </Button>
        </Link>
        <Link to="#">
          <Button size="sm" round color="primary" block>
            {' '}
            {application.application_corres_list.length > 0 ? application.application_corres_list[0].title : ""}

          </Button>
        </Link>
      </Breadcrumbs>
      <br />
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <AppCorresComp
        application_id={id}
        getApplicationCorres={getApplicationCorres}
        application_corres_list={application.application_corres_list === undefined ? [] : application.application_corres_list}
        requesting={application.requesting}
        message={application.message}
        error_core={application.error_core}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    application: state.adApplication,
    defaultStates: state.adDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    applicationActions: bindActionCreators(applicationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AppCorres);
