import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import loginPageStyle from 'assets/jss/material-dashboard-react/views/loginPageStyle.jsx';
import { Navigate, useParams } from 'react-router-dom';
import * as loginActions from 'actions/LoginActions';
import * as networkActions from 'actions/NetworkActions';
import * as defaultActions from 'actions/DefaultActions';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import SetPasswordComp from './SetPassword/SetPasswordComp';


const SetPassword = (props) => {
  const { email } = useParams();
  const { token } = useParams();
  const { classes } = props;
  const { network, login, defaultStates } = props;
  const { onSetPassword } = props.loginActions;
  const { getNetworkByName, getNetworkByAPIKey } = props.networkActions;
  //const [showLoadingPage, setShowLoadingPage] = useState(true);
  const [redirectToNetwork, setRedirectToNetwork] = useState(false);
  const { network_name } = useParams();


  useEffect(() => {
    if (localStorage.getItem('network_api_key')) {
      //setShowLoadingPage(false);
    } else {
      //setShowLoadingPage(true);
      if (network_name !== undefined) {
        getNetworkByName(network_name);
      } else {
        var envApiKey = process.env.REACT_APP_API_KEY;
        if (envApiKey !== undefined) {
          getNetworkByAPIKey(envApiKey);
        } else {
          setRedirectToNetwork(true);
        }
      }
    }
  }, []);


  if (redirectToNetwork) {
    return <Navigate to="/public/network" />;
  }

  if (localStorage.getItem('token')) {
    return <Navigate to="/list" />;
  }

  /*
  if (showLoadingPage) {
    return (
      <div>Loading...</div>
    );
  }
  */

  var tokenParam = token;
  var emailParam = email;

  return (
    <div className={classes.content}>
      <div className={classes.container}>
        {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
        <SetPasswordComp
          token={tokenParam}
          email={emailParam}
          requesting={login.requesting}
          message={login.message}
          error_code={login.error_code}
          onSetPassword={onSetPassword}
          classes={props}
          loggedIn={login.loggedIn}
          refreshNetwork={network.refreshNetwork}
        />
      </div>
    </div>
  );
}


function mapStateToProps(state) {
  return {
    login: state.login,
    defaultStates: state.defaultStates,
    network: state.network,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loginActions: bindActionCreators(loginActions, dispatch),
    defaultActions: bindActionCreators(defaultActions, dispatch),
    networkActions: bindActionCreators(networkActions, dispatch),

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(loginPageStyle)(SetPassword));
