import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import * as departmentActions from 'actions/admin/DepartmentActions';
import DepartmentComp from './Department/DepartmentComp';

const Department = (props) => {
  const { classes } = props;
  const { department, defaultStates } = props;
  const {
    getDepartments,
  } = props.departmentActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <DepartmentComp
        getDepartments={getDepartments}
        department_list={department.department_list.list === undefined ? [] : department.department_list.list}
        refreshDepartment={department.refreshDepartment}
        requesting={department.requesting}
        message={department.message}
        error_core={department.error_core}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    department: state.adDepartment,
    defaultStates: state.adDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    departmentActions: bindActionCreators(departmentActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Department);
