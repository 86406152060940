export const GET_ACTIONDEPENDENCY_REQUEST = 'GET_ACTIONDEPENDENCY_REQUEST';
export const GET_ACTIONDEPENDENCY_SUCCESS = 'GET_ACTIONDEPENDENCY_SUCCESS';
export const GET_ACTIONDEPENDENCY_FAILURE = 'GET_ACTIONDEPENDENCY_FAILURE';
export const POST_ACTIONDEPENDENCY_REQUEST = 'POST_ACTIONDEPENDENCY_REQUEST';
export const POST_ACTIONDEPENDENCY_SUCCESS = 'POST_ACTIONDEPENDENCY_SUCCESS';
export const POST_ACTIONDEPENDENCY_FAILURE = 'POST_ACTIONDEPENDENCY_FAILURE';
export const PATCH_ACTIONDEPENDENCY_REQUEST = 'PATCH_ACTIONDEPENDENCY_REQUEST';
export const PATCH_ACTIONDEPENDENCY_SUCCESS = 'PATCH_ACTIONDEPENDENCY_SUCCESS';
export const PATCH_ACTIONDEPENDENCY_FAILURE = 'PATCH_ACTIONDEPENDENCY_FAILURE';
export const DELETE_ACTIONDEPENDENCY_REQUEST = 'DELETE_ACTIONDEPENDENCY_REQUEST';
export const DELETE_ACTIONDEPENDENCY_SUCCESS = 'DELETE_ACTIONDEPENDENCY_SUCCESS';
export const DELETE_ACTIONDEPENDENCY_FAILURE = 'DELETE_ACTIONDEPENDENCY_FAILURE';
