import API from 'Api/api.js';
import { commonBackendCall, getNetworkAdminConfig } from 'Api/common.js';
import {
	GET_DASHBOARD_REQUEST,
	GET_DASHBOARD_SUCCESS,
	GET_DASHBOARD_FAILURE,
	GET_UNSENT_ERROR_EMAIL_REQUEST,
	GET_UNSENT_ERROR_EMAIL_SUCCESS,
	GET_UNSENT_ERROR_EMAIL_FAILURE,
	POST_RESEND_EMAIL_REQUEST,
	POST_RESEND_EMAIL_SUCCESS,
	POST_RESEND_EMAIL_FAILURE,
	POST_NETWORK_BACKUP_REQUEST,
	POST_NETWORK_BACKUP_SUCCESS,
	POST_NETWORK_BACKUP_FAILURE,
	GET_NETWORK_BACKUP_REQUEST,
	GET_NETWORK_BACKUP_SUCCESS,
	GET_NETWORK_BACKUP_FAILURE,
	POST_NETWORK_RESTORE_REQUEST,
	POST_NETWORK_RESTORE_SUCCESS,
	POST_NETWORK_RESTORE_FAILURE,
	GET_SENT_EMAIL_REQUEST,
	GET_SENT_EMAIL_SUCCESS,
	GET_SENT_EMAIL_FAILURE,
} from 'constants/networkadmin/Dashboard';


export function getDashboard() {
	return commonBackendCall(
		GET_DASHBOARD_REQUEST,
		GET_DASHBOARD_SUCCESS,
		GET_DASHBOARD_FAILURE,
		API.get('admin/networkadmin/dashboard', getNetworkAdminConfig()),
	);
}

export function getSentEmailList(searchStr, pageSize, pageNum, orderby, sort) {
	return commonBackendCall(
		GET_SENT_EMAIL_REQUEST,
		GET_SENT_EMAIL_SUCCESS,
		GET_SENT_EMAIL_FAILURE,
		API.get(`admin/sent-email?search=${searchStr}&page=${pageNum}&limit=${pageSize}&orderby=${orderby}&sort=${sort}`, getNetworkAdminConfig()),
	);
}

export function getUnsentErrorEmailList() {
	return commonBackendCall(
		GET_UNSENT_ERROR_EMAIL_REQUEST,
		GET_UNSENT_ERROR_EMAIL_SUCCESS,
		GET_UNSENT_ERROR_EMAIL_FAILURE,
		API.get('admin/unsent-email-list', getNetworkAdminConfig()),
	);
}



export function resendEmail(obj) {
	return commonBackendCall(
		POST_RESEND_EMAIL_REQUEST,
		POST_RESEND_EMAIL_SUCCESS,
		POST_RESEND_EMAIL_FAILURE,
		API.post('admin/resend-email', obj, getNetworkAdminConfig()),
	);
}

export function createNetworkBackup(obj) {
	return commonBackendCall(
		POST_NETWORK_BACKUP_REQUEST,
		POST_NETWORK_BACKUP_SUCCESS,
		POST_NETWORK_BACKUP_FAILURE,
		API.post('admin/backup/network', obj, getNetworkAdminConfig()),
	);
}

export function restoreNetworkBackup(obj) {
	return commonBackendCall(
		POST_NETWORK_RESTORE_REQUEST,
		POST_NETWORK_RESTORE_SUCCESS,
		POST_NETWORK_RESTORE_FAILURE,
		API.post('admin/restore/network', obj, getNetworkAdminConfig()),
	);
}
export function listNetworkBackup(obj) {
	return commonBackendCall(
		GET_NETWORK_BACKUP_REQUEST,
		GET_NETWORK_BACKUP_SUCCESS,
		GET_NETWORK_BACKUP_FAILURE,
		API.get('admin/backup/network', getNetworkAdminConfig()),
	);
}