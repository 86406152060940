import {
  GET_WORKFLOW_REQUEST,
  GET_WORKFLOW_SUCCESS,
  GET_WORKFLOW_FAILURE,
  PATCH_WORKFLOW_REQUEST,
  PATCH_WORKFLOW_SUCCESS,
  PATCH_WORKFLOW_FAILURE,
  POST_WORKFLOW_VERSION_REQUEST,
  POST_WORKFLOW_VERSION_SUCCESS,
  POST_WORKFLOW_VERSION_FAILURE,
} from '../constants/admin/Workflow';
import {
  RESET_MESSAGE,
} from '../constants/admin/Default';

const initialState = {
  requesting: false,
  message: '',
  workflow: '',
  refreshWorkflow: false,
};

export default function workflow(state = initialState, action) {
  switch (action.type) {
    case RESET_MESSAGE:
      return {
        ...state,
        message: action.payload.value,
      };
    case GET_WORKFLOW_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshWorkflow: false,
        message: '',
      };

    case GET_WORKFLOW_SUCCESS:
      localStorage.setItem('workflow_state', action.payload.data.state);
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        workflow: action.payload.data,
      };

    case GET_WORKFLOW_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case PATCH_WORKFLOW_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshWorkflow: false,
      };

    case PATCH_WORKFLOW_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshWorkflow: true,
      };

    case PATCH_WORKFLOW_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case POST_WORKFLOW_VERSION_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshWorkflow: false,
      };

    case POST_WORKFLOW_VERSION_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshWorkflow: true,
      };

    case POST_WORKFLOW_VERSION_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
