import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.jsx';
import { useTranslation } from 'react-i18next';

const ApplicationMessagesComp = (props) => {
  const { getApplicationMessages, application_id, task_id } = props;
  const { t } = useTranslation();
  useEffect(() => {
    getApplicationMessages(application_id, task_id);
  }, [getApplicationMessages, application_id, task_id]);

  const handleRequiredFormClick = () => {
    props.setShowForm('required_form', 0);
  };
  const handlePendingApprovalsClick = () => {
    props.setShowForm('pending_approval', 0);
  };

  var hideRequiredFormButton = false;
  var hidePendingButton = false;
  if (props.submitableFormList.length > 0) {
    if (props.showHideMenu ) {
      hideRequiredFormButton = false;
      hidePendingButton = true;
    } else {
      hideRequiredFormButton = true;
      hidePendingButton = false;
    }
  } else {
    hideRequiredFormButton = true;
    hidePendingButton = true;
  }

  var messagesUI = [];
  props.application_detail_messages.forEach((message) => {
    var date = new Date(message.sent_date);
    messagesUI.push(
      <div key={message.id}>
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <TextField
              value={message.message}
              key={message.id}
              fullWidth
              label={t('app_detail_messages_message_label')}
              rows={10}
              multiline
              disabled
              variant="outlined"
            />
          </GridItem>
        </GridContainer>
        <br />
        <GridContainer>
          <GridItem xs={12} sm={12} md={12}>
            <TextField
              value={date.toLocaleString(localStorage.getItem('network_lang'), { weekday: "short", year: "numeric", month: "2-digit", day: "numeric", hour: "2-digit", minute: "2-digit" })}
              key={`sentData${message.id}`}
              fullWidth
              label={t('app_detail_messages_send_at_label')}
              disabled
              variant="outlined"
            />
          </GridItem>
        </GridContainer>
        <br />
        <br />
      </div>,
    );
  });

  return (
    <div>
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <Typography variant="h5" gutterBottom>{t('app_detail_messages_title')}</Typography>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <Button
                onClick={handleRequiredFormClick}
                color="primary"
                round
                style={{ display: hideRequiredFormButton ? 'none' : 'block' }}
              >
                {t('app_detail_messages_required_forms')}
              </Button>
              <Button
                onClick={handlePendingApprovalsClick}
                color="primary"
                round
                style={{ display: hidePendingButton ? 'none' : 'block' }}
              >
                {t('app_detail_messages_pending_request')}
              </Button>
            </GridItem>
          </GridContainer>
          <br />
          {messagesUI}
        </CardBody>
      </Card>
    </div>
  );
};

export default ApplicationMessagesComp;
