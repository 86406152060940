import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter.jsx';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import AccountTree from '@mui/icons-material/AccountTree';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from 'components/CustomButtons/Button.jsx';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@mui/material/MenuItem';
import withStyles from "@material-ui/core/styles/withStyles";
import extendedFormsStyle from "assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import UserSearch from 'components/UserSearch/UserSearch.jsx';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom'

const DepartmentComp = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogButton, setDialogButton] = useState('');
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [dialogType, setDialogType] = useState('add');
  const [id, setID] = useState('');
  const [name, setName] = useState('');
  const [userID, setUserID] = useState('');
  const [userData, setUserData] = useState('');
  const [parentDeptID, setParentDeptID] = useState('');
  const [alias, setAlias] = useState('');
  const [shortName, setShortName] = useState('');
  const [status, setStatus] = useState(1)
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [showUserSearch, setShowUserSearch] = useState(false)
  const [selectedUserNameEmail, setSelectedUserNameEmail] = useState("")
  const [selectedUserID, setSelectedUserID] = useState("")
  const [downloadFile, setDownloadFile] = useState(false)
  const navigate = useNavigate();


  const { getDepartmentsCSV, getDepartments } = props;
  useEffect(() => {
    getDepartments();
  }, [getDepartmentsCSV, getDepartments])

  const handleEditClick = (e) => {
    setID(e[0]);
    setName(e[1]);
    setUserID(e[2]);
    setParentDeptID(e[3]);
    setShortName(e[4]);
    setStatus(e[5]);
    setUserData(e[6]);
    setAlias(e[7]);
    setSelectedUserNameEmail(e[6])
    setMessage('');
    setDialogTitle('Edit');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('update');
    setDialogOpen(true);
  };
  const handleDeleteClick = (e) => {
    setID(e[0]);
    setName(e[1]);
    setUserID(e[2]);
    setParentDeptID(e[3]);
    setShortName(e[4]);
    setStatus(e[5]);
    setUserData(e[6]);
    setAlias(e[7]);
    setSelectedUserNameEmail(e[6])
    setMessage('');
    setDialogTitle('Delete');
    setDialogButton('Confirm Delete');
    setFieldDisabled(true);
    setDialogType('delete');
    setDialogOpen(true);
  };
  const handleNewClick = (e) => {
    setName("")
    setUserID("")
    setParentDeptID("")
    setShortName("")
    setStatus("1")
    setAlias("");
    setUserData("")
    setMessage('');
    setDialogTitle('Add New');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('add');
    setDialogOpen(true);
  };

  useEffect(() => {
    setMessage(props.message)
    if (props.department_list_csv.length > 0 && (!props.requesting) && downloadFile) {
      setDownloadFile(false)
      let filename = "DeptData.csv";
      let contentType = "application/csv;charset=utf-8;";
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        var blob = new Blob([decodeURIComponent(props.department_list_csv)], { type: contentType });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        var a = document.createElement('a');
        a.download = filename;
        a.href = 'data:' + contentType + ',' + encodeURIComponent(props.department_list_csv);
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    }
    if (props.refreshDepartment) {
      props.getDepartments();
      setDialogOpen(false);
    }
    var rowItems = [];
    for (let i = 0; i < props.department_list.length; i++) {
      if (props.department_list[i].name !== "") {
        var colItems = [];
        var link = "/networkadmin/dept-intg/" + props.department_list[i].id
        colItems.push(i + 1);
        colItems.push(props.department_list[i].id);
        colItems.push(props.department_list[i].name);
        colItems.push(props.department_list[i].alias);
        colItems.push(props.department_list[i].short_name);
        colItems.push(props.department_list[i].user_name);
        //colItems.push(props.department_list[i].user_email);
        var parentDeptObj = props.department_list.find(o => o.id === props.department_list[i].parent_dept_id);
        parentDeptObj === undefined ? colItems.push('') : colItems.push(parentDeptObj.name);
        colItems.push(props.department_list[i].status ? "Active" : "Inactive");
        colItems.push(
          <div>
            <IconButton
              onClick={handleEditClick.bind(this,
                [props.department_list[i].id,
                props.department_list[i].name,
                props.department_list[i].user_id,
                props.department_list[i].parent_dept_id,
                props.department_list[i].short_name,
                props.department_list[i].status,
                props.department_list[i].user_name + " " +
                props.department_list[i].user_email,
                props.department_list[i].alias,
                ])}
              title="Edit row"
            >
              {' '}
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={handleDeleteClick.bind(this,
                [props.department_list[i].id,
                props.department_list[i].name,
                props.department_list[i].user_id,
                props.department_list[i].parent_dept_id,
                props.department_list[i].short_name,
                props.department_list[i].status,
                props.department_list[i].user_name + " " +
                props.department_list[i].user_email,
                props.department_list[i].alias,
                ])}
              title="Delete row"
            >
              {' '}
              <DeleteIcon />
            </IconButton>
            <IconButton title="Integration Fields" onClick={() => navigate(`/networkadmin/dept-intg/${props.department_list[i].id}`)}><AccountTree /></IconButton>
          </div>,
        );
        rowItems.push(colItems);
      }
    }
    setData(rowItems)
    if (props.selected_user_id !== 0) {
      setSelectedUserNameEmail(props.selected_user)
      setSelectedUserID(props.selected_user_id)
      setUserID(props.selected_user_id)
      setShowUserSearch(false)
    }
  }, [props])

  const { classes } = props;
  // const columns = [
  //   "ID",
  //   "Name",
  //   "Short Name",
  //   "Department Head",
  //   "",
  // ];
  const columns = [
    {
      name: "Serial",
      label: "Serial",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "ID",
      label: "ID",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "Name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "Alias",
      label: "Alias",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "Short Name",
      label: "Short Name",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "Department Head",
      label: "Department Head",
      options: {
        filter: true,
        sort: true,
      },
    },
    /*
    {
      name: "Department Head Email",
      label: "Department Head Email",
      options: {
        filter: true,
        sort: true,
        display: true,
      }
    },
    */
    {
      name: "Parent Dept",
      label: "Parent Dept",
      options: {
        filter: true,
        sort: true,
        display: true,
      }
    },
    {
      name: "Status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        display: true,
      }
    },

    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: true,
      }
    },
  ];
  const options = {
    filterType: "dropdown",
    responsive: "standard",
    filter: true,
    selectableRows: "none",
    rowsPerPage: 100,
    selectToolbarPlacement: "none"
  };
  const onNameChange = (e) => {
    setName(e.target.value);
  };
  const onParentDeptIDChange = (e) => {
    setParentDeptID(e.target.value);
  }
  const onShortNameChange = (e) => {
    setShortName(e.target.value);
  }
  const onAliasChange = (e) => {
    setAlias(e.target.value);
  }
  const onStatusChange = (e) => {
    setStatus(e.target.checked);
  }
  const handleDialogClose = (e) => {
    setDialogOpen(false);
    setMessage('');
  };
  const handleShowSearchClick = (e) => {
    props.setSelectedUser("", 0)
    setMessage("")
    setShowUserSearch(true)
  }

  const handleRemoveUserClick = (e) => {
    setSelectedUserNameEmail("")
    setSelectedUserID("")
  }

  const handleDialogConfirm = (e) => {
    if (dialogType === 'add') {
      props.createDepartment({
        name: name,
        parent_dept_id: parentDeptID.toString(),
        short_name: shortName,
        user_id: userID.toString(),
        status: status ? '1' : '0',
        alias: alias,
      });
    } else if (dialogType === 'update') {
      props.updateDepartment(id, {
        name: name,
        parent_dept_id: parentDeptID.toString(),
        short_name: shortName,
        user_id: userID.toString(),
        status: status ? '1' : '0',
        alias: alias,
      });
    } else if (dialogType === 'delete') {
      props.deleteDepartment(id);
    }
  };
  const handleDownloadCSV = (e) => {
    if (!props.requesting) {
      getDepartmentsCSV();
      setDownloadFile(true)
    }
  }
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Department</b>
          <p className={classes.cardCategoryWhite}>
          </p>
        </CardHeader>
        <CardBody>
          <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
          <MUIDataTable
            title={<div>
              <Button onClick={handleNewClick.bind(this)} color="primary" title="Create a new department" round> New Department </Button>
              <Button
                onClick={handleDownloadCSV.bind(this)}
                color="primary"
                title="Download CSV"
                round
                disabled={props.requesting}>
                {props.requesting
                  ? <CircularProgress size={20} color="inherit" /> : <CloudDownloadIcon />}
                Download CSV
              </Button>
            </div>}
            data={data}
            columns={columns}
            options={options}
          />
          <Dialog fullWidth open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              <Card>
                <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                  <b>{dialogTitle}</b>
                </CardHeader>
                <CardBody>
                  <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        fullWidth
                        label="Name"
                        value={name}
                        onChange={onNameChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        fullWidth
                        label="Alias"
                        value={alias}
                        onChange={onAliasChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled}>
                        <InputLabel id="departmentLabel">Parent Department</InputLabel>
                        <Select
                          labelid="departmentLabel"
                          id="departmtneSelect"
                          value={parentDeptID}
                          onChange={onParentDeptIDChange.bind(this)}
                        >
                          {props.department_list.filter(rec => rec.id !== id).map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {props.department_list.find((p) => p.id === item.id).name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        required={false}
                        fullWidth
                        label="Short Name"
                        value={shortName}
                        onChange={onShortNameChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <InputLabel id="statusLabel" shrink>Status</InputLabel>
                      <Grid item component="label" container alignItems="center" spacing={1}>
                        <Grid item>Inactive</Grid>
                        <Grid item>
                          <Switch
                            labelid="statusLabel"
                            checked={status}
                            onChange={onStatusChange.bind(this)}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled={fieldDisabled}
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                            }}
                          />
                        </Grid>
                        <Grid item>Active</Grid>
                      </Grid>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <div style={{ display: !showUserSearch ? "block" : "none" }} >
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={10}>
                        <TextField
                          required={false}
                          fullWidth={true}
                          label="Selected User"
                          onClick={handleShowSearchClick.bind(this)}
                          value={selectedUserNameEmail}
                          disabled={true}
                          InputProps={{ readOnly: true }}
                        />
                      </GridItem>
                      <GridItem xs={12} sm={12} md={1}>
                        <IconButton
                          onClick={handleShowSearchClick.bind(this)}
                          title="Change User">
                          <EditIcon />
                        </IconButton>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={1}>
                        <IconButton
                          onClick={handleRemoveUserClick.bind(this)}
                          title="Remove User">
                          <DeleteIcon />
                        </IconButton>
                      </GridItem>
                    </GridContainer>
                  </div>
                  <div style={{ display: showUserSearch ? "block" : "none" }} >
                    <UserSearch user_type="networkadmin" />
                  </div>

                  <br />
                </CardBody>
                <CardFooter style={{ textAlign: 'left' }} />
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose.bind(this)} color="primary" round>
                Cancel
              </Button>
              <Button onClick={handleDialogConfirm.bind(this)} color="primary" round>
                {dialogButton}
              </Button>
            </DialogActions>
          </Dialog>
        </CardBody>
      </Card>
    </div>
  );
}
export default withStyles(extendedFormsStyle)(DepartmentComp)
