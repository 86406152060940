import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from "components/Card/CardFooter.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.jsx';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import TextField from '@material-ui/core/TextField';
import withStyles from "@material-ui/core/styles/withStyles";
import extendedFormsStyle from "assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx";

const MessageTemplateComp = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogButton, setDialogButton] = useState("");
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [dialogType, setDialogType] = useState("add");
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [id, setID] = useState("");
  const [msgTemplate, setMsgTemplate] = useState("");
  const [templateType, setTemplateType] = useState("");

  const { getMessageTemplates } = props;
  useEffect(() => {
    getMessageTemplates();
  }, [getMessageTemplates])


  useEffect(() => {
    setMessage(props.message)
    if (props.refreshMessageTemplate) {
      props.getMessageTemplates();
      setDialogOpen(false);
    }
    var rowItems = [];
    if (props.message_template_list.length > 0) {
      for (let i = 0; i < props.message_template_list.length; i++) {
        var colItems = [];
        colItems.push(i + 1);
        colItems.push(props.message_template_list[i].template_type);
        colItems.push(props.message_template_list[i].msg_template);
        colItems.push(<div><IconButton
          onClick={handleEditClick.bind(this,
            [props.message_template_list[i].id,
            props.message_template_list[i].template_type,
            props.message_template_list[i].msg_template
            ])}
          title="Edit row"> <EditIcon /></IconButton>
          <IconButton onClick={handleDeleteClick.bind(this,
            [props.message_template_list[i].id,
            props.message_template_list[i].template_type,
            props.message_template_list[i].msg_template
            ])} title="Delete row"> <DeleteIcon /></IconButton>
        </div>);
        rowItems.push(colItems);
      }
    }
    setData(rowItems)
  }, [props])


  const handleEditClick = (e) => {
    setID(e[0])
    setTemplateType(e[1])
    setMsgTemplate(e[2])
    setDialogTitle('Edit')
    setDialogButton('Save')
    setFieldDisabled(false)
    setDialogType('update')
    setDialogOpen(true)
  }
  const handleDeleteClick = (e) => {
    setID(e[0])
    setTemplateType(e[1])
    setMsgTemplate(e[2])
    setDialogTitle('Delete')
    setDialogButton('Confirm Delete')
    setFieldDisabled(true)
    setDialogType('delete')
    setDialogOpen(true)
  }
  const handleNewClick = (e) => {
    setMsgTemplate('')
    setTemplateType('')
    setDialogTitle('Add New')
    setDialogButton('Save')
    setFieldDisabled(false)
    setDialogType('add')
    setDialogOpen(true)
  }
  const handleDialogClose = (e) => {
    setDialogOpen(false)
    setMessage('')
  }
  const handleDialogConfirm = (e) => {
    if (dialogType === 'add') {
      props.createMessageTemplate({
        msg_template: msgTemplate,
        template_type: templateType
      })
    } else if (dialogType === 'update') {
      props.updateMessageTemplate(id, {
        msg_template: msgTemplate,
        template_type: templateType
      })
    } else if (dialogType === 'delete') {
      props.deleteMessageTemplate(id)
    }
  }
  const onMsgTemplateChange = (e) => {
    setMsgTemplate(e.target.value)
  }
  const onTemplateTypeChange = (e) => {
    setTemplateType(e.target.value)
  }


  const { classes } = props;
  const columns = [
    "ID",
    "Template Type",
    "Message Template",
    ""
  ];
  const options = {
    filterType: "dropdown",
    responsive: "standard",
    filter: true,
    selectableRows: "none",
    rowsPerPage: 100,
    selectToolbarPlacement: "none"
  };
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Message Template</b>
          <p className={classes.cardCategoryWhite}>
            Here is the list of email templates used in each action.
                </p>
        </CardHeader>
        <CardBody>
          <MUIDataTable
            title={<Button onClick={handleNewClick.bind(this)} color="primary" title="Create a new Message Template" round > New Message Template </Button>}
            data={data}
            columns={columns}
            options={options}
          />
          <Dialog fullWidth={true} open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              <Card>
                <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                  <b>{dialogTitle}</b>
                </CardHeader>
                <CardBody>
                  <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        id="idTemplateType"
                        label="Template Type"
                        type="text"
                        fullWidth={true}
                        value={templateType}
                        onChange={onTemplateTypeChange.bind(this)}
                        disabled={fieldDisabled}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        id="idMessage"
                        label="Message Template"
                        type="text"
                        multiline
                        rows={10}
                        fullWidth={true}
                        value={msgTemplate}
                        onChange={onMsgTemplateChange.bind(this)}
                        disabled={fieldDisabled}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </GridItem>

                  </GridContainer>
                  <br />
                </CardBody>
                <CardFooter style={{ textAlign: 'left' }}>
                </CardFooter>
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose.bind(this)} color="primary" round>
                Cancel
                    </Button>
              <Button onClick={handleDialogConfirm.bind(this)} color="primary" round>
                {dialogButton}
              </Button>
            </DialogActions>
          </Dialog>
        </CardBody>
      </Card>
    </div >
  );
}

export default withStyles(extendedFormsStyle)(MessageTemplateComp)
