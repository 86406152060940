import {
  GET_FIELD_REQUEST,
  GET_FIELD_SUCCESS,
  GET_FIELD_FAILURE,
  GET_FORM_FIELD_REQUEST,
  GET_FORM_FIELD_SUCCESS,
  GET_FORM_FIELD_FAILURE,
  POST_FIELD_REQUEST,
  POST_FIELD_SUCCESS,
  POST_FIELD_FAILURE,
  PATCH_FIELD_REQUEST,
  PATCH_FIELD_SUCCESS,
  PATCH_FIELD_FAILURE,
  DELETE_FIELD_REQUEST,
  DELETE_FIELD_SUCCESS,
  DELETE_FIELD_FAILURE,
  SET_OPTIONS,
  SET_OPTIONS_DEFAULT_VALUE,
  SET_OPTIONS_INTG_FIELD_ID,
  SET_FIELD_AGGREGATE_RULE,
  SET_FIELD_VALIDATE_RULE,
  SET_FIELD_REQUIRED_RULE,
  SET_FIELD_VISIBLE_RULE,
  SET_DATATABLE_RULE,
  GET_USER_INTG_FIELD_REQUEST,
  GET_USER_INTG_FIELD_SUCCESS,
  GET_USER_INTG_FIELD_FAILURE,
  GET_DEPT_INTG_FIELD_REQUEST,
  GET_DEPT_INTG_FIELD_SUCCESS,
  GET_DEPT_INTG_FIELD_FAILURE,
  GET_CUST_INTG_FIELD_REQUEST,
  GET_CUST_INTG_FIELD_SUCCESS,
  GET_CUST_INTG_FIELD_FAILURE,
  GET_ITEM_INTG_FIELD_REQUEST,
  GET_ITEM_INTG_FIELD_SUCCESS,
  GET_ITEM_INTG_FIELD_FAILURE,
  GET_FIELD_ROLE_REQUEST,
  GET_FIELD_ROLE_SUCCESS,
  GET_FIELD_ROLE_FAILURE,
  GET_FIELD_DEPT_REQUEST,
  GET_FIELD_DEPT_SUCCESS,
  GET_FIELD_DEPT_FAILURE,
  GET_FIELD_ADHOC_COMMS_REQUEST,
  GET_FIELD_ADHOC_COMMS_SUCCESS,
  GET_FIELD_ADHOC_COMMS_FAILURE,
  GET_FIELD_ITEMS_REQUEST,
  GET_FIELD_ITEMS_SUCCESS,
  GET_FIELD_ITEMS_FAILURE,
} from '../constants/admin/Field';
import {
  RESET_MESSAGE,
} from '../constants/admin/Default';

const initialState = {
  requesting: false,
  message: '',
  field_list: [],
  role_list: [],
  adhoc_comm_list: [],
  dept_list: [],
  item_list: [],
  form_field_list: '',
  field_user_intg_list: [],
  field_dept_intg_list: [],
  field_cust_intg_list: [],
  field_item_intg_list: [],
  refreshField: false,
  options: { option_type: 'admin_defined', intg_field_id: '', options: [] },
  optionsDefaultValue: [],
  optionsIntgFieldID: [],
  field_aggregate_rule: { type: 'SUM', field_ids: [] },
  field_validate_rule: [],
  field_required_rule: [],
  field_visible_rule: [],
  field_datatable_rule: [{ "datatable_rule": [] }, { "datatable_rule": [] }, { "datatable_rule": [] }],
};

export default function field(state = initialState, action) {
  switch (action.type) {
    case RESET_MESSAGE:
      return {
        ...state,
        message: action.payload.value,
      };

    case SET_OPTIONS:
      return {
        ...state,
        options: action.payload.value,
      };

    case SET_OPTIONS_DEFAULT_VALUE:
      return {
        ...state,
        optionsDefaultValue: action.payload.value,
      };


    case SET_OPTIONS_INTG_FIELD_ID:
      return {
        ...state,
        optionsIntgFieldID: action.payload.value,
      };

    case SET_FIELD_AGGREGATE_RULE:
      return {
        ...state,
        field_aggregate_rule: action.payload.value,
      };

    case SET_FIELD_VALIDATE_RULE:
      return {
        ...state,
        field_validate_rule: action.payload.value,
      }

    case SET_FIELD_REQUIRED_RULE:
      return {
        ...state,
        field_required_rule: action.payload.value,
      }

    case SET_DATATABLE_RULE:
      return {
        ...state,
        field_datatable_rule: action.payload.value,
      }
    case SET_FIELD_VISIBLE_RULE:
      return {
        ...state,
        field_visible_rule: action.payload.value,
      }

    case GET_FIELD_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshField: false,
        message: '',
      };

    case GET_FIELD_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        field_list: action.payload.data,
      };

    case GET_FIELD_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_FIELD_ROLE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
      };

    case GET_FIELD_ROLE_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        role_list: action.payload.data,
      };

    case GET_FIELD_ROLE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_FIELD_ADHOC_COMMS_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
      };

    case GET_FIELD_ADHOC_COMMS_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        adhoc_comm_list: action.payload.data,
      };

    case GET_FIELD_ADHOC_COMMS_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_FIELD_DEPT_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
      };

    case GET_FIELD_DEPT_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        dept_list: action.payload.data,
      };

    case GET_FIELD_DEPT_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_FIELD_ITEMS_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
      };

    case GET_FIELD_ITEMS_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        item_list: action.payload.data,
      };

    case GET_FIELD_ITEMS_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_USER_INTG_FIELD_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
      };

    case GET_USER_INTG_FIELD_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        field_user_intg_list: action.payload.data,
      };

    case GET_USER_INTG_FIELD_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_DEPT_INTG_FIELD_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
      };

    case GET_DEPT_INTG_FIELD_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        field_dept_intg_list: action.payload.data,
      };

    case GET_DEPT_INTG_FIELD_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_CUST_INTG_FIELD_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
      };

    case GET_CUST_INTG_FIELD_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        field_cust_intg_list: action.payload.data,
      };

    case GET_CUST_INTG_FIELD_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_ITEM_INTG_FIELD_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
      };

    case GET_ITEM_INTG_FIELD_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        field_item_intg_list: action.payload.data,
      };

    case GET_ITEM_INTG_FIELD_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_FORM_FIELD_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshField: false,
        message: '',
      };

    case GET_FORM_FIELD_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        form_field_list: action.payload.data,
      };

    case GET_FORM_FIELD_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case POST_FIELD_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshField: false,
      };

    case POST_FIELD_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshField: true,
      };

    case POST_FIELD_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case PATCH_FIELD_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshField: false,
      };

    case PATCH_FIELD_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshField: true,
      };

    case PATCH_FIELD_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case DELETE_FIELD_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshField: false,
      };

    case DELETE_FIELD_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshField: true,
      };

    case DELETE_FIELD_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
