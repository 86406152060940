export const GET_ACTION_MSG_REQUEST = 'GET_ACTION_MSG_REQUEST';
export const GET_ACTION_MSG_SUCCESS = 'GET_ACTION_MSG_SUCCESS';
export const GET_ACTION_MSG_FAILURE = 'GET_ACTION_MSG_FAILURE';
export const POST_ACTION_MSG_REQUEST = 'POST_ACTION_MSG_REQUEST';
export const POST_ACTION_MSG_SUCCESS = 'POST_ACTION_MSG_SUCCESS';
export const POST_ACTION_MSG_FAILURE = 'POST_ACTION_MSG_FAILURE';
export const PATCH_ACTION_MSG_REQUEST = 'PATCH_ACTION_MSG_REQUEST';
export const PATCH_ACTION_MSG_SUCCESS = 'PATCH_ACTION_MSG_SUCCESS';
export const PATCH_ACTION_MSG_FAILURE = 'PATCH_ACTION_MSG_FAILURE';
export const DELETE_ACTION_MSG_REQUEST = 'DELETE_ACTION_MSG_REQUEST';
export const DELETE_ACTION_MSG_SUCCESS = 'DELETE_ACTION_MSG_SUCCESS';
export const DELETE_ACTION_MSG_FAILURE = 'DELETE_ACTION_MSG_FAILURE';
export const GET_ACTION_ACTIONMSG_REQUEST = 'GET_ACTION_ACTIONMSG_REQUEST';
export const GET_ACTION_ACTIONMSG_SUCCESS = 'GET_ACTION_ACTIONMSG_SUCCESS';
export const GET_ACTION_ACTIONMSG_FAILURE = 'GET_ACTION_ACTIONMSG_FAILURE';
export const GET_ACTION_MSG_VAR_REQUEST = 'GET_ACTION_MSG_VAR_REQUEST';
export const GET_ACTION_MSG_VAR_SUCCESS = 'GET_ACTION_MSG_VAR_SUCCESS';
export const GET_ACTION_MSG_VAR_FAILURE = 'GET_ACTION_MSG_VAR_FAILURE';
export const GET_ACTION_MSG_LINK_DATA_LIST_REQUEST = 'GET_ACTION_MSG_LINK_DATA_LIST_REQUEST';
export const GET_ACTION_MSG_LINK_DATA_LIST_SUCCESS = 'GET_ACTION_MSG_LINK_DATA_LIST_SUCCESS';
export const GET_ACTION_MSG_LINK_DATA_LIST_FAILURE = 'GET_ACTION_MSG_LINK_DATA_LIST_FAILURE';
export const GET_ACTION_MSG_WF_ATTR_REQUEST = 'GET_ACTION_MSG_WF_ATTR_REQUEST';
export const GET_ACTION_MSG_WF_ATTR_SUCCESS = 'GET_ACTION_MSG_WF_ATTR_SUCCESS';
export const GET_ACTION_MSG_WF_ATTR_FAILURE = 'GET_ACTION_MSG_WF_ATTR_FAILURE';
export const GET_ACTION_MSG_TASK_REQUEST = 'GET_ACTION_MSG_TASK_REQUEST';
export const GET_ACTION_MSG_TASK_SUCCESS = 'GET_ACTION_MSG_TASK_SUCCESS';
export const GET_ACTION_MSG_TASK_FAILURE = 'GET_ACTION_MSG_TASK_FAILURE';
export const GET_INTG_EXT_API_EXTERNAL_REQUEST = 'GET_INTG_EXT_API_EXTERNAL_REQUEST';
export const GET_INTG_EXT_API_EXTERNAL_SUCCESS = 'GET_INTG_EXT_API_EXTERNAL_SUCCESS';
export const GET_INTG_EXT_API_EXTERNAL_FAILURE = 'GET_INTG_EXT_API_EXTERNAL_FAILURE';
export const GET_ACTION_MSG_TASK_FORM_REQUEST = 'GET_ACTION_MSG_TASK_FORM_REQUEST';
export const GET_ACTION_MSG_TASK_FORM_SUCCESS = 'GET_ACTION_MSG_TASK_FORM_SUCCESS';
export const GET_ACTION_MSG_TASK_FORM_FAILURE = 'GET_ACTION_MSG_TASK_FORM_FAILURE';
export const GET_ACTION_MSG_TASK_ACTION_REQUEST = 'GET_ACTION_MSG_TASK_ACTION_REQUEST';
export const GET_ACTION_MSG_TASK_ACTION_SUCCESS = 'GET_ACTION_MSG_TASK_ACTION_SUCCESS';
export const GET_ACTION_MSG_TASK_ACTION_FAILURE = 'GET_ACTION_MSG_TASK_ACTION_FAILURE';