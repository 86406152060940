import API from 'Api/api.js';
import { commonBackendCall, getNetworkAdminConfig } from 'Api/common.js';
import {
  GET_USER_INTG_REQUEST,
  GET_USER_INTG_SUCCESS,
  GET_USER_INTG_FAILURE,
  GET_CUSTOM_INTG_FIELD_REQUEST,
  GET_CUSTOM_INTG_FIELD_SUCCESS,
  GET_CUSTOM_INTG_FIELD_FAILURE,
  GET_DEPT_INTG_REQUEST,
  GET_DEPT_INTG_SUCCESS,
  GET_DEPT_INTG_FAILURE,
  GET_ITEM_INTG_FIELD_REQUEST,
  GET_ITEM_INTG_FIELD_SUCCESS,
  GET_ITEM_INTG_FIELD_FAILURE,
  GET_SYS_USER_REQUEST,
  GET_SYS_USER_SUCCESS,
  GET_SYS_USER_FAILURE,
  POST_INTG_REQUEST,
  POST_INTG_SUCCESS,
  POST_INTG_FAILURE,
  PATCH_INTG_REQUEST,
  PATCH_INTG_SUCCESS,
  PATCH_INTG_FAILURE,
  DELETE_INTG_REQUEST,
  DELETE_INTG_SUCCESS,
  DELETE_INTG_FAILURE,
  GET_BATCH_INTG_REQUEST,
  GET_BATCH_INTG_SUCCESS,
  GET_BATCH_INTG_FAILURE,
  POST_FILE_UPLOAD_REQUEST,
  POST_FILE_UPLOAD_SUCCESS,
  POST_FILE_UPLOAD_FAILURE,
  POST_CUSTOM_FILE_UPLOAD_REQUEST,
  POST_CUSTOM_FILE_UPLOAD_SUCCESS,
  POST_CUSTOM_FILE_UPLOAD_FAILURE,
  POST_ITEM_FILE_UPLOAD_REQUEST,
  POST_ITEM_FILE_UPLOAD_SUCCESS,
  POST_ITEM_FILE_UPLOAD_FAILURE,
  GET_BATCH_INTG_LOG_REQUEST,
  GET_BATCH_INTG_LOG_SUCCESS,
  GET_BATCH_INTG_LOG_FAILURE,
} from 'constants/networkadmin/Integration';

export function getFileUploadConfig() {
  const config = {
    headers: {
      Token: localStorage.getItem('networkadmin_token'),
      Apikey: localStorage.getItem('network_api_key'),
      'content-type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
    }
  };
  return config;
}

export function getSystemUser() {
  return commonBackendCall(
    GET_SYS_USER_REQUEST,
    GET_SYS_USER_SUCCESS,
    GET_SYS_USER_FAILURE,
    API.get('admin/bot', getNetworkAdminConfig()),
  );
}
export function getBatchIntgs() {
  return commonBackendCall(
    GET_BATCH_INTG_REQUEST,
    GET_BATCH_INTG_SUCCESS,
    GET_BATCH_INTG_FAILURE,
    API.get('admin/intg-batches', getNetworkAdminConfig()),
  );
}
export function getBatchLogs(batchID) {
  return commonBackendCall(
    GET_BATCH_INTG_LOG_REQUEST,
    GET_BATCH_INTG_LOG_SUCCESS,
    GET_BATCH_INTG_LOG_FAILURE,
    API.get(`admin/intg-batch-logs/${batchID}`, getNetworkAdminConfig()),
  );
}
export function getDeptIntgFields() {
  return commonBackendCall(
    GET_DEPT_INTG_REQUEST,
    GET_DEPT_INTG_SUCCESS,
    GET_DEPT_INTG_FAILURE,
    API.get('admin/intg-fields?type=dept', getNetworkAdminConfig()),
  );
}
export function getUserIntgFields() {
  return commonBackendCall(
    GET_USER_INTG_REQUEST,
    GET_USER_INTG_SUCCESS,
    GET_USER_INTG_FAILURE,
    API.get('admin/intg-fields?type=user', getNetworkAdminConfig()),
  );
}
export function getCustomIntgFields() {
  return commonBackendCall(
    GET_CUSTOM_INTG_FIELD_REQUEST,
    GET_CUSTOM_INTG_FIELD_SUCCESS,
    GET_CUSTOM_INTG_FIELD_FAILURE,
    API.get('admin/intg-fields?type=custom', getNetworkAdminConfig()),
  );
}
export function getItemIntgFields() {
  return commonBackendCall(
    GET_ITEM_INTG_FIELD_REQUEST,
    GET_ITEM_INTG_FIELD_SUCCESS,
    GET_ITEM_INTG_FIELD_FAILURE,
    API.get('admin/intg-fields?type=item', getNetworkAdminConfig()),
  );
}
export function createIntgField(obj) {
  return commonBackendCall(
    POST_INTG_REQUEST,
    POST_INTG_SUCCESS,
    POST_INTG_FAILURE,
    API.post('admin/intg-fields', obj, getNetworkAdminConfig()),
  );
}
export function postFileUpload(obj) {
  return commonBackendCall(
    POST_FILE_UPLOAD_REQUEST,
    POST_FILE_UPLOAD_SUCCESS,
    POST_FILE_UPLOAD_FAILURE,
    API.post('admin/upload-files', obj, getFileUploadConfig()),
  );
}
export function postCustomFileUpload(obj) {
  return commonBackendCall(
    POST_CUSTOM_FILE_UPLOAD_REQUEST,
    POST_CUSTOM_FILE_UPLOAD_SUCCESS,
    POST_CUSTOM_FILE_UPLOAD_FAILURE,
    API.post('admin/upload-custom-files', obj, getFileUploadConfig()),
  );
}
export function postItemFileUpload(obj) {
  return commonBackendCall(
    POST_ITEM_FILE_UPLOAD_REQUEST,
    POST_ITEM_FILE_UPLOAD_SUCCESS,
    POST_ITEM_FILE_UPLOAD_FAILURE,
    API.post('admin/upload-item2-files', obj, getFileUploadConfig()),
  );
}
export function updateIntgField(intgFieldID, obj) {
  return commonBackendCall(
    PATCH_INTG_REQUEST,
    PATCH_INTG_SUCCESS,
    PATCH_INTG_FAILURE,
    API.patch(`admin/intg-fields/${intgFieldID}`, obj, getNetworkAdminConfig()),
  );
}
export function deleteIntgField(intgFieldID) {
  return commonBackendCall(
    DELETE_INTG_REQUEST,
    DELETE_INTG_SUCCESS,
    DELETE_INTG_FAILURE,
    API.delete(`admin/intg-fields/${intgFieldID}`, getNetworkAdminConfig()),
  );
}
