import API from 'Api/api.js';
import { commonBackendCall, getNetworkAdminConfig } from 'Api/common.js';
import {
  GET_CUSTOM_INTG_REQUEST,
  GET_CUSTOM_INTG_SUCCESS,
  GET_CUSTOM_INTG_FAILURE,
  POST_CUSTOM_INTG_REQUEST,
  POST_CUSTOM_INTG_SUCCESS,
  POST_CUSTOM_INTG_FAILURE,
  PATCH_CUSTOM_INTG_REQUEST,
  PATCH_CUSTOM_INTG_SUCCESS,
  PATCH_CUSTOM_INTG_FAILURE,
  DELETE_CUSTOM_INTG_REQUEST,
  DELETE_CUSTOM_INTG_SUCCESS,
  DELETE_CUSTOM_INTG_FAILURE,
  GET_CUSTOM_CSV_REQUEST,
  GET_CUSTOM_CSV_SUCCESS,
  GET_CUSTOM_CSV_FAILURE,

} from 'constants/networkadmin/Custom';

export function getCustomCSV() {
  return commonBackendCall(
    GET_CUSTOM_CSV_REQUEST,
    GET_CUSTOM_CSV_SUCCESS,
    GET_CUSTOM_CSV_FAILURE,
    API.get('admin/custom/csv', getNetworkAdminConfig()),
  );
}

export function getCustomIntgs() {
  return commonBackendCall(
    GET_CUSTOM_INTG_REQUEST,
    GET_CUSTOM_INTG_SUCCESS,
    GET_CUSTOM_INTG_FAILURE,
    API.get(`admin/custom/intg`, getNetworkAdminConfig()),
  );
}

export function createCustomIntgFieldValue(obj) {
  return commonBackendCall(
    POST_CUSTOM_INTG_REQUEST,
    POST_CUSTOM_INTG_SUCCESS,
    POST_CUSTOM_INTG_FAILURE,
    API.post('admin/custom/intg', obj, getNetworkAdminConfig()),
  );
}

export function updateCustomIntgFieldValue(customIntgID, obj) {
  return commonBackendCall(
    PATCH_CUSTOM_INTG_REQUEST,
    PATCH_CUSTOM_INTG_SUCCESS,
    PATCH_CUSTOM_INTG_FAILURE,
    API.patch(`admin/custom/intg/${customIntgID}`, obj, getNetworkAdminConfig()),
  );
}

export function deleteCustomIntgFieldValue(customIntgID) {
  return commonBackendCall(
    DELETE_CUSTOM_INTG_REQUEST,
    DELETE_CUSTOM_INTG_SUCCESS,
    DELETE_CUSTOM_INTG_FAILURE,
    API.delete(`admin/custom/intg/${customIntgID}`, getNetworkAdminConfig()),
  );
}