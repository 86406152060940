import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.jsx';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx';
import UserSearch from 'components/UserSearch/UserSearch.jsx';

function generateUniqueString() {
  var ts = String(new Date().getTime());
  var i = 0;
  var out = '';

  for (i = 0; i < ts.length; i += 2) {
    out += Number(ts.substr(i, 2)).toString(36);
  }

  return (out);
}

const RolesComp = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogButton, setDialogButton] = useState('');
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [dialogType, setDialogType] = useState('add');
  const [data, setData] = useState([]);
  const [message, setMessage] = useState('');
  const [id, setID] = useState('');
  const [title, setTitle] = useState('');
  const [generic, setGeneric] = useState(0);
  const [alias, setAlias] = useState('');
  const [childRoleID, setChildRoleID] = useState(0);
  const [departmentID, setDepartmentID] = useState('');
  const [isCommittee, setIsCommittee] = useState(false);
  const [isGeneric, setIsGeneric] = useState(false);
  const [isApplicant, setIsApplicant] = useState(false);
  const [selectedDeputyUserID1, setSelectedDeputyUserID1] = useState('');
  const [selectedDeputyNameEmail1, setSelectedDeputyNameEmail1] = useState('');
  const [selectedDeputyUserID2, setSelectedDeputyUserID2] = useState('');
  const [selectedDeputyNameEmail2, setSelectedDeputyNameEmail2] = useState('');
  const [showDeputySearch1, setShowDeputySearch1] = useState(false);
  const [showDeputySearch2, setShowDeputySearch2] = useState(false);
  const [allowAppOnhold, setAllowAppOnhold] = useState(false);
  const [allowAppEdit, setAllowAppEdit] = useState(false);
  const [allowSendRemind, setAllowSendRemind] = useState(false);
  const [hideMenu, setHideMenu] = useState(false);
  const [showMsgsInStatus, setShowMsgsInStatus] = useState(false);


  const [displayROTitle, setDisplayROTitle] = useState(false);
  const [displayTitle, setDisplayTitle] = useState(false);
  const [displayDDTitle, setDisplayDDTitle] = useState(false);
  const [displayAlias, setDisplayAlias] = useState(false);
  const [displayDeptUser, setDisplayDeptUser] = useState(false);
  const [displayGeneric, setDisplayGeneric] = useState(false);
  const [displayDeputy, setDisplayDeputy] = useState(false);

  const {
    getRoles, getAdhocRoles, getCommNames, getDepartments,
  } = props;
  useEffect(() => {
    getRoles();
    getAdhocRoles();
    getCommNames();
    getDepartments();
  }, [getRoles, getAdhocRoles, getCommNames, getDepartments]);

  const roleTitleReadable = (value, alias) => (alias === '' ? value : `${value} (${alias})`);

  const showHideComponents = (e) => {
    if (e[4]) {
      // is Generic
      setDisplayROTitle(false);
      setDisplayTitle(false);
      setDisplayDDTitle(true);
      setDisplayAlias(true);
      setDisplayDeptUser(false);
      setDisplayGeneric(true);
      setDisplayDeputy(false);
    } else if (e[3] !== 0) {
      // is Committee
      setDisplayROTitle(true);
      setDisplayTitle(false);
      setDisplayDDTitle(false);
      setDisplayAlias(true);
      setDisplayDeptUser(false);
      setDisplayGeneric(false);
      setDisplayDeputy(false);
    } else if (localStorage.getItem('role_id') === e[0].toString()) {
      // is applicant
      setDisplayROTitle(true);
      setDisplayTitle(false);
      setDisplayDDTitle(false);
      setDisplayAlias(true);
      setDisplayDeptUser(false);
      setDisplayGeneric(false);
      setDisplayDeputy(true);
    } else {
      // normal role
      setDisplayROTitle(false);
      setDisplayTitle(true);
      setDisplayDDTitle(false);
      setDisplayAlias(true);
      setDisplayDeptUser(true);
      setDisplayGeneric(true);
      setDisplayDeputy(true);
    }
  };
  const handleEditClick = (e) => {
    setID(e[0]);
    setIsApplicant(localStorage.getItem('role_id') === e[0].toString());
    setTitle(e[1]);
    setDepartmentID(e[2]);
    setIsCommittee(e[3] !== 0);
    setGeneric(e[4]);
    setIsGeneric(!!e[4]);
    setAlias(e[5]);
    setSelectedDeputyUserID1(e[6]);
    setSelectedDeputyNameEmail1(e[6] !== 0 ? `${e[7]} (${e[8]})` : '');
    setSelectedDeputyUserID2(e[9]);
    setSelectedDeputyNameEmail2(e[9] !== 0 ? `${e[10]} (${e[11]})` : '');
    setChildRoleID(e[12]);
    setAllowAppOnhold(e[13]);
    setAllowAppEdit(e[14]);
    setAllowSendRemind(e[15]);
    setHideMenu(e[16]);
    setShowMsgsInStatus(e[17]);
    setMessage('');
    setDialogTitle('Edit');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('update');
    setDialogOpen(true);
    setShowDeputySearch1(false);
    setShowDeputySearch2(false);
    showHideComponents(e);
    props.resetMessage('');
  };

  const handleDeleteClick = (e) => {
    setID(e[0]);
    setTitle(e[1]);
    setDepartmentID(e[2]);
    setIsCommittee(e[3] !== 0);
    setGeneric(e[4]);
    setIsGeneric(!!e[4]);
    setAlias(e[5]);
    setSelectedDeputyUserID1(e[6]);
    setSelectedDeputyNameEmail1(e[6] !== 0 ? `${e[7]} (${e[8]})` : '');
    setSelectedDeputyUserID2(e[9]);
    setSelectedDeputyNameEmail2(e[9] !== 0 ? `${e[10]} (${e[11]})` : '');
    setChildRoleID(e[12]);
    setAllowAppOnhold(e[13]);
    setAllowAppEdit(e[14]);
    setAllowSendRemind(e[15]);
    setHideMenu(e[16]);
    setShowMsgsInStatus(e[17]);
    setMessage('');
    setDialogTitle('Delete');
    setDialogButton('Confirm Delete');
    setFieldDisabled(true);
    setDialogType('delete');
    setDialogOpen(true);
    setShowDeputySearch1(false);
    setShowDeputySearch2(false);
    showHideComponents(e);
    props.resetMessage('');
  };

  const handleNewClick = (e) => {
    setID('');
    setTitle('');
    setAlias('');
    setDepartmentID('');
    setChildRoleID(0);
    setIsCommittee(false);
    setGeneric(false);
    setIsGeneric(false);
    setMessage('');
    setDialogTitle('Add New');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('add');
    setDialogOpen(true);
    setSelectedDeputyNameEmail1('');
    setSelectedDeputyUserID1('');
    setSelectedDeputyUserID2('');
    setSelectedDeputyNameEmail2('');
    setShowDeputySearch1(false);
    setShowDeputySearch2(false);
    setAllowAppOnhold(false);
    setAllowAppEdit(false);
    setAllowSendRemind(false);
    setHideMenu(false);
    setShowMsgsInStatus(false);
    props.resetMessage('');

    setDisplayROTitle(false);
    setDisplayTitle(true);
    setDisplayDDTitle(false);
    setDisplayAlias(true);
    setDisplayDeptUser(true);
    setDisplayGeneric(true);
    setDisplayDeputy(true);
  };
  useEffect(() => {
    setMessage(props.message);
    if (props.refreshRole) {
      props.getRoles();
      setDialogOpen(false);
    }
    var rowItems = [];
    for (let i = 0; i < props.role_list.length; i++) {
      if (props.role_list[i].title !== '') {
        var colItems = [];
        colItems.push(i + 1);
        colItems.push(roleTitleReadable.bind(this, props.role_list[i].title, (props.role_list[i].alias)));
        colItems.push(props.role_list[i].department_name !== '' ? `${props.role_list[i].department_name}/${props.role_list[i].user_name}` : '');
        colItems.push(props.role_list[i].committee_name_id === 0 ? 'No' : 'Yes');
        colItems.push(props.role_list[i].generic ? 'Yes' : 'No');
        colItems.push(props.role_list[i].deputy_user_name1);
        colItems.push(props.role_list[i].deputy_user_name2);
        colItems.push(props.role_list[i].alias);

        if (localStorage.getItem('role_id') !== props.role_list[i].id.toString() && !(props.role_list[i].committee_name_id > 0)) {
          colItems.push(
            <div>
              <IconButton
                onClick={handleEditClick.bind(this,
                  [props.role_list[i].id,
                  props.role_list[i].title,
                  props.role_list[i].department_id,
                  props.role_list[i].committee_name_id,
                  props.role_list[i].generic,
                  props.role_list[i].alias,
                  props.role_list[i].deputy_user_id1,
                  props.role_list[i].deputy_user_name1,
                  props.role_list[i].deputy_user_email1,
                  props.role_list[i].deputy_user_id2,
                  props.role_list[i].deputy_user_name2,
                  props.role_list[i].deputy_user_email2,
                  props.role_list[i].child_role_id,
                  props.role_list[i].allow_app_onhold,
                  props.role_list[i].allow_app_edit,
                  props.role_list[i].allow_send_remind,
                  props.role_list[i].hide_menu,
                  props.role_list[i].show_msgs_in_status,

                  ])}
                title={`Edit Row ID:${props.role_list[i].id}`}
              >
                {' '}
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={handleDeleteClick.bind(this,
                  [props.role_list[i].id,
                  props.role_list[i].title,
                  props.role_list[i].department_id,
                  props.role_list[i].committee_name_id,
                  props.role_list[i].generic,
                  props.role_list[i].alias,
                  props.role_list[i].deputy_user_id1,
                  props.role_list[i].deputy_user_name1,
                  props.role_list[i].deputy_user_email1,
                  props.role_list[i].deputy_user_id2,
                  props.role_list[i].deputy_user_name2,
                  props.role_list[i].deputy_user_email2,
                  props.role_list[i].child_role_id,
                  props.role_list[i].allow_app_onhold,
                  props.role_list[i].allow_app_edit,
                  props.role_list[i].allow_send_remind,
                  props.role_list[i].hide_menu,
                  props.role_list[i].show_msgs_in_status,
                  ])}
                title="Delete Row"
              >
                {' '}
                <DeleteIcon />
              </IconButton>
            </div>,
          );
        } else {
          colItems.push(
            <div>
              {' '}
              <IconButton
                onClick={handleEditClick.bind(this,
                  [props.role_list[i].id,
                  props.role_list[i].title,
                  props.role_list[i].department_id,
                  props.role_list[i].committee_name_id,
                  props.role_list[i].generic,
                  props.role_list[i].alias,
                  props.role_list[i].deputy_user_id1,
                  props.role_list[i].deputy_user_name1,
                  props.role_list[i].deputy_user_email1,
                  props.role_list[i].deputy_user_id2,
                  props.role_list[i].deputy_user_name2,
                  props.role_list[i].deputy_user_email2,
                  props.role_list[i].child_role_id,
                  props.role_list[i].allow_app_onhold,
                  props.role_list[i].allow_app_edit,
                  props.role_list[i].allow_send_remind,
                  props.role_list[i].hide_menu,
                  props.role_list[i].show_msgs_in_status,
                  ])}
                title={`Edit Row ID:${props.role_list[i].id}`}
              >
                {' '}
                <EditIcon />
              </IconButton>
            </div>,
          );
        }
        rowItems.push(colItems);
      }
      setData(rowItems);
      if (props.selected_user_id !== 0 && showDeputySearch1) {
        setSelectedDeputyNameEmail1(props.selected_user);
        setSelectedDeputyUserID1(props.selected_user_id);
        setShowDeputySearch1(false);
        setShowDeputySearch2(false);
      }
      if (props.selected_user_id !== 0 && showDeputySearch2) {
        setSelectedDeputyNameEmail2(props.selected_user);
        setSelectedDeputyUserID2(props.selected_user_id);
        setShowDeputySearch1(false);
        setShowDeputySearch2(false);
      }
    }
  }, [props, showDeputySearch1, showDeputySearch2]);

  const handleDialogClose = (e) => {
    setDialogOpen(false);
    setMessage('');
  };
  const handleDialogConfirm = (e) => {
    if (generic) {
      if (alias === '') {
        setMessage('Invalid Alias');
        return;
      }
    }
    if (title === '') {
      setMessage('Invalid Title');
      return;
    }
    if (!generic) {
      if (departmentID === '') {
        setMessage('Invalid Department');
        return;
      }
    }
    if (dialogType === 'add') {
      props.createRole({
        title,
        department_id: departmentID.toString(),
        child_role_id: childRoleID.toString(),
        generic: generic ? '1' : '0',
        alias,
        deputy_user_id1: !isGeneric ? selectedDeputyUserID1.toString() : '',
        deputy_user_id2: !isGeneric ? selectedDeputyUserID2.toString() : '',
        allow_app_onhold: allowAppOnhold ? '1' : '0',
        allow_app_edit: allowAppEdit ? '1' : '0',
        allow_send_remind: allowSendRemind ? '1' : '0',
        hide_menu: hideMenu ? '1' : '0',
        show_msgs_in_status: showMsgsInStatus ? '1' : '0',
      });
    } else if (dialogType === 'update') {
      props.updateRole(id, {
        title,
        department_id: departmentID.toString(),
        child_role_id: childRoleID.toString(),
        generic: generic ? '1' : '0',
        alias,
        deputy_user_id1: !isGeneric ? selectedDeputyUserID1.toString() : '',
        deputy_user_id2: !isGeneric ? selectedDeputyUserID2.toString() : '',
        allow_app_onhold: allowAppOnhold ? '1' : '0',
        allow_app_edit: allowAppEdit ? '1' : '0',
        allow_send_remind: allowSendRemind ? '1' : '0',
        hide_menu: hideMenu ? '1' : '0',
        show_msgs_in_status: showMsgsInStatus ? '1' : '0',
      });
    } else if (dialogType === 'delete') {
      props.deleteRole(id);
    }
  };

  const onTitleChange = (e) => {
    var strTitle = e.target.value;
    if (e.target.value.includes('FIRST_LEVEL_MANAGER')) {
      strTitle = `FIRST_LEVEL_MANAGER${generateUniqueString()}`;
    }
    if (e.target.value.includes('SECOND_LEVEL_MANAGER')) {
      strTitle = `SECOND_LEVEL_MANAGER${generateUniqueString()}`;
    }
    setTitle(strTitle);
  };
  const onAliasChange = (e) => {
    setAlias(e.target.value);
  };
  const onChildRoleIDChange = (e) => {
    setChildRoleID(e.target.value);
  };
  const onAllowAppOnholdChange = (e) => {
    setAllowAppOnhold(e.target.checked);
  }
  const onAllowAppEditChange = (e) => {
    setAllowAppEdit(e.target.checked);
  }
  const onAllowSendRemindChange = (e) => {
    setAllowSendRemind(e.target.checked);
  }
  const onHideMenuChange = (e) => {
    setHideMenu(e.target.checked);
  }
  const onShowMsgsInStatusChange = (e) => {
    setShowMsgsInStatus(e.target.checked);
  }
  const onGenericChange = (e) => {
    setGeneric(e.target.checked);
    setIsGeneric(e.target.checked);
    setTitle('');
    if (e.target.checked) {
      setDisplayROTitle(false);
      setDisplayTitle(false);
      setDisplayDDTitle(true);
      setDisplayAlias(true);
      setDisplayDeptUser(false);
      setDisplayGeneric(true);
      setDisplayDeputy(false);
    } else {
      setDisplayROTitle(false);
      setDisplayTitle(true);
      setDisplayDDTitle(false);
      setDisplayAlias(true);
      setDisplayDeptUser(true);
      setDisplayGeneric(true);
      setDisplayDeputy(true);
    }
  };
  const onDepartmentIDChange = (e) => {
    setDepartmentID(e.target.value);
  };

  const handleShowSearch1Click = (e) => {
    props.setSelectedUser('', 0);
    setShowDeputySearch1(true);
    setShowDeputySearch2(false);
  };

  const handleRemoveUser1Click = (e) => {
    setSelectedDeputyNameEmail1('');
    setSelectedDeputyUserID1('');
  };

  const handleRemoveUser2Click = (e) => {
    setSelectedDeputyNameEmail2('');
    setSelectedDeputyUserID2('');
  };

  const handleShowSearch2Click = (e) => {
    props.setSelectedUser('', 0);
    setShowDeputySearch1(false);
    setShowDeputySearch2(true);
  };

  /* const columns = [
    "Serial",
    "Title",
    "Department/User",
    ""
  ]; */
  const columns = [
    {
      name: 'serial',
      label: 'Serial',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'title',
      label: 'Title',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'department',
      label: 'Department/User',
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'is involved in committee',
      label: 'Is Involved In Committee',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'is generic',
      label: 'Is Generic',
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'deputy name 1',
      label: 'Deputy Name 1',
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'deputy name 2',
      label: 'Deputy Name 2',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'alias',
      label: 'Alias',
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: '',
      label: '',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
  ];
  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    filter: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    selectToolbarPlacement: 'none',
  };
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Roles</b>
          <p className={props.classes.cardCategoryWhite}>
            Here is the list of the roles corresponding to each position of a user in the workflow.
          </p>
        </CardHeader>
        <CardBody>
          <MUIDataTable
            title={(
              <Button
                onClick={handleNewClick.bind(this)}
                color="primary"
                title="Create a new Role"
                disabled={parseInt(localStorage.getItem('workflow_state'), 10) > 0}
                round
              >
                {' '}
                New Role
              </Button>
            )}
            data={data}
            columns={columns}
            options={options}
          />
          <Dialog fullWidth open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              <Card>
                <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                  <b>{dialogTitle}</b>
                </CardHeader>
                <CardBody>
                  <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
                  <br />
                  <div style={{ display: displayROTitle ? 'block' : 'none' }}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          required
                          fullWidth
                          label="Title"
                          value={title}
                          disabled={fieldDisabled}
                          InputProps={{ readOnly: true }}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                  </div>
                  <div style={{ display: displayTitle ? 'block' : 'none' }}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          required
                          fullWidth
                          label="Title"
                          onChange={onTitleChange.bind(this)}
                          value={title}
                          disabled={fieldDisabled || parseInt(localStorage.getItem('role_id'), 10) === id}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                  </div>
                  <div style={{ display: displayDDTitle ? 'block' : 'none' }}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <FormControl fullWidth disabled={fieldDisabled || parseInt(localStorage.getItem('workflow_state'), 10) > 0}>
                          <InputLabel id="titleLabel">Title</InputLabel>
                          <Select
                            labelid="titleLabel"
                            id="titleSelect"
                            value={title.includes('FIRST_LEVEL_MANAGER') ? 'FIRST_LEVEL_MANAGER' : title.includes('SECOND_LEVEL_MANAGER') ? 'SECOND_LEVEL_MANAGER' : ''}
                            onChange={onTitleChange.bind(this)}
                          >
                            <MenuItem key="FIRST_LEVEL_MANAGER" value="FIRST_LEVEL_MANAGER"> First Level Manager </MenuItem>
                            <MenuItem key="SECOND_LEVEL_MANAGER" value="SECOND_LEVEL_MANAGER"> Second Level Manager </MenuItem>
                          </Select>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <br />
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <FormControl fullWidth disabled={fieldDisabled || parseInt(localStorage.getItem('workflow_state'), 10) > 0}>
                          <InputLabel id="roleLabel">Role</InputLabel>
                          <Select
                            labelid="roleLabel"
                            id="roleSelect"
                            value={childRoleID}
                            onChange={onChildRoleIDChange.bind(this)}
                          >
                            <MenuItem key={localStorage.getItem('role_id')} value={localStorage.getItem('role_id')}> Applicant </MenuItem>
                            {props.role_adhoc_list.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {props.role_adhoc_list.find((p) => p.id === item.id).title}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <br />
                  </div>
                  <div style={{ display: displayAlias ? 'block' : 'none' }}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          required={false}
                          fullWidth
                          label="Alias"
                          value={alias}
                          onChange={onAliasChange.bind(this)}
                          disabled={fieldDisabled}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                  </div>
                  <div style={{ display: displayDeptUser ? 'block' : 'none' }}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <FormControl fullWidth className={props.classes.formControl} disabled={fieldDisabled || parseInt(localStorage.getItem('workflow_state'), 10) > 0}>
                          <InputLabel id="departmentLabel">Department/User</InputLabel>
                          <Select
                            labelid="departmentLabel"
                            id="departmentSelect"
                            value={departmentID}
                            onChange={onDepartmentIDChange.bind(this)}
                          >
                            {props.department_list.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {`${props.department_list.find((p) => p.id === item.id).name}/${props.department_list.find((p) => p.id === item.id).user_name}`}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </GridItem>
                    </GridContainer>
                    <br />
                  </div>
                  <div style={{ display: displayGeneric ? 'block' : 'none' }}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <InputLabel id="genericLabel" shrink>Is Generic</InputLabel>
                        <Grid item component="label" container alignItems="center" spacing={1}>
                          <Grid item>No</Grid>
                          <Grid item>
                            <Switch
                              labelid="genricLabel"
                              checked={generic}
                              onChange={onGenericChange.bind(this)}
                              color="primary"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                              disabled={fieldDisabled}
                              classes={{
                                switchBase: props.classes.switchBase,
                                checked: props.classes.switchChecked,
                              }}
                            />
                          </Grid>
                          <Grid item>Yes</Grid>
                        </Grid>
                      </GridItem>
                    </GridContainer>
                    <br />
                  </div>
                  <div style={{ display: displayDeputy ? 'block' : 'none' }}>
                    <div style={{ display: !showDeputySearch1 ? 'block' : 'none' }}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={10}>
                          <TextField
                            required={false}
                            fullWidth
                            label="Deputy User 1"
                            onClick={handleShowSearch1Click.bind(this)}
                            value={selectedDeputyNameEmail1}
                            // title={selectedDeputyNameEmail1}
                            disabled
                            InputProps={{ readOnly: true }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={1}>
                          <IconButton
                            onClick={handleShowSearch1Click.bind(this)}
                            title="Change User"
                          >
                            <EditIcon />
                          </IconButton>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={1}>
                          <IconButton
                            onClick={handleRemoveUser1Click.bind(this)}
                            title="Remove User"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </GridItem>
                      </GridContainer>
                      <br />
                    </div>
                    <div style={{ display: showDeputySearch1 ? 'block' : 'none' }}>
                      <UserSearch user_type="admin" />
                    </div>
                    <hr />
                    <div style={{ display: !showDeputySearch2 ? 'block' : 'none' }}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={10}>
                          <TextField
                            required={false}
                            fullWidth
                            label="Deputy User 2"
                            onClick={handleShowSearch2Click.bind(this)}
                            value={selectedDeputyNameEmail2}
                            disabled
                            InputProps={{ readOnly: true }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={1}>
                          <IconButton
                            onClick={handleShowSearch2Click.bind(this)}
                            title="Change User"
                          >
                            <EditIcon />
                          </IconButton>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={1}>
                          <IconButton
                            onClick={handleRemoveUser2Click.bind(this)}
                            title="Remove User"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </GridItem>
                      </GridContainer>
                      <br />
                    </div>
                    <div style={{ display: showDeputySearch2 ? 'block' : 'none' }}>
                      <UserSearch user_type="admin" />
                    </div>
                  </div>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <InputLabel id="allowOnholdLabel" shrink>Allow Application Onhold</InputLabel>
                      <Grid item component="label" container alignItems="center" spacing={1}>
                        <Grid item>No</Grid>
                        <Grid item>
                          <Switch
                            labelid="allowOnholdLabel"
                            checked={allowAppOnhold}
                            onChange={onAllowAppOnholdChange.bind(this)}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled={fieldDisabled}
                            classes={{
                              switchBase: props.classes.switchBase,
                              checked: props.classes.switchChecked,
                            }}
                          />
                        </Grid>
                        <Grid item>Yes</Grid>
                      </Grid>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <InputLabel id="allowEditLabel" shrink>Allow Application Edit</InputLabel>
                      <Grid item component="label" container alignItems="center" spacing={1}>
                        <Grid item>No</Grid>
                        <Grid item>
                          <Switch
                            labelid="allowEditLabel"
                            checked={allowAppEdit}
                            onChange={onAllowAppEditChange.bind(this)}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled={fieldDisabled}
                            classes={{
                              switchBase: props.classes.switchBase,
                              checked: props.classes.switchChecked,
                            }}
                          />
                        </Grid>
                        <Grid item>Yes</Grid>
                      </Grid>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <InputLabel id="allowSendRemindLabel" shrink>Allow Send Reminder</InputLabel>
                      <Grid item component="label" container alignItems="center" spacing={1}>
                        <Grid item>No</Grid>
                        <Grid item>
                          <Switch
                            labelid="allowSendRemindLabel"
                            checked={allowSendRemind}
                            onChange={onAllowSendRemindChange.bind(this)}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled={fieldDisabled}
                            classes={{
                              switchBase: props.classes.switchBase,
                              checked: props.classes.switchChecked,
                            }}
                          />
                        </Grid>
                        <Grid item>Yes</Grid>
                      </Grid>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <InputLabel id="hideMenuLabel" shrink>Hide Menu</InputLabel>
                      <Grid item component="label" container alignItems="center" spacing={1}>
                        <Grid item>No</Grid>
                        <Grid item>
                          <Switch
                            labelid="hideMenuLabel"
                            checked={hideMenu}
                            onChange={onHideMenuChange.bind(this)}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled={fieldDisabled}
                            classes={{
                              switchBase: props.classes.switchBase,
                              checked: props.classes.switchChecked,
                            }}
                          />
                        </Grid>
                        <Grid item>Yes</Grid>
                      </Grid>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <InputLabel id="showmsgsinstatusLabel" shrink>Show Messages With Status</InputLabel>
                      <Grid item component="label" container alignItems="center" spacing={1}>
                        <Grid item>No</Grid>
                        <Grid item>
                          <Switch
                            labelid="showmsgsinstatusLabel"
                            checked={showMsgsInStatus}
                            onChange={onShowMsgsInStatusChange.bind(this)}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled={fieldDisabled}
                            classes={{
                              switchBase: props.classes.switchBase,
                              checked: props.classes.switchChecked,
                            }}
                          />
                        </Grid>
                        <Grid item>Yes</Grid>
                      </Grid>
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter style={{ textAlign: 'left' }} />
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose.bind(this)} color="primary" round>
                Cancel
              </Button>
              <Button
                onClick={handleDialogConfirm.bind(this)}
                color="primary"
                round
                disabled={message === '' && dialogButton !== 'Save' && parseInt(localStorage.getItem('workflow_state'), 10) > 0}
              >
                {dialogButton}
              </Button>
            </DialogActions>
          </Dialog>
        </CardBody>
      </Card>
    </div>
  );
};
export default withStyles(extendedFormsStyle)(RolesComp);
