import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter.jsx';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.jsx';
import withStyles from "@material-ui/core/styles/withStyles";
import extendedFormsStyle from "assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx";

const ItemIntgComp = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogButton, setDialogButton] = useState('');
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [dialogType, setDialogType] = useState('add');
  const [intgFieldID, setIntgFieldID] = useState('')
  const [intgFieldName, setIntgFieldName] = useState('')
  const [value, setValue] = useState('')
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("")

  const { getItemIntgs, item_id } = props;
  useEffect(() => {
    getItemIntgs(item_id)
  }, [getItemIntgs, item_id])

  const handleEditClick = (e) => {
    setIntgFieldID(e[0]);
    setValue(e[1]);
    setIntgFieldName(e[2]);
    setMessage('');
    setDialogTitle('Edit');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('update');
    setDialogOpen(true);
  };

  useEffect(() => {
    setMessage(props.message)
    if (props.refreshItemIntg) {
      getItemIntgs(item_id)
      setDialogOpen(false);
    }
    var rowItems = [];
    if (props.item_intg_list.length > 0) {
      for (let i = 0; i < props.item_intg_list.length; i++) {
        var colItems = [];
        colItems.push(i + 1);
        colItems.push(props.item_intg_list[i].intg_field_name);
        colItems.push(props.item_intg_list[i].value);
        colItems.push(
          <div>
            <IconButton
              onClick={handleEditClick.bind(this,
                [ props.item_intg_list[i].intg_field_id,
                props.item_intg_list[i].value,
                props.item_intg_list[i].intg_field_name,
                ])}
              title="Edit row"
            >
              {' '}
              <EditIcon />
            </IconButton>
          </div>,
        );
        rowItems.push(colItems);
      }
    }
    setData(rowItems)
  }, [props])

  const columns = [
    "Serial",
    "Field Name",
    "Field Value",
    ""
  ];
  const options = {
    filterType: "dropdown",
    responsive: "standard",
    filter: true,
    selectableRows: "none",
    rowsPerPage: 100,
    selectToolbarPlacement: "none"
  };
  const handleDialogClose = (e) => {
    setDialogOpen(false);
    setMessage('');
  }

  const handleDialogConfirm = (e) => {
    if (dialogType === 'update') {
      props.updateItemIntgFieldValue(props.item_id, {
        intg_field_id: intgFieldID.toString(),
        value: value,
      });
    }
  };
  const onValueChange = (e) => {
    setValue(e.target.value);
  }
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Item Integration Fields</b>
        </CardHeader>
        <CardBody>
          <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
          <MUIDataTable
            data={data}
            columns={columns}
            options={options}
          />
          <Dialog fullWidth open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              <Card>
                <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                  <b>{dialogTitle}</b>
                </CardHeader>
                <CardBody>
                  <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        required={false}
                        fullWidth
                        label="Field Name"
                        value={intgFieldName}
                        //onChange={onEmailChange.bind(this)}
                        disabled={true}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        required={false}
                        fullWidth
                        label="Field Value"
                        value={value}
                        onChange={onValueChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter style={{ textAlign: 'left' }} />
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose.bind(this)} color="primary" round>
                Cancel
              </Button>
              <Button onClick={handleDialogConfirm.bind(this)} color="primary" round>
                {dialogButton}
              </Button>
            </DialogActions>
          </Dialog>
        </CardBody>
      </Card>
    </div >
  );
}
export default withStyles(extendedFormsStyle)(ItemIntgComp)