import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import UserComp from './User/UserComp';
import * as userActions from 'actions/networkadmin/UserActions';
import * as departmentActions from 'actions/networkadmin/DepartmentActions';

const Users = (props) => {
  const { classes } = props;
  const { user, department, defaultStates } = props;
  const { getUsers,
    getUsersCSV,
    createUser,
    updateUser,
    resetPassword,
    deleteUser, } = props.userActions;
  const { getDepartments, } = props.departmentActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <UserComp
        getUsers={getUsers}
        getUsersCSV={getUsersCSV}
        getDepartments={getDepartments}
        createUser={createUser}
        updateUser={updateUser}
        deleteUser={deleteUser}
        resetPassword={resetPassword}
        user_list={user.user_list.list === undefined ? [] : user.user_list.list}
        user_count={user.user_list.count === undefined ? 0 : user.user_list.count}
        user_list_csv={user.user_list_csv === undefined ? [] : user.user_list_csv}
        department_list={department.department_list.list === undefined ? [] : department.department_list.list}
        refreshUser={user.refreshUser}
        requesting={user.requesting}
        message={user.message}
        error_core={user.error_core}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.naUser,
    department: state.naDepartment,
    defaultStates: state.naDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
    departmentActions: bindActionCreators(departmentActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
