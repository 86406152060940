import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as itemActions from 'actions/ItemActions';
import ItemSearchMultiSelectComp from './ItemSearchMultiSelectComp';

const ItemSearchMultiSelect = (props) => {
  const { classes } = props;
  const { item } = props;
  const { 
    SearchItem,
    setSelectedParentItems,
     } = props.itemActions;

  return (
    <ItemSearchMultiSelectComp
      item_relation_level={props.item_relation_level}
      SearchItem={SearchItem}
      setSelectedParentItems={setSelectedParentItems}
      search_level={props.search_level}
      search_item_list={item.search_item_list.list === undefined ? [] : item.search_item_list.list}
      search_item_count={item.search_item_list.count === undefined ? [] : item.search_item_list.count}
      classes={classes}
    />
  );
}

function mapStateToProps(state) {
  return {
    item: state.item,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    itemActions: bindActionCreators(itemActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ItemSearchMultiSelect);
