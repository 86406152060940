import React from 'react';

import Login from 'views/Login.jsx';
import Network from 'views/Network.jsx';
import SetPassword from 'views/SetPassword.jsx';
import LinkAction from 'views/LinkAction.jsx';
import LinkActionExternal from 'views/LinkActionExternal.jsx';
import LinkDetail from 'views/LinkDetail.jsx';
import Register from 'views/Register.jsx';
import ForgotPassword from 'views/ForgotPassword.jsx';
import ChooseWorkflow from 'views/ChooseWorkflow.jsx';
import ChangeWorkflow from 'views/ChangeWorkflow.jsx';

const publicRoutes = [
  {
    path: '/public/network',
    component: <Network />,
  },
  {
    path: '/public/login/:workflow_title/:application_id',
    component: <Login />,
  },
  {
    path: '/public/login/:workflow_title',
    component: <Login />,
  },
  {
    path: '/public/login',
    component: <Login />,
  },
  {
    path: '/public/register',
    component: <Register />,
  },
  {
    path: '/public/forgot-password',
    component: <ForgotPassword />,
  },
  {
    path: '/public/setpassword/:token/:email',
    component: <SetPassword />,
  },
  {
    path: '/public/choose-workflow',
    component: <ChooseWorkflow />,
  },
  {
    path: '/public/change-workflow',
    component: <ChangeWorkflow />,
  },
  {
    path: '/public/link-action/:token',
    component: <LinkAction />,
  },
  {
    path: '/public/link-action-external/:token',
    component: <LinkActionExternal />,
  },
  {
    path: '/public/link-detail/:token',
    component: <LinkDetail />,
  },
];

export default publicRoutes;
