import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import LogoutIcon from '@mui/icons-material/SettingsPower';
import { Link } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import TrackChangesIcon from '@mui/icons-material/TrackChanges';
import { useTranslation } from 'react-i18next';
import Select from '@material-ui/core/Select';
import MenuItem from '@mui/material/MenuItem';
import SubHeader from './SubHeader';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function Header() {
  const classes = useStyles();
  const { t } = useTranslation();

  const [displayChangeWorkflow, setDisplayChangeWorkflow] = useState(true);
  const [lang, setLang] = useState(localStorage.getItem('network_lang'));
  const [showLangOption, setShowLangOption] = useState(false);
  const [langSupports, setLangSupports] = useState([]);

  useEffect(() => {
    const tempLangSupport = JSON.parse(localStorage.getItem('network_lang_support'));

    if (!tempLangSupport) {
      window.location.href = '/public/network';
    }

    setLangSupports(tempLangSupport);

    if (tempLangSupport.length > 1) {
      setShowLangOption(true);
    }

    const isDeputy = localStorage.getItem('is_deputy1') === 'true' || localStorage.getItem('is_deputy2') === 'true';
    setDisplayChangeWorkflow(!isDeputy);
  }, []);

  const handleLogout = () => {
    localStorage.removeItem('token');
    window.location.reload();
  };

  const onLangChange = (e) => {
    const selectedLang = e.target.value;
    setLang(selectedLang);
    localStorage.setItem('network_lang', selectedLang);
    window.location.reload();
  };

  const theme = localStorage.getItem('network_theme');
  const linkChooseWorkflow = '/public/change-workflow';

  const langSupportItems = langSupports.map((langSupport) => (
    <MenuItem key={langSupport.lang_code} id={langSupport.lang_code} value={langSupport.lang_code}>
      {langSupport.lang}
    </MenuItem>
  ));

  return (
    <div className={classes.root}>
      <AppBar
        key="header"
        style={{
          background:
            theme === 'green'
              ? 'linear-gradient(60deg, #3aab77, #186441)'
              : theme === 'pinkred'
                ? 'linear-gradient(60deg, #7d2121, #83000e)'
                : theme === 'mergenta'
                  ? 'linear-gradient(60deg, #82133e, #9b4264)'
                  : 'linear-gradient(60deg, #ab47bc, #8e24aa)',
        }}
        position="static"
      >
        <Toolbar>
          {localStorage.getItem('user_type') === 'EMP' && (
            <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
              <Link to="/list" style={{ color: '#FFF', hover: '#00F' }}>
                <HomeIcon />
              </Link>
            </IconButton>
          )}
          <div className={classes.menuButton}>
            {localStorage.getItem('workflow_image') === '' ? (
              <Avatar>{localStorage.getItem('workflow_name').substring(0, 2).toUpperCase()}</Avatar>
            ) : (
              <Avatar src={localStorage.getItem('workflow_image')} variant="square" />
            )}
          </div>
          <Typography variant="subtitle1" className={classes.title}>
            {localStorage.getItem('network_name')}
            <br />
            {localStorage.getItem('workflow_name')}
          </Typography>
          <div style={{ display: showLangOption ? 'block' : 'none' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Select id="lang" value={lang} onChange={onLangChange} autoWidth>
                {langSupportItems}
              </Select>
              <br />
            </div>
          </div>
          <Typography variant="caption" className={classes.menuButton}>
            {localStorage.getItem('name')} {localStorage.getItem('email')}
            <br />
            {localStorage.getItem('user_rank') === 'NA' ? '' : localStorage.getItem('user_rank')}{' '}
            {localStorage.getItem('user_dept')}
          </Typography>
          {localStorage.getItem('user_type') === 'EMP' && (
            <Link
              to={linkChooseWorkflow}
              title={t('header_switch_workflow')}
              style={{ display: displayChangeWorkflow ? 'block' : 'none' }}
            >
              <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                <TrackChangesIcon style={{ color: '#12f9b9', paddingLeft: '1px' }} />
              </IconButton>
            </Link>
          )}
          <IconButton edge="start" onClick={handleLogout} className={classes.menuButton} color="inherit" aria-label="menu" title={t('header_logout')}>
            <LogoutIcon style={{ color: '#e30c00', paddingLeft: '1px' }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      {localStorage.getItem('user_type') === 'EMP' && <SubHeader key="subHeader" />}
    </div>
  );
}
