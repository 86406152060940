import React, { useState, useEffect } from 'react';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx';
import Chip from '@material-ui/core/Chip';

const AggregateRuleComp = (props) => {
  const [aggregateType, setAggregateType] = useState('');
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);


  useEffect(() => {
    setFieldDisabled(props.fieldDisabled);
  }, []);

  useEffect(() => {
    setSelectedFields(props.field_aggregate_rule.field_ids.map((v) => parseInt(v, 10)));
    setAggregateType(props.field_aggregate_rule.type);
  }, [props]);

  const onAggregateTypeChange = (e) => {
    setAggregateType(e.target.value);
    props.setAggregateRule({ type: e.target.value, field_ids: selectedFields.map(String) });
  };
  const onHandleFieldChange = (e) => {
    setSelectedFields(e.target.value);
    props.setAggregateRule({ type: aggregateType, field_ids: e.target.value.map((e) => e.toString()) });
  };
  const { classes } = props;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  return (
    <div>
      <GridContainer key="new option">
        <GridItem xs={12} sm={12} md={4}>
          <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled}>
            <InputLabel id="typeLabel">Type</InputLabel>
            <Select
              labelId="typeLabel"
              id="typeSelect"
              value={aggregateType}
              onChange={onAggregateTypeChange.bind(this)}
            >
              <MenuItem key="SUM" value="SUM"> Sum </MenuItem>
              <MenuItem key="AVG" value="AVG"> Average </MenuItem>
              <MenuItem key="MUL" value="MUL"> Multiply </MenuItem>
              <MenuItem key="MINUS" value="MINUS"> Minus </MenuItem>
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={8}>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel id="fieldlabel">Fields</InputLabel>
            {props.num_fields != undefined ?
              <Select
                labelId="fieldlabel"
                id="fieldsselect"
                multiple
                value={selectedFields}
                onChange={onHandleFieldChange.bind(this)}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={
                          `${props.num_fields.find((element) => element.id === value).form_name} -> ${props.num_fields.find((element) => element.id === value).label}`
                        }
                        style={{ display: 'flex', flexWrap: 'wrap' }}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {props.num_fields.map((field) => (
                  <MenuItem key={field.id} value={field.id}>
                    {`${field.form_name} -> ${field.label}`}
                  </MenuItem>
                ))}
              </Select>
              : ""}
          </FormControl>
        </GridItem>
      </GridContainer>
    </div>
  );
};
export default withStyles(extendedFormsStyle)(AggregateRuleComp);
