export const GET_UNSENT_ERROR_EMAIL_REQUEST = 'GET_UNSENT_ERROR_EMAIL_REQUEST';
export const GET_UNSENT_ERROR_EMAIL_SUCCESS = 'GET_UNSENT_ERROR_EMAIL_SUCCESS';
export const GET_UNSENT_ERROR_EMAIL_FAILURE = 'GET_UNSENT_ERROR_EMAIL_FAILURE';
export const POST_RESEND_EMAIL_REQUEST = 'POST_RESEND_EMAIL_REQUEST';
export const POST_RESEND_EMAIL_SUCCESS = 'POST_RESEND_EMAIL_SUCCESS';
export const POST_RESEND_EMAIL_FAILURE = 'POST_RESEND_EMAIL_FAILURE';
export const GET_DASHBOARD_REQUEST = 'GET_DASHBOARD_REQUEST';
export const GET_DASHBOARD_SUCCESS = 'GET_DASHBOARD_SUCCESS';
export const GET_DASHBOARD_FAILURE = 'GET_DASHBOARD_FAILURE';
export const POST_NETWORK_BACKUP_REQUEST = 'POST_NETWORK_BACKUP_REQUEST';
export const POST_NETWORK_BACKUP_SUCCESS = 'POST_NETWORK_BACKUP_SUCCESS';
export const POST_NETWORK_BACKUP_FAILURE = 'POST_NETWORK_BACKUP_FAILURE';
export const GET_NETWORK_BACKUP_REQUEST = 'GET_NETWORK_BACKUP_REQUEST';
export const GET_NETWORK_BACKUP_SUCCESS = 'GET_NETWORK_BACKUP_SUCCESS';
export const GET_NETWORK_BACKUP_FAILURE = 'GET_NETWORK_BACKUP_FAILURE';
export const POST_NETWORK_RESTORE_REQUEST = 'POST_NETWORK_RESTORE_REQUEST';
export const POST_NETWORK_RESTORE_SUCCESS = 'POST_NETWORK_RESTORE_SUCCESS';
export const POST_NETWORK_RESTORE_FAILURE = 'POST_NETWORK_RESTORE_FAILURE';
export const GET_SENT_EMAIL_REQUEST = 'GET_SENT_EMAIL_REQUEST';
export const GET_SENT_EMAIL_SUCCESS = 'GET_SENT_EMAIL_SUCCESS';
export const GET_SENT_EMAIL_FAILURE = 'GET_SENT_EMAIL_FAILURE';