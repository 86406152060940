// import React from "react";
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { Link } from 'react-router-dom';
import { onRefresh } from 'actions/admin/LoginActions';

import footerStyle from 'assets/jss/material-dashboard-react/components/footerStyle';

function Footer({ ...props }) {
  const { classes, fluid, white /* rtlActive */ } = props;
  var container = cx({
    [classes.container]: !fluid,
    [classes.containerFluid]: fluid,
    [classes.whiteColor]: white,
  });
  /*
  var anchor =
    classes.a +
    cx({
      [" " + classes.whiteColor]: white
    });
  var block = cx({
    [classes.block]: true,
    [classes.whiteColor]: white
  });
  */
  return (
    <footer className={classes.footer}>
      <div className={container}>
        <div className={classes.left}>
          <List >
            <ListItem className={classes.inlineBlock}>
              <span>
                <a target='_blank' rel="noreferrer" href={'http://cogentproc.com'} className={classes.a} >
                  Optimizing Business Process Automation
                </a>
              </span>
            </ListItem>
          </List>
        </div>
        <p className={classes.right}>
          <span>
            <a target='_blank' rel="noreferrer" href={'http://cogentproc.com'} className={classes.a} >
              &copy;
              {' Copyright '}
              {1900 + new Date().getYear()}
              {' CogentProc - All Rights Reserved'}
            </a>
          </span>
        </p>
        <IntervalRefresh />
      </div>
    </footer>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
  fluid: PropTypes.bool,
  white: PropTypes.bool,
  rtlActive: PropTypes.bool,
};

export default withStyles(footerStyle)(Footer);

const IntervalRefresh = () => {
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  var loginTime = parseInt(localStorage.getItem('login_time'), 10);
  var unixTime = Math.floor(Date.now() / 1000);
  // eslint-disable-next-line use-isnan
  if (loginTime != NaN) {
    if (unixTime > (loginTime + 3595)) {
      localStorage.setItem('login_time', null);
      onRefresh();
    }
  }
  return (<div></div>);
};
