export const GET_PERMCOMM_REQUEST = 'GET_PERMCOMM_REQUEST';
export const GET_PERMCOMM_SUCCESS = 'GET_PERMCOMM_SUCCESS';
export const GET_PERMCOMM_FAILURE = 'GET_PERMCOMM_FAILURE';
export const POST_PERMCOMM_REQUEST = 'POST_PERMCOMM_REQUEST';
export const POST_PERMCOMM_SUCCESS = 'POST_PERMCOMM_SUCCESS';
export const POST_PERMCOMM_FAILURE = 'POST_PERMCOMM_FAILURE';
export const PATCH_PERMCOMM_REQUEST = 'PATCH_PERMCOMM_REQUEST';
export const PATCH_PERMCOMM_SUCCESS = 'PATCH_PERMCOMM_SUCCESS';
export const PATCH_PERMCOMM_FAILURE = 'PATCH_PERMCOMM_FAILURE';
export const DELETE_PERMCOMM_REQUEST = 'DELETE_PERMCOMM_REQUEST';
export const DELETE_PERMCOMM_SUCCESS = 'DELETE_PERMCOMM_SUCCESS';
export const DELETE_PERMCOMM_FAILURE = 'DELETE_PERMCOMM_FAILURE';
export const GET_PERMCOMM_ROLE_REQUEST = 'GET_PERMCOMM_ROLE_REQUEST';
export const GET_PERMCOMM_ROLE_SUCCESS = 'GET_PERMCOMM_ROLE_SUCCESS';
export const GET_PERMCOMM_ROLE_FAILURE = 'GET_PERMCOMM_ROLE_FAILURE';
export const GET_COMM_PERMCOMM_REQUEST = 'GET_COMM_PERMCOMM_REQUEST';
export const GET_COMM_PERMCOMM_SUCCESS = 'GET_COMM_PERMCOMM_SUCCESS';
export const GET_COMM_PERMCOMM_FAILURE = 'GET_COMM_PERMCOMM_FAILURE';
