import React from 'react';

const StateText = (value) => {
  // eslint-disable-next-line default-case
  switch (value) {
    case 0:
      return 'state_label_draft';
    case 1:
      return 'state_label_test';
    case 2:
      return 'state_label_publish';
    case 3:
      return 'state_label_discontinue';
    case 4:
      return 'state_label_archive';
  }
};

export default StateText;
