export const GET_ADMIN_REQUEST = 'GET_ADMIN_REQUEST';
export const GET_ADMIN_SUCCESS = 'GET_ADMIN_SUCCESS';
export const GET_ADMIN_FAILURE = 'GET_ADMIN_FAILURE';
export const POST_ADMIN_REQUEST = 'POST_ADMIN_REQUEST';
export const POST_ADMIN_SUCCESS = 'POST_ADMIN_SUCCESS';
export const POST_ADMIN_FAILURE = 'POST_ADMIN_FAILURE';
export const DELETE_ADMIN_REQUEST = 'DELETE_ADMIN_REQUEST';
export const DELETE_ADMIN_SUCCESS = 'DELETE_ADMIN_SUCCESS';
export const DELETE_ADMIN_FAILURE = 'DELETE_ADMIN_FAILURE';
export const SET_SELECTED_USER = 'SET_SELECTED_USER';
