import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import WorkflowComp from './Workflow/WorkflowComp';
import * as defaultActions from 'actions/networkadmin/DefaultActions';
import * as workflowactions from 'actions/networkadmin/WorkflowActions';

const Workflows = (props) => {
  const { classes } = props;
  const { workflow, defaultStates } = props;
  const {
    getWorkflows,
    createWorkflow,
    updateWorkflow,
    deleteWorkflow,
    exportWorkflow,
    postWorkflowFileUpload,
  } = props.workflowactions;
  const { resetMessage } = props.defaultActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <WorkflowComp
        getWorkflows={getWorkflows}
        resetMessage={resetMessage}
        exportWorkflow={exportWorkflow}
        postWorkflowFileUpload={postWorkflowFileUpload}
        workflow_list={workflow.workflow_list === undefined ? [] : workflow.workflow_list}
        workflow_export_list={workflow.workflow_export_list === undefined ? [] : workflow.workflow_export_list}
        createWorkflow={createWorkflow}
        updateWorkflow={updateWorkflow}
        deleteWorkflow={deleteWorkflow}
        refreshWorkflow={workflow.refreshWorkflow}
        requesting={workflow.requesting}
        message={workflow.message}
        error_core={workflow.error_core}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    workflow: state.naWorkflow,
    defaultStates: state.naDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    workflowactions: bindActionCreators(workflowactions, dispatch),
    defaultActions: bindActionCreators(defaultActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Workflows);
