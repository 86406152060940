export const GET_FIELD_REQUEST = 'GET_FIELD_REQUEST';
export const GET_FIELD_SUCCESS = 'GET_FIELD_SUCCESS';
export const GET_FIELD_FAILURE = 'GET_FIELD_FAILURE';
export const GET_FORM_FIELD_REQUEST = 'GET_FORM_FIELD_REQUEST';
export const GET_FORM_FIELD_SUCCESS = 'GET_FORM_FIELD_SUCCESS';
export const GET_FORM_FIELD_FAILURE = 'GET_FORM_FIELD_FAILURE';
export const POST_FIELD_REQUEST = 'POST_FIELD_REQUEST';
export const POST_FIELD_SUCCESS = 'POST_FIELD_SUCCESS';
export const POST_FIELD_FAILURE = 'POST_FIELD_FAILURE';
export const PATCH_FIELD_REQUEST = 'PATCH_FIELD_REQUEST';
export const PATCH_FIELD_SUCCESS = 'PATCH_FIELD_SUCCESS';
export const PATCH_FIELD_FAILURE = 'PATCH_FIELD_FAILURE';
export const DELETE_FIELD_REQUEST = 'DELETE_FIELD_REQUEST';
export const DELETE_FIELD_SUCCESS = 'DELETE_FIELD_SUCCESS';
export const DELETE_FIELD_FAILURE = 'DELETE_FIELD_FAILURE';
export const SET_OPTIONS = 'SET_OPTIONS';
export const SET_OPTIONS_DEFAULT_VALUE = 'SET_OPTIONS_DEFAULT_VALUE';
export const SET_OPTIONS_INTG_FIELD_ID = 'SET_OPTIONS_INTG_FIELD_ID';
export const SET_FIELD_AGGREGATE_RULE = 'SET_FIELD_AGGREGATE_RULE';
export const SET_FIELD_VALIDATE_RULE = 'SET_FIELD_VALIDATE_RULE';
export const SET_FIELD_REQUIRED_RULE = 'SET_FIELD_REQUIRED_RULE';
export const SET_FIELD_VISIBLE_RULE = 'SET_FIELD_VISIBLE_RULE';
export const SET_DATATABLE_RULE = 'SET_DATATABLE_RULE';
export const GET_USER_INTG_FIELD_REQUEST = 'GET_USER_INTG_FIELD_REQUEST';
export const GET_USER_INTG_FIELD_SUCCESS = 'GET_USER_INTG_FIELD_SUCCESS';
export const GET_USER_INTG_FIELD_FAILURE = 'GET_USER_INTG_FIELD_FAILURE';
export const GET_DEPT_INTG_FIELD_REQUEST = 'GET_DEPT_INTG_FIELD_REQUEST';
export const GET_DEPT_INTG_FIELD_SUCCESS = 'GET_DEPT_INTG_FIELD_SUCCESS';
export const GET_DEPT_INTG_FIELD_FAILURE = 'GET_DEPT_INTG_FIELD_FAILURE';
export const GET_CUST_INTG_FIELD_REQUEST = 'GET_CUST_INTG_FIELD_REQUEST';
export const GET_CUST_INTG_FIELD_SUCCESS = 'GET_CUST_INTG_FIELD_SUCCESS';
export const GET_CUST_INTG_FIELD_FAILURE = 'GET_CUST_INTG_FIELD_FAILURE';
export const GET_ITEM_INTG_FIELD_REQUEST = 'GET_ITEM_INTG_FIELD_REQUEST';
export const GET_ITEM_INTG_FIELD_SUCCESS = 'GET_ITEM_INTG_FIELD_SUCCESS';
export const GET_ITEM_INTG_FIELD_FAILURE = 'GET_ITEM_INTG_FIELD_FAILURE';
export const GET_FIELD_ROLE_REQUEST = 'GET_FIELD_ROLE_REQUEST';
export const GET_FIELD_ROLE_SUCCESS = 'GET_FIELD_ROLE_SUCCESS';
export const GET_FIELD_ROLE_FAILURE = 'GET_FIELD_ROLE_FAILURE';
export const GET_FIELD_DEPT_REQUEST = 'GET_FIELD_DEPT_REQUEST';
export const GET_FIELD_DEPT_SUCCESS = 'GET_FIELD_DEPT_SUCCESS';
export const GET_FIELD_DEPT_FAILURE = 'GET_FIELD_DEPT_FAILURE';
export const GET_FIELD_ADHOC_COMMS_REQUEST = 'GET_FIELD_ADHOC_COMMS_REQUEST';
export const GET_FIELD_ADHOC_COMMS_SUCCESS = 'GET_FIELD_ADHOC_COMMS_SUCCESS';
export const GET_FIELD_ADHOC_COMMS_FAILURE = 'GET_FIELD_ADHOC_COMMS_FAILURE';
export const GET_FIELD_ITEMS_REQUEST = 'GET_FIELD_ITEMS_REQUEST';
export const GET_FIELD_ITEMS_SUCCESS = 'GET_FIELD_ITEMS_SUCCESS';
export const GET_FIELD_ITEMS_FAILURE = 'GET_FIELD_ITEMS_FAILURE';
