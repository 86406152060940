import API from 'Api/api.js';
import { commonBackendCall, getNetworkAdminConfig } from 'Api/common.js';
import {
  GET_WORKFLOW_REQUEST,
  GET_WORKFLOW_SUCCESS,
  GET_WORKFLOW_FAILURE,
  POST_WORKFLOW_REQUEST,
  POST_WORKFLOW_SUCCESS,
  POST_WORKFLOW_FAILURE,
  PATCH_WORKFLOW_REQUEST,
  PATCH_WORKFLOW_SUCCESS,
  PATCH_WORKFLOW_FAILURE,
  DELETE_WORKFLOW_REQUEST,
  DELETE_WORKFLOW_SUCCESS,
  DELETE_WORKFLOW_FAILURE,
  GET_WORKFLOW_EXPORT_REQUEST,
  GET_WORKFLOW_EXPORT_SUCCESS,
  GET_WORKFLOW_EXPORT_FAILURE,
  POST_WORKFLOW_FILE_UPLOAD_REQUEST,
  POST_WORKFLOW_FILE_UPLOAD_SUCCESS,
  POST_WORKFLOW_FILE_UPLOAD_FAILURE,
} from 'constants/networkadmin/Workflow';

export function getFileUploadConfig() {
  const config = {
    headers: {
      Token: localStorage.getItem('networkadmin_token'),
      'content-type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
    }
  };
  return config;
}

export function getWorkflows() {
  return commonBackendCall(
    GET_WORKFLOW_REQUEST,
    GET_WORKFLOW_SUCCESS,
    GET_WORKFLOW_FAILURE,
    API.get('admin/workflows', getNetworkAdminConfig()),
  );
}
export function createWorkflow(obj) {
  return commonBackendCall(
    POST_WORKFLOW_REQUEST,
    POST_WORKFLOW_SUCCESS,
    POST_WORKFLOW_FAILURE,
    API.post('admin/workflows', obj, getNetworkAdminConfig()),
  );
}
export function updateWorkflow(workspaceID, obj) {
  return commonBackendCall(
    PATCH_WORKFLOW_REQUEST,
    PATCH_WORKFLOW_SUCCESS,
    PATCH_WORKFLOW_FAILURE,
    API.patch(`admin/workflows/${workspaceID}`, obj, getNetworkAdminConfig()),
  );
}
export function deleteWorkflow(workspaceID) {
  return commonBackendCall(
    DELETE_WORKFLOW_REQUEST,
    DELETE_WORKFLOW_SUCCESS,
    DELETE_WORKFLOW_FAILURE,
    API.delete(`admin/workflows/${workspaceID}`, getNetworkAdminConfig()),
  );
}

export function exportWorkflow(workspaceID) {
  return commonBackendCall(
    GET_WORKFLOW_EXPORT_REQUEST,
    GET_WORKFLOW_EXPORT_SUCCESS,
    GET_WORKFLOW_EXPORT_FAILURE,
    API.get(`admin/workflows-export/${workspaceID}`, getNetworkAdminConfig()),
  );
}

export function postWorkflowFileUpload(jsonString, workflowID) {
  return (dispatch) => {

    dispatch({
      type: POST_WORKFLOW_FILE_UPLOAD_REQUEST,
      payload: {
        requesting: true,
      },
    });

    var apiCalls = ""
    try {
      apiCalls = JSON.parse(jsonString)
    } catch (error) {
      dispatch({
        type: POST_WORKFLOW_FILE_UPLOAD_FAILURE,
        payload: {
          requesting: false,
          message: "Invalid JSON",
        },
      });
      return;
    }

    if (apiCalls !== "") {
      const apiKey = localStorage.getItem("network_api_key");
      var config = {
        headers: {
          Apikey: apiKey,
          Token: localStorage.getItem('networkadmin_token'),
        },
      };
      //API.post('login-admin', `{"email":"meemwebsolutions+admin@gmail.com", "password":"123456" , "workflow_id":"` + workflowID + `" }`, config)
      API.post('admin/login-as-admin', `{"workflow_id":"` + workflowID + `" }`, config)
        .then((response) => {
          if (response.data.result === 'success') {
            var adminToken = response.data.data.token
            const config = {
              headers: {
                Token: adminToken,
                Apikey: apiKey,
              },
            };
            API.delete("admin/workflows-unload/" + workflowID, config)
              .then((response) => {
                if (response.data.result === 'success') {
                  var keyValue = []
                  var i = 0
                  callBackend(apiCalls, keyValue, config, i)
                }
              })
          }
        })
    }
    dispatch({
      type: POST_WORKFLOW_FILE_UPLOAD_SUCCESS,
      payload: {
        requesting: false,
      },

    });
  };
}

function callBackend(apiCalls, keyValue, config, i) {
  if (typeof apiCalls[i] !== 'undefined') {
    console.log(apiCalls[i])
    console.log(keyValue)
    var apiCall = ""
    if (apiCalls[i].title_var === "") {
      apiCall = API.post(apiCalls[i].path, replaceStringVar(apiCalls[i].body, keyValue), config)
    } else {
      if (apiCalls[i].title_var === "PATCH") {
        apiCall = API.patch(replaceStringVar(apiCalls[i].find_path, keyValue), replaceStringVar(apiCalls[i].body, keyValue), config)
      } else {
        apiCall = API.get((encodeURI(apiCalls[i].find_path + "?title=" + apiCalls[i].title_var)).replaceAll("&", "%26"), config)
      }
    }
    apiCall
      .then((response) => {
        if (response.data.result === 'success') {
          var temp = []
          temp = { "key": apiCalls[i].id_var, "value": response.data.data[apiCalls[i].resp_var] }
          keyValue.push(temp)
          i++
          callBackend(apiCalls, keyValue, config, i)
        }
      })
  }
  return;
}

function replaceStringVar(body, keyValue) {
  var i = body.indexOf("#");
  if (i > -1) {
    var secondIndex = body.indexOf("#", i + 1)
    var bodyPrefix = body.substring(0, i)
    var bodySuffix = body.substring(i + 1).substring(body.substring(i + 1).indexOf("#") + 1)
    var variableName = body.substring(i + 1, secondIndex)
    console.log(variableName)
    var variableValue = keyValue.find(o => o.key === variableName).value
    body = bodyPrefix + variableValue + bodySuffix
    return replaceStringVar(bodyPrefix + variableValue + bodySuffix, keyValue)
  }
  return body

}
