import {
  SEARCH_ITEM_REQUEST,
  SEARCH_ITEM_SUCCESS,
  SEARCH_ITEM_FAILURE,
  FIND_RELATED_ITEM_REQUEST,
  FIND_RELATED_ITEM_SUCCESS,
  FIND_RELATED_ITEM_FAILURE,
  SET_SELECTED_ITEM,
  SET_SELECTED_PARENT_ITEMS,

} from '../constants/Item';

import {
  POST_APPLICATION_SUCCESS,
} from '../constants/ApplicationDetail';

const initialState = {
  requesting: false,
  message: '',
  search_item_list: [],
  search_related_item_list: [],
  selected_parent_items: [],
  selected_item: '',
  selected_item_id: 0,
  field_id: 0,
};

export default function item(state = initialState, action) {
  switch (action.type) {
    case POST_APPLICATION_SUCCESS:
      return {
        ...state,
        message: '',
        search_related_item_list: [],
      };

    case SET_SELECTED_ITEM:
      return {
        ...state,
        message: '',
        selected_item: action.payload.item,
        selected_item_id: action.payload.itemID,
        field_id: action.payload.fieldID,
      };

    case SET_SELECTED_PARENT_ITEMS:
      return {
        ...state,
        message: '',
        selected_parent_items: action.payload.items,
      };

    case SEARCH_ITEM_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
      };

    case SEARCH_ITEM_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        search_item_list: action.payload.data,
      };

    case SEARCH_ITEM_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case FIND_RELATED_ITEM_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        selected_parent_items: []
      };

    case FIND_RELATED_ITEM_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        search_related_item_list: action.payload.data,
      };

    case FIND_RELATED_ITEM_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    default:
      return state;
  }
}
