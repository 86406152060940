import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { TRANSLATIONS_AR } from "./translations/ar/label.js";
import { TRANSLATIONS_EN } from "./translations/en/label.js";


i18n
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    debug: true,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: TRANSLATIONS_EN
      },
      ar: {
        translation: TRANSLATIONS_AR
      }
    }
  });

i18n.changeLanguage(localStorage.getItem('network_lang'));

export default i18n;