import React, { useState } from 'react';

import { connect } from 'react-redux';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Grid from '@material-ui/core/Grid';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { Translation } from 'react-i18next';

const emails = ['username@gmail.com', 'user02@gmail.com'];

const SubHeader = (props) => {
  // const { classes } = props;
  const { application, applicationDetail, applicationStatus } = props;
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleDialogClose = (e) => {
    setDialogOpen(false);
  };
  const roleDeputy = (e) => {
    if ((localStorage.getItem('is_deputy1') !== 'true' && localStorage.getItem('is_deputy2') !== 'true') && (applicationStatus.role.deputy_user_id1 > 0 || applicationStatus.role.deputy_user_id2 > 0)) {
      setDialogOpen(true);
    }
  };

  var subHeaderUI = [];
  if (!applicationDetail.application_detail_form.role_hide_menu) {
    if (application.application !== '') {
      var date = new Date(application.application.start_date);
      subHeaderUI.push(
        <AppBar key="subHeaderComp" style={{ background: application.application.application_closed ? '#c0c0c0' : '#FFFFF0' }} position="static">
          <Toolbar>
            <Grid container style={{ justifyContent: 'center' }} spacing={10}>
              <Grid key={1} item style={{ width: '100%' }}>
                <GridContainer style={{ width: '100%' }}>
                  <GridItem xs={12} sm={12} md={2}>
                    <Typography color="primary" variant="body2" align="left" style={{ fontWeight: 'bold' }}>
                      {application.application.user_name}
                    </Typography>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <Typography color="primary" variant="body2" align="right" >
                      <Translation>{(t, { i18n }) => t('app_sub_head_started_on')}</Translation>
                    </Typography>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <Typography color="primary" variant="body2" align="left" >
                      {date.toLocaleString(localStorage.getItem('network_lang'), { weekday: "short", year: "numeric", month: "2-digit", day: "numeric", hour: "2-digit", minute: "2-digit" })}
                    </Typography>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <Typography color="primary" variant="body2" align="right" style={{ fontWeight: 'bold' }}>
                      <Translation>{(t, { i18n }) => t('app_sub_head_your_role')}</Translation>
                    </Typography>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <Typography onClick={roleDeputy} color="primary" variant="body2" align="left" >
                      {`${applicationDetail.headerRoleTitle}`}
                      {' '}
                      {localStorage.getItem('is_deputy1') === 'true' ? ' (Deputy1)' : ''}
                      {' '}
                      {localStorage.getItem('is_deputy2') === 'true' ? ' (Deputy2)' : ''}
                      {' '}
                      {(localStorage.getItem('is_deputy1') !== 'true' && localStorage.getItem('is_deputy2') !== 'true') && (applicationStatus.role.deputy_user_id1 > 0 || applicationStatus.role.deputy_user_id2 > 0) ? " (Deputies of this Role)"/*<PersonIcon style={{ paddingBottom: '5px', paddingTop: '5px' }} /> */ : ""}
                    </Typography>
                  </GridItem>
                </GridContainer>
                <GridContainer style={{ width: '100%' }}>
                  <GridItem xs={12} sm={12} md={2}>
                    <Typography color="primary" variant="body2" align="left" style={{ fontWeight: 'bold' }}>
                      {application.application.user_rank === 'NA' ? '' : application.application.user_rank} {' '} {application.application.user_dept}
                    </Typography>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <Typography color="primary" variant="body2" align="right" style={{ fontWeight: 'bold' }}>
                      <Translation>{(t, { i18n }) => t('app_sub_head_title')}</Translation>
                    </Typography>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <Typography color="primary" variant="body2" align="left" >
                      {`${application.application.app_name}`}
                    </Typography>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <Typography color="primary" variant="body2" align="right" >
                      {applicationDetail.headerTaskTitle !== ''
                        ? <Translation>{(t, { i18n }) => t('app_sub_head_your_task')}</Translation> : ''}
                    </Typography>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={3}>
                    <Typography color="primary" variant="body2" align="left" >
                      {applicationDetail.headerTaskTitle !== ''
                        ? applicationDetail.headerTaskTitle : ''}
                    </Typography>
                  </GridItem>
                </GridContainer>
                <div style={{ display: application.application.app_desc.length > 1 ? 'block' : 'none' }}>
                  <GridContainer style={{ width: '100%' }}>
                    <GridItem xs={12} sm={12} md={12}>
                      <Typography color="primary" variant="body2" align="left" >
                        {`${application.application.app_desc}`}
                      </Typography>
                    </GridItem>
                  </GridContainer>
                </div>
                <div style={{ display: application.application.status > 0 ? 'block' : 'none' }}>
                  <GridContainer style={{ width: '100%' }}>
                    <GridItem xs={12} sm={12} md={12}>
                      {application.application.status > 1 ?
                        <Typography style={{ color: 'red' }} variant="body2" align="center" >
                          ON-HOLD (Undelivered Email) <ReportProblemIcon style={{ color: '#b21c0e' }} />
                        </Typography>
                        :
                        <Typography style={{ color: 'red' }} variant="body2" align="center" >
                          ON-HOLD
                        </Typography>
                      }
                    </GridItem>
                  </GridContainer>
                </div>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>,
      );
    }
  }

  return (
    <div>
      <div>
        {subHeaderUI}
      </div>
      <Dialog onClose={handleDialogClose} open={dialogOpen}>
        {applicationStatus.role.deputy_user_id1 > 0 && applicationStatus.role.deputy_user_id2 > 0 ?
          <DialogTitle>Role Deputies</DialogTitle> :
          <DialogTitle>Role Deputy</DialogTitle>
        }
        <List sx={{ pt: 0 }}>

          {applicationStatus.role.deputy_user_id1 > 0 ? <ListItem button key={applicationStatus.role.deputy_user_email1}> <ListItemText primary={applicationStatus.role.deputy_user_name1 + " (" + applicationStatus.role.deputy_user_email1 + ")"} /> </ListItem> : ""}
          {applicationStatus.role.deputy_user_id2 > 0 ? <ListItem button key={applicationStatus.role.deputy_user_email2}> <ListItemText primary={applicationStatus.role.deputy_user_name2 + " (" + applicationStatus.role.deputy_user_email2 + ")"} /> </ListItem> : ""}
        </List>
      </Dialog>
    </div>
  );

}

const mapStateToProps = (state) => ({
  application: state.application,
  applicationDetail: state.applicationDetail,
  applicationStatus: state.applicationStatus,
});
export default connect(mapStateToProps)(SubHeader);
