import API from 'Api/api.js';
import { commonBackendCall, getAdminConfig } from 'Api/common.js';
import {
  GET_NAMEEXCLUSION_REQUEST,
  GET_NAMEEXCLUSION_SUCCESS,
  GET_NAMEEXCLUSION_FAILURE,
  POST_NAMEEXCLUSION_REQUEST,
  POST_NAMEEXCLUSION_SUCCESS,
  POST_NAMEEXCLUSION_FAILURE,
  PATCH_NAMEEXCLUSION_REQUEST,
  PATCH_NAMEEXCLUSION_SUCCESS,
  PATCH_NAMEEXCLUSION_FAILURE,
  DELETE_NAMEEXCLUSION_REQUEST,
  DELETE_NAMEEXCLUSION_SUCCESS,
  DELETE_NAMEEXCLUSION_FAILURE,
} from 'constants/admin/NameExclusion';

export function getNameExclusions() {
  return commonBackendCall(
    GET_NAMEEXCLUSION_REQUEST,
    GET_NAMEEXCLUSION_SUCCESS,
    GET_NAMEEXCLUSION_FAILURE,
    API.get('admin/name-exclusions', getAdminConfig()),
  );
}
export function createNameExclusion(obj) {
  return commonBackendCall(
    POST_NAMEEXCLUSION_REQUEST,
    POST_NAMEEXCLUSION_SUCCESS,
    POST_NAMEEXCLUSION_FAILURE,
    API.post('admin/name-exclusions', obj, getAdminConfig()),
  );
}
export function updateNameExclusion(nameExclusionID, obj) {
  return commonBackendCall(
    PATCH_NAMEEXCLUSION_REQUEST,
    PATCH_NAMEEXCLUSION_SUCCESS,
    PATCH_NAMEEXCLUSION_FAILURE,
    API.patch(`admin/name-exclusions/${nameExclusionID}`, obj, getAdminConfig()),
  );
}
export function deleteNameExclusion(nameExclusionID) {
  return commonBackendCall(
    DELETE_NAMEEXCLUSION_REQUEST,
    DELETE_NAMEEXCLUSION_SUCCESS,
    DELETE_NAMEEXCLUSION_FAILURE,
    API.delete(`admin/name-exclusions/${nameExclusionID}`, getAdminConfig()),
  );
}
