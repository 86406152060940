import API from 'Api/api.js';
import { commonBackendCall, getAdminConfig } from 'Api/common.js';
import {
  GET_ROLE_REQUEST,
  GET_ROLE_SUCCESS,
  GET_ROLE_FAILURE,
  GET_ADHOC_ROLE_REQUEST,
  GET_ADHOC_ROLE_SUCCESS,
  GET_ADHOC_ROLE_FAILURE,
  POST_ROLE_REQUEST,
  POST_ROLE_SUCCESS,
  POST_ROLE_FAILURE,
  PATCH_ROLE_REQUEST,
  PATCH_ROLE_SUCCESS,
  PATCH_ROLE_FAILURE,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE,
} from 'constants/admin/Role';

export function getRoles() {
  return commonBackendCall(
    GET_ROLE_REQUEST,
    GET_ROLE_SUCCESS,
    GET_ROLE_FAILURE,
    API.get('admin/roles', getAdminConfig()),
  );
}

export function getAdhocRoles() {
  return commonBackendCall(
    GET_ADHOC_ROLE_REQUEST,
    GET_ADHOC_ROLE_SUCCESS,
    GET_ADHOC_ROLE_FAILURE,
    API.get('admin/roles-adhoc', getAdminConfig()),
  );
}

export function createRole(obj) {
  return commonBackendCall(
    POST_ROLE_REQUEST,
    POST_ROLE_SUCCESS,
    POST_ROLE_FAILURE,
    API.post('admin/roles', obj, getAdminConfig()),
  );
}
export function updateRole(roleId, obj) {
  return commonBackendCall(
    PATCH_ROLE_REQUEST,
    PATCH_ROLE_SUCCESS,
    PATCH_ROLE_FAILURE,
    API.patch(`admin/roles/${roleId}`, obj, getAdminConfig()),
  );
}
export function deleteRole(roleId) {
  return commonBackendCall(
    DELETE_ROLE_REQUEST,
    DELETE_ROLE_SUCCESS,
    DELETE_ROLE_FAILURE,
    API.delete(`admin/roles/${roleId}`, getAdminConfig()),
  );
}
