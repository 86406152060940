import API from 'Api/api.js';
import { commonBackendCall, getAdminConfig } from 'Api/common.js';
import {
  GET_CUSTOM_INTG_ADMIN_REQUEST,
  GET_CUSTOM_INTG_ADMIN_SUCCESS,
  GET_CUSTOM_INTG_ADMIN_FAILURE,
} from 'constants/admin/Custom';

export function getCustomIntgs() {
  return commonBackendCall(
    GET_CUSTOM_INTG_ADMIN_REQUEST,
    GET_CUSTOM_INTG_ADMIN_SUCCESS,
    GET_CUSTOM_INTG_ADMIN_FAILURE,
    API.get('admin/custom/intg-admin', getAdminConfig()),
  );
}
