import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import * as permCommActions from 'actions/admin/PermCommActions';
import * as commNameActions from 'actions/admin/CommNameActions';
import * as userActions from 'actions/UserActions';
import * as rolesActions from 'actions/admin/RolesActions';
import * as defaultActions from 'actions/admin/DefaultActions';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Assignment from '@mui/icons-material/Assignment';
import { Link, useParams } from 'react-router-dom';
import Button from 'components/CustomButtons/Button.jsx';
import CommPermCommComp from './CommPermComm/CommPermCommComp';

const PermComm = (props) => {
  const { classes } = props;
  const {
    permComm, commName, user, role, defaultStates,
  } = props;
  const {
    getPermComms,
    createPermComm,
    updatePermComm,
    deletePermComm,
    getPermCommRoles,
    getPermCommFromComm,
  } = props.permCommActions;
  const { resetMessage } = props.defaultActions;
  const { getCommNames } = props.commNameActions;
  const { setSelectedUser } = props.userActions;
  const { id } = useParams();


  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/admin/comm-name">
          <Button size="sm" round color="primary">
            {' '}
            <Assignment />
            {' '}
            Committee
          </Button>
        </Link>
        <Link to="#">
          <Button size="sm" round color="primary" block>
            {' '}
            {permComm.commPermComm_list.title}
          </Button>
        </Link>
      </Breadcrumbs>
      <br />
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <CommPermCommComp
        comm_name_id={id}
        resetMessage={resetMessage}
        getPermComms={getPermComms}
        getPermCommFromComm={getPermCommFromComm}
        getCommNames={getCommNames}
        getPermCommRoles={getPermCommRoles}
        // findUsers={findUsers}
        setSelectedUser={setSelectedUser}
        selected_user_id={user.selected_user_id}
        selected_user={user.selected_user}
        permComm_list={permComm.permComm_list.list === undefined ? [] : permComm.permComm_list.list}
        commName_list={commName.commName_list.list === undefined ? [] : commName.commName_list.list}
        // user_search_list={user.user_list.list === undefined ? [] : user.user_list.list}
        permComm_role_list={permComm.permComm_role_list === undefined ? [] : permComm.permComm_role_list}
        commPermComm_list={permComm.commPermComm_list.permanent_committee === undefined ? [] : permComm.commPermComm_list.permanent_committee}
        commName_title={permComm.commPermComm_list.title}
        createPermComm={createPermComm}
        updatePermComm={updatePermComm}
        deletePermComm={deletePermComm}
        refreshPermComm={permComm.refreshPermComm}
        requesting={permComm.requesting}
        message={permComm.message}
        error_core={permComm.error_core}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    permComm: state.adPermComm,
    commName: state.adCommName,
    user: state.user,
    role: state.adRole,
    defaultStates: state.adDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    permCommActions: bindActionCreators(permCommActions, dispatch),
    commNameActions: bindActionCreators(commNameActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    rolesActions: bindActionCreators(rolesActions, dispatch),
    defaultActions: bindActionCreators(defaultActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PermComm);
