import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom'
import Button from 'components/CustomButtons/Button.jsx';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import BatchLogsComp from './BatchLogs/BatchLogsComp';
import * as integrationActions from 'actions/networkadmin/IntegrationActions';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Assignment from '@mui/icons-material/Assignment';

const BatchLogs = (props) => {

  const { classes } = props;
  const { integration, defaultStates } = props;
  const {
    getBatchLogs,
  } = props.integrationActions;
  const { id } = useParams();

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/networkadmin/batch-data-upload" >
          <Button size="sm" round color="primary"> <Assignment />Batch Data Upload</Button>
        </Link>
        <Link to="#" >
          <Button size="sm" round color="primary" block={true}> </Button>
        </Link>
      </Breadcrumbs>
      <br />
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <BatchLogsComp
        batch_id={id}
        getBatchLogs={getBatchLogs}
        intg_batch_log_list={integration.intg_batch_log_list === undefined ? [] : integration.intg_batch_log_list}
        requesting={integration.requesting}
        message={integration.message}
        error_code={integration.error_code}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    integration: state.naIntegration,
    defaultStates: state.naDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    integrationActions: bindActionCreators(integrationActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(BatchLogs);
