export const GET_FORMCATEGORY_REQUEST = 'GET_FORMCATEGORY_REQUEST';
export const GET_FORMCATEGORY_SUCCESS = 'GET_FORMCATEGORY_SUCCESS';
export const GET_FORMCATEGORY_FAILURE = 'GET_FORMCATEGORY_FAILURE';
export const POST_FORMCATEGORY_REQUEST = 'POST_FORMCATEGORY_REQUEST';
export const POST_FORMCATEGORY_SUCCESS = 'POST_FORMCATEGORY_SUCCESS';
export const POST_FORMCATEGORY_FAILURE = 'POST_FORMCATEGORY_FAILURE';
export const PATCH_FORMCATEGORY_REQUEST = 'PATCH_FORMCATEGORY_REQUEST';
export const PATCH_FORMCATEGORY_SUCCESS = 'PATCH_FORMCATEGORY_SUCCESS';
export const PATCH_FORMCATEGORY_FAILURE = 'PATCH_FORMCATEGORY_FAILURE';
export const DELETE_FORMCATEGORY_REQUEST = 'DELETE_FORMCATEGORY_REQUEST';
export const DELETE_FORMCATEGORY_SUCCESS = 'DELETE_FORMCATEGORY_SUCCESS';
export const DELETE_FORMCATEGORY_FAILURE = 'DELETE_FORMCATEGORY_FAILURE';
