import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import loginPageStyle from 'assets/jss/material-dashboard-react/views/loginPageStyle.jsx';
import { Navigate } from 'react-router-dom';
import * as loginActions from 'actions/LoginActions';
import * as defaultActions from 'actions/DefaultActions';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import ForgotPasswordComp from './ForgotPassword/ForgotPasswordComp';

const ForgotPassword = (props) => {

  const { classes } = props;
  const { login, defaultStates } = props;
  const {
    onForgotPassword,
  } = props.loginActions;

  if (localStorage.getItem('token')) {
    return <Navigate to="/list" />;
  }
  return (
    <div className={classes.content}>
      <div className={classes.container}>
        {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
        <ForgotPasswordComp
          requesting={login.requesting}
          message={login.message}
          forgot_password_success={login.forgot_password_success}
          error_code={login.error_code}
          onForgotPassword={onForgotPassword}
          classes={props}
          loggedIn={login.loggedIn}
        />
      </div>
    </div>
  );
}


function mapStateToProps(state) {
  return {
    login: state.login,
    defaultStates: state.defaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loginActions: bindActionCreators(loginActions, dispatch),
    defaultActions: bindActionCreators(defaultActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(loginPageStyle)(ForgotPassword));
