import React, { useState, useEffect } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Icon from "@material-ui/core/Icon";
import DateRange from "@mui/icons-material/DateRange";
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import Card from "components/Card/Card.jsx";
import CardHeader from "components/Card/CardHeader.jsx";
import CardBody from "components/Card/CardBody.jsx";
import CardIcon from "components/Card/CardIcon.jsx";
import CardFooter from "components/Card/CardFooter.jsx";
import { Link } from 'react-router-dom';
import dashboardStyle from "assets/jss/material-dashboard-react/views/dashboardStyle.jsx";
import Button from 'components/CustomButtons/Button';
import MUIDataTable from "mui-datatables";
import ResetTvIcon from '@mui/icons-material/ResetTv';
import IconButton from '@mui/material/IconButton';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Typography from '@mui/material/Typography';



const DashboardComp = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [textFieldValue, setTextFieldValue] = useState("");
  const [data, setData] = useState([]);
  const [restoreBackup, setRestoreBackup] = useState("");
  const [tagValue, setTagValue] = useState("");


  const { getDashboard, listNetworkBackup } = props;
  useEffect(() => {
    getDashboard();
    listNetworkBackup();
  }, [getDashboard, listNetworkBackup])

  useEffect(() => {
    if (props.refreshNetworkBackupList) {
      setTagValue("");
      listNetworkBackup();
    }
    var rowItems = [];
    if (props.network_backup_list != undefined) {
      var contents = props.network_backup_list
      for (let i = 0; i < contents.length; i++) {
        var prefix = contents[i].prefix
        var colItems = [];
        var completeFileName = contents[i].key.slice(prefix.length, contents[i].key.length)
        var shortFileName = completeFileName.substring(completeFileName.indexOf("-") + 1)
        colItems.push(i + 1);
        colItems.push(shortFileName)
        colItems.push(contents[i].tag)
        colItems.push(bytesToSize(contents[i].size))
        colItems.push(<div>
          <IconButton onClick={handleRestoreClick.bind(this,
            [contents[i].key,
            ])} title="Retore Backup"> <ResetTvIcon /></IconButton>
        </div>);
        rowItems.push(colItems);
      }
    }
    setData(rowItems)
  }, [props])

  function bytesToSize(bytes) {
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']
    if (bytes === 0) return 'n/a'
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10)
    if (i === 0) return `${bytes} ${sizes[i]})`
    return `${(bytes / (1024 ** i)).toFixed(1)} ${sizes[i]}`
  }

  const handleRestoreClick = (e) => {
    setDialogOpen(true)
    setRestoreBackup(e[0])
  }
  const handleClickCreateNetworkBackup = (e) => {
    if (!props.requesting) {
      props.createNetworkBackup({ tag: tagValue });
    }
  }
  const handleDialogClose = (e) => {
    setDialogOpen(false)
  }
  const handleDialogConfirm = (e) => {
    if (textFieldValue === "delete") {
      props.restoreNetworkBackup({ backup: restoreBackup })
    }
  }
  const handleTextValueChange = (e) => {
    setTextFieldValue(e.target.value)
  }
  const onTagValueChange = (e) => {
    setTagValue(e.target.value)
  }
  const columns = [
    "Serial",
    "Backups",
    "Tag",
    "Size",
    ""
  ];
  const options = {
    filterType: "dropdown",
    responsive: "standard",
    filter: true,
    selectableRows: "none",
    rowsPerPage: 1000,
    selectToolbarPlacement: "none"
  };
  const { classes } = props;
  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={6} md={8}>
          <Link to="/networkadmin/network-settings">
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <Icon>account_balance</Icon>
                </CardIcon>
                <h3 className={classes.cardTitle}>
                  {props.dashboard_data.network_name}
                </h3>
                <h3 className={classes.cardTitle}>
                  {props.dashboard_data.network_title}
                </h3>
                <p className={classes.cardCategory}>{props.dashboard_data.network_desc} </p>
                <p></p>
                <h3 className={classes.cardTitle}>
                  {props.dashboard_data.network_status === 1 ? "Active" : "Inactive"}
                </h3>
              </CardHeader>

              <CardFooter stats>
                <div className={classes.stats}>
                  <DateRange />
                  {new Date(props.dashboard_data.network_created_at).toLocaleString(localStorage.getItem('network_lang'), { weekday: "short", year: "numeric", month: "2-digit", day: "numeric", hour: "2-digit", minute: "2-digit" })}
                </div>
              </CardFooter>
            </Card>
          </Link>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Link to="/networkadmin/workflows">
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <Icon>account_tree</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Workflows</p>
                <h3 className={classes.cardTitle}>
                  {props.dashboard_data.num_workflow}
                </h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Icon>approval</Icon>
                  Workflow Limit: {props.dashboard_data.workflow_limit}
                </div>
              </CardFooter>
            </Card>
          </Link>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={6} md={4}>
          <Link to="/networkadmin/users">
            <Card >
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <Icon>emoji_people</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Users</p>
                <h3 className={classes.cardTitle}>{props.dashboard_data.total_user}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                  <Icon>approval</Icon>
                  User Limit: {props.dashboard_data.user_limit}
                </div>
              </CardFooter>
            </Card>
          </Link>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Link to="/networkadmin/department">
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <Icon>grain</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Departments</p>
                <h3 className={classes.cardTitle}>{props.dashboard_data.total_dept}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                </div>
              </CardFooter>
            </Card>
          </Link>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Link to="/networkadmin/item">
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <Icon>bookmarks</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Item</p>
                <h3 className={classes.cardTitle}>{props.dashboard_data.total_item}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                </div>
              </CardFooter>
            </Card>
          </Link>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <Link to="/networkadmin/error-emails">
            <Card>
              <CardHeader color="danger" stats icon>
                <CardIcon color="danger">
                  <Icon>report_problem</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Unsent Email</p>
                <h3 className={classes.cardTitle}>{props.dashboard_data.total_unsent_email}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                </div>
              </CardFooter>
            </Card>
          </Link>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <Link to="/networkadmin/admins">
            <Card>
              <CardHeader color="info" stats icon>
                <CardIcon color="info">
                  <Icon>assignment</Icon>
                </CardIcon>
                <p className={classes.cardCategory}>Admin</p>
                <h3 className={classes.cardTitle}>{props.dashboard_data.total_admin}</h3>
              </CardHeader>
              <CardFooter stats>
                <div className={classes.stats}>
                </div>
              </CardFooter>
            </Card>
          </Link>
        </GridItem>
      </GridContainer>
      {/* */}
      <GridContainer>
        <GridItem xs={12} sm={6} md={12}>
          <Card>
            <CardBody >
              <MUIDataTable
                title=
                {
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div>
                      <Button round color="primary" onClick={handleClickCreateNetworkBackup.bind(this)}
                        style={{ float: 'left' }} disabled={false} > Create Network Backup
                      </Button>
                    </div>
                    <div style={{ marginLeft: '.8rem' }}>
                      <TextField
                        required={false}
                        fullWidth
                        label="Backup File Tag"
                        value={tagValue}
                        onChange={onTagValueChange.bind(this)}
                        disabled={false}
                      />
                    </div>
                  </div>
                }
                data={data}
                columns={columns}
                options={options}
              />
              <br />

            </CardBody>
            <CardFooter stats>
              {props.list_network_backup}
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
      <Dialog fullWidth={true} open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
        <DialogContent>
          <Card>
            <CardHeader color="primary" style={{ textAlign: 'left' }}>
              {"Confirm Restore Network"}
            </CardHeader>
            <CardBody>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Typography style={{ color: 'red', textAlign: 'center' }} variant="button">Restore network will delete existing workflows and applications and it can not be undone.
                  </Typography>
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    required={false}
                    fullWidth={true}
                    label="To confirm restore network, type 'delete'"
                    onChange={handleTextValueChange.bind(this)}
                    value={textFieldValue}
                    disabled={false}
                    InputProps={{ readOnly: false }}
                  />
                </GridItem>
              </GridContainer>
              <br />
            </CardBody>
            <CardFooter style={{ textAlign: 'left' }}>
            </CardFooter>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose.bind(this)} color="primary" round>
            Cancel
          </Button>
          <Button onClick={handleDialogConfirm.bind(this)} color="primary" round
            disabled={textFieldValue === "delete" ? false : true}
          >
            {"Confirm Restore Network"}
          </Button>
        </DialogActions>
      </Dialog>
      {/* */}
    </div >

  );
}
export default withStyles(dashboardStyle)(DashboardComp)