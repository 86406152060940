export const GET_USER_INTG_REQUEST = 'GET_USER_INTG_REQUEST';
export const GET_USER_INTG_SUCCESS = 'GET_USER_INTG_SUCCESS';
export const GET_USER_INTG_FAILURE = 'GET_USER_INTG_FAILURE';
export const GET_CUSTOM_INTG_FIELD_REQUEST = 'GET_CUSTOM_INTG_FIELD_REQUEST';
export const GET_CUSTOM_INTG_FIELD_SUCCESS = 'GET_CUSTOM_INTG_FIELD_SUCCESS';
export const GET_CUSTOM_INTG_FIELD_FAILURE = 'GET_CUSTOM_INTG_FIELD_FAILURE';
export const GET_ITEM_INTG_FIELD_REQUEST = 'GET_ITEM_INTG_FIELD_REQUEST';
export const GET_ITEM_INTG_FIELD_SUCCESS = 'GET_ITEM_INTG_FIELD_SUCCESS';
export const GET_ITEM_INTG_FIELD_FAILURE = 'GET_ITEM_INTG_FIELD_FAILURE';
export const GET_DEPT_INTG_REQUEST = 'GET_DEPT_INTG_REQUEST';
export const GET_DEPT_INTG_SUCCESS = 'GET_DEPT_INTG_SUCCESS';
export const GET_DEPT_INTG_FAILURE = 'GET_DEPT_INTG_FAILURE';
export const POST_INTG_REQUEST = 'POST_INTG_REQUEST';
export const POST_INTG_SUCCESS = 'POST_INTG_SUCCESS';
export const POST_INTG_FAILURE = 'POST_INTG_FAILURE';
export const PATCH_INTG_REQUEST = 'PATCH_INTG_REQUEST';
export const PATCH_INTG_SUCCESS = 'PATCH_INTG_SUCCESS';
export const PATCH_INTG_FAILURE = 'PATCH_INTG_FAILURE';
export const DELETE_INTG_REQUEST = 'DELETE_INTG_REQUEST';
export const DELETE_INTG_SUCCESS = 'DELETE_INTG_SUCCESS';
export const DELETE_INTG_FAILURE = 'DELETE_INTG_FAILURE';
export const GET_BATCH_INTG_REQUEST = 'GET_BATCH_INTG_REQUEST';
export const GET_BATCH_INTG_SUCCESS = 'GET_BATCH_INTG_SUCCESS';
export const GET_BATCH_INTG_FAILURE = 'GET_BATCH_INTG_FAILURE';
export const POST_FILE_UPLOAD_REQUEST = 'POST_FILE_UPLOAD_REQUEST';
export const POST_FILE_UPLOAD_SUCCESS = 'POST_FILE_UPLOAD_SUCCESS';
export const POST_FILE_UPLOAD_FAILURE = 'POST_FILE_UPLOAD_FAILURE';
export const POST_CUSTOM_FILE_UPLOAD_REQUEST = 'POST_CUSTOM_FILE_UPLOAD_REQUEST';
export const POST_CUSTOM_FILE_UPLOAD_SUCCESS = 'POST_CUSTOM_FILE_UPLOAD_SUCCESS';
export const POST_CUSTOM_FILE_UPLOAD_FAILURE = 'POST_CUSTOM_FILE_UPLOAD_FAILURE';
export const POST_ITEM_FILE_UPLOAD_REQUEST = 'POST_ITEM_FILE_UPLOAD_REQUEST';
export const POST_ITEM_FILE_UPLOAD_SUCCESS = 'POST_ITEM_FILE_UPLOAD_SUCCESS';
export const POST_ITEM_FILE_UPLOAD_FAILURE = 'POST_ITEM_FILE_UPLOAD_FAILURE';
export const GET_BATCH_INTG_LOG_REQUEST = 'GET_BATCH_INTG_LOG_REQUEST';
export const GET_BATCH_INTG_LOG_SUCCESS = 'GET_BATCH_INTG_LOG_SUCCESS';
export const GET_BATCH_INTG_LOG_FAILURE = 'GET_BATCH_INTG_LOG_FAILURE';
export const GET_SYS_USER_REQUEST = 'GET_SYS_USER_REQUEST';
export const GET_SYS_USER_SUCCESS = 'GET_SYS_USER_SUCCESS';
export const GET_SYS_USER_FAILURE = 'GET_SYS_USER_FAILURE';
