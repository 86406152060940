import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as applicationDetailActions from 'actions/ApplicationDetailActions';
import AppRolesComp from './AppRoles/AppRolesComp';

const AppRoles = (props) => {
  const { applicationDetail } = props;
  const { getApplicationRoles } = props.applicationDetailActions;
  return (
    <AppRolesComp
      application_id={props.application_id}
      role_id={props.role_id}
      refreshApplicationDetailForm={props.refreshApplicationDetailForm}
      getApplicationRoles={getApplicationRoles}
      application_roles={applicationDetail.application_roles === undefined ? [] : applicationDetail.application_roles}
    />
  );
}

function mapStateToProps(state) {
  return {
    applicationDetail: state.applicationDetail,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    applicationDetailActions: bindActionCreators(applicationDetailActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AppRoles);
