import {
  GET_DEPARTMENT_REQUEST,
  GET_DEPARTMENT_SUCCESS,
  GET_DEPARTMENT_FAILURE,
  GET_DEPT_INTG_REQUEST,
  GET_DEPT_INTG_SUCCESS,
  GET_DEPT_INTG_FAILURE,
} from '../constants/admin/Department';

const initialState = {
  requesting: false,
  message: '',
  department_list: '',
  dept_intg_list: [],
  refreshDepartment: false,
};

export default function department(state = initialState, action) {
  switch (action.type) {
    case GET_DEPARTMENT_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshDepartment: false,
        message: '',
      };

    case GET_DEPARTMENT_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        department_list: action.payload.data,
      };

    case GET_DEPARTMENT_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case GET_DEPT_INTG_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        dept_intg_list: [],
      };

    case GET_DEPT_INTG_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        dept_intg_list: action.payload.data,
      };

    case GET_DEPT_INTG_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    default:
      return state;
  }
}
