import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter.jsx';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import AccountTree from '@mui/icons-material/AccountTree';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from 'components/CustomButtons/Button.jsx';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@mui/material/MenuItem';
import withStyles from "@material-ui/core/styles/withStyles";
import extendedFormsStyle from "assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import UserSearch from 'components/UserSearch/UserSearch.jsx';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ItemSearch from 'components/ItemSearch/ItemSearch.jsx';
import { Link } from 'react-router-dom'
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Error from '@mui/icons-material/Error';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const ItemComp = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogButton, setDialogButton] = useState('');
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [dialogType, setDialogType] = useState('add');
  const [id, setID] = useState('');
  const [name, setName] = useState('');
  const [itemSKU, setItemSKU] = useState('');
  const [relatedItemID, setRelatedItemID] = useState('');
  const [relatedItemName, setRelatedItemName] = useState('');
  const [relation, setRelation] = useState('');
  const [status, setStatus] = useState(1)
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [downloadFile, setDownloadFile] = useState(false)
  const [pageSize, setPageSize] = useState(1000);
  const [pageNum, setPageNum] = useState(1);
  const [orderBy, setOrderBy] = useState('item_name');
  const [orderByDirec, setOrderByDirec] = useState('asc');
  const [searchStr, setSearchStr] = useState('');
  const [currentTab, setCurrentTab] = useState(30)
  const [showItemSearch, setShowItemSearch] = useState(false)

  const navigate = useNavigate();
  const { t } = useTranslation();

  const { getItemsCSV, getItemsWithRelation } = props;
  useEffect(() => {
    getItemsWithRelation(searchStr, pageSize, pageNum, orderBy, orderByDirec, currentTab);
  }, [getItemsCSV, getItemsWithRelation])


  const handleEditClick = (e) => {
    setID(e[0]);
    setItemSKU(e[1]);
    setName(e[2]);
    setStatus(e[3]);
    setRelatedItemID(e[4]);
    setRelatedItemName(e[5]);
    setRelation(e[6]);
    setMessage('');
    setDialogTitle('Edit');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('update');
    setDialogOpen(true);
  };
  const handleDeleteClick = (e) => {
    setID(e[0]);
    setItemSKU(e[1]);
    setName(e[2]);
    setStatus(e[3]);
    setRelatedItemID(e[4]);
    setRelatedItemName(e[5]);
    setRelation(e[6]);
    setMessage('');
    setDialogTitle('Delete');
    setDialogButton('Confirm Delete');
    setFieldDisabled(true);
    setDialogType('delete');
    setDialogOpen(true);
  };
  const handleNewClick = (e) => {
    setItemSKU('');
    setName('');
    setStatus(1);
    setMessage('');
    setDialogTitle('Add New');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('add');
    setDialogOpen(true);
  };

  useEffect(() => {
    setMessage(props.message)
    if (props.item_list_csv.length > 0 && (!props.requesting) && downloadFile) {
      setDownloadFile(false)
      let filename = "ItemData.csv";
      let contentType = "application/csv;charset=utf-8;";
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        var blob = new Blob([decodeURIComponent(props.item_list_csv)], { type: contentType });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        var a = document.createElement('a');
        a.download = filename;
        a.href = 'data:' + contentType + ',' + encodeURIComponent(props.item_list_csv);
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    }
    if (props.refreshItem) {
      props.getItemsWithRelation(searchStr, pageSize, pageNum, orderBy, orderByDirec, currentTab);
      setDialogOpen(false);
    }
    var rowItems = [];
    for (let i = 0; i < props.item_list.length; i++) {
      if (props.item_list[i].name !== "") {
        var colItems = [];
        var link = `/admin/item-intg/${props.item_list[i].id}`;
        colItems.push(props.item_list[i].id);
        colItems.push(props.item_list[i].item_sku);
        colItems.push(props.item_list[i].name);
        colItems.push(props.item_list[i].related_item_name);
        colItems.push(localStorage.getItem('network_item_alias').trim().split(' ')[props.item_list[i].relation - 30]);
        colItems.push(props.item_list[i].status ? "Active" : "Inactive");
        colItems.push(
          <div>
            <IconButton
              onClick={handleEditClick.bind(this,
                [props.item_list[i].id,
                props.item_list[i].item_sku,
                props.item_list[i].name,
                props.item_list[i].status,
                props.item_list[i].related_item_id,
                props.item_list[i].related_item_name,
                props.item_list[i].relation,
                ])}
              title="Edit row"
            >
              {' '}
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={handleDeleteClick.bind(this,
                [props.item_list[i].id,
                props.item_list[i].item_sku,
                props.item_list[i].name,
                props.item_list[i].status,
                props.item_list[i].related_item_id,
                props.item_list[i].related_item_name,
                props.item_list[i].relation,
                ])}
              title="Delete row"
            >
              {' '}
              <DeleteIcon />
            </IconButton>
            <IconButton title="Integration Fields" onClick={() => navigate(`/admin/item-intg/${props.item_list[i].id}`)}><AccountTree /></IconButton>
          </div>,
        );
        rowItems.push(colItems);
      }
    }

    if (props.selected_item !== "" && showItemSearch) {
      setShowItemSearch(false)
      setRelatedItemID(props.selected_item_id)
      setRelatedItemName(props.selected_item)
    }
    setData(rowItems)
  }, [props])

  const handleTabChange = (e, newValue) => {
    setCurrentTab(newValue + 30)
    props.getItemsWithRelation(searchStr, pageSize, 0, orderBy, orderByDirec, newValue + 30)

  }

  const { classes } = props;
  const columns = [
    {
      name: "ID",
      label: "ID",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "Item SKU",
      label: "Item SKU",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "Name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "Related Item",
      label: "Related Item",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "Relation",
      label: "Relation",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "Status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        display: true,
      }
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: true,
      }
    },
  ];
  const options = {
    selectableRowsHideCheckboxes: false,
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: "multiple",
    selectableRowsOnClick: true,
    selectToolbarPlacement: 'below',
    pagination: true,
    print: true,
    filter: false,
    download: false,
    search: true,
    sortFilterList: false,
    viewColumns: true,
    rowsSelected: [],
    serverSide: true,
    count: props.item_count,
    rowsPerPage: pageSize,
    page: pageNum - 1,
    rowsPerPageOptions: [1000, 5000, 10000],
    expandableRowsHeader: true,
    onRowsDelete: (rowsDeleted, newData) => {
      const result = rowsDeleted.data.map(item => { return data.at(item.index) });
      const selectedIDs = result.map(item => { return item[0]; });
      props.deleteSelectedItem(selectedIDs)
    },
    selectableRowsHeader: true,
    onTableChange: (action, tableState) => {
      switch (action) {
        case 'changeRowsPerPage':
          setPageSize(tableState.rowsPerPage)
          setPageNum(1)
          props.getItemsWithRelation(searchStr, tableState.rowsPerPage, 1, orderBy, orderByDirec, currentTab)
          break;
        case 'changePage':
          setPageNum(tableState.page + 1)
          props.getItemsWithRelation(searchStr, pageSize, tableState.page + 1, orderBy, orderByDirec, currentTab)
          break;
        case 'sort':
          console.log(action, tableState);
          console.log(tableState);
          var sortString = 'item_name'
          setOrderByDirec(tableState.sortOrder.direction)
          if (tableState.sortOrder.name === 'Name') {
            setOrderBy('item_name')
            sortString = 'item_name'
          } else if (tableState.sortOrder.name === 'Item SKU') {
            setOrderBy('item_sku')
            sortString = 'item_sku'
          }
          props.getItemsWithRelation(searchStr, pageSize, pageNum, sortString, tableState.sortOrder.direction, currentTab)
          break;
        case 'search':
          setSearchStr(tableState.searchText)
          setPageNum(1)
          props.getItemsWithRelation(tableState.searchText, pageSize, pageNum, orderBy, orderByDirec, currentTab)
          break;
      }
    },
    textLabels: {
      body: {
        noMatch: t('mui_table_no_match'),
        toolTip: t('mui_table_tool_tip'),
        columnHeaderTooltip: column => t('mui_table_header_tool_tip') + " " + column.label
      },
      pagination: {
        next: t('mui_table_next'),
        previous: t('mui_table_previous'),
        rowsPerPage: t('mui_table_rows_per_page'),
        displayRows: t('mui_table_display_rows'),
      },
      toolbar: {
        search: t('mui_table_search'),
        downloadCsv: t('mui_table_download_csv'),
        print: t('mui_table_print'),
        viewColumns: t('mui_table_view_columns'),
        filterTable: t('mui_table_filter_table'),
      },
      filter: {
        all: t('mui_table_filter_all'),
        title: t('mui_table_filter_title'),
        reset: t('mui_table_filter_reset'),
      },
      viewColumns: {
        title: t('mui_table_column_title'),
        titleAria: t('mui_table_column_title_aria'),
      },
      selectedRows: {
        text: t('mui_table_selected_text'),
        delete: t('mui_table_selected_delete'),
        deleteAria: t('mui_table_selected_delete_aria'),
      },
    },
  };


  const onItemSKUChange = (e) => {
    setItemSKU(e.target.value);
  };
  const onNameChange = (e) => {
    setName(e.target.value);
  };
  const onRelationChange = (e) => {
    setRelation(e.target.value);
  };

  const onStatusChange = (e) => {
    setStatus(e.target.checked);
  }
  const handleDialogClose = (e) => {
    setDialogOpen(false);
    setMessage('');
  };

  const handleDialogConfirm = (e) => {
    if (dialogType === 'add') {
      props.createItem({
        item_sku: itemSKU,
        name: name,
        related_item_id: relatedItemID.toString(),
        relation: relation.toString(),
        status: status ? '1' : '0',
      });
    } else if (dialogType === 'update') {
      props.updateItem(id, {
        item_sku: itemSKU,
        name: name,
        related_item_id: relatedItemID.toString(),
        relation: relation.toString(),
        status: status ? '1' : '0',
      });
    } else if (dialogType === 'delete') {
      var tempArray = []
      tempArray.push(id)
      props.deleteSelectedItem(tempArray)
    }
  };
  const handleDownloadCSV = (e) => {
    if (!props.requesting) {
      getItemsCSV();
      setDownloadFile(true)
    }
  }
  const handleRemoveRelatedItemClick = (e) => {
    props.setSelectedItem("", 0)
    setRelatedItemID(0)
    setRelatedItemName("")
  }
  const handleShowSearchClick = (e) => {
    setMessage("")
    setShowItemSearch(true)
  }
  const tabItems = [];
  if (localStorage.getItem('network_item_alias') !== null && localStorage.getItem('network_item_alias') !== "") {
    localStorage.getItem('network_item_alias').trim().split(' ').forEach((item, index) => {
      tabItems.push(
        <Tab label={item} value={index} />
      )
    })
  }
  const dropdownItemAlias = [];
  if (localStorage.getItem('network_item_alias') !== null && localStorage.getItem('network_item_alias') !== "") {
    localStorage.getItem('network_item_alias').trim().split(' ').forEach((item, index) => {
      dropdownItemAlias.push(
        <MenuItem key={item} value={index + 30}>{item}
        </MenuItem>
      )
    })
  }
  var problemList = [];
  props.item_delete_error_list.forEach((problem) => {
    problemList.push(
      <ListItem style={{ color: 'red' }}>
        <ListItemIcon>
          {' '}
          <Error color="error" />
          {' '}
        </ListItemIcon>
        <ListItemText primary={problem} />
      </ListItem>,
    );
  });
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}

      {props.item_delete_error_list.length > 0 ?
        <Card>
          <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
            <b>Item Not Deleted</b>
            <p className={classes.cardCategoryWhite}>
            </p>
          </CardHeader>
          {problemList}
        </Card>
        : ""
      }
      <br />
      <Paper square>
        <Tabs
          value={currentTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange.bind(this)}
          centered
        >
          {tabItems}
        </Tabs>
      </Paper>
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Item</b>
          <p className={classes.cardCategoryWhite}>
          </p>
        </CardHeader>
        <CardBody>
          <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
          <MUIDataTable
            title={<div>
              <Button onClick={handleNewClick.bind(this)} color="primary" title="Create a new item" round> New Item </Button>
              <Button
                onClick={handleDownloadCSV.bind(this)}
                color="primary"
                title="Download CSV"
                round
                disabled={props.requesting}>
                {props.requesting
                  ? <CircularProgress size={20} color="inherit" /> : <CloudDownloadIcon />}
                Download CSV
              </Button>
            </div>}
            data={data}
            columns={columns}
            options={options}
          />
          <Dialog fullWidth maxWidth="lg" open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              <Card>
                <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                  <b>{dialogTitle}</b>
                </CardHeader>
                <CardBody>
                  <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        fullWidth
                        label="ID"
                        value={id}
                        //onChange={onItemSKUChange.bind(this)}
                        disabled={true}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        fullWidth
                        label="Item SKU"
                        value={itemSKU}
                        onChange={onItemSKUChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        fullWidth
                        label="Name"
                        value={name}
                        onChange={onNameChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled || parseInt(localStorage.getItem('workflow_state'), 10) > 0}>
                        <InputLabel id="networkItemAliasSelect">Item Type</InputLabel>
                        <Select
                          labelid="networkItemAliasLable"
                          id="networkItemAliasSelect"
                          value={relation}
                          onChange={onRelationChange.bind(this)}
                        >
                          {dropdownItemAlias}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <InputLabel id="statusLabel" shrink>Status</InputLabel>
                      <Grid item component="label" container alignItems="center" spacing={1}>
                        <Grid item>Inactive</Grid>
                        <Grid item>
                          <Switch
                            labelid="statusLabel"
                            checked={status}
                            onChange={onStatusChange.bind(this)}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled={fieldDisabled}
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                            }}
                          />
                        </Grid>
                        <Grid item>Active</Grid>
                      </Grid>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={10}>
                      <TextField
                        fullWidth
                        label="Related Item"
                        value={relatedItemID + " " + relatedItemName}
                        onClick={handleShowSearchClick.bind(this)}
                        //onChange={onNameChange.bind(this)}
                        disabled={true}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={2}>
                      <Button
                        onClick={handleRemoveRelatedItemClick.bind(this)}
                        color="primary"
                        round
                      >
                        Remove Related Item
                      </Button>
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter style={{ textAlign: 'left' }} />
              </Card>
              <br />
              <div style={{ display: showItemSearch ? "block" : "none" }} >
                <Card>
                  <CardHeader>
                    <b>Search new Related Item </b>
                  </CardHeader>
                  <CardBody>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <ItemSearch
                          item_relation_level={0}
                          field_id={'123'}
                          item_type={'admin'}
                        />
                      </GridItem>
                    </GridContainer>
                  </CardBody>

                </Card>
              </div>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose.bind(this)} color="primary" round>
                Cancel
              </Button>
              <Button onClick={handleDialogConfirm.bind(this)} color="primary" round>
                {dialogButton}
              </Button>
            </DialogActions>
          </Dialog>
        </CardBody>
      </Card>
    </div >
  );
}
export default withStyles(extendedFormsStyle)(ItemComp)
