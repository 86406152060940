import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import loginPageStyle from 'assets/jss/material-dashboard-react/views/loginPageStyle.jsx';
import * as networkActions from 'actions/NetworkActions';
import NetworkComp from './Network/NetworkComp';

const Network = (props) => {
  const { classes } = props;
  const { network } = props;
  const {
    getNetworkByName,
    getNetworkByAPIKey,
  } = props.networkActions;

  if (network.name !== "") {
    let search = window.location.search;
    let params = new URLSearchParams(search);
    let appParam = params.get('app');
    let backURL = "/public/login"
    if (appParam !== null) {
      backURL = "/" + appParam + backURL
    }
    window.location.replace(backURL)
  }
  var envApiKey = process.env.REACT_APP_API_KEY;
  if (envApiKey !== undefined && !network.requesting) {
    getNetworkByAPIKey(envApiKey)
  }
  return (
    <div className={classes.content}>
      <div className={classes.container}>
        <NetworkComp
          requesting={network.requesting}
          message={network.message}
          getNetworkByName={getNetworkByName}
          classes={props}
        />
      </div>
    </div>
  );
}


function mapStateToProps(state) {
  return {
    network: state.network,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    networkActions: bindActionCreators(networkActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(loginPageStyle)(Network));
