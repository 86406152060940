import React, { useState, useEffect, useRef } from 'react';

import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
import cx from 'classnames';
import withStyles from '@material-ui/core/styles/withStyles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Hidden from '@material-ui/core/Hidden';
import Collapse from '@material-ui/core/Collapse';
import sidebarStyle from 'assets/jss/material-dashboard-react/components/sidebarStyle.jsx';
import Avatar from '@mui/material/Avatar';
import { useLocation } from "react-router-dom";

const SidebarWrapper = (props) => {
  const panel = useRef();
  const { className, links } = props;
  const sidebarWrapperRef = useRef();

  return (
    // eslint-disable-next-line react/no-unknown-property
    <div className={className} refs={sidebarWrapperRef}>
      {links}
    </div>
  );
};

const Sidebar = (props) => {
  const [miniActive, setMiniActive] = useState(true);
  const [collapse, setCollapse] = useState(false);

  let location = useLocation();

  const activeRoute = (routeName) => {
    return location.pathname.indexOf(routeName) > -1;
  }

  const openCollapse = () => {
    setCollapse(!collapse)
  }

  const {
    classes,
    color,
    logo,
    image,
    logoText,
    routes,
    bgColor,
    rtlActive,
  } = props;

  const userWrapperClass = `${classes.user
    } ${cx({
      [classes.whiteAfter]: bgColor === 'white',
    })}`;

  var user = ( <div className={userWrapperClass} />);

  var links = (
    <List className={classes.list}>
      {routes.map((prop, key) => {
        if (prop.redirect) {
          return null;
        }
        if (prop.collapse) {
          const navLinkClasses = `${classes.itemLink
            } ${cx({
              [` ${classes.collapseActive}`]: activeRoute(prop.path),
            })}`;
          const itemText = `${classes.itemText
            } ${cx({
              [classes.itemTextMini]:
                props.miniActive && miniActive,
              [classes.itemTextMiniRTL]:
                rtlActive && props.miniActive && miniActive,
              [classes.itemTextRTL]: rtlActive,
            })}`;
          const collapseItemText = `${classes.collapseItemText
            } ${cx({
              [classes.collapseItemTextMini]:
                props.miniActive && miniActive,
              [classes.collapseItemTextMiniRTL]:
                rtlActive && props.miniActive && miniActive,
              [classes.collapseItemTextRTL]: rtlActive,
            })}`;
          const itemIcon = `${classes.itemIcon
            } ${cx({
              [classes.itemIconRTL]: rtlActive,
            })}`;
          const caret = `${classes.caret
            } ${cx({
              [classes.caretRTL]: rtlActive,
            })}`;
          return (
            <ListItem key={key} className={classes.item}>
              <NavLink
                to="#"
                className={navLinkClasses}
                onClick={() => openCollapse()}
              >
                <ListItemIcon className={itemIcon}>
                  <prop.icon />
                </ListItemIcon>
                <ListItemText
                  primary={prop.sidebarName}
                  secondary={(
                    {/*
                    <b
                      className={
                        `${caret
                        } ${this.state[prop.state] ? classes.caretActive : ''}`
                      }
                    />
                    */}
                  )}
                  disableTypography
                  className={itemText}
                />
              </NavLink>
              <Collapse /*in={this.state[prop.state]}  unmountOnExit */>
                <List className={`${classes.list} ${classes.collapseList}`}>
                  {prop.views.map((prop, key) => {
                    if (prop.redirect) {
                      return null;
                    }
                    const navLinkClasses = `${classes.collapseItemLink
                      } ${cx({
                        [` ${classes[color]}`]: activeRoute(prop.path),
                      })}`;
                    const collapseItemMini = `${classes.collapseItemMini
                      } ${cx({
                        [classes.collapseItemMiniRTL]: rtlActive,
                      })}`;
                    return (
                      <ListItem key={key} className={classes.collapseItem}>
                        <NavLink to={prop.path} className={navLinkClasses}>
                          <span className={collapseItemMini}>
                            {prop.mini}
                          </span>
                          <ListItemText
                            primary={prop.sidebarName}
                            disableTypography
                            className={collapseItemText}
                          />
                        </NavLink>
                      </ListItem>
                    );
                  })}
                </List>
              </Collapse>
            </ListItem>
          );
        }
        const navLinkClasses = `${classes.itemLink
          } ${cx({
            [` ${classes[color]}`]: activeRoute(prop.path),
          })}`;
        const itemText = `${classes.itemText
          } ${cx({
            [classes.itemTextMini]:
              props.miniActive && miniActive,
            [classes.itemTextMiniRTL]:
              rtlActive && props.miniActive && miniActive,
            [classes.itemTextRTL]: rtlActive,
          })}`;
        const itemIcon = `${classes.itemIcon
          } ${cx({
            [classes.itemIconRTL]: rtlActive,
          })}`;
        return (
          !prop.hidden
            ? (
              <ListItem key={key} className={classes.item}>
                <NavLink to={prop.path} className={navLinkClasses}>
                  <ListItemIcon className={itemIcon}>
                    <prop.icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={prop.sidebarName}
                    disableTypography
                    className={itemText}
                  />
                </NavLink>
              </ListItem>
            )
            : ''
        );
      })}
    </List>
  );

  const logoNormal = `${classes.logoNormal
    } ${cx({
      [classes.logoNormalSidebarMini]:
        props.miniActive && miniActive,
      [classes.logoNormalSidebarMiniRTL]:
        rtlActive && props.miniActive && miniActive,
      [classes.logoNormalRTL]: rtlActive,
    })}`;
  const logoMini = `${classes.logoMini
    } ${cx({
      [classes.logoMiniRTL]: rtlActive,
    })}`;
  const logoClasses = `${classes.logo
    } ${cx({
      [classes.whiteAfter]: bgColor === 'white',
    })}`;
  const url = localStorage.getItem('network_frontend_url');
  const networkImage = localStorage.getItem('network_image');
  const networkTitle = localStorage.getItem('network_title');
  var brand = (
    <div className={logoClasses}>
      <a href={url} className={logoMini}>
        {networkImage === ''
          ? <Avatar>{(networkTitle).substring(0, 2).toUpperCase()}</Avatar>
          : <Avatar src={networkImage} />}
      </a>
      <a href={url} className={logoNormal}>
        {networkTitle}
        <br />
        {props.logoText}
      </a>

    </div>
  );
  const drawerPaper = `${classes.drawerPaper
    } ${cx({
      [classes.drawerPaperMini]:
        props.miniActive && miniActive,
      [classes.drawerPaperRTL]: rtlActive,
    })}`;
  const sidebarWrapper = `${classes.sidebarWrapper
    } ${cx({
      [classes.drawerPaperMini]:
        props.miniActive && miniActive,
      [classes.sidebarWrapperWithPerfectScrollbar]:
        navigator.platform.indexOf('Win') > -1,
    })}`;
    const mainPanel = useRef();

  return (
    // eslint-disable-next-line react/no-string-refs
    <div ref={mainPanel}>
      <Hidden mdUp>
        <Drawer
          variant="temporary"
          anchor={rtlActive ? 'left' : 'right'}
          open={props.open}
          classes={{
            paper: `${drawerPaper} ${classes[`${bgColor}Background`]}`,
          }}
          onClose={props.handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {brand}
          <SidebarWrapper
            className={sidebarWrapper}
            user={user}
            //headerLinks={<HeaderLinks rtlActive={rtlActive} />}
            links={links}
          />
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: `url(${image})` }}
            />
          ) : null}
        </Drawer>
      </Hidden>
      <Hidden smDown>
        <Drawer
          onMouseOver={() => setMiniActive(false)}
          onMouseOut={() => setMiniActive(true)}
          anchor={rtlActive ? 'right' : 'left'}
          variant="permanent"
          open
          classes={{
            paper: `${drawerPaper} ${classes[`${bgColor}Background`]}`,
          }}
        >
          {brand}
          <SidebarWrapper
            className={sidebarWrapper}
            user={user}
            links={links}
          />
          {image !== undefined ? (
            <div
              className={classes.background}
              style={{ backgroundImage: `url(${image})` }}
            />
          ) : null}
        </Drawer>
      </Hidden>
    </div>
  );
}


Sidebar.defaultProps = {
  bgColor: 'blue',
};

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
  bgColor: PropTypes.oneOf(['white', 'black', 'blue']),
  rtlActive: PropTypes.bool,
  color: PropTypes.oneOf([
    'white',
    'red',
    'orange',
    'green',
    'blue',
    'purple',
    'rose',
  ]),
  logo: PropTypes.string,
  logoText: PropTypes.string,
  image: PropTypes.string,
  routes: PropTypes.arrayOf(PropTypes.object),
};

export default withStyles(sidebarStyle)(Sidebar);
