import API from 'Api/api.js';
import { commonBackendCall, getAdminConfig } from 'Api/common.js';
import {
  GET_COMMNAME_REQUEST,
  GET_COMMNAME_SUCCESS,
  GET_COMMNAME_FAILURE,
  POST_COMMNAME_REQUEST,
  POST_COMMNAME_SUCCESS,
  POST_COMMNAME_FAILURE,
  PATCH_COMMNAME_REQUEST,
  PATCH_COMMNAME_SUCCESS,
  PATCH_COMMNAME_FAILURE,
  DELETE_COMMNAME_REQUEST,
  DELETE_COMMNAME_SUCCESS,
  DELETE_COMMNAME_FAILURE,
  GET_COMMNAME_TASK_REQUEST,
  GET_COMMNAME_TASK_SUCCESS,
  GET_COMMNAME_TASK_FAILURE,
  GET_COMMNAME_PHASE_REQUEST,
  GET_COMMNAME_PHASE_SUCCESS,
  GET_COMMNAME_PHASE_FAILURE,
} from 'constants/admin/CommName';

export function getCommNames() {
  return commonBackendCall(
    GET_COMMNAME_REQUEST,
    GET_COMMNAME_SUCCESS,
    GET_COMMNAME_FAILURE,
    API.get('admin/comm-names', getAdminConfig()),
  );
}
export function createCommName(obj) {
  return commonBackendCall(
    POST_COMMNAME_REQUEST,
    POST_COMMNAME_SUCCESS,
    POST_COMMNAME_FAILURE,
    API.post('admin/comm-names', obj, getAdminConfig()),
  );
}
export function updateCommName(commNameID, obj) {
  return commonBackendCall(
    PATCH_COMMNAME_REQUEST,
    PATCH_COMMNAME_SUCCESS,
    PATCH_COMMNAME_FAILURE,
    API.patch(`admin/comm-names/${commNameID}`, obj, getAdminConfig()),
  );
}
export function deleteCommName(commNameID) {
  return commonBackendCall(
    DELETE_COMMNAME_REQUEST,
    DELETE_COMMNAME_SUCCESS,
    DELETE_COMMNAME_FAILURE,
    API.delete(`admin/comm-names/${commNameID}`, getAdminConfig()),
  );
}
export function getNormalTasks() {
  return commonBackendCall(
    GET_COMMNAME_TASK_REQUEST,
    GET_COMMNAME_TASK_SUCCESS,
    GET_COMMNAME_TASK_FAILURE,
    API.get('admin/normal-tasks', getAdminConfig()),
  );
}
export function getPhases() {
  return commonBackendCall(
    GET_COMMNAME_PHASE_REQUEST,
    GET_COMMNAME_PHASE_SUCCESS,
    GET_COMMNAME_PHASE_FAILURE,
    API.get('admin/phases', getAdminConfig()),
  );
}
