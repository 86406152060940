import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import loginPageStyle from 'assets/jss/material-dashboard-react/views/loginPageStyle.jsx';
import { useParams } from 'react-router-dom';
import * as loginActions from 'actions/LoginActions';
import * as applicationDetailActions from 'actions/ApplicationDetailActions';
import * as defaultActions from 'actions/DefaultActions';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import LinkActionExternalComp from './LinkActionExternal/LinkActionExternalComp';

const LinkActionExternal = (props) => {
  const { token } = useParams();
  const { classes } = props;
  const { login, defaultStates } = props;
  const { onLinkActionExternalGetDetail } = props.loginActions;
  const { postApplicationDetail, postApplicationDetailAction } = props.applicationDetailActions;
  var tokenParam = token
  return (
    <div className={classes.content}>
      <div className={classes.container}>
        {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
        <LinkActionExternalComp
          token={tokenParam}
          requesting={login.requesting}
          postApplicationDetail={postApplicationDetail}
          postApplicationDetailAction={postApplicationDetailAction}
          refreshApplicationDetailForm={login.refreshApplicationDetailForm}
          refreshApplicationDetailAction={login.refreshApplicationDetailAction}
          message={login.message}
          error_code={login.error_code}
          onLinkActionExternalGetDetail={onLinkActionExternalGetDetail}
          application_detail_form={login.link_action_ext_data.task}
          application_id={login.link_action_ext_data.application_id === "" ? 0 : login.link_action_ext_data.application_id}
          action_id={login.link_action_ext_data.action_id === "" ? 0 : login.link_action_ext_data.action_id}
          shortcut_token={login.link_action_ext_data.shortcut_token === "" ? "" : login.link_action_ext_data.shortcut_token}
          user_token={login.link_action_ext_data.user_token === "" ? "" : login.link_action_ext_data.user_token}
          classes={props}
          loggedIn={login.loggedIn}
        />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    login: state.login,
    defaultStates: state.defaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loginActions: bindActionCreators(loginActions, dispatch),
    defaultActions: bindActionCreators(defaultActions, dispatch),
    applicationDetailActions: bindActionCreators(applicationDetailActions, dispatch),

  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(loginPageStyle)(LinkActionExternal));
