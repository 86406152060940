import API from 'Api/api.js';
import { commonBackendCall, getAdminConfig } from 'Api/common.js';
import {
  GET_ACTION_REQUEST,
  GET_ACTION_SUCCESS,
  GET_ACTION_FAILURE,
  GET_ACTION_SHARE_REQUEST,
  GET_ACTION_SHARE_SUCCESS,
  GET_ACTION_SHARE_FAILURE,
  POST_ACTION_REQUEST,
  POST_ACTION_SUCCESS,
  POST_ACTION_FAILURE,
  PATCH_ACTION_REQUEST,
  PATCH_ACTION_SUCCESS,
  PATCH_ACTION_FAILURE,
  DELETE_ACTION_REQUEST,
  DELETE_ACTION_SUCCESS,
  DELETE_ACTION_FAILURE,
  GET_TASK_ACTION_REQUEST,
  GET_TASK_ACTION_SUCCESS,
  GET_TASK_ACTION_FAILURE,
  SET_ACTION_RULE,
} from 'constants/admin/Action';

export function setActionRule(value) {
  return (dispatch) => {
    dispatch({
      type: SET_ACTION_RULE,
      payload: {
        value,
      },
    });
  };
}

export function getTaskActions(taskID) {
  return commonBackendCall(
    GET_TASK_ACTION_REQUEST,
    GET_TASK_ACTION_SUCCESS,
    GET_TASK_ACTION_FAILURE,
    API.get(`admin/actions/tasks/${taskID}`, getAdminConfig()),
  );
}
export function getShareActions() {
  return commonBackendCall(
    GET_ACTION_SHARE_REQUEST,
    GET_ACTION_SHARE_SUCCESS,
    GET_ACTION_SHARE_FAILURE,
    API.get('admin/actions-share', getAdminConfig()),
  );
}
export function getActions() {
  return commonBackendCall(
    GET_ACTION_REQUEST,
    GET_ACTION_SUCCESS,
    GET_ACTION_FAILURE,
    API.get('admin/actions', getAdminConfig()),
  );
}
export function createAction(obj) {
  return commonBackendCall(
    POST_ACTION_REQUEST,
    POST_ACTION_SUCCESS,
    POST_ACTION_FAILURE,
    API.post('admin/actions', obj, getAdminConfig()),
  );
}
export function updateAction(actionID, obj) {
  return commonBackendCall(
    PATCH_ACTION_REQUEST,
    PATCH_ACTION_SUCCESS,
    PATCH_ACTION_FAILURE,
    API.patch(`admin/actions/${actionID}`, obj, getAdminConfig()),
  );
}
export function deleteAction(actionID) {
  return commonBackendCall(
    DELETE_ACTION_REQUEST,
    DELETE_ACTION_SUCCESS,
    DELETE_ACTION_FAILURE,
    API.delete(`admin/actions/${actionID}`, getAdminConfig()),
  );
}
