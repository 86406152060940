import {
  GET_ACTIONTYPE_REQUEST,
  GET_ACTIONTYPE_SUCCESS,
  GET_ACTIONTYPE_FAILURE,
} from '../constants/admin/ActionType';

const initialState = {
  requesting: false,
  message: '',
  actionType_list: '',
  refreshActionType: false,
};

export default function actionType(state = initialState, action) {
  switch (action.type) {
    case GET_ACTIONTYPE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshActionType: false,
        message: '',
      };

    case GET_ACTIONTYPE_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        actionType_list: action.payload.data,
      };

    case GET_ACTIONTYPE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    default:
      return state;
  }
}
