/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.jsx';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import AirplayIcon from '@mui/icons-material/Airplay';
import { DefaultEditor } from 'react-simple-wysiwyg';
import { Navigate } from 'react-router-dom';
import ItemSearch from 'components/ItemSearch/ItemSearch.jsx';


import {
  TEXT_FIELD,
  TEXT_AREA,
  DROP_DOWN,
  RADIO_BUTTON,
  CHECK_BOX,
  DATE,
  TIME,
  HELPTEXT,
  COMMITTEE,
  FILE_SHARING,
  AGGREGATE_NUM,
  WEEK_CALENDAR,
  ITEM_SEARCH,
  ITEM_SEARCH2,
  ITEM_DATATABLE,
  ITEM_TRANSACTIONS,
  ITEM_OPT_DETAILS,
  SYSTEM_DATA,
  TRANS_TEXT_FIELD,
} from 'constants/admin/FieldTypes';

import {
  INTEGER_NUMBER,
  DECIMAL_NUMBER,
  STRING,
  DATE_STRING,
  EMAIL_STRING,
  NONE,
} from 'constants/admin/FieldDataTypes';
import RuleJSONEditComp from './RuleJSONEditComp';
import { TextFieldComp } from './FieldComp/TextFieldComp.jsx';
import { TransTextFieldComp } from './FieldComp/TransTextFieldComp.jsx';
import { SystemDataComp } from './FieldComp/SystemDataComp.jsx';
import { TextAreaFieldComp } from './FieldComp/TextAreaFieldComp.jsx';
import { DropRadioCheckFieldComp } from './FieldComp/DropRadioCheckFieldComp.jsx';
import { DateTimeFieldComp } from './FieldComp/DateTimeFieldComp.jsx';
import { HelpTextFieldComp } from './FieldComp/HelpTextFieldComp.jsx';
import { FileSharingFieldComp } from './FieldComp/FileSharingFieldComp.jsx';
import { AggregateFieldComp } from './FieldComp/AggregateFieldComp.jsx';
import { SearchItemFieldComp } from './FieldComp/SearchItemFieldComp.jsx';
import { SearchItem2FieldComp } from './FieldComp/SearchItem2FieldComp.jsx';
import { ItemTransFieldComp } from './FieldComp/ItemTransFieldComp.jsx';
import { ItemOptionDetailsFieldComp } from './FieldComp/ItemOptionDetailsFieldComp.jsx';

const FieldComp = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogButton, setDialogButton] = useState('');
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [dialogType, setDialogType] = useState('add');
  const [data, setData] = useState([]);
  const [message, setMessage] = useState('');
  const [id, setID] = useState('');


  const [type, setType] = useState('TEXT_FIELD');
  const [name, setName] = useState('');
  const [alias, setAlias] = useState('');
  const [tag, setTag] = useState('');
  const [transType, setTransType] = useState('');
  const [intgFieldID, setIntgFieldID] = useState('');
  const [optionIntgFieldID, setOptionIntgFieldID] = useState('');
  const [label, setLabel] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [status, setStatus] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [defaultValue, setDefaultValue] = useState('');
  const [colspan, setColspan] = useState('6');
  const [appListCustomIndex, setAppListCustomIndex] = useState('0');
  const [required, setRequired] = useState(false);
  const [rows, setRows] = useState(4);
  const [options, setOptions] = useState('');
  const [helpText, setHelpText] = useState('');
  const [departmentID, setDepartmentID] = useState('');
  const [intgType, setIntgType] = useState("0");
  const [itemRelationLevel, setItemRelationLevel] = useState(0);
  const [itemSearchType, setItemSearchType] = useState(0);
  const [searchTable, setSearchTable] = useState(0);
  const [searchTableRelated, setSearchTableRelated] = useState(0);
  const [relatedFieldID, setRelatedFieldID] = useState('');
  const [roleID, setRoleID] = useState('');
  const [committeeNameID, setCommitteeNameID] = useState([]);
  const [aggregateRule, setAggregateRule] = useState('');
  const [requiredRule, setRequiredRule] = useState('');
  const [visibleRule, setVisibileRule] = useState('');
  const [validationRule, setValidationRule] = useState('');
  const [datatableRule, setDatatableRule] = useState('[{"datatable_rule":[]},{ "datatable_rule":[]},{"datatable_rule":[]}]');
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [fieldDataType, setFieldDataType] = useState(NONE);

  const [useAsFormVar, setUseAsFormVar] = useState(false);
  const [selectedFormID, setSelectedFormID] = useState('');
  const [redirectPreview, setRedirectPreview] = useState(false);
  const [showAddBtn, setShowAddBtn] = useState(false);
  const [intgFieldList, setIntgFieldList] = useState([]);

  const {
    getFormFields, getUserIntgFields, getDeptIntgFields,
    getCustIntgFields, getItemIntgFields, getAllDepts, getAllRoles, getAllItems, getForms,
    getNumFields, getAllAdhocComms, form_id,
  } = props;
  useEffect(() => {
    getFormFields(form_id);
    getUserIntgFields();
    getDeptIntgFields();
    getCustIntgFields();
    getItemIntgFields();
    getAllDepts();
    getAllRoles();
    getForms();
    getNumFields();
    getAllAdhocComms();
  }, [getFormFields, getUserIntgFields, getDeptIntgFields,
    getCustIntgFields, getItemIntgFields, getAllDepts, getAllRoles, getAllItems, getForms,
    getNumFields, getAllAdhocComms, form_id]);

  const handlePreviewClick = (e) => {
    setRedirectPreview(true);
    setSelectedFormID(e[0]);
  };
  const handleEditClick = (e) => {
    var localUseAsVar = false;
    if (e[6] !== '') {
      localUseAsVar = true;
    }
    setMessage('');
    setDialogTitle(`Edit field for form: ${props.form_field_list.title}`);
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('update');
    setDialogOpen(true);
    setUseAsFormVar(localUseAsVar);

    setID(e[0]);
    setType(e[2])
    setName(e[3])
    if (e[3] !== "") {
      setUseAsFormVar(true)
    }
    setAlias(e[4])
    setTag(e[5])
    setTransType(e[6])
    setIntgFieldID(e[7])
    setOptionIntgFieldID(e[8])
    setLabel(e[9])
    setSortOrder(e[10])
    setStatus(e[11])
    setErrorMessage(e[12])
    setDefaultValue(e[13])
    setColspan(e[14])
    setRequired(e[15])
    setRows(e[16])
    setOptions(e[17])
    setHelpText(e[18])
    setDepartmentID(e[19])
    setIntgType(e[20])
    setItemRelationLevel(e[21])
    setItemSearchType(e[22])
    setSearchTable(e[23])
    setRelatedFieldID(e[24])
    setRoleID(e[25])
    setCommitteeNameID(e[26])
    setAggregateRule(e[27] === "" ? "" : JSON.parse(e[27]))
    setRequiredRule(e[28])
    setVisibileRule(e[29])
    setValidationRule(e[30])
    setDatatableRule(e[31])
    setIsReadOnly(e[32])
    setFieldDataType(e[33])
    setAppListCustomIndex(e[34])
    setSearchTableRelated(e[35])

    props.setAggregateRule(e[27] === "" ? "" : JSON.parse(e[27]));
    props.setRequiredRule(e[28] === "" ? "" : JSON.parse(e[28]));
    props.setVisibleRule(e[29] === "" ? "" : JSON.parse(e[29]));
    props.setValidateRule(e[30] === "" ? "" : JSON.parse(e[30]))
    props.setDatatableRule(e[31] === "" ? "" : JSON.parse(e[31]));
  };
  const handleDeleteClick = (e) => {
    var localUseAsVar = false;
    if (e[6] !== '') {
      localUseAsVar = true;
    }
    setMessage('');
    setDialogTitle('Delete');
    setDialogButton('Confirm Delete');
    setFieldDisabled(true);
    setDialogType('delete');
    setDialogOpen(true);
    setUseAsFormVar(localUseAsVar);
    setID(e[0]);

    setType(e[2])
    setName(e[3])
    setAlias(e[4])
    setTag(e[5])
    setTransType(e[6])
    setIntgFieldID(e[7])
    setOptionIntgFieldID(e[8])
    setLabel(e[9])
    setSortOrder(e[10])
    setStatus(e[11])
    setErrorMessage(e[12])
    setDefaultValue(e[13])
    setColspan(e[14])
    setRequired(e[15])
    setRows(e[16])
    setOptions(e[17])
    setHelpText(e[18])
    setDepartmentID(e[19])
    setIntgType(e[20])
    setItemRelationLevel(e[21])
    setItemSearchType(e[22])
    setSearchTable(e[23])
    setRelatedFieldID(e[24])
    setRoleID(e[25])
    setCommitteeNameID(e[26])
    setAggregateRule(e[27] === "" ? "" : JSON.parse(e[27]))
    setRequiredRule(e[28])
    setVisibileRule(e[29])
    setValidationRule(e[30])
    setDatatableRule(e[31])
    setIsReadOnly(e[32])
    setFieldDataType(e[33])
    setAppListCustomIndex(e[34])
    setSearchTableRelated(e[35])

    props.setAggregateRule(e[27] === "" ? "" : JSON.parse(e[27]));
    props.setRequiredRule(e[28] === "" ? "" : JSON.parse(e[28]));
    props.setVisibleRule(e[29] === "" ? "" : JSON.parse(e[29]));
    props.setValidateRule(e[30] === "" ? "" : JSON.parse(e[30]))
    props.setDatatableRule(e[31] === "" ? "" : JSON.parse(e[31]));


  };
  const handleNewClick = (e) => {
    const maxValueOfSortOrder = Math.max(...props.form_fields.map((o) => o.sort_order), 0);
    setDialogTitle(`Add new field for form: ${props.form_field_list.title}`);
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('add');
    setDialogOpen(true);
    setUseAsFormVar(false);

    setID("");
    setType("")
    setName("")
    setAlias("")
    setTag("")
    setTransType("")
    setIntgFieldID("")
    setOptionIntgFieldID("[]")
    setLabel("")
    setSortOrder(maxValueOfSortOrder + 5)
    setStatus(true)
    setErrorMessage("")
    setDefaultValue("")
    setColspan("")
    setRequired(false)
    setRows("")
    setOptions("")
    setHelpText("")
    setDepartmentID("")
    setIntgType("")
    setItemRelationLevel("")
    setItemSearchType("")
    setSearchTable("")
    setRelatedFieldID("")
    setRoleID("")
    setCommitteeNameID("")
    setAggregateRule("[]")
    setRequiredRule("[]")
    setVisibileRule("[]")
    setValidationRule('[]')
    setDatatableRule('[{"datatable_rule":[]},{ "datatable_rule":[]},{"datatable_rule":[]}]')
    setIsReadOnly(false)
    setFieldDataType(NONE)
    setAppListCustomIndex('0')
    props.setAggregateRule({ type: 'SUM', field_ids: [] });
    props.setOptionsDefaultValue([]);
    props.setOptions({ option_type: 'admin_defined', intg_field_id: '', options: [] })
    props.setDatatableRule(JSON.parse('[{"datatable_rule":[]},{ "datatable_rule":[]},{"datatable_rule":[]}]'));
  };
  const fieldTypeReadable = (value) => {
    var rtnString = '';
    switch (value) {
      case TEXT_FIELD:
        rtnString = 'Text Field';
        break;
      case TEXT_AREA:
        rtnString = 'Text Area';
        break;
      case DROP_DOWN:
        rtnString = 'Drop Down';
        break;
      case RADIO_BUTTON:
        rtnString = 'Radio Button';
        break;
      case CHECK_BOX:
        rtnString = 'Check Box';
        break;
      case DATE:
        rtnString = 'Date Field';
        break;
      case TIME:
        rtnString = 'Time Field';
        break;
      case HELPTEXT:
        rtnString = 'Help Text';
        break;
      case COMMITTEE:
        rtnString = 'Committee';
        break;
      case ITEM_SEARCH:
        rtnString = 'Item Search';
        break;
      case ITEM_SEARCH2:
        rtnString = 'Item Search 2';
        break;
      case ITEM_TRANSACTIONS:
        rtnString = 'Item Transactions';
        break;
      case ITEM_OPT_DETAILS:
        rtnString = 'Item Option Details';
        break;
      case FILE_SHARING:
        rtnString = 'File Sharing';
        break;
      case AGGREGATE_NUM:
        rtnString = 'Aggregate Number';
        break;
      case WEEK_CALENDAR:
        rtnString = 'Week Calender';
      case SYSTEM_DATA:
        rtnString = 'System Data';
        break;
      case TRANS_TEXT_FIELD:
        rtnString = 'Transaciton Text Field';
        break;
      default:
    }
    return rtnString;
  };
  useEffect(() => {
    setMessage(props.message);
    if (props.refreshField) {
      props.getFormFields(props.form_id);
      setDialogOpen(false);
    }

    if (intgFieldList.length === 0) {
      if (props.field_dept_intg_list.length > 0 && props.field_user_intg_list.length > 0) {
        var arr = [...props.field_dept_intg_list, ...props.field_user_intg_list];
        setIntgFieldList(arr);
      }
    }

    var tempShowAddBtn = props.form_fields.findIndex((element) => element.type === COMMITTEE) === -1;
    setShowAddBtn(tempShowAddBtn);

    var rowItems = [];
    if (props.form_fields.length > 0 && props.form_list.length > 0) {
      for (let i = 0; i < props.form_fields.length; i++) {
        var colItems = [];
        colItems.push(i + 1);
        colItems.push(
          <div style={{ display: 'flex', flexDirection: 'row' }} >
            <div >{fieldTypeReadable(props.form_fields[i].type)}</div>
            <div >
              <IconButton size={'small'}
                onClick={handleEditClick.bind(this,
                  [props.form_fields[i].id,
                  props.form_fields[i].form_id,
                  props.form_fields[i].type,
                  props.form_fields[i].name,
                  props.form_fields[i].alias,
                  props.form_fields[i].tag,
                  props.form_fields[i].trans_type,
                  props.form_fields[i].intg_field_id,
                  props.form_fields[i].option_intg_field_id,
                  props.form_fields[i].label,
                  props.form_fields[i].sort_order,
                  props.form_fields[i].status,
                  props.form_fields[i].error_message,
                  props.form_fields[i].default_value,
                  props.form_fields[i].colspan,
                  props.form_fields[i].required,
                  props.form_fields[i].rows,
                  props.form_fields[i].options,
                  props.form_fields[i].help_text,
                  props.form_fields[i].department_id,
                  props.form_fields[i].intg_type,
                  props.form_fields[i].item_relation_level,
                  props.form_fields[i].item_search_type,
                  props.form_fields[i].search_table,
                  props.form_fields[i].related_field_id,
                  props.form_fields[i].role_id,
                  props.form_fields[i].committee_name_id,
                  props.form_fields[i].aggregate_rule,
                  props.form_fields[i].required_rule,
                  props.form_fields[i].visible_rule,
                  props.form_fields[i].validation_rule,
                  props.form_fields[i].datatable_rule,
                  props.form_fields[i].is_read_only,
                  props.form_fields[i].field_data_type,
                  props.form_fields[i].app_list_custom_index,
                  props.form_fields[i].search_table_related,
                  ])}
                title={`Edit Row ID:${props.form_fields[i].id}`}
              >
                {' '}
                <EditIcon />
              </IconButton>
            </div>

          </div>);
        colItems.push(props.form_fields[i].label);
        colItems.push(props.form_fields[i].alias);
        colItems.push(props.form_fields[i].sort_order);
        colItems.push(props.form_fields[i].required ? 'Yes' : 'No');
        colItems.push(props.form_fields[i].status ? 'Active' : 'Inactive');
        colItems.push(props.form_fields[i].help_text);
        colItems.push(props.form_fields[i].aggregate_rule);
        colItems.push(props.form_fields[i].required_rule);
        colItems.push(props.form_fields[i].visible_rule);
        colItems.push(props.form_fields[i].validation_rule);
        colItems.push(props.form_fields[i].colspan === '' ? 'All 12 columns' : `${props.form_fields[i].colspan} of 12 columns`);
        colItems.push(props.form_fields[i].rows > 0 ? props.form_fields[i].rows : '');
        colItems.push(
          <div>
            <IconButton
              onClick={handleEditClick.bind(this,
                [props.form_fields[i].id,
                props.form_fields[i].form_id,
                props.form_fields[i].type,
                props.form_fields[i].name,
                props.form_fields[i].alias,
                props.form_fields[i].tag,
                props.form_fields[i].trans_type,
                props.form_fields[i].intg_field_id,
                props.form_fields[i].option_intg_field_id,
                props.form_fields[i].label,
                props.form_fields[i].sort_order,
                props.form_fields[i].status,
                props.form_fields[i].error_message,
                props.form_fields[i].default_value,
                props.form_fields[i].colspan,
                props.form_fields[i].required,
                props.form_fields[i].rows,
                props.form_fields[i].options,
                props.form_fields[i].help_text,
                props.form_fields[i].department_id,
                props.form_fields[i].intg_type,
                props.form_fields[i].item_relation_level,
                props.form_fields[i].item_search_type,
                props.form_fields[i].search_table,
                props.form_fields[i].related_field_id,
                props.form_fields[i].role_id,
                props.form_fields[i].committee_name_id,
                props.form_fields[i].aggregate_rule,
                props.form_fields[i].required_rule,
                props.form_fields[i].visible_rule,
                props.form_fields[i].validation_rule,
                props.form_fields[i].datatable_rule,
                props.form_fields[i].is_read_only,
                props.form_fields[i].field_data_type,
                props.form_fields[i].app_list_custom_index,
                props.form_fields[i].search_table_related,
                ])}
              title={`Edit Row ID:${props.form_fields[i].id}`}
            >
              {' '}
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={handleDeleteClick.bind(this,
                [props.form_fields[i].id,
                props.form_fields[i].form_id,
                props.form_fields[i].type,
                props.form_fields[i].name,
                props.form_fields[i].alias,
                props.form_fields[i].tag,
                props.form_fields[i].trans_type,
                props.form_fields[i].intg_field_id,
                props.form_fields[i].option_intg_field_id,
                props.form_fields[i].label,
                props.form_fields[i].sort_order,
                props.form_fields[i].status,
                props.form_fields[i].error_message,
                props.form_fields[i].default_value,
                props.form_fields[i].colspan,
                props.form_fields[i].required,
                props.form_fields[i].rows,
                props.form_fields[i].options,
                props.form_fields[i].help_text,
                props.form_fields[i].department_id,
                props.form_fields[i].intg_type,
                props.form_fields[i].item_relation_level,
                props.form_fields[i].item_search_type,
                props.form_fields[i].search_table,
                props.form_fields[i].related_field_id,
                props.form_fields[i].role_id,
                props.form_fields[i].committee_name_id,
                props.form_fields[i].aggregate_rule,
                props.form_fields[i].required_rule,
                props.form_fields[i].visible_rule,
                props.form_fields[i].validation_rule,
                props.form_fields[i].datatable_rule,
                props.form_fields[i].is_read_only,
                props.form_fields[i].field_data_type,
                props.form_fields[i].app_list_custom_index,
                props.form_fields[i].search_table_related,
                ])}
              title="Delete Row"
            >
              {' '}
              <DeleteIcon />
            </IconButton>
          </div>,
        );
        colItems.push(props.form_fields[i].id);
        rowItems.push(colItems);
      }
    }
    setData(rowItems);
  }, [props]);

  const handleDialogClose = (e) => {
    setDialogOpen(false);
    setMessage('');
  };
  const handleDialogConfirm = (e) => {
    var localName = '';
    if (useAsFormVar) {
      localName = name;
    }

    if (dialogType === 'add') {
      props.createField({
        form_id: props.form_id.toString(),
        type: type,
        name: localName,
        alias: alias,
        tag: tag,
        trans_type: transType,
        intg_field_id: intgFieldID.toString(),
        option_intg_field_id: props.optionsIntgFieldID != undefined ? props.optionsIntgFieldID.toString() : "",
        label: label,
        sort_order: sortOrder.toString(),
        status: status ? '1' : '0',
        error_message: errorMessage,
        default_value: type === DROP_DOWN || type === CHECK_BOX || type === RADIO_BUTTON ? JSON.stringify(props.optionsDefaultValue) : defaultValue,
        colspan: colspan,
        required: required ? '1' : '0',
        rows: rows.toString(),
        options: (type === DROP_DOWN || type === CHECK_BOX || type === RADIO_BUTTON) ? JSON.stringify(props.options) : '',
        help_text: helpText,
        department_id: departmentID.toString(),
        intg_type: intgType.toString(),
        item_relation_level: itemRelationLevel.toString(),
        item_search_type: itemSearchType.toString(),
        search_table: searchTable.toString(),
        search_table_related: searchTableRelated.toString(),
        related_field_id: relatedFieldID.toString(),
        role_id: roleID.toString(),
        aggregate_rule: JSON.stringify(props.field_aggregate_rule),
        required_rule: JSON.stringify(props.field_required_rule),
        visible_rule: JSON.stringify(props.field_visible_rule),
        validation_rule: JSON.stringify(props.field_validate_rule),
        datatable_rule: JSON.stringify(props.field_datatable_rule),
        is_read_only: isReadOnly ? '1' : '0',
        app_list_custom_index: appListCustomIndex.toString(),
        field_data_type: fieldDataType,
      });
    } else if (dialogType === 'update') {
      /**/
      props.updateField(id, {
        form_id: props.form_id.toString(),
        type: type,
        name: localName,
        alias: alias,
        tag: tag,
        trans_type: transType,
        intg_field_id: intgFieldID.toString(),
        option_intg_field_id: props.optionsIntgFieldID != undefined ? props.optionsIntgFieldID.toString() : "",
        label: label,
        sort_order: sortOrder.toString(),
        status: status ? '1' : '0',
        error_message: errorMessage,
        default_value: type === DROP_DOWN || type === CHECK_BOX || type === RADIO_BUTTON ? JSON.stringify(props.optionsDefaultValue) : defaultValue,
        colspan: colspan,
        required: required ? '1' : '0',
        rows: rows.toString(),
        options: (type === DROP_DOWN || type === CHECK_BOX || type === RADIO_BUTTON) ? JSON.stringify(props.options) : '',
        help_text: helpText,
        department_id: departmentID.toString(),
        intg_type: intgType.toString(),
        item_relation_level: itemRelationLevel.toString(),
        item_search_type: itemSearchType.toString(),
        search_table: searchTable.toString(),
        search_table_related: searchTableRelated.toString(),
        related_field_id: relatedFieldID.toString(),
        role_id: roleID.toString(),
        aggregate_rule: JSON.stringify(props.field_aggregate_rule),
        required_rule: JSON.stringify(props.field_required_rule),
        visible_rule: JSON.stringify(props.field_visible_rule),
        validation_rule: JSON.stringify(props.field_validate_rule),
        datatable_rule: JSON.stringify(props.field_datatable_rule),
        is_read_only: isReadOnly ? '1' : '0',
        app_list_custom_index: appListCustomIndex.toString(),
        field_data_type: fieldDataType,
      });
      /**/
    } else if (dialogType === 'delete') {
      props.deleteField(id);
    }
  };

  const onChangeType = (e) => {
    setType(e.target.value);
  };
  const onChangeName = (e) => {
    setName(e.target.value);
  };
  const onChangeAlias = (e) => {
    setAlias(e.target.value);
  };
  const onChangeTag = (e) => {
    setTag(e.target.value);
  };
  const onChangeTransType = (e) => {
    setTransType(e.target.value);
  };
  const onChangeIntgFieldID = (e) => {
    setIntgFieldID(e.target.value);
  };
  const onChangeOptionIntgFieldID = (e) => {
    setOptionIntgFieldID(e.target.value);
  };
  const onChangeLabel = (e) => {
    setLabel(e.target.value);
  };
  const onChangeSortOrder = (e) => {
    var messageL = '';
    var letters = /^[0-9]+$/;
    if (!e.target.value.match(letters)) {
      messageL = 'Invalid Sort Order number';
    }
    setSortOrder(e.target.value);
    setMessage(messageL);
  };
  const onChangeStatus = (e) => {
    setStatus(e.target.checked);
  };
  const onChangeErrorMessage = (e) => {
    setErrorMessage(e.target.value);
  };
  const onChangeDefaultValue = (e) => {
    setDefaultValue(e.target.value);
  };
  const onChangeColspan = (e) => {
    setColspan(e.target.value);
  };
  const onChangeRequired = (e) => {
    setRequired(e.target.checked);
    if (e.target.checked) {
      setIsReadOnly(false);
    }
  };
  const onChangeRows = (e) => {
    setRows(e.target.value);
  };
  const onChangeOptions = (e) => {
    setOptions(e.target.value);
  };
  const onChangeHelpText = (e) => {
    setHelpText(e.target.value);
  };
  const onChangeDepartmentID = (e) => {
    setDepartmentID(e.target.value);
  };
  const onChangeIntgType = (e) => {
    setIntgType(e.target.value);
  };
  const onChangeItemRelationLevel = (e) => {
    setItemRelationLevel(e.target.value);
  };
  const onChangeItemSearchType = (e) => {
    setItemSearchType(e.target.value);
  };
  const onChangeSearchTable = (e) => {
    setSearchTable(e.target.value);
  };
  const onChangeSearchTableRelated = (e) => {
    setSearchTableRelated(e.target.value);
  };
  const onChangeRelatedFieldID = (e) => {
    setRelatedFieldID(e.target.value);
  };
  const onChangeRoleID = (e) => {
    setRoleID(e.target.value);
  };
  const onChangeCommitteeNameID = (e) => {
    setCommitteeNameID(e.target.value);
  };
  const onChangeAggregateRule = (e) => {
    setAggregateRule(e.target.value);
  };
  const onChangeRequiredRule = (e) => {
    setRequiredRule(e.target.value);
  };
  const onChangeVisibileRule = (e) => {
    setVisibileRule(e.target.value);
  };
  const onChangeValidationRule = (e) => {
    setValidationRule(e.target.value);
  };
  const onChangeDatatableRule = (e) => {
    setDatatableRule(e.target.value);
  };
  const onChangeIsReadOnly = (e) => {
    setIsReadOnly(e.target.checked);
    if (e.target.checked) {
      setRequired(false);
    }
  };
  const onChangeFieldDataType = (e) => {
    setFieldDataType(e.target.value);
  };
  const onChangeAppListCustomIndex = (e) => {
    setAppListCustomIndex(e.target.value);
  };

  const onUseAsFormVarChange = (e) => {
    setUseAsFormVar(e.target.checked);
  };
  const { classes } = props;
  const columns = [
    {
      name: 'serial',
      label: 'Serial',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'label',
      label: 'Label',
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'alias',
      label: 'Alias',
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'Sort Order',
      label: 'Sort Order',
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'Required',
      label: 'Required',
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'Status',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'help text',
      label: 'Help Text',
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'Aggregate Rule',
      label: 'Aggregate Rule',
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'Required Rule',
      label: 'Required Rule',
      options: {
        filter: true,
        sort: true,
        display: true,
      },
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            {value}
            <br />
            <br />
            <RuleJSONEditComp
              updateField={props.updateField}
              rule_label={"Required Rule"}
              rule_type={"REQUIRED_RULE"}
              rule_value={value}
              field_id={tableMeta.rowData[15]}
            />
          </div>
        ),
      },
    },

    {
      name: 'Visible Rule',
      label: 'Visible Rule',
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            {value}
            <br />
            <br />
            <RuleJSONEditComp
              updateField={props.updateField}
              rule_label={"Visible Rule"}
              rule_type={"VISIBLE_RULE"}
              rule_value={value}
              field_id={tableMeta.rowData[15]}
            />
          </div>
        ),
      },
    },
    {
      name: 'Validate Rule',
      label: 'Validate Rule',
      options: {
        filter: true,
        sort: true,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            {value}
            <br />
            <br />
            <RuleJSONEditComp
              updateField={props.updateField}
              rule_label={"Validate Rule"}
              rule_type={"VALIDATION_RULE"}
              rule_value={value}
              field_id={tableMeta.rowData[15]}
            />
          </div>
        ),
      },
    },
    {
      name: 'Column Width',
      label: 'Column Width',
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'rows',
      label: 'Rows',
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: '',
      label: '',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
  ];
  const tableOptions = {
    filterType: 'dropdown',
    responsive: 'standard',
    filter: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    selectToolbarPlacement: 'none',
  };
  if (redirectPreview) {
    return <Navigate push to={{ pathname: `/admin/form-preview/${selectedFormID}` }} />;
  }

  const renderFieldTypeSelection = () => {
    switch (type) {
      case TEXT_FIELD:
        return (
          <TextFieldComp
            id={id}
            classes={classes}
            fieldDisabled={fieldDisabled}

            type={type}
            name={name}
            alias={alias}
            tag={tag}
            transType={transType}
            intgFieldID={intgFieldID}
            optionIntgFieldID={optionIntgFieldID}
            label={label}
            sortOrder={sortOrder}
            status={status}
            errorMessage={errorMessage}
            defaultValue={defaultValue}
            colspan={colspan}
            required={required}
            rows={rows}
            options={options}
            helpText={helpText}
            departmentID={departmentID}
            intgType={intgType.toString()}
            itemRelationLevel={itemRelationLevel}
            itemSearchType={itemSearchType}
            searchTable={searchTable}
            relatedFieldID={relatedFieldID}
            roleID={roleID}
            committeeNameID={committeeNameID}
            aggregateRule={aggregateRule}
            requiredRule={requiredRule}
            visibleRule={visibleRule}
            validationRule={validationRule}
            datatableRule={datatableRule}
            isReadOnly={isReadOnly}
            fieldDataType={fieldDataType}
            appListCustomIndex={appListCustomIndex}

            onChangeType={onChangeType}
            onChangeName={onChangeName}
            onChangeAlias={onChangeAlias}
            onChangeTag={onChangeTag}
            onChangeTransType={onChangeTransType}
            onChangeIntgFieldID={onChangeIntgFieldID}
            onChangeOptionIntgFieldID={onChangeOptionIntgFieldID}
            onChangeLabel={onChangeLabel}
            onChangeSortOrder={onChangeSortOrder}
            onChangeStatus={onChangeStatus}
            onChangeErrorMessage={onChangeErrorMessage}
            onChangeDefaultValue={onChangeDefaultValue}
            onChangeColspan={onChangeColspan}
            onChangeRequired={onChangeRequired}
            onChangeRows={onChangeRows}
            onChangeOptions={onChangeOptions}
            onChangeHelpText={onChangeHelpText}
            onChangeDepartmentID={onChangeDepartmentID}
            onChangeIntgType={onChangeIntgType}
            onChangeItemRelationLevel={onChangeItemRelationLevel}
            onChangeItemSearchType={onChangeItemSearchType}
            onChangeSearchTable={onChangeSearchTable}
            onChangeRelatedFieldID={onChangeRelatedFieldID}
            onChangeRoleID={onChangeRoleID}
            onChangeCommitteeNameID={onChangeCommitteeNameID}
            onChangeAggregateRule={onChangeAggregateRule}
            onChangeRequiredRule={onChangeRequiredRule}
            onChangeVisibileRule={onChangeVisibileRule}
            onChangeValidationRule={onChangeValidationRule}
            onChangeDatatableRule={onChangeDatatableRule}
            onChangeIsReadOnly={onChangeIsReadOnly}
            onChangeFieldDataType={onChangeFieldDataType}
            onChangeAppListCustomIndex={onChangeAppListCustomIndex}

            field_data_type={fieldDataType}
            field_validate_rule={validationRule}
            setValidateRule={props.setValidateRule}
            field_required_rule={requiredRule}
            setRequiredRule={props.setRequiredRule}
            field_visible_rule={visibleRule}
            setVisibleRule={props.setVisibleRule}
            fields={props.field_list}
            field_list={props.field_list}
            role_list={props.role_list}
            dept_list={props.dept_list}
            intg_field_List={intgFieldList}
            field_item_intg_list={props.field_item_intg_list}
            useAsFormVar={useAsFormVar}
            onUseAsFormVarChange={onUseAsFormVarChange}
          />
        );
        break;
      case TEXT_AREA:
        return (
          <TextAreaFieldComp
            id={id}
            classes={classes}
            fieldDisabled={fieldDisabled}
            type={type}
            name={name}
            alias={alias}
            tag={tag}
            transType={transType}
            intgFieldID={intgFieldID}
            optionIntgFieldID={optionIntgFieldID}
            label={label}
            sortOrder={sortOrder}
            status={status}
            errorMessage={errorMessage}
            defaultValue={defaultValue}
            colspan={colspan}
            required={required}
            rows={rows}
            options={options}
            helpText={helpText}
            departmentID={departmentID}
            intgType={intgType.toString()}
            itemRelationLevel={itemRelationLevel}
            itemSearchType={itemSearchType}
            searchTable={searchTable}
            relatedFieldID={relatedFieldID}
            roleID={roleID}
            committeeNameID={committeeNameID}
            aggregateRule={aggregateRule}
            requiredRule={requiredRule}
            visibleRule={visibleRule}
            validationRule={validationRule}
            datatableRule={datatableRule}
            isReadOnly={isReadOnly}
            fieldDataType={fieldDataType}
            appListCustomIndex={appListCustomIndex}

            onChangeType={onChangeType}
            onChangeName={onChangeName}
            onChangeAlias={onChangeAlias}
            onChangeTag={onChangeTag}
            onChangeTransType={onChangeTransType}
            onChangeIntgFieldID={onChangeIntgFieldID}
            onChangeOptionIntgFieldID={onChangeOptionIntgFieldID}
            onChangeLabel={onChangeLabel}
            onChangeSortOrder={onChangeSortOrder}
            onChangeStatus={onChangeStatus}
            onChangeErrorMessage={onChangeErrorMessage}
            onChangeDefaultValue={onChangeDefaultValue}
            onChangeColspan={onChangeColspan}
            onChangeRequired={onChangeRequired}
            onChangeRows={onChangeRows}
            onChangeOptions={onChangeOptions}
            onChangeHelpText={onChangeHelpText}
            onChangeDepartmentID={onChangeDepartmentID}
            onChangeIntgType={onChangeIntgType}
            onChangeItemRelationLevel={onChangeItemRelationLevel}
            onChangeItemSearchType={onChangeItemSearchType}
            onChangeSearchTable={onChangeSearchTable}
            onChangeRelatedFieldID={onChangeRelatedFieldID}
            onChangeRoleID={onChangeRoleID}
            onChangeCommitteeNameID={onChangeCommitteeNameID}
            onChangeAggregateRule={onChangeAggregateRule}
            onChangeRequiredRule={onChangeRequiredRule}
            onChangeVisibileRule={onChangeVisibileRule}
            onChangeValidationRule={onChangeValidationRule}
            onChangeDatatableRule={onChangeDatatableRule}
            onChangeIsReadOnly={onChangeIsReadOnly}
            onChangeFieldDataType={onChangeFieldDataType}
            onChangeAppListCustomIndex={onChangeAppListCustomIndex}

            field_data_type={fieldDataType}
            field_validate_rule={validationRule}
            setValidateRule={props.setValidateRule}
            field_required_rule={requiredRule}
            setRequiredRule={props.setRequiredRule}
            field_visible_rule={visibleRule}
            setVisibleRule={props.setVisibleRule}
            fields={props.field_list}
            field_list={props.field_list}
            role_list={props.role_list}
            dept_list={props.dept_list}
            intg_field_List={intgFieldList}
            field_item_intg_list={props.field_item_intg_list}
            useAsFormVar={useAsFormVar}
            onUseAsFormVarChange={onUseAsFormVarChange}

          />
        );
        break;
      case DROP_DOWN:
      case RADIO_BUTTON:
      case CHECK_BOX:
        return (
          <DropRadioCheckFieldComp
            id={id}
            classes={classes}
            fieldDisabled={fieldDisabled}

            type={type}
            name={name}
            alias={alias}
            tag={tag}
            transType={transType}
            intgFieldID={intgFieldID}
            optionIntgFieldID={optionIntgFieldID}
            label={label}
            sortOrder={sortOrder}
            status={status}
            errorMessage={errorMessage}
            defaultValue={defaultValue}
            colspan={colspan}
            required={required}
            rows={rows}
            options={options}
            helpText={helpText}
            departmentID={departmentID}
            intgType={intgType.toString()}
            itemRelationLevel={itemRelationLevel}
            itemSearchType={itemSearchType}
            searchTable={searchTable}
            relatedFieldID={relatedFieldID}
            roleID={roleID}
            committeeNameID={committeeNameID}
            aggregateRule={aggregateRule}
            requiredRule={requiredRule}
            visibleRule={visibleRule}
            validationRule={validationRule}
            datatableRule={datatableRule}
            isReadOnly={isReadOnly}
            fieldDataType={fieldDataType}
            appListCustomIndex={appListCustomIndex}

            onChangeType={onChangeType}
            onChangeName={onChangeName}
            onChangeAlias={onChangeAlias}
            onChangeTag={onChangeTag}
            onChangeTransType={onChangeTransType}
            onChangeIntgFieldID={onChangeIntgFieldID}
            onChangeOptionIntgFieldID={onChangeOptionIntgFieldID}
            onChangeLabel={onChangeLabel}
            onChangeSortOrder={onChangeSortOrder}
            onChangeStatus={onChangeStatus}
            onChangeErrorMessage={onChangeErrorMessage}
            onChangeDefaultValue={onChangeDefaultValue}
            onChangeColspan={onChangeColspan}
            onChangeRequired={onChangeRequired}
            onChangeRows={onChangeRows}
            onChangeOptions={onChangeOptions}
            onChangeHelpText={onChangeHelpText}
            onChangeDepartmentID={onChangeDepartmentID}
            onChangeIntgType={onChangeIntgType}
            onChangeItemRelationLevel={onChangeItemRelationLevel}
            onChangeItemSearchType={onChangeItemSearchType}
            onChangeSearchTable={onChangeSearchTable}
            onChangeRelatedFieldID={onChangeRelatedFieldID}
            onChangeRoleID={onChangeRoleID}
            onChangeCommitteeNameID={onChangeCommitteeNameID}
            onChangeAggregateRule={onChangeAggregateRule}
            onChangeRequiredRule={onChangeRequiredRule}
            onChangeVisibileRule={onChangeVisibileRule}
            onChangeValidationRule={onChangeValidationRule}
            onChangeDatatableRule={onChangeDatatableRule}
            onChangeIsReadOnly={onChangeIsReadOnly}
            onChangeFieldDataType={onChangeFieldDataType}
            onChangeAppListCustomIndex={onChangeAppListCustomIndex}


            field_data_type={fieldDataType}
            field_validate_rule={validationRule}
            setValidateRule={props.setValidateRule}
            field_required_rule={requiredRule}
            setRequiredRule={props.setRequiredRule}
            field_visible_rule={visibleRule}
            setVisibleRule={props.setVisibleRule}
            fields={props.field_list}
            field_list={props.field_list}
            role_list={props.role_list}
            dept_list={props.dept_list}
            intg_field_List={intgFieldList}
            field_item_intg_list={props.field_item_intg_list}
            useAsFormVar={useAsFormVar}
            onUseAsFormVarChange={onUseAsFormVarChange}

            option_intg_field_id={optionIntgFieldID}
            optionsDefaultValue={props.optionsDefaultValue}
            setOptionsIntgFieldID={props.setOptionsIntgFieldID}
            setOptions={props.setOptions}
            setOptionsDefaultValue={props.setOptionsDefaultValue}
            field_cust_intg_list={props.field_cust_intg_list.filter((obj) => (obj.intg_field_data_type === fieldDataType))}
          />
        );
        break;
      case DATE:
      case TIME:
        return (
          <DateTimeFieldComp
            id={id}
            classes={classes}
            fieldDisabled={fieldDisabled}

            type={type}
            name={name}
            alias={alias}
            tag={tag}
            transType={transType}
            intgFieldID={intgFieldID}
            optionIntgFieldID={optionIntgFieldID}
            label={label}
            sortOrder={sortOrder}
            status={status}
            errorMessage={errorMessage}
            defaultValue={defaultValue}
            colspan={colspan}
            required={required}
            rows={rows}
            options={options}
            helpText={helpText}
            departmentID={departmentID}
            intgType={intgType.toString()}
            itemRelationLevel={itemRelationLevel}
            itemSearchType={itemSearchType}
            searchTable={searchTable}
            relatedFieldID={relatedFieldID}
            roleID={roleID}
            committeeNameID={committeeNameID}
            aggregateRule={aggregateRule}
            requiredRule={requiredRule}
            visibleRule={visibleRule}
            validationRule={validationRule}
            datatableRule={datatableRule}
            isReadOnly={isReadOnly}
            fieldDataType={fieldDataType}
            appListCustomIndex={appListCustomIndex}

            onChangeType={onChangeType}
            onChangeName={onChangeName}
            onChangeAlias={onChangeAlias}
            onChangeTag={onChangeTag}
            onChangeTransType={onChangeTransType}
            onChangeIntgFieldID={onChangeIntgFieldID}
            onChangeOptionIntgFieldID={onChangeOptionIntgFieldID}
            onChangeLabel={onChangeLabel}
            onChangeSortOrder={onChangeSortOrder}
            onChangeStatus={onChangeStatus}
            onChangeErrorMessage={onChangeErrorMessage}
            onChangeDefaultValue={onChangeDefaultValue}
            onChangeColspan={onChangeColspan}
            onChangeRequired={onChangeRequired}
            onChangeRows={onChangeRows}
            onChangeOptions={onChangeOptions}
            onChangeHelpText={onChangeHelpText}
            onChangeDepartmentID={onChangeDepartmentID}
            onChangeIntgType={onChangeIntgType}
            onChangeItemRelationLevel={onChangeItemRelationLevel}
            onChangeItemSearchType={onChangeItemSearchType}
            onChangeSearchTable={onChangeSearchTable}
            onChangeRelatedFieldID={onChangeRelatedFieldID}
            onChangeRoleID={onChangeRoleID}
            onChangeCommitteeNameID={onChangeCommitteeNameID}
            onChangeAggregateRule={onChangeAggregateRule}
            onChangeRequiredRule={onChangeRequiredRule}
            onChangeVisibileRule={onChangeVisibileRule}
            onChangeValidationRule={onChangeValidationRule}
            onChangeDatatableRule={onChangeDatatableRule}
            onChangeIsReadOnly={onChangeIsReadOnly}
            onChangeFieldDataType={onChangeFieldDataType}
            onChangeAppListCustomIndex={onChangeAppListCustomIndex}

            field_data_type={fieldDataType}
            field_validate_rule={validationRule}
            setValidateRule={props.setValidateRule}
            field_required_rule={requiredRule}
            setRequiredRule={props.setRequiredRule}
            field_visible_rule={visibleRule}
            setVisibleRule={props.setVisibleRule}
            fields={props.field_list}
            field_list={props.field_list}
            role_list={props.role_list}
            dept_list={props.dept_list}
            intg_field_List={intgFieldList}
            field_item_intg_list={props.field_item_intg_list}
            useAsFormVar={useAsFormVar}
            onUseAsFormVarChange={onUseAsFormVarChange}
          />
        );
        break;
      case HELPTEXT:
        return (
          <HelpTextFieldComp
            id={id}
            classes={classes}
            fieldDisabled={fieldDisabled}

            type={type}
            name={name}
            alias={alias}
            tag={tag}
            transType={transType}
            intgFieldID={intgFieldID}
            optionIntgFieldID={optionIntgFieldID}
            label={label}
            sortOrder={sortOrder}
            status={status}
            errorMessage={errorMessage}
            defaultValue={defaultValue}
            colspan={colspan}
            required={required}
            rows={rows}
            options={options}
            helpText={helpText}
            departmentID={departmentID}
            intgType={intgType.toString()}
            itemRelationLevel={itemRelationLevel}
            itemSearchType={itemSearchType}
            searchTable={searchTable}
            relatedFieldID={relatedFieldID}
            roleID={roleID}
            committeeNameID={committeeNameID}
            aggregateRule={aggregateRule}
            requiredRule={requiredRule}
            visibleRule={visibleRule}
            validationRule={validationRule}
            datatableRule={datatableRule}
            isReadOnly={isReadOnly}
            fieldDataType={fieldDataType}
            appListCustomIndex={appListCustomIndex}


            onChangeType={onChangeType}
            onChangeName={onChangeName}
            onChangeAlias={onChangeAlias}
            onChangeTag={onChangeTag}
            onChangeTransType={onChangeTransType}
            onChangeIntgFieldID={onChangeIntgFieldID}
            onChangeOptionIntgFieldID={onChangeOptionIntgFieldID}
            onChangeLabel={onChangeLabel}
            onChangeSortOrder={onChangeSortOrder}
            onChangeStatus={onChangeStatus}
            onChangeErrorMessage={onChangeErrorMessage}
            onChangeDefaultValue={onChangeDefaultValue}
            onChangeColspan={onChangeColspan}
            onChangeRequired={onChangeRequired}
            onChangeRows={onChangeRows}
            onChangeOptions={onChangeOptions}
            onChangeHelpText={onChangeHelpText}
            onChangeDepartmentID={onChangeDepartmentID}
            onChangeIntgType={onChangeIntgType}
            onChangeItemRelationLevel={onChangeItemRelationLevel}
            onChangeItemSearchType={onChangeItemSearchType}
            onChangeSearchTable={onChangeSearchTable}
            onChangeRelatedFieldID={onChangeRelatedFieldID}
            onChangeRoleID={onChangeRoleID}
            onChangeCommitteeNameID={onChangeCommitteeNameID}
            onChangeAggregateRule={onChangeAggregateRule}
            onChangeRequiredRule={onChangeRequiredRule}
            onChangeVisibileRule={onChangeVisibileRule}
            onChangeValidationRule={onChangeValidationRule}
            onChangeDatatableRule={onChangeDatatableRule}
            onChangeIsReadOnly={onChangeIsReadOnly}
            onChangeFieldDataType={onChangeFieldDataType}
            onChangeAppListCustomIndex={onChangeAppListCustomIndex}

            field_data_type={fieldDataType}
            field_validate_rule={validationRule}
            setValidateRule={props.setValidateRule}
            field_required_rule={requiredRule}
            setRequiredRule={props.setRequiredRule}
            field_visible_rule={visibleRule}
            setVisibleRule={props.setVisibleRule}
            fields={props.field_list}
            field_list={props.field_list}
            role_list={props.role_list}
            dept_list={props.dept_list}
            intg_field_List={intgFieldList}
            field_item_intg_list={props.field_item_intg_list}
            useAsFormVar={useAsFormVar}
            onUseAsFormVarChange={onUseAsFormVarChange}
          />
        );
        break;

      case FILE_SHARING:
        return (
          <FileSharingFieldComp

            id={id}
            classes={classes}
            fieldDisabled={fieldDisabled}

            type={type}
            name={name}
            alias={alias}
            tag={tag}
            transType={transType}
            intgFieldID={intgFieldID}
            optionIntgFieldID={optionIntgFieldID}
            label={label}
            sortOrder={sortOrder}
            status={status}
            errorMessage={errorMessage}
            defaultValue={defaultValue}
            colspan={colspan}
            required={required}
            rows={rows}
            options={options}
            helpText={helpText}
            departmentID={departmentID}
            intgType={intgType.toString()}
            itemRelationLevel={itemRelationLevel}
            itemSearchType={itemSearchType}
            searchTable={searchTable}
            relatedFieldID={relatedFieldID}
            roleID={roleID}
            committeeNameID={committeeNameID}
            aggregateRule={aggregateRule}
            requiredRule={requiredRule}
            visibleRule={visibleRule}
            validationRule={validationRule}
            datatableRule={datatableRule}
            isReadOnly={isReadOnly}
            fieldDataType={fieldDataType}
            appListCustomIndex={appListCustomIndex}


            onChangeType={onChangeType}
            onChangeName={onChangeName}
            onChangeAlias={onChangeAlias}
            onChangeTag={onChangeTag}
            onChangeTransType={onChangeTransType}
            onChangeIntgFieldID={onChangeIntgFieldID}
            onChangeOptionIntgFieldID={onChangeOptionIntgFieldID}
            onChangeLabel={onChangeLabel}
            onChangeSortOrder={onChangeSortOrder}
            onChangeStatus={onChangeStatus}
            onChangeErrorMessage={onChangeErrorMessage}
            onChangeDefaultValue={onChangeDefaultValue}
            onChangeColspan={onChangeColspan}
            onChangeRequired={onChangeRequired}
            onChangeRows={onChangeRows}
            onChangeOptions={onChangeOptions}
            onChangeHelpText={onChangeHelpText}
            onChangeDepartmentID={onChangeDepartmentID}
            onChangeIntgType={onChangeIntgType}
            onChangeItemRelationLevel={onChangeItemRelationLevel}
            onChangeItemSearchType={onChangeItemSearchType}
            onChangeSearchTable={onChangeSearchTable}
            onChangeRelatedFieldID={onChangeRelatedFieldID}
            onChangeRoleID={onChangeRoleID}
            onChangeCommitteeNameID={onChangeCommitteeNameID}
            onChangeAggregateRule={onChangeAggregateRule}
            onChangeRequiredRule={onChangeRequiredRule}
            onChangeVisibileRule={onChangeVisibileRule}
            onChangeValidationRule={onChangeValidationRule}
            onChangeDatatableRule={onChangeDatatableRule}
            onChangeIsReadOnly={onChangeIsReadOnly}
            onChangeFieldDataType={onChangeFieldDataType}
            onChangeAppListCustomIndex={onChangeAppListCustomIndex}

            field_data_type={fieldDataType}
            field_validate_rule={validationRule}
            setValidateRule={props.setValidateRule}
            field_required_rule={requiredRule}
            setRequiredRule={props.setRequiredRule}
            field_visible_rule={visibleRule}
            setVisibleRule={props.setVisibleRule}
            fields={props.field_list}
            field_list={props.field_list}
            role_list={props.role_list}
            dept_list={props.dept_list}
            intg_field_List={intgFieldList}
            field_item_intg_list={props.field_item_intg_list}
            useAsFormVar={useAsFormVar}
            onUseAsFormVarChange={onUseAsFormVarChange}

          />
        );
        break;

      case AGGREGATE_NUM:
        return (
          <AggregateFieldComp
            id={id}
            classes={classes}
            fieldDisabled={fieldDisabled}
            type={type}
            name={name}
            alias={alias}
            tag={tag}
            transType={transType}
            intgFieldID={intgFieldID}
            optionIntgFieldID={optionIntgFieldID}
            label={label}
            sortOrder={sortOrder}
            status={status}
            errorMessage={errorMessage}
            defaultValue={defaultValue}
            colspan={colspan}
            required={required}
            rows={rows}
            options={options}
            helpText={helpText}
            departmentID={departmentID}
            intgType={intgType.toString()}
            itemRelationLevel={itemRelationLevel}
            itemSearchType={itemSearchType}
            searchTable={searchTable}
            relatedFieldID={relatedFieldID}
            roleID={roleID}
            committeeNameID={committeeNameID}
            aggregateRule={aggregateRule}
            requiredRule={requiredRule}
            visibleRule={visibleRule}
            validationRule={validationRule}
            datatableRule={datatableRule}
            isReadOnly={isReadOnly}
            fieldDataType={fieldDataType}
            appListCustomIndex={appListCustomIndex}


            onChangeType={onChangeType}
            onChangeName={onChangeName}
            onChangeAlias={onChangeAlias}
            onChangeTag={onChangeTag}
            onChangeTransType={onChangeTransType}
            onChangeIntgFieldID={onChangeIntgFieldID}
            onChangeOptionIntgFieldID={onChangeOptionIntgFieldID}
            onChangeLabel={onChangeLabel}
            onChangeSortOrder={onChangeSortOrder}
            onChangeStatus={onChangeStatus}
            onChangeErrorMessage={onChangeErrorMessage}
            onChangeDefaultValue={onChangeDefaultValue}
            onChangeColspan={onChangeColspan}
            onChangeRequired={onChangeRequired}
            onChangeRows={onChangeRows}
            onChangeOptions={onChangeOptions}
            onChangeHelpText={onChangeHelpText}
            onChangeDepartmentID={onChangeDepartmentID}
            onChangeIntgType={onChangeIntgType}
            onChangeItemRelationLevel={onChangeItemRelationLevel}
            onChangeItemSearchType={onChangeItemSearchType}
            onChangeSearchTable={onChangeSearchTable}
            onChangeRelatedFieldID={onChangeRelatedFieldID}
            onChangeRoleID={onChangeRoleID}
            onChangeCommitteeNameID={onChangeCommitteeNameID}
            onChangeAggregateRule={onChangeAggregateRule}
            onChangeRequiredRule={onChangeRequiredRule}
            onChangeVisibileRule={onChangeVisibileRule}
            onChangeValidationRule={onChangeValidationRule}
            onChangeDatatableRule={onChangeDatatableRule}
            onChangeIsReadOnly={onChangeIsReadOnly}
            onChangeFieldDataType={onChangeFieldDataType}
            onChangeAppListCustomIndex={onChangeAppListCustomIndex}

            field_data_type={fieldDataType}
            field_validate_rule={validationRule}
            setValidateRule={props.setValidateRule}
            field_required_rule={requiredRule}
            setRequiredRule={props.setRequiredRule}
            field_visible_rule={visibleRule}
            setVisibleRule={props.setVisibleRule}
            fields={props.field_list}
            field_list={props.field_list}
            role_list={props.role_list}
            dept_list={props.dept_list}
            intg_field_List={intgFieldList}
            field_item_intg_list={props.field_item_intg_list}
            useAsFormVar={useAsFormVar}
            onUseAsFormVarChange={onUseAsFormVarChange}

            setAggregateRule={props.setAggregateRule}
            num_fields={props.field_list.filter((obj) => (obj.field_data_type === INTEGER_NUMBER
              || obj.field_data_type === DECIMAL_NUMBER) && obj.id !== id)}
            field_aggregate_rule={props.field_aggregate_rule}

          />
        );
        break;
      case ITEM_SEARCH:
        return (
          <SearchItemFieldComp
            id={id}
            classes={classes}
            fieldDisabled={fieldDisabled}
            type={type}
            name={name}
            alias={alias}
            tag={tag}
            transType={transType}
            intgFieldID={intgFieldID}
            optionIntgFieldID={optionIntgFieldID}
            label={label}
            sortOrder={sortOrder}
            status={status}
            errorMessage={errorMessage}
            defaultValue={defaultValue}
            colspan={colspan}
            required={required}
            rows={rows}
            options={options}
            helpText={helpText}
            departmentID={departmentID}
            intgType={intgType.toString()}
            itemRelationLevel={itemRelationLevel}
            itemSearchType={itemSearchType}
            searchTable={searchTable}
            relatedFieldID={relatedFieldID}
            roleID={roleID}
            committeeNameID={committeeNameID}
            aggregateRule={aggregateRule}
            requiredRule={requiredRule}
            visibleRule={visibleRule}
            validationRule={validationRule}
            datatableRule={datatableRule}
            isReadOnly={isReadOnly}
            fieldDataType={fieldDataType}
            appListCustomIndex={appListCustomIndex}


            onChangeType={onChangeType}
            onChangeName={onChangeName}
            onChangeAlias={onChangeAlias}
            onChangeTag={onChangeTag}
            onChangeTransType={onChangeTransType}
            onChangeIntgFieldID={onChangeIntgFieldID}
            onChangeOptionIntgFieldID={onChangeOptionIntgFieldID}
            onChangeLabel={onChangeLabel}
            onChangeSortOrder={onChangeSortOrder}
            onChangeStatus={onChangeStatus}
            onChangeErrorMessage={onChangeErrorMessage}
            onChangeDefaultValue={onChangeDefaultValue}
            onChangeColspan={onChangeColspan}
            onChangeRequired={onChangeRequired}
            onChangeRows={onChangeRows}
            onChangeOptions={onChangeOptions}
            onChangeHelpText={onChangeHelpText}
            onChangeDepartmentID={onChangeDepartmentID}
            onChangeIntgType={onChangeIntgType}
            onChangeItemRelationLevel={onChangeItemRelationLevel}
            onChangeItemSearchType={onChangeItemSearchType}
            onChangeSearchTable={onChangeSearchTable}
            onChangeRelatedFieldID={onChangeRelatedFieldID}
            onChangeRoleID={onChangeRoleID}
            onChangeCommitteeNameID={onChangeCommitteeNameID}
            onChangeAggregateRule={onChangeAggregateRule}
            onChangeRequiredRule={onChangeRequiredRule}
            onChangeVisibileRule={onChangeVisibileRule}
            onChangeValidationRule={onChangeValidationRule}
            onChangeDatatableRule={onChangeDatatableRule}
            onChangeIsReadOnly={onChangeIsReadOnly}
            onChangeFieldDataType={onChangeFieldDataType}
            onChangeAppListCustomIndex={onChangeAppListCustomIndex}

            field_data_type={fieldDataType}
            field_validate_rule={validationRule}
            setValidateRule={props.setValidateRule}
            field_required_rule={requiredRule}
            setRequiredRule={props.setRequiredRule}
            field_visible_rule={visibleRule}
            setVisibleRule={props.setVisibleRule}
            fields={props.field_list}
            field_list={props.field_list}
            role_list={props.role_list}
            dept_list={props.dept_list}
            intg_field_List={intgFieldList}
            field_item_intg_list={props.field_item_intg_list}
            useAsFormVar={useAsFormVar}
            onUseAsFormVarChange={onUseAsFormVarChange}

          />
        );
        break;

      case ITEM_SEARCH2:
        return (
          <SearchItem2FieldComp
            id={id}
            classes={classes}
            fieldDisabled={fieldDisabled}

            type={type}
            name={name}
            alias={alias}
            tag={tag}
            transType={transType}
            intgFieldID={intgFieldID}
            optionIntgFieldID={optionIntgFieldID}
            label={label}
            sortOrder={sortOrder}
            status={status}
            errorMessage={errorMessage}
            defaultValue={defaultValue}
            colspan={colspan}
            required={required}
            rows={rows}
            options={options}
            helpText={helpText}
            departmentID={departmentID}
            intgType={intgType.toString()}
            itemRelationLevel={itemRelationLevel}
            itemSearchType={itemSearchType}
            searchTable={searchTable}
            searchTableRelated={searchTableRelated}
            relatedFieldID={relatedFieldID}
            roleID={roleID}
            committeeNameID={committeeNameID}
            aggregateRule={aggregateRule}
            requiredRule={requiredRule}
            visibleRule={visibleRule}
            validationRule={validationRule}
            datatableRule={datatableRule}
            isReadOnly={isReadOnly}
            fieldDataType={fieldDataType}
            appListCustomIndex={appListCustomIndex}


            onChangeType={onChangeType}
            onChangeName={onChangeName}
            onChangeAlias={onChangeAlias}
            onChangeTag={onChangeTag}
            onChangeTransType={onChangeTransType}
            onChangeIntgFieldID={onChangeIntgFieldID}
            onChangeOptionIntgFieldID={onChangeOptionIntgFieldID}
            onChangeLabel={onChangeLabel}
            onChangeSortOrder={onChangeSortOrder}
            onChangeStatus={onChangeStatus}
            onChangeErrorMessage={onChangeErrorMessage}
            onChangeDefaultValue={onChangeDefaultValue}
            onChangeColspan={onChangeColspan}
            onChangeRequired={onChangeRequired}
            onChangeRows={onChangeRows}
            onChangeOptions={onChangeOptions}
            onChangeHelpText={onChangeHelpText}
            onChangeDepartmentID={onChangeDepartmentID}
            onChangeIntgType={onChangeIntgType}
            onChangeItemRelationLevel={onChangeItemRelationLevel}
            onChangeItemSearchType={onChangeItemSearchType}
            onChangeSearchTable={onChangeSearchTable}
            onChangeSearchTableRelated={onChangeSearchTableRelated}
            onChangeRelatedFieldID={onChangeRelatedFieldID}
            onChangeRoleID={onChangeRoleID}
            onChangeCommitteeNameID={onChangeCommitteeNameID}
            onChangeAggregateRule={onChangeAggregateRule}
            onChangeRequiredRule={onChangeRequiredRule}
            onChangeVisibileRule={onChangeVisibileRule}
            onChangeValidationRule={onChangeValidationRule}
            onChangeDatatableRule={onChangeDatatableRule}
            onChangeIsReadOnly={onChangeIsReadOnly}
            onChangeFieldDataType={onChangeFieldDataType}
            onChangeAppListCustomIndex={onChangeAppListCustomIndex}

            field_data_type={fieldDataType}
            field_validate_rule={validationRule}
            setValidateRule={props.setValidateRule}
            field_required_rule={requiredRule}
            setRequiredRule={props.setRequiredRule}
            field_visible_rule={visibleRule}
            setVisibleRule={props.setVisibleRule}
            fields={props.field_list}
            field_list={props.field_list}
            role_list={props.role_list}
            dept_list={props.dept_list}
            intg_field_List={intgFieldList}
            field_item_intg_list={props.field_item_intg_list}
            useAsFormVar={useAsFormVar}
            onUseAsFormVarChange={onUseAsFormVarChange}

            setDatatableRule={props.setDatatableRule}
            field_datatable_rule={props.field_datatable_rule}
          />
        );
        break;

      case ITEM_TRANSACTIONS:
        return (
          <ItemTransFieldComp

            id={id}
            classes={classes}
            fieldDisabled={fieldDisabled}

            type={type}
            name={name}
            alias={alias}
            tag={tag}
            transType={transType}
            intgFieldID={intgFieldID}
            optionIntgFieldID={optionIntgFieldID}
            label={label}
            sortOrder={sortOrder}
            status={status}
            errorMessage={errorMessage}
            defaultValue={defaultValue}
            colspan={colspan}
            required={required}
            rows={rows}
            options={options}
            helpText={helpText}
            departmentID={departmentID}
            intgType={intgType.toString()}
            itemRelationLevel={itemRelationLevel}
            itemSearchType={itemSearchType}
            searchTable={searchTable}
            relatedFieldID={relatedFieldID}
            roleID={roleID}
            committeeNameID={committeeNameID}
            aggregateRule={aggregateRule}
            requiredRule={requiredRule}
            visibleRule={visibleRule}
            validationRule={validationRule}
            datatableRule={datatableRule}
            isReadOnly={isReadOnly}
            fieldDataType={fieldDataType}
            appListCustomIndex={appListCustomIndex}


            onChangeType={onChangeType}
            onChangeName={onChangeName}
            onChangeAlias={onChangeAlias}
            onChangeTag={onChangeTag}
            onChangeTransType={onChangeTransType}
            onChangeIntgFieldID={onChangeIntgFieldID}
            onChangeOptionIntgFieldID={onChangeOptionIntgFieldID}
            onChangeLabel={onChangeLabel}
            onChangeSortOrder={onChangeSortOrder}
            onChangeStatus={onChangeStatus}
            onChangeErrorMessage={onChangeErrorMessage}
            onChangeDefaultValue={onChangeDefaultValue}
            onChangeColspan={onChangeColspan}
            onChangeRequired={onChangeRequired}
            onChangeRows={onChangeRows}
            onChangeOptions={onChangeOptions}
            onChangeHelpText={onChangeHelpText}
            onChangeDepartmentID={onChangeDepartmentID}
            onChangeIntgType={onChangeIntgType}
            onChangeItemRelationLevel={onChangeItemRelationLevel}
            onChangeItemSearchType={onChangeItemSearchType}
            onChangeSearchTable={onChangeSearchTable}
            onChangeRelatedFieldID={onChangeRelatedFieldID}
            onChangeRoleID={onChangeRoleID}
            onChangeCommitteeNameID={onChangeCommitteeNameID}
            onChangeAggregateRule={onChangeAggregateRule}
            onChangeRequiredRule={onChangeRequiredRule}
            onChangeVisibileRule={onChangeVisibileRule}
            onChangeValidationRule={onChangeValidationRule}
            onChangeDatatableRule={onChangeDatatableRule}
            onChangeIsReadOnly={onChangeIsReadOnly}
            onChangeFieldDataType={onChangeFieldDataType}
            onChangeAppListCustomIndex={onChangeAppListCustomIndex}

            field_data_type={fieldDataType}
            field_validate_rule={validationRule}
            setValidateRule={props.setValidateRule}
            field_required_rule={requiredRule}
            setRequiredRule={props.setRequiredRule}
            field_visible_rule={visibleRule}
            setVisibleRule={props.setVisibleRule}
            fields={props.field_list}
            field_list={props.field_list}
            role_list={props.role_list}
            dept_list={props.dept_list}
            intg_field_List={intgFieldList}
            field_item_intg_list={props.field_item_intg_list}
            useAsFormVar={useAsFormVar}
            onUseAsFormVarChange={onUseAsFormVarChange}

          />
        );
        break;
      case ITEM_OPT_DETAILS:
        return (
          <ItemOptionDetailsFieldComp
            id={id}
            classes={classes}
            fieldDisabled={fieldDisabled}

            type={type}
            name={name}
            alias={alias}
            tag={tag}
            transType={transType}
            intgFieldID={intgFieldID}
            optionIntgFieldID={optionIntgFieldID}
            label={label}
            sortOrder={sortOrder}
            status={status}
            errorMessage={errorMessage}
            defaultValue={defaultValue}
            colspan={colspan}
            required={required}
            rows={rows}
            options={options}
            helpText={helpText}
            departmentID={departmentID}
            intgType={intgType.toString()}
            itemRelationLevel={itemRelationLevel}
            itemSearchType={itemSearchType}
            searchTable={searchTable}
            relatedFieldID={relatedFieldID}
            roleID={roleID}
            committeeNameID={committeeNameID}
            aggregateRule={aggregateRule}
            requiredRule={requiredRule}
            visibleRule={visibleRule}
            validationRule={validationRule}
            datatableRule={datatableRule}
            isReadOnly={isReadOnly}
            fieldDataType={fieldDataType}
            appListCustomIndex={appListCustomIndex}


            onChangeType={onChangeType}
            onChangeName={onChangeName}
            onChangeAlias={onChangeAlias}
            onChangeTag={onChangeTag}
            onChangeTransType={onChangeTransType}
            onChangeIntgFieldID={onChangeIntgFieldID}
            onChangeOptionIntgFieldID={onChangeOptionIntgFieldID}
            onChangeLabel={onChangeLabel}
            onChangeSortOrder={onChangeSortOrder}
            onChangeStatus={onChangeStatus}
            onChangeErrorMessage={onChangeErrorMessage}
            onChangeDefaultValue={onChangeDefaultValue}
            onChangeColspan={onChangeColspan}
            onChangeRequired={onChangeRequired}
            onChangeRows={onChangeRows}
            onChangeOptions={onChangeOptions}
            onChangeHelpText={onChangeHelpText}
            onChangeDepartmentID={onChangeDepartmentID}
            onChangeIntgType={onChangeIntgType}
            onChangeItemRelationLevel={onChangeItemRelationLevel}
            onChangeItemSearchType={onChangeItemSearchType}
            onChangeSearchTable={onChangeSearchTable}
            onChangeRelatedFieldID={onChangeRelatedFieldID}
            onChangeRoleID={onChangeRoleID}
            onChangeCommitteeNameID={onChangeCommitteeNameID}
            onChangeAggregateRule={onChangeAggregateRule}
            onChangeRequiredRule={onChangeRequiredRule}
            onChangeVisibileRule={onChangeVisibileRule}
            onChangeValidationRule={onChangeValidationRule}
            onChangeDatatableRule={onChangeDatatableRule}
            onChangeIsReadOnly={onChangeIsReadOnly}
            onChangeFieldDataType={onChangeFieldDataType}
            onChangeAppListCustomIndex={onChangeAppListCustomIndex}

            field_data_type={fieldDataType}
            field_validate_rule={validationRule}
            setValidateRule={props.setValidateRule}
            field_required_rule={requiredRule}
            setRequiredRule={props.setRequiredRule}
            field_visible_rule={visibleRule}
            setVisibleRule={props.setVisibleRule}
            fields={props.field_list}
            field_list={props.field_list}
            role_list={props.role_list}
            dept_list={props.dept_list}
            intg_field_List={intgFieldList}
            field_item_intg_list={props.field_item_intg_list}
            useAsFormVar={useAsFormVar}
            onUseAsFormVarChange={onUseAsFormVarChange}
          />
        );
        break;

      case SYSTEM_DATA:
        return (
          <SystemDataComp
            id={id}
            classes={classes}
            fieldDisabled={fieldDisabled}

            type={type}
            name={name}
            alias={alias}
            tag={tag}
            transType={transType}
            intgFieldID={intgFieldID}
            optionIntgFieldID={optionIntgFieldID}
            label={label}
            sortOrder={sortOrder}
            status={status}
            errorMessage={errorMessage}
            defaultValue={defaultValue}
            colspan={colspan}
            required={required}
            rows={rows}
            options={options}
            helpText={helpText}
            departmentID={departmentID}
            intgType={intgType.toString()}
            itemRelationLevel={itemRelationLevel}
            itemSearchType={itemSearchType}
            searchTable={searchTable}
            relatedFieldID={relatedFieldID}
            roleID={roleID}
            committeeNameID={committeeNameID}
            aggregateRule={aggregateRule}
            requiredRule={requiredRule}
            visibleRule={visibleRule}
            validationRule={validationRule}
            datatableRule={datatableRule}
            isReadOnly={isReadOnly}
            fieldDataType={fieldDataType}
            appListCustomIndex={appListCustomIndex}


            onChangeType={onChangeType}
            onChangeName={onChangeName}
            onChangeAlias={onChangeAlias}
            onChangeTag={onChangeTag}
            onChangeTransType={onChangeTransType}
            onChangeIntgFieldID={onChangeIntgFieldID}
            onChangeOptionIntgFieldID={onChangeOptionIntgFieldID}
            onChangeLabel={onChangeLabel}
            onChangeSortOrder={onChangeSortOrder}
            onChangeStatus={onChangeStatus}
            onChangeErrorMessage={onChangeErrorMessage}
            onChangeDefaultValue={onChangeDefaultValue}
            onChangeColspan={onChangeColspan}
            onChangeRequired={onChangeRequired}
            onChangeRows={onChangeRows}
            onChangeOptions={onChangeOptions}
            onChangeHelpText={onChangeHelpText}
            onChangeDepartmentID={onChangeDepartmentID}
            onChangeIntgType={onChangeIntgType}
            onChangeItemRelationLevel={onChangeItemRelationLevel}
            onChangeItemSearchType={onChangeItemSearchType}
            onChangeSearchTable={onChangeSearchTable}
            onChangeRelatedFieldID={onChangeRelatedFieldID}
            onChangeRoleID={onChangeRoleID}
            onChangeCommitteeNameID={onChangeCommitteeNameID}
            onChangeAggregateRule={onChangeAggregateRule}
            onChangeRequiredRule={onChangeRequiredRule}
            onChangeVisibileRule={onChangeVisibileRule}
            onChangeValidationRule={onChangeValidationRule}
            onChangeDatatableRule={onChangeDatatableRule}
            onChangeIsReadOnly={onChangeIsReadOnly}
            onChangeFieldDataType={onChangeFieldDataType}
            onChangeAppListCustomIndex={onChangeAppListCustomIndex}

            field_data_type={fieldDataType}
            field_validate_rule={validationRule}
            setValidateRule={props.setValidateRule}
            field_required_rule={requiredRule}
            setRequiredRule={props.setRequiredRule}
            field_visible_rule={visibleRule}
            setVisibleRule={props.setVisibleRule}
            fields={props.field_list}
            field_list={props.field_list}
            role_list={props.role_list}
            dept_list={props.dept_list}
            intg_field_List={intgFieldList}
            field_item_intg_list={props.field_item_intg_list}
            useAsFormVar={useAsFormVar}
            onUseAsFormVarChange={onUseAsFormVarChange}
          />
        );
        break;
      case TRANS_TEXT_FIELD:
        return (
          <TransTextFieldComp
            id={id}
            classes={classes}
            fieldDisabled={fieldDisabled}

            type={type}
            name={name}
            alias={alias}
            tag={tag}
            transType={transType}
            intgFieldID={intgFieldID}
            optionIntgFieldID={optionIntgFieldID}
            label={label}
            sortOrder={sortOrder}
            status={status}
            errorMessage={errorMessage}
            defaultValue={defaultValue}
            colspan={colspan}
            required={required}
            rows={rows}
            options={options}
            helpText={helpText}
            departmentID={departmentID}
            intgType={intgType.toString()}
            itemRelationLevel={itemRelationLevel}
            itemSearchType={itemSearchType}
            searchTable={searchTable}
            relatedFieldID={relatedFieldID}
            roleID={roleID}
            committeeNameID={committeeNameID}
            aggregateRule={aggregateRule}
            requiredRule={requiredRule}
            visibleRule={visibleRule}
            validationRule={validationRule}
            datatableRule={datatableRule}
            isReadOnly={isReadOnly}
            fieldDataType={fieldDataType}
            appListCustomIndex={appListCustomIndex}


            onChangeType={onChangeType}
            onChangeName={onChangeName}
            onChangeAlias={onChangeAlias}
            onChangeTag={onChangeTag}
            onChangeTransType={onChangeTransType}
            onChangeIntgFieldID={onChangeIntgFieldID}
            onChangeOptionIntgFieldID={onChangeOptionIntgFieldID}
            onChangeLabel={onChangeLabel}
            onChangeSortOrder={onChangeSortOrder}
            onChangeStatus={onChangeStatus}
            onChangeErrorMessage={onChangeErrorMessage}
            onChangeDefaultValue={onChangeDefaultValue}
            onChangeColspan={onChangeColspan}
            onChangeRequired={onChangeRequired}
            onChangeRows={onChangeRows}
            onChangeOptions={onChangeOptions}
            onChangeHelpText={onChangeHelpText}
            onChangeDepartmentID={onChangeDepartmentID}
            onChangeIntgType={onChangeIntgType}
            onChangeItemRelationLevel={onChangeItemRelationLevel}
            onChangeItemSearchType={onChangeItemSearchType}
            onChangeSearchTable={onChangeSearchTable}
            onChangeRelatedFieldID={onChangeRelatedFieldID}
            onChangeRoleID={onChangeRoleID}
            onChangeCommitteeNameID={onChangeCommitteeNameID}
            onChangeAggregateRule={onChangeAggregateRule}
            onChangeRequiredRule={onChangeRequiredRule}
            onChangeVisibileRule={onChangeVisibileRule}
            onChangeValidationRule={onChangeValidationRule}
            onChangeDatatableRule={onChangeDatatableRule}
            onChangeIsReadOnly={onChangeIsReadOnly}
            onChangeFieldDataType={onChangeFieldDataType}
            onChangeAppListCustomIndex={onChangeAppListCustomIndex}

            field_data_type={fieldDataType}
            field_validate_rule={validationRule}
            setValidateRule={props.setValidateRule}
            field_required_rule={requiredRule}
            setRequiredRule={props.setRequiredRule}
            field_visible_rule={visibleRule}
            setVisibleRule={props.setVisibleRule}
            fields={props.field_list}
            field_list={props.field_list}
            role_list={props.role_list}
            dept_list={props.dept_list}
            intg_field_List={intgFieldList}
            field_item_intg_list={props.field_item_intg_list}
            useAsFormVar={useAsFormVar}
            onUseAsFormVarChange={onUseAsFormVarChange}
          />
        );
        break;
      default:
        return null;
    }
  };
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      {/*
        <div style={{ width: '100%' }}>
          <Box display="flex" p={1} color="white" bgcolor="#9c27b0" >
            <b>Form : {props.form_field_list.title}</b>
          </Box>
        </div>
        <br />
        */}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Fields </b>
        </CardHeader>
        <CardBody>
          <MUIDataTable
            title={(
              <div>
                <Button
                  onClick={handleNewClick.bind(this)}
                  color="primary"
                  title="Create a new Field"
                  disabled={parseInt(localStorage.getItem('workflow_state'), 10) > 0}
                  style={{ display: showAddBtn ? 'inline-block' : 'none' }}
                  round
                >
                  New Field
                </Button>
                <IconButton
                  onClick={handlePreviewClick.bind(this,
                    [props.form_id])}
                  title="Preview Fields"
                >
                  <AirplayIcon />
                </IconButton>
              </div>
            )}
            data={data}
            columns={columns}
            options={tableOptions}
          />

          <Dialog fullWidth maxWidth="lg" open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              <Card>
                <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                  <b>{dialogTitle}</b>
                </CardHeader>
                <CardBody>
                  <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled}>
                        <InputLabel id="typeLabel">Type</InputLabel>
                        {showAddBtn
                          ? (
                            <Select
                              labelid="typeLabel"
                              id="typeSelect"
                              value={type}
                              onChange={onChangeType.bind(this)}
                            >
                              <MenuItem value={TEXT_FIELD}>Text Field</MenuItem>
                              <MenuItem value={TEXT_AREA}>Text Area</MenuItem>
                              <MenuItem value={DROP_DOWN}>Drop Down</MenuItem>
                              <MenuItem value={RADIO_BUTTON}>Radio Button</MenuItem>
                              <MenuItem value={CHECK_BOX}>Check Box</MenuItem>
                              <MenuItem value={DATE}>Date Field</MenuItem>
                              <MenuItem value={WEEK_CALENDAR}>Week Calendar</MenuItem>
                              <MenuItem value={TIME}>Time Field</MenuItem>
                              <MenuItem value={HELPTEXT}>Help Text</MenuItem>
                              <MenuItem value={FILE_SHARING}>File Sharing</MenuItem>
                              <MenuItem value={AGGREGATE_NUM}>Aggregate Number</MenuItem>
                              <MenuItem value={ITEM_SEARCH}>Item Search</MenuItem>
                              <MenuItem value={ITEM_SEARCH2}>Item Search 2</MenuItem>
                              <MenuItem value={ITEM_TRANSACTIONS}>Item Transctions </MenuItem>
                              <MenuItem value={ITEM_OPT_DETAILS}>Item Option Details </MenuItem>
                              <MenuItem value={SYSTEM_DATA}>System Data</MenuItem>
                              <MenuItem value={TRANS_TEXT_FIELD}>Transaction Text Field</MenuItem>
                              {/*<MenuItem value={ITEM_DATATABLE}>Item Datatable</MenuItem>*/}
                            </Select>
                          )
                          : (
                            <Select
                              labelid="typeLabel"
                              id="typeSelect"
                              value={type}
                              onChange={onChangeType.bind(this)}
                            >
                              <MenuItem value={COMMITTEE}>Adhoc Committee</MenuItem>
                            </Select>
                          )}
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <InputLabel id="statusLabel" shrink>Status</InputLabel>
                      <Grid item component="label" container alignItems="center" spacing={1}>
                        <Grid item>Inactive</Grid>
                        <Grid item>
                          <Switch
                            labelid="statusLabel"
                            checked={status}
                            onChange={onChangeStatus.bind(this)}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled={fieldDisabled || parseInt(localStorage.getItem('workflow_state'), 10) > 0}
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              // icon: classes.switchIcon,
                              // iconChecked: classes.switchIconChecked,
                              // bar: classes.switchBar
                            }}
                          />
                        </Grid>
                        <Grid item>Active</Grid>
                      </Grid>
                    </GridItem>
                  </GridContainer>
                  <br />
                  {renderFieldTypeSelection()}

                </CardBody>
                <CardFooter style={{ textAlign: 'left' }} />
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose.bind(this)} color="primary" round>
                Cancel
              </Button>
              <Button
                onClick={handleDialogConfirm.bind(this)}
                color="primary"
                round
                disabled={message === '' && dialogButton !== 'Save' && parseInt(localStorage.getItem('workflow_state'), 10) > 0}
              >
                {dialogButton}
              </Button>
            </DialogActions>
          </Dialog>
        </CardBody>
      </Card>
    </div >
  );
};
export default withStyles(extendedFormsStyle)(FieldComp);
