export const GET_ALL_APPLICATION_REQUEST = 'GET_ALL_APPLICATION_REQUEST';
export const GET_ALL_APPLICATION_SUCCESS = 'GET_ALL_APPLICATION_SUCCESS';
export const GET_ALL_APPLICATION_FAILURE = 'GET_ALL_APPLICATION_FAILURE';
export const DELETE_APPLICATION_REQUEST = 'DELETE_APPLICATION_REQUEST';
export const DELETE_APPLICATION_SUCCESS = 'DELETE_APPLICATION_SUCCESS';
export const DELETE_APPLICATION_FAILURE = 'DELETE_APPLICATION_FAILURE';
export const GET_APP_CORRES_REQUEST = 'GET_APP_CORRES_REQUEST';
export const GET_APP_CORRES_SUCCESS = 'GET_APP_CORRES_SUCCESS';
export const GET_APP_CORRES_FAILURE = 'GET_APP_CORRES_FAILURE';
export const GET_APP_TRACK_REPORT_REQUEST = 'GET_APP_TRACK_REPORT_REQUEST';
export const GET_APP_TRACK_REPORT_SUCCESS = 'GET_APP_TRACK_REPORT_SUCCESS';
export const GET_APP_TRACK_REPORT_FAILURE = 'GET_APP_TRACK_REPORT_FAILURE';
export const POST_ADMIN_RESTORE_APP_REQUEST = 'POST_ADMIN_RESTORE_APP_REQUEST';
export const POST_ADMIN_RESTORE_APP_SUCCESS = 'POST_ADMIN_RESTORE_APP_SUCCESS';
export const POST_ADMIN_RESTORE_APP_FAILURE = 'POST_ADMIN_RESTORE_APP_FAILURE';
export const GET_APP_TRACK_REPORT_FORM_DATA_REQUEST = 'GET_APP_TRACK_REPORT_FORM_DATA_REQUEST';
export const GET_APP_TRACK_REPORT_FORM_DATA_SUCCESS = 'GET_APP_TRACK_REPORT_FORM_DATA_SUCCESS';
export const GET_APP_TRACK_REPORT_FORM_DATA_FAILURE = 'GET_APP_TRACK_REPORT_FORM_DATA_FAILURE';