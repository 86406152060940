import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import DashboardComp from './Dashboard/DashboardComp';
import * as dashboardActions from 'actions/networkadmin/DashboardActions';

const Dashboard = (props) => {
  const { classes } = props;
  const { dashboard, defaultStates } = props;
  const {
    getDashboard,
    createNetworkBackup,
    restoreNetworkBackup,
    listNetworkBackup,
  } = props.dashboardActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <DashboardComp
        getDashboard={getDashboard}
        createNetworkBackup={createNetworkBackup}
        restoreNetworkBackup={restoreNetworkBackup}
        listNetworkBackup={listNetworkBackup}
        dashboard_data={dashboard.dashboard_data === undefined ? [] : dashboard.dashboard_data}
        requesting={dashboard.requesting}
        refreshNetworkBackupList={dashboard.refreshNetworkBackupList}
        message={dashboard.message}
        network_backup_list={dashboard.network_backup_list === undefined ? [] : dashboard.network_backup_list}
        error_code={dashboard.error_code}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    dashboard: state.naDashboard,
    defaultStates: state.naDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    dashboardActions: bindActionCreators(dashboardActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);