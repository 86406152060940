import {
  GET_TASKDEPENDENCY_REQUEST,
  GET_TASKDEPENDENCY_SUCCESS,
  GET_TASKDEPENDENCY_FAILURE,
  POST_TASKDEPENDENCY_REQUEST,
  POST_TASKDEPENDENCY_SUCCESS,
  POST_TASKDEPENDENCY_FAILURE,
  PATCH_TASKDEPENDENCY_REQUEST,
  PATCH_TASKDEPENDENCY_SUCCESS,
  PATCH_TASKDEPENDENCY_FAILURE,
  DELETE_TASKDEPENDENCY_REQUEST,
  DELETE_TASKDEPENDENCY_SUCCESS,
  DELETE_TASKDEPENDENCY_FAILURE,
} from '../constants/admin/TaskDependency';
import {
  RESET_MESSAGE,
} from '../constants/admin/Default';

const initialState = {
  requesting: false,
  message: '',
  taskDependency_list: [],
  refreshTaskDependency: false,
};

export default function taskDependency(state = initialState, action) {
  switch (action.type) {
    case RESET_MESSAGE:
      return {
        ...state,
        message: action.payload.value,
      };
    case GET_TASKDEPENDENCY_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        taskDependency_list: [],
        refreshTaskDependency: false,
        message: '',
      };

    case GET_TASKDEPENDENCY_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        taskDependency_list: action.payload.data,
      };

    case GET_TASKDEPENDENCY_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case POST_TASKDEPENDENCY_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshTaskDependency: false,
      };

    case POST_TASKDEPENDENCY_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshTaskDependency: true,
      };

    case POST_TASKDEPENDENCY_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case PATCH_TASKDEPENDENCY_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshTaskDependency: false,
      };

    case PATCH_TASKDEPENDENCY_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshTaskDependency: true,
      };

    case PATCH_TASKDEPENDENCY_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case DELETE_TASKDEPENDENCY_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshTaskDependency: false,
      };

    case DELETE_TASKDEPENDENCY_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshTaskDependency: true,
      };

    case DELETE_TASKDEPENDENCY_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
