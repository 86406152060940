import {
  GET_ROLE_REQUEST,
  GET_ROLE_SUCCESS,
  GET_ROLE_FAILURE,
  GET_ADHOC_ROLE_REQUEST,
  GET_ADHOC_ROLE_SUCCESS,
  GET_ADHOC_ROLE_FAILURE,
  POST_ROLE_REQUEST,
  POST_ROLE_SUCCESS,
  POST_ROLE_FAILURE,
  PATCH_ROLE_REQUEST,
  PATCH_ROLE_SUCCESS,
  PATCH_ROLE_FAILURE,
  DELETE_ROLE_REQUEST,
  DELETE_ROLE_SUCCESS,
  DELETE_ROLE_FAILURE,
} from '../constants/admin/Role';
import {
  RESET_MESSAGE,
} from '../constants/admin/Default';

const initialState = {
  requesting: false,
  message: '',
  role_list: [],
  role_adhoc_list: [],
  user_list: '',
  refreshRole: false,
};

export default function role(state = initialState, action) {
  switch (action.type) {
    case RESET_MESSAGE:
      return {
        ...state,
        message: action.payload.value,
      };

    case GET_ROLE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshRole: false,
        message: '',
      };

    case GET_ROLE_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        role_list: action.payload.data,
      };

    case GET_ROLE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_ADHOC_ROLE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
      };

    case GET_ADHOC_ROLE_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        role_adhoc_list: action.payload.data,
      };

    case GET_ADHOC_ROLE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case POST_ROLE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshRole: false,
      };

    case POST_ROLE_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshRole: true,
      };

    case POST_ROLE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case PATCH_ROLE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshRole: false,
      };

    case PATCH_ROLE_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshRole: true,
      };

    case PATCH_ROLE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case DELETE_ROLE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshRole: false,
      };

    case DELETE_ROLE_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshRole: true,
      };

    case DELETE_ROLE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
