import {
  GET_FORMCATEGORY_REQUEST,
  GET_FORMCATEGORY_SUCCESS,
  GET_FORMCATEGORY_FAILURE,
  POST_FORMCATEGORY_REQUEST,
  POST_FORMCATEGORY_SUCCESS,
  POST_FORMCATEGORY_FAILURE,
  PATCH_FORMCATEGORY_REQUEST,
  PATCH_FORMCATEGORY_SUCCESS,
  PATCH_FORMCATEGORY_FAILURE,
  DELETE_FORMCATEGORY_REQUEST,
  DELETE_FORMCATEGORY_SUCCESS,
  DELETE_FORMCATEGORY_FAILURE,
} from '../constants/admin/FormCategory';
import {
  RESET_MESSAGE,
} from '../constants/admin/Default';

const initialState = {
  requesting: false,
  message: '',
  formCategory_list: '',
  refreshFormCategory: false,
};

export default function formCategory(state = initialState, action) {
  switch (action.type) {
    case RESET_MESSAGE:
      return {
        ...state,
        message: action.payload.value,
      };
    case GET_FORMCATEGORY_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshFormCategory: false,
        message: '',
      };

    case GET_FORMCATEGORY_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        formCategory_list: action.payload.data,
      };

    case GET_FORMCATEGORY_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case POST_FORMCATEGORY_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshFormCategory: false,
      };

    case POST_FORMCATEGORY_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshFormCategory: true,
      };

    case POST_FORMCATEGORY_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case PATCH_FORMCATEGORY_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshFormCategory: false,
      };

    case PATCH_FORMCATEGORY_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshFormCategory: true,
      };

    case PATCH_FORMCATEGORY_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case DELETE_FORMCATEGORY_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshFormCategory: false,
      };

    case DELETE_FORMCATEGORY_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshFormCategory: true,
      };

    case DELETE_FORMCATEGORY_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
