import API from 'Api/api.js';
import { commonBackendCall, getAdminConfig } from 'Api/common.js';
import {
  GET_PERMCOMM_REQUEST,
  GET_PERMCOMM_SUCCESS,
  GET_PERMCOMM_FAILURE,
  POST_PERMCOMM_REQUEST,
  POST_PERMCOMM_SUCCESS,
  POST_PERMCOMM_FAILURE,
  PATCH_PERMCOMM_REQUEST,
  PATCH_PERMCOMM_SUCCESS,
  PATCH_PERMCOMM_FAILURE,
  DELETE_PERMCOMM_REQUEST,
  DELETE_PERMCOMM_SUCCESS,
  DELETE_PERMCOMM_FAILURE,
  GET_PERMCOMM_ROLE_REQUEST,
  GET_PERMCOMM_ROLE_SUCCESS,
  GET_PERMCOMM_ROLE_FAILURE,
  GET_COMM_PERMCOMM_REQUEST,
  GET_COMM_PERMCOMM_SUCCESS,
  GET_COMM_PERMCOMM_FAILURE,
} from 'constants/admin/PermComm';

export function getPermCommFromComm(commID) {
  return commonBackendCall(
    GET_COMM_PERMCOMM_REQUEST,
    GET_COMM_PERMCOMM_SUCCESS,
    GET_COMM_PERMCOMM_FAILURE,
    API.get(`admin/perm-comms/comm-name/${commID}`, getAdminConfig()),
  );
}
export function getPermComms() {
  return commonBackendCall(
    GET_PERMCOMM_REQUEST,
    GET_PERMCOMM_SUCCESS,
    GET_PERMCOMM_FAILURE,
    API.get('admin/perm-comms', getAdminConfig()),
  );
}
export function createPermComm(obj) {
  return commonBackendCall(
    POST_PERMCOMM_REQUEST,
    POST_PERMCOMM_SUCCESS,
    POST_PERMCOMM_FAILURE,
    API.post('admin/perm-comms', obj, getAdminConfig()),
  );
}
export function updatePermComm(permCommID, obj) {
  return commonBackendCall(
    PATCH_PERMCOMM_REQUEST,
    PATCH_PERMCOMM_SUCCESS,
    PATCH_PERMCOMM_FAILURE,
    API.patch(`admin/perm-comms/${permCommID}`, obj, getAdminConfig()),
  );
}
export function deletePermComm(permCommID) {
  return commonBackendCall(
    DELETE_PERMCOMM_REQUEST,
    DELETE_PERMCOMM_SUCCESS,
    DELETE_PERMCOMM_FAILURE,
    API.delete(`admin/perm-comms/${permCommID}`, getAdminConfig()),
  );
}
export function getPermCommRoles(commNameID) {
  return commonBackendCall(
    GET_PERMCOMM_ROLE_REQUEST,
    GET_PERMCOMM_ROLE_SUCCESS,
    GET_PERMCOMM_ROLE_FAILURE,
    API.get(`admin/roles-perm/${commNameID}`, getAdminConfig()),
  );
}
