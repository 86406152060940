export const TRANSLATIONS_EN = {
    log_in_title: "LOG IN",
    email: "Email",
    password: "Password",
    google_continue: "Continue with Google",
    log_in_button: "LOG IN",
    register_button: "Register",
    forgot_pswd_button: "Forgot Password",
    err_invalid_email_password: "Invalid Email and Password",
    err_invalid_email: "Invalid Email",
    err_invalid_password: "Invalid Password",
    workflow_selector: "WORKFLOW SELECTOR",
    workflow_selector_logout: "Log Out",
    header_logout: "Log Out",
    header_switch_workflow: "Switch Workflow",
    state_label_draft: 'Draft',
    state_label_test: 'Test',
    state_label_publish: 'Publish',
    state_label_discontinue: 'Discontinue',
    state_label_archive: 'Archive',
    app_list_active_serial: 'Ref#',
    app_list_active_name_init: 'Name of Initiator',
    app_list_active_role_title: 'Your Role',
    app_list_active_title: 'Title',
    app_list_active_start_date: 'Start Date',
    app_list_active_status: 'Status',
    app_list_active_desc: 'Description',
    app_list_active_state: 'On-Hold',
    app_list_archive_serial: 'Ref#',
    app_list_archive_name_init: 'Name of Initiator',
    app_list_archive_title: 'Title',
    app_list_archive_start_date: 'Start Date',
    app_list_archive_status: 'Status',
    app_list_active_head_title: 'Active Items',
    app_list_archive_head_title: 'Archive Items',
    app_list_help_head_title: 'Help',
    app_list_active_status_inaccessible: 'INACCESSIBLE',
    app_list_active_status_accessible: 'ACCESSIBLE',
    app_list_create_new_button: 'Create New',
    app_list_create_new_button_with_default: 'Create (Autotitle)',
    app_list_create_new_button_with_detail: 'Create Multiple',
    app_list_dialog_title: 'Create New',
    app_list_dialog_app_name1: 'Name',
    app_list_dialog_app_name2: 'Application Name',
    app_list_dialog_app_desc: 'Description',
    app_list_dialog_app_status: 'On Hold',
    app_list_dialog_app_button1: 'Create',
    app_list_dialog_app_button2: 'Create Application',
    app_list_dialog_app_button_cancel: 'Cancel',
    mui_table_no_match: "Sorry, no matching records found",
    mui_table_tool_tip: "Sort",
    mui_table_header_tool_tip: "Sort for",
    mui_table_next: "Next Page",
    mui_table_previous: "Previous Page",
    mui_table_rows_per_page: "Rows per page:",
    mui_table_display_rows: "of",
    mui_table_search: "Search",
    mui_table_download_csv: "Download CSV",
    mui_table_print: "Print",
    mui_table_view_columns: "View Columns",
    mui_table_filter_table: "Filter Table",
    mui_table_filter_all: "All",
    mui_table_filter_title: "FILTERS",
    mui_table_filter_reset: "RESET",
    mui_table_column_title: "Show Columns",
    mui_table_column_title_aria: "Show/Hide Table Columns",
    mui_table_selected_text: "row(s) selected",
    mui_table_selected_delete: "Delete",
    mui_table_selected_delete_aria: "Delete Selected Rows",
    app_sub_head_started_on: 'Started On',
    app_sub_head_title: 'Title',
    app_sub_head_your_role: 'Your Role',
    app_sub_head_your_task: 'Your Task',
    app_detail_right_side_submit: 'Submit',
    app_detail_right_side_messages: 'Messages',
    app_detail_right_side_tracking: 'Tracking',
    app_detail_right_side_roles: 'Roles',
    app_detail_right_side_active_tasks: 'Active Tasks',
    app_detail_right_side_help: 'Help',
    app_detail_stepper_submit: 'Submit',
    app_detail_submit_checklist_title: 'Submit Checklist',
    app_detail_submit_checklist_completed: 'Completed',
    app_detail_submit_checklist_some_missing: 'Some fields are incomplete',
    app_detail_submit_checklist_many_missing: 'Many fields are incomplete',
    app_detail_submit_checklist_no_member_selected: 'No Member Selected',
    app_detail_submit_action_title: 'Submit Action',
    app_detail_act_require_all_form_completed: 'Require all forms to be completed',
    app_detail_act_msg_to: 'To',
    app_detail_act_msg_next_task: 'Next Task',
    app_detail_act_msg_cc_email: 'CC Email',
    app_detail_act_msg_subject: 'Subject',
    app_detail_act_msg_message: 'Message',
    app_detail_act_msg_sms_to: 'SMS To',
    app_detail_act_msg_sms_message: 'SMS Message',
    app_detail_act_msg_remind_date: 'Remind Date',
    app_detail_act_msg_confirm_action: 'Confirm Action',
    app_detail_act_msg_submit: 'Submit',
    app_detail_messages_title: 'Messages',
    app_detail_messages_pending_request: 'PENDING REQUESTS',
    app_detail_messages_required_forms: 'REQUIRED FORMS',
    app_detail_messages_message_label: 'Message',
    app_detail_messages_send_at_label: 'Send At',
    app_detail_app_tracking_title: 'Application Tracking',
    app_detail_app_tracking_serial: 'Serial',
    app_detail_app_tracking_task: 'Task',
    app_detail_app_tracking_person: 'Person',
    app_detail_app_tracking_dept: 'Department',
    app_detail_app_tracking_action: 'Action',
    app_detail_app_tracking_action_time: 'Action Time',
    app_detail_roles_title: 'Roles',
    app_detail_roles_serial: 'Serial',
    app_detail_roles_name: 'Name',
    app_detail_roles_role: 'Role',
    app_detail_active_task_title: 'Active Task',
    app_detail_active_task_serial: 'Serial',
    app_detail_active_task_assigned_to: 'Assigned To',
    app_detail_active_task_role: 'Role',
    app_detail_active_task_task_name: 'Task Name',
    app_detail_active_task_application: 'Application',
    app_detail_active_task_status: 'Status',
    app_detail_active_task_pending: 'Pending',
    app_detail_active_task_current_user: 'Current User',
    app_detail_active_task_reminders: 'Reminders',
    app_detail_help_title: 'Help',
    app_detail_comm_no_user_selected: 'No User Selected',
    app_detail_comm_select_user: 'Select',
    app_detail_comm_unselect_user: 'Unselect',
    app_detail_comm_search_user: 'Search User',
    app_detail_comm_search_user_cancel: 'Cancel',
    app_detail_user_search_name_label: 'Name',
    app_detail_user_search_dept_label: 'Department',
    app_detail_user_search_search_label: 'Search User',
    app_detail_user_search_search_button: 'Search',
    app_detail_user_search_no_record: 'No Record Found',
    app_detail_file_sharing_unable_file_upload: 'Unable to upload file',
    app_detail_file_sharing_select_file_btn: 'Select File',
    app_detail_file_sharing_upload_file_btn: 'Upload File',
    app_detail_form_save_previous:'Previous',
    app_detail_form_save_next:'Next',
    app_detail_form_save_save:'Save',
    app_detail_form_week_label1:'Week of',
    app_detail_form_week_label2:'Week of',
    app_status_active_task_title: 'Active Task of ',
    app_status_active_task_application_label: ' Application ',
    app_status_active_task_serial: 'Serial',
    app_status_active_task_assigned_to: 'Assigned To',
    app_status_active_task_role: 'Role',
    app_status_active_task_task_name: 'Task Name',
    app_status_active_task_application: 'Application',
    app_status_active_task_status: 'Status',
    app_status_active_task_reminders: 'Reminder Count:',
    app_status_active_task_msg_sent_date: 'Message Sent At:',
    app_status_active_remind_date: 'Schedule Remind Date:',
    app_status_active_last_remind_date: 'Last Reminder Sent At:',
    app_status_active_task_btn_send_reminder: 'Send or Schedule Reminder',
    app_status_active_task_btn_escalate_setup: 'Escalate Setup',
    app_status_active_task_btn_escalate_to_date: 'Convert to Date',
    app_status_active_task_current_user: 'Current User',
    app_status_active_task_pending: 'Pending',
    app_status_tracking_title: 'Tracking',
    app_status_tracking_serial: 'Serial',
    app_status_tracking_task: 'Task',
    app_status_tracking_person: 'Person',
    app_status_tracking_action: 'Action',
    app_status_tracking_dept: "Department",
    app_status_tracking_action_time: 'Action Time',
    app_snack_bar_success: 'Data Saved!',
    app_snack_bar_error: 'Data Not Saved! Please Try again',
    Start: '',
    Initiate: 'Forward to the next task from the Applicant for the first time in the process.',
    Forward: 'Forward the application to the recipient with email notification. Sender will loose access and recipient will gain access to the application.',
    Close: 'End the process and move the application to archive state.',
    Return: 'Return the application to the recipient with email notification. The sender will loose access to the application.',
    ForwardAlive: 'Forward the application to the recipient with email notification. Sender will not loose access while recipient will also gain access to the application.',
    Share:'Share the application to the recipient with email notification. Recipient will have access to the application along with the sender.',
    Unshare:'Retrieve access of the application from the recipient with email notification.',
    app_detail_item_search_name_label: 'Name',
    app_detail_item_search_sku_label: 'SKU',
    app_detail_item_search_search_label: 'Search Item',
    app_detail_item_search_cancel_label: 'Cancel',
    app_detail_item_search_search_button: 'Search',
    app_detail_item_search_no_record: 'No Record Found',
};
