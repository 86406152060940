/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.jsx';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Typography from '@mui/material/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx';
import PostAddRounded from '@mui/icons-material/PostAddRounded';

const TaskComp = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogButton, setDialogButton] = useState('');
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [dialogType, setDialogType] = useState('add');
  const [data, setData] = useState([]);
  const [message, setMessage] = useState('');
  const [id, setID] = useState('');
  const [title, setTitle] = useState('');
  const [alias, setAlias] = useState('');
  const [roleID, setRoleID] = useState('');
  const [phaseID, setPhaseID] = useState('');
  const [escalateActionID, setEscalateActionID] = useState('');
  const [remindAfter, setRemindAfter] = useState(0);
  const [escalateAfter, setEscalateAfter] = useState(0);
  const [remindAgainAfter, setRemindAgainAfter] = useState(0);
  const [showHideMenu, setShowHideMenu] = useState('');
  const [actionRequired, setActionRequired] = useState('');
  const [autoGenerated, setAutoGenerated] = useState(false);
  const navigate = useNavigate();


  const {
    getRoles, getTasks, getPhases, getActions,
  } = props;
  useEffect(() => {
    getRoles();
    getTasks();
    getPhases();
    getActions();
  }, [getRoles, getTasks, getPhases, getActions]);

  const handleEditClick = (e) => {
    setTitle(e[1]);
    setID(e[0]);
    setRoleID(e[2]);
    setPhaseID(e[3]);
    setRemindAfter(e[4]);
    setRemindAgainAfter(e[5]);
    setActionRequired(e[6]);
    setShowHideMenu(e[7]);
    setAlias(e[8]);
    setAutoGenerated(e[9]);
    setEscalateActionID(e[10]);
    setEscalateAfter(e[11]);
    setMessage('');
    setDialogTitle('Edit');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('update');
    setDialogOpen(true);
  };

  const handleDeleteClick = (e) => {
    setTitle(e[1]);
    setID(e[0]);
    setRoleID(e[2]);
    setPhaseID(e[3]);
    setRemindAfter(e[4]);
    setRemindAgainAfter(e[5]);
    setActionRequired(e[6]);
    setShowHideMenu(e[7]);
    setAlias(e[8]);
    setAutoGenerated(e[9]);
    setEscalateActionID(e[10]);
    setEscalateAfter(e[11]);
    setMessage('');
    setDialogTitle('Delete');
    setDialogButton('Confirm Delete');
    setFieldDisabled(true);
    setDialogType('delete');
    setDialogOpen(true);
  };
  const handleNewClick = (e) => {
    setID('');
    setTitle('');
    setRoleID('');
    setPhaseID('');
    setRemindAfter(0);
    setRemindAgainAfter(0);
    setActionRequired(0);
    setShowHideMenu('');
    setAlias('');
    setAutoGenerated(false);
    setEscalateActionID('');
    setEscalateAfter(0);
    setMessage('');
    setDialogTitle('Add New');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('add');
    setDialogOpen(true);
  };

  useEffect(() => {
    setMessage(props.message);
    if (props.refreshTask) {
      props.getTasks();
      setDialogOpen(false);
    }

    var rowItems = [];
    if (props.task_list.length > 0 && props.role_list.length > 0) {
      for (let i = 0; i < props.task_list.length; i++) {
        var colItems = [];
        colItems.push(i + 1);
        colItems.push(props.task_list[i].alias === '' ? props.task_list[i].title : `${props.task_list[i].title} (${props.task_list[i].alias})`);
        colItems.push(props.task_list[i].alias);
        var roleObj = props.role_list.find((o) => o.id === props.task_list[i].role_id);
        roleObj === undefined ? colItems.push('') : colItems.push(roleObj.alias === '' ? roleObj.title : `${roleObj.title} (${roleObj.alias})`);
        var phaseObj = props.phase_list.find((o) => o.id === props.task_list[i].phase_id);
        phaseObj === undefined ? colItems.push('') : colItems.push(phaseObj.title);
        colItems.push(props.task_list[i].remind_after);
        colItems.push(props.task_list[i].remind_again_after);

        if (localStorage.getItem('task_id') === props.task_list[i].id.toString() || props.task_list[i].auto_generate) {
          colItems.push(
            <div>
              <IconButton title="Task Forms" onClick={() => navigate(`/admin/task-forms/${props.task_list[i].id}`)}><Typography variant="body2">{props.task_list[i].task_form_count}</Typography><PostAddRounded /></IconButton>
              <IconButton
                onClick={handleEditClick.bind(this,
                  [props.task_list[i].id,
                  props.task_list[i].title,
                  props.task_list[i].role_id,
                  props.task_list[i].phase_id,
                  props.task_list[i].remind_after,
                  props.task_list[i].remind_again_after,
                  props.task_list[i].action_required,
                  props.task_list[i].show_hide_menu,
                  props.task_list[i].alias,
                  props.task_list[i].auto_generate,
                  props.task_list[i].escalate_action_id,
                  props.task_list[i].escalate_after,
                  ])}
                title={`Edit Row ID:${props.task_list[i].id}`}
              >
                {' '}
                <EditIcon />
              </IconButton>
            </div>,
          );
        }
        if (localStorage.getItem('task_id') !== props.task_list[i].id.toString() && !props.task_list[i].auto_generate) {
          colItems.push(
            <div>
              <IconButton title="Task Forms" onClick={() => navigate(`/admin/task-forms/${props.task_list[i].id}`)}><Typography variant="body2">{props.task_list[i].task_form_count}</Typography><PostAddRounded /></IconButton>
              <IconButton
                onClick={handleEditClick.bind(this,
                  [props.task_list[i].id,
                  props.task_list[i].title,
                  props.task_list[i].role_id,
                  props.task_list[i].phase_id,
                  props.task_list[i].remind_after,
                  props.task_list[i].remind_again_after,
                  props.task_list[i].action_required,
                  props.task_list[i].show_hide_menu,
                  props.task_list[i].alias,
                  props.task_list[i].auto_generate,
                  props.task_list[i].escalate_action_id,
                  props.task_list[i].escalate_after,
                  ])}
                title={`Edit Row ID:${props.task_list[i].id}`}
              >
                {' '}
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={handleDeleteClick.bind(this,
                  [props.task_list[i].id,
                  props.task_list[i].title,
                  props.task_list[i].role_id,
                  props.task_list[i].phase_id,
                  props.task_list[i].remind_after,
                  props.task_list[i].remind_again_after,
                  props.task_list[i].action_required,
                  props.task_list[i].show_hide_menu,
                  props.task_list[i].alias,
                  props.task_list[i].auto_generate,
                  props.task_list[i].escalate_action_id,
                  props.task_list[i].escalate_after,

                  ])}
                title="Delete row"
              >
                {' '}
                <DeleteIcon />
              </IconButton>
            </div>,
          );
        }
        rowItems.push(colItems);
      }
    }
    setData(rowItems);
  }, [props]);

  const handleDialogClose = (e) => {
    setDialogOpen(false);
    setMessage('');
  };

  const handleDialogConfirm = (e) => {
    if (title === '') {
      setMessage('Invalid Title');
      return;
    }

    if (dialogType === 'add') {
      props.createTask({
        title,
        role_id: roleID.toString(),
        phase_id: phaseID.toString(),
        remind_after: remindAfter.toString(),
        remind_again_after: remindAgainAfter.toString(),
        action_required: actionRequired ? '1' : '0',
        show_hide_menu: showHideMenu,
        alias,
        escalate_action_id: escalateActionID.toString(),
        escalate_after: escalateAfter.toString(),
      });
    } else if (dialogType === 'update') {
      props.updateTask(id, {
        title,
        role_id: roleID.toString(),
        phase_id: phaseID.toString(),
        remind_after: remindAfter.toString(),
        remind_again_after: remindAgainAfter.toString(),
        action_required: actionRequired ? '1' : '0',
        show_hide_menu: showHideMenu,
        alias,
        escalate_action_id: escalateActionID.toString(),
        escalate_after: escalateAfter.toString(),
      });
    } else if (dialogType === 'delete') {
      props.deleteTask(id);
    }
  };
  const onTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const onRoleIDChange = (e) => {
    if (!(localStorage.getItem('task_id') === id.toString() || autoGenerated)) {
      setRoleID(e.target.value);
    }
  };
  const onPhaseIDChange = (e) => {
    if (!(localStorage.getItem('task_id') === id.toString() || autoGenerated)) {
      setPhaseID(e.target.value);
    }
  };
  const onEscalateActionIDChange = (e) => {
    setEscalateActionID(e.target.value);
  };
  const onShowHideMenuChange = (e) => {
    setShowHideMenu(e.target.value);
  };
  const onAliasChange = (e) => {
    setAlias(e.target.value);
  };

  const onRemindAfterChange = (e) => {
    var message = '';
    var letters = /^[0-9]+$/;
    if (!e.target.value.match(letters)) {
      message = 'Invalid First Reminder';
    }
    setRemindAfter(e.target.value);
    setMessage(message);
  };
  const onEscalateAfterChange = (e) => {
    var message = '';
    var letters = /^[0-9]+$/;
    if (!e.target.value.match(letters)) {
      message = 'Invalid First Escalate After';
    }
    setEscalateAfter(e.target.value);
    setMessage(message);
  };
  const onRemindAgainAfterChange = (e) => {
    var message = '';
    var letters = /^[0-9]+$/;
    if (!e.target.value.match(letters)) {
      message = 'Invalid Next Reminder';
    }
    setRemindAgainAfter(e.target.value);
    setMessage(message);
  };
  const onActionRequiredChange = (e) => {
    if (!(localStorage.getItem('task_id') === id.toString() || autoGenerated)) {
      setActionRequired(e.target.checked);
    }
  };

  const { classes } = props;
  /*
  const columns = [
    "Serial",
    "Title",
    "Role",
    "Phase",
    ""
  ];
  */

  const columns = [
    {
      name: 'serial',
      label: 'Serial',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'title',
      label: 'Title',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'alias',
      label: 'Alias',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'role',
      label: 'Role',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'phase',
      label: 'Phase',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'remindAfter',
      label: 'Remind After',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'remindAgainAfter',
      label: 'Remind Again After',
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: '',
      label: '',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    filter: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    selectToolbarPlacement: 'none',
  };
  return (

    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Tasks</b>
          <p className={classes.cardCategoryWhite}>
            Here is the list of the tasks, which are work items in the workflow assigned to each role.
          </p>
        </CardHeader>
        <CardBody>
          <MUIDataTable
            title={(
              <Button
                onClick={handleNewClick.bind(this)}
                color="primary"
                title="Create a new Task"
                disabled={parseInt(localStorage.getItem('workflow_state'), 10) > 0}
                round
              >
                {' '}
                New Task
                {' '}
              </Button>
            )}
            data={data}
            columns={columns}
            options={options}

          />
          <Dialog fullWidth open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              <Card>
                <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                  <b>{dialogTitle}</b>
                </CardHeader>
                <CardBody>
                  <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        required
                        fullWidth
                        label="Title"
                        value={title}
                        onChange={onTitleChange.bind(this)}
                        disabled={fieldDisabled || parseInt(localStorage.getItem('task_id'), 10) === id}
                        InputProps={{ readOnly: localStorage.getItem('task_id') === id.toString() || autoGenerated }}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        required={false}
                        fullWidth
                        label="Alias"
                        value={alias}
                        onChange={onAliasChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled || parseInt(localStorage.getItem('workflow_state'), 10) > 0}>
                        <InputLabel id="roleIDLabel">Role</InputLabel>
                        <Select
                          labelid="roleIDLabel"
                          id="roleIDSelect"
                          value={roleID}
                          onChange={onRoleIDChange.bind(this)}
                        >
                          {props.role_list.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {props.role_list.find((p) => p.id === item.id).title}
                              {props.role_list.find((p) => p.id === item.id).alias !== '' ? ` (${props.role_list.find((p) => p.id === item.id).alias})` : ''}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled || parseInt(localStorage.getItem('workflow_state'), 10) > 0}>
                        <InputLabel id="phaseIDLabel">Phase</InputLabel>
                        <Select
                          labelid="phaseIDLabel"
                          id="phaseIDSelect"
                          value={phaseID}
                          onChange={onPhaseIDChange.bind(this)}
                        >
                          <MenuItem key="empty" value="">None</MenuItem>
                          {props.phase_list.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {props.phase_list.find((p) => p.id === item.id).title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled || parseInt(localStorage.getItem('workflow_state'), 10) > 0}>
                        <InputLabel id="escalateActionIDLabel">Escalate Action</InputLabel>
                        <Select
                          labelid="escalateActionIDLabel"
                          id="escalateActionIDSelect"
                          value={escalateActionID}
                          onChange={onEscalateActionIDChange.bind(this)}
                        >
                          <MenuItem key="empty" value="">None</MenuItem>
                          {props.action_list.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {props.action_list.find((p) => p.id === item.id).title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        required={false}
                        fullWidth
                        label="Escalate After (Days)"
                        value={escalateAfter}
                        onChange={onEscalateAfterChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        required={false}
                        fullWidth
                        label="First Reminder (Days)"
                        value={remindAfter}
                        onChange={onRemindAfterChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        required={false}
                        fullWidth
                        label="Next Reminder (Days)"
                        value={remindAgainAfter}
                        onChange={onRemindAgainAfterChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <InputLabel id="actionRequiredLabel" shrink>Action Required</InputLabel>
                      <Grid item component="label" container alignItems="center" spacing={1}>
                        <Grid item>No</Grid>
                        <Grid item>
                          <Switch
                            labelid="actionRequiredLabel"
                            checked={actionRequired}
                            onChange={onActionRequiredChange.bind(this)}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled={fieldDisabled}
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              icon: classes.switchIcon,
                              iconChecked: classes.switchIconChecked,
                              bar: classes.switchBar,
                            }}
                          />
                        </Grid>
                        <Grid item>Yes</Grid>
                      </Grid>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled || parseInt(localStorage.getItem('workflow_state'), 10) > 0}>
                        <InputLabel id="showHideMenuLabel">Show Hide Menu</InputLabel>
                        <Select
                          labelId="showHideMenuLabel"
                          id="showHideMenuSelect"
                          value={showHideMenu}
                          onChange={onShowHideMenuChange.bind(this)}
                        >
                          <MenuItem value="">None</MenuItem>
                          <MenuItem value="initiate_hide">Hide Menu</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <br />
                </CardBody>
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose.bind(this)} color="primary" round>
                Cancel
              </Button>
              <Button
                onClick={handleDialogConfirm.bind(this)}
                color="primary"
                round
                disabled={message === '' && dialogButton !== 'Save' && parseInt(localStorage.getItem('workflow_state'), 10) > 0}
              >
                {dialogButton}
              </Button>
            </DialogActions>
          </Dialog>
        </CardBody>
      </Card>
    </div>
  );
};

export default withStyles(extendedFormsStyle)(TaskComp);
