import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import AdminComp from './Admin/AdminComp';
import * as adminActions from 'actions/networkadmin/AdminActions';
import * as userActions from 'actions/UserActions';
import * as workflowActions from 'actions/networkadmin/WorkflowActions';

const Admins = (props) => {
  const { classes } = props;
  const { admin, user, workflow, defaultStates } = props;
  const {
    getAdmins,
    createAdmin,
    deleteAdmin,
    setSelectedUser,
  } = props.adminActions;
  const { getUsers, findUsers } = props.userActions;
  const { getWorkflows } = props.workflowActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <AdminComp
        getAdmins={getAdmins}
        getWorkflows={getWorkflows}
        getUsers={getUsers}
        findUsers={findUsers}
        setSelectedUser={setSelectedUser}
        selected_user_id={user.selected_user_id}
        selected_user={user.selected_user}
        admin_list={admin.admin_list.list === undefined ? [] : admin.admin_list.list}
        workflow_list={workflow.workflow_list === undefined ? [] : workflow.workflow_list}
        createAdmin={createAdmin}
        deleteAdmin={deleteAdmin}
        refreshAdmin={admin.refreshAdmin}
        requesting={admin.requesting}
        message={admin.message}
        error_core={admin.error_core}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    admin: state.naAdmin,
    user: state.user,
    workflow: state.naWorkflow,
    defaultStates: state.naDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    adminActions: bindActionCreators(adminActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    workflowActions: bindActionCreators(workflowActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Admins);
