import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button.jsx';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Box from '@material-ui/core/Box';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx';
import Checkbox from '@material-ui/core/Checkbox';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@mui/material/Typography';
import FormHelperText from '@material-ui/core/FormHelperText';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import WeekSelect from './WeekSelectComp';
import FileSharing from './FileSharingComp';

import {
  TEXT_FIELD,
  TEXT_AREA,
  DROP_DOWN,
  RADIO_BUTTON,
  CHECK_BOX,
  DATE,
  TIME,
  HELPTEXT,
  COMMITTEE,
  FILE_SHARING,
  AGGREGATE_NUM,
  WEEK_CALENDAR,
} from 'constants/admin/FieldTypes';

const FormPreviewComp = (props) => {
  const [fields, setFields] = useState([]);
  const [formFields, setFormFields] = useState([]);

  const { getFormFields } = props;
  useEffect(() => {
    getFormFields(props.form_id);
  }, [getFormFields]);

  useEffect(() => {
    var tempFields = [];
    props.form_fields.forEach((field) => {
      var isError = false;
      var value = '';
      var fieldValues = [];
      if (field.value !== '') {
        if (field.type === 'AGGREGATE_NUM') {
          fieldValues = JSON.parse(field.value);
          value = fieldValues.aggregate;
        } else {
          fieldValues = JSON.parse(field.value);
          if (fieldValues.length > 0) {
            value = fieldValues[0];
          }
        }
      }
      tempFields.push({
        id: field.id, formId: props.form_id, type: field.type, valueArray: fieldValues, value, label: field.label, required: field.required, error: isError, field_error_message: field.field_error_message,
      });
    });
    setFields(tempFields);
    setFormFields(props.form_fields)
  }, [props]);


  const onCheckboxChange = (fieldID, e) => {
  };
  const onFieldValueChange = (fieldID, e) => {
  };

  const fieldItems = [];
  formFields.forEach((field) => {
    //props.form_fields.forEach((field) => {
    //fields.forEach((field) => {
    var fieldUI = [];
    if (field.type === TEXT_FIELD) {
      var isDisabled = false;
      /*
      if (field.is_read_only || !form.checkable) {
        isDisabled = true;
      }
      */
      fieldUI.push(
        <TextField
          required={field.required === 'true'}
          error={fields.find((o) => o.id === field.id).error}
          value={fields.find((o) => o.id === field.id).value}
          key={field.id}
          id={`${field.id}`}
          fullWidth
          label={field.label}
          onChange={onFieldValueChange.bind(this, field.id)}
          // disabled={isDisabled}
          InputProps={{ readOnly: isDisabled }}
          helperText={fields.find((o) => o.id === field.id).field_error_message}
        />,
      );
    } else if (field.type === WEEK_CALENDAR) {
      var isDisabled = false;
      /*
      if (field.is_read_only || (field.intg_field_id !== 0) || !form.checkable) {
        isDisabled = true;
      }
      */
      fieldUI.push(
        <WeekSelect
          id={field.id}
          label={field.label}
          disabled={isDisabled}
          required={field.required}
          value={fields.find((o) => o.id === field.id).value}
          error={fields.find((o) => o.id === field.id).error}
          //setWeekSelectField={props.setWeekSelectField}
          //application_id={props.application_id}
          //form_id={form.id}
          requesting={props.requesting}
          //postApplicationDetail={props.postApplicationDetail}
          //task_id={taskID}
          fields={fields}
        />,
      );
    } else if (field.type === AGGREGATE_NUM) {
      fieldUI.push(
        <TextField
          // required={field.required === "true" ? true : false}
          error={fields.find((o) => o.id === field.id).error}
          value={fields.find((o) => o.id === field.id).value}
          key={field.id}
          id={`${field.id}`}
          fullWidth
          label={field.label}
          InputProps={{ readOnly: true }}
          onChange={onFieldValueChange.bind(this, field.id)}
        />,
      );
    } else if (field.type === HELPTEXT) {
      if (field.help_text.length > 0) {
        if (field.help_text.substring(0, 1) === '<') {
          fieldUI.push(
            <div
              dangerouslySetInnerHTML={{ __html: field.help_text }}
            />,
          );
        } else {
          fieldUI.push(
            <Box
              style={{ background: '#EEEEE0' }}
              key={field.id}
            >
              <Typography variant="subtitle1" gutterBottom>
                {field.help_text}
              </Typography>
            </Box>,
          );
        }
      }
    } else if (field.type === TIME) {
      var isDisabled = false;
      /*
      if ((field.is_read_only) || !form.checkable) {
        isDisabled = true;
      }
      */
      fieldUI.push(
        <TextField
          required={field.required === 'true'}
          error={fields.find((o) => o.id === field.id).error}
          value={fields.find((o) => o.id === field.id).value}
          key={field.id}
          id={`${field.id}`}
          type="time"
          // InputLabelProps={{ shrink: true, }}
          fullWidth
          label={field.label}
          onChange={isDisabled ? '' : onFieldValueChange.bind(this, field.id)}
          // disabled={isDisabled}
          helperText={fields.find((o) => o.id === field.id).field_error_message}
          InputLabelProps={{
            shrink: true,
          }}
        />,
      );
    } else if (field.type === DATE) {
      var isDisabled = false;
      /*
      if ((field.is_read_only) || !form.checkable) {
        isDisabled = true;
      }
      */
      fieldUI.push(
        <TextField
          id={`${field.id}`}
          key={field.id}
          label={field.label}
          type="date"
          error={fields.find((o) => o.id === field.id).error}
          value={fields.find((o) => o.id === field.id).value}
          onChange={isDisabled ? '' : onFieldValueChange.bind(this, field.id)}
          // disabled={isDisabled}
          helperText={fields.find((o) => o.id === field.id).field_error_message}
          InputLabelProps={{
            shrink: true,
          }}
        />,
      );
    } else if (field.type === TEXT_AREA) {
      var isDisabled = false;
      /*
      if (field.is_read_only || !form.checkable) {
        isDisabled = true;
      }
      */
      fieldUI.push(
        <TextField
          required={field.required === 'true'}
          error={fields.find((o) => o.id === field.id).error}
          value={fields.find((o) => o.id === field.id).value}
          key={field.id}
          id={`${field.id}`}
          multiline
          rows={field.rows}
          // InputLabelProps={{ shrink: true, }}
          fullWidth
          label={field.label}
          onChange={onFieldValueChange.bind(this, field.id)}
          // disabled={isDisabled}
          InputProps={{ readOnly: isDisabled }}
        />,
      );
    } else if (field.type === CHECK_BOX) {
      var checkboxUI = [];
      var checkboxOptionJSON = JSON.parse(field.options);
      var isDisabled = false;
      /*
      if (field.is_read_only || !form.checkable) {
        isDisabled = true;
      }
      */
      checkboxOptionJSON.options.forEach((checkbox, index) => {
        var valueArray = fields.find((o) => o.id === field.id).valueArray;
        checkboxUI.push(
          <FormControlLabel
            key={index + checkbox.id}
            control={(
              <Checkbox
                id={`${index}${field.id}`}
                key={`${index}${field.id}`}
                color="primary"
                checked={!!valueArray.find((q) => q === checkbox.id)}
                value={checkbox.id}
                onChange={isDisabled ? '' : onCheckboxChange.bind(this, field.id)}
              />
            )}
            label={checkbox.value}
            value={checkbox.id}
          />,
        );
      });
      fieldUI.push(
        <FormControl
          required={field.required === 'true'}
          error={fields.find((o) => o.id === field.id).error}
          key={`${field.id}`}
          id={`${field.id}`}
          component="fieldset"
        >
          <FormLabel>{field.label}</FormLabel>
          <FormGroup key={field.id}>
            {checkboxUI}
          </FormGroup>
          <FormHelperText>{fields.find((o) => o.id === field.id).field_error_message}</FormHelperText>
        </FormControl>,
      );
    } else if (field.type === RADIO_BUTTON) {
      var radioButtonUI = [];
      var radioButtonOptionJSON = JSON.parse(field.options);
      var isDisabled = false;
      /*
      if (field.is_read_only || !form.checkable) {
        isDisabled = true;
      }
      */
       radioButtonOptionJSON.options.forEach((radioButton, index) => {
         radioButtonUI.push(
           <FormControlLabel
             key={`radioButton${index}${field.id}`}
             control={(
               <Radio
                 id={`radioButton${index}${field.id}`}
                 color="primary"
               />
             )}
             label={radioButton.value}
             value={radioButton.id}
           />,
         );
       });
      fieldUI.push(
        <FormControl
          required={field.required === 'true'}
          error={fields.find((o) => o.id === field.id).error}
          id={field.id}
          key={`radioForm${field.id}`}
          // disabled={isDisabled}
          component="fieldset"
        >
          <FormLabel>{field.label}</FormLabel>
          <RadioGroup
            aria-label="radio"
            name="radio"
            value={fields.find((o) => o.id === field.id).value}
            key={`radioGroup${field.id}`}
            onChange={isDisabled ? '' : onFieldValueChange.bind(this, field.id)}
          >
            {radioButtonUI}
          </RadioGroup>
          <FormHelperText>{fields.find((o) => o.id === field.id).field_error_message}</FormHelperText>
        </FormControl>,
      );
    } else if (field.type === DROP_DOWN) {
      var isDisabled = false;
      /*
      if (field.is_read_only || !form.checkable) {
        isDisabled = true;
      }
      */
      var dropdownUI = [];
      var dropdownOptionJSON = JSON.parse(field.options);
      dropdownOptionJSON.options.forEach((dropdown) => {
        dropdownUI.push(
          <MenuItem key={dropdown.id} id={dropdown.id} value={dropdown.id}>{dropdown.value}</MenuItem>,
        );
      });
      fieldUI.push(
        <FormControl
          required={field.required === 'true'}
          error={fields.find((o) => o.id === field.id).error}
          key={field.id}
          fullWidth
          id={field.id}
        >
          <InputLabel id={`label${field.id}`}>{field.label}</InputLabel>
          <Select
            labelId={`label${field.id}`}
            id={`${field.id}`}
            value={fields.find((o) => o.id === field.id).value}
            onChange={isDisabled ? '' : onFieldValueChange.bind(this, field.id)}
            autoWidth
          >
            {dropdownUI}
          </Select>
          <FormHelperText>{fields.find((o) => o.id === field.id).field_error_message}</FormHelperText>
        </FormControl>,
      );
    } else if (field.type === COMMITTEE) {
      fieldUI.push(
        /*
        <Committee
          form_checkable={form.checkable}
          field_required={field.required}
          field_value={fields.find((o) => o.id === field.id).valueArray}
          field_label={field.label}
          field_committee_id={field.committee_name_id}
          key={field.id}
        />,
  */
        <div>User Selection Page Preview Not Available</div>

      );
    } else if (field.type === FILE_SHARING) {
      fieldUI.push(
        <FileSharing
          field_required={field.required}
          field_value={fields.find((o) => o.id === field.id).valueArray}
          field_label={field.label}
          field_id={field.id}
          field_role_id={field.role_id.toString()}
          //role_id={props.role_id}
          //application_id={props.application_id}
          //share_file={props.share_file}
          key={field.id}
          fields={fields}
          //form_id={form.id}
          requesting={props.requesting}
        //postApplicationDetail={props.postApplicationDetail}
        //task_id={taskID}
        />,
      );
    }
    var fieldColspan = 12;
    if (field.colspan !== '') {
      fieldColspan = field.colspan;
    }
    fieldItems.push(
      <GridItem key={`${field.id}`} xs={12} sm={12} md={parseInt(fieldColspan, 10)}>
        <Box mt={2} key={`${field.id}`}>
          {fieldUI}
        </Box>
      </GridItem>,
    );
  });
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>{props.form_field_list.title}</b>
        </CardHeader>
        <CardBody>
          <GridContainer>
            {fieldItems}
          </GridContainer>
        </CardBody>
      </Card>
      <br />
      <Card>
        <CardBody>
          <Link to={"/admin/form-fields/" + props.form_id}>
            <Button size="sm" round color="primary" title="Form Fields">
              {' '}
              <ListRoundedIcon />
              {' '}
              Fields
            </Button>
          </Link>
        </CardBody>
      </Card>
    </div>
  );
};
export default withStyles(extendedFormsStyle)(FormPreviewComp);
