import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import { useTranslation } from 'react-i18next';


const AppHelpComp = (props) => {
  const [helpDescription, setHelpDescription] = useState([]);

  const { t } = useTranslation();
  const { getWorkflowByUser } = props;
  useEffect(() => {
    getWorkflowByUser();
  }, [getWorkflowByUser]);

  useEffect(() => {
    setHelpDescription(props.workflow.description);
  }, [props]);

  return (
    <div>
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <Typography variant="h5" gutterBottom>{t('app_detail_help_title')}</Typography>
        </CardHeader>
        <CardBody>
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: helpDescription }}
          />
        </CardBody>
      </Card>
    </div>
  );
};
export default AppHelpComp;
