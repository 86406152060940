import API from 'Api/api.js';
import { commonBackendCall, getAdminConfig } from 'Api/common.js';
import {
  GET_FORMCATEGORY_REQUEST,
  GET_FORMCATEGORY_SUCCESS,
  GET_FORMCATEGORY_FAILURE,
  POST_FORMCATEGORY_REQUEST,
  POST_FORMCATEGORY_SUCCESS,
  POST_FORMCATEGORY_FAILURE,
  PATCH_FORMCATEGORY_REQUEST,
  PATCH_FORMCATEGORY_SUCCESS,
  PATCH_FORMCATEGORY_FAILURE,
  DELETE_FORMCATEGORY_REQUEST,
  DELETE_FORMCATEGORY_SUCCESS,
  DELETE_FORMCATEGORY_FAILURE,
} from 'constants/admin/FormCategory';

export function getFormCategories() {
  return commonBackendCall(
    GET_FORMCATEGORY_REQUEST,
    GET_FORMCATEGORY_SUCCESS,
    GET_FORMCATEGORY_FAILURE,
    API.get('admin/form-categories', getAdminConfig()),
  );
}
export function createFormCategory(obj) {
  return commonBackendCall(
    POST_FORMCATEGORY_REQUEST,
    POST_FORMCATEGORY_SUCCESS,
    POST_FORMCATEGORY_FAILURE,
    API.post('admin/form-categories', obj, getAdminConfig()),
  );
}
export function updateFormCategory(formCategoriesID, obj) {
  return commonBackendCall(
    PATCH_FORMCATEGORY_REQUEST,
    PATCH_FORMCATEGORY_SUCCESS,
    PATCH_FORMCATEGORY_FAILURE,
    API.patch(`admin/form-categories/${formCategoriesID}`, obj, getAdminConfig()),
  );
}
export function deleteFormCategory(formCategoriesID) {
  return commonBackendCall(
    DELETE_FORMCATEGORY_REQUEST,
    DELETE_FORMCATEGORY_SUCCESS,
    DELETE_FORMCATEGORY_FAILURE,
    API.delete(`admin/form-categories/${formCategoriesID}`, getAdminConfig()),
  );
}
