export const GET_ACTION_REQUEST = 'GET_ACTION_REQUEST';
export const GET_ACTION_SUCCESS = 'GET_ACTION_SUCCESS';
export const GET_ACTION_FAILURE = 'GET_ACTION_FAILURE';
export const POST_ACTION_REQUEST = 'POST_ACTION_REQUEST';
export const POST_ACTION_SUCCESS = 'POST_ACTION_SUCCESS';
export const POST_ACTION_FAILURE = 'POST_ACTION_FAILURE';
export const PATCH_ACTION_REQUEST = 'PATCH_ACTION_REQUEST';
export const PATCH_ACTION_SUCCESS = 'PATCH_ACTION_SUCCESS';
export const PATCH_ACTION_FAILURE = 'PATCH_ACTION_FAILURE';
export const DELETE_ACTION_REQUEST = 'DELETE_ACTION_REQUEST';
export const DELETE_ACTION_SUCCESS = 'DELETE_ACTION_SUCCESS';
export const DELETE_ACTION_FAILURE = 'DELETE_ACTION_FAILURE';
export const GET_TASK_ACTION_REQUEST = 'GET_TASK_ACTION_REQUEST';
export const GET_TASK_ACTION_SUCCESS = 'GET_TASK_ACTION_SUCCESS';
export const GET_TASK_ACTION_FAILURE = 'GET_TASK_ACTION_FAILURE';
export const GET_ACTION_SHARE_REQUEST = 'GET_ACTION_SHARE_REQUEST';
export const GET_ACTION_SHARE_SUCCESS = 'GET_ACTION_SHARE_SUCCESS';
export const GET_ACTION_SHARE_FAILURE = 'GET_ACTION_SHARE_FAILURE';
export const SET_ACTION_RULE = 'SET_ACTION_RULE';
