import API from '../Api/api.js';

import {
  commonBackendCall, getConfig, getNetworkAdminConfig, getAdminConfig,
} from '../Api/common.js';
import {
  SEARCH_USER_REQUEST,
  SEARCH_USER_SUCCESS,
  SEARCH_USER_FAILURE,
  SET_SELECTED_USER,
} from '../constants/User';

export function SearchUser(userType, pageSize, pageNum, orderby, sort, searchStr, applicationID) {
  if (userType === 'networkadmin') {
    return commonBackendCall(
      SEARCH_USER_REQUEST,
      SEARCH_USER_SUCCESS,
      SEARCH_USER_FAILURE,
      API.get(`admin/networkadmin/users?page=${pageNum}&limit=${pageSize}&orderby=${orderby}&sort=${sort}&search=${searchStr}`, getNetworkAdminConfig()),

    );
  } if (userType === 'admin') {
    return commonBackendCall(
      SEARCH_USER_REQUEST,
      SEARCH_USER_SUCCESS,
      SEARCH_USER_FAILURE,
      API.get(`admin/users?page=${pageNum}&limit=${pageSize}&orderby=${orderby}&sort=${sort}&search=${searchStr}`, getAdminConfig()),

    );
  }
  return commonBackendCall(
    SEARCH_USER_REQUEST,
    SEARCH_USER_SUCCESS,
    SEARCH_USER_FAILURE,
    API.get(`applications/users/${applicationID}?page=${pageNum}&limit=${pageSize}&orderby=${orderby}&sort=${sort}&search=${searchStr}`, getConfig()),
  );
}

export function setSelectedUser(user, userID) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_USER,
      payload: {
        user, userID,
      },
    });
  };
}
