/* eslint-disable func-names */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PrintIcon from '@mui/icons-material/Print';
import IconButton from '@mui/material/IconButton';
import ExcelJS from 'exceljs';

import {
  TEXT_FIELD,
  TEXT_AREA,
  DROP_DOWN,
  RADIO_BUTTON,
  CHECK_BOX,
  DATE,
  TIME,
  HELPTEXT,
  COMMITTEE,
  FILE_SHARING,
  AGGREGATE_NUM,
  WEEK_CALENDAR,
  ITEM_SEARCH,
  ITEM_SEARCH2,
  ITEM_DATATABLE,
} from 'constants/FieldTypes';

const FormPrintComp = (props) => {
  const { t } = useTranslation()
  const [formDataFileDownloaded, setFormDataFileDownloaded] = useState(false);
  const [localFormID, setLocalFormID] = useState("");
  const [worksheetData, setWorksheetData] = useState([]);

  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  useEffect(() => {
    if (props.formdata_file !== undefined) {
      if (props.formdata_file.file !== undefined) {
        if (!formDataFileDownloaded) {
          setFormDataFileDownloaded(true)
          if (props.form_id == localFormID) {
            var localFormArray = props.form_array.filter((item) => item.id === parseInt(props.form_id));
            if (localFormArray.length > 0) {
              const element = document.createElement("a");
              const blob = b64toBlob(props.formdata_file.file, { type: "application/octet-stream" });
              var url = URL.createObjectURL(blob);
              element.href = url;
              element.download = localFormArray[0].title + ".xlsm";
              element.click();

            }
          }
        }
      }
    }
  });
  const handlePrintFormData = () => {
    setLocalFormID(props.form_id)
    props.getFormDataFile(props.form_id, props.application_id)
  };
  return (
    <div>
      {props.form_print ?
        <IconButton
          color="inherit"
          aria-label="Download Print Form Data"
          onClick={handlePrintFormData}
          edge="end"
          style={{ float: 'right' }}
        >
          <PrintIcon />
        </IconButton>
        : ""}
    </div>
  );
};

export default FormPrintComp;