import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import { useTranslation } from 'react-i18next';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import 'assets/scss/cp-react.css?v=1.2.0';
import './i18n';

import AppLogin from 'views/Login.jsx';
import AdminLogin from 'views/admin/Login.jsx';
import NetworkAdminLogin from 'views/networkadmin/Login.jsx';

import configureStore from 'store/configureStore';

import applicationRoutes from 'routes/application.jsx';
import applicationPublicRoutes from 'routes/applicationPublic.jsx';
import networkAdminPublicRoutes from 'routes/networkAdminPublic.jsx';
import networkAdminDashboardRoutes from "routes/networkAdminDashboard.jsx";
import adminDashboardRoutes from 'routes/adminDashboard.jsx';
import adminPublicRoutes from 'routes/adminPublic';


import ApplicationPublic from 'layouts/ApplicationPublic.jsx';
import Application from 'layouts/Application.jsx';
import NetworkAdminPublic from 'layouts/NetworkAdminPublic.jsx';
import NetworkAdminDashboard from 'layouts/NetworkAdminDashboard.jsx';
import AdminPublic from 'layouts/AdminPublic.jsx';
import AdminDashboard from 'layouts/AdminDashboard.jsx';

const theme = createTheme();

function App() {
	const { t, i18n } = useTranslation();
	document.body.dir = i18n.dir()

	function getFaviconEl() {
		return document.getElementById('favicon');
	}
	useEffect(() => {
		var title = 'Workflow System';
		if (localStorage.getItem('network_title')) {
			title = localStorage.getItem('network_title');
		}
		document.title = title;

		if (localStorage.getItem('network_icon')) {
			var favicon = getFaviconEl();
			favicon.href = localStorage.getItem('network_icon');
		}
	}, []);
	return (
		<ThemeProvider theme={theme}>
			<Provider store={configureStore()}>
				<BrowserRouter >
					<Routes>
						<Route path={'/'} element={<Application />} >
							<Route index element={< AppLogin />} />
							{applicationRoutes.map((prop, key) => (
								<Route path={prop.path} element={prop.component} key={key} />
							))}
						</Route>
						<Route path={'/public'} element={<ApplicationPublic />} >
							<Route index element={< AppLogin />} />
							{applicationPublicRoutes.map((prop, key) => (
								<Route path={prop.path} element={prop.component} key={key} />
							))}
						</Route>
						<Route path={'/admin'} element={<AdminDashboard />} >
							<Route index element={< AdminLogin />} />
							{adminDashboardRoutes.map((prop, key) => (
								<Route path={prop.path} element={prop.component} key={key} />
							))}
						</Route>
						<Route path={'/admin/public'} element={<AdminPublic />} >
							<Route index element={< AdminLogin />} />
							{adminPublicRoutes.map((prop, key) => (
								<Route path={prop.path} element={prop.component} key={key} />
							))}
						</Route>
						<Route path={'/networkadmin/public'} element={<NetworkAdminPublic />} >
							<Route index element={< NetworkAdminLogin />} />
							{networkAdminPublicRoutes.map((prop, key) => (
								<Route path={prop.path} element={prop.component} key={key} />
							))}
						</Route>
						<Route path={'/networkadmin'} element={<NetworkAdminDashboard />} >
							<Route index element={< NetworkAdminLogin />} />
							{networkAdminDashboardRoutes.map((prop, key) => (
								<Route path={prop.path} element={prop.component} key={key} />
							))}
						</Route>
					</Routes>
				</BrowserRouter>
			</Provider>
		</ThemeProvider>
	);
}

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);
