export const TRANSLATIONS_AR = {
    log_in_title: 'تسجيل الدخول',
    email: 'بريد إلكتروني',
    password: ' كلمه السر',
    google_continue: ' تواصل مع جوجل',
    log_in_button: ' تسجيل الدخول',
    register_button: ' يسجل',
    forgot_pswd_button: ' هل نسيت كلمة السر',
    err_invalid_email_password: ' البريد الإلكتروني وكلمة المرور غير صحيحين',
    err_invalid_email: ' بريد إلكتروني خاطئ',
    err_invalid_password: ' كلمة السر خاطئ',
    workflow_selector: ' محدد سير العمل',
    workflow_selector_logout: ' تسجيل خروج ',
    header_logout: ' تسجيل خروج ',
    header_switch_workflow: ' تبديل سير العمل ',
    state_label_draft: 'مسودة',
    state_label_test: 'اختبار',
    state_label_publish: 'ينشر',
    state_label_discontinue: 'توقف',
    state_label_archive: 'أرشيف',
    app_list_active_serial: 'مسلسل',
    app_list_active_name_init: 'اسم البادئ',
    app_list_active_role_title: 'دورك',
    app_list_active_title: ' لقب',
    app_list_active_start_date: 'تاريخ البدء',
    app_list_active_status: ' حالة ',
    app_list_active_desc: "Description",
    app_list_active_state: 'On-Hold',
    app_list_archive_serial: 'مسلسل',
    app_list_archive_name_init: 'اسم البادئ',
    app_list_archive_title: ' لقب',
    app_list_archive_start_date: 'تاريخ البدء',
    app_list_archive_status: ' حالة ',
    app_list_active_head_title: 'العناصر النشطة',
    app_list_archive_head_title: ' العناصر المؤرشفة ',
    app_list_help_head_title: 'مساعدة',
    app_list_active_status_inaccessible: 'لا يمكن الوصول إليه',
    app_list_active_status_accessible: 'يمكن الوصول',
    app_list_create_new_button: 'إنشاء جديد',
    app_list_create_new_button_with_default: 'Create New (with default)',
    app_list_create_new_button_with_detail: 'Create New (with detail)',
    app_list_dialog_title: 'إنشاء جديد',
    app_list_dialog_app_name1: 'اسم',
    app_list_dialog_app_name2: 'اسم التطبيق',
    app_list_dialog_app_desc: 'Description',
    app_list_dialog_app_status: 'On Hold',
    app_list_dialog_app_button1: ' إنشاء ',
    app_list_dialog_app_button2: ' إنشاء التطبيق ',
    app_list_dialog_app_button_cancel: 'يلغي',
    mui_table_no_match: ' عذرا ، لا توجد سجلات مطابقة',
    mui_table_tool_tip: ' فرز',
    mui_table_header_tool_tip: ' فرز ل ',
    mui_table_next: ' الصفحة التالية',
    mui_table_previous: ' الصفحة السابقة',
    mui_table_rows_per_page: ' عدد الصفوف في الصفحة:',
    mui_table_display_rows: ' من',
    mui_table_search: ' يبحث',
    mui_table_download_csv: ' تنزيل ملف CSV ',
    mui_table_print: ' مطبعة',
    mui_table_view_columns: ' اعرض الأعمدة',
    mui_table_filter_table: ' جدول التصفية',
    mui_table_filter_all: ' الجميع',
    mui_table_filter_title: ' المرشحات',
    mui_table_filter_reset: ' إعادة تعيين',
    mui_table_column_title: ' اعرض الأعمدة',
    mui_table_column_title_aria: 'إظهار / إخفاء أعمدة الجدول',
    mui_table_selected_text: ' الصفوف المختارة',
    mui_table_selected_delete: ' حذف',
    mui_table_selected_delete_aria: ' حذف الصفوف المحددة',
    app_sub_head_started_on: 'بدأ في',
    app_sub_head_title: ' لقب',
    app_sub_head_your_role: 'دورك',
    app_sub_head_your_task: ' مهمتك',
    app_detail_right_side_submit: 'إرسال',
    app_detail_right_side_messages: 'رسائل',
    app_detail_right_side_tracking: 'تتبع',
    app_detail_right_side_roles: 'الأدوار',
    app_detail_right_side_active_tasks: 'المهام النشطة',
    app_detail_right_side_help: 'مساعدة',
    app_detail_stepper_submit: 'إرسال',
    app_detail_submit_checklist_title: 'إرسال قائمة التحقق',
    app_detail_submit_checklist_completed: 'مكتمل',
    app_detail_submit_checklist_some_missing: 'بعض الحقول غير مكتملة',
    app_detail_submit_checklist_many_missing: 'العديد من الحقول غير مكتملة',
    app_detail_submit_checklist_no_member_selected: 'لم يتم تحديد عضو',
    app_detail_submit_action_title: 'إرسال الإجراء',
    app_detail_act_require_all_form_completed: 'طلب استكمال جميع النماذج',
    app_detail_act_msg_to: 'الى',
    app_detail_act_msg_next_task: 'المهمة التالية',
    app_detail_act_msg_cc_email: 'CC البريد الإلكتروني',
    app_detail_act_msg_subject: 'موضوع',
    app_detail_act_msg_message: 'رسالة',
    app_detail_act_msg_sms_to: 'SMS الى',
    app_detail_act_msg_sms_message: 'SMS رسالة',
    app_detail_act_msg_remind_date: 'Remind Date',
    app_detail_act_msg_confirm_action: 'تأكيد الإجراء',
    app_detail_act_msg_submit: 'إرسال',
    app_detail_messages_title: 'رسائل',
    app_detail_messages_pending_request: 'الطلبات المعلقة',
    app_detail_messages_required_forms: 'النماذج المطلوبة',
    app_detail_messages_message_label: 'رسالة',
    app_detail_messages_send_at_label: 'أرسل في',
    app_detail_app_tracking_title: 'تتبع التطبيق',
    app_detail_app_tracking_serial: 'مسلسل',
    app_detail_app_tracking_task: 'مهمة',
    app_detail_app_tracking_person: 'شخص',
    app_detail_app_tracking_dept: 'Department',
    app_detail_app_tracking_action: 'عمل',
    app_detail_app_tracking_action_time: 'وقت العمل',
    app_detail_roles_title: 'الأدوار',
    app_detail_roles_serial: 'مسلسل',
    app_detail_roles_name: 'اسم',
    app_detail_roles_role: 'دور',
    app_detail_active_task_title: 'المهام النشطة',
    app_detail_active_task_serial: 'مسلسل',
    app_detail_active_task_assigned_to: 'مخصص ل',
    app_detail_active_task_role: 'دور',
    app_detail_active_task_task_name: 'اسم المهمة',
    app_detail_active_task_application: 'طلب',
    app_detail_active_task_status: 'حالة',
    app_detail_active_task_pending: 'قيد الانتظار',
    app_detail_active_task_current_user: 'المستخدم الحالي',
    app_detail_active_task_reminders: 'تذكير',
    app_detail_help_title: 'مساعدة',
    app_detail_comm_no_user_selected: 'لم يتم تحديد مستخدم',
    app_detail_comm_select_user: 'اختر المستخدم',
    app_detail_comm_unselect_user: 'مسح المستخدم',
    app_detail_comm_search_user: 'بحث عن المستخدم',
    app_detail_comm_search_user_cancel: 'يلغي',
    app_detail_user_search_name_label: 'اسم',
    app_detail_user_search_dept_label: 'قسم',
    app_detail_user_search_search_label: 'بحث عن المستخدم',
    app_detail_user_search_search_button: 'يبحث',
    app_detail_user_search_no_record: 'لا يوجد سجلات',
    app_detail_file_sharing_unable_file_upload: 'لا اتمكن من تحميل الملف',
    app_detail_file_sharing_select_file_btn: 'اختر ملف',
    app_detail_file_sharing_upload_file_btn: 'رفع ملف',
    app_detail_form_save_previous: 'سابق',
    app_detail_form_save_next: 'التالي',
    app_detail_form_save_save: 'يحفظ',
    app_detail_form_week_label1: 'أسبوع من',
    app_detail_form_week_label2: 'أسبوع من',
    app_status_active_task_title: 'مهمة نشطة من',
    app_status_active_task_application_label: ' طلب ',
    app_status_active_task_serial: 'مسلسل',
    app_status_active_task_assigned_to: 'مخصص ل',
    app_status_active_task_role: 'دور',
    app_status_active_task_task_name: 'اسم المهمة',
    app_status_active_task_application: 'طلب',
    app_status_active_task_status: 'حالة',
    app_status_active_task_reminders: 'Reminder Count:',
    app_status_active_task_msg_sent_date: 'Message Sent At:',
    app_status_active_remind_date: 'Schedule Remind Date:',
    app_status_active_last_remind_date: 'Last Remind Date:',
    app_status_active_task_btn_send_reminder: 'Send or Schedule Reminder',
    app_status_active_task_btn_escalate_setup: 'Escalate Setup',
    app_status_active_task_btn_escalate_to_date: 'Convert to Date',
    app_status_active_task_current_user: 'المستخدم الحالي',
    app_status_active_task_pending: 'قيد الانتظار',
    app_status_tracking_title: 'تتبع',
    app_status_tracking_serial: 'مسلسل',
    app_status_tracking_task: 'المهمة',
    app_status_tracking_person: 'شخص',
    app_status_tracking_action: 'عمل',
    app_status_tracking_dept: "Department",
    app_status_tracking_action_time: 'وقت العمل',
    app_snack_bar_success: 'البيانات المحفوظة!',
    app_snack_bar_error: 'لم يتم حفظ البيانات! حاول مرة اخرى',
    Start: '',
    Initiate: 'إعادة توجيه إلى المهمة التالية من مقدم الطلب لأول مرة في سير العمل',
    Forward: 'إعادة توجيه التطبيق إلى المستلم مع إشعار البريد الإلكتروني. سيفقد المرسل الوصول وسيحصل المستلم على حق الوصول إلى التطبيق',
    Close: 'قم بإنهاء العملية ونقل التطبيق إلى حالة الأرشفة',
    Return: 'إعادة التطبيق إلى المستلم مع إشعار البريد الإلكتروني. سيفقد المرسل الوصول إلى التطبيق',
    ForwardAlive: 'إعادة توجيه التطبيق إلى المستلم مع إشعار البريد الإلكتروني. لن يفقد المرسل الوصول بينما سيحصل المستلم أيضًا على حق الوصول إلى التطبيق',
    Share: 'شارك التطبيق مع المستلم مع إشعار البريد الإلكتروني. سيتمكن المستلم من الوصول إلى التطبيق مع المرسل',
    Unshare: 'استرجع الوصول إلى التطبيق من المستلم من خلال إشعار البريد الإلكتروني',
    app_detail_item_search_name_label: 'Name',
    app_detail_item_search_sku_label: 'SKU',
    app_detail_item_search_search_label: 'Search Item',
    app_detail_item_search_cancel_label: 'Cancel',
    app_detail_item_search_search_button: 'Search',
    app_detail_item_search_no_record: 'No Record Found',
};