export const GET_DEPARTMENT_REQUEST = 'GET_DEPARTMENT_REQUEST';
export const GET_DEPARTMENT_SUCCESS = 'GET_DEPARTMENT_SUCCESS';
export const GET_DEPARTMENT_FAILURE = 'GET_DEPARTMENT_FAILURE';
export const GET_DEPT_INTG_REQUEST = 'GET_DEPT_INTG_REQUEST';
export const GET_DEPT_INTG_SUCCESS = 'GET_DEPT_INTG_SUCCESS';
export const GET_DEPT_INTG_FAILURE = 'GET_DEPT_INTG_FAILURE';
export const POST_DEPT_REQUEST = 'POST_DEPT_REQUEST';
export const POST_DEPT_SUCCESS = 'POST_DEPT_SUCCESS';
export const POST_DEPT_FAILURE = 'POST_DEPT_FAILURE';
export const PATCH_DEPT_REQUEST = 'PATCH_DEPT_REQUEST';
export const PATCH_DEPT_SUCCESS = 'PATCH_DEPT_SUCCESS';
export const PATCH_DEPT_FAILURE = 'PATCH_DEPT_FAILURE';
export const DELETE_DEPT_REQUEST = 'DELETE_DEPT_REQUEST';
export const DELETE_DEPT_SUCCESS = 'DELETE_DEPT_SUCCESS';
export const DELETE_DEPT_FAILURE = 'DELETE_DEPT_FAILURE';
export const PATCH_DEPT_INTG_REQUEST = 'PATCH_DEPT_INTG_REQUEST';
export const PATCH_DEPT_INTG_SUCCESS = 'PATCH_DEPT_INTG_SUCCESS';
export const PATCH_DEPT_INTG_FAILURE = 'PATCH_DEPT_INTG_FAILURE';
export const GET_DEPT_CSV_REQUEST = 'GET_DEPT_CSV_REQUEST';
export const GET_DEPT_CSV_SUCCESS = 'GET_DEPT_CSV_SUCCESS';
export const GET_DEPT_CSV_FAILURE = 'GET_DEPT_CSV_FAILURE';
