import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import IconButton from '@mui/material/IconButton';
import Logout from '@mui/icons-material/SettingsPower';

import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import { useTranslation } from 'react-i18next';
import withRoot from 'withRoot';
import WorkflowItemComp from './WorkflowItemComp.jsx';

const ChooseWorkflowComp = (props) => {
  const [message, setMessage] = useState('');
  const [cardAnimaton, setCardAnimaton] = useState('cardHidden');
  const [workflowList, setWorkflowList] = useState([]);
  const { t } = useTranslation();

  const { getNetworkWorkflows } = props;
  useEffect(() => {
    getNetworkWorkflows();
    setTimeout(() => {
      setCardAnimaton('');
    }, 500);
  }, [getNetworkWorkflows]);

  useEffect(() => {
    setMessage(props.message);
    setWorkflowList(props.workflow_list);
  }, [props]);

  const handleLogout = (e) => {
    // localStorage.clear();
    localStorage.removeItem('network_user_token');
    localStorage.removeItem('token');
    window.location.href = '/public/login';
  };

  const listItems = [];
  workflowList.forEach((workflow) => {
    listItems.push(
      <WorkflowItemComp
        workflow={workflow}
        onLogin={props.onLogin} />
    );
  });

  const { classes } = props;

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={6} md={12}>
        <Card className={classes[cardAnimaton]}>
          _
          <CardHeader color="primary" plain style={{ textAlign: 'center' }}>
            <b>{t("workflow_selector")} </b>
          </CardHeader>
          <CardBody>
            <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
            <GridContainer>
              <GridItem xs={12} sm={6} md={12}>
                <IconButton title={t('workflow_selector_logout')} style={{ float: 'right' }} edge="end" onClick={handleLogout} className={classes.menuButton} color="inherit" aria-label="menu">
                  <Logout style={{ color: '#e30c05', paddingLeft: '1px' }} />
                </IconButton>
              </GridItem>
            </GridContainer>
            <GridContainer>
              {listItems}
            </GridContainer>
          </CardBody>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default withRoot(ChooseWorkflowComp);
