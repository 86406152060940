import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.jsx';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import TextField from '@material-ui/core/TextField';
import Email from '@mui/icons-material/Email';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import RestartAltIcon from '@mui/icons-material//RestartAlt';
import Select from '@material-ui/core/Select';

const ApplicationComp = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogButton, setDialogButton] = useState('');
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [dialogType, setDialogType] = useState('add');
  const [data, setData] = useState([]);
  const [trackingData, setTrackingData] = useState([]);
  const [message, setMessage] = useState('');
  const [queryMessage, setQueryMessage] = useState('');
  const [id, setID] = useState('');
  const [appName, setAppName] = useState('');
  const [taskID, setTaskID] = useState('');
  const [reminder, setReminder] = useState('NONE');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [formDataFileDownloaded, setFormDataFileDownloaded] = useState(false);
  const navigate = useNavigate();


  const { getAllApplications, getApplicationTrackReport, getApplicationTrackFormDataReport, getTasks } = props;
  useEffect(() => {
    getAllApplications();
    getTasks();

  }, [getAllApplications, getApplicationTrackReport, getApplicationTrackFormDataReport, getTasks]);

  const handleDeleteClick = (e) => {
    setID(e[0]);
    setAppName(e[1]);
    setMessage('');
    setDialogTitle('Delete');
    setDialogButton('Confirm Delete');
    setFieldDisabled(true);
    setDialogType('delete');
    setDialogOpen(true);
  };
  const handleRestoreClick = (e) => {
    setID(e[0]);
    setAppName(e[1]);
    setMessage('');
    setDialogTitle('Restore Archive');
    setDialogButton('Confirm Restore');
    setFieldDisabled(true);
    setDialogType('restore');
    setDialogOpen(true);
  };
  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  useEffect(() => {
    if (props.application_track_form_data_report) {
      if (!formDataFileDownloaded) {
        setFormDataFileDownloaded(true)
        const element = document.createElement("a");
        const blob = b64toBlob(props.application_track_form_data_report.file, { type: "application/octet-stream" });
        var url = URL.createObjectURL(blob);
        element.href = url;
        //element.download = localFormArray[0].title + ".xlsm";
        element.download = "taskDataReport" + ".xls";
        element.click();
      }
    }
    setQueryMessage('')
    setMessage(props.message);
    if (props.refreshApplication) {
      props.getAllApplications();
      setDialogOpen(false);
    }
    var rowItems = [];
    for (let i = 0; i < props.application_list.length; i++) {
      if (props.application_list[i].app_name !== '') {
        var date = new Date(props.application_list[i].start_date);
        var colItems = [];
        //colItems.push(i + 1);
        colItems.push(props.application_list[i].id);
        colItems.push(props.application_list[i].app_name);
        colItems.push(props.application_list[i].user_id);
        colItems.push(props.application_list[i].user_name + " (" + props.application_list[i].user_email + ")");
        colItems.push(props.application_list[i].user_rank);
        colItems.push(props.application_list[i].user_dept);
        colItems.push(`${date.toDateString()} ${date.toLocaleTimeString()}`);
        colItems.push(props.application_list[i].application_closed ? 'Yes' : 'No');
        colItems.push(props.application_list[i].application_test ? 'Yes' : 'No');
        colItems.push(
          <div>
            <IconButton
              onClick={handleDeleteClick.bind(this,
                [props.application_list[i].id, props.application_list[i].app_name])}
              title="Delete row"
            >
              {' '}
              <DeleteIcon />
            </IconButton>
            {props.application_list[i].application_closed ?
              <IconButton
                onClick={handleRestoreClick.bind(this,
                  [props.application_list[i].id,
                  props.application_list[i].app_name])}
                title="Restore Archived"
              >
                {' '}
                <RestartAltIcon />
              </IconButton>
              : ""}
            <IconButton title="Correspondences" onClick={() => navigate(`/admin/application-correspondences/${props.application_list[i].id}`)}><Email /></IconButton>

          </div>,
        );
        rowItems.push(colItems);
      }
    }
    setData(rowItems);
    var rowTrackItems = [];
    for (let i = 0; i < props.application_track_report.length; i++) {
      if (props.application_track_report[i].app_name !== '') {
        var date = new Date(props.application_track_report[i].insert_date);
        var colItems = [];
        colItems.push(props.application_track_report[i].app_name);
        colItems.push(`${date.toDateString()} ${date.toLocaleTimeString()}`);
        colItems.push(props.application_track_report[i].task_alias !== "" ? props.application_track_report[i].task_alias : props.application_track_report[i].task_title);
        colItems.push(props.application_track_report[i].action_title);
        colItems.push(props.application_track_report[i].role_alias !== "" ? props.application_track_report[i].role_alias : props.application_track_report[i].role_title);
        rowTrackItems.push(colItems);
      }
    }
    setTrackingData(rowTrackItems);
  }, [props]);

  const handleDialogClose = (e) => {
    setDialogOpen(false);
    setMessage('');
  };
  const handleDialogConfirm = (e) => {
    if (dialogType === 'delete') {
      props.deleteApplication(id);
    } else if (dialogType === 'restore') {
      props.adminRestoreArchiveApplication(id);
    }
  };



  const handleQueryReset = (e) => {
    setMessage('');
    setTaskID('');
    setStartDate('');
    setEndDate('');
    setReminder('NONE');
  };
  const onTaskIDChange = (e) => {
    setFormDataFileDownloaded(false)
    setTaskID(e.target.value);
  };
  const onReminderChange = (e) => {
    setReminder(e.target.value);
  };
  const onStartDateChange = (e) => {
    setStartDate(e.target.value);
  };
  const onEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  const handleQueryConfirm = (e) => {
    setQueryMessage("");
    if (taskID === "") {
      setQueryMessage("Task is a required field")
      return
    }
    getApplicationTrackReport(taskID.toString(), startDate.toString(), endDate.toString(), reminder);
  };
  const handleQueryFormDataConfirm = (e) => {
    setQueryMessage("");
    if (taskID === "") {
      setQueryMessage("Task is a required field")
      return
    }
    getApplicationTrackFormDataReport(taskID.toString(), startDate.toString(), endDate.toString());
  };
  const { classes } = props;
  const trackingColumns = [
    'Application',
    'Start Date',
    'Task',
    'Action',
    'Role',
  ];
  const columns = [
    'Ref#',
    'Title',
    'User ID',
    'Name',
    'Rank',
    'Dept',
    'Started On',
    'Closed',
    'Test',
    '',
  ];


  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    filter: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    selectToolbarPlacement: 'none',
  };
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Applications</b>
        </CardHeader>
        <CardBody>
          <MUIDataTable
            title=""
            data={data}
            columns={columns}
            options={options}
          />
          <Dialog fullWidth open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              <Card>
                <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                  <b>{dialogTitle}</b>
                </CardHeader>
                <CardBody>
                  <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        required
                        fullWidth
                        label="Title"
                        value={appName}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter style={{ textAlign: 'left' }} />
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose.bind(this)} color="primary" round>
                Cancel
              </Button>
              <Button onClick={handleDialogConfirm.bind(this)} color="primary" round>
                {dialogButton}
              </Button>
            </DialogActions>
          </Dialog>
        </CardBody>
      </Card>
      <br />
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Task Tracking</b>
        </CardHeader>
        <CardBody>

          <Card>
            <CardBody>
              <div style={{ color: 'red', textAlign: 'center' }}>{queryMessage}</div>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <FormControl fullWidth disabled={false}>
                    <InputLabel id="taskIDLabel">Task</InputLabel>
                    <Select
                      labelid="taskIDLabel"
                      id="taskIDSelect"
                      value={taskID}
                      onChange={onTaskIDChange.bind(this)}
                    >
                      {props.task_list.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                          {props.task_list.find((p) => p.id === item.id).alias === '' ? props.task_list.find((p) => p.id === item.id).title : `${props.task_list.find((p) => p.id === item.id).title} (${props.task_list.find((p) => p.id === item.id).alias})`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required={false}
                    fullWidth
                    type={"date"}
                    label="Start Date"
                    value={startDate}
                    onChange={onStartDateChange.bind(this)}
                    disabled={false}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required={false}
                    fullWidth
                    type={"date"}
                    label="End Date"
                    value={endDate}
                    onChange={onEndDateChange.bind(this)}
                    disabled={false}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={8}>
                  <FormControl fullWidth disabled={false}>
                    <InputLabel id="reminderLabel">Task Reminder</InputLabel>
                    <Select
                      labelid="reminderLabel"
                      id="reminderSelect"
                      value={reminder}
                      onChange={onReminderChange.bind(this)}
                    >
                      <MenuItem key={'NONE'} value={'NONE'}>Do Not Include Reminder In Query </MenuItem>
                      <MenuItem key={'0'} value={'0'}>No Reminder Sent </MenuItem>
                      <MenuItem key={'1'} value={'1'}>At Least One Reminder Sent </MenuItem>
                      <MenuItem key={'2'} value={'2'}>More Than One Reminders Sent </MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <Button onClick={handleQueryFormDataConfirm.bind(this)} style={{ float: 'right' }} color="primary" round>
                    Download Form Data (Excel)
                  </Button>
                  <Button onClick={handleQueryConfirm.bind(this)} style={{ float: 'right' }} color="primary" round>
                    Search
                  </Button>
                  <Button onClick={handleQueryReset.bind(this)} style={{ float: 'right' }} color="primary" round>
                    Reset Form
                  </Button>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
          <br />
          <MUIDataTable
            title={"Search Result"}
            data={trackingData}
            columns={trackingColumns}
            options={options}
          />
        </CardBody>
      </Card>
    </div>
  );
};
export default ApplicationComp;
