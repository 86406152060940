import React, { useState, useEffect } from 'react';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx';
import TextField from '@material-ui/core/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Chip from '@material-ui/core/Chip';

const VisibleRuleComp = (props) => {
  const [condition, setCondition] = useState('');
  const [selectedField, setSelectedField] = useState('');
  const [ruleValue, setRuleValue] = useState('');
  const [logicalOpt, setLogicalOpt] = useState('AND');
  const [disabled, setDisabled] = useState(false);
  const [message, setMessage] = useState('');
  const [rules, setRules] = useState([]);
  const [roleIDs, setRoleIDs] = useState([]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const {
    setVisibleRule,
  } = props;
  useEffect(() => {
    setVisibleRule([]);
  }, [setVisibleRule]);

  useEffect(() => {
    if (props.field_visible_rule) {
      if (props.field_visible_rule !== '[]' && props.field_visible_rule !== '') {
        var ruleComplete = JSON.parse(props.field_visible_rule);
        setRules(ruleComplete.rules);
        setLogicalOpt(ruleComplete.logic);
        setRoleIDs(ruleComplete.role_ids.map((v) => parseInt(v, 10)));
        props.setVisibleRule(ruleComplete);
      }
    }
    setDisabled(props.disabled);
  }, []);

  useEffect(() => {
  }, [props]);

  const onRuleValueChange = (e) => {
    setRuleValue(e.target.value);
  };
  const onLogicalOptValueChange = (e) => {
    setLogicalOpt(e.target.value);
    props.setVisibleRule({ "logic": e.target.value, "role_ids": roleIDs, "rules": rules });
  };
  const onConditionChange = (e) => {
    setCondition(e.target.value);
  };
  const onSelectedFieldChange = (e) => {
    setSelectedField(e.target.value);
  };

  const onHandleRoleIDChange = (e) => {
    setRoleIDs(e.target.value);
    props.setVisibleRule({ "logic": logicalOpt, "role_ids": e.target.value.map((e) => e.toString()), "rules": rules });
  };

  const handleAddClick = (e) => {
    setMessage('');
    if (selectedField === '') {
      setMessage('Please Select Field');
      return;
    }
    if (condition === '') {
      setMessage('Please Select Condition');
      return;
    }
    // if (ruleValue === '') {
    //   setMessage('Please Select Value');
    //   return;
    // }
    //if (condition !== '' && ruleValue !== '' && selectedField !== "") {
    var rule = {
      field_id: selectedField.toString(),
      condition: condition,
      value: ruleValue,
    };
    setRules([...rules, rule]);
    setMessage('');
    setRuleValue('');
    setCondition('');
    setSelectedField('');
    props.setVisibleRule({ "logic": logicalOpt, "role_ids": roleIDs, "rules": [...rules, rule] });

  }

  const handleDeleteClick = (index, e) => {
    const tempRules = rules.slice();
    tempRules.splice(index, 1);
    setRules(tempRules);
    setMessage('');
    props.setVisibleRule({ "logic": logicalOpt, "rules": tempRules });
  }

  const { classes } = props;
  const ruleItems = [];
  rules.forEach((rule, index) => {
    ruleItems.push(
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            <FormControl fullWidth className={classes.formControl} disabled>
              <InputLabel id="fieldlabel">Fields</InputLabel>
              <Select
                labelId="fieldlabel"
                id="fieldsselect"
                value={rule.field_id}
              >
                {props.fields.map((field) => (
                  <MenuItem key={field.id} value={field.id}>
                    {`${field.form_name} -> ${field.label}`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <FormControl fullWidth className={classes.formControl} disabled>
              <InputLabel id="typelabel">Condition</InputLabel>
              <Select
                labelId="typelabel"
                id="typeselect"
                value={rule.condition}
              >
                <MenuItem key="==" value="=="> equal to</MenuItem>
                <MenuItem key="!=" value="!="> not equal to</MenuItem>
                <MenuItem key=">" value=">"> greater than </MenuItem>
                <MenuItem key=">=" value=">="> greater than equal </MenuItem>
                <MenuItem key="<" value="<"> less than </MenuItem>
                <MenuItem key="<=" value="<="> less than equal </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <TextField
              required={false}
              fullWidth
              label="Value"
              value={rule.value}
              disabled
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            {disabled ? '' : (
              <IconButton onClick={handleDeleteClick.bind(this, index)}>
                <DeleteIcon />
              </IconButton>
            )}
          </GridItem>
        </GridContainer>
      </div>,
    );
    ruleItems.push(<br key={`linebreak${index}`} />);
  });
  return (
    <div>
      <GridContainer key="logicalOperator">
        <GridItem xs={12} sm={12} md={2}>
          <br />
          <FormControl fullWidth className={classes.formControl} disabled={disabled}>
            <InputLabel id="logic">Logical Operator</InputLabel>
            <Select
              labelId="logical"
              id="logicalSelect"
              value={logicalOpt}
              onChange={onLogicalOptValueChange.bind(this)}
            >
              <MenuItem key={'AND'} value={'AND'}> AND</MenuItem>
              <MenuItem key={'OR'} value={'OR'}> OR</MenuItem>
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={10}>
          <br />
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel id="rolelabel">Roles</InputLabel>
            {props.role_list != undefined ?
              <Select
                labelId="rolelabel"
                id="roleSelect"
                multiple
                value={roleIDs}
                onChange={onHandleRoleIDChange.bind(this)}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value) => (
                      <Chip
                        key={value}
                        label={
                          props.role_list.find((element) => element.id === parseInt(value, 10)).alias !== '' ? `${props.role_list.find((element) => element.id === parseInt(value, 10)).title + ' (' + props.role_list.find((element) => element.id === parseInt(value, 10)).alias})` : `${props.role_list.find((element) => element.id === parseInt(value, 10)).title}`
                        }
                        style={{ display: 'flex', flexWrap: 'wrap' }}
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {props.role_list.map((role) => (
                  <MenuItem key={role.id} value={role.id}>
                    {role.alias !== '' ? `${role.title} (${role.alias})` : `${role.title}`}
                  </MenuItem>
                ))}
              </Select>
              : ""}
          </FormControl>
        </GridItem>
      </GridContainer>
      <br />
      {ruleItems}
      <br />
      <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
      <GridContainer key="new validation_rule line 1">
        <GridItem xs={12} sm={12} md={6}>
          <FormControl fullWidth className={classes.formControl} disabled={disabled}>
            <InputLabel id="fieldlabel">Fields</InputLabel>
            <Select
              labelId="fieldlabel"
              id="fieldsselect"
              value={selectedField}
              onChange={onSelectedFieldChange.bind(this)}
            >
              {props.fields.map((field) => (
                <MenuItem key={field.id} value={field.id}>
                  {`${field.form_name} -> ${field.label}`}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <FormControl fullWidth className={classes.formControl} disabled={disabled}>
            <InputLabel id="typeLabel">Condition</InputLabel>
            <Select
              labelId="conditionLabel"
              id="conditionLabelID"
              value={condition}
              onChange={onConditionChange.bind(this)}
            >
              <MenuItem key="==" value="=="> equal to</MenuItem>
              <MenuItem key="!=" value="!="> not equal to</MenuItem>
              <MenuItem key=">" value=">"> greater than </MenuItem>
              <MenuItem key=">=" value=">="> greater than equal </MenuItem>
              <MenuItem key="<" value="<"> less than </MenuItem>
              <MenuItem key="<=" value="<="> less than equal </MenuItem>
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <TextField
            required={false}
            fullWidth
            label="Value"
            value={ruleValue}
            onChange={onRuleValueChange.bind(this)}
            disabled={disabled}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          {disabled ? '' : (
            <IconButton onClick={handleAddClick.bind(this)}>
              <AddIcon />
            </IconButton>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
};
export default withStyles(extendedFormsStyle)(VisibleRuleComp);
