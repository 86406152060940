import React, { Component } from 'react';
import { Link, useParams } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import Button from 'components/CustomButtons/Button.jsx';
import * as formActions from 'actions/admin/FormActions';
import * as itemActions from 'actions/ItemActions';
import * as taskActions from 'actions/admin/TaskActions';
import * as fieldActions from 'actions/admin/FieldActions';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import PostAddRounded from '@mui/icons-material/PostAddRounded';
import * as defaultActions from 'actions/admin/DefaultActions';
import FormFieldComp from './FormField/FormFieldComp';

const FormField = (props) => {
  const { classes } = props;
  const {
    item, field, task, form, defaultStates,
  } = props;
  const {
    getNumFields,
    getFormFields,
    getUserIntgFields,
    getDeptIntgFields,
    getCustIntgFields,
    getItemIntgFields,
    createField,
    updateField,
    deleteField,
    setOptions,
    setOptionsDefaultValue,
    setOptionsIntgFieldID,
    setAggregateRule,
    setValidateRule,
    setRequiredRule,
    setVisibleRule,
    setDatatableRule,
    getAllDepts,
    getAllRoles,
    getAllAdhocComms,
  } = props.fieldActions;
  const { setSelectedItem } = props.itemActions;
  const { resetMessage } = props.defaultActions;
  const { getForms } = props.formActions;
  const { getTasks } = props.taskActions;
  const { id } = useParams();

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/admin/form">
          <Button size="sm" round color="primary">
            {' '}
            <PostAddRounded />
            {' '}
            Form
          </Button>
        </Link>
        <Link to="#">
          <Button size="sm" round color="primary" block>
            {' '}
            {field.form_field_list.title}
          </Button>
        </Link>
      </Breadcrumbs>
      <br />
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}

      <FormFieldComp
        setSelectedItem={setSelectedItem}
        form_id={id}
        resetMessage={resetMessage}
        getNumFields={getNumFields}
        getForms={getForms}
        getTasks={getTasks}
        getAllAdhocComms={getAllAdhocComms}
        setOptions={setOptions}
        setOptionsDefaultValue={setOptionsDefaultValue}
        setOptionsIntgFieldID={setOptionsIntgFieldID}
        setAggregateRule={setAggregateRule}
        setValidateRule={setValidateRule}
        setRequiredRule={setRequiredRule}
        setVisibleRule={setVisibleRule}
        setDatatableRule={setDatatableRule}
        getFormFields={getFormFields}
        getUserIntgFields={getUserIntgFields}
        getDeptIntgFields={getDeptIntgFields}
        getCustIntgFields={getCustIntgFields}
        getItemIntgFields={getItemIntgFields}
        getAllDepts={getAllDepts}
        getAllRoles={getAllRoles}
        field_list={field.field_list === undefined ? [] : field.field_list}
        form_list={form.form_list.list === undefined ? [] : form.form_list.list}
        task_list={task.task_list.list === undefined ? [] : task.task_list.list}
        form_field_list={field.form_field_list === undefined ? [] : field.form_field_list}
        form_fields={field.form_field_list.fields === undefined ? [] : field.form_field_list.fields}
        field_user_intg_list={field.field_user_intg_list === undefined ? [] : field.field_user_intg_list}
        field_dept_intg_list={field.field_dept_intg_list === undefined ? [] : field.field_dept_intg_list}
        field_cust_intg_list={field.field_cust_intg_list === undefined ? [] : field.field_cust_intg_list}
        field_item_intg_list={field.field_item_intg_list === undefined ? [] : field.field_item_intg_list}
        role_list={field.role_list.list === undefined ? [] : field.role_list.list}
        dept_list={field.dept_list.list === undefined ? [] : field.dept_list.list}
        adhoc_comm_list={field.adhoc_comm_list === undefined ? [] : field.adhoc_comm_list}
        createField={createField}
        updateField={updateField}
        deleteField={deleteField}
        refreshField={field.refreshField}
        requesting={field.requesting}
        options={field.options}
        optionsDefaultValue={field.optionsDefaultValue}
        optionsIntgFieldID={field.optionsIntgFieldID}
        field_aggregate_rule={field.field_aggregate_rule}
        field_validate_rule={field.field_validate_rule}
        field_required_rule={field.field_required_rule}
        field_visible_rule={field.field_visible_rule}
        field_datatable_rule={field.field_datatable_rule}
        selected_item={item.selected_item}
        selected_item_id={item.selected_item_id}
        message={field.message}
        error_code={field.error_code}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    item: state.item,
    form: state.adForm,
    field: state.adField,
    task: state.adTask,
    defaultStates: state.adDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    formActions: bindActionCreators(formActions, dispatch),
    itemActions: bindActionCreators(itemActions, dispatch),
    taskActions: bindActionCreators(taskActions, dispatch),
    fieldActions: bindActionCreators(fieldActions, dispatch),
    defaultActions: bindActionCreators(defaultActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormField);
