export const GET_COMMNAME_REQUEST = 'GET_COMMNAME_REQUEST';
export const GET_COMMNAME_SUCCESS = 'GET_COMMNAME_SUCCESS';
export const GET_COMMNAME_FAILURE = 'GET_COMMNAME_FAILURE';
export const POST_COMMNAME_REQUEST = 'POST_COMMNAME_REQUEST';
export const POST_COMMNAME_SUCCESS = 'POST_COMMNAME_SUCCESS';
export const POST_COMMNAME_FAILURE = 'POST_COMMNAME_FAILURE';
export const PATCH_COMMNAME_REQUEST = 'PATCH_COMMNAME_REQUEST';
export const PATCH_COMMNAME_SUCCESS = 'PATCH_COMMNAME_SUCCESS';
export const PATCH_COMMNAME_FAILURE = 'PATCH_COMMNAME_FAILURE';
export const DELETE_COMMNAME_REQUEST = 'DELETE_COMMNAME_REQUEST';
export const DELETE_COMMNAME_SUCCESS = 'DELETE_COMMNAME_SUCCESS';
export const DELETE_COMMNAME_FAILURE = 'DELETE_COMMNAME_FAILURE';
export const GET_COMMNAME_TASK_REQUEST = 'GET_COMMNAME_TASK_REQUEST';
export const GET_COMMNAME_TASK_SUCCESS = 'GET_COMMNAME_TASK_SUCCESS';
export const GET_COMMNAME_TASK_FAILURE = 'GET_COMMNAME_TASK_FAILURE';
export const GET_COMMNAME_PHASE_REQUEST = 'GET_COMMNAME_PHASE_REQUEST';
export const GET_COMMNAME_PHASE_SUCCESS = 'GET_COMMNAME_PHASE_SUCCESS';
export const GET_COMMNAME_PHASE_FAILURE = 'GET_COMMNAME_PHASE_FAILURE';
