import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx';
import moment from 'moment';

import {
  INTEGER_NUMBER,
  DECIMAL_NUMBER,
  STRING,
  DATE_STRING,
  NONE,
} from 'constants/admin/FieldDataTypes';

const ActionStartRuleComp = (props) => {
  const [rules, setRules] = useState([]);
  const [intgType, setIntgType] = useState('user');
  const [condition, setCondition] = useState('==');
  const [disabled, setDisabled] = useState(false);
  const [ruleValue, setRuleValue] = useState('');
  const [selectedIntgField, setSelectedIntgField] = useState('');
  const [selectedDataType, setSelectedDataType] = useState(NONE);
  const [message, setMessage] = useState('');

  useEffect(() => {
    if (props.rule !== '') {
      var rules = JSON.parse(props.rule);
      setRules(rules);
      props.setActionRule(rules);
    }
    setDisabled(props.disabled);
  }, []);

  const handleDeleteClick = (index, e) => {
    const tempRules = rules.slice();
    tempRules.splice(index, 1);
    setRules(tempRules);
    setMessage('');
    props.setActionRule(tempRules);
  };
  function isIntegerNumeric(value) {
    return /^\d+$/.test(value);
  }
  function isDecimalNumeric(value) {
    return /^\d+\.\d+$/.test(value);
  }
  const handleAddClick = (e) => {
    if (selectedIntgField !== '' && condition !== ''
      && ruleValue !== '') {
      if (selectedDataType === INTEGER_NUMBER) {
        if (!isIntegerNumeric(ruleValue)) {
          setMessage('Invalid Rule Value. Must be an Integer number');
          return;
        }
      } else if (selectedDataType === DECIMAL_NUMBER) {
        if (!isDecimalNumeric(ruleValue)) {
          setMessage('Invalid Rule Value. Must be Decimal number');
          return;
        }
      } else if (selectedDataType == DATE_STRING) {
        if (!moment(ruleValue, 'YYYY-MM-DD', true).isValid()) {
          setMessage("Invalid Rule Value. Must be a valid date with the format: 'YYYY-MM-DD'");
          return;
        }
      }

      var rule = {
        type: intgType,
        intg_field_id: selectedIntgField.toString(),
        condition,
        value: ruleValue,
      };
      setRules([...rules, rule]);
      setMessage('');
      setRuleValue('');
      setSelectedIntgField('');
      props.setActionRule([...rules, rule]);
    } else {
      setMessage('Invalid rule');
    }
  };

  const getRuleConditionBasedOnDataType = (value) => {
    var rtnComp = [];
    switch (value) {
      case NONE:
        break;
      case INTEGER_NUMBER:
        rtnComp.push(<MenuItem key="==" value="=="> equal </MenuItem>);
        rtnComp.push(<MenuItem key="!=" value="!="> not equal</MenuItem>);
        rtnComp.push(<MenuItem key=">" value=">"> greater than </MenuItem>);
        rtnComp.push(<MenuItem key="<" value="<"> less than </MenuItem>);
        rtnComp.push(<MenuItem key=">=" value=">="> greater than equal </MenuItem>);
        rtnComp.push(<MenuItem key="<=" value="<="> less than equal </MenuItem>);
        break;
      case DECIMAL_NUMBER:
        rtnComp.push(<MenuItem key="==" value="=="> equal </MenuItem>);
        rtnComp.push(<MenuItem key="!=" value="!="> not equal</MenuItem>);
        rtnComp.push(<MenuItem key=">" value=">"> greater than </MenuItem>);
        rtnComp.push(<MenuItem key="<" value="<"> less than </MenuItem>);
        rtnComp.push(<MenuItem key=">=" value=">="> greater than equal </MenuItem>);
        rtnComp.push(<MenuItem key="<=" value="<="> less than equal </MenuItem>);
        break;
      case STRING:
        rtnComp.push(<MenuItem key="==" value="=="> equal </MenuItem>);
        rtnComp.push(<MenuItem key="!=" value="!="> not equal</MenuItem>);
        break;
      case DATE_STRING:
        rtnComp.push(<MenuItem key="==" value="=="> equal </MenuItem>);
        rtnComp.push(<MenuItem key="!=" value="!="> not equal</MenuItem>);
        rtnComp.push(<MenuItem key=">" value=">"> greater than </MenuItem>);
        rtnComp.push(<MenuItem key="<" value="<"> less than </MenuItem>);
        rtnComp.push(<MenuItem key=">=" value=">="> greater than equal </MenuItem>);
        rtnComp.push(<MenuItem key="<=" value="<="> less than equal </MenuItem>);
        break;
      default:
    }
    return rtnComp;
  };
  const onIntgTypeChange = (e) => {
    setIntgType(e.target.value);
  };
  const onSelectedIntgFieldChange = (e) => {
    setSelectedIntgField(e.target.value);
    if (intgType == 'dept') {
      setSelectedDataType(props.field_dept_intg_list.find((obj) => obj.id === e.target.value).intg_field_data_type);
    } else {
      setSelectedDataType(props.field_user_intg_list.find((obj) => obj.id === e.target.value).intg_field_data_type);
    }
  };
  const onConditionChange = (e) => {
    setCondition(e.target.value);
  };
  const onRuleValueChange = (e) => {
    setRuleValue(e.target.value);
  };
  const { classes } = props;
  const ruleItems = [];
  rules.forEach((rule, index) => {
    ruleItems.push(
      <GridContainer key={index}>
        <GridItem xs={12} sm={12} md={2}>
          <FormControl fullWidth className={classes.formControl} disabled>
            <InputLabel id="typelabel">Type</InputLabel>
            <Select
              labelId="typelabel"
              id="typeselect"
              value={rule.type}
            // onChange={onIntgTypeChange.bind(this)}
            >
              <MenuItem key="dept" value="dept"> Dept </MenuItem>
              <MenuItem key="user" value="user"> User </MenuItem>
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <FormControl fullWidth className={classes.formControl} disabled>
            <InputLabel id="fieldlabel">Intg Field</InputLabel>
            <Select
              labelId="fieldlabel"
              id="fieldsselect"
              value={rule.intg_field_id}
            >
              {rule.type === 'dept'
                ? props.field_dept_intg_list.map((field) => (
                  <MenuItem key={field.id} value={field.id}>
                    {field.field_name}
                  </MenuItem>
                ))
                : props.field_user_intg_list.map((field) => (
                  <MenuItem key={field.id} value={field.id}>
                    {field.field_name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <FormControl fullWidth className={classes.formControl} disabled>
            <InputLabel id="typelabel">Condition</InputLabel>
            <Select
              labelId="typelabel"
              id="typeselect"
              value={rule.condition}
            >
              <MenuItem key="==" value="=="> equal </MenuItem>
              <MenuItem key="!=" value="!="> not equal</MenuItem>
              <MenuItem key=">" value=">"> greater than </MenuItem>
              <MenuItem key="<" value="<"> less than </MenuItem>
              <MenuItem key=">=" value=">="> greater than equal </MenuItem>
              <MenuItem key="<=" value="<="> less than equal </MenuItem>
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <TextField
            required={false}
            fullWidth
            label="Value"
            value={rule.value}
            disabled
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          {disabled ? '' : (
            <IconButton onClick={handleDeleteClick.bind(this, index)}>
              <DeleteIcon />
            </IconButton>
          )}
        </GridItem>
      </GridContainer>,
    );
    ruleItems.push(<br key={`linebreak${index}`} />);
  });

  return (
    <div>
      {ruleItems}
      <br />
      <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>

      <GridContainer key="new rule">
        <GridItem xs={12} sm={12} md={2}>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel id="typelabel">Type</InputLabel>
            <Select
              labelId="typelabel"
              id="typeselect"
              value={intgType}
              onChange={onIntgTypeChange.bind(this)}
            >
              <MenuItem key="dept" value="dept"> Dept </MenuItem>
              <MenuItem key="user" value="user"> User </MenuItem>
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel id="fieldlabel">Intg Field</InputLabel>
            <Select
              labelId="fieldlabel"
              id="fieldsselect"
              value={selectedIntgField}
              onChange={onSelectedIntgFieldChange.bind(this)}
            >
              {intgType === 'dept'
                ? props.field_dept_intg_list.map((field) => (
                  <MenuItem key={field.id} value={field.id}>
                    {field.field_name}
                  </MenuItem>
                ))
                : props.field_user_intg_list.map((field) => (
                  <MenuItem key={field.id} value={field.id}>
                    {field.field_name}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={3}>
          <FormControl fullWidth className={classes.formControl}>
            <InputLabel id="typelabel">Condition</InputLabel>
            <Select
              labelId="typelabel"
              id="typeselect"
              value={condition}
              onChange={onConditionChange.bind(this)}
            >
              {getRuleConditionBasedOnDataType(selectedDataType)}
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <TextField
            required={false}
            fullWidth
            label="Value"
            value={ruleValue}
            onChange={onRuleValueChange.bind(this)}
            disabled={disabled}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          {disabled ? '' : (
            <IconButton onClick={handleAddClick.bind(this)}>
              <AddIcon />
            </IconButton>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
};
export default withStyles(extendedFormsStyle)(ActionStartRuleComp);
