import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter.jsx';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import AccountTree from '@mui/icons-material/AccountTree';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Button from 'components/CustomButtons/Button.jsx';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@mui/material/MenuItem';
import withStyles from "@material-ui/core/styles/withStyles";
import extendedFormsStyle from "assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import LockResetIcon from '@mui/icons-material/LockReset';
import InputAdornment from '@mui/material/InputAdornment';
import Input from '@material-ui/core/Input';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { Link } from 'react-router-dom'

const UserComp = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogButton, setDialogButton] = useState('');
  const [dialogResetOpen, setDialogResetOpen] = useState(false);
  const [dialogResetTitle, setDialogResetTitle] = useState('');
  const [dialogResetButton, setDialogResetButton] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [dialogType, setDialogType] = useState('add');
  const [id, setID] = useState('');
  const [name, setName] = useState('');
  const [departmentID, setDepartmentID] = useState('');
  const [email, setEmail] = useState('');
  const [rank, setRank] = useState('');
  const [status, setStatus] = useState(1);
  const [userType, setUserType] = useState("EMP");
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [downloadFile, setDownloadFile] = useState(false)
  const [pageSize, setPageSize] = useState(100);
  const [pageNum, setPageNum] = useState(1);
  const [orderBy, setOrderBy] = useState('user_name');
  const [orderByDirec, setOrderByDirec] = useState('asc');
  const [searchStr, setSearchStr] = useState('');
  const navigate = useNavigate();


  const { t } = useTranslation();

  const { getUsers, getUsersCSV, getDepartments } = props;
  useEffect(() => {
    getUsers(searchStr, pageSize, pageNum, orderBy, orderByDirec);
    getDepartments();
  }, [getUsers, getUsersCSV, getDepartments])

  const handleEditClick = (e) => {
    setID(e[0]);
    setName(e[1]);
    setDepartmentID(e[2]);
    setEmail(e[3]);
    setRank(e[4]);
    setStatus(e[5]);
    setUserType(e[6]);
    setMessage('');
    setDialogTitle('Edit');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('update');
    setDialogOpen(true);
  };
  const handleDeleteClick = (e) => {
    setID(e[0]);
    setName(e[1]);
    setDepartmentID(e[2]);
    setEmail(e[3]);
    setRank(e[4]);
    setStatus(e[5]);
    setUserType(e[6]);
    setMessage('');
    setDialogTitle('Delete');
    setDialogButton('Confirm Delete');
    setFieldDisabled(true);
    setDialogType('delete');
    setDialogOpen(true);
  };
  const handleResetPasswordClick = (e) => {
    setID(e[0]);
    setName(e[1]);
    setDepartmentID(e[2]);
    setEmail(e[3]);
    setRank(e[4]);
    setStatus(e[5]);
    setUserType(e[6]);
    setMessage('');
    setNewPassword('');
    setDialogResetTitle('Assign New Password');
    setDialogResetButton('Set Password');
    setFieldDisabled(true);
    setDialogResetOpen(true);
  };
  const handleNewClick = (e) => {
    setName("");
    setDepartmentID("");
    setEmail("");
    setRank("");
    setStatus(1);
    setUserType("EMP");
    setMessage('');
    setDialogTitle('Add New');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('add');
    setDialogOpen(true);
  };
  useEffect(() => {
    setMessage(props.message)
    if (props.user_list_csv.length > 0 && (!props.requesting) && downloadFile) {
      setDownloadFile(false)
      let filename = "UserData.csv";
      let contentType = "application/csv;charset=utf-8;";
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        var blob = new Blob([decodeURIComponent(props.user_list_csv)], { type: contentType });
        navigator.msSaveOrOpenBlob(blob, filename);
      } else {
        var a = document.createElement('a');
        a.download = filename;
        a.href = 'data:' + contentType + ',' + encodeURIComponent(props.user_list_csv);
        a.target = '_blank';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    }
    if (props.refreshUser) {
      props.getUsers(searchStr, pageSize, pageNum, orderBy, orderByDirec);
      setDialogOpen(false);
      setDialogResetOpen(false);
    }
    var rowItems = [];
    for (let i = 0; i < props.user_list.length; i++) {
      if (props.user_list[i].name !== "") {
        var colItems = [];
        colItems.push(i + 1);
        colItems.push(props.user_list[i].id);
        colItems.push(props.user_list[i].name);
        colItems.push(props.user_list[i].dept_name);
        colItems.push(props.user_list[i].email);
        colItems.push(props.user_list[i].rank);
        colItems.push(props.user_list[i].status ? "Active" : "Inactive");
        colItems.push(
          <div>
            <IconButton
              onClick={handleEditClick.bind(this,
                [props.user_list[i].id,
                props.user_list[i].name,
                props.user_list[i].department_id,
                props.user_list[i].email,
                props.user_list[i].rank,
                props.user_list[i].status,
                props.user_list[i].user_type,
                ])}
              title="Edit row"
            >
              {' '}
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={handleDeleteClick.bind(this,
                [props.user_list[i].id,
                props.user_list[i].name,
                props.user_list[i].department_id,
                props.user_list[i].email,
                props.user_list[i].rank,
                props.user_list[i].status,
                props.user_list[i].user_type,
                ])}
              title="Delete row"
            >
              {' '}
              <DeleteIcon />
            </IconButton>
            <IconButton
              onClick={handleResetPasswordClick.bind(this,
                [props.user_list[i].id,
                props.user_list[i].name,
                props.user_list[i].department_id,
                props.user_list[i].email,
                props.user_list[i].rank,
                props.user_list[i].status,
                props.user_list[i].user_type,
                ])}
              title="Reset Password"
            >
              {' '}
              <LockResetIcon />
            </IconButton>
            <IconButton title="Integration Fields" onClick={() => navigate(`/networkadmin/user-intg/${props.user_list[i].id}`)}><AccountTree /></IconButton>
          </div>,
        );
        rowItems.push(colItems);
      }
    }
    setData(rowItems)
  }, [props])

  const { classes } = props;
  const columns = [
    {
      name: "Serial",
      label: "Serial",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "ID",
      label: "ID",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "Name",
      label: "Name",
      options: {
        filter: true,
        sort: true,
      }
    },
    {
      name: "Department",
      label: "Department",
      options: {
        filter: true,
        sort: true,
        display: true,
      }
    },
    {
      name: "Email",
      label: "Email",
      options: {
        filter: true,
        sort: true,
        display: true,
      }
    },
    {
      name: "Rank",
      label: "Rank",
      options: {
        filter: true,
        sort: true,
        display: true,
      }
    },
    {
      name: "Status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        display: true,
      }
    },
    {
      name: "",
      label: "",
      options: {
        filter: false,
        sort: false,
        display: true,
      }
    },
  ];
  const options = {
    selectableRowsHideCheckboxes: true,
    filterType: 'dropdown',
    responsive: 'standard',
    selectableRows: 'none',
    selectableRowsOnClick: true,
    selectToolbarPlacement: 'none',
    pagination: true,
    print: true,
    filter: false,
    download: false,
    search: true,
    sortFilterList: false,
    viewColumns: true,
    rowsSelected: [],
    serverSide: true,
    count: props.user_count,
    rowsPerPage: pageSize,
    page: pageNum - 1,
    rowsPerPageOptions: [10, 20, 100],

    onTableChange: (action, tableState) => {
      //console.log(action, tableState);
      switch (action) {
        case 'changeRowsPerPage':
          setPageSize(tableState.rowsPerPage)
          setPageNum(1)
          props.getUsers(searchStr, tableState.rowsPerPage, 1, orderBy, orderByDirec)
          break;
        case 'changePage':
          setPageNum(tableState.page + 1)
          props.getUsers(searchStr, pageSize, tableState.page + 1, orderBy, orderByDirec)
          break;
        case 'sort':
          console.log(action, tableState);
          console.log(tableState);
          var sortString = 'user_name'
          setOrderByDirec(tableState.sortOrder.direction)
          if (tableState.sortOrder.name === 'Name') {
            setOrderBy('user_name')
            sortString = 'user_name'
          } else if (tableState.sortOrder.name === 'Department') {
            setOrderBy('department_name')
            sortString = 'department_name'
          }
          props.getUsers(searchStr, pageSize, pageNum, sortString, tableState.sortOrder.direction)
          break;
        case 'search':
          setSearchStr(tableState.searchText)
          setPageNum(1)
          props.getUsers(tableState.searchText, pageSize, pageNum, orderBy, orderByDirec)
          break;
      }
    },
    textLabels: {
      body: {
        noMatch: t('mui_table_no_match'),
        toolTip: t('mui_table_tool_tip'),
        columnHeaderTooltip: column => t('mui_table_header_tool_tip') + " " + column.label
      },
      pagination: {
        next: t('mui_table_next'),
        previous: t('mui_table_previous'),
        rowsPerPage: t('mui_table_rows_per_page'),
        displayRows: t('mui_table_display_rows'),
      },
      toolbar: {
        search: t('mui_table_search'),
        downloadCsv: t('mui_table_download_csv'),
        print: t('mui_table_print'),
        viewColumns: t('mui_table_view_columns'),
        filterTable: t('mui_table_filter_table'),
      },
      filter: {
        all: t('mui_table_filter_all'),
        title: t('mui_table_filter_title'),
        reset: t('mui_table_filter_reset'),
      },
      viewColumns: {
        title: t('mui_table_column_title'),
        titleAria: t('mui_table_column_title_aria'),
      },
      selectedRows: {
        text: t('mui_table_selected_text'),
        delete: t('mui_table_selected_delete'),
        deleteAria: t('mui_table_selected_delete_aria'),
      },
    },
  };
  const handleClickShowPassword = () => {
    setShowPassword(showPassword ? false : true);
    //setShowPassword(true);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const onNameChange = (e) => {
    setName(e.target.value);
  };
  const onNewPasswordChange = (e) => {
    setNewPassword(e.target.value);
  };
  const onDepartmentIDChange = (e) => {
    setDepartmentID(e.target.value);
  };
  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };
  const onRankChange = (e) => {
    setRank(e.target.value);
  }
  const onStatusChange = (e) => {
    setStatus(e.target.checked);
  }
  const onUserTypeChange = (e) => {
    setUserType(e.target.value);
  }
  const handleDialogClose = (e) => {
    setDialogOpen(false);
    setMessage('');
  };
  const handleDialogResetClose = (e) => {
    setDialogResetOpen(false);
    setMessage('');
  };
  const handleDialogResetConfirm = (e) => {
    props.resetPassword({
      user_id: id.toString(),
      password: newPassword,
    });
  };
  const handleDialogConfirm = (e) => {
    if (dialogType === 'add') {
      props.createUser({
        name: name,
        department_id: departmentID.toString(),
        email: email,
        rank: rank,
        user_type: userType,
        status: status ? '1' : '0',
      });
    } else if (dialogType === 'update') {
      props.updateUser(id, {
        name: name,
        department_id: departmentID.toString(),
        email: email,
        rank: rank,
        user_type: userType,
        status: status ? '1' : '0',
      });
    } else if (dialogType === 'delete') {
      props.deleteUser(id);
    }
  };

  const handleDownloadCSV = (e) => {
    if (!props.requesting) {
      getUsersCSV();
      setDownloadFile(true)
    }
  }
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Users</b>
          <p className={classes.cardCategoryWhite}>
          </p>
        </CardHeader>
        <CardBody>
          <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
          <MUIDataTable
            title={<div><Button onClick={handleNewClick.bind(this)} color="primary" title="Create a new User" round> New User </Button>
              <Button
                onClick={handleDownloadCSV.bind(this)}
                color="primary"
                title="Download CSV"
                round
                disabled={props.requesting}>
                {props.requesting
                  ? <CircularProgress size={20} color="inherit" /> : <CloudDownloadIcon />}
                Download CSV
              </Button></div>}
            data={data}
            columns={columns}
            options={options}
          />
          <Dialog fullWidth open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              <Card>
                <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                  <b>{dialogTitle}</b>
                </CardHeader>
                <CardBody>
                  <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        fullWidth
                        label="Name"
                        value={name}
                        onChange={onNameChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled}>
                        <InputLabel id="departmentLabel">Department</InputLabel>
                        <Select
                          labelid="departmentLabel"
                          id="departmtneSelect"
                          value={departmentID}
                          onChange={onDepartmentIDChange.bind(this)}
                        >
                          {props.department_list.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {props.department_list.find((p) => p.id === item.id).name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        required={false}
                        fullWidth
                        label="Email"
                        value={email}
                        onChange={onEmailChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        fullWidth
                        label="Rank"
                        value={rank}
                        onChange={onRankChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled}>
                        <InputLabel id="userTypeLabel">User Type</InputLabel>
                        <Select
                          labelid="userTypeLabel"
                          id="userTypeSelect"
                          value={userType}
                          onChange={onUserTypeChange.bind(this)}
                        >
                          <MenuItem key={"EMP"} value={"EMP"}>Employee  </MenuItem>
                          <MenuItem key={"SSO"} value={"SSO"}>SSO  </MenuItem>
                          <MenuItem key={"EXT"} value={"EXT"}>External  </MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6} >
                      <InputLabel id="statusLabel" shrink>Status</InputLabel>
                      <Grid item component="label" container alignItems="center" spacing={1}>
                        <Grid item>Inactive</Grid>
                        <Grid item>
                          <Switch
                            labelid="statusLabel"
                            checked={status}
                            onChange={onStatusChange.bind(this)}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled={fieldDisabled}
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                            }}
                          />
                        </Grid>
                        <Grid item>Active</Grid>
                      </Grid>
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter style={{ textAlign: 'left' }} />
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose.bind(this)} color="primary" round>
                Cancel
              </Button>
              <Button onClick={handleDialogConfirm.bind(this)} color="primary" round>
                {dialogButton}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog fullWidth open={dialogResetOpen} onClose={handleDialogResetClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              <Card>
                <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                  <b>{dialogResetTitle}</b>
                </CardHeader>
                <CardBody>
                  <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        fullWidth
                        label="Name"
                        value={name}
                        disabled={true}
                      />
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled}>
                        <InputLabel id="departmentLabel">Department</InputLabel>
                        <Select
                          labelid="departmentLabel"
                          id="departmtneSelect"
                          value={departmentID}
                        >
                          {props.department_list.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {props.department_list.find((p) => p.id === item.id).name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        required={false}
                        fullWidth
                        label="Email"
                        value={email}
                        disabled={true}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl fullWidth className={classes.formControl} >
                        <InputLabel htmlFor="wf-adornment-password">New Password</InputLabel>
                        <Input
                          id="wf-adornment-password"
                          type={showPassword ? 'text' : 'password'}
                          value={newPassword}
                          onChange={onNewPasswordChange.bind(this)}
                          endAdornment={
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                                edge="end"
                              >
                                {showPassword ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          }
                          labelWidth={70}
                        />
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <br />
                </CardBody>
                <CardFooter style={{ textAlign: 'left' }} />
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogResetClose.bind(this)} color="primary" round>
                Cancel
              </Button>
              <Button onClick={handleDialogResetConfirm.bind(this)} color="primary" round>
                {dialogResetButton}
              </Button>
            </DialogActions>
          </Dialog>
        </CardBody>
      </Card>
    </div >
  );
}
export default withStyles(extendedFormsStyle)(UserComp)
