import API from 'Api/api.js';
import { commonBackendCall, getAdminConfig } from 'Api/common.js';
import {
  GET_ALLOW_APP_CREATE_REQUEST,
  GET_ALLOW_APP_CREATE_SUCCESS,
  GET_ALLOW_APP_CREATE_FAILURE,
  POST_ALLOW_APP_CREATE_REQUEST,
  POST_ALLOW_APP_CREATE_SUCCESS,
  POST_ALLOW_APP_CREATE_FAILURE,
  DELETE_ALLOW_APP_CREATE_REQUEST,
  DELETE_ALLOW_APP_CREATE_SUCCESS,
  DELETE_ALLOW_APP_CREATE_FAILURE,
  GET_ROLES_EXCLUDE_REQUEST,
  GET_ROLES_EXCLUDE_SUCCESS,
  GET_ROLES_EXCLUDE_FAILURE,
} from 'constants/admin/AllowAppCreate';

export function getAllowAppCreateRole() {
  return commonBackendCall(
    GET_ALLOW_APP_CREATE_REQUEST,
    GET_ALLOW_APP_CREATE_SUCCESS,
    GET_ALLOW_APP_CREATE_FAILURE,
    API.get('admin/app-create-allow', getAdminConfig()),
  );
}

export function createAllowAppCreateRole(obj) {
  return commonBackendCall(
    POST_ALLOW_APP_CREATE_REQUEST,
    POST_ALLOW_APP_CREATE_SUCCESS,
    POST_ALLOW_APP_CREATE_FAILURE,
    API.post('admin/app-create-allow', obj, getAdminConfig()),
  );
}

export function deleteAllowAppCreateRole(appCreateAllowID) {
  return commonBackendCall(
    DELETE_ALLOW_APP_CREATE_REQUEST,
    DELETE_ALLOW_APP_CREATE_SUCCESS,
    DELETE_ALLOW_APP_CREATE_FAILURE,
    API.delete(`admin/app-create-allow/${appCreateAllowID}`, getAdminConfig()),
  );
}

export function getRolesExcludeAdhocAndGeneric() {
  return commonBackendCall(
    GET_ROLES_EXCLUDE_REQUEST,
    GET_ROLES_EXCLUDE_SUCCESS,
    GET_ROLES_EXCLUDE_FAILURE,
    API.get('admin/roles-exclude', getAdminConfig()),
  );
}
