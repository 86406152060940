import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import MessageTemplateComp from './MessageTemplate/MessageTemplateComp';
import * as messageTemplateActions from 'actions/networkadmin/MessageTemplateActions';

const MessageTemplate = (props) => {
  const { classes } = props;
  const { messageTemplate, defaultStates } = props;
  const {
    getMessageTemplateTasks,
    getMessageTemplates,
    createMessageTemplate,
    updateMessageTemplate,
    deleteMessageTemplate,
  } = props.messageTemplateActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <MessageTemplateComp
        getMessageTemplates={getMessageTemplates}
        getMessageTemplateTasks={getMessageTemplateTasks}
        message_template_list={messageTemplate.message_template_list === undefined ? [] : messageTemplate.message_template_list}
        createMessageTemplate={createMessageTemplate}
        updateMessageTemplate={updateMessageTemplate}
        deleteMessageTemplate={deleteMessageTemplate}
        refreshMessageTemplate={messageTemplate.refreshMessageTemplate}
        requesting={messageTemplate.requesting}
        message={messageTemplate.message}
        error_core={messageTemplate.error_core}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    messageTemplate: state.naMessageTemplate,
    defaultStates: state.naDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    messageTemplateActions: bindActionCreators(messageTemplateActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(MessageTemplate);
