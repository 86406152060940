import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import ErrorEmailsComp from './ErrorEmails/ErrorEmailsComp';
import * as errorEmailsActions from 'actions/networkadmin/DashboardActions';

const ErrorEmails = (props) => {
  const { classes } = props;
  const { dashboard, defaultStates } = props;
  const {
    getUnsentErrorEmailList,
    resendEmail,
  } = props.errorEmailsActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <ErrorEmailsComp
        getUnsentErrorEmailList={getUnsentErrorEmailList}
        resendEmail={resendEmail}
        unsent_error_email_list={dashboard.unsent_error_email_list === undefined ? [] : dashboard.unsent_error_email_list}
        refreshUnsentErrorEmail={dashboard.refreshUnsentErrorEmail}
        requesting={dashboard.requesting}
        message={dashboard.message}
        error_code={dashboard.error_code}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    dashboard: state.naDashboard,
    defaultStates: state.naDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    errorEmailsActions: bindActionCreators(errorEmailsActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ErrorEmails);
