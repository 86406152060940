/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import AccountTree from '@mui/icons-material/AccountTree';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx';

const DepartmentComp = (props) => {
  const [message, setMessage] = useState('');
  const [data, setData] = useState([]);
  const navigate = useNavigate();

  const { getDepartments } = props;
  useEffect(() => {
    getDepartments();
  }, [getDepartments]);

  useEffect(() => {
    setMessage(props.message);

    var rowItems = [];
    for (let i = 0; i < props.department_list.length; i++) {
      if (props.department_list[i].name !== '') {
        var colItems = [];
        var link = `/admin/dept-intg/${props.department_list[i].id}`;
        colItems.push(i + 1);
        colItems.push(props.department_list[i].id);
        colItems.push(props.department_list[i].name);
        colItems.push(props.department_list[i].alias);
        colItems.push(props.department_list[i].short_name);
        colItems.push(props.department_list[i].user_name);
        colItems.push(props.department_list[i].user_email);
        var parentDeptObj = props.department_list.find((o) => o.id === props.department_list[i].parent_dept_id);
        parentDeptObj === undefined ? colItems.push('') : colItems.push(parentDeptObj.name);
        colItems.push(props.department_list[i].status ? 'Active' : 'Inactive');
        colItems.push(
          <div>
            <IconButton title="Integration Fields" onClick={() => navigate(`/admin/dept-intg/${props.department_list[i].id}`)}><AccountTree /></IconButton>
          </div>,
        );
        rowItems.push(colItems);
      }
    }
    setData(rowItems);
  }, [props]);

  const { classes } = props;
  // const columns = [
  //   "ID",
  //   "Name",
  //   "Short Name",
  //   "Department Head",
  //   "",
  // ];
  const columns = [
    {
      name: 'Serial',
      label: 'Serial',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'ID',
      label: 'ID',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Name',
      label: 'Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Alias',
      label: 'Alias',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Short Name',
      label: 'Short Name',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Department Head',
      label: 'Department Head',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: "Department Head Email",
      label: "Department Head Email",
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'Parent Dept',
      label: 'Parent Dept',
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'Status',
      label: 'Status',
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },

    {
      name: '',
      label: '',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
  ];

  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    filter: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    selectToolbarPlacement: 'none',
  };
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Department</b>
          <p className={classes.cardCategoryWhite} />
        </CardHeader>
        <CardBody>
          <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
          <MUIDataTable
            data={data}
            columns={columns}
            options={options}
          />
        </CardBody>
      </Card>
    </div>
  );
};

export default withStyles(extendedFormsStyle)(DepartmentComp);
