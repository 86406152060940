/* eslint-disable react/jsx-closing-tag-location */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import withStyles from '@material-ui/core/styles/withStyles';
import MUIDataTable from 'mui-datatables';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.jsx';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import extendedFormsStyle from 'assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx';
import Message from '@mui/icons-material/Message';
import Typography from '@mui/material/Typography';
import {
  INTEGER_NUMBER,
  DECIMAL_NUMBER,
  STRING,
  DATE_STRING,
  NONE,
} from 'constants/admin/FieldDataTypes';
import ActionRuleComp from './ActionRuleComp';
import ActionStartRuleComp from './ActionStartRuleComp';
import ActionRuleJSONEditComp from './ActionRuleJSONEditComp';

const ActionComp = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogButton, setDialogButton] = useState('');
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [dialogType, setDialogType] = useState('add');
  const [data, setData] = useState([]);
  const [message, setMessage] = useState('');
  const [id, setID] = useState('');
  const [title, setTitle] = useState('');
  const [alias, setAlias] = useState('');
  const [taskID, setTaskID] = useState('');
  const [nextPhaseID, setNextPhaseID] = useState('');
  const [nextTaskID, setNextTaskID] = useState('');
  const [attActionID, setAttActionID] = useState('');
  const [type, setType] = useState('');
  const [rule, setRule] = useState('');
  const [ruleCustom, setRuleCustom] = useState('');
  const [requireCompleteTask, setRequireCompleteTask] = useState(false);
  const [isTypeShare, setIsTypeShare] = useState(false);
  const [isTypeUnshare, setIsTypeUnshare] = useState(false);
  const [status, setStatus] = useState(true);
  const [isTrans, setIsTrans] = useState(false);
  const [transType, setTransType] = useState("ITEM_TRANS_INCREASE");
  const [filteredAttachedAction, setFilteredAttachedAction] = useState([]);
  const navigate = useNavigate();



  const {
    getTasks, getPhases, getActions, getActionTypes,
    getShareActions, getDeptIntgFields, getUserIntgFields, getNumFields,
  } = props;

  useEffect(() => {
    getTasks();
    getPhases();
    getActions();
    getActionTypes();
    getShareActions();
    getDeptIntgFields();
    getUserIntgFields();
    getNumFields();
  }, [getTasks, getPhases, getActions, getActionTypes,
    getShareActions, getDeptIntgFields, getUserIntgFields, getNumFields]);

  const handleEditClick = (e) => {
    var filteredAttachedActionTemp = [];
    var lclIsTypeShare = false;
    var lclIsTypeUnShare = false;
    if (e[5] === 'Unshare') {
      lclIsTypeUnShare = true;
    }
    if (e[5] === 'Share' || e[5] === 'Unshare') {
      lclIsTypeShare = true;
    }
    if (e[5] === 'Unshare') {
      filteredAttachedActionTemp = props.share_action_list.filter((obj) => obj.type !== 'Unshare');
    }
    if (e[5] === 'Share') {
      filteredAttachedActionTemp = props.share_action_list.filter((obj) => obj.type !== 'Share');
    }
    filteredAttachedActionTemp = filteredAttachedActionTemp.filter((obj) => obj.id !== e[0]);

    setMessage('');
    setID(e[0]);
    setTaskID(e[1]);
    setNextPhaseID(e[2]);
    setNextTaskID(e[3]);
    setAttActionID(e[4]);
    setType(e[5]);
    setTitle(e[6]);
    setRequireCompleteTask(e[7]);
    setStatus(e[8]);
    setRule(e[9]);
    setAlias(e[10]);
    setRuleCustom(e[11]);
    setIsTrans(e[12]);
    setTransType(e[13] === "" ? "ITEM_TRANS_INCREASE" : e[13]);
    setDialogTitle('Edit');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('update');
    setDialogOpen(true);
    setIsTypeShare(lclIsTypeShare);
    setIsTypeUnshare(lclIsTypeUnShare);
    setFilteredAttachedAction(filteredAttachedActionTemp);
    props.resetMessage('');
  };
  const handleDeleteClick = (e) => {
    var filteredAttachedActionTemp = [];
    var lclIsTypeShare = false;
    var lclIsTypeUnShare = false;
    if (e[5] === 'Unshare') {
      lclIsTypeUnShare = true;
    }
    if (e[5] === 'Share' || e[5] === 'Unshare') {
      lclIsTypeShare = true;
    }
    if (e[5] === 'Unshare') {
      filteredAttachedActionTemp = props.share_action_list.filter((obj) => obj.type !== 'Unshare');
    }
    if (e[5] === 'Share') {
      filteredAttachedActionTemp = props.share_action_list.filter((obj) => obj.type !== 'Share');
    }
    filteredAttachedActionTemp = filteredAttachedActionTemp.filter((obj) => obj.id !== e[0]);

    setMessage('');
    setID(e[0]);
    setTaskID(e[1]);
    setNextPhaseID(e[2]);
    setNextTaskID(e[3]);
    setAttActionID(e[4]);
    setType(e[5]);
    setTitle(e[6]);
    setRequireCompleteTask(e[7]);
    setStatus(e[8]);
    setRule(e[9]);
    setAlias(e[10]);
    setRuleCustom(e[11]);
    setIsTrans(e[12]);
    setTransType(e[13] === "" ? "ITEM_TRANS_INCREASE" : e[13]);
    setDialogTitle('Delete');
    setDialogButton('Confirm Delete');
    setFieldDisabled(true);
    setDialogType('delete');
    setDialogOpen(true);
    setIsTypeShare(lclIsTypeShare);
    setIsTypeUnshare(lclIsTypeUnShare);
    setFilteredAttachedAction(filteredAttachedActionTemp);
    props.resetMessage('');
  };
  const handleNewClick = (e) => {
    setID('');
    setMessage('');
    setTaskID('');
    setNextPhaseID('');
    setNextTaskID('');
    setAttActionID('');
    setType('');
    setTitle('');
    setRule('');
    setAlias('');
    setRuleCustom('');
    setIsTrans(false);
    setTransType("ITEM_TRANS_INCREASE");
    setRequireCompleteTask(false);
    setIsTypeShare(false);
    setIsTypeUnshare(false);
    setStatus(true);
    setDialogTitle('Add New');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('add');
    setDialogOpen(true);
    props.setActionRule([]);
    props.resetMessage('');
  };

  useEffect(() => {
    setMessage(props.message);
    if (props.refreshAction) {
      getShareActions();
      getActions();
      setDialogOpen(false);
    }
    var rowItems = [];
    if (props.actionType_list.length > 0 && props.task_list.length > 0 && props.action_list.length > 0) {
      for (let i = 0; i < props.action_list.length; i++) {
        if (props.action_list[i].title !== '') {
          var colItems = [];
          //var link = `/admin/action-messages/${props.action_list[i].id}`;
          colItems.push(i + 1);
          colItems.push(<div style={{ display: 'flex', flexDirection: 'row' }}>
            <div>{props.action_list[i].title}</div>
            <div>
              <IconButton size={'small'}
                onClick={handleEditClick.bind(this,
                  [props.action_list[i].id,
                  props.action_list[i].task_id,
                  props.action_list[i].next_phase_id,
                  props.action_list[i].next_task_id,
                  props.action_list[i].att_action_id,
                  props.action_list[i].type,
                  props.action_list[i].title,
                  props.action_list[i].require_complete_task,
                  props.action_list[i].status,
                  props.action_list[i].rule,
                  props.action_list[i].alias,
                  props.action_list[i].rule_custom,
                  props.action_list[i].is_trans,
                  props.action_list[i].trans_type,
                  ])}
                title={`Edit Row ID:${props.action_list[i].id}`}
              >
                {' '}
                <EditIcon />
              </IconButton>
            </div>
          </div>);
          colItems.push(props.action_list[i].alias);
          var taskObj = props.task_list.find((o) => o.id === props.action_list[i].task_id);
          colItems.push(taskObj === undefined ? '' : taskObj.alias === '' ? taskObj.title : `${taskObj.title} (${taskObj.alias})`);
          colItems.push(props.action_list[i].type);
          var nextPhaseObj = props.phase_list.find((o) => o.id === props.action_list[i].next_phase_id);
          // eslint-disable-next-line no-unused-expressions
          nextPhaseObj === undefined ? colItems.push('') : colItems.push(nextPhaseObj.title);
          var nextTaskObj = props.task_list.find((o) => o.id === props.action_list[i].next_task_id);
          colItems.push(nextTaskObj === undefined ? '' : nextTaskObj.alias === '' ? nextTaskObj.title : `${nextTaskObj.title} (${nextTaskObj.alias})`);
          colItems.push(props.action_list[i].require_complete_task ? 'Yes' : 'No');
          colItems.push(props.action_list[i].rule);

          /*
          if (props.action_list[i].auto_generate) {
            colItems.push(<div>
              <IconButton title="Action Messages"><Link to={link}><Message /></Link></IconButton>
            </div>);
          } else {
          */
          colItems.push(<div>
            {props.action_list[i].status ? <IconButton title="Action Messages" onClick={() => navigate(`/admin/action-messages/${props.action_list[i].id}`)} ><Typography variant="body2">{props.action_list[i].action_msg_count}</Typography><Message /></IconButton> : ''}
            <IconButton
              onClick={handleEditClick.bind(this,
                [props.action_list[i].id,
                props.action_list[i].task_id,
                props.action_list[i].next_phase_id,
                props.action_list[i].next_task_id,
                props.action_list[i].att_action_id,
                props.action_list[i].type,
                props.action_list[i].title,
                props.action_list[i].require_complete_task,
                props.action_list[i].status,
                props.action_list[i].rule,
                props.action_list[i].alias,
                props.action_list[i].rule_custom,
                props.action_list[i].is_trans,
                props.action_list[i].trans_type,
                ])}
              title={`Edit Row ID:${props.action_list[i].id}`}
            >
              {' '}
              <EditIcon />
            </IconButton>
            {props.action_list[i].type !== 'Unshare'
              ? (
                <IconButton
                  onClick={handleDeleteClick.bind(this,
                    [props.action_list[i].id,
                    props.action_list[i].task_id,
                    props.action_list[i].next_phase_id,
                    props.action_list[i].next_task_id,
                    props.action_list[i].att_action_id,
                    props.action_list[i].type,
                    props.action_list[i].title,
                    props.action_list[i].require_complete_task,
                    props.action_list[i].status,
                    props.action_list[i].rule,
                    props.action_list[i].alias,
                    props.action_list[i].rule_custom,
                    props.action_list[i].is_trans,
                    props.action_list[i].trans_type,
                    ])}
                  title="Delete row"
                >
                  {' '}
                  <DeleteIcon />
                </IconButton>
              ) : ''}
          </div>);
          colItems.push(props.action_list[i].id);
          colItems.push(props.action_list[i].next_phase_id);
          colItems.push(props.action_list[i].next_task_id);
          rowItems.push(colItems);
        }
      }
    }
    setData(rowItems);
    setFilteredAttachedAction(props.share_action_list);
  }, [props]);

  const handleDialogClose = (e) => {
    setDialogOpen(false);
    setMessage('');
  };
  const handleDialogConfirm = (e) => {
    if (dialogType === 'add') {
      props.createAction({
        title: title.toString(),
        task_id: taskID.toString(),
        next_phase_id: nextPhaseID.toString(),
        next_task_id: nextTaskID.toString(),
        att_action_id: attActionID.toString(),
        type,
        require_complete_task: requireCompleteTask ? '1' : '0',
        status: status ? '1' : '0',
        rule: JSON.stringify(props.action_rules),
        alias: alias,
        rule_custom: ruleCustom,
        is_trans: isTrans ? '1' : '0',
        trans_type: transType,
      });
    } else if (dialogType === 'update') {
      props.updateAction(id, {
        title,
        task_id: taskID.toString(),
        next_phase_id: nextPhaseID.toString(),
        next_task_id: nextTaskID.toString(),
        att_action_id: attActionID.toString(),
        type,
        require_complete_task: requireCompleteTask ? '1' : '0',
        status: status ? '1' : '0',
        rule: JSON.stringify(props.action_rules),
        alias: alias,
        rule_custom: ruleCustom,
        is_trans: isTrans ? '1' : '0',
        trans_type: transType,
      });
    } else if (dialogType === 'delete') {
      props.deleteAction(id);
    }
  };

  const onTaskIDChange = (e) => {
    setTaskID(e.target.value);
  };

  const onNextPhaseIDChange = (e) => {
    setNextTaskID(0);
    setNextPhaseID(e.target.value);
  };
  const onNextTaskIDChange = (e) => {
    setNextTaskID(e.target.value);
    setNextPhaseID(0);
  };

  const onAttActionIDChange = (e) => {
    setAttActionID(e.target.value);
  };
  const onTypeChange = (e) => {
    var filteredAttachedActionTemp = [];
    var lclIsTypeShare = false;
    var lclIsTypeUnShare = false;
    if (e[5] === 'Unshare') {
      lclIsTypeUnShare = true;
    }
    if (e.target.value === 'Share' || e.target.value === 'Unshare') {
      lclIsTypeShare = true;
    }
    if (e.target.value === 'Unshare') {
      filteredAttachedActionTemp = props.share_action_list.filter((obj) => obj.type !== 'Unshare');
    }
    if (e.target.value === 'Share') {
      filteredAttachedActionTemp = props.share_action_list.filter((obj) => obj.type !== 'Share');
    }

    setType(e.target.value);
    setIsTypeShare(lclIsTypeShare);
    setIsTypeUnshare(lclIsTypeUnShare);
    setFilteredAttachedAction(filteredAttachedActionTemp);
  };
  const onTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const onAliasChange = (e) => {
    setAlias(e.target.value);
  };
  const onRuleChange = (e) => {
    setRule(e.target.value);
  };
  const onRuleCustomChange = (e) => {
    setRuleCustom(e.target.value);
  };
  const onTransTypeChange = (e) => {
    setTransType(e.target.value);
  };
  const onIsTransChange = (e) => {
    setIsTrans(e.target.checked);
  };
  const onRequireCompleteTaskChange = (e) => {
    setRequireCompleteTask(e.target.checked);
  };

  const onStatusChange = (e) => {
    setStatus(e.target.checked);
  };

  const { classes } = props;
  /* const columns = [
    "Serial",
    "Title",
    "Task",
    "Type",
    "Next Phase",
    "Next Task",
    ""
  ]; */
  const columns = [
    {
      name: 'serial',
      label: 'Serial',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'title',
      label: 'Title',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'alias',
      label: 'Alias',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'task',
      label: 'Task',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'type',
      label: 'Type',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'next phase',
      label: 'Next Phase',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'next Task',
      label: 'Next Task',
      options: {
        filter: true,
        sort: true,
      },
    },
    {
      name: 'Require Complete Task',
      label: 'Require Complete Task',
      options: {
        filter: true,
        sort: true,
        display: true,
      },
    },
    {
      name: 'Rule',
      label: 'Rule',
      options: {
        filter: true,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>
            {value}
            <br />
            <br />
            <ActionRuleJSONEditComp
              updateAction={props.updateAction}
              rule_label={"Action Rule"}
              rule_value={value}
              action_id={tableMeta.rowData[10]}
              phase_id={tableMeta.rowData[11].toString()}
              next_task_id={tableMeta.rowData[12].toString()}
            />
          </div>
        ),
      },
    },
    {
      name: '',
      label: '',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
    {
      name: 'id',
      label: 'id',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'phase_id',
      label: 'phase_id',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'next_task_id',
      label: 'next_task_id',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
  ];
  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    filter: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    selectToolbarPlacement: 'none',
  };
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Actions</b>
          <p className={classes.cardCategoryWhite}>
            Here is the list of the actions that allow applications to traverse from one task to another.
          </p>
        </CardHeader>
        <CardBody>
          <MUIDataTable
            title={(
              <Button
                onClick={handleNewClick.bind(this)}
                color="primary"
                title="Create a new Action"
                disabled={parseInt(localStorage.getItem('workflow_state'), 10) > 0}
                round
              >
                New Action
              </Button>
            )}
            data={data}
            columns={columns}
            options={options}
          />
          <Dialog fullWidth maxWidth="lg" open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              <Card>
                <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                  <b>{dialogTitle}</b>
                </CardHeader>
                <CardBody>
                  <div style={{ color: 'red', textAlign: 'center' }}>
                    {' '}
                    {message}
                    {' '}
                  </div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        required
                        fullWidth
                        label="Title"
                        value={title}
                        onChange={onTitleChange.bind(this)}
                        disabled={fieldDisabled || parseInt(localStorage.getItem('action_id'), 10) === id}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        fullWidth
                        label="Alias"
                        value={alias}
                        onChange={onAliasChange.bind(this)}
                        disabled={fieldDisabled || parseInt(localStorage.getItem('action_id'), 10) === id}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled || parseInt(localStorage.getItem('workflow_state'), 10) > 0}>
                        <InputLabel id="taskIDLabel">Task</InputLabel>
                        <Select
                          labelid="taskIDLabel"
                          id="taskIDSelect"
                          value={taskID}
                          onChange={onTaskIDChange.bind(this)}
                        >
                          <MenuItem key="0" value="0">None</MenuItem>
                          {type === 'RestoreArchive' ?
                            props.task_list.map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {props.task_list.find((p) => p.id === item.id).alias === '' ? props.task_list.find((p) => p.id === item.id).title : `${props.task_list.find((p) => p.id === item.id).title} (${props.task_list.find((p) => p.id === item.id).alias})`}
                              </MenuItem>
                            ))
                            :
                            props.task_list.filter((obj) => obj.action_required).map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {props.task_list.filter((obj) => obj.action_required).find((p) => p.id === item.id).alias === '' ? props.task_list.filter((obj) => obj.action_required).find((p) => p.id === item.id).title : `${props.task_list.filter((obj) => obj.action_required).find((p) => p.id === item.id).title} (${props.task_list.filter((obj) => obj.action_required).find((p) => p.id === item.id).alias})`}
                              </MenuItem>
                            ))
                          }
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled || parseInt(localStorage.getItem('workflow_state'), 10) > 0}>
                        <InputLabel id="nextTaskIDLabel">Next Task</InputLabel>
                        <Select
                          labelid="nextTaskIDLabel"
                          id="nextTaskidSelect"
                          value={nextTaskID}
                          onChange={onNextTaskIDChange.bind(this)}
                        >
                          <MenuItem key="0" value="0">None</MenuItem>
                          {props.task_list.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {props.task_list.find((p) => p.id === item.id).alias === '' ? props.task_list.find((p) => p.id === item.id).title : `${props.task_list.find((p) => p.id === item.id).title} (${props.task_list.find((p) => p.id === item.id).alias})`}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled || parseInt(localStorage.getItem('workflow_state'), 10) > 0}>
                        <InputLabel id="nextPhaseIDLabel">Phase</InputLabel>
                        <Select
                          labelid="nextPhaseIDLabel"
                          id="nextPhaseIDSelect"
                          value={nextPhaseID}
                          onChange={onNextPhaseIDChange.bind(this)}
                        >
                          <MenuItem key="0" value="0">None</MenuItem>
                          {props.phase_list.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {props.phase_list.find((p) => p.id === item.id).title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled || isTypeUnshare}>
                        <InputLabel id="typeLabel">Type</InputLabel>
                        <Select
                          labelid="typeLabel"
                          id="typeSelect"
                          value={type}
                          onChange={onTypeChange.bind(this)}
                        >
                          {props.actionType_list.filter((obj) => obj.type !== 'Unshare').map((item) => (
                            <MenuItem key={item.type} value={item.type}>
                              {props.actionType_list.filter((obj) => obj.type !== 'Unshare').find((p) => p.type === item.type).type}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      {/*
                        <div style={{ display: isTypeShare ? "block" : "none" }} >
                          <FormControl fullWidth={true} className={classes.formControl} disabled={fieldDisabled}>
                            <InputLabel id="attActionIDLabel">Attached Action</InputLabel>
                            <Select
                              labelid="attActionIDLabel"
                              id="attActionIDSelect"
                              value={attActionID}
                              onChange={onAttActionIDChange.bind(this)}
                            >
                              {filteredAttachedAction.map(item => (
                                < MenuItem key={item.id} value={item.id} >
                                  {filteredAttachedAction.find(p => p.id === item.id).title}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                       */}
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <InputLabel id="statusLabel" shrink>Status</InputLabel>
                      <Grid item component="label" container alignItems="center" spacing={1}>
                        <Grid item>Inactive</Grid>
                        <Grid item>
                          <Switch
                            labelid="statusLabel"
                            checked={status}
                            onChange={onStatusChange.bind(this)}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled={fieldDisabled}
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                            }}
                          />
                        </Grid>
                        <Grid item>Active</Grid>
                      </Grid>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <InputLabel id="requireCompleteTaskLabel" shrink>Require Complete Task</InputLabel>
                      <Grid item component="label" container alignItems="center" spacing={1}>
                        <Grid item>No</Grid>
                        <Grid item>
                          <Switch
                            labelid="requireCompleteTaskLabe"
                            checked={requireCompleteTask}
                            onChange={onRequireCompleteTaskChange.bind(this)}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled={fieldDisabled}
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                            }}
                          />
                        </Grid>
                        <Grid item>Yes</Grid>
                      </Grid>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <InputLabel id="transLabel" shrink>Is Transaction</InputLabel>
                      <Grid item component="label" container alignItems="center" spacing={1}>
                        <Grid item>No</Grid>
                        <Grid item>
                          <Switch
                            labelid="transLabel"
                            checked={isTrans}
                            onChange={onIsTransChange.bind(this)}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled={fieldDisabled}
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                            }}
                          />
                        </Grid>
                        <Grid item>Yes</Grid>
                      </Grid>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <div style={{ display: isTrans ? 'block' : 'none' }}>
                        <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled || isTypeUnshare}>
                          <InputLabel id="transtypeLabel">Transaction Type</InputLabel>
                          <Select
                            labelid="transtypeLabel"
                            id="transTypeSelect"
                            value={transType}
                            onChange={onTransTypeChange.bind(this)}
                          >
                            <MenuItem key={"ITEM_TRANS_INCREASE"} value={"ITEM_TRANS_INCREASE"}>Increase</MenuItem>
                            <MenuItem key={"ITEM_TRANS_DECREASE"} value={"ITEM_TRANS_DECREASE"}>Decrease</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      {/*<InputLabel id="ruleLabel" shrink>Action Rule</InputLabel>*/}
                      <br />
                      {type === 'Start'
                        ? (
                          <ActionStartRuleComp
                            field_user_intg_list={props.field_user_intg_list.filter((obj) => obj.intg_field_data_type !== NONE)}
                            field_dept_intg_list={props.field_dept_intg_list.filter((obj) => obj.intg_field_data_type !== NONE)}
                            disabled={fieldDisabled}
                            rule={rule}
                            setActionRule={props.setActionRule}
                            action_rules={props.action_rules}
                          />
                        )
                        : (
                          <ActionRuleComp
                            field_list={props.field_list.filter((obj) => obj.field_data_type !== NONE)}
                            disabled={fieldDisabled}
                            rule={rule}
                            setActionRule={props.setActionRule}
                            action_rules={props.action_rules}
                          />
                        )}
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        fullWidth
                        multiline
                        rows={15}
                        label="Rule Custom"
                        value={ruleCustom}
                        onChange={onRuleCustomChange.bind(this)}
                        disabled={fieldDisabled || parseInt(localStorage.getItem('action_id'), 10) === id}
                      />
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter style={{ textAlign: 'left' }} />
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose.bind(this)} color="primary" round>
                Cancel
              </Button>
              <Button
                onClick={handleDialogConfirm.bind(this)}
                color="primary"
                round
                disabled={message === '' && dialogButton !== 'Save' && parseInt(localStorage.getItem('workflow_state'), 10) > 0}
              >
                {dialogButton}
              </Button>
            </DialogActions>
          </Dialog>
        </CardBody>
      </Card>
    </div>
  );
};
export default withStyles(extendedFormsStyle)(ActionComp);
