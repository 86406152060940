import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.jsx';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx';

const ActionDependencyComp = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogButton, setDialogButton] = useState('');
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [dialogType, setDialogType] = useState('add');
  const [data, setData] = useState([]);
  const [message, setMessage] = useState('');
  const [id, setID] = useState('');
  const [parentActionID, setParentActionID] = useState('');
  const [childActionID, setChildActionID] = useState('');

  const { getActions, getActionDependencies } = props;
  useEffect(() => {
    getActions();
    getActionDependencies();
  }, [getActions, getActionDependencies]);

  const handleEditClick = (e) => {
    setID(e[0]);
    setParentActionID(e[1]);
    setChildActionID(e[2]);
    setMessage('');
    setDialogTitle('Edit');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('update');
    setDialogOpen(true);
    props.resetMessage('');
  };
  const handleDeleteClick = (e) => {
    setID(e[0]);
    setParentActionID(e[1]);
    setChildActionID(e[2]);
    setMessage('');
    setDialogTitle('Delete');
    setDialogButton('Confirm Delete');
    setFieldDisabled(true);
    setDialogType('delete');
    setDialogOpen(true);
    props.resetMessage('');
  };
  const handleNewClick = (e) => {
    setParentActionID('');
    setChildActionID('');
    setMessage('');
    setDialogTitle('Add New');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('add');
    setDialogOpen(true);
    props.resetMessage('');
  };
  useEffect(() => {
    setMessage(props.message);
    if (props.refreshActionDependency) {
      props.getActionDependencies();
      props.getActions();
      setDialogOpen(false);
    }
    var rowItems = [];
    if (props.action_list.length > 0 && props.actionDependency_list.length > 0) {
      for (let i = 0; i < props.actionDependency_list.length; i++) {
        var colItems = [];
        colItems.push(i + 1);
        var parentActionObj = props.action_list.find((o) => o.id === props.actionDependency_list[i].parent_action_id);
        colItems.push(parentActionObj.title);
        var childActionObj = props.action_list.find((o) => o.id === props.actionDependency_list[i].child_action_id);
        colItems.push(childActionObj.title);
        if (!props.actionDependency_list[i].auto_generate) {
          colItems.push(
            <div>
              <IconButton
                onClick={handleEditClick.bind(this,
                  [props.actionDependency_list[i].id,
                    props.actionDependency_list[i].parent_action_id,
                    props.actionDependency_list[i].child_action_id,
                  ])}
                title="Edit row"
              >
                {' '}
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={handleDeleteClick.bind(this,
                  [props.actionDependency_list[i].id,
                    props.actionDependency_list[i].parent_action_id,
                    props.actionDependency_list[i].child_action_id,
                  ])}
                title="Delete row"
              >
                {' '}
                <DeleteIcon />
              </IconButton>
            </div>,
          );
        }
        rowItems.push(colItems);
      }
    }
    setData(rowItems);
  }, [props]);

  const handleDialogClose = (e) => {
    setDialogOpen(false);
    setMessage('');
  };
  const handleDialogConfirm = (e) => {
    if (dialogType === 'add') {
      props.createActionDependency({
        parent_action_id: parentActionID.toString(),
        child_action_id: childActionID.toString(),
      });
    } else if (dialogType === 'update') {
      props.updateActionDependency(id, {
        parent_action_id: parentActionID.toString(),
        child_action_id: childActionID.toString(),
      });
    } else if (dialogType === 'delete') {
      props.deleteActionDependency(id);
    }
  };

  const onParentActionIDChange = (e) => {
    setParentActionID(e.target.value);
  };
  const onChildActionIDChange = (e) => {
    setChildActionID(e.target.value);
  };

  const { classes } = props;
  const columns = [
    'Serial',
    'Parent Action',
    'Child Action',
    '',
  ];
  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    filter: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    selectToolbarPlacement: 'none',
  };
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Action Dependency</b>
          <p className={classes.cardCategoryWhite}>
            Here is the list showing a set of actions that are dependent on a specific action. 
          </p>
        </CardHeader>
        <CardBody>
          <MUIDataTable
            title={<Button onClick={handleNewClick.bind(this)} color="primary" title="Create a new Action Dependency" round> New Action Dependency </Button>}
            data={data}
            columns={columns}
            options={options}
          />
          <Dialog fullWidth open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              <Card>
                <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                  <b>{dialogTitle}</b>
                </CardHeader>
                <CardBody>
                  <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled}>
                        <InputLabel id="parentActionIdLabel">Parent Action</InputLabel>
                        <Select
                          labelId="parentActionIdLabel"
                          id="parentActionIdSelect"
                          value={parentActionID}
                          onChange={onParentActionIDChange.bind(this)}
                        >
                          {props.action_list.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {props.action_list.find((p) => p.id === item.id).alias === '' ? props.action_list.find((p) => p.id === item.id).title : `${props.action_list.find((p) => p.id === item.id).title} (${props.action_list.find((p) => p.id === item.id).alias})`}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled}>
                        <InputLabel id="childActionIdLabel">Child Action</InputLabel>
                        <Select
                          labelId="childActionIdLabel"
                          id="childActionIdSelect"
                          value={childActionID}
                          onChange={onChildActionIDChange.bind(this)}
                        >
                          {props.action_list.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {props.action_list.find((p) => p.id === item.id).alias === '' ? props.action_list.find((p) => p.id === item.id).title : `${props.action_list.find((p) => p.id === item.id).title} (${props.action_list.find((p) => p.id === item.id).alias})`}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <br />
                </CardBody>
                <CardFooter style={{ textAlign: 'left' }} />
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose.bind(this)} color="primary" round>
                Cancel
              </Button>
              <Button onClick={handleDialogConfirm.bind(this)} color="primary" round>
                {dialogButton}
              </Button>
            </DialogActions>
          </Dialog>
        </CardBody>
      </Card>
    </div>
  );
};
export default withStyles(extendedFormsStyle)(ActionDependencyComp);
