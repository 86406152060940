import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.jsx';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx';

const PhasesComp = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogButton, setDialogButton] = useState('');
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [dialogType, setDialogType] = useState('add');
  const [data, setData] = useState([]);
  const [message, setMessage] = useState('');
  const [id, setID] = useState('');
  const [title, setTitle] = useState('');
  const [alias, setAlias] = useState('');
  const [description, setDescription] = useState('');

  const { getPhases } = props;
  useEffect(() => {
    getPhases();
  }, [getPhases]);

  const handleEditClick = (e) => {
    setID(e[0]);
    setTitle(e[1]);
    setDescription(e[2]);
    setAlias(e[3]);
    setMessage('');
    setDialogTitle('Edit');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('update');
    setDialogOpen(true);
    props.resetMessage('');
  };
  const handleDeleteClick = (e) => {
    setID(e[0]);
    setTitle(e[1]);
    setDescription(e[2]);
    setAlias(e[3]);
    setMessage('');
    setDialogTitle('Delete');
    setDialogButton('Confirm Delete');
    setFieldDisabled(true);
    setDialogType('delete');
    setDialogOpen(true);
    props.resetMessage('');
  };
  const handleNewClick = (e) => {
    setTitle('');
    setDescription('');
    setMessage('');
    setAlias('');
    setDialogTitle('Add New');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('add');
    setDialogOpen(true);
    props.resetMessage('');
  };
  useEffect(() => {
    setMessage(props.message);
    if (props.refreshPhase) {
      props.getPhases();
      setDialogOpen(false);
    }
    var rowItems = [];
    for (let i = 0; i < props.phase_list.length; i++) {
      if (props.phase_list[i].title !== '') {
        var colItems = [];
        colItems.push(i + 1);
        colItems.push(props.phase_list[i].title);
        colItems.push(props.phase_list[i].alias);
        if (!props.phase_list[i].auto_generate) {
          colItems.push(
            <div>
              <IconButton
                onClick={handleEditClick.bind(this,
                  [props.phase_list[i].id,
                    props.phase_list[i].title,
                    props.phase_list[i].description,
                    props.phase_list[i].alias,
                  ])}
                title="Edit row"
              >
                {' '}
                <EditIcon />
              </IconButton>
              <IconButton
                onClick={handleDeleteClick.bind(this,
                  [props.phase_list[i].id,
                    props.phase_list[i].title,
                    props.phase_list[i].description,
                    props.phase_list[i].status,
                    props.phase_list[i].alias,
                  ])}
                title="Delete row"
              >
                {' '}
                <DeleteIcon />
              </IconButton>
            </div>,
          );
        }
        rowItems.push(colItems);
      }
    }
    setData(rowItems);
  }, [props]);

  const handleDialogClose = (e) => {
    setDialogOpen(false);
    setMessage('');
  };
  const handleDialogConfirm = (e) => {
    if (dialogType === 'add') {
      props.createPhase({
        title,
        description,
        alias,
      });
    } else if (dialogType === 'update') {
      props.updatePhase(id, {
        title,
        description,
        alias,
      });
    } else if (dialogType === 'delete') {
      props.deletePhase(id);
    }
  };
  const onTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const onAliasChange = (e) => {
    setAlias(e.target.value);
  };
  const onDescriptionChange = (e) => {
    setDescription(e.target.value);
  };
  const { classes } = props;
  const columns = [
    'Serial',
    'Title',
    'Alias',
    '',
  ];
  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    filter: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    selectToolbarPlacement: 'none',
  };
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Phases</b>
          <p className={classes.cardCategoryWhite}>
            Here is the list of the phases, which are places in workflow where more than one role can access the application.
          </p>
        </CardHeader>
        <CardBody>
          <MUIDataTable
            title={(
              <Button
                onClick={handleNewClick.bind(this)}
                color="primary"
                title="Create a new Phase"
                round
                disabled={parseInt(localStorage.getItem('workflow_state'), 10) > 0}
              >
                {' '}
                New Phase
              </Button>
)}
            data={data}
            columns={columns}
            options={options}
          />
          <Dialog fullWidth open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              <Card>
                <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                  <b>{dialogTitle}</b>
                </CardHeader>
                <CardBody>
                  <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        required
                        fullWidth
                        label="Title"
                        value={title}
                        onChange={onTitleChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        fullWidth
                        label="Alias"
                        value={alias}
                        onChange={onAliasChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        required={false}
                        fullWidth
                        label="Description"
                        multiline
                        rows={3}
                        value={description}
                        onChange={onDescriptionChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                </CardBody>
                <CardFooter style={{ textAlign: 'left' }} />
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose.bind(this)} color="primary" round>
                Cancel
              </Button>
              <Button
                onClick={handleDialogConfirm.bind(this)}
                color="primary"
                round
                disabled={message === '' && dialogButton !== 'Save' && parseInt(localStorage.getItem('workflow_state'), 10) > 0}
              >
                {dialogButton}
              </Button>
            </DialogActions>
          </Dialog>
        </CardBody>
      </Card>
    </div>
  );
};
export default withStyles(extendedFormsStyle)(PhasesComp);
