import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import MUIDataTable from 'mui-datatables';
import Table from '@material-ui/core/Table';
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from "@material-ui/core/Paper";
import { useTranslation } from 'react-i18next';

const AppTrackingComp = (props) => {
  const [data, setData] = useState([]);
  const { t } = useTranslation();

  const { getApplicationLog, application_id, role_id } = props;
  useEffect(() => {
    getApplicationLog(application_id, role_id);
  }, [getApplicationLog, application_id, role_id]);

  useEffect(() => {
    var rowItems = [];
    for (let i = 0; i < props.application_log.length; i++) {
      var colItems = [];
      var date = new Date(props.application_log[i].action_time);
      colItems.push(props.application_log[i].task_alias === '' ? props.application_log[i].task_title : props.application_log[i].task_alias);
      colItems.push(`${props.application_log[i].actor === 'hidden' ? '-' : props.application_log[i].actor + ' ' + (props.application_log[i].actor_email !== '' ? '(' + props.application_log[i].actor_email + ')' : '')} (${props.application_log[i].role_alias === '' ? props.application_log[i].role_title : props.application_log[i].role_alias})`);
      colItems.push(props.application_log[i].actor_department === 'hidden' ? '-' : props.application_log[i].actor_department);
      colItems.push(props.application_log[i].action_title);
      colItems.push(date.toLocaleString(localStorage.getItem('network_lang'), { weekday: "short", year: "numeric", month: "2-digit", day: "numeric", hour: "2-digit", minute: "2-digit" }));
      colItems.push(props.application_log[i].task_log);
      rowItems.push(colItems);
    }

    setData(rowItems);
  }, [props]);

  const columns = [
    t('app_detail_app_tracking_task'),
    t('app_detail_app_tracking_person'),
    t('app_detail_app_tracking_dept'),
    t('app_detail_app_tracking_action'),
    t('app_detail_app_tracking_action_time'),
    {
      name: 'task_logs',
      label: '',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
  ];
  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    filter: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    selectToolbarPlacement: 'none',
    expandableRows: props.role_show_msgs_in_status,
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <React.Fragment>
          <tr>
            <td colSpan={6}>
              <TableContainer component={Paper}>
                <Table style={{ minWidth: "650" }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Messages</TableCell>
                      <TableCell>Reminders</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowData[5].map(row => (
                      <TableRow key={row.action_id}>
                        {/*<TableCell component="th" scope="row"> {"row.name"} </TableCell>*/}
                        <TableCell>{row.message}</TableCell>
                        <TableCell align="center">{row.reminders}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>

        </React.Fragment>
      );
    },
    textLabels: {
      body: {
        noMatch: t('mui_table_no_match'),
        toolTip: t('mui_table_tool_tip'),
        columnHeaderTooltip: column => t('mui_table_header_tool_tip') + " " + column.label
      },
      pagination: {
        next: t('mui_table_next'),
        previous: t('mui_table_previous'),
        rowsPerPage: t('mui_table_rows_per_page'),
        displayRows: t('mui_table_display_rows'),
      },
      toolbar: {
        search: t('mui_table_search'),
        downloadCsv: t('mui_table_download_csv'),
        print: t('mui_table_print'),
        viewColumns: t('mui_table_view_columns'),
        filterTable: t('mui_table_filter_table'),
      },
      filter: {
        all: t('mui_table_filter_all'),
        title: t('mui_table_filter_title'),
        reset: t('mui_table_filter_reset'),
      },
      viewColumns: {
        title: t('mui_table_column_title'),
        titleAria: t('mui_table_column_title_aria'),
      },
      selectedRows: {
        text: t('mui_table_selected_text'),
        delete: t('mui_table_selected_delete'),
        deleteAria: t('mui_table_selected_delete_aria'),
      },
    }
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <Typography variant="h5" gutterBottom>{t('app_detail_app_tracking_title')}</Typography>
        </CardHeader>
        <CardBody>
          <MUIDataTable
            data={data}
            columns={columns}
            options={options}
          />
        </CardBody>
      </Card>
    </div>
  );
};
export default AppTrackingComp;
