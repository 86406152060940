/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.jsx';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx';

const NameExclusionComp = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogButton, setDialogButton] = useState('');
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [dialogType, setDialogType] = useState('add');
  const [data, setData] = useState([]);
  const [message, setMessage] = useState('');
  const [id, setID] = useState('');
  const [roleID, setRoleID] = useState('');
  const [excludedRoleID, setExcludedRoleID] = useState('');

  const { getRoles, getNameExclusions } = props;
  useEffect(() => {
    getRoles();
    getNameExclusions();
  }, [getRoles, getNameExclusions]);

  const handleEditClick = (e) => {
    setID(e[0]);
    setRoleID(e[1]);
    setExcludedRoleID(e[2]);
    setMessage('');
    setDialogTitle('Edit');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('update');
    setDialogOpen(true);
    props.resetMessage('');
  };
  const handleDeleteClick = (e) => {
    setID(e[0]);
    setRoleID(e[1]);
    setExcludedRoleID(e[2]);
    setMessage('');
    setDialogTitle('Delete');
    setDialogButton('Confirm Delete');
    setFieldDisabled(true);
    setDialogType('delete');
    setDialogOpen(true);
    props.resetMessage('');
  };
  const handleNewClick = (e) => {
    setRoleID('');
    setExcludedRoleID('');
    setMessage('');
    setDialogTitle('Add New');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('add');
    setDialogOpen(true);
    props.resetMessage('');
  };
  useEffect(() => {
    setMessage(props.message);
    if (props.refreshNameExclusion) {
      props.getNameExclusions();
      setDialogOpen(false);
    }
    var rowItems = [];
    if (props.role_list.length > 0 && props.nameExclusion_list.length > 0) {
      for (let i = 0; i < props.nameExclusion_list.length; i++) {
        var colItems = [];
        colItems.push(i + 1);
        var roleObj = props.role_list.find((o) => o.id === props.nameExclusion_list[i].role_id);
        roleObj === undefined ? colItems.push('') : colItems.push(roleObj.alias !== "" ? roleObj.title + " (" + roleObj.alias + ")" : roleObj.title);
        var excludedRoleObj = props.role_list.find((o) => o.id === props.nameExclusion_list[i].excluded_role_id);
        excludedRoleObj === undefined ? colItems.push('') : colItems.push(excludedRoleObj.alias !== "" ? excludedRoleObj.title + " (" + excludedRoleObj.alias + ")" : excludedRoleObj.title);
        colItems.push(
          <div>
            <IconButton
              onClick={handleEditClick.bind(this,
                [props.nameExclusion_list[i].id,
                props.nameExclusion_list[i].role_id,
                props.nameExclusion_list[i].excluded_role_id,
                ])}
              title="Edit row"
            >
              {' '}
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={handleDeleteClick.bind(this,
                [props.nameExclusion_list[i].id,
                props.nameExclusion_list[i].role_id,
                props.nameExclusion_list[i].excluded_role_id,
                ])}
              title="Delete row"
            >
              {' '}
              <DeleteIcon />
            </IconButton>
          </div>,
        );
        rowItems.push(colItems);
      }
    }
    setData(rowItems);
  }, [props]);

  const handleDialogClose = (e) => {
    setDialogOpen(false);
    setMessage('');
  };
  const handleDialogConfirm = (e) => {
    if (dialogType === 'add') {
      props.createNameExclusion({
        role_id: roleID.toString(),
        excluded_role_id: excludedRoleID.toString(),
      });
    } else if (dialogType === 'update') {
      props.updateNameExclusion(id, {
        role_id: roleID.toString(),
        excluded_role_id: excludedRoleID.toString(),
      });
    } else if (dialogType === 'delete') {
      props.deleteNameExclusion(id);
    }
  };

  const onRoleIDChange = (e) => {
    setRoleID(e.target.value);
  };
  const onExcludedRoleIDChange = (e) => {
    setExcludedRoleID(e.target.value);
  };

  const { classes } = props;
  const columns = [
    'ID',
    'Role',
    'Excluded Role',
    '',
  ];
  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    filter: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    selectToolbarPlacement: 'none',
  };
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Name Exclusion</b>
          <p className={classes.cardCategoryWhite}>
            Here is the list of roles that need to be hidden from certain other role in all applications in the workflow.
          </p>
        </CardHeader>
        <CardBody>
          <MUIDataTable
            title={<Button onClick={handleNewClick.bind(this)} color="primary" title="Create a new Name Exclusion" round> New Name Exclusion </Button>}
            data={data}
            columns={columns}
            options={options}
          />
          <Dialog fullWidth open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              <Card>
                <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                  <b>{dialogTitle}</b>
                </CardHeader>
                <CardBody>
                  <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled}>
                        <InputLabel id="roleIdLabel">Role</InputLabel>
                        <Select
                          labelId="roleIdLabel"
                          id="roleIdSelect"
                          value={roleID}
                          onChange={onRoleIDChange.bind(this)}
                        >
                          {props.role_list.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {props.role_list.find((p) => p.id === item.id).alias !== "" ? props.role_list.find((p) => p.id === item.id).title + " (" + props.role_list.find((p) => p.id === item.id).alias + ")" : props.role_list.find((p) => p.id === item.id).title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled}>
                        <InputLabel id="excludedRoleIdLabel">Excluded Role</InputLabel>
                        <Select
                          labelId="excludedRoleIdLabel"
                          id="excludedRoleIdSelect"
                          value={excludedRoleID}
                          onChange={onExcludedRoleIDChange.bind(this)}
                        >
                          {props.role_list.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {props.role_list.find((p) => p.id === item.id).alias !== "" ? props.role_list.find((p) => p.id === item.id).title + " (" + props.role_list.find((p) => p.id === item.id).alias + ")" : props.role_list.find((p) => p.id === item.id).title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <br />
                </CardBody>
                <CardFooter style={{ textAlign: 'left' }} />
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose.bind(this)} color="primary" round>
                Cancel
              </Button>
              <Button onClick={handleDialogConfirm.bind(this)} color="primary" round>
                {dialogButton}
              </Button>
            </DialogActions>
          </Dialog>
        </CardBody>
      </Card>
    </div>
  );
};
export default withStyles(extendedFormsStyle)(NameExclusionComp);
