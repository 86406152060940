import React from 'react';
import Button from 'components/CustomButtons/Button.jsx';
import CircularProgress from '@mui/material/CircularProgress';
import SaveIcon from '@mui/icons-material/Save';
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import NavigateNext from '@mui/icons-material/NavigateNext';
import format from 'date-fns/format';
import startOfWeek from 'date-fns/startOfWeek';
import { useTranslation } from 'react-i18next';
import { SaveFormToAPI } from 'Api/common.js';

import {
  CHECK_BOX,
  COMMITTEE,
  FILE_SHARING,
  WEEK_CALENDAR,
} from 'constants/FieldTypes';


const FormSaveComp = (props) => {
  const { t, i18n } = useTranslation();
  const saveForm = (formID) => {
    console.log("saveForm ---------------------------------------1")
    console.log(props.fields)
    console.log("saveForm ---------------------------------------2")
    SaveFormToAPI(formID, props.requesting, props.fields, props.role_user, props.share_file, props.weekSelect, props.postApplicationDetailCommittee, props.postApplicationDetail, props.application_id, props.task_id)
  };
  const handlePreviousClick = (e) => {
    props.setShowForm('previous', e);
    saveForm(e);
  };
  const handleSaveClick = (e) => {
    props.setShowForm('save', e);
    saveForm(e);
  };
  const handleNextClick = (e) => {
    props.setShowForm('next', e);
    saveForm(e);
  };

  var displayPreviousButton = true;
  var displaySaveButton = true;
  var displayNextButton = true;
  if (props.submitableFormList.length > 0) {
    if (props.submitableFormList[0].id === props.form_id) {
      displayPreviousButton = false;
    }
  }
  if (props.submitableFormList.length < 1) {
    displayPreviousButton = false;
    displaySaveButton = false;
    displayNextButton = false;
  }
  return (
    <div>
      <Button
        onClick={handlePreviousClick.bind(this, props.form_id)}
        color="primary"
        round
        style={{ display: displayPreviousButton ? 'inline-block' : 'none' }}
      >
        {props.requesting
          ? <CircularProgress size={20} color="inherit" />
          : i18n.dir() === 'rtl' ? <NavigateNext /> : <NavigateBefore />}
        &nbsp;&nbsp; {t('app_detail_form_save_previous')}
      </Button>

      <Button
        onClick={handleSaveClick.bind(this, props.form_id)}
        color="primary"
        round
        style={{ display: displaySaveButton ? 'inline-block' : 'none' }}
      >
        {props.requesting
          ? <CircularProgress size={20} color="inherit" />
          : <SaveIcon />}
        &nbsp;&nbsp;{t('app_detail_form_save_save')}
      </Button>
      <Button
        onClick={handleNextClick.bind(this, props.form_id)}
        color="primary"
        round
        style={{ display: displayNextButton ? 'inline-block' : 'none' }}
      >
        {t('app_detail_form_save_next')}&nbsp;&nbsp;
        {props.requesting
          ? <CircularProgress size={20} color="inherit" /> : i18n.dir() === 'rtl' ? <NavigateBefore /> : <NavigateNext />}
      </Button>
    </div>
  );
};

export default FormSaveComp;
