import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as applicationStatusActions from 'actions/ApplicationStatusActions';
import AppTrackingComp from './AppTracking/AppTrackingComp';

const AppTracking = (props) => {
  const { applicationStatus } = props;
  const { getApplicationLog } = props.applicationStatusActions;
  return (
    <AppTrackingComp
      application_id={props.application_id}
      role_id={props.role_id}
      role_show_msgs_in_status={props.role_show_msgs_in_status}
      getApplicationLog={getApplicationLog}
      application_log={applicationStatus.application_log === undefined ? [] : applicationStatus.application_log}
    />
  );
}

function mapStateToProps(state) {
  return {
    applicationStatus: state.applicationStatus,
  };
}
function mapDispatchToProps(dispatch) {
  return {
    applicationStatusActions: bindActionCreators(applicationStatusActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AppTracking);
