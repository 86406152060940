import React from 'react';

import NetworkAdmnLogin from 'views/networkadmin/Login.jsx';
import NetworkAdmnNetwork from 'views/Network.jsx';

const networkAdminPublicRoutes = [
  {
    path: '/networkadmin/public/network',
    component: <NetworkAdmnNetwork />,
  },
  {
    path: '/networkadmin/public/login',
    component: <NetworkAdmnLogin />,
  },
];

export default networkAdminPublicRoutes;
