import {
  GET_ITEM_ADMIN_REQUEST,
  GET_ITEM_ADMIN_SUCCESS,
  GET_ITEM_ADMIN_FAILURE,
  GET_ITEM_CSV_ADMIN_REQUEST,
  GET_ITEM_CSV_ADMIN_SUCCESS,
  GET_ITEM_CSV_ADMIN_FAILURE,
  POST_ITEM_ADMIN_REQUEST,
  POST_ITEM_ADMIN_SUCCESS,
  POST_ITEM_ADMIN_FAILURE,
  PATCH_ITEM_ADMIN_REQUEST,
  PATCH_ITEM_ADMIN_SUCCESS,
  PATCH_ITEM_ADMIN_FAILURE,
  DELETE_ITEM_SELECT_ADMIN_REQUEST,
  DELETE_ITEM_SELECT_ADMIN_SUCCESS,
  DELETE_ITEM_SELECT_ADMIN_FAILURE,
  GET_ITEM_INTG_ADMIN_REQUEST,
  GET_ITEM_INTG_ADMIN_SUCCESS,
  GET_ITEM_INTG_ADMIN_FAILURE,
  PATCH_ITEM_INTG_ADMIN_REQUEST,
  PATCH_ITEM_INTG_ADMIN_SUCCESS,
  PATCH_ITEM_INTG_ADMIN_FAILURE,
} from '../constants/admin/Item';

const initialState = {
  requesting: false,
  message: '',
  item_list: [],
  item_list_csv: [],
  item_intg_list: [],
  item_delete_error_list: [],
  refreshItem: false,
  refreshItemIntg: false,
};

export default function item(state = initialState, action) {
  switch (action.type) {
    case GET_ITEM_ADMIN_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshItem: false,
        message: '',
        item_list: [],
      };

    case GET_ITEM_ADMIN_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        item_list: action.payload.data,
      };

    case GET_ITEM_ADMIN_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case GET_ITEM_INTG_ADMIN_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshItemIntg:false,
        item_intg_list: [],
      };

    case GET_ITEM_INTG_ADMIN_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        item_intg_list: action.payload.data,
      };

    case GET_ITEM_INTG_ADMIN_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case POST_ITEM_ADMIN_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshItem: false,
      };

    case POST_ITEM_ADMIN_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshItem: true,
      };

    case POST_ITEM_ADMIN_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case PATCH_ITEM_ADMIN_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshItem: false,
      };

    case PATCH_ITEM_ADMIN_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshItem: true,
      };

    case PATCH_ITEM_ADMIN_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case DELETE_ITEM_SELECT_ADMIN_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        item_delete_error_list: [],
        refreshItem: false,
      };

    case DELETE_ITEM_SELECT_ADMIN_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        item_delete_error_list: action.payload.data,
        refreshItem: true,
      };

    case DELETE_ITEM_SELECT_ADMIN_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case GET_ITEM_CSV_ADMIN_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
      };

    case GET_ITEM_CSV_ADMIN_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        item_list_csv: action.payload.data,
      };

    case GET_ITEM_CSV_ADMIN_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case PATCH_ITEM_INTG_ADMIN_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshItemIntg: false,
      };

    case PATCH_ITEM_INTG_ADMIN_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshItemIntg: true,
      };

    case PATCH_ITEM_INTG_ADMIN_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
