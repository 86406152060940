import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CircularProgress from '@mui/material/CircularProgress';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@mui/material/Typography';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';


import extendedFormsStyle from 'assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx';

const AppCorresComp = (props) => {
  const [data, setData] = useState([]);
  const [message, setMessage] = useState('');

  const { getApplicationCorres, application_id } = props;
  useEffect(() => {
    getApplicationCorres(application_id);
  }, [getApplicationCorres, application_id]);

  useEffect(() => {
    setMessage(props.message);
    var rowItems = [];
    if (props.application_corres_list.length > 0) {
      for (let i = 0; i < props.application_corres_list.length; i++) {
        var date = new Date(props.application_corres_list[i].insert_date);

        var dateStr = `${date.toDateString()} ${date.toLocaleTimeString()}`;
        var emailStatus = dateStr
        if (props.application_corres_list[i].email_status !== "EMAIL_SENT") {
          if (props.application_corres_list[i].email_status === "EMAIL_ERROR") {
            emailStatus = <Typography style={{ color: 'red' }} variant="body2" align="center" > {dateStr + " (Undelivered)"}<ReportProblemIcon style={{ color: '#b21c0e' }} /></Typography>;
          } else if (props.application_corres_list[i].email_status === "EMAIL_NOT_SENT") {
            emailStatus = <Typography style={{ color: 'red' }} variant="body2" align="center" >{dateStr + " (Email Not Sent)"}</Typography>;
          }
        }

        var colItems = [];
        colItems.push(i + 1);
        //colItems.push(`${date.toDateString()} ${date.toLocaleTimeString()}`  emailStatus);
        colItems.push(emailStatus);
        colItems.push(props.application_corres_list[i].to_name + " (" + props.application_corres_list[i].to_email + ")");
        colItems.push(props.application_corres_list[i].cc_email);
        colItems.push(props.application_corres_list[i].subject);
        colItems.push(props.application_corres_list[i].body);
        rowItems.push(colItems);
      }
    }
    setData(rowItems);
  }, [props]);

  // const { classes } = props;
  const columns = [
    'Serial',
    'Sent At',
    'To',
    'CC',
    'Subject',
    'Email Message',
  ];
  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    filter: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    selectToolbarPlacement: 'none',
  };
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Correspondences </b>
        </CardHeader>
        <CardBody>
          <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
          <MUIDataTable
            title={(<Typography variant="body1" align="left" > {props.application_corres_list.length > 0 ? props.application_corres_list[0].title : ""} </Typography>)}
            data={data}
            columns={columns}
            options={options}
          />
        </CardBody>
      </Card>
    </div>
  );
};
export default withStyles(extendedFormsStyle)(AppCorresComp);
