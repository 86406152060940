/* eslint-disable func-names */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import Button from 'components/CustomButtons/Button.jsx';
import Card from '@mui/material/Card';
import CardBody from 'components/Card/CardBody';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@material-ui/core/Box';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';

const FileSharingComp = (props) => {
  const [shareFileLocal, setShareFileLocal] = useState(null);
  const [displayUploadProgress, setDisplayUploadProgress] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const { t } = useTranslation()

  const useStyles = makeStyles({
    root: {
      width: '100%',
    },
  });

  const classes = useStyles();

  return (
    <Card>
      <CardBody>
        <FormControl
          required={props.field_required === 'true'}
          error={props.fields.find((o) => o.id === props.field_id).error}
          id={props.field_id}
          key={`fileSharingForm${props.field_id}`}
          component="fieldset"
        >
          <FormLabel>{props.field_label}</FormLabel>
        </FormControl>
        <br />
        <br />
        <br />
        <form id="uploadForm">
          <GridContainer>
            <GridItem xs={12} sm={12} md={6}>
              <input
                type="file"
                //onChange={onFileChange.bind(this)}
                style={{ display: 'none' }}
                id={`raised-button-userfile${props.field_id}`}
              />
              <label htmlFor={`raised-button-userfile${props.field_id}`}>
                <Button
                  round
                  color="primary"
                  component="span"
                  id={`button${props.field_id}`}
                  key={`button${props.field_id}`}
                >
                  {t('app_detail_file_sharing_select_file_btn')}
                </Button>
              </label>
            </GridItem>
            <GridItem xs={12} sm={12} md={6}>
              <Button
                round
                id={`uploadButton${props.field_id}`}
                key={`uploadButton${props.field_id}`}
                color="primary"
                type="submit"
                style={{ float: 'right', display: 'none' }}
                disabled={shareFileLocal === null}
              >
                {t('app_detail_file_sharing_upload_file_btn')}
              </Button>
            </GridItem>
          </GridContainer>
          <br />
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <div
                className={classes.root}
                style={{ display: displayUploadProgress ? 'block' : 'none' }}
              >
                <LinearProgressWithLabel value={progress} />
              </div>
            </GridItem>
          </GridContainer>
        </form>
      </CardBody>
    </Card>
  );
};

export default FileSharingComp;

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">
          {`${Math.round(
            props.value,
          )}%`}
        </Typography>
      </Box>
    </Box>
  );
}
