/* eslint-disable react/display-name */
import React, { useState, useEffect } from 'react';
import MUIDataTable from 'mui-datatables';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.jsx';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import TextField from '@material-ui/core/TextField';
import { Link, Navigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import { useTranslation } from 'react-i18next';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import Typography from '@mui/material/Typography';
import Box from '@material-ui/core/Box';
import InputLabel from '@material-ui/core/InputLabel';
import Grid from '@material-ui/core/Grid';
import Switch from '@material-ui/core/Switch'
import PanToolIcon from '@mui/icons-material/PanTool';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import moment from 'moment';

import withRoot from 'withRoot';

import {
  TEXT_FIELD,
  TEXT_AREA,
  HELPTEXT,
} from 'constants/FieldTypes';

const ApplicationListComp = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogButton, setDialogButton] = useState('');
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [dialogType, setDialogType] = useState('add')
  const [data, setData] = useState([]);
  const [dataArchive, setDataArchive] = useState([]);
  const [id, setID] = useState('');
  const [applicationName, setApplicationName] = useState('');
  const [applicationDesc, setApplicationDesc] = useState('');
  const [applicationStatus, setApplicationStatus] = useState(false);
  const [roleAllowAppEdit, setRoleAllowAppEdit] = useState(false);
  const [roleAllowAppOnhold, setRoleAllowAppOnhold] = useState(false);
  const [message, setMessage] = useState('');
  const [helpDescription, setHelpDescription] = useState([]);
  const [activePageSize, setActivePageSize] = useState(100);
  const [activePageNum, setActivePageNum] = useState(1);
  const [activeOrderBy, setActiveOrderBy] = useState('id');
  const [activeOrderByDirec, setActiveOrderByDirec] = useState('desc');
  const [activeSearchText, setActiveSearchText] = useState('');
  const [archivePageSize, setArchivePageSize] = useState(100);
  const [archivePageNum, setArchivePageNum] = useState(1);
  const [archiveOrderBy, setArchiveOrderBy] = useState('id');
  const [archiveOrderByDirec, setArchiveOrderByDirec] = useState('desc');
  const [archiveSearchText, setArchiveSearchText] = useState('');
  const [redirectToDetail, setRedirectToDetail] = useState(false);
  const [clickDefaultAppTitle, setClickDefaultAppTitle] = useState(false);
  const [hideStatusInput, setHideStatusInput] = useState(true);

  const [dialogDetailOpen, setDialogDetailOpen] = useState(false);
  const [dialogDetailButton, setDialogDetailButton] = useState('');
  const [applicationUIList, setApplicationUIList] = useState([]);
  const [applicationList, setApplicationList] = useState([]);
  const [currentApp, setCurrentApp] = useState(0);
  const [commonColName, setCommonColName] = useState([]);
  const [customColName, setCustomColName] = useState([]);

  const { t } = useTranslation();


  const renderTableCell = (value, tableMeta) => {
    const linkPath = tableMeta.rowData[1] === 'INACCESSIBLE'
      ? `/status/${tableMeta.rowData[0]}/${tableMeta.rowData[2]}`
      : `/detail/${tableMeta.rowData[0]}/${tableMeta.rowData[2]}`;

    console.log("tableMeta", tableMeta.columnData.name)
    return (
      tableMeta.columnData.name === "task_status" ?
        <TextField
          value={value}
          maxRows={5}
          InputProps={{ style: { color: tableMeta.rowData[1] === 'INACCESSIBLE' ? 'red' : 'green' }, disableUnderline: true }}
          multiline
        />
        :
        <Link to={linkPath}>
          <div style={{ color: tableMeta.rowData[1] === 'INACCESSIBLE' ? 'red' : 'green' }}>
            {value}
          </div>
        </Link>
    );
  };


  var common_col_name = [
    {
      name: 'application_id',
      label: t('app_list_active_serial'),
      display: false,
      options: {
        filter: true,
        sort: true,
        display: false,
      },
    },
    {
      name: 'status',
      label: '',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
    {
      name: 'role_id',
      label: 'Role ID',
      options: {
        filter: true,
        sort: false,
        display: false,
      },
    },
    {
      name: 'app_name',
      label: t('app_list_active_title'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>{renderTableCell(value, tableMeta)}</div>
        ),
      },
    },

    {
      name: 'name_string',
      label: t('app_list_active_name_init'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>{renderTableCell(value, tableMeta)}</div>
        ),
      },
    },
    {
      name: 'role_title',
      label: t('app_list_active_role_title'),
      options: {
        filter: true,
        sort: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>{renderTableCell(value, tableMeta)}</div>
        ),
      },
    },

    {
      name: 'start_date',
      label: t('app_list_active_start_date'),
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>{renderTableCell(value, tableMeta)}</div>
        ),
      },
    },



    {
      name: 'task_status',
      label: t('app_list_active_status'),
      options: {
        filter: true,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>{renderTableCell(value, tableMeta)}</div>
        ),
      },
    },
    {
      name: 'app_desc',
      label: t('app_list_active_desc'),
      options: {
        filter: false,
        sort: false,
        display: true,
        customBodyRender: (value, tableMeta, updateValue) => (
          <div>{renderTableCell(value, tableMeta)}</div>
        ),
      },
    },
    {
      name: '',
      label: '',
      options: {
        filter: false,
        sort: false,
        display: true,
      },
    },
  ];


  const {
    resetApplicationDetail, getActiveApplications, getArchiveApplications, isAllowedToCreateApplication, getWorkflowByUser,
  } = props;
  useEffect(() => {
    resetApplicationDetail();
    getActiveApplications(activePageSize, activePageNum, activeOrderBy, activeOrderByDirec, activeSearchText);
    getArchiveApplications(archivePageSize, archivePageNum, archiveOrderBy, archiveOrderByDirec, archiveSearchText);
    isAllowedToCreateApplication();
    getWorkflowByUser();

    var wsAppListCustom = localStorage.getItem("app_list_custom")
    if (wsAppListCustom === null) {
      localStorage.clear()
      window.location.reload();
    }
    if (wsAppListCustom !== "") {
      var arrWsAppListCustom = JSON.parse(wsAppListCustom)
      if (arrWsAppListCustom.length > 0 && arrWsAppListCustom[0] !== "") {
        setCustomColName(arrWsAppListCustom)
        arrWsAppListCustom.forEach((element, i) => {
          var tempColUI = {
            name: "Dummy Name",
            label: "Dummy Label",
            options: {
              filter: true,
              sort: true,
              customBodyRender: (value, tableMeta, updateValue) => (
                <div>{renderTableCell(value, tableMeta)}</div>
              ),
            },
          };
          tempColUI.label = element
          common_col_name.splice(7 + i, 0, tempColUI);
        })
      }
    }
    setCommonColName(common_col_name)


  }, [resetApplicationDetail, getActiveApplications, getArchiveApplications, isAllowedToCreateApplication, getWorkflowByUser]);

  useEffect(() => {
    setMessage(props.message);
    if (props.refreshApplication) {
      if (clickDefaultAppTitle &&
        props.newApplicationID !== "" &&
        localStorage.getItem("default_app_title") !== "") {
        setRedirectToDetail(true)
      } else {
        if (props.application_detail_form_without_app.forms !== undefined) {
          window.location.reload();
        } else {
          setDialogOpen(false);
          setDialogDetailOpen(false);
          props.getActiveApplications(activePageSize, activePageNum, activeOrderBy, activeOrderByDirec, activeSearchText);
        }
      }
    }
    if (props.application_detail_form_without_app.forms !== undefined && currentApp == 0) {
      getApplicationRecord(props.application_detail_form_without_app.forms);
      const tempApplicationUIList = []
      tempApplicationUIList.push(<GetApplicationUI
        application_detail_form_without_app={props.application_detail_form_without_app}
        app_create_with_detail_msg={props.app_create_with_detail_msg}
      />);
      setApplicationUIList(tempApplicationUIList)
    }
    var rowItems = [];
    for (let i = 0; i < props.active_application_list.length; i++) {
      if (props.active_application_list[i].app_name !== '') {
        var appStatus = parseInt(props.active_application_list[i].app_status, 10)
        var colItems = [];
        //colItems.push(i + 1);
        colItems.push(props.active_application_list[i].application_id);
        colItems.push(props.active_application_list[i].status);
        colItems.push(props.active_application_list[i].role_id);
        colItems.push(props.active_application_list[i].app_name);
        colItems.push(props.active_application_list[i].name_string);
        colItems.push(props.active_application_list[i].role_alias !== "" ? props.active_application_list[i].role_alias : props.active_application_list[i].role_title);
        colItems.push(moment.utc(props.active_application_list[i].start_date).format('llll'));

        customColName.forEach((element, k) => {
          var variableName = `app_list_custom${k + 1}`
          colItems.push(props.active_application_list[i][variableName]);
        })
        colItems.push(props.active_application_list[i].task_alias !== "" ? props.active_application_list[i].task_alias : props.active_application_list[i].task_title);
        colItems.push(props.active_application_list[i].app_desc);
        colItems.push(
          appStatus > 1 ?
            <div>
              <IconButton
                title="On Hold (Undelivered Email)">
                <ReportProblemIcon style={{ color: '#b21c0e' }} />
              </IconButton>
            </div>
            :
            props.active_application_list[i].role_allow_app_edit || props.active_application_list[i].role_allow_app_onhold ?
              <div>
                {props.active_application_list[i].app_status === "1" ?
                  <IconButton
                    onClick={handleEditClick.bind(this,
                      [props.active_application_list[i].application_id,
                      props.active_application_list[i].app_name,
                      props.active_application_list[i].app_desc,
                      props.active_application_list[i].app_status,
                      props.active_application_list[i].role_allow_app_edit,
                      props.active_application_list[i].role_allow_app_onhold,
                      ])}
                    title="On-Hold"
                  >
                    <PanToolIcon style={{ color: '#b21c0e' }} />
                  </IconButton> : ""}
                <IconButton
                  onClick={handleEditClick.bind(this,
                    [props.active_application_list[i].application_id,
                    props.active_application_list[i].app_name,
                    props.active_application_list[i].app_desc,
                    props.active_application_list[i].app_status,
                    props.active_application_list[i].role_allow_app_edit,
                    props.active_application_list[i].role_allow_app_onhold,
                    ])}
                  title="Edit row"
                >
                  {' '}
                  <EditIcon />
                </IconButton>
              </div> : <div>
                {props.active_application_list[i].app_status === "1" ?
                  <IconButton>
                    <PanToolIcon style={{ color: '#b21c0e' }} />
                  </IconButton> : ""}
              </div>)
        rowItems.push(colItems);
      }
    }
    var rowItemsArchive = [];
    for (let i = 0; i < props.archive_application_list.length; i++) {
      if (props.archive_application_list[i].app_name !== '') {
        var colItemsAr = [];
        //colItemsAr.push(i + 1);
        colItemsAr.push(props.archive_application_list[i].application_id);
        colItemsAr.push(props.archive_application_list[i].status);
        colItemsAr.push(props.archive_application_list[i].role_id);
        colItemsAr.push(props.archive_application_list[i].app_name);
        colItemsAr.push(props.archive_application_list[i].name_string);
        colItemsAr.push(props.archive_application_list[i].role_alias !== "" ? props.archive_application_list[i].role_alias : props.archive_application_list[i].role_title);
        colItemsAr.push(moment.utc(props.archive_application_list[i].start_date).format('llll'));
        customColName.forEach((element, k) => {
          var variableName = `app_list_custom${k + 1}`
          colItemsAr.push(props.archive_application_list[i][variableName]);
        })
        colItemsAr.push(props.archive_application_list[i].task_alias !== "" ? props.archive_application_list[i].task_alias : props.archive_application_list[i].task_title);
        colItemsAr.push(props.archive_application_list[i].app_desc);
        colItemsAr.push("");
        rowItemsArchive.push(colItemsAr);
      }
    }
    setData(rowItems);
    setDataArchive(rowItemsArchive);
    setHelpDescription(props.workflow.description);
  }, [props]);

  /* Detail Start */
  const handleAddClick = (e) => {
    getApplicationRecord(props.application_detail_form_without_app.forms);
    const tempApplicationUIList = applicationUIList
    tempApplicationUIList.push(
      <GetApplicationUI
        application_detail_form_without_app={props.application_detail_form_without_app}
        app_create_with_detail_msg={props.app_create_with_detail_msg}
      />)
    setApplicationUIList(tempApplicationUIList)
  };

  const handleDialogMultiClose = (e) => {
    setDialogDetailOpen(false);
    setMessage('');
  };

  const handleDialogMultiConfirm = (e) => {
    if (!props.requesting) {
      props.processMultipleApp(applicationList)
    }
  };
  const onApplicationNameMultipleChange = (appID, e) => {
    var tempApplicationList = applicationList;
    var foundIndex = tempApplicationList.findIndex((x) => x.id === appID);
    tempApplicationList[foundIndex].app_title_value = e.target.value
    setApplicationList(tempApplicationList);
  };

  const onApplicationDescMultipleChange = (appID, e) => {
    var tempApplicationList = applicationList;
    var foundIndex = tempApplicationList.findIndex((x) => x.id === appID);
    tempApplicationList[foundIndex].app_desc_value = e.target.value
    setApplicationList(tempApplicationList);
  };

  const onFieldValueChange = (fieldID, appID, e) => {
    var tempApplicationList = applicationList;
    var foundIndex = tempApplicationList.findIndex((x) => x.id === appID);
    var foundFieldIndex = tempApplicationList[foundIndex].fields.findIndex((x) => x.id === fieldID);
    var tempValueArray = [];
    tempValueArray.push(e.target.value.trim());
    tempApplicationList[foundIndex].fields[foundFieldIndex].value = tempValueArray
    setApplicationList(tempApplicationList);
  };

  var formID = 0;
  var taskID = 0;
  var getApplicationRecord = function (form) {
    var tempFields = [];
    if (props.application_detail_form_without_app.forms !== undefined) {
      taskID = props.application_detail_form_without_app.id;
      props.application_detail_form_without_app.forms.forEach((form, index) => {
        formID = form.id
        if (form.checkable && index === 0) {
          form.fields.forEach((field) => {
            tempFields.push({ "id": field.id, "value": [] });

          })
        }
      })
    }
    var tempApps = applicationList;
    tempApps.push({ "id": currentApp, "app_title_value": "", "app_desc_value": "", "task_id": taskID, "form_id": formID, "fields": tempFields })
    setApplicationList(tempApps)
    var tempCurrentApp = currentApp + 1
    setCurrentApp(tempCurrentApp)
  }

  const GetApplicationUI = (props) => {
    const applicationUI = [];
    const fieldItems = [];

    if (props.application_detail_form_without_app.forms !== undefined) {
      props.application_detail_form_without_app.forms.forEach((form, index) => {
        if (form.checkable && index === 0) {
          form.fields.forEach((field) => {
            var fieldUI = [];
            if (field.type === TEXT_FIELD) {
              var isDisabled = false;
              if (field.is_read_only || !form.checkable) {
                isDisabled = true;
              }
              fieldUI.push(
                <TextField
                  required={field.required === 'true'}
                  //value={applicationList.find((o) => o.id === currentApp).fields.find((o) => o.id === field.id).value}
                  key={"field_" + currentApp + field.id}
                  id={`${currentApp + field.id}`}
                  fullWidth
                  label={field.label}
                  onChange={onFieldValueChange.bind(this, field.id, currentApp)}
                />,
              );
            } else if (field.type === TEXT_AREA) {
              var isDisabled = false;
              if (field.is_read_only || !form.checkable) {
                isDisabled = true;
              }
              fieldUI.push(
                <TextField
                  required={field.required === 'true'}
                  //value={fields.find((o) => o.id === field.id).value}
                  key={"field_" + currentApp + field.id}
                  id={`${currentApp + field.id}`}
                  multiline
                  rows={field.rows}
                  fullWidth
                  label={field.label}
                  onChange={onFieldValueChange.bind(this, field.id, currentApp)}
                />,
              );
            } else if (field.type === HELPTEXT) {
              if (field.help_text.length > 0) {
                if (field.help_text.substring(0, 1) === '<') {
                  fieldUI.push(
                    <div
                      dangerouslySetInnerHTML={{ __html: field.help_text }}
                    />,
                  );
                } else {
                  fieldUI.push(
                    <Box
                      style={{ background: '#EEEEE0' }}
                      key={field.id}
                    >
                      <Typography variant="subtitle1" gutterBottom>
                        {field.help_text}
                      </Typography>
                    </Box>,
                  );
                }
              }
            }

            var fieldColspan = 12;
            if (field.colspan !== '') {
              fieldColspan = field.colspan;
            }
            fieldItems.push(
              <GridItem key={`${currentApp}_${field.id}`} xs={12} sm={12} md={parseInt(fieldColspan, 10)}>
                <Box mt={2} key={`${currentApp}_${field.id}`} >
                  {fieldUI}
                </Box>
              </GridItem>,
            );
          })
        }
      })
    }

    const ApplicationUI = (props) => {
      return (
        <GridItem xs={12} sm={12} md={6}>
          <Card>
            <CardBody>
              {/*<div key={"msg_" + currentApp} style={{ color: 'red', textAlign: 'center' }}></div>*/}
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    key={"name_" + currentApp}
                    id={"name_" + currentApp}
                    required
                    fullWidth
                    label={localStorage.getItem('application_label') !== '' ? localStorage.getItem('application_label') + " " + t('app_list_dialog_app_name1') : t('app_list_dialog_app_name2')}
                    //value={applicationList.find((o) => o.id === currentApp).app_title_value}
                    onChange={onApplicationNameMultipleChange.bind(this, currentApp)}
                  />
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    key={"desc_" + currentApp}
                    id={"desc_" + currentApp}
                    multiline
                    fullWidth
                    label={t('app_list_dialog_app_desc')}
                    //value={applicationList.find((o) => o.id === currentApp).app_desc_value}
                    onChange={onApplicationDescMultipleChange.bind(this, currentApp)}
                    rows={2}
                  />
                </GridItem>
              </GridContainer>
              <br />
              <Card>
                <CardBody>
                  <GridContainer>
                    {fieldItems}
                  </GridContainer>
                </CardBody>
              </Card>
            </CardBody>
          </Card>
          <br />
        </GridItem>
      )
    }

    applicationUI.push(
      <ApplicationUI
        app_create_with_detail_msg={props.app_create_with_detail_msg}
      />
    )
    return applicationUI
  }

  /* Detail End */

  const handleEditClick = (e) => {
    setID(e[0]);
    setApplicationName(e[1]);
    setApplicationDesc(e[2]);
    setApplicationStatus(e[3] === "1" ? true : false);
    setRoleAllowAppEdit(e[4]);
    setRoleAllowAppOnhold(e[5]);
    setDialogTitle('Edit');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('update');
    setDialogOpen(true);
    setHideStatusInput(false);
  };

  const handleNewWithDefaultTitleClick = (e) => {
    if (localStorage.getItem("default_app_title") !== "") {
      setClickDefaultAppTitle(true)
      props.createApplication({
        app_name: "",
        app_desc: "",
      });
      return;
    }
  };

  const handleNewWithDetailClick = (e) => {
    if (localStorage.getItem("app_detail_at_create") === "true") {
      props.getApplicationDetailFormWithoutApp();
      setDialogOpen(false);
      setDialogDetailOpen(true);
      setMessage('');
      setDialogDetailButton('Create & Submit');
      return
    }
  }
  const handleNewClick = (e) => {
    setID('');
    setApplicationName('');
    setApplicationDesc('');
    setApplicationStatus(false);
    setMessage('');
    setDialogButton(localStorage.getItem('application_label') !== '' ? t('app_list_dialog_app_button1') + " " + localStorage.getItem('application_label') : t('app_list_dialog_app_button2'));
    setDialogOpen(true);
    setDialogDetailOpen(false);
    setMessage('');
    setDialogTitle('Add New');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('add');
    setHideStatusInput(true);
  };

  const onApplicationNameChange = (e) => {
    setApplicationName(e.target.value);
  };

  const onApplicationDescChange = (e) => {
    setApplicationDesc(e.target.value);
  };
  const onApplicationStatusChange = (e) => {
    setApplicationStatus(e.target.checked);
  };

  const handleDialogClose = (e) => {
    setDialogOpen(false);
    setMessage('');
  };

  const handleDialogConfirm = (e) => {
    if (!props.requesting) {
      if (dialogType === 'add') {
        props.createApplication({
          app_name: applicationName,
          app_desc: applicationDesc,
        });
      } else if (dialogType === 'update') {
        props.updateApplication(id, {
          app_name: applicationName,
          app_desc: applicationDesc,
          status: applicationStatus ? "1" : "0",
        });
      }
    }
  };

  const columns = commonColName;
  const activeOptions = {
    filterType: 'dropdown',
    responsive: 'standard',
    serverSide: true,
    count: parseInt(props.active_application_count),
    filter: false,
    selectableRows: 'none',
    rowsPerPage: activePageSize,
    page: activePageNum - 1,
    rowsPerPageOptions: [5, 10, 20, 100],
    selectToolbarPlacement: 'none',
    onTableChange: (action, tableState) => {
      //console.log(action, tableState);
      switch (action) {
        case 'changeRowsPerPage':
          setActivePageSize(tableState.rowsPerPage)
          setActivePageNum(1)
          props.getActiveApplications(tableState.rowsPerPage, 1, activeOrderBy, activeOrderByDirec, activeSearchText)
          break;
        case 'changePage':
          setActivePageNum(tableState.page + 1)
          props.getActiveApplications(activePageSize, tableState.page + 1, activeOrderBy, activeOrderByDirec, activeSearchText)
          break;
        case 'sort':
          var sortString = 'id'
          setActiveOrderByDirec(tableState.sortOrder.direction)
          if (tableState.sortOrder.name === 'name_string') {
            setActiveOrderBy('namestring')
            sortString = 'namestring'
          } else if (tableState.sortOrder.name === 'start_date') {
            setActiveOrderBy('startdate')
            sortString = 'startdate'
          } else if (tableState.sortOrder.name === 'app_name') {
            setActiveOrderBy('appname')
            sortString = 'appname'
          }
          props.getActiveApplications(activePageSize, activePageNum, sortString, tableState.sortOrder.direction, activeSearchText)
          break;
        case 'search':
          setActiveSearchText(tableState.searchText)
          setActivePageNum(1)
          props.getActiveApplications(activePageSize, 1, activeOrderBy, activeOrderByDirec, tableState.searchText)
          break;
      }
    },
    textLabels: {
      body: {
        noMatch: t('mui_table_no_match'),
        toolTip: t('mui_table_tool_tip'),
        columnHeaderTooltip: column => t('mui_table_header_tool_tip') + " " + column.label
      },
      pagination: {
        next: t('mui_table_next'),
        previous: t('mui_table_previous'),
        rowsPerPage: t('mui_table_rows_per_page'),
        displayRows: t('mui_table_display_rows'),
      },
      toolbar: {
        search: t('mui_table_search'),
        downloadCsv: t('mui_table_download_csv'),
        print: t('mui_table_print'),
        viewColumns: t('mui_table_view_columns'),
        filterTable: t('mui_table_filter_table'),
      },
      filter: {
        all: t('mui_table_filter_all'),
        title: t('mui_table_filter_title'),
        reset: t('mui_table_filter_reset'),
      },
      viewColumns: {
        title: t('mui_table_column_title'),
        titleAria: t('mui_table_column_title_aria'),
      },
      selectedRows: {
        text: t('mui_table_selected_text'),
        delete: t('mui_table_selected_delete'),
        deleteAria: t('mui_table_selected_delete_aria'),
      },
    }
  };
  const archiveOptions = {
    filterType: 'dropdown',
    responsive: 'standard',
    serverSide: true,
    count: parseInt(props.archive_application_count),
    filter: false,
    selectableRows: 'none',
    rowsPerPage: archivePageSize,
    page: archivePageNum - 1,
    rowsPerPageOptions: [5, 10, 25, 50, 100],
    selectToolbarPlacement: 'none',
    onTableChange: (action, tableState) => {
      //console.log(action, tableState);
      switch (action) {
        case 'changeRowsPerPage':
          setArchivePageSize(tableState.rowsPerPage)
          setArchivePageNum(1)
          props.getArchiveApplications(tableState.rowsPerPage, 1, archiveOrderBy, archiveOrderByDirec, archiveSearchText)
          break;
        case 'changePage':
          setArchivePageNum(tableState.page + 1)
          props.getArchiveApplications(archivePageSize, tableState.page + 1, archiveOrderBy, archiveOrderByDirec, archiveSearchText)
          break;
        case 'sort':
          var sortString = 'id'
          setArchiveOrderByDirec(tableState.sortOrder.direction)
          if (tableState.sortOrder.name === 'name_string') {
            setArchiveOrderBy('namestring')
            sortString = 'namestring'
          } else if (tableState.sortOrder.name === 'start_date') {
            setArchiveOrderBy('startdate')
            sortString = 'startdate'
          } else if (tableState.sortOrder.name === 'app_name') {
            setArchiveOrderBy('appname')
            sortString = 'appname'
          }
          props.getArchiveApplications(archivePageSize, archivePageNum, sortString, tableState.sortOrder.direction, archiveSearchText)
          break;
        case 'search':
          setArchiveSearchText(tableState.searchText)
          setArchivePageNum(1)
          props.getArchiveApplications(archivePageSize, 1, archiveOrderBy, archiveOrderByDirec, tableState.searchText)
          break;
      }
    },
    textLabels: {
      body: {
        noMatch: t('mui_table_no_match'),
        toolTip: t('mui_table_tool_tip'),
        columnHeaderTooltip: column => t('mui_table_header_tool_tip') + " " + column.label
      },
      pagination: {
        next: t('mui_table_next'),
        previous: t('mui_table_previous'),
        rowsPerPage: t('mui_table_rows_per_page'),
        displayRows: t('mui_table_display_rows'),
      },
      toolbar: {
        search: t('mui_table_search'),
        downloadCsv: t('mui_table_download_csv'),
        print: t('mui_table_print'),
        viewColumns: t('mui_table_view_columns'),
        filterTable: t('mui_table_filter_table'),
      },
      filter: {
        all: t('mui_table_filter_all'),
        title: t('mui_table_filter_title'),
        reset: t('mui_table_filter_reset'),
      },
      viewColumns: {
        title: t('mui_table_column_title'),
        titleAria: t('mui_table_column_title_aria'),
      },
      selectedRows: {
        text: t('mui_table_selected_text'),
        delete: t('mui_table_selected_delete'),
        deleteAria: t('mui_table_selected_delete_aria'),
      },
    }
  };
  if (redirectToDetail) {
    return <Navigate to={"/detail/" + props.newApplicationID + "/" + localStorage.getItem("role_id")} />;
  }
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <div style={{ paddingTop: '10px' }}>
        <div style={{ textAlign: 'right', display: props.isAllowed ? 'block' : 'none' }}>
          {localStorage.getItem("default_app_title") !== "" ?
            <Button
              color="primary"
              round
              onClick={handleNewWithDefaultTitleClick.bind(this)}
              startIcon={<AddIcon />}
              disabled={props.requesting}
            >
              {t('app_list_create_new_button_with_default')}
              {' '}
              {localStorage.getItem('application_label') !== '' ? localStorage.getItem('application_label') : 'Application'}
            </Button>
            :
            <Button
              color="primary"
              round
              onClick={handleNewClick.bind(this)}
              startIcon={<AddIcon />}
              disabled={props.requesting}
            >
              {t('app_list_create_new_button')}
              {' '}
              {localStorage.getItem('application_label') !== '' ? localStorage.getItem('application_label') : 'Application'}
            </Button>
          }
          {localStorage.getItem("app_detail_at_create") === "true" ?
            <Button
              color="primary"
              round
              onClick={handleNewWithDetailClick.bind(this)}
              startIcon={<AddIcon />}
              disabled={props.requesting}
            >
              {t('app_list_create_new_button_with_detail')}
              {' '}
              {localStorage.getItem('application_label') !== '' ? localStorage.getItem('application_label') : 'Application'}
            </Button>
            : ""}
        </div>
        <br />
        {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
        <div style={{ color: 'red', textAlign: 'center' }}>{message}</div> <br />
        <Card>
          <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
            <b>{t('app_list_active_head_title')}</b>
          </CardHeader>
          <CardBody>
            <MUIDataTable
              data={data}
              columns={columns}
              options={activeOptions}
            />
          </CardBody>
        </Card>
        <br />
        {localStorage.getItem('user_type') === "EMP" ?
          <div>
            <Card>
              <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                <b>{t('app_list_archive_head_title')}</b>
              </CardHeader>
              <CardBody>
                <MUIDataTable
                  data={dataArchive}
                  columns={columns}
                  options={archiveOptions}
                />
              </CardBody>
            </Card>
            <br />
            <Card>
              <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                <b>{t('app_list_help_head_title')}</b>
              </CardHeader>
              <CardBody>
                <div
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{ __html: helpDescription }}
                />
              </CardBody>
            </Card>
          </div>
          : ""}

        <Dialog fullWidth open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
          <DialogContent>
            <Card>
              <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                <b>
                  {t('app_list_dialog_title')}
                  {localStorage.getItem('application_label') !== '' ? localStorage.getItem('application_label') : 'Application'}
                </b>
              </CardHeader>
              <CardBody>
                <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
                {roleAllowAppEdit || dialogType === 'add' ?
                  <div>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          required
                          fullWidth
                          label={localStorage.getItem('application_label') !== '' ? localStorage.getItem('application_label') + " " + t('app_list_dialog_app_name1') : t('app_list_dialog_app_name2')}
                          value={applicationName}
                          onChange={onApplicationNameChange.bind(this)}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          multiline
                          fullWidth
                          label={t('app_list_dialog_app_desc')}
                          value={applicationDesc}
                          onChange={onApplicationDescChange.bind(this)}
                          rows={5}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                  </div>
                  : ""}
                <div style={{ display: hideStatusInput ? 'none' : 'block' }}>
                  {roleAllowAppOnhold ?
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <InputLabel id="changeStatusLabel" shrink>{t('app_list_dialog_app_status')}</InputLabel>
                        <Grid item component="label" container alignItems="center" spacing={1}>
                          <Grid item>No</Grid>
                          <Grid item>
                            <Switch
                              labelid="changeStatusLabel"
                              checked={applicationStatus}
                              onChange={onApplicationStatusChange.bind(this)}
                              color="primary"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            /*
                            classes={{
                              switchBase: props.classes.switchBase,
                              checked: props.classes.switchChecked,
                            }}
                            */
                            />
                          </Grid>
                          <Grid item>Yes</Grid>
                        </Grid>
                      </GridItem>
                    </GridContainer>
                    : ""}
                </div>
              </CardBody>
              <CardFooter style={{ textAlign: 'left' }} />
            </Card>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose.bind(this)} color="primary" round>
              {t('app_list_dialog_app_button_cancel')}
            </Button>
            <Button onClick={handleDialogConfirm.bind(this)} color="primary" round>
              {props.requesting
                ? <CircularProgress size={20} color="inherit" />
                : <SaveIcon />}
              {dialogButton}
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog fullWidth maxWidth="lg" open={dialogDetailOpen} onClose={handleDialogMultiClose.bind(this)} aria-labelledby="form-dialog-title">
          <DialogContent>
            <Card>
              <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                <b>
                  {t('app_list_dialog_title')}
                  {localStorage.getItem('application_label') !== '' ? localStorage.getItem('application_label') : 'Application'}
                </b>
              </CardHeader>
              <CardBody>
                <div style={{ color: 'green', textAlign: 'center' }}>{props.app_create_with_detail_msg.message}</div>
                <div style={{ textAlign: 'right' }}>
                  <IconButton onClick={handleAddClick.bind(this)}>
                    <AddIcon />
                  </IconButton>
                </div>
                <GridContainer>
                  {applicationUIList}
                </GridContainer>
              </CardBody>
              <CardFooter style={{ textAlign: 'left' }} />
            </Card>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogMultiClose.bind(this)} color="primary" round>
              {t('app_list_dialog_app_button_cancel')}
            </Button>
            <Button onClick={handleDialogMultiConfirm.bind(this)} color="primary" round>
              {props.requesting
                ? <CircularProgress size={20} color="inherit" />
                : <SaveIcon />}
              {dialogDetailButton}
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    </div>
  );
};

export default withRoot(ApplicationListComp);
