import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as itemActions from 'actions/ItemActions';
import ItemSearchComp from './ItemSearchComp';

const ItemSearch = (props) => {
  const { classes } = props;
  const { item } = props;
  const { SearchItem, setSelectedItem, ResetSeachItemList } = props.itemActions;

  return (
    <ItemSearchComp
      SearchItem={SearchItem}
      setSelectedItem={setSelectedItem}
      ResetSeachItemList={ResetSeachItemList}
      search_item_list={item.search_item_list.list === undefined ? [] : item.search_item_list.list}
      search_item_count={item.search_item_list.count === undefined ? [] : item.search_item_list.count}
      search_table={props.search_table}
      search_level={props.search_level}
      field_id={props.field_id}
      item_type={props.item_type}
      classes={classes}
    />
  );
}

function mapStateToProps(state) {
  return {
    item: state.item,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    itemActions: bindActionCreators(itemActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ItemSearch);
