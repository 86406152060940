import { PATCH_APPLICATION_FAILURE } from 'constants/ApplicationsList';
import {
  GET_APPLICATION_STATUS_REQUEST,
  GET_APPLICATION_STATUS_SUCCESS,
  GET_APPLICATION_STATUS_FAILURE,
  GET_APPLICATION_LOG_REQUEST,
  GET_APPLICATION_LOG_SUCCESS,
  GET_APPLICATION_LOG_FAILURE,
  GET_ROLE_STATUS_REQUEST,
  GET_ROLE_STATUS_SUCCESS,
  GET_ROLE_STATUS_FAILURE,
  PATCH_APPLICATION_REMIND_REQUEST,
  PATCH_APPLICATION_REMIND_SUCCESS,
  PATCH_APPLICATION_REMIND_FAILURE,
  PATCH_APPLICATION_ESCALATE_REQUEST,
  PATCH_APPLICATION_ESCALATE_SUCCESS,
  PATCH_APPLICATION_ESCALATE_FAILURE,
  GET_APPLICATION_COMPLETED_TASK_LIST_REQUEST,
  GET_APPLICATION_COMPLETED_TASK_LIST_SUCCESS,
  GET_APPLICATION_COMPLETED_TASK_LIST_FAILURE,
} from '../constants/ApplicationStatus';

const initialState = {
  requesting: false,
  message: '',
  application_status_list: [],
  completed_task_list: [],
  application_log: [],
  role: '',
  refreshApplicationStatus: false,
};

export default function applicationStatus(state = initialState, action) {
  switch (action.type) {
    case GET_APPLICATION_STATUS_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshApplicationStatus: false,
      };

    case GET_APPLICATION_STATUS_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        requesting: action.payload.requesting,
        application_status_list: action.payload.data,
      };

    case GET_APPLICATION_STATUS_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case GET_APPLICATION_LOG_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshApplicationStatus: false,
      };

    case GET_APPLICATION_LOG_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        requesting: action.payload.requesting,
        application_log: action.payload.data,
      };

    case GET_APPLICATION_LOG_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case GET_ROLE_STATUS_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
      };

    case GET_ROLE_STATUS_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        requesting: action.payload.requesting,
        role: action.payload.data,
      };

    case GET_ROLE_STATUS_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case PATCH_APPLICATION_REMIND_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
      };

    case PATCH_APPLICATION_REMIND_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshApplicationStatus: true,
      };

    case PATCH_APPLICATION_REMIND_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

      case PATCH_APPLICATION_ESCALATE_REQUEST:
        return {
          ...state,
          requesting: action.payload.requesting,
        };
  
      case PATCH_APPLICATION_ESCALATE_SUCCESS:
        return {
          ...state,
          requesting: action.payload.requesting,
          message: action.payload.message,
          refreshApplicationStatus: true,
        };
  
      case PATCH_APPLICATION_ESCALATE_FAILURE:
        return {
          ...state,
          requesting: action.payload.requesting,
          message: action.payload.message,
        };



    case GET_APPLICATION_COMPLETED_TASK_LIST_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshApplicationStatus: false,
      };

    case GET_APPLICATION_COMPLETED_TASK_LIST_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        requesting: action.payload.requesting,
        completed_task_list: action.payload.data,
      };

    case GET_APPLICATION_COMPLETED_TASK_LIST_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };


    default:
      return state;
  }
}
