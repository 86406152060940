import React, { useState, useEffect } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';

import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';

const NetworkComp = (props) => {
  const [cardAnimaton, setCardAnimaton] = useState('cardHidden')
  const [message, setMessage] = useState('')
  const [networkName, setNetworkName] = useState('')

  useEffect(() => {
    setTimeout(() => {
      setCardAnimaton('')
    }, 2500);
  }, [])

  useEffect(() => {
    setMessage(props.message)
  }, [props])


  const onNetworkNameChange = (e) => {
    setNetworkName(e.target.value);
  }

  const getNetworkClick = (e) => {
    props.getNetworkByName(networkName);
  }
  const onKeyPressChange = (e) => {
    if (e.key === "Enter") {
      props.getNetworkByName(networkName);
    }
  };

  const { classes } = props;
  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={6} md={4}>
        <Card className={classes[cardAnimaton]}>

          <CardHeader color="primary" plain style={{ textAlign: 'center' }}>
            <b>Network </b>
          </CardHeader>

          <CardBody>
            {<div style={{ color: 'red' }}>{message}</div>}
            <CustomInput
              labelText="Network"
              id="network"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: onNetworkNameChange.bind(this),
                onKeyPress: onKeyPressChange.bind(this),
                endAdornment: (
                  <InputAdornment position="end">
                    <AccountBalanceIcon className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
              }}
            />
          </CardBody>

          <div style={{
            padding: "0",
            paddingTop: "10px",
            margin: "0 15px 10px",
            borderRadius: "0",
            justifyContent: "space-between",
            alignItems: "center",
            //display: "flex",
            backgroundColor: "transparent",
            border: "0"
          }}>
            <Button
              color="primary"
              round
              block
              onClick={getNetworkClick.bind(this)} >
              Network
            </Button>
          </div>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            {props.requesting && <CircularProgress size={24} style={{ position: 'absolute', top: '74%', left: '47%' }} />}
          </div>
        </Card>
      </GridItem>
    </GridContainer>
  );
}

export default NetworkComp;
