import {
  GET_PHASE_REQUEST,
  GET_PHASE_SUCCESS,
  GET_PHASE_FAILURE,
  POST_PHASE_REQUEST,
  POST_PHASE_SUCCESS,
  POST_PHASE_FAILURE,
  PATCH_PHASE_REQUEST,
  PATCH_PHASE_SUCCESS,
  PATCH_PHASE_FAILURE,
  DELETE_PHASE_REQUEST,
  DELETE_PHASE_SUCCESS,
  DELETE_PHASE_FAILURE,
} from '../constants/admin/Phase';
import {
  RESET_MESSAGE,
} from '../constants/admin/Default';

const initialState = {
  requesting: false,
  message: '',
  phase_list: '',
  refreshPhase: false,
};

export default function phase(state = initialState, action) {
  switch (action.type) {
    case RESET_MESSAGE:
      return {
        ...state,
        message: action.payload.value,
      };
    case GET_PHASE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshPhase: false,
        message: '',
      };

    case GET_PHASE_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        phase_list: action.payload.data,
      };

    case GET_PHASE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case POST_PHASE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshPhase: false,
      };

    case POST_PHASE_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshPhase: true,
      };

    case POST_PHASE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case PATCH_PHASE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshPhase: false,
      };

    case PATCH_PHASE_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshPhase: true,
      };

    case PATCH_PHASE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case DELETE_PHASE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshPhase: false,
      };

    case DELETE_PHASE_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshPhase: true,
      };

    case DELETE_PHASE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
