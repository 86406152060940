import API from '../Api/api.js';

import { commonBackendCall, getConfig } from '../Api/common.js';
import {
  GET_APPLICATION_FORM_REQUEST,
  GET_APPLICATION_FORM_SUCCESS,
  GET_APPLICATION_FORM_FAILURE,
  GET_APPLICATION_ACTION_REQUEST,
  GET_APPLICATION_ACTION_SUCCESS,
  GET_APPLICATION_ACTION_FAILURE,
  POST_APPLICATION_ACTION_REQUEST,
  POST_APPLICATION_ACTION_SUCCESS,
  POST_APPLICATION_ACTION_FAILURE,
  POST_APPLICATION_REQUEST,
  POST_APPLICATION_SUCCESS,
  POST_APPLICATION_FAILURE,
  POST_APPLICATION_COMMITTEE_REQUEST,
  POST_APPLICATION_COMMITTEE_SUCCESS,
  POST_APPLICATION_COMMITTEE_FAILURE,
  GET_APPLICATION_MESSAGES_REQUEST,
  GET_APPLICATION_MESSAGES_SUCCESS,
  GET_APPLICATION_MESSAGES_FAILURE,
  GET_APPLICATION_ROLES_REQUEST,
  GET_APPLICATION_ROLES_SUCCESS,
  GET_APPLICATION_ROLES_FAILURE,
  SET_FORM_SUCCESS_MSG,
  SET_FORM_ERROR_MSG,
  SET_FIELDS,
  SET_SHOW_FORM,
  POST_APP_ITEM_INBOUND_REQUEST,
  POST_APP_ITEM_INBOUND_SUCCESS,
  POST_APP_ITEM_INBOUND_FAILURE,
  PATCH_APP_ITEM_PERCENT_REQUEST,
  PATCH_APP_ITEM_PERCENT_SUCCESS,
  PATCH_APP_ITEM_PERCENT_FAILURE,
  POST_APP_ITEM_DETAIL_REQUEST,
  POST_APP_ITEM_DETAIL_SUCCESS,
  POST_APP_ITEM_DETAIL_FAILURE,
} from '../constants/ApplicationDetail';

export function setShowForm(type, form_id) {
  return (dispatch) => {
    dispatch({
      type: SET_SHOW_FORM,
      payload: {
        type,
        form_id,
      },
    });
  };
}

export function setFields(value) {
  return (dispatch) => {
    dispatch({
      type: SET_FIELDS,
      payload: {
        value,
      },
    });
  };
}

export function setFormSuccessMsg(value) {
  return (dispatch) => {
    dispatch({
      type: SET_FORM_SUCCESS_MSG,
      payload: {
        value,
      },
    });
  };
}

export function setFormErrorMsg(value) {
  return (dispatch) => {
    dispatch({
      type: SET_FORM_ERROR_MSG,
      payload: {
        value,
      },
    });
  };
}

export function getApplicationRoles(applicationID, roleID) {
  return commonBackendCall(
    GET_APPLICATION_ROLES_REQUEST,
    GET_APPLICATION_ROLES_SUCCESS,
    GET_APPLICATION_ROLES_FAILURE,
    API.get(`applications/roles/${applicationID}/${roleID}`, getConfig()),
  );
}
export function getApplicationDetailForm(applicationID, roleID) {
  return commonBackendCall(
    GET_APPLICATION_FORM_REQUEST,
    GET_APPLICATION_FORM_SUCCESS,
    GET_APPLICATION_FORM_FAILURE,
    API.get(`applications/details/${applicationID}/${roleID}`, getConfig()),
  );
}

export function getApplicationDetailCompletedTask(applicationID, roleID, taskID) {
  return commonBackendCall(
    GET_APPLICATION_FORM_REQUEST,
    GET_APPLICATION_FORM_SUCCESS,
    GET_APPLICATION_FORM_FAILURE,
    API.get(`applications/completed/${applicationID}/${roleID}/${taskID}`, getConfig()),
  );
}

/*
export function getApplicationDetailAction(applicationID, roleID) {
  return commonBackendCall(
    GET_APPLICATION_ACTION_REQUEST,
    GET_APPLICATION_ACTION_SUCCESS,
    GET_APPLICATION_ACTION_FAILURE,
    API.get(`application-detail-actions/${applicationID}/${roleID}`, getConfig()),
  );
}
*/

export function postApplicationDetail(applicationID, obj) {
  console.log("postApplicationDetail applicationID:", applicationID)
  console.log("postApplicationDetail obj:", obj)
  return commonBackendCall(
    POST_APPLICATION_REQUEST,
    POST_APPLICATION_SUCCESS,
    POST_APPLICATION_FAILURE,
    API.post(`applications/details/${applicationID}`, obj, getConfig()),
  );
}

export function postApplicationDetailCommittee(applicationID, obj) {
  return commonBackendCall(
    POST_APPLICATION_COMMITTEE_REQUEST,
    POST_APPLICATION_COMMITTEE_SUCCESS,
    POST_APPLICATION_COMMITTEE_FAILURE,
    API.post(`applications/details/committee/${applicationID}`, obj, getConfig()),
  );
}

export function postApplicationDetailAction(applicationID, taskID, actionID, obj) {
  return commonBackendCall(
    POST_APPLICATION_ACTION_REQUEST,
    POST_APPLICATION_ACTION_SUCCESS,
    POST_APPLICATION_ACTION_FAILURE,
    API.post(`applications/details/actions/${applicationID}/${taskID}/${actionID}`, obj, getConfig()),
  );
}

export function getApplicationMessages(applicationID, taskID) {
  return commonBackendCall(
    GET_APPLICATION_MESSAGES_REQUEST,
    GET_APPLICATION_MESSAGES_SUCCESS,
    GET_APPLICATION_MESSAGES_FAILURE,
    API.get(`applications/messages/${applicationID}/${taskID}`, getConfig()),
  );
}

export function postItem(obj) {
  return commonBackendCall(
    POST_APP_ITEM_INBOUND_REQUEST,
    POST_APP_ITEM_INBOUND_SUCCESS,
    POST_APP_ITEM_INBOUND_FAILURE,
    API.post(`applications/inbound-item-app`, obj, getConfig()),
  );
}

export function patchItemPercent(obj) {
  return commonBackendCall(
    PATCH_APP_ITEM_PERCENT_REQUEST,
    PATCH_APP_ITEM_PERCENT_SUCCESS,
    PATCH_APP_ITEM_PERCENT_FAILURE,
    API.patch(`applications/percent-item-app`, obj, getConfig()),
  );
}

export function postUpdateItemDetail(obj) {
  return commonBackendCall(
    POST_APP_ITEM_DETAIL_REQUEST,
    POST_APP_ITEM_DETAIL_SUCCESS,
    POST_APP_ITEM_DETAIL_FAILURE,
    API.post(`applications/inbound-item-app`, obj, getConfig()),
  );
}