import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Button from 'components/CustomButtons/Button.jsx';

import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import * as actionMsgActions from 'actions/admin/ActionMsgActions';
import * as actionActions from 'actions/admin/ActionActions';
import * as phasesActions from 'actions/admin/PhasesActions';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import TrendingFlat from '@mui/icons-material/TrendingFlat';
import ActionActionMsgComp from './ActionActionMsg/ActionActionMsgComp';

const ActionMsg = (props) => {
  const { classes } = props;
  const {
    actionMsg, action, phase, defaultStates,
  } = props;
  const {
    createActionMsg,
    updateActionMsg,
    deleteActionMsg,
    getActionActionMessages,
    getActionMessageVariables,
    getActionMessageLinkDataList,
    getActionMessageTaskForm,
    getActionMessageTaskAction,
    getActionMessageWFAttr,
    getActionMsgTasks,
    getIntgExtAPI,
  } = props.actionMsgActions;
  const { getActions } = props.actionActions;
  const { getPhases } = props.phasesActions;
  const { id } = useParams();

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/admin/action">
          <Button size="sm" round color="primary">
            {' '}
            <TrendingFlat />
            {' '}
            Action
          </Button>
        </Link>
        <Link to="#">
          <Button size="sm" round color="primary" block>
            {' '}
            {actionMsg.action_actionmessage_list.title}
          </Button>
        </Link>
      </Breadcrumbs>
      <br />
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <ActionActionMsgComp
        action_id={id}
        getActionMessageTaskForm={getActionMessageTaskForm}
        getActionMessageTaskAction={getActionMessageTaskAction}
        getIntgExtAPI={getIntgExtAPI}
        getActionActionMessages={getActionActionMessages}
        getActionMessageVariables={getActionMessageVariables}
        getActionMessageLinkDataList={getActionMessageLinkDataList}
        getActionMessageWFAttr={getActionMessageWFAttr}
        getActionMsgTasks={getActionMsgTasks}
        getActions={getActions}
        getPhases={getPhases}
        action_list={action.action_list.list === undefined ? [] : action.action_list.list}
        phase_list={phase.phase_list.list === undefined ? [] : phase.phase_list.list}
        actionMsg_list={actionMsg.action_actionmessage_list.action_messages === undefined ? [] : actionMsg.action_actionmessage_list.action_messages}
        action_actionmessage_list={actionMsg.action_actionmessage_list}
        actionMsgVar_list={actionMsg.actionMsgVar_list === undefined ? [] : actionMsg.actionMsgVar_list}
        actionMsg_link_data_list={actionMsg.actionMsg_link_data_list === undefined ? [] : actionMsg.actionMsg_link_data_list}
        actionMsgWFAttr_list={actionMsg.actionMsgWFAttr_list === undefined ? [] : actionMsg.actionMsgWFAttr_list}
        action_msg_task_list={actionMsg.action_msg_task_list === undefined ? [] : actionMsg.action_msg_task_list}
        intg_ext_api_list={actionMsg.intg_ext_api_list === undefined ? [] : actionMsg.intg_ext_api_list}
        actionMsg_task_form={actionMsg.actionMsg_task_form === undefined ? [] : actionMsg.actionMsg_task_form}
        actionMsg_task_action={actionMsg.actionMsg_task_action === undefined ? [] : actionMsg.actionMsg_task_action}
        createActionMsg={createActionMsg}
        updateActionMsg={updateActionMsg}
        deleteActionMsg={deleteActionMsg}
        refreshActionMsg={actionMsg.refreshActionMsg}
        requesting={actionMsg.requesting}
        message={actionMsg.message}
        error_core={actionMsg.error_core}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    action: state.adAction,
    phase: state.adPhase,
    actionMsg: state.adActionMsg,
    defaultStates: state.adDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actionMsgActions: bindActionCreators(actionMsgActions, dispatch),
    actionActions: bindActionCreators(actionActions, dispatch),
    phasesActions: bindActionCreators(phasesActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ActionMsg);
