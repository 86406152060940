import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as lookupActions from 'actions/LookupActions';
import * as applicationDetailActions from 'actions/ApplicationDetailActions';
import FormSaveComp from './FormSave/FormSaveComp';

const FormSave = (props) => {
  const { classes } = props;
  const { lookup, item } = props;
  const {
    postApplicationDetail,
    postApplicationDetailCommittee,
    setShowForm,
  } = props.applicationDetailActions;
  return (
    <FormSaveComp
      submitableFormList={props.submitableFormList}
      setShowForm={setShowForm}
      postApplicationDetail={postApplicationDetail}
      postApplicationDetailCommittee={postApplicationDetailCommittee}
      fields={props.fields}
      role_user={lookup.role_user === undefined ? [] : lookup.role_user}
      form_id={props.form_id}
      task_id={props.task_id}
      selected_item={item.selected_item}
      selected_item_id={item.selected_item_id}
      application_id={props.application_id}
      share_file={lookup.share_file}
      weekSelect={lookup.weekSelect}
      requesting={props.requesting}
      classes={classes}
    />
  );
}

function mapStateToProps(state) {
  return {
    lookup: state.lookup,
    item: state.item,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    lookupActions: bindActionCreators(lookupActions, dispatch),
    applicationDetailActions: bindActionCreators(applicationDetailActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormSave);
