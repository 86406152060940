import API from 'Api/api.js';
import { commonBackendCall, getAdminConfig } from 'Api/common.js';
import {
  GET_PHASE_REQUEST,
  GET_PHASE_SUCCESS,
  GET_PHASE_FAILURE,
  POST_PHASE_REQUEST,
  POST_PHASE_SUCCESS,
  POST_PHASE_FAILURE,
  PATCH_PHASE_REQUEST,
  PATCH_PHASE_SUCCESS,
  PATCH_PHASE_FAILURE,
  DELETE_PHASE_REQUEST,
  DELETE_PHASE_SUCCESS,
  DELETE_PHASE_FAILURE,
} from 'constants/admin/Phase';

export function getPhases() {
  return commonBackendCall(
    GET_PHASE_REQUEST,
    GET_PHASE_SUCCESS,
    GET_PHASE_FAILURE,
    API.get('admin/phases', getAdminConfig()),
  );
}
export function createPhase(obj) {
  return commonBackendCall(
    POST_PHASE_REQUEST,
    POST_PHASE_SUCCESS,
    POST_PHASE_FAILURE,
    API.post('admin/phases', obj, getAdminConfig()),
  );
}
export function updatePhase(phaseId, obj) {
  return commonBackendCall(
    PATCH_PHASE_REQUEST,
    PATCH_PHASE_SUCCESS,
    PATCH_PHASE_FAILURE,
    API.patch(`admin/phases/${phaseId}`, obj, getAdminConfig()),
  );
}
export function deletePhase(phaseId) {
  return commonBackendCall(
    DELETE_PHASE_REQUEST,
    DELETE_PHASE_SUCCESS,
    DELETE_PHASE_FAILURE,
    API.delete(`admin/phases/${phaseId}`, getAdminConfig()),
  );
}
