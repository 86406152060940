import { POST_ACTION_FAILURE } from 'constants/admin/Action';
import {
  GET_WORKFLOW_SETTING_REQUEST,
  GET_WORKFLOW_SETTING_SUCCESS,
  GET_WORKFLOW_SETTING_FAILURE,
  PATCH_WORKFLOW_SETTING_REQUEST,
  PATCH_WORKFLOW_SETTING_SUCCESS,
  PATCH_WORKFLOW_SETTING_FAILURE,
  GET_WORKFLOW_USER_REQUEST,
  GET_WORKFLOW_USER_SUCCESS,
  GET_WORKFLOW_USER_FAILURE,
  POST_WORKFLOW_USER_REQUEST,
  POST_WORKFLOW_USER_SUCCESS,
  POST_WORKFLOW_USER_FAILURE,
  DELETE_WORKFLOW_USER_REQUEST,
  DELETE_WORKFLOW_USER_SUCCESS,
  DELETE_WORKFLOW_USER_FAILURE,
  SET_SELECTED_USER,
  POST_WORKFLOWUSER_FILE_UPLOAD_REQUEST,
  POST_WORKFLOWUSER_FILE_UPLOAD_SUCCESS,
  POST_WORKFLOWUSER_FILE_UPLOAD_FAILURE,
} from '../constants/admin/WorkflowSetting';

const initialState = {
  requesting: false,
  message: '',
  message_wu: '',
  workflow_setting_list: '',
  workflow_user_list: '',
  workflow_user_count: '',
  refreshWorkflowSetting: false,
  refreshWorkflowUser: false,
};

export default function workflowSetting(state = initialState, action) {
  switch (action.type) {

    case SET_SELECTED_USER:
      return {
        ...state,
        message: '',
        message_wu: '',
      };

    case GET_WORKFLOW_SETTING_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshWorkflowSetting: false,
        message: '',
        message_wu: '',
      };

    case GET_WORKFLOW_SETTING_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        message_wu: '',
        workflow_setting_list: action.payload.data,
      };

    case GET_WORKFLOW_SETTING_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        message_wu: '',
      };
    case PATCH_WORKFLOW_SETTING_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        message_wu: '',
        refreshWorkflowSetting: false,
      };

    case PATCH_WORKFLOW_SETTING_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        message_wu: '',
        refreshWorkflowSetting: true,
      };

    case PATCH_WORKFLOW_SETTING_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        message_wu: '',
      };

    case GET_WORKFLOW_USER_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshWorkflowUser: false,
        message: '',
        message_wu: '',
      };

    case GET_WORKFLOW_USER_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        message_wu: '',
        workflow_user_list: action.payload.data,
      };

    case GET_WORKFLOW_USER_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message_wu: action.payload.message,
        message: ''
      };


    case POST_WORKFLOW_USER_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message_wu: '',
        message: '',
        refreshWorkflowUser: false,
      };

    case POST_WORKFLOW_USER_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message_wu: action.payload.message,
        refreshWorkflowUser: true,
        message: '',
      };

    case POST_WORKFLOW_USER_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message_wu: action.payload.message,
        message: '',
      };



    case DELETE_WORKFLOW_USER_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message_wu: '',
        message: '',
        refreshWorkflowUser: false,
      };

    case DELETE_WORKFLOW_USER_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message_wu: action.payload.message,
        message: '',
        refreshWorkflowUser: true,
      };

    case DELETE_WORKFLOW_USER_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message_wu: action.payload.message,
        message: '',
      };


    case POST_WORKFLOWUSER_FILE_UPLOAD_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message_wu: '',
        message: '',
        refreshWorkflowUser: false,
      };

    case POST_WORKFLOWUSER_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message_wu: action.payload.message,
        message: '',
        refreshWorkflowUser: true,
      };

    case POST_WORKFLOWUSER_FILE_UPLOAD_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message_wu: action.payload.message,
        message: '',
      };



    default:
      return state;
  }
}
