import React, { useState, useEffect } from 'react';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx';
import FormLabel from '@material-ui/core/FormLabel';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import moment from 'moment';
import Checkbox from '@material-ui/core/Checkbox';

import {
  INTEGER_NUMBER,
  DECIMAL_NUMBER,
  STRING,
  DATE_STRING,
  NONE,
} from 'constants/admin/FieldDataTypes';
import { CHECK_BOX } from 'constants/admin/FieldTypes';

const OptionComp = (props) => {
  const [options, setOptionsLocal] = useState([]);
  const [optionType, setOptionType] = useState('admin_defined');
  const [newOptionID, setNewOptionID] = useState('');
  const [intgFieldID, setIntgFieldID] = useState('');
  const [newOptionValue, setNewOptionValue] = useState('');
  const [newOptionDefault, setNewOptionDefault] = useState(false);
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [showAdminDefined, setShowAdminDefined] = useState(true);
  const [displayDirection, setDisplayDirection] = useState("vertical");
  const [showBackendDatasource, setShowBackendDatasource] = useState(false);
  const [message, setMessage] = useState('');

  const { setOptions, setOptionsIntgFieldID } = props;
  useEffect(() => {
    if (props.options !== '') {
      var optionObj = JSON.parse(props.options);
      setOptionsLocal(optionObj.options === undefined ? [] : optionObj.options);
      setOptionType(optionObj.option_type);
      setIntgFieldID(props.option_intg_field_id);
      setShowAdminDefined(optionObj.option_type === 'admin_defined');
      setShowBackendDatasource(optionObj.option_type === 'backend_datasource');
      setOptions(optionObj);
      setDisplayDirection(optionObj.display_direction);
      setOptionsIntgFieldID(props.option_intg_field_id)
    }
    setFieldDisabled(props.disabled);
  }, [setOptions, setOptionsIntgFieldID]);

  const handleDeleteClick = (index, e) => {
    const tempOptions = options.slice();
    tempOptions.splice(index, 1);
    setOptionsLocal(tempOptions);
    props.setOptions({ display_direction: displayDirection, option_type: 'admin_defined', options: tempOptions });

    var tempOptionsDefaultValue = []
    props.optionsDefaultValue.forEach((defaultOption) => {
      if (tempOptions.findIndex((obj) => obj.id === defaultOption) > -1) {
        tempOptionsDefaultValue = [...tempOptionsDefaultValue, defaultOption]
      }
    })
    props.setOptionsDefaultValue(tempOptionsDefaultValue)
  };
  function isIntegerNumeric(value) {
    return /^\d+$/.test(value);
  }
  function isDecimalNumeric(value) {
    return /^\d+\.\d+$/.test(value);
  }
  const handleAddClick = (e) => {
    if (newOptionID !== '' && newOptionValue !== '') {
      if (props.field_data_type === INTEGER_NUMBER) {
        if (!isIntegerNumeric(newOptionID)) {
          setMessage('Invalid Option ID. Must be an Integer number');
          return;
        }
      } else if (props.field_data_type === DECIMAL_NUMBER) {
        if (!isDecimalNumeric(newOptionID)) {
          setMessage('Invalid Option ID. Must be Decimal number');
          return;
        }
      } else if (props.field_data_type == DATE_STRING) {
        if (!moment(newOptionID, 'YYYY-MM-DD', true).isValid()) {
          setMessage("Invalid Option ID. Must be a valid date with the format: 'YYYY-MM-DD'");
          return;
        }
      }
      if (options.findIndex((obj) => obj.id === newOptionID) !== -1) {
        setMessage('Option ID must be a unique value');
        return;
      }

      setMessage('');
      var jsonOption = { id: newOptionID, value: newOptionValue };
      setOptionsLocal([...options, jsonOption]);
      setNewOptionID('');
      setNewOptionValue('');
      props.setOptionsIntgFieldID('')
      props.setOptions({ display_direction: displayDirection, option_type: showAdminDefined ? 'admin_defined' : 'backend_datasource', options: [...options, jsonOption] });
      if (newOptionDefault) {
        if (props.type === CHECK_BOX) {
          props.setOptionsDefaultValue([...props.optionsDefaultValue, newOptionID.toString()])
        } else {
          props.setOptionsDefaultValue([newOptionID.toString()])
        }
      }
      setNewOptionDefault(false)
    } else {
      setMessage('Invalid Option');
    }
  };

  const onNewOptionValueChange = (e) => {
    setNewOptionValue(e.target.value);
  };
  const onDisplayDirectionChange = (e) => {
    setDisplayDirection(e.target.value);
    props.setOptions({ display_direction: e.target.value, option_type: 'admin_defined', options: options });
  };
  const onNewOptionIdChange = (e) => {
    setNewOptionID(e.target.value);
  };
  const onNewOptionDefaultChange = (e) => {
    setNewOptionDefault(e.target.checked);
  };
  const onIntgIDChange = (e) => {
    if (e.target.value !== '') {
      setIntgFieldID(e.target.value);
      props.setOptionsIntgFieldID(e.target.value)
      props.setOptions({ display_direction: displayDirection, option_type: 'backend_datasource', options: [] });
    }
  };

  const onOptionTypeChange = (e) => {
    setOptionType(e.target.value);
    setShowAdminDefined(e.target.value === 'admin_defined');
    setShowBackendDatasource(e.target.value === 'backend_datasource');
    if (e.target.value === 'admin_defined') {
      props.setOptions({ display_direction: displayDirection, option_type: e.target.value, options });
      props.setOptionsIntgFieldID('')
    } else if (e.target.value === 'backend_datasource') {
      props.setOptionsDefaultValue([])
      props.setOptions({ display_direction: displayDirection, option_type: e.target.value, options: [] });
    }
  };
  const { classes } = props;
  const optionItems = [];
  if (options !== undefined && options !== null && options.length > 0) {
    options.forEach((option, index) => {
      optionItems.push(
        <GridContainer key={index}>
          <GridItem xs={12} sm={12} md={1}>
            <Checkbox
              disabled
              size="small"
              color="primary"
              checked={props.optionsDefaultValue.findIndex((obj) => obj === option.id) > -1}
              inputProps={{ 'title': 'Default Value' }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <TextField
              required={false}
              fullWidth
              value={options[options.findIndex((obj) => obj.id === option.id)].id}
              InputProps={{ readOnly: true }}
              disabled={fieldDisabled}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <TextField
              required={false}
              fullWidth
              value={options[options.findIndex((obj) => obj.id === option.id)].value}
              InputProps={{ readOnly: true }}
              disabled={fieldDisabled}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            {fieldDisabled ? '' : (
              <IconButton onClick={handleDeleteClick.bind(this, index)}>
                <DeleteIcon />
              </IconButton>
            )}
          </GridItem>
        </GridContainer>,
      );
    });
  }

  return (
    <div>
      <br />
      <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
      <br />
      <GridContainer>
        <GridItem xs={12} sm={12} md={6}>
          <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled}>
            <FormLabel id="optionType">Select Option Type</FormLabel>
            <RadioGroup
              aria-label="radio"
              name="radio"
              value={optionType}
              onChange={onOptionTypeChange.bind(this)}
            >
              <FormControlLabel
                key="admin_defined"
                control={<Radio id="admin_defined" color="primary" />}
                label="Admin Defined"
                value="admin_defined"
              />
              <FormControlLabel
                key="backend_datasource"
                control={<Radio id="backend_datasource" color="primary" />}
                label="Backend Datasource"
                value="backend_datasource"
              />
            </RadioGroup>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={6}>
          <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled}>
            <InputLabel id="displayDircectionLabel">Display Direction</InputLabel>
            <Select
              labelId="displayDirectionLabel"
              id="displayDirectionSelect"
              value={displayDirection}
              onChange={onDisplayDirectionChange.bind(this)}
            >
              <MenuItem key={"vertical"} value={"vertical"}>Vertical</MenuItem>
              <MenuItem key={"horizontal"} value={"horizontal"}>Horizontal</MenuItem>
            </Select>
          </FormControl>
        </GridItem>
      </GridContainer>
      <br />
      <div style={{ display: showAdminDefined ? 'block' : 'none' }}>
        {optionItems}
        <GridContainer key="new option">
          <GridItem xs={12} sm={12} md={1}>
            <Checkbox
              onChange={onNewOptionDefaultChange}
              size="small"
              color="primary"
              checked={newOptionDefault}
              value={newOptionDefault}
              inputProps={{ 'title': 'Default Value' }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={3}>
            <TextField
              required={false}
              fullWidth
              value={newOptionID}
              placeholder="Add option ID"
              onChange={onNewOptionIdChange.bind(this)}
              disabled={fieldDisabled}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            <TextField
              required={false}
              fullWidth
              value={newOptionValue}
              placeholder="Add option Value"
              onChange={onNewOptionValueChange.bind(this)}
              disabled={fieldDisabled}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            {fieldDisabled ? '' : (
              <IconButton onClick={handleAddClick.bind(this)}>
                <AddIcon />
              </IconButton>
            )}
          </GridItem>
        </GridContainer>
      </div>
      <div style={{ display: showBackendDatasource ? 'block' : 'none' }}>
        <GridContainer key="custom_option">
          <GridItem xs={12} sm={12} md={12}>
            <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled}>
              <InputLabel id="customIntgLabel">Select Custom Field</InputLabel>
              <Select
                labelId="customIntgLabel"
                id="custIntgSelect"
                value={intgFieldID}
                onChange={onIntgIDChange.bind(this)}
              >
                {props.field_cust_intg_list.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {props.field_cust_intg_list.find((p) => p.id === item.id).field_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
      </div>
    </div>
  );
};
export default withStyles(extendedFormsStyle)(OptionComp);
