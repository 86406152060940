import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardBody from 'components/Card/CardBody';
import MUIDataTable from 'mui-datatables';
import { TableFooter, TableCell, TableRow } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CardHeader from 'components/Card/CardHeader';
import CardFooter from 'components/Card/CardFooter.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.jsx';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import TextField from '@material-ui/core/TextField';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

import { SaveFormToAPI } from 'Api/common.js';

const ItemOptionDetail = (props) => {
	const [dialogItemDetailEditOpen, setDialogItemDetailEditOpen] = useState(false);
	const [message, setMessage] = useState("");
	const [options, setOptions] = useState([]);
	const [itemID, setItemID] = useState("");
	const [intgfieldID, setIntgfieldID] = useState("");
	const [itemSKU, setItemSKU] = useState("");
	const [itemName, setItemName] = useState("");
	const [radioOption, setRadioOption] = useState(0);
	const [inputs, setInputs] = useState({});

	useEffect(() => {
		if (props.field_value !== "") {
			//console.log("option ", props.field_value)
			//console.log("option len", props.field_value.length)
			if (props.field_value.length > 1) {
				setRadioOption(parseInt(props.field_value[0]))
				var itemDetailArr = JSON.parse(props.field_value[1]);
				setItemID(itemDetailArr.item_id)
				setIntgfieldID(itemDetailArr.intgfield_id)
				setItemSKU(itemDetailArr.item_sku)
				setItemName(itemDetailArr.item_name)
				setOptions(itemDetailArr.options)

				itemDetailArr.options.forEach((option, j) => {
					var keys = Object.keys(option);
					for (let i = 0; i < keys.length; i++) {
						setInputs(values => ({ ...values, [j.toString() + i.toString()]: option[keys[i]] }))
					}
				})
			}
		}
	}, [props]);

	const handleSubmit = (e) => {
		e.preventDefault();
		var optionKeys = Object.keys(options[0]);
		var objArray = []
		options.forEach((option, j) => {
			const obj = {};
			optionKeys.forEach((key, i) => {
				obj[key] = inputs[j.toString() + i.toString()]
			})
			objArray.push(obj)
		})
		var intgFieldObjAry = []
		const intgFieldObj = { "intgfield_id": parseInt(intgfieldID, 10), "status": "1", "value": JSON.stringify(objArray) }
		intgFieldObjAry.push(intgFieldObj)
		const obj = { "item_sku_character": "", "name": itemName, "parent_item_id": itemID.toString(), "intgfield": intgFieldObjAry }
		const jsonObj = JSON.stringify(obj)
		//console.log(jsonObj)
		props.postUpdateItemDetail(jsonObj)
	};
	const handleChange = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		setInputs(values => ({ ...values, [name]: value }))
	};

	var optionsUI = [];
	var optionsEditUI = [];


	if (options.length > 0) {
		options.forEach((option, j) => {
			var optionItemsUI = [];
			var keys = Object.keys(option);
			for (let i = 0; i < keys.length; i = i + 2) {
				optionItemsUI.push(
					<div>
						<GridContainer>
							<GridItem xs={12} sm={12} md={12}>
								<GridContainer>
									<GridItem xs={12} sm={12} md={6}>
										<TextField
											required={false}
											disabled={true}
											error={false}
											value={option[keys[i]]}
											key={keys[i]}
											id={keys[i]}
											fullWidth
											label={keys[i]}
										/>
									</GridItem>
									{keys[i + 1] !== undefined ?
										<GridItem xs={12} sm={12} md={6}>
											<TextField
												required={false}
												disabled={true}
												error={false}
												value={option[keys[i + 1]]}
												key={keys[i + 1]}
												id={keys[i + 1]}
												fullWidth
												label={keys[i + 1]}
											/>
										</GridItem>
										: ""}
								</GridContainer>
							</GridItem>
						</GridContainer>
						<br />
					</div>
				);
			}
			optionsUI.push(
				<div>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12} sm={12} md={2}>
									{<FormControlLabel value={j} control={<Radio />} />}
								</GridItem>
								<GridItem xs={12} sm={12} md={10}>
									{optionItemsUI}
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
					<br />
				</div>
			);
		})
		options.forEach((option, j) => {
			var optionItemsEditUI = [];
			var keys = Object.keys(option);
			for (let i = 0; i < keys.length; i = i + 2) {
				optionItemsEditUI.push(
					<div>
						<GridContainer>
							<GridItem xs={12} sm={12} md={12}>
								<GridContainer>
									<GridItem xs={12} sm={12} md={6}>
										<TextField
											required={false}
											disabled={false}
											error={false}
											value={inputs[j.toString() + i.toString()] || ""}
											key={keys[i]}
											id={keys[i]}
											fullWidth
											label={keys[i]}
											name={j.toString() + i.toString()}
											onChange={handleChange}
										/>
									</GridItem>
									{keys[i + 1] !== undefined ?
										<GridItem xs={12} sm={12} md={6}>
											<TextField
												required={false}
												disabled={false}
												error={false}
												value={inputs[j.toString() + (i + 1).toString()] || ""}
												key={keys[i + 1]}
												id={keys[i + 1]}
												fullWidth
												label={keys[i + 1]}
												name={j.toString() + (i + 1).toString()}
												onChange={handleChange}
											/>
										</GridItem>
										: ""}
								</GridContainer>
							</GridItem>
						</GridContainer>
						<br />
					</div>
				);
			}
			optionsEditUI.push(
				<div>
					<Card>
						<CardBody>
							<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
									{optionItemsEditUI}
								</GridItem>
							</GridContainer>
						</CardBody>
					</Card>
					<br />
				</div>
			);
		})
	}

	const onRadioValueChange = (e) => {
		if (props.is_read_only || !props.form_checkable || props.task_completed) {
			return
		}
		setRadioOption(e.target.value)
		if (props.field_value.length > 1) {
			const tempStrItemDetail = props.field_value[1]
			var tempItemValue = []
			tempItemValue.push(e.target.value)
			tempItemValue.push(tempStrItemDetail)
			var tempFields = props.fields;
			var foundIndex = tempFields.findIndex((x) => x.id === props.field_id);
			tempFields[foundIndex].valueArray = tempItemValue
			tempFields[foundIndex].error = false;
			SaveFormToAPI(props.form_id, props.requesting, tempFields, [], [], "", "", props.postApplicationDetail, props.application_id, props.task_id)
		}
	};
	const handleChangeDetailClick = (e) => {
		setMessage("")
		setDialogItemDetailEditOpen(true)
	};
	const handleDialogClose = (e) => {
		setMessage("")
		setDialogItemDetailEditOpen(false)
	};
	const handleDialogConfirm = (e) => {
		setMessage("")
		setDialogItemDetailEditOpen(false)
	};

	const { classes } = props;
	return (
		<div >
			<Card>
				<CardBody>
					{props.level === 1 ?
						<GridContainer>
							<GridItem xs={12} sm={12} md={11}>
							</GridItem>
							<GridItem xs={12} sm={12} md={1}>
								<IconButton
									onClick={handleChangeDetailClick.bind(this)}
									title="Change Detail"
									disabled={false}
								>
									<EditIcon />
								</IconButton>
							</GridItem>
						</GridContainer>
						: ""}
					{options.length > 0 ?
						<GridContainer>
							<GridItem xs={12} sm={12} md={4}>
								<TextField
									required={false}
									error={false}
									disabled={true}
									value={itemSKU}
									key={'item_sku'}
									id={'item_sku'}
									fullWidth
									label={props.label + " ID"}
								/>
							</GridItem>
							<GridItem xs={12} sm={12} md={8}>
								<TextField
									required={false}
									error={false}
									disabled={true}
									value={itemName}
									key={'item_name'}
									id={'item_name'}
									fullWidth
									label={props.label + " Name"}
								/>
							</GridItem>
						</GridContainer>
						: ""}
					<br />
					<RadioGroup
						aria-labelledby="demo-radio-buttons-group-label"
						//defaultValue="female"
						name="radio-buttons-group"
						value={radioOption}
						onChange={onRadioValueChange.bind(this)}
					>
						{optionsUI}
					</RadioGroup>
					<Dialog fullWidth maxWidth="lg" open={dialogItemDetailEditOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
						<form onSubmit={handleSubmit.bind(this)}>
							<DialogContent>
								<Card>
									<CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
										<b>{"Edit"}</b>
									</CardHeader>
									<CardBody>
										<div style={{ color: 'red', textAlign: 'center' }}> {' '} {message} {' '} </div>
										{optionsEditUI}
									</CardBody>
									<CardFooter style={{ textAlign: 'left' }} />
								</Card>
							</DialogContent>
							<DialogActions>
								<Button onClick={handleDialogClose.bind(this)} color="primary" round>
									Cancel
								</Button>
								<Button
									onClick={handleDialogConfirm.bind(this)}
									color="primary"
									round
									type="submit"
									disabled={false}
								>
									{"Confirm Changes"}
								</Button>
							</DialogActions>
						</form>
					</Dialog>
				</CardBody>
			</Card>
		</div>
	);
};
export default ItemOptionDetail;
