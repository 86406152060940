import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import * as actionActions from 'actions/admin/ActionActions';
import * as taskActions from 'actions/admin/TaskActions';
import * as defaultActions from 'actions/admin/DefaultActions';
import * as rolesActions from 'actions/admin/RolesActions';
import * as phasesActions from 'actions/admin/PhasesActions';
import * as actionTypeActions from 'actions/admin/ActionTypeActions';
import * as fieldActions from 'actions/admin/FieldActions';
import ActionComp from './Action/ActionComp';

const Action = (props) => {
  const { classes } = props;
  const {
    actionType, action, task, phase, field, defaultStates,
  } = props;
  const {
    getShareActions,
    getActions,
    createAction,
    updateAction,
    deleteAction,
    setActionRule,
  } = props.actionActions;
  const { getTasks } = props.taskActions;
  const { resetMessage } = props.defaultActions;
  const { getRoles } = props.rolesActions;
  const { getPhases } = props.phasesActions;
  const { getDeptIntgFields, getUserIntgFields, getNumFields } = props.fieldActions;
  const { getActionTypes } = props.actionTypeActions;

  return (

    <div>
      {defaultStates.unDefinedError
        ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <ActionComp
        setActionRule={setActionRule}
        resetMessage={resetMessage}
        getActions={getActions}
        getShareActions={getShareActions}
        getActionTypes={getActionTypes}
        getDeptIntgFields={getDeptIntgFields}
        getUserIntgFields={getUserIntgFields}
        getNumFields={getNumFields}
        getTasks={getTasks}
        getRoles={getRoles}
        getPhases={getPhases}
        task_list={task.task_list.list === undefined ? [] : task.task_list.list}
        action_list={action.action_list.list === undefined ? [] : action.action_list.list}
        share_action_list={action.share_action_list === undefined ? [] : action.share_action_list}
        phase_list={phase.phase_list.list === undefined ? [] : phase.phase_list.list}
        actionType_list={actionType.actionType_list.list === undefined ? [] : actionType.actionType_list.list}
        field_list={field.field_list === undefined ? [] : field.field_list}
        field_user_intg_list={field.field_user_intg_list === undefined ? [] : field.field_user_intg_list}
        field_dept_intg_list={field.field_dept_intg_list === undefined ? [] : field.field_dept_intg_list}
        action_rules={action.action_rules === undefined ? [] : action.action_rules}
        createAction={createAction}
        updateAction={updateAction}
        deleteAction={deleteAction}
        refreshAction={action.refreshAction}
        requesting={action.requesting}
        message={action.message}
        error_core={action.error_core}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    action: state.adAction,
    actionType: state.adActionType,
    task: state.adTask,
    phase: state.adPhase,
    field: state.adField,
    defaultStates: state.adDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actionActions: bindActionCreators(actionActions, dispatch),
    taskActions: bindActionCreators(taskActions, dispatch),
    rolesActions: bindActionCreators(rolesActions, dispatch),
    phasesActions: bindActionCreators(phasesActions, dispatch),
    fieldActions: bindActionCreators(fieldActions, dispatch),
    actionTypeActions: bindActionCreators(actionTypeActions, dispatch),
    defaultActions: bindActionCreators(defaultActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Action);
