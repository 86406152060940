import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import * as applicationStatusActions from 'actions/ApplicationStatusActions';
import * as applicationActions from 'actions/ApplicationActions';
import * as defaultActions from 'actions/DefaultActions';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import ApplicationStatusComp from './ApplicationStatus/ApplicationStatusComp';

const ApplicationStatus = (props) => {
  const { application_id } = useParams();
  const { role_id } = useParams();
  const { classes } = props;
  const { applicationStatus, application, defaultStates } = props;
  const {
    getApplicationLog,
    getApplicationStatus,
    getRole,
    setCurrentRoleTitle,
    patchApplicationRemind,
    patchApplicationEscalate,
    getApplicationCompletedTaskList,
  } = props.applicationStatusActions;

  const { getApplication } = props.applicationActions;

  return (
    <div>
      {defaultStates.unDefinedError
        ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <ApplicationStatusComp
        application_id={application_id}
        role_id={role_id}
        setCurrentRoleTitle={setCurrentRoleTitle}
        getApplication={getApplication}
        patchApplicationRemind={patchApplicationRemind}
        patchApplicationEscalate={patchApplicationEscalate}
        getRole={getRole}
        getApplicationStatus={getApplicationStatus}
        getApplicationCompletedTaskList={getApplicationCompletedTaskList}
        getApplicationLog={getApplicationLog}
        application_status_list={applicationStatus.application_status_list}
        completed_task_list={applicationStatus.completed_task_list}
        application_log={applicationStatus.application_log}
        role={applicationStatus.role}
        application={application.application}
        refreshApplicationStatus={applicationStatus.refreshApplicationStatus}
        // refreshApplicationDetailForm={applicationDetail.refreshApplicationDetailForm}
        classes={classes}
      />
    </div>

  );
}

function mapStateToProps(state) {
  return {
    applicationStatus: state.applicationStatus,
    application: state.application,
    defaultStates: state.defaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    applicationStatusActions: bindActionCreators(applicationStatusActions, dispatch),
    applicationActions: bindActionCreators(applicationActions, dispatch),
    defaultActions: bindActionCreators(defaultActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationStatus);
