import API from 'Api/api.js';
import { commonBackendCall, getAdminConfig } from 'Api/common.js';
import {
  GET_TASKFORM_REQUEST,
  GET_TASKFORM_SUCCESS,
  GET_TASKFORM_FAILURE,
  POST_TASKFORM_REQUEST,
  POST_TASKFORM_SUCCESS,
  POST_TASKFORM_FAILURE,
  PATCH_TASKFORM_REQUEST,
  PATCH_TASKFORM_SUCCESS,
  PATCH_TASKFORM_FAILURE,
  DELETE_TASKFORM_REQUEST,
  DELETE_TASKFORM_SUCCESS,
  DELETE_TASKFORM_FAILURE,
  GET_TASK_TASKFORMS_REQUEST,
  GET_TASK_TASKFORMS_SUCCESS,
  GET_TASK_TASKFORMS_FAILURE,
} from 'constants/admin/TaskForm';

export function getTaskTaskForms(taskID) {
  return commonBackendCall(
    GET_TASK_TASKFORMS_REQUEST,
    GET_TASK_TASKFORMS_SUCCESS,
    GET_TASK_TASKFORMS_FAILURE,
    API.get(`admin/tasks/taskforms/${taskID}`, getAdminConfig()),
  );
}

export function getTaskForms() {
  return commonBackendCall(
    GET_TASKFORM_REQUEST,
    GET_TASKFORM_SUCCESS,
    GET_TASKFORM_FAILURE,
    API.get('admin/task-forms', getAdminConfig()),
  );
}
export function createTaskForm(obj) {
  return commonBackendCall(
    POST_TASKFORM_REQUEST,
    POST_TASKFORM_SUCCESS,
    POST_TASKFORM_FAILURE,
    API.post('admin/task-forms', obj, getAdminConfig()),
  );
}
export function updateTaskForm(taskFormID, obj) {
  return commonBackendCall(
    PATCH_TASKFORM_REQUEST,
    PATCH_TASKFORM_SUCCESS,
    PATCH_TASKFORM_FAILURE,
    API.patch(`admin/task-forms/${taskFormID}`, obj, getAdminConfig()),
  );
}
export function deleteTaskForm(taskFormID) {
  return commonBackendCall(
    DELETE_TASKFORM_REQUEST,
    DELETE_TASKFORM_SUCCESS,
    DELETE_TASKFORM_FAILURE,
    API.delete(`admin/task-forms/${taskFormID}`, getAdminConfig()),
  );
}
