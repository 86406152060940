export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';
export const LOGIN_CHOOSE_WORKFLOW = 'LOGIN_CHOOSE_WORKFLOW';
export const LOGIN_CHOOSE_ROLE = 'LOGIN_CHOOSE_ROLE';
export const SET_PASSWORD_REQUEST = 'SET_PASSWORD_REQUEST';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_FAILURE = 'SET_PASSWORD_FAILURE';
export const LINK_ACTION_REQUEST = 'LINK_ACTION_REQUEST';
export const LINK_ACTION_SUCCESS = 'LINK_ACTION_SUCCESS';
export const LINK_ACTION_FAILURE = 'LINK_ACTION_FAILURE';
export const LINK_DETAIL_REQUEST = 'LINK_DETAIL_REQUEST';
export const LINK_DETAIL_SUCCESS = 'LINK_DETAIL_SUCCESS';
export const LINK_DETAIL_FAILURE = 'LINK_DETAIL_FAILURE';
export const GET_USER_WORKFLOWS_REQUEST = 'GET_USER_WORKFLOWS_REQUEST';
export const GET_USER_WORKFLOWS_SUCCESS = 'GET_USER_WORKFLOWS_SUCCESS';
export const GET_USER_WORKFLOWS_FAILURE = 'GET_USER_WORKFLOWS_FAILURE';
export const REGISTER_REQUEST = 'REGISTER_REQUEST';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAILURE = 'REGISTER_FAILURE';
export const FORGOT_PASSWORD_REQUEST = 'FORGOT_PASSWORD_REQUEST';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILURE = 'FORGOT_PASSWORD_FAILURE';
export const GET_LINK_ACTION_EX_DETAIL_REQUEST = 'GET_LINK_ACTION_EX_DETAIL_REQUEST';
export const GET_LINK_ACTION_EX_DETAIL_SUCCESS = 'GET_LINK_ACTION_EX_DETAIL_SUCCESS';
export const GET_LINK_ACTION_EX_DETAIL_FAILURE = 'GET_LINK_ACTION_EX_DETAIL_FAILURE';
