import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import CustomIntgComp from './CustomIntg/CustomIntgComp';
import * as customActions from 'actions/networkadmin/CustomActions';
import * as integratonActions from 'actions/networkadmin/IntegrationActions';

const CustomIntg = (props) => {
  const { classes } = props;
  const { custom, integration, defaultStates } = props;
  const {
    getCustomIntgs,
    getCustomCSV,
    createCustomIntgFieldValue,
    updateCustomIntgFieldValue,
    deleteCustomIntgFieldValue,
  } = props.customActions;
  const {
    getCustomIntgFields,
  } = props.integratonActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <CustomIntgComp
        getCustomIntgs={getCustomIntgs}
        getCustomCSV={getCustomCSV}
        getCustomIntgFields={getCustomIntgFields}
        createCustomIntgFieldValue={createCustomIntgFieldValue}
        updateCustomIntgFieldValue={updateCustomIntgFieldValue}
        deleteCustomIntgFieldValue={deleteCustomIntgFieldValue}
        custom_intg_list={custom.custom_intg_list === undefined ? [] : custom.custom_intg_list}
        custom_list_csv={custom.custom_list_csv === undefined ? [] : custom.custom_list_csv}
        intg_custom_field_list={integration.intg_custom_field_list === undefined ? [] : integration.intg_custom_field_list}
        requesting={custom.requesting}
        message={custom.message}
        refreshCustomIntg={custom.refreshCustomIntg}
        error_code={custom.error_code}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    custom: state.naCustom,
    defaultStates: state.naDefaultStates,
    integration: state.naIntegration,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    customActions: bindActionCreators(customActions, dispatch),
    integratonActions: bindActionCreators(integratonActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomIntg);
