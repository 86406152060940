import {
  GET_FORM_REQUEST,
  GET_FORM_SUCCESS,
  GET_FORM_FAILURE,
  POST_FORM_REQUEST,
  POST_FORM_SUCCESS,
  POST_FORM_FAILURE,
  PATCH_FORM_REQUEST,
  PATCH_FORM_SUCCESS,
  PATCH_FORM_FAILURE,
  DELETE_FORM_REQUEST,
  DELETE_FORM_SUCCESS,
  DELETE_FORM_FAILURE,
  POST_FORM_ADHOC_REQUEST,
  POST_FORM_ADHOC_SUCCESS,
  POST_FORM_ADHOC_FAILURE,
  GET_FORM_PRINT_FILE_REQUEST,
  GET_FORM_PRINT_FILE_SUCCESS,
  GET_FORM_PRINT_FILE_FAILURE,
} from '../constants/admin/Form';
import {
  RESET_MESSAGE,
} from '../constants/admin/Default';

const initialState = {
  requesting: false,
  message: '',
  form_list: '',
  form_print_file: '',
  refreshForm: false,
};

export default function form(state = initialState, action) {
  switch (action.type) {
    case RESET_MESSAGE:
      return {
        ...state,
        message: action.payload.value,
      };
    case GET_FORM_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshForm: false,
        message: '',
      };

    case GET_FORM_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        form_list: action.payload.data,
      };

    case GET_FORM_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case GET_FORM_PRINT_FILE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshForm: false,
        message: '',
      };

    case GET_FORM_PRINT_FILE_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        form_print_file: action.payload.data,
      };

    case GET_FORM_PRINT_FILE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case POST_FORM_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshForm: false,
      };

    case POST_FORM_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshForm: true,
      };

    case POST_FORM_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case POST_FORM_ADHOC_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshForm: false,
      };

    case POST_FORM_ADHOC_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshForm: true,
      };

    case POST_FORM_ADHOC_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case PATCH_FORM_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshForm: false,
      };

    case PATCH_FORM_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshForm: true,
      };

    case PATCH_FORM_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case DELETE_FORM_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshForm: false,
      };

    case DELETE_FORM_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshForm: true,
      };

    case DELETE_FORM_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
