import React, { useState, useEffect } from 'react';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import { SortComp } from './UnitFieldComp/FormFunctions.jsx';
import { LabelComp } from './UnitFieldComp/FormFunctions.jsx';
import { AliasComp } from './UnitFieldComp/FormFunctions.jsx';
import { TagComp } from './UnitFieldComp/FormFunctions.jsx';
import { ColspanComp } from './UnitFieldComp/FormFunctions.jsx';
import { IsReadOnlyComp } from './UnitFieldComp/FormFunctions.jsx';
import { RequiredComp } from './UnitFieldComp/FormFunctions.jsx';
import { DataTypeComp } from './UnitFieldComp/FormFunctions.jsx';
import { ErrorMessageComp } from './UnitFieldComp/FormFunctions.jsx';
import { DefaultValueComp } from './UnitFieldComp/FormFunctions.jsx';
import { HelpTextComp } from './UnitFieldComp/FormFunctions.jsx';
import { UpdateIntgComp } from './UnitFieldComp/FormFunctions.jsx';
import { ItemIntgFieldComp } from './UnitFieldComp/FormFunctions.jsx';
import { ItemRelationLevelComp } from './UnitFieldComp/FormFunctions.jsx';
import { RelatedItemSearchComp } from './UnitFieldComp/FormFunctions.jsx';
import { ValidateRuleComp } from './UnitFieldComp/FormFunctions.jsx';
import { RequiredFieldRuleComp } from './UnitFieldComp/FormFunctions.jsx';
import { VisibleFieldRuleComp } from './UnitFieldComp/FormFunctions.jsx';
import { IntgComp } from './UnitFieldComp/FormFunctions.jsx';
import { UserIntgComp } from './UnitFieldComp/FormFunctions.jsx';
import { IntegrationFieldComp } from './UnitFieldComp/FormFunctions.jsx';
import { ItemIndexComp } from './UnitFieldComp/FormFunctions.jsx';
import { DepartmentComp } from './UnitFieldComp/FormFunctions.jsx';
import { UseAsVariableComp } from './UnitFieldComp/FormFunctions.jsx';
import { UseAsVariableNameComp } from './UnitFieldComp/FormFunctions.jsx';
import { RelatedFieldComp } from './UnitFieldComp/FormFunctions.jsx';
import { AppListCustomIndexComp } from './UnitFieldComp/FormFunctions.jsx';

function TextFieldComp(props) {

	return (
		<div>
			<GridContainer>
				<GridItem xs={12} sm={12} md={8}>
					<LabelComp
						fieldDisabled={props.fieldDisabled}
						label={props.label}
						onChangeLabel={props.onChangeLabel}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={4}>
					<SortComp
						fieldDisabled={props.fieldDisabled}
						sortOrder={props.sortOrder}
						onChangeSortOrder={props.onChangeSortOrder}
					/>
				</GridItem>

			</GridContainer>
			<br />
			<GridContainer>
				<GridItem xs={12} sm={12} md={6}>
					<AliasComp
						fieldDisabled={props.fieldDisabled}
						alias={props.alias}
						onChangeAlias={props.onChangeAlias}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={6}>
					<TagComp
						fieldDisabled={props.fieldDisabled}
						tag={props.tag}
						onChangeTag={props.onChangeTag}
					/>
				</GridItem>
			</GridContainer>
			<br />
			<GridContainer>
				<GridItem xs={12} sm={12} md={6}>
					<ColspanComp
						fieldDisabled={props.fieldDisabled}
						classes={props.classes}
						colspan={props.colspan}
						onChangeColspan={props.onChangeColspan}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={6}>
					<IsReadOnlyComp
						fieldDisabled={props.fieldDisabled}
						classes={props.classes}
						isReadOnly={props.isReadOnly}
						onChangeIsReadOnly={props.onChangeIsReadOnly}
					/>
				</GridItem>
			</GridContainer>
			<br />
			<GridContainer>
				<GridItem xs={12} sm={12} md={6}>
					<RequiredComp
						fieldDisabled={props.fieldDisabled}
						classes={props.classes}
						required={props.required}
						onChangeRequired={props.onChangeRequired}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={6}>
					<DataTypeComp
						fieldDisabled={props.fieldDisabled}
						classes={props.classes}
						type={props.type}
						fieldDataType={props.fieldDataType}
						onChangeFieldDataType={props.onChangeFieldDataType}
					/>
				</GridItem>
			</GridContainer>
			<br />
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<ErrorMessageComp
						fieldDisabled={props.fieldDisabled}
						errorMessage={props.errorMessage}
						onChangeErrorMessage={props.onChangeErrorMessage}
					/>
				</GridItem>
			</GridContainer>
			<br />
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<DefaultValueComp
						fieldDisabled={props.fieldDisabled}
						type={props.type}
						defaultValue={props.defaultValue}
						onChangeDefaultValue={props.onChangeDefaultValue}
					/>
				</GridItem>
			</GridContainer>
			<br />
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<HelpTextComp
						fieldDisabled={props.fieldDisabled}
						type={props.type}
						helpText={props.helpText}
						onChangeHelpText={props.onChangeHelpText}
					/>
				</GridItem>
			</GridContainer>
			<br />
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<ValidateRuleComp
						disabled={props.fieldDisabled}
						field_data_type={props.field_data_type}
						setValidateRule={props.setValidateRule}
						field_validate_rule={props.field_validate_rule}
					/>
				</GridItem>
			</GridContainer>
			<br />
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<RequiredFieldRuleComp
						disabled={props.fieldDisabled}
						fields={props.fields}
						role_list={props.role_list}
						setRequiredRule={props.setRequiredRule}
						field_required_rule={props.field_required_rule}
					/>
				</GridItem>
			</GridContainer>
			<br />
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<VisibleFieldRuleComp
						disabled={props.fieldDisabled}
						fields={props.fields}
						role_list={props.role_list}
						setVisibleRule={props.setVisibleRule}
						field_visible_rule={props.field_visible_rule}
					/>
				</GridItem>
			</GridContainer>
			<br />
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<IntgComp
						fieldDisabled={props.fieldDisabled}
						classes={props.classes}
						intgType={props.intgType}
						onChangeIntgType={props.onChangeIntgType}
						roleID={props.roleID}
						onChangeRoleID={props.onChangeRoleID}
						role_list={props.role_list}
						departmentID={props.departmentID}
						onChangeDepartmentID={props.onChangeDepartmentID}
						dept_list={props.dept_list}
						intgFieldID={props.intgFieldID}
						onChangeIntgFieldID={props.onChangeIntgFieldID}
						intg_field_List={props.intg_field_List}
						field_item_intg_list={props.field_item_intg_list}
						itemRelationLevel={props.itemRelationLevel}
						onChangeItemRelationLevel={props.onChangeItemRelationLevel}
						onChangeRelatedFieldID={props.onChangeRelatedFieldID}
						field_list={props.field_list}
						relatedFieldID={props.relatedFieldID}
					/>
				</GridItem>
			</GridContainer>
			<br />
			<GridContainer>
				<GridItem xs={12} sm={12} md={6}>
					<UseAsVariableComp
						fieldDisabled={props.fieldDisabled}
						classes={props.classes}
						useAsFormVar={props.useAsFormVar}
						name={props.name}
						onUseAsFormVarChange={props.onUseAsFormVarChange}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={6}>
					<div style={{ display: props.useAsFormVar ? 'block' : 'none' }}>
						<UseAsVariableNameComp
							fieldDisabled={props.fieldDisabled}
							classes={props.classes}
							name={props.name}
							onChangeName={props.onChangeName}
						/>
					</div>
				</GridItem>
			</GridContainer>
			<br />
			<GridContainer>
				<GridItem xs={12} sm={12} md={6}>
					<RelatedFieldComp
						fieldDisabled={props.fieldDisabled}
						classes={props.classes}
						id={props.id}
						relatedFieldID={props.relatedFieldID}
						onChangeRelatedFieldID={props.onChangeRelatedFieldID}
						field_list={props.field_list}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={6}>
					<AppListCustomIndexComp
						fieldDisabled={props.fieldDisabled}
						classes={props.classes}
						appListCustomIndex={props.appListCustomIndex}
						onChangeAppListCustomIndex={props.onChangeAppListCustomIndex}
					/>
				</GridItem>
			</GridContainer>
		</div>);
}
export { TextFieldComp }
