import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import TextField from '@material-ui/core/TextField';
import Button from 'components/CustomButtons/Button.jsx';

const AppSelfRemindComp = (props) => {
  const [remindMessage, setRemindMessage] = useState("");
  const [remindDate, setRemindDate] = useState("");
  const [remindDateError, setRemindDateError] = useState("");
  const [remindMessageError, setRemindMessageError] = useState("");
  const { application_tasklog_id, application_id, task_id } = props;

  useEffect(() => {
    setRemindMessage(props.remind_message)
    setRemindDate(props.remind_date)
  }, [props]);

  const onRemindMessageChange = (e) => {
    setRemindMessage(e.target.value);
  };
  const onRemindDateChange = (e) => {
    setRemindDateError("")
    setRemindDate(e.target.value);
  };

  const handleSetupReminderClick = () => {
    if (remindDate === "") {
      setRemindDateError("Invalid remind date")
      return
    }
    let remindDateObj = new Date(remindDate).getTime();
    let todayDateObj = new Date().getTime();
    if (remindDateObj <= todayDateObj) {
      setRemindDateError("Remind date should be in future")
      return
    }
    if (remindMessage.trim() === "") {
      setRemindMessageError("Invalid remind message")
      return
    }
    props.patchApplicationRemind(application_id, application_tasklog_id, { "remind_message": remindMessage, "remind_date": remindDate, "send_now": "0" })
  };

  return (
    <div>
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <Typography variant="h5" gutterBottom>{"Self Reminder"}</Typography>
        </CardHeader>
        <CardBody>
          <GridContainer>
            <GridItem xs={12} sm={12} md={12}>
              <TextField
                error={remindMessageError !== ""}
                onChange={onRemindMessageChange.bind(this)}
                key={'selfReminderMessage'}
                fullWidth
                label={"Reminder Message"}
                rows={10}
                multiline
                disabled={false}
                variant="outlined"
                value={remindMessage}
                helperText={remindMessageError}
              />
            </GridItem>
          </GridContainer>
          <br />
          <GridContainer>
            <GridItem xs={12} sm={12} md={4}>
              <TextField
                //value={date.toLocaleString(localStorage.getItem('network_lang'), { weekday: "short", year: "numeric", month: "2-digit", day: "numeric", hour: "2-digit", minute: "2-digit" })}
                error={remindDateError !== ""}
                onChange={onRemindDateChange.bind(this)}
                key={'selfReminderDate'}
                fullWidth
                label={"Reminder Date"}
                disabled={false}
                type="date"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                value={remindDate}
                helperText={remindDateError}
              />
            </GridItem>
            <GridItem xs={12} sm={12} md={8}>
              <Button
                onClick={handleSetupReminderClick}
                color="primary"
                round
              >
                {"Set Self Reminder"}
              </Button>
            </GridItem>
          </GridContainer>
        </CardBody>
      </Card>
    </div>
  );
};

export default AppSelfRemindComp;
