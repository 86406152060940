import React, { useState, useEffect } from 'react';

import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import withStyles from '@material-ui/core/styles/withStyles';
import loginPageStyle from 'assets/jss/material-dashboard-react/views/loginPageStyle.jsx';
import { Navigate,useParams } from 'react-router-dom';
import * as loginActions from 'actions/admin/LoginActions';
import * as networkActions from 'actions/NetworkActions';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import LoginComp from './Login/LoginComp';

const Login = (props) => {
  const { classes } = props;
  const { login, defaultStates } = props;
  const { onLogin } = props.loginActions;
  const { getNetworkByName, getNetworkByAPIKey } = props.networkActions;
  const [redirectToNetwork, setRedirectToNetwork] = useState(false);
  const [refreshLogin, setRefreshLogin] = useState(false);
  const [showLoadingPage, setShowLoadingPage] = useState(true);
  const { network_name } = useParams();
  const { workflow_id } = useParams();

  useEffect(() => {
    if (localStorage.getItem('network_api_key')) {
      setShowLoadingPage(false);
    } else {
      setShowLoadingPage(true);
      var networkNameParam = network_name;
      if (networkNameParam !== undefined) {
        getNetworkByName(networkNameParam);
        setRefreshLogin(true);
      } else {
        var envApiKey = process.env.REACT_APP_API_KEY;
        if (envApiKey !== undefined) {
          getNetworkByAPIKey(envApiKey);
          setRefreshLogin(true);
        } else {
          setRedirectToNetwork(true);
        }
      }
    }
  }, []);

  if (localStorage.getItem('admin_token')) {
    return <Navigate to="/admin/dashboard" />;
  }

  if (login.chooseWorkflow) {
    return <Navigate to="/admin/public/choose-workflow" />;
  }

  var workflowIDParam = workflow_id;

  if (redirectToNetwork) {
    return <Navigate to="/admin/public/network?app=admin" />;
  }

  if (refreshLogin) {
    setTimeout(() => {
      window.location.reload();
    }, 500);
  }

  if (showLoadingPage) {
    return (
      <div>Loading...</div>
    );
  }
  return (
    <div className={classes.content}>
      <div className={classes.container}>
        {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
        <LoginComp
          requesting={login.requesting}
          message={login.message}
          error_code={login.error_code}
          onLogin={onLogin}
          classes={classes}
          workflow_id={workflowIDParam}
          loggedIn={login.loggedIn}
        />
      </div>
    </div>
  );
};

function mapStateToProps(state) {
  return {
    login: state.adLogin,
    defaultStates: state.adDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loginActions: bindActionCreators(loginActions, dispatch),
    networkActions: bindActionCreators(networkActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(loginPageStyle)(Login));
