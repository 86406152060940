import {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_INTG_REQUEST,
  GET_USER_INTG_SUCCESS,
  GET_USER_INTG_FAILURE,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
} from '../constants/admin/User';

const initialState = {
  requesting: false,
  message: '',
  user_list: [],
  user_intg_list: [],
  refreshUser: false,
  resetPasswordSuccess: false,
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshUser: false,
        message: '',
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: 'Reset Password Successfully',
        resetPasswordSuccess: true,
      };

    case RESET_PASSWORD_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_USER_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshUser: false,
        message: '',
        user_list: [],
      };

    case GET_USER_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        user_list: action.payload.data,
      };

    case GET_USER_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case GET_USER_INTG_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        user_intg_list: [],
      };

    case GET_USER_INTG_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        user_intg_list: action.payload.data,
      };

    case GET_USER_INTG_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
