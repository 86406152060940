import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as lookupActions from 'actions/LookupActions';
import * as userActions from 'actions/UserActions';
import CommitteeComp from './Committee/CommitteeComp';

const Committee = (props) => {
  const { classes } = props;
  const { lookup, user } = props;
  const { getAdhocRoles, setRoleUser, setSelectedRoleID } = props.lookupActions;
  const { setSelectedUser } = props.userActions;

  return (
    <CommitteeComp
      getAdhocRoles={getAdhocRoles}
      setSelectedRoleID={setSelectedRoleID}
      field_committee_id={props.field_committee_id}
      setRoleUser={setRoleUser}
      role_list={lookup.role_list === undefined ? [] : lookup.role_list}
      field_required={props.field_required}
      field_value={props.field_value === '' || props.field_value === undefined ? [] : props.field_value}
      field_label={props.field_label}
      form_checkable={props.form_checkable}
      role_user={lookup.role_user === undefined ? [] : lookup.role_user}
      selected_user={user.selected_user}
      selected_user_id={user.selected_user_id}
      selected_role_id={lookup.selected_role_id}
      application_id={props.application_id}
      message={lookup.message}
      task_completed={props.task_completed}
      setSelectedUser={setSelectedUser}
      classes={classes}
    />
  );
}

function mapStateToProps(state) {
  return {
    lookup: state.lookup,
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    lookupActions: bindActionCreators(lookupActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Committee);
