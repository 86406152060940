import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as itemActions from 'actions/ItemActions';
import Item2Comp from './Item2/Item2Comp';

const Item2 = (props) => {
  const { classes } = props;
  const { setSelectedItem, FindRelatedItems } = props.itemActions;
  const { item, } = props;

  return (
    <Item2Comp
      FindRelatedItems={FindRelatedItems}
      selected_parent_items={item.selected_parent_items}
      error={props.error}
      helperText={props.helperText}
      form_checkable={props.form_checkable}
      field_value={props.field_value}
      field_label={props.field_label}
      item_relation_level={props.item_relation_level}
      search_table={props.search_table}
      search_table_related={props.search_table_related}
      search_level={props.search_level}
      setSelectedItem={setSelectedItem}
      selected_item={item.selected_item}
      selected_item_id={item.selected_item_id}
      selected_field_id={item.field_id}
      selected_related_item_list={item.selected_related_item_list}
      fields={props.fields}
      application_id={props.application_id}
      form_id={props.form_id}
      field_id={props.field_id}
      task_id={props.task_id}
      task_completed={props.task_completed}
      is_read_only={props.is_read_only}
      requesting={props.requesting}
      datatable_rule={props.datatable_rule}
      postApplicationDetail={props.postApplicationDetail}
      search_related_item_list={item.search_related_item_list.list === undefined ? [] : item.search_related_item_list.list.item}
      search_related_item_parents={item.search_related_item_list.list === undefined ? [] : item.search_related_item_list.list.parent_ids}
      search_related_item_count={item.search_related_item_list.count === undefined ? 0 : item.search_related_item_list.count}
      refreshRelatedItemSearch={props.refreshRelatedItemSearch}
      postItem={props.postItem}
      patchItemPercent={props.patchItemPercent}
      message={item.message}
      classes={classes}
    />
  );
}

function mapStateToProps(state) {
  return {
    item: state.item,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    itemActions: bindActionCreators(itemActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Item2);
