/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import MUIDataTable from 'mui-datatables';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.jsx';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import AirplayIcon from '@mui/icons-material/Airplay';
import Typography from '@mui/material/Typography';

const TaskTaskFormComp = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogButton, setDialogButton] = useState('');
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [dialogType, setDialogType] = useState('add');
  const [data, setData] = useState([]);
  const [message, setMessage] = useState('');
  const [id, setID] = useState('');
  const [taskID, setTaskID] = useState('');
  const [formID, setFormID] = useState('');
  const [sortOrder, setSortOrder] = useState('');
  const [checkable, setCheckable] = useState('');
  const [level, setLevel] = useState('');
  const navigate = useNavigate();


  const {
    getTasks, getForms, getTaskTaskForms, task_id,
  } = props;
  useEffect(() => {
    getTasks();
    getForms();
    getTaskTaskForms(task_id);
  }, [getTasks, getForms, getTaskTaskForms, task_id]);

  const handleEditClick = (e) => {
    setID(e[0]);
    setTaskID(e[1]);
    setFormID(e[2]);
    setSortOrder(e[3]);
    setCheckable(e[4]);
    setLevel(e[5]);
    setMessage('');
    setDialogTitle('Edit');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('update');
    setDialogOpen(true);
    props.resetMessage('');
  };
  const handleDeleteClick = (e) => {
    setID(e[0]);
    setTaskID(e[1]);
    setFormID(e[2]);
    setSortOrder(e[3]);
    setCheckable(e[4]);
    setLevel(e[5]);
    setMessage('');
    setDialogTitle('Delete');
    setDialogButton('Confirm Delete');
    setFieldDisabled(true);
    setDialogType('delete');
    setDialogOpen(true);
    props.resetMessage('');
  };
  const handleNewClick = (e) => {
    const maxValueOfSortOrder = Math.max(...props.task_taskforms_list.map((o) => o.sort_order), 0);
    setSortOrder(maxValueOfSortOrder + 5);
    setTaskID(props.task_id);
    setFormID('');
    setCheckable('');
    setLevel(0);
    setMessage('');
    setDialogTitle('Add New');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('add');
    setDialogOpen(true);
    props.resetMessage('');
  };
  useEffect(() => {
    setMessage(props.message);
    if (props.refreshTaskForm) {
      props.getTaskTaskForms(props.task_id);
      setDialogOpen(false);
    }
    var rowItems = [];
    if (props.task_list.length > 0 && props.form_list.length > 0 && props.task_taskforms_list.length > 0) {
      for (let i = 0; i < props.task_taskforms_list.length; i++) {
        var linkPreview = `/admin/task-form-preview/${props.task_taskforms_list[i].form_id}/${props.task_id}`;
        var link = `/admin/task-form-fields/${props.task_taskforms_list[i].form_id}/${props.task_id}`;
        var colItems = [];
        colItems.push(i + 1);
        var formObj = props.form_list.find((o) => o.id === props.task_taskforms_list[i].form_id);
        formObj === undefined ? colItems.push('') : colItems.push(formObj.title);
        colItems.push(props.task_taskforms_list[i].form_category_title);
        colItems.push(props.task_taskforms_list[i].sort_order);
        colItems.push(props.task_taskforms_list[i].checkable ? 'Yes' : 'No');
        colItems.push(
          <div>
            <IconButton title="Fields" onClick={() => navigate(`/admin/task-form-fields/${props.task_taskforms_list[i].form_id}/${props.task_id}`)}><Typography variant="body2">{props.task_taskforms_list[i].field_count}</Typography><ListRoundedIcon /></IconButton>
            <IconButton title="Preview" onClick={() => navigate(`/admin/task-form-preview/${props.task_taskforms_list[i].form_id}/${props.task_id}`)}><AirplayIcon /></IconButton>
            <IconButton
              onClick={handleEditClick.bind(this,
                [props.task_taskforms_list[i].id,
                props.task_taskforms_list[i].task_id,
                props.task_taskforms_list[i].form_id,
                props.task_taskforms_list[i].sort_order,
                props.task_taskforms_list[i].checkable,
                props.task_taskforms_list[i].level,
                ])}
              title={`Edit Row ID:${props.form_list[i].id}`}
            >
              {' '}
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={handleDeleteClick.bind(this,
                [props.task_taskforms_list[i].id,
                props.task_taskforms_list[i].task_id,
                props.task_taskforms_list[i].form_id,
                props.task_taskforms_list[i].sort_order,
                props.task_taskforms_list[i].checkable,
                props.task_taskforms_list[i].level,
                ])}
              title="Delete row"
            >
              {' '}
              <DeleteIcon />
            </IconButton>
          </div>,
        );
        rowItems.push(colItems);
      }
    }
    setData(rowItems);
  }, [props]);

  const handleDialogClose = (e) => {
    setDialogOpen(false);
    setMessage('');
  };
  const handleDialogConfirm = (e) => {
    if (dialogType === 'add') {
      props.createTaskForm({
        task_id: taskID.toString(),
        form_id: formID.toString(),
        sort_order: sortOrder.toString(),
        checkable: checkable ? '1' : '0',
        level: level.toString() ,
      });
    } else if (dialogType === 'update') {
      props.updateTaskForm(id, {
        task_id: taskID.toString(),
        form_id: formID.toString(),
        sort_order: sortOrder.toString(),
        checkable: checkable ? '1' : '0',
        level: level.toString() ,
      });
    } else if (dialogType === 'delete') {
      props.deleteTaskForm(id);
    }
  };
  const onTaskIDChange = (e) => {
    setTaskID(e.target.value);
  };
  const onFormIDChange = (e) => {
    setFormID(e.target.value);
  };
  const onSortOrderChange = (e) => {
    var message = '';
    var letters = /^[0-9]+$/;
    if (!e.target.value.match(letters)) {
      message = 'Invalid sort order';
    }
    setSortOrder(e.target.value);
    setMessage(message);
  };
  const onCheckableChange = (e) => {
    setCheckable(e.target.checked);
  };

  const onLevelChange = (e) => {
    setLevel(e.target.value);
  };
  const { classes } = props;
  const columns = [
    'Serial',
    'Form',
    'Form Category',
    'Sort Order',
    'Form Required',
    '',
  ];
  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    filter: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    selectToolbarPlacement: 'none',
  };
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Task Form</b>
          <p className={classes.cardCategoryWhite}>
            Here is the list representing forms that will be shown in each task in the workflow.
          </p>
        </CardHeader>
        <CardBody>
          <MUIDataTable
            title={(
              <Button
                onClick={handleNewClick.bind(this)}
                color="primary"
                title="Create a new Task Form"
                disabled={parseInt(localStorage.getItem('workflow_state'), 10) > 0}
                round
              >
                New Task Form
              </Button>
            )}
            data={data}
            columns={columns}
            options={options}
          />
          <Dialog fullWidth open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              <Card>
                <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                  <b>{dialogTitle}</b>
                </CardHeader>
                <CardBody>
                  <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl fullWidth className={classes.formControl} disabled>
                        <InputLabel id="taskIdLabel">Task</InputLabel>
                        <Select
                          labelid="taskIdLabel"
                          id="taskIdSelect"
                          value={props.task_id}
                          onChange={onTaskIDChange.bind(this)}
                        >
                          {props.task_list.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {props.task_list.find((p) => p.id === item.id).title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled || parseInt(localStorage.getItem('workflow_state'), 10) > 0}>
                        <InputLabel id="formIdLabel">Form</InputLabel>
                        <Select
                          labelid="formIdLabel"
                          id="formIdSelect"
                          value={formID}
                          onChange={onFormIDChange.bind(this)}
                        >
                          {props.form_list.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {/*`${props.form_list.find((p) => p.id === item.id).form_category_title}->`*/}
                              {props.form_list.find((p) => p.id === item.id).title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <TextField
                        required={false}
                        fullWidth
                        label="Sort Order"
                        value={sortOrder}
                        onChange={onSortOrderChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <InputLabel id="checkableLabel" shrink>Form Required </InputLabel>
                      <Grid item component="label" container alignItems="center" spacing={1}>
                        <Grid item>No</Grid>
                        <Grid item>
                          <Switch
                            labelid="checkableLabel"
                            checked={checkable}
                            onChange={onCheckableChange.bind(this)}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled={fieldDisabled}
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                            }}
                          />
                        </Grid>
                        <Grid item>Yes</Grid>
                      </Grid>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled || parseInt(localStorage.getItem('workflow_state'), 10) > 0}>
                        <InputLabel id="levelLabel">Security</InputLabel>
                        <Select
                          labelid="levelLabel"
                          id="levelSelect"
                          value={level}
                          onChange={onLevelChange.bind(this)}
                        >
                          <MenuItem key={0} value={0}> {"No Security (0)"} </MenuItem>
                          <MenuItem key={1} value={1}> {"Level (1)"} </MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <br />
                </CardBody>
                <CardFooter style={{ textAlign: 'left' }} />
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose.bind(this)} color="primary" round>
                Cancel
              </Button>
              <Button
                onClick={handleDialogConfirm.bind(this)}
                color="primary"
                round
                disabled={message === '' && dialogButton !== 'Save' && parseInt(localStorage.getItem('workflow_state'), 10) > 0}
              >
                {dialogButton}
              </Button>
            </DialogActions>
          </Dialog>
        </CardBody>
      </Card>
    </div>
  );
};
export default withStyles(extendedFormsStyle)(TaskTaskFormComp);
