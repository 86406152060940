import {
  GET_WORKFLOW_REQUEST,
  GET_WORKFLOW_SUCCESS,
  GET_WORKFLOW_FAILURE,
  POST_WORKFLOW_REQUEST,
  POST_WORKFLOW_SUCCESS,
  POST_WORKFLOW_FAILURE,
  PATCH_WORKFLOW_REQUEST,
  PATCH_WORKFLOW_SUCCESS,
  PATCH_WORKFLOW_FAILURE,
  DELETE_WORKFLOW_REQUEST,
  DELETE_WORKFLOW_SUCCESS,
  DELETE_WORKFLOW_FAILURE,
  GET_WORKFLOW_EXPORT_REQUEST,
  GET_WORKFLOW_EXPORT_SUCCESS,
  GET_WORKFLOW_EXPORT_FAILURE,
  POST_WORKFLOW_FILE_UPLOAD_REQUEST,
  POST_WORKFLOW_FILE_UPLOAD_SUCCESS,
  POST_WORKFLOW_FILE_UPLOAD_FAILURE,
} from '../constants/networkadmin/Workflow';
import {
  RESET_MESSAGE,
} from '../constants/networkadmin/Default';


const initialState = {
  requesting: false,
  message: '',
  workflow_list: [],
  workflow_export_list: '',
  refreshWorkflow: false,
};

export default function workflow(state = initialState, action) {
  switch (action.type) {
    case RESET_MESSAGE:
      return {
        ...state,
        message: action.payload.value,
      };

    case GET_WORKFLOW_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshWorkflow: false,
        message: '',
      };

    case GET_WORKFLOW_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        workflow_list: action.payload.data,
      };

    case GET_WORKFLOW_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_WORKFLOW_EXPORT_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
      };

    case GET_WORKFLOW_EXPORT_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        workflow_export_list: action.payload.data,
      };

    case GET_WORKFLOW_EXPORT_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case POST_WORKFLOW_FILE_UPLOAD_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshWorkflow: false,
      };

    case POST_WORKFLOW_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case POST_WORKFLOW_FILE_UPLOAD_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case POST_WORKFLOW_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshWorkflow: false,
      };

    case POST_WORKFLOW_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshWorkflow: true,
      };

    case POST_WORKFLOW_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case PATCH_WORKFLOW_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshWorkflow: false,
      };

    case PATCH_WORKFLOW_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshWorkflow: true,
      };

    case PATCH_WORKFLOW_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case DELETE_WORKFLOW_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshWorkflow: false,
      };

    case DELETE_WORKFLOW_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshWorkflow: true,
      };

    case DELETE_WORKFLOW_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
