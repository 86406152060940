import {
  GET_ACTION_REQUEST,
  GET_ACTION_SUCCESS,
  GET_ACTION_FAILURE,
  GET_ACTION_SHARE_REQUEST,
  GET_ACTION_SHARE_SUCCESS,
  GET_ACTION_SHARE_FAILURE,
  POST_ACTION_REQUEST,
  POST_ACTION_SUCCESS,
  POST_ACTION_FAILURE,
  PATCH_ACTION_REQUEST,
  PATCH_ACTION_SUCCESS,
  PATCH_ACTION_FAILURE,
  DELETE_ACTION_REQUEST,
  DELETE_ACTION_SUCCESS,
  DELETE_ACTION_FAILURE,
  GET_TASK_ACTION_REQUEST,
  GET_TASK_ACTION_SUCCESS,
  GET_TASK_ACTION_FAILURE,
  SET_ACTION_RULE,
} from '../constants/admin/Action';
import {
  RESET_MESSAGE,
} from '../constants/admin/Default';

const initialState = {
  requesting: false,
  message: '',
  action_list: '',
  task_action_list: '',
  share_action_list: [],
  refreshAction: false,
  action_rules: [],
};

export default function action(state = initialState, action) {
  switch (action.type) {
    case RESET_MESSAGE:
      return {
        ...state,
        message: action.payload.value,
      };

    case SET_ACTION_RULE:
      return {
        ...state,
        action_rules: action.payload.value,
      };

    case GET_ACTION_SHARE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshAction: false,
        message: '',
      };

    case GET_ACTION_SHARE_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        share_action_list: action.payload.data,
      };

    case GET_ACTION_SHARE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_TASK_ACTION_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshAction: false,
        message: '',
      };

    case GET_TASK_ACTION_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        task_action_list: action.payload.data,
      };

    case GET_TASK_ACTION_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_ACTION_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshAction: false,
        message: '',
      };

    case GET_ACTION_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        action_list: action.payload.data,
      };

    case GET_ACTION_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case POST_ACTION_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshAction: false,
      };

    case POST_ACTION_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshAction: true,
      };

    case POST_ACTION_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case PATCH_ACTION_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshAction: false,
      };

    case PATCH_ACTION_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshAction: true,
      };

    case PATCH_ACTION_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case DELETE_ACTION_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshAction: false,
      };

    case DELETE_ACTION_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshAction: true,
      };

    case DELETE_ACTION_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
