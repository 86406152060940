import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from "components/Card/CardFooter.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.jsx';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import withStyles from "@material-ui/core/styles/withStyles";
import extendedFormsStyle from "assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx";
import InputLabel from '@material-ui/core/InputLabel';
import Switch from "@material-ui/core/Switch";
import Grid from '@material-ui/core/Grid';
import StateUI from "components/StateUI/StateUI.jsx";
import { DefaultEditor } from 'react-simple-wysiwyg';


const WorkflowsComp = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogButton, setDialogButton] = useState("");
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [dialogType, setDialogType] = useState("add");
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [id, setID] = useState("");
  const [dialogExportOpen, setDialogExportOpen] = useState(false)
  const [dialogImportOpen, setDialogImportOpen] = useState(false)
  const [name, setName] = useState('')
  const [alias, setAlias] = useState('')
  const [workflowName, setWorkflowName] = useState('')
  const [description, setDescription] = useState('')
  const [shortDesc, setShortDesc] = useState('')
  const [image, setImage] = useState('')
  const [status, setStatus] = useState('0')
  const [workflowExport, setWorkflowExport] = useState([])
  const [workflowFile, setWorkflowFile] = useState(null)


  const { getWorkflows } = props;
  useEffect(() => {
    getWorkflows();
  }, [getWorkflows])


  useEffect(() => {
    setMessage(props.message)
    if (props.refreshWorkflow) {
      props.getWorkflows();
      setDialogOpen(false);
      setDialogExportOpen(false)
      setDialogImportOpen(false)
    }
    var rowItems = [];
    for (let i = 0; i < props.workflow_list.length; i++) {
      if (props.workflow_list[i].name !== "") {
        var colItems = [];
        colItems.push(i + 1);
        colItems.push(props.workflow_list[i].name);
        colItems.push(props.workflow_list[i].alias);
        colItems.push(props.workflow_list[i].short_desc);
        colItems.push(props.workflow_list[i].status ? "Active" : "Inactive");

        colItems.push(StateUI(props.workflow_list[i].state, "small"));
        colItems.push(<div><IconButton
          onClick={handleEditClick.bind(this,
            [props.workflow_list[i].id,
            props.workflow_list[i].name,
            props.workflow_list[i].description,
            props.workflow_list[i].short_desc,
            props.workflow_list[i].image,
            props.workflow_list[i].status,
            props.workflow_list[i].alias,
            ])}
          title="Edit row"> <EditIcon /></IconButton>
          {/*
          <IconButton onClick={handleDeleteClick.bind(this,
            [props.workflow_list[i].id,
            props.workflow_list[i].name,
            props.workflow_list[i].description,
            props.workflow_list[i].short_desc,
            props.workflow_list[i].image,
            props.workflow_list[i].status,
            props.workflow_list[i].alias,
            ])} title="Delete row"> <DeleteIcon /></IconButton>
          <IconButton onClick={handleExportIconClick.bind(this,
            [props.workflow_list[i].id,
            props.workflow_list[i].name,]
          )} title="Export Workflow"> <CloudDownloadIcon /></IconButton>
          <IconButton onClick={handleImportIconClick.bind(this,
            [props.workflow_list[i].id,
            props.workflow_list[i].name,]
          )} title="Import Workflow"> <CloudUploadIcon /></IconButton>
          */}
        </div>);
        //exportWorkflow
        rowItems.push(colItems);
      }
    }
    setData(rowItems)
    setWorkflowExport(props.workflow_export_list)
  }, [props])


  const handleExportIconClick = (e) => {
    props.resetMessage("")
    props.exportWorkflow(e[0])
    setDialogExportOpen(true)
    setName(e[1])
  }
  const handleImportIconClick = (e) => {
    setDialogImportOpen(true)
    setID(e[0])
    setName(e[1])
    props.resetMessage("")
  }
  const handleEditClick = (e) => {
    setID(e[0])
    setWorkflowName(e[1])
    setDescription(e[2])
    setShortDesc(e[3])
    setImage(e[4])
    setStatus(e[5])
    setAlias(e[6])
    setMessage('')
    setDialogTitle('Edit')
    setDialogButton('Save')
    setFieldDisabled(false)
    setDialogType('update')
    setDialogOpen(true)
    props.resetMessage("")
  }
  const handleDeleteClick = (e) => {
    setID(e[0])
    setWorkflowName(e[1])
    setDescription(e[2])
    setShortDesc(e[3])
    setImage(e[4])
    setStatus(e[5])
    setAlias(e[6])
    setMessage('')
    setDialogTitle('Delete')
    setDialogButton('Confirm Delete')
    setFieldDisabled(true)
    setDialogType('delete')
    setDialogOpen(true)
    props.resetMessage("")
  }
  const handleNewClick = (e) => {
    setWorkflowName('')
    setDescription('')
    setShortDesc('')
    setImage('')
    setStatus("0")
    setAlias("")
    setMessage('')
    setDialogTitle('Add New')
    setDialogButton('Save')
    setFieldDisabled(false)
    setDialogType('add')
    setDialogOpen(true)
    props.resetMessage("")
  }
  const handleDialogClose = (e) => {
    setDialogOpen(false)
    setMessage('')
  }
  const handleExportDialogClose = (e) => {
    setDialogExportOpen(false)
  }
  const handleImportDialogClose = (e) => {
    setDialogImportOpen(false)
  }
  const handleImportDialogConfirm = (e) => {
    setDialogImportOpen(false)
  }
  function toCamelCase(sentenceCase) {
    var out = "";
    sentenceCase.split(" ").forEach(function (el, idx) {
      var add = el.toLowerCase();
      out += (idx === 0 ? add : add[0].toUpperCase() + add.slice(1));
    });
    return out;
  }
  const handleExportDialogConfirm = (e) => {
    let filename = toCamelCase(name) + ".json";
    let contentType = "application/json;charset=utf-8;";
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      var blob = new Blob([decodeURIComponent(encodeURI(JSON.stringify(workflowExport)))], { type: contentType });
      navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      var a = document.createElement('a');
      a.download = filename;
      a.href = 'data:' + contentType + ',' + encodeURIComponent(JSON.stringify(workflowExport));
      a.target = '_blank';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    setDialogExportOpen(false)
  }

  const handleDialogConfirm = (e) => {
    if (dialogType === 'add') {
      props.createWorkflow({
        name: workflowName,
        description: description,
        short_desc: shortDesc,
        image: image,
        alias: alias,
      })
    } else if (dialogType === 'update') {
      props.updateWorkflow(
        id, {
        name: workflowName,
        description: description,
        short_desc: shortDesc,
        image: image,
        status: status ? "1" : "0",
        alias: alias,
      })
    } else if (dialogType === 'delete') {
      props.deleteWorkflow(id)
    }
  }
  const onWorkflowNameChange = (e) => {
    setWorkflowName(e.target.value)
  }

  const onDescriptionChange = (e) => {
    setDescription(e.target.value)
  }
  const onShortDescChange = (e) => {
    setShortDesc(e.target.value)
  }
  const onAliasChange = (e) => {
    setAlias(e.target.value)
  }
  const onImageChange = (e) => {
    setImage(e.target.value)
  }
  const onStatusChange = (e) => {
    setStatus(e.target.checked)
  }
  const onWorkflowFormSubmit = (e) => {
    e.preventDefault() // Stop form submit
    var reader = new FileReader();
    reader.onload = (function (props, id, theFile) {
      return function (e) {
        props.postWorkflowFileUpload(e.target.result, id)
      };
    })(props, id, workflowFile);

    reader.readAsText(workflowFile);

    setDialogImportOpen(false)
  }
  const onWorkflowFileChange = (e) => {
    setWorkflowFile(e.target.files[0])
  }
  const { classes } = props;
  const columns = [
    "Serial",
    "Name",
    "Alias",
    "Description",
    "Status",
    "State",
    ""
  ];
  const options = {
    filterType: "dropdown",
    responsive: "standard",
    filter: true,
    selectableRows: "none",
    rowsPerPage: 100,
    selectToolbarPlacement: "none"
  };
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      < Card >
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Workflows</b>
          <p className={classes.cardCategoryWhite}>
          </p>
        </CardHeader>
        <CardBody>
          <MUIDataTable
            title={<Button onClick={handleNewClick.bind(this)} color="primary" title="Create a new Workflow" round > New Workflow </Button>}
            data={data}
            columns={columns}
            options={options}
          />
          <Dialog fullWidth={true} open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              <DialogContentText>
                <Card>
                  <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                    {dialogTitle}
                  </CardHeader>

                  <CardBody>
                    <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
                    <br />
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          required={true}
                          fullWidth={true}
                          label="Name"
                          value={workflowName}
                          onChange={onWorkflowNameChange.bind(this)}
                          disabled={fieldDisabled}
                          variant="outlined"
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          required={false}
                          fullWidth={true}
                          label="Alias"
                          value={alias}
                          onChange={onAliasChange.bind(this)}
                          disabled={fieldDisabled}
                          variant="outlined"
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <InputLabel id="workflowDisabled" shrink={true}>Status</InputLabel>
                        <Grid item={true} component="label" container alignItems="center" spacing={1}>
                          <Grid item>Inactive</Grid>
                          <Grid item>
                            <Switch
                              labelid="workflowDisabled"
                              checked={status}
                              onChange={onStatusChange.bind(this)}
                              color="primary"
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                              disabled={fieldDisabled}
                              classes={{
                                switchBase: classes.switchBase,
                                checked: classes.switchChecked,
                              }}
                            />
                          </Grid>
                          <Grid item>Active</Grid>
                        </Grid>
                      </GridItem>
                    </GridContainer>
                    <br />
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          required={false}
                          fullWidth={true}
                          label="Short Description"
                          value={shortDesc}
                          onChange={onShortDescChange.bind(this)}
                          disabled={fieldDisabled}
                          variant="outlined"
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          required={false}
                          fullWidth={true}
                          label="Image"
                          value={image}
                          onChange={onImageChange.bind(this)}
                          disabled={fieldDisabled}
                          variant="outlined"
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <DefaultEditor value={description} onChange={onDescriptionChange} />
                        {/*
                        <TextField
                          required={true}
                          fullWidth={true}
                          multiline
                          rows="10"
                          label="Description"
                          value={description}
                          onChange={onDescriptionChange.bind(this)}
                          disabled={fieldDisabled}
                          variant="outlined"
                          style={{ width: '100%', }}
                        />
                        */}
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                  <CardFooter style={{ textAlign: 'left' }}>
                  </CardFooter>
                </Card>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose.bind(this)} color="primary" round>
                Cancel
              </Button>
              <Button onClick={handleDialogConfirm.bind(this)} color="primary" round>
                {dialogButton}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog fullWidth={true} open={dialogExportOpen} onClose={handleExportDialogClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              <DialogContentText>
                <Card>
                  <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                    Download Workflow
                  </CardHeader>

                  <CardBody>
                    <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        Download workflow schema into the file: <br />
                        <br />
                        <b>&quot;{toCamelCase(name)}.json&quot;</b>
                        <br />
                      </GridItem>
                    </GridContainer>
                    <br />
                  </CardBody>
                  <CardFooter style={{ textAlign: 'left' }}>
                  </CardFooter>
                </Card>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleExportDialogClose.bind(this)} color="primary" round>
                Cancel
              </Button>
              <Button onClick={handleExportDialogConfirm.bind(this)} color="primary" round
                disabled={message === undefined || message === "" ? false : true}
              >
                Download
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog fullWidth={true} open={dialogImportOpen} onClose={handleExportDialogClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              <DialogContentText>
                <Card>
                  <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                    Upload Workflow
                  </CardHeader>
                  <CardBody>
                    <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
                    <form onSubmit={onWorkflowFormSubmit.bind(this)}>
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              <input
                                type="file"
                                onChange={onWorkflowFileChange.bind(this)}
                                style={{ display: 'none' }}
                                id="raised-button-workflowfile"
                              />
                              <label htmlFor="raised-button-workflowfile">
                                <Button round color="primary" component="span" >
                                  Select a Workflow JSON File
                                </Button>
                              </label>
                            </GridItem>
                          </GridContainer>
                          <br />
                          <br />
                          <GridContainer>
                            <GridItem xs={12} sm={12} md={12}>
                              <b>Selected Workflow File:</b>&nbsp;{workflowFile !== null ? workflowFile.name : ""}
                            </GridItem>
                          </GridContainer>
                        </GridItem>
                      </GridContainer>
                      <br />
                      <hr />
                      <GridContainer>
                        <GridItem xs={12} sm={12} md={12}>
                          <Button
                            round
                            color="primary"
                            type="submit"
                            style={{ float: 'right' }}
                            disabled={workflowFile === null}>
                            Upload workflow File
                          </Button>
                          <Button
                            round
                            color="primary"
                            style={{ float: 'right' }}
                            onClick={handleImportDialogClose.bind(this)}
                          >
                            Cancel
                          </Button>
                        </GridItem>
                      </GridContainer>
                    </form>
                  </CardBody>
                  <CardFooter style={{ textAlign: 'left' }}>
                  </CardFooter>
                </Card>
              </DialogContentText>
            </DialogContent>
            {/*
              <DialogActions>
                <Button onClick={handleImportDialogClose.bind(this)} color="primary" round>
                  Cancel
                </Button>
                <Button onClick={handleImportDialogConfirm.bind(this)} color="primary" round>
                  Upload
                </Button>
              </DialogActions>
              */}
          </Dialog>
        </CardBody>
      </Card >
    </div >
  );
}
export default withStyles(extendedFormsStyle)(WorkflowsComp)
