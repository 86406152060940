import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from "components/Card/CardFooter.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.jsx';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Grid from '@material-ui/core/Grid';
import Switch from "@material-ui/core/Switch";
import withStyles from "@material-ui/core/styles/withStyles";
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import extendedFormsStyle from "assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx";


import {
  INTEGER_NUMBER,
  DECIMAL_NUMBER,
  STRING,
  DATE_STRING,
  EMAIL_STRING,
  NONE
} from 'constants/networkadmin/FieldDataTypes'

const IntgFieldComp = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogButton, setDialogButton] = useState("");
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [dialogType, setDialogType] = useState("add");
  const [intgFieldDataType, setIntgFieldDataType] = useState(NONE);
  const [message, setMessage] = useState("");
  const [id, setID] = useState("");
  const [deptData, setDeptData] = useState([])
  const [userData, setUserData] = useState([])
  const [customData, setCustomData] = useState([])
  const [itemData, setItemData] = useState([])
  const [fieldName, setFieldName] = useState('')
  const [sortOrder, setSortOrder] = useState('')
  const [fieldStatus, setFieldStatus] = useState(false)
  const [currentTab, setCurrentTab] = useState('user')

  const { getUserIntgFields, getCustomIntgFields, getDeptIntgFields,getItemIntgFields } = props;
  useEffect(() => {
    getUserIntgFields();
    getCustomIntgFields();
    getItemIntgFields();
    getDeptIntgFields();
  }, [getUserIntgFields, getCustomIntgFields, getDeptIntgFields,getItemIntgFields])


  useEffect(() => {
    setMessage(props.message)
    if (props.refreshIntgField) {
      props.getUserIntgFields();
      props.getCustomIntgFields();
      props.getDeptIntgFields();
      props.getItemIntgFields();
      setDialogOpen(false)
    }
    var deptRowItems = [];
    var userRowItems = [];
    var customRowItems = [];
    var itemRowItems = [];
    for (let i = 0; i < props.intg_user_field_list.length; i++) {
      var colItems = [];
      colItems.push(i + 1);
      colItems.push(props.intg_user_field_list[i].id);
      colItems.push(props.intg_user_field_list[i].field_name);
      colItems.push(props.intg_user_field_list[i].sort_order);
      colItems.push(props.intg_user_field_list[i].intg_field_data_type);
      colItems.push(props.intg_user_field_list[i].status ? 'Active' : 'Inactive');
      colItems.push(<div><IconButton
        onClick={handleEditClick.bind(this,
          [props.intg_user_field_list[i].id,
          props.intg_user_field_list[i].field_name,
          props.intg_user_field_list[i].sort_order,
          props.intg_user_field_list[i].status,
          props.intg_user_field_list[i].intg_field_data_type,
          ])}
        title="Edit row"> <EditIcon /></IconButton>
        <IconButton onClick={handleDeleteClick.bind(this,
          [props.intg_user_field_list[i].id,
          props.intg_user_field_list[i].field_name,
          props.intg_user_field_list[i].sort_order,
          props.intg_user_field_list[i].status,
          props.intg_user_field_list[i].intg_field_data_type,
          ])} title="Delete row"> <DeleteIcon /></IconButton>
      </div>);
      userRowItems.push(colItems);
    }
    for (let i = 0; i < props.intg_dept_field_list.length; i++) {
      colItems = [];
      colItems.push(i + 1);
      colItems.push(props.intg_dept_field_list[i].id);
      colItems.push(props.intg_dept_field_list[i].field_name);
      colItems.push(props.intg_dept_field_list[i].sort_order);
      colItems.push(props.intg_dept_field_list[i].intg_field_data_type);
      colItems.push(props.intg_dept_field_list[i].status ? 'Active' : 'Inactive');
      colItems.push(<div><IconButton
        onClick={handleEditClick.bind(this,
          [props.intg_dept_field_list[i].id,
          props.intg_dept_field_list[i].field_name,
          props.intg_dept_field_list[i].sort_order,
          props.intg_dept_field_list[i].status,
          props.intg_dept_field_list[i].intg_field_data_type,
          ])}
        title="Edit row"> <EditIcon /></IconButton>
        <IconButton onClick={handleDeleteClick.bind(this,
          [props.intg_dept_field_list[i].id,
          props.intg_dept_field_list[i].field_name,
          props.intg_dept_field_list[i].sort_order,
          props.intg_dept_field_list[i].status,
          props.intg_dept_field_list[i].intg_field_data_type,
          ])} title="Delete row"> <DeleteIcon /></IconButton>
      </div>);
      deptRowItems.push(colItems);
    }
    for (let i = 0; i < props.intg_custom_field_list.length; i++) {
      colItems = [];
      colItems.push(i + 1);
      colItems.push(props.intg_custom_field_list[i].id);
      colItems.push(props.intg_custom_field_list[i].field_name);
      colItems.push(props.intg_custom_field_list[i].sort_order);
      colItems.push(props.intg_custom_field_list[i].intg_field_data_type);
      colItems.push(props.intg_custom_field_list[i].status ? 'Active' : 'Inactive');
      colItems.push(<div><IconButton
        onClick={handleEditClick.bind(this,
          [props.intg_custom_field_list[i].id,
          props.intg_custom_field_list[i].field_name,
          props.intg_custom_field_list[i].sort_order,
          props.intg_custom_field_list[i].status,
          props.intg_custom_field_list[i].intg_field_data_type,
          ])}
        title="Edit row"> <EditIcon /></IconButton>
        <IconButton onClick={handleDeleteClick.bind(this,
          [props.intg_custom_field_list[i].id,
          props.intg_custom_field_list[i].field_name,
          props.intg_custom_field_list[i].sort_order,
          props.intg_custom_field_list[i].status,
          props.intg_custom_field_list[i].intg_field_data_type,
          ])} title="Delete row"> <DeleteIcon /></IconButton>
      </div>);
      customRowItems.push(colItems);
    }
    for (let i = 0; i < props.intg_item_field_list.length; i++) {
      colItems = [];
      colItems.push(i + 1);
      colItems.push(props.intg_item_field_list[i].id);
      colItems.push(props.intg_item_field_list[i].field_name);
      colItems.push(props.intg_item_field_list[i].sort_order);
      colItems.push(props.intg_item_field_list[i].intg_field_data_type);
      colItems.push(props.intg_item_field_list[i].status ? 'Active' : 'Inactive');
      colItems.push(<div><IconButton
        onClick={handleEditClick.bind(this,
          [props.intg_item_field_list[i].id,
          props.intg_item_field_list[i].field_name,
          props.intg_item_field_list[i].sort_order,
          props.intg_item_field_list[i].status,
          props.intg_item_field_list[i].intg_field_data_type,
          ])}
        title="Edit row"> <EditIcon /></IconButton>
        <IconButton onClick={handleDeleteClick.bind(this,
          [props.intg_item_field_list[i].id,
          props.intg_item_field_list[i].field_name,
          props.intg_item_field_list[i].sort_order,
          props.intg_item_field_list[i].status,
          props.intg_item_field_list[i].intg_field_data_type,
          ])} title="Delete row"> <DeleteIcon /></IconButton>
      </div>);
      itemRowItems.push(colItems);
    }
    setDeptData(deptRowItems)
    setUserData(userRowItems)
    setCustomData(customRowItems)
    setItemData(itemRowItems)
  }, [props])

  const handleEditClick = (e) => {
    setID(e[0])
    setFieldName(e[1])
    setSortOrder(e[2])
    setFieldStatus(e[3])
    setIntgFieldDataType(e[4])
    setMessage('')
    setDialogTitle('Edit')
    setDialogButton('Save')
    setFieldDisabled(false)
    setDialogType('update')
    setDialogOpen(true)
  }
  const handleDeleteClick = (e) => {
    setID(e[0])
    setFieldName(e[1])
    setSortOrder(e[2])
    setFieldStatus(e[3])
    setIntgFieldDataType(e[4])
    setMessage('')
    setDialogTitle('Delete')
    setDialogButton('Confirm Delete')
    setFieldDisabled(true)
    setDialogType('delete')
    setDialogOpen(true)
  }
  const handleNewClick = (e) => {
    var maxValueOfSortOrder = 0
    if (currentTab == 'user') {
      maxValueOfSortOrder = Math.max(...props.intg_user_field_list.map((o) => o.sort_order), 0);
    } else if (currentTab == 'dept') {
      maxValueOfSortOrder = Math.max(...props.intg_dept_field_list.map((o) => o.sort_order), 0);
    } else if (currentTab == 'custom') {
      maxValueOfSortOrder = Math.max(...props.intg_custom_field_list.map((o) => o.sort_order), 0);
    } else if (currentTab == 'item') {
      maxValueOfSortOrder = Math.max(...props.intg_item_field_list.map((o) => o.sort_order), 0);
    }
    setSortOrder(maxValueOfSortOrder + 5)
    setFieldName('')
    setFieldStatus(false)
    setIntgFieldDataType(NONE)
    setMessage('')
    setDialogTitle('Add New')
    setDialogButton('Save')
    setFieldDisabled(false)
    setDialogType('add')
    setDialogOpen(true)
  }
  const handleDialogClose = (e) => {
    setDialogOpen(false)
    setMessage('')
  }
  const handleDialogConfirm = (e) => {
    if (dialogType === 'add') {
      props.createIntgField({
        field_name: fieldName,
        sort_order: sortOrder.toString(),
        field_type: currentTab,
        status: fieldStatus ? "1" : "0",
        intg_field_data_type: intgFieldDataType,
      })
    } else if (dialogType === 'update') {
      props.updateIntgField(
        id, {
        field_name: fieldName,
        sort_order: sortOrder.toString(),
        field_type: currentTab,
        status: fieldStatus ? "1" : "0",
        intg_field_data_type: intgFieldDataType,
      })
    } else if (dialogType === 'delete') {
      props.deleteIntgField(id)
    }
  }
  const onFieldNameChange = (e) => {
    setFieldName(e.target.value)
  }
  const onSortOrderChange = (e) => {
    setSortOrder(e.target.value)
  }
  const onFieldStatusSelect = (e) => {
    setFieldStatus(e.target.checked)
  }
  const handleTabChange = (e, newValue) => {
    setCurrentTab(newValue)

  }

  const onFieldDataTypeChange = (e) => {
    setIntgFieldDataType(e.target.value)
  }

  const { classes } = props;
  const columns = [
    "Serial",
    "ID",
    "Name",
    "Sort Order",
    "Data Type",
    "Status",
    ""
  ];
  const options = {
    filterType: "dropdown",
    responsive: "standard",
    filter: true,
    selectableRows: "none",
    rowsPerPage: 100,
    selectToolbarPlacement: "none"
  };
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Paper square>
        <Tabs
          value={currentTab}
          indicatorColor="primary"
          textColor="primary"
          onChange={handleTabChange.bind(this)}
          centered
        >
          <Tab label="User" value="user" />
          <Tab label="Department" value="dept" />
          <Tab label="Custom" value="custom" />
          <Tab label="Item" value="item" />
        </Tabs>
      </Paper>
      <div style={{ display: currentTab === 'user' ? "block" : "none" }} >
        <Card>
          <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
            <b>User Integration Field</b>
          </CardHeader>
          <CardBody>
            <MUIDataTable
              title={<Button onClick={handleNewClick.bind(this)} color="primary" title="Create a new Field" round > New Field </Button>}
              data={userData}
              columns={columns}
              options={options}
            />
          </CardBody>
        </Card>
      </div>
      <div style={{ display: currentTab === 'dept' ? "block" : "none" }} >
        <Card>
          <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
            <b>Department Integration Field</b>
          </CardHeader>
          <CardBody>
            <MUIDataTable
              title={<Button onClick={handleNewClick.bind(this)} color="primary" title="Create a new Field" round > New Field </Button>}
              data={deptData}
              columns={columns}
              options={options}
            />
          </CardBody>
        </Card>
      </div>
      <div style={{ display: currentTab === 'custom' ? "block" : "none" }} >
        <Card>
          <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
            <b>Custom Integration Field</b>
          </CardHeader>
          <CardBody>
            <MUIDataTable
              title={<Button onClick={handleNewClick.bind(this)} color="primary" title="Create a new Field" round > New Field </Button>}
              data={customData}
              columns={columns}
              options={options}
            />
          </CardBody>
        </Card>
      </div>
      <div style={{ display: currentTab === 'item' ? "block" : "none" }} >
        <Card>
          <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
            <b>Item Integration Field</b>
          </CardHeader>
          <CardBody>
            <MUIDataTable
              title={<Button onClick={handleNewClick.bind(this)} color="primary" title="Create a new Field" round > New Field </Button>}
              data={itemData}
              columns={columns}
              options={options}
            />
          </CardBody>
        </Card>
      </div>
      <Dialog fullWidth={true} open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
        <DialogContent>
          <Card>
            <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
              <b>{dialogTitle}</b>
            </CardHeader>
            <CardBody>
              <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required={true}
                    fullWidth={true}
                    label="Name"
                    value={fieldName}
                    onChange={onFieldNameChange.bind(this)}
                    disabled={fieldDisabled}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <TextField
                    required={true}
                    fullWidth={true}
                    label="Sort Order"
                    value={sortOrder}
                    onChange={onSortOrderChange.bind(this)}
                    disabled={fieldDisabled}
                  />
                </GridItem>
              </GridContainer>
              <br />
              <GridContainer>
                <GridItem xs={12} sm={12} md={6}>
                  <InputLabel id="statusLabel" shrink={true}>Status</InputLabel>
                  <Grid item={true} component="label" container alignItems="center" spacing={1}>
                    <Grid item>Inactive</Grid>
                    <Grid item>
                      <Switch
                        labelId="statusLabel"
                        checked={fieldStatus}
                        onChange={onFieldStatusSelect.bind(this)}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        disabled={fieldDisabled}
                        classes={{
                          switchBase: classes.switchBase,
                          checked: classes.switchChecked,
                          icon: classes.switchIcon,
                          iconChecked: classes.switchIconChecked,
                          bar: classes.switchBar
                        }}
                      />
                    </Grid>
                    <Grid item>Active</Grid>
                  </Grid>
                </GridItem>
                <GridItem xs={12} sm={12} md={6}>
                  <FormControl fullWidth={true} className={classes.formControl} disabled={fieldDisabled}>
                    <InputLabel id="dataTypeLabel">Field Data Type</InputLabel>
                    <Select
                      labelid="dataTypeLabel"
                      id="dataTypeSelect"
                      value={intgFieldDataType}
                      onChange={onFieldDataTypeChange.bind(this)}
                    >
                      <MenuItem value={INTEGER_NUMBER}>Integer Number</MenuItem>
                      <MenuItem value={DECIMAL_NUMBER}>Decimal Number</MenuItem>
                      <MenuItem value={STRING}>String</MenuItem>
                      <MenuItem value={DATE_STRING}>Date String</MenuItem>
                      <MenuItem value={EMAIL_STRING}>Email String</MenuItem>
                      <MenuItem value={NONE}>None</MenuItem>
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>

            </CardBody>
            <CardFooter style={{ textAlign: 'left' }}>
            </CardFooter>
          </Card>
        </DialogContent>
        <br />
        <DialogActions>
          <Button onClick={handleDialogClose.bind(this)} color="primary" round>
            Cancel
          </Button>
          <Button onClick={handleDialogConfirm.bind(this)} color="primary" round>
            {dialogButton}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
export default withStyles(extendedFormsStyle)(IntgFieldComp)
