import {
  GET_USER_INTG_REQUEST,
  GET_USER_INTG_SUCCESS,
  GET_USER_INTG_FAILURE,
  GET_CUSTOM_INTG_FIELD_REQUEST,
  GET_CUSTOM_INTG_FIELD_SUCCESS,
  GET_CUSTOM_INTG_FIELD_FAILURE,
  GET_ITEM_INTG_FIELD_REQUEST,
  GET_ITEM_INTG_FIELD_SUCCESS,
  GET_ITEM_INTG_FIELD_FAILURE,
  GET_DEPT_INTG_REQUEST,
  GET_DEPT_INTG_SUCCESS,
  GET_DEPT_INTG_FAILURE,
  GET_SYS_USER_REQUEST,
  GET_SYS_USER_SUCCESS,
  GET_SYS_USER_FAILURE,
  POST_INTG_REQUEST,
  POST_INTG_SUCCESS,
  POST_INTG_FAILURE,
  PATCH_INTG_REQUEST,
  PATCH_INTG_SUCCESS,
  PATCH_INTG_FAILURE,
  DELETE_INTG_REQUEST,
  DELETE_INTG_SUCCESS,
  DELETE_INTG_FAILURE,
  GET_BATCH_INTG_REQUEST,
  GET_BATCH_INTG_SUCCESS,
  GET_BATCH_INTG_FAILURE,
  GET_BATCH_INTG_LOG_REQUEST,
  GET_BATCH_INTG_LOG_SUCCESS,
  GET_BATCH_INTG_LOG_FAILURE,
  POST_FILE_UPLOAD_REQUEST,
  POST_FILE_UPLOAD_SUCCESS,
  POST_FILE_UPLOAD_FAILURE,
  POST_CUSTOM_FILE_UPLOAD_REQUEST,
  POST_CUSTOM_FILE_UPLOAD_SUCCESS,
  POST_CUSTOM_FILE_UPLOAD_FAILURE,
  POST_ITEM_FILE_UPLOAD_REQUEST,
  POST_ITEM_FILE_UPLOAD_SUCCESS,
  POST_ITEM_FILE_UPLOAD_FAILURE,
} from '../constants/networkadmin/Integration';


const initialState = {
  requesting: false,
  message: '',
  intg_dept_field_list: [],
  intg_item_field_list: [],
  intg_user_field_list: [],
  intg_custom_field_list: [],
  system_user: '',
  intg_batch_list: [],
  intg_batch_log_list: [],
  refreshIntgField: false,
  refreshIntgBatch: false,
  message_userdept: "",
  message_custom: "",
  message_item: "",
  error_userdept: false,
  error_custom: false,
  error_item: false,
};

export default function integration(state = initialState, action) {
  switch (action.type) {
    case GET_BATCH_INTG_LOG_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshIntgBatch: false,
        message: '',
      };

    case GET_BATCH_INTG_LOG_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        intg_batch_log_list: action.payload.data,
      };

    case GET_BATCH_INTG_LOG_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_BATCH_INTG_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshIntgBatch: false,
        message: '',
      };

    case GET_BATCH_INTG_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        intg_batch_list: action.payload.data,
      };

    case GET_BATCH_INTG_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_SYS_USER_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshIntgField: false,
        message: '',
      };

    case GET_SYS_USER_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        system_user: action.payload.data,
      };

    case GET_SYS_USER_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_USER_INTG_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshIntgField: false,
        message: '',
      };

    case GET_USER_INTG_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        intg_user_field_list: action.payload.data,
      };

    case GET_USER_INTG_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_CUSTOM_INTG_FIELD_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshIntgField: false,
        message: '',
      };

    case GET_CUSTOM_INTG_FIELD_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        intg_custom_field_list: action.payload.data,
      };

    case GET_CUSTOM_INTG_FIELD_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_DEPT_INTG_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshIntgField: false,
        message: '',
      };

    case GET_DEPT_INTG_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        intg_dept_field_list: action.payload.data,
      };

    case GET_DEPT_INTG_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_ITEM_INTG_FIELD_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshIntgField: false,
        message: '',
      };

    case GET_ITEM_INTG_FIELD_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        intg_item_field_list: action.payload.data,
      };

    case GET_ITEM_INTG_FIELD_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case POST_FILE_UPLOAD_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshIntgBatch: false,
      };

    case POST_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        error_userdept: false,
        error_custom: false,
        error_item: false,
        message_userdept: "Successfully started User and Department files upload batch process",
        message_custom: "",
        message_item: "",
        refreshIntgBatch: true,
      };

    case POST_FILE_UPLOAD_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        message_userdept: action.payload.message,
        error_userdept: true,
        error_custom: false,
        error_item: false,
      };
    case POST_CUSTOM_FILE_UPLOAD_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshIntgBatch: false,
      };

    case POST_CUSTOM_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        error_userdept: false,
        error_custom: false,
        error_item: false,
        message_userdept: "",
        message_custom: "Successfully started Custom file upload batch process",
        message_item: "",
        refreshIntgBatch: true,
      };

    case POST_CUSTOM_FILE_UPLOAD_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        message_custom: action.payload.message,
        error_userdept: false,
        error_custom: true,
        error_item: false,
      };
    case POST_ITEM_FILE_UPLOAD_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshIntgBatch: false,
      };

    case POST_ITEM_FILE_UPLOAD_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        error_userdept: false,
        error_custom: false,
        error_item: false,
        message_userdept: "",
        message_custom: "",
        message_item: "Successfully started Item file upload batch process",
        refreshIntgBatch: true,
      };

    case POST_ITEM_FILE_UPLOAD_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        message_item: action.payload.message,
        error_userdept: false,
        error_custom: false,
        error_item: true,
      };
    case POST_INTG_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshIntgField: false,
      };

    case POST_INTG_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshIntgField: true,
      };

    case POST_INTG_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case PATCH_INTG_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshIntgField: false,
      };

    case PATCH_INTG_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshIntgField: true,
      };

    case PATCH_INTG_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case DELETE_INTG_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshIntgField: false,
      };

    case DELETE_INTG_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshIntgField: true,
      };

    case DELETE_INTG_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
