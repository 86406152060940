export const GET_APPLICATION_STATUS_REQUEST = 'GET_APPLICATION_STATUS_REQUEST';
export const GET_APPLICATION_STATUS_SUCCESS = 'GET_APPLICATION_STATUS_SUCCESS';
export const GET_APPLICATION_STATUS_FAILURE = 'GET_APPLICATION_STATUS_FAILURE';
export const GET_APPLICATION_LOG_REQUEST = 'GET_APPLICATION_LOG_REQUEST';
export const GET_APPLICATION_LOG_SUCCESS = 'GET_APPLICATION_LOG_SUCCESS';
export const GET_APPLICATION_LOG_FAILURE = 'GET_APPLICATION_LOG_FAILURE';
export const GET_ROLE_STATUS_REQUEST = 'GET_ROLE_STATUS_REQUEST';
export const GET_ROLE_STATUS_SUCCESS = 'GET_ROLE_STATUS_SUCCESS';
export const GET_ROLE_STATUS_FAILURE = 'GET_ROLE_STATUS_FAILURE';
export const SET_CURRENT_ROLE_TITLE = 'SET_CURRENT_ROLE_TITLE';
export const PATCH_APPLICATION_REMIND_REQUEST = 'PATCH_APPLICATION_REMIND_REQUEST';
export const PATCH_APPLICATION_REMIND_SUCCESS = 'PATCH_APPLICATION_REMIND_SUCCESS';
export const PATCH_APPLICATION_REMIND_FAILURE = 'PATCH_APPLICATION_REMIND_FAILURE';
export const PATCH_APPLICATION_ESCALATE_REQUEST = 'PATCH_APPLICATION_ESCALATE_REQUEST';
export const PATCH_APPLICATION_ESCALATE_SUCCESS = 'PATCH_APPLICATION_ESCALATE_SUCCESS';
export const PATCH_APPLICATION_ESCALATE_FAILURE = 'PATCH_APPLICATION_ESCALATE_FAILURE';
export const GET_APPLICATION_COMPLETED_TASK_LIST_REQUEST = 'GET_APPLICATION_COMPLETED_TASK_LIST_REQUEST';
export const GET_APPLICATION_COMPLETED_TASK_LIST_SUCCESS = 'GET_APPLICATION_COMPLETED_TASK_LIST_SUCCESS';
export const GET_APPLICATION_COMPLETED_TASK_LIST_FAILURE = 'GET_APPLICATION_COMPLETED_TASK_LIST_FAILURE';