import React, { useState, useEffect } from 'react';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import { SortComp } from './UnitFieldComp/FormFunctions.jsx';
import { LabelComp } from './UnitFieldComp/FormFunctions.jsx';
import { AliasComp } from './UnitFieldComp/FormFunctions.jsx';
import { ColspanComp } from './UnitFieldComp/FormFunctions.jsx';
import { IsReadOnlyComp } from './UnitFieldComp/FormFunctions.jsx';
import { RequiredComp } from './UnitFieldComp/FormFunctions.jsx';
import { DataTypeComp } from './UnitFieldComp/FormFunctions.jsx';
import { ErrorMessageComp } from './UnitFieldComp/FormFunctions.jsx';
import { DefaultValueComp } from './UnitFieldComp/FormFunctions.jsx';
import { HelpTextComp } from './UnitFieldComp/FormFunctions.jsx';
import { UpdateIntgComp } from './UnitFieldComp/FormFunctions.jsx';
import { ItemIntgFieldComp } from './UnitFieldComp/FormFunctions.jsx';
import { ItemRelationLevelComp } from './UnitFieldComp/FormFunctions.jsx';
import { RelatedItemSearchComp } from './UnitFieldComp/FormFunctions.jsx';
import { ValidateRuleComp } from './UnitFieldComp/FormFunctions.jsx';
import { RequiredFieldRuleComp } from './UnitFieldComp/FormFunctions.jsx';
import { VisibleFieldRuleComp } from './UnitFieldComp/FormFunctions.jsx';
import { IntgComp } from './UnitFieldComp/FormFunctions.jsx';
import { UserIntgComp } from './UnitFieldComp/FormFunctions.jsx';
import { IntegrationFieldComp } from './UnitFieldComp/FormFunctions.jsx';
import { ItemIndexComp } from './UnitFieldComp/FormFunctions.jsx';
import { DepartmentComp } from './UnitFieldComp/FormFunctions.jsx';
import { UseAsVariableComp } from './UnitFieldComp/FormFunctions.jsx';
import { UseAsVariableNameComp } from './UnitFieldComp/FormFunctions.jsx';
import { RelatedFieldComp } from './UnitFieldComp/FormFunctions.jsx';
import { DatatableRuleFieldComp } from './UnitFieldComp/FormFunctions.jsx';

function ItemOptionDetailsFieldComp(props) {
	return (
		<div>
			<GridContainer>
				<GridItem xs={12} sm={12} md={8}>
					<LabelComp
						fieldDisabled={props.fieldDisabled}
						label={props.label}
						onChangeLabel={props.onChangeLabel}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={4}>
					<SortComp
						fieldDisabled={props.fieldDisabled}
						sortOrder={props.sortOrder}
						onChangeSortOrder={props.onChangeSortOrder}
					/>
				</GridItem>
			</GridContainer>
			<br />
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<AliasComp
						fieldDisabled={props.fieldDisabled}
						alias={props.alias}
						onChangeAlias={props.onChangeAlias}
					/>
				</GridItem>
			</GridContainer>
			<br />
			<GridContainer>
				<GridItem xs={12} sm={12} md={6}>
					<ColspanComp
						fieldDisabled={props.fieldDisabled}
						classes={props.classes}
						colspan={props.colspan}
						onChangeColspan={props.onChangeColspan}
					/>
				</GridItem>
			</GridContainer>
			<br />
			<GridContainer>
				<GridItem xs={12} sm={12} md={6}>
					<RequiredComp
						fieldDisabled={props.fieldDisabled}
						classes={props.classes}
						required={props.required}
						onChangeRequired={props.onChangeRequired}
					/>
				</GridItem>
			</GridContainer>
			<br />
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<ErrorMessageComp
						fieldDisabled={props.fieldDisabled}
						errorMessage={props.errorMessage}
						onChangeErrorMessage={props.onChangeErrorMessage}
					/>
				</GridItem>
			</GridContainer>
			<br />
			<GridContainer>
				<GridItem xs={12} sm={12} md={12}>
					<HelpTextComp
						fieldDisabled={props.fieldDisabled}
						type={props.type}
						helpText={props.helpText}
						onChangeHelpText={props.onChangeHelpText}
					/>
				</GridItem>
			</GridContainer>
			<br />
			<GridContainer>
				<GridItem xs={12} sm={12} md={6}>
					<ItemIntgFieldComp
						fieldDisabled={props.fieldDisabled}
						classes={props.classes}
						intgFieldID={props.intgFieldID}
						onChangeIntgFieldID={props.onChangeIntgFieldID}
						field_item_intg_list={props.field_item_intg_list}
					/>
				</GridItem>
			</GridContainer>
			<br />
			<GridContainer>
				<GridItem xs={12} sm={12} md={6}>
					<ItemRelationLevelComp
						fieldDisabled={props.fieldDisabled}
						classes={props.classes}
						itemRelationLevel={props.itemRelationLevel}
						onChangeItemRelationLevel={props.onChangeItemRelationLevel}
					/>
				</GridItem>
				<GridItem xs={12} sm={12} md={6}>
					<RelatedItemSearchComp
						fieldDisabled={props.fieldDisabled}
						classes={props.classes}
						id={props.id}
						relatedFieldID={props.relatedFieldID}
						onChangeRelatedFieldID={props.onChangeRelatedFieldID}
						field_list={props.field_list}
					/>
				</GridItem>
			</GridContainer>
		</div>);
}


export { ItemOptionDetailsFieldComp as ItemOptionDetailsFieldComp }
