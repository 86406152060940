import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as lookupActions from 'actions/LookupActions';
import * as applicationDetailActions from 'actions/ApplicationDetailActions';
import FileSharingComp from './FileSharing/FileSharingComp';

const FileSharing = (props) => {
  const { classes } = props;
  const { lookup } = props;
  const { setShareFile, getFileSharingServer } = props.lookupActions;
  const {
    postApplicationDetail,
  } = props.applicationDetailActions;

  return (
    <FileSharingComp
      getFileSharingServer={getFileSharingServer}
      application_id={props.application_id}
      field_committee_id={props.field_committee_id}
      setShareFile={setShareFile}
      // user={lookup.user}
      field_role_id={props.field_role_id}
      postApplicationDetail={postApplicationDetail}
      role_id={props.role_id}
      field_id={props.field_id}
      task_id={props.task_id}
      form_id={props.form_id}
      share_file={lookup.share_file}
      form_checkable={props.form_checkable}
      application_status={props.application_status}
      task_completed={props.task_completed}
      field_required={props.field_required}
      field_value={props.field_value === '' || props.field_value === undefined ? [] : props.field_value}
      field_label={props.field_label}
      filesharing_list={lookup.filesharing_list === undefined ? [] : lookup.filesharing_list}
      fields={props.fields}
      classes={classes}
    />
  );
}

function mapStateToProps(state) {
  return {
    lookup: state.lookup,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    lookupActions: bindActionCreators(lookupActions, dispatch),
    applicationDetailActions: bindActionCreators(applicationDetailActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FileSharing);
