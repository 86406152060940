import {
	GET_DASHBOARD_REQUEST,
	GET_DASHBOARD_SUCCESS,
	GET_DASHBOARD_FAILURE,
	GET_UNSENT_ERROR_EMAIL_REQUEST,
	GET_UNSENT_ERROR_EMAIL_SUCCESS,
	GET_UNSENT_ERROR_EMAIL_FAILURE,
	POST_RESEND_EMAIL_REQUEST,
	POST_RESEND_EMAIL_SUCCESS,
	POST_RESEND_EMAIL_FAILURE,
	POST_NETWORK_BACKUP_REQUEST,
	POST_NETWORK_BACKUP_SUCCESS,
	POST_NETWORK_BACKUP_FAILURE,
	GET_NETWORK_BACKUP_REQUEST,
	GET_NETWORK_BACKUP_SUCCESS,
	GET_NETWORK_BACKUP_FAILURE,
	POST_NETWORK_RESTORE_REQUEST,
	POST_NETWORK_RESTORE_SUCCESS,
	POST_NETWORK_RESTORE_FAILURE,
	GET_SENT_EMAIL_REQUEST,
	GET_SENT_EMAIL_SUCCESS,
	GET_SENT_EMAIL_FAILURE,
} from '../constants/networkadmin/Dashboard';

const initialState = {
	requesting: false,
	message: '',
	unsent_error_email_list: '',
	dashboard_data: '',
	network_backup_list: [],
	refreshNetworkBackupList: false,
	refreshUnsentErrorEmail: false,
	sent_email_list: [],
};

export default function dashboard(state = initialState, action) {
	switch (action.type) {

		case POST_NETWORK_RESTORE_REQUEST:
			return {
				...state,
				requesting: action.payload.requesting,
				message: '',
			};

		case POST_NETWORK_RESTORE_SUCCESS:
			localStorage.clear()
			window.location.reload();
			return {
				...state,
				requesting: action.payload.requesting,
				message: action.payload.message,

			};

		case POST_NETWORK_RESTORE_FAILURE:
			localStorage.clear()
			window.location.reload();
			return {
				...state,
				requesting: action.payload.requesting,
				message: action.payload.message,
			};

		case GET_NETWORK_BACKUP_REQUEST:
			return {
				...state,
				requesting: action.payload.requesting,
				refreshNetworkBackupList: false,
				message: '',
			};

		case GET_NETWORK_BACKUP_SUCCESS:
			return {
				...state,
				requesting: action.payload.requesting,
				message: action.payload.message,
				network_backup_list: action.payload.data,
			};

		case GET_NETWORK_BACKUP_FAILURE:
			return {
				...state,
				requesting: action.payload.requesting,
				message: action.payload.message,
			};
		case POST_NETWORK_BACKUP_REQUEST:
			return {
				...state,
				requesting: action.payload.requesting,
				message: '',
			};

		case POST_NETWORK_BACKUP_SUCCESS:
			return {
				...state,
				requesting: action.payload.requesting,
				message: action.payload.message,
				refreshNetworkBackupList: true,
			};

		case POST_NETWORK_BACKUP_FAILURE:
			return {
				...state,
				requesting: action.payload.requesting,
				message: action.payload.message,
			};

		case GET_DASHBOARD_REQUEST:
			return {
				...state,
				requesting: action.payload.requesting,
				message: '',
			};

		case GET_DASHBOARD_SUCCESS:
			return {
				...state,
				requesting: action.payload.requesting,
				message: action.payload.message,
				dashboard_data: action.payload.data,
			};

		case GET_DASHBOARD_FAILURE:
			return {
				...state,
				requesting: action.payload.requesting,
				message: action.payload.message,
			};

		case GET_UNSENT_ERROR_EMAIL_REQUEST:
			return {
				...state,
				requesting: action.payload.requesting,
				refreshUnsentErrorEmail: false,
				message: '',
			};

		case GET_UNSENT_ERROR_EMAIL_SUCCESS:
			return {
				...state,
				requesting: action.payload.requesting,
				message: action.payload.message,
				unsent_error_email_list: action.payload.data,
			};

		case GET_UNSENT_ERROR_EMAIL_FAILURE:
			return {
				...state,
				requesting: action.payload.requesting,
				message: action.payload.message,
			};

		case GET_SENT_EMAIL_REQUEST:
			return {
				...state,
				requesting: action.payload.requesting,
				message: '',
			};

		case GET_SENT_EMAIL_SUCCESS:
			return {
				...state,
				requesting: action.payload.requesting,
				message: action.payload.message,
				sent_email_list: action.payload.data,
			};

		case GET_SENT_EMAIL_FAILURE:
			return {
				...state,
				requesting: action.payload.requesting,
				message: action.payload.message,
			};

		case POST_RESEND_EMAIL_REQUEST:
			return {
				...state,
				requesting: action.payload.requesting,
				message: '',
				refreshUnsentErrorEmail: false,
			};

		case POST_RESEND_EMAIL_SUCCESS:
			return {
				...state,
				requesting: action.payload.requesting,
				message: action.payload.message,
				refreshUnsentErrorEmail: true,
			};

		case POST_RESEND_EMAIL_FAILURE:
			return {
				...state,
				requesting: action.payload.requesting,
				message: action.payload.message,
			};

		default:
			return state;
	}
}
