import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import Button from 'components/CustomButtons/Button.jsx';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import * as userActions from 'actions/admin/UserActions';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Assignment from '@mui/icons-material/Assignment';
import UserIntgComp from './UserIntg/UserIntgComp';

const UserIntg = (props) => {
  const { classes } = props;
  const { user, defaultStates } = props;
  const {
    getUserIntgs,
  } = props.userActions;
  const { id } = useParams();

  return (
    <div>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/admin/users">
          <Button size="sm" round color="primary">
            {' '}
            <Assignment />
            User
          </Button>
        </Link>
        <Link to="#">
          <Button size="sm" round color="primary" block> </Button>
        </Link>
      </Breadcrumbs>
      <br />
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <UserIntgComp
        user_id={id}
        getUserIntgs={getUserIntgs}
        user_intg_list={user.user_intg_list === undefined ? [] : user.user_intg_list}
        requesting={user.requesting}
        message={user.message}
        error_code={user.error_code}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.adUser,
    defaultStates: state.adDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(UserIntg);
