import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import MUIDataTable from 'mui-datatables';
import Table from '@material-ui/core/Table';
import TableContainer from "@material-ui/core/TableContainer";
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from "@material-ui/core/Paper";
import Button from 'components/CustomButtons/Button.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import AltRouteIcon from '@mui/icons-material/AltRoute';
import Chip from '@mui/material/Chip';


import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import withRoot from 'withRoot';
const moment = require('moment');

const ApplicationStatusComp = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [data, setData] = useState([]);
  const [id, setID] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [ccEmail, setCCEmail] = useState("");
  const [remindMessage, setRemindMessage] = useState("");
  const [remindDate, setRemindDate] = useState("");
  const [message, setMessage] = useState("");
  const [applicationStatusList, setApplicationStatusList] = useState([]);
  const [applicationCompletedTaskList, setApplicationCompletedTaskList] = useState([]);
  const [dialogEscalateOpen, setDialogEscalateOpen] = useState(false);
  const [escalateMonths, setEscalateMonths] = useState("0");
  const [escalateDays, setEscalateDays] = useState("0");
  const [escalateHours, setEscalateHours] = useState("0");
  const [escalateNewDate, setEscalateNewDate] = useState("");
  const { t } = useTranslation();

  const {
    getApplicationStatus, getApplicationLog, getApplication, getRole, application_id, role_id, getApplicationCompletedTaskList,
  } = props;
  useEffect(() => {
    getApplicationStatus(application_id, role_id);
    getApplicationCompletedTaskList(application_id, role_id);
    getApplicationLog(application_id, role_id);
    getApplication(application_id);
    getRole(role_id);
  }, [getApplicationStatus, getApplicationLog, getApplication, getRole, application_id, role_id, getApplicationCompletedTaskList]);

  useEffect(() => {
    if (props.refreshApplicationStatus) {
      getApplicationStatus(application_id, role_id);
      setDialogOpen(false);
    }
    setApplicationStatusList(props.application_status_list);
    setApplicationCompletedTaskList(props.completed_task_list);

    console.log("COMPLETED_TASK_LIST 1")
    console.log(props.completed_task_list)
    console.log("COMPLETED_TASK_LIST 2")

    var rowItems = [];
    for (let i = 0; i < props.application_log.length; i++) {
      var colItems = [];
      var date = new Date(props.application_log[i].action_time);
      colItems.push(props.application_log[i].task_alias === '' ? props.application_log[i].task_title : props.application_log[i].task_alias);
      colItems.push(`${props.application_log[i].actor === 'hidden' ? '-' : props.application_log[i].actor + ' ' + (props.application_log[i].actor_email !== '' ? '(' + props.application_log[i].actor_email + ')' : '')} (${props.application_log[i].role_alias === '' ? props.application_log[i].role_title : props.application_log[i].role_alias})`);
      colItems.push(`${props.application_log[i].actor_department === 'hidden' ? '-' : props.application_log[i].actor_department}`);
      colItems.push(props.application_log[i].action_title);
      colItems.push(date.toLocaleString(localStorage.getItem('network_lang'), { weekday: "short", year: "numeric", month: "2-digit", day: "numeric", hour: "2-digit", minute: "2-digit" }))
      colItems.push(props.application_log[i].task_log);
      rowItems.push(colItems);
    }
    setData(rowItems);
    props.setCurrentRoleTitle((props.role.alias === '' ? props.role.title : props.role.alias));
  }, [props]);

  const handleEditReminderClick = (e) => {
    setMessage("");
    setID(e[0]);
    setName(e[1]);
    setEmail(e[2]);
    setCCEmail(e[3]);
    setRemindMessage(e[4]);
    setRemindDate(e[5]);
    setDialogOpen(true);
  };

  const handleDialogClose = (e) => {
    setDialogOpen(false);
    setMessage("")
  };
  const handleDialogConfirm = (e) => {
    if (remindMessage.trim() === "") {
      setMessage("invalid reminder message")
      return
    }
    if (!props.requesting) {
      props.patchApplicationRemind(props.application_id, id, {
        remind_message: remindMessage,
        remind_date: remindDate,
        send_now: "1",
      })
    }
  };
  const handleUnscheduleReminder = (e) => {
    if (remindMessage.trim() === "") {
      setMessage("invalid reminder message")
      return
    }
    if (!props.requesting) {
      props.patchApplicationRemind(props.application_id, id, {
        remind_message: remindMessage,
        remind_date: "",
        send_now: "0",
      })
    }
  };
  const handlescheduleReminder = (e) => {
    if (remindMessage.trim() === "") {
      setMessage("invalid reminder message")
      return
    }
    if (remindDate.trim() === "") {
      setMessage("invalid reminder date")
      return
    }
    if (!props.requesting) {
      props.patchApplicationRemind(props.application_id, id, {
        remind_message: remindMessage,
        remind_date: remindDate,
        send_now: "0",
      })
    }
  };
  const onRemindMessageChange = (e) => {
    setRemindMessage(e.target.value);
  };
  const onRemindDateChange = (e) => {
    setRemindDate(e.target.value);
  };

  const handleDialogEscalateClose = (e) => {
    setDialogEscalateOpen(false);
  };
  const handleDialogEscalateConfirm = (e) => {
    setDialogEscalateOpen(false);
    if (!props.requesting) {
      props.patchApplicationEscalate(props.application_id, id, {
        escalate_date: escalateNewDate,
      })
    }
  };

  const handleDialogRemoveEscalate = (e) => {
    setDialogEscalateOpen(false);
    if (!props.requesting) {
      props.patchApplicationEscalate(props.application_id, id, {
        escalate_date: "",
      })
    }
  };

  const handleEscalateClick = (e) => {
    setID(e[0]);
    setEscalateNewDate(e[1]);
    setDialogEscalateOpen(true);
  };
  const handleConverDateClick = (e) => {
    const currentDate = moment();
    const newDate = currentDate.add({
      months: escalateMonths,
      days: escalateDays,
      hours: escalateHours
    });

    const formattedDate = newDate.format("YYYY-MM-DD HH:mm:ss");
    setEscalateNewDate(formattedDate)
  };

  const onEscalateMonthsChange = (e) => {
    setEscalateMonths(e.target.value);
  };
  const onEscalateDaysChange = (e) => {
    setEscalateDays(e.target.value);
  };
  const onEscalateHoursChange = (e) => {
    setEscalateHours(e.target.value);
  };
  const onEscalateNewDateChange = (e) => {
    const newDate = moment(e.target.value);
    const formattedDate = newDate.format("YYYY-MM-DD HH:mm:ss");
    setEscalateNewDate(formattedDate)
  };
  const monthNames = ["One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine", "Ten", "Eleven", "Twelve"];
  const columns = [
    t('app_status_tracking_task'),
    t('app_status_tracking_person'),
    t('app_status_tracking_dept'),
    t('app_status_tracking_action'),
    t('app_status_tracking_action_time'),
    {
      name: 'task_logs',
      label: '',
      options: {
        filter: false,
        sort: false,
        display: false,
      },
    },
  ];
  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    filter: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    expandableRows: props.role.show_msgs_in_status,
    renderExpandableRow: (rowData, rowMeta) => {
      return (
        <React.Fragment>
          <tr>
            <td colSpan={6}>
              <TableContainer component={Paper}>
                <Table style={{ minWidth: "650" }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Messages</TableCell>
                      <TableCell>Reminders</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rowData[5].map(row => (
                      <TableRow key={row.action_id}>
                        {/*<TableCell component="th" scope="row"> {"row.name"} </TableCell>*/}
                        <TableCell>{row.message}</TableCell>
                        <TableCell align="center">{row.reminders}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </td>
          </tr>

        </React.Fragment>
      );
    },
    selectToolbarPlacement: 'none',
    textLabels: {
      body: {
        noMatch: t('mui_table_no_match'),
        toolTip: t('mui_table_tool_tip'),
        columnHeaderTooltip: column => t('mui_table_header_tool_tip') + " " + column.label
      },
      pagination: {
        next: t('mui_table_next'),
        previous: t('mui_table_previous'),
        rowsPerPage: t('mui_table_rows_per_page'),
        displayRows: t('mui_table_display_rows'),
      },
      toolbar: {
        search: t('mui_table_search'),
        downloadCsv: t('mui_table_download_csv'),
        print: t('mui_table_print'),
        viewColumns: t('mui_table_view_columns'),
        filterTable: t('mui_table_filter_table'),
      },
      filter: {
        all: t('mui_table_filter_all'),
        title: t('mui_table_filter_title'),
        reset: t('mui_table_filter_reset'),
      },
      viewColumns: {
        title: t('mui_table_column_title'),
        titleAria: t('mui_table_column_title_aria'),
      },
      selectedRows: {
        text: t('mui_table_selected_text'),
        delete: t('mui_table_selected_delete'),
        deleteAria: t('mui_table_selected_delete_aria'),
      },
    }
  };
  const rowItems = [];
  var i = 1;
  applicationStatusList.forEach((element) => {
    var status = '';
    if (!element.completed) {
      status = 'Pending';
    }
    if (parseInt(element.role_id, 10) === parseInt(props.role_id, 10)) {
      status = <Link to={`/detail/${props.application_id}/${props.role_id}`}> {t('app_status_active_task_current_user')} </Link>;
    }
    console.log("Khalid Omair ", element.escalate_action_id)
    rowItems.push(
      <TableRow key={element.id}>
        <TableCell>{i}</TableCell>
        {!props.role.hide_menu ?
          <TableCell>
            {element.user_name === 'hidden' ? '-' : element.user_name}
            <br />
            {element.user_email === 'hidden' ? '-' : element.user_email}
            <br />
            {element.user_rank === 'hidden' ? '-' : element.user_rank === "NA" ? "" : element.user_rank}
            <br />
            {element.user_dept_name === 'hidden' ? '-' : element.user_dept_alias === "" ? element.user_dept_name : element.user_dept_alias}
          </TableCell>
          : ""}
        <TableCell>{element.role_alias === '' ? element.role_title : element.role_alias}</TableCell>
        <TableCell>{element.task_alias === '' ? element.task_title : element.task_alias}</TableCell>
        <TableCell>{element.app_name}</TableCell>
        <TableCell>
          <br />
          {!props.application.application_closed ?
            <div style={{ color: status === 'Pending' ? 'red' : 'blue' }}>{status === 'Pending' ? t('app_status_active_task_pending') : t('app_status_active_task_current_user')}</div>
            : ""}
          <br />
          {element.remind_message !== "" && props.role.allow_send_remind ? t('app_status_active_task_msg_sent_date') + " " + new Date(element.sent_date).toLocaleString(localStorage.getItem('network_lang'), { weekday: "short", year: "numeric", month: "2-digit", day: "numeric", hour: "2-digit", minute: "2-digit" }) : ""}
          <br />
          {element.remind_message !== "" && props.role.allow_send_remind && element.remind_date !== "" ? t('app_status_active_remind_date') + " " + new Date(element.remind_date).toLocaleString(localStorage.getItem('network_lang'), { weekday: "short", year: "numeric", month: "2-digit", day: "numeric" }) : ""}
          <br />
          {element.remind_message !== "" && props.role.allow_send_remind && element.last_remind_date !== "" ? t('app_status_active_last_remind_date') + " " + new Date(element.last_remind_date).toLocaleString(localStorage.getItem('network_lang'), { weekday: "short", year: "numeric", month: "2-digit", day: "numeric", hour: "2-digit", minute: "2-digit" }) : ""}
          <br />
          {element.remind_message !== "" && props.role.allow_send_remind && parseInt(element.reminders, 10) > 0 ? t('app_status_active_task_reminders') + ' ' + element.reminders : ""}
          <br />
          {element.remind_message !== "" && props.role.allow_send_remind ?
            < Button
              onClick={handleEditReminderClick.bind(this,
                [element.id,
                element.user_name,
                element.user_email,
                element.cc_email,
                element.remind_message,
                element.remind_date,
                ])}
              color="primary"
              round
            >
              {t('app_status_active_task_btn_send_reminder')}
            </Button>
            : ""}
          {element.escalate_date ? <div><Chip label={"Escalate Date " + element.escalate_date} /><br /></div> : ""}
          {element.escalate_action_id !== "" && element.escalate_action_id !== 0 ?
            < Button
              onClick={handleEscalateClick.bind(this, [element.id, element.escalate_date])}
              color="primary"
              round
            >
              <AltRouteIcon />
              &nbsp;&nbsp;
              {t('app_status_active_task_btn_escalate_setup')}
            </Button>
            : ""}
        </TableCell>
      </TableRow >,
    );
    i++;
  });
  const onComletedTaskPageClick = (taskID, e) => {
    window.location.href = `/completed/${props.application_id}/${props.role_id}/${taskID}`
  };
  const rowCompletedTaskItems = [];
  applicationCompletedTaskList.forEach((element) => {
    rowCompletedTaskItems.push(
      <TableRow key={element.id}>
        <TableCell>
          <a style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={onComletedTaskPageClick.bind(this, element.id)} > {element.alias === '' ? element.title : element.alias} </a>
        </TableCell>
      </TableRow >,
    );
  });


  return (
    <div>
      {/* MainPage */}
      <div style={{ border: '0px solid', width: '100%', height: '100%' }} id="1">
        <Card>
          <CardHeader color="primary">
            <h4>
              {t('app_status_active_task_title')}
              {localStorage.getItem('application_label') !== '' ? localStorage.getItem('application_label') : t('app_status_active_task_application_label')}
            </h4>
          </CardHeader>
          <CardBody>
            {props.role.hide_menu ?
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('app_status_active_task_serial')}</TableCell>
                    <TableCell>{t('app_status_active_task_role')}</TableCell>
                    <TableCell>{t('app_status_active_task_task_name')}</TableCell>
                    <TableCell>{localStorage.getItem('application_label') !== '' ? localStorage.getItem('application_label') : t('app_status_active_task_application_label')}</TableCell>
                    <TableCell>{t('app_status_active_task_status')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowItems}
                </TableBody>
              </Table>
              :
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>{t('app_status_active_task_serial')}</TableCell>
                    <TableCell>{t('app_status_active_task_assigned_to')}</TableCell>
                    <TableCell>{t('app_status_active_task_role')}</TableCell>
                    <TableCell>{t('app_status_active_task_task_name')}</TableCell>
                    <TableCell>{localStorage.getItem('application_label') !== '' ? localStorage.getItem('application_label') : t('app_status_active_task_application_label')}</TableCell>
                    <TableCell>{t('app_status_active_task_status')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowItems}
                </TableBody>
              </Table>
            }
          </CardBody>
        </Card>
        <br />
        <div style={{ display: (props.role.hide_menu) ? 'none' : 'block' }}>
          <Card>
            <CardHeader color="primary">
              <h4>
                {localStorage.getItem('application_label') !== '' ? localStorage.getItem('application_label') : t('app_status_active_task_application_label')}
                {' '}
                {t('app_status_tracking_title')}
              </h4>
            </CardHeader>
            <CardBody>
              <MUIDataTable
                data={data}
                columns={columns}
                options={options}
              />
            </CardBody>
          </Card>
        </div>
        <br />
        <div key="completedTaskView" style={{ display: rowCompletedTaskItems.length > 0 ? 'block' : 'none' }}>
          <Card>
            <CardHeader color="primary">
              <h4>
                Completed Task
              </h4>
            </CardHeader>
            <CardBody>
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell>Tasks</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowCompletedTaskItems}
                </TableBody>
              </Table>
            </CardBody>
          </Card>
        </div>
        <Dialog fullWidth open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
          <DialogContent>
            <Card>
              <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                <b>Send or Schedule Reminder</b>
              </CardHeader>
              <CardBody>
                <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      disabled
                      label={"To"}
                      variant="outlined"
                      value={name + " <" + email + ">"}
                      InputLabelProps={{ shrink: true }}
                      style={{ width: '100%' }}
                    />
                  </GridItem>
                </GridContainer>
                <br />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      disabled
                      label={"CC Email"}
                      variant="outlined"
                      value={ccEmail}
                      InputLabelProps={{ shrink: true }}
                      style={{ width: '100%' }}
                    />
                  </GridItem>
                </GridContainer>
                <br />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      label={"Reminder Message"}
                      variant="outlined"
                      value={remindMessage}
                      multiline={true}
                      rows={12}
                      InputLabelProps={{ shrink: true }}
                      style={{ width: '100%' }}
                      onChange={onRemindMessageChange.bind(this)}
                    />
                  </GridItem>
                </GridContainer>
                <br />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={6}>
                    <TextField
                      label={"Reminder Date"}
                      variant="outlined"
                      type="date"
                      value={remindDate}
                      InputLabelProps={{ shrink: true }}
                      style={{ width: '100%' }}
                      onChange={onRemindDateChange.bind(this)}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6}>
                    <Button onClick={handlescheduleReminder.bind(this)}
                      color="primary"
                      round
                    >
                      Schedule Reminder
                    </Button>
                    <Button onClick={handleUnscheduleReminder.bind(this)}
                      color="primary"
                      round
                    >
                      Unschedule Reminder
                    </Button>
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose.bind(this)}
              color="primary"
              round
            >
              Cancel
            </Button>
            <Button
              onClick={handleDialogConfirm.bind(this)}
              color="primary"
              round
              disabled={props.requesting}
            >
              {props.requesting
                ? <CircularProgress size={20} color="inherit" /> : ""}
              Send Reminder Now
            </Button>
          </DialogActions>

        </Dialog>

        <Dialog fullWidth open={dialogEscalateOpen} onClose={handleDialogEscalateClose.bind(this)} aria-labelledby="form-dialog-title">
          <DialogContent>
            <Card>
              <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                <b>Escalate Setup</b>
              </CardHeader>
              <CardBody>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={4}>
                    <FormControl fullWidth={true}  >
                      <InputLabel id="monthsLabel">Months</InputLabel>
                      <Select
                        labelId="monthsLabel"
                        id="monthsSelect"
                        value={escalateMonths}
                        onChange={onEscalateMonthsChange.bind(this)}
                      >
                        <MenuItem key={0} value={`0`}>None</MenuItem>
                        {monthNames.map((month, index) => (
                          <MenuItem key={index + 1} value={`${index + 1}`}>
                            {month}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <FormControl fullWidth={true}  >
                      <InputLabel id="daysLabel">Days</InputLabel>
                      <Select
                        labelId="daysLabel"
                        id="daysSelect"
                        value={escalateDays}
                        onChange={onEscalateDaysChange.bind(this)}
                      >
                        <MenuItem key={0} value={`0`}>None</MenuItem>
                        {[...Array(31).keys()].map((day) => (
                          <MenuItem key={day + 1} value={`${day + 1}`}>
                            {day + 1}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={2}>
                    <FormControl fullWidth={true}  >
                      <InputLabel id="hoursLabel">Hours</InputLabel>
                      <Select
                        labelId="hoursLabel"
                        id="hoursSelect"
                        value={escalateHours}
                        onChange={onEscalateHoursChange.bind(this)}
                      >
                        <MenuItem key={0} value={`0`}>None</MenuItem>
                        {[...Array(24).keys()].map((day) => (
                          <MenuItem key={day + 1} value={`${day + 1}`}>
                            {day + 1}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </GridItem>
                  <GridItem xs={12} sm={12} md={4}>
                    < Button
                      onClick={handleConverDateClick.bind(this)}
                      color="primary"
                      round
                    >
                      {t('app_status_active_task_btn_escalate_to_date')}
                    </Button>
                  </GridItem>
                </GridContainer>
                <br />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <TextField
                      type='datetime-local'
                      value={escalateNewDate}
                      onChange={onEscalateNewDateChange.bind(this)}
                    />
                  </GridItem>
                </GridContainer>
              </CardBody>
            </Card>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogEscalateClose.bind(this)} color="primary" round>
              Cancel
            </Button>
            <Button onClick={handleDialogRemoveEscalate.bind(this)} color="primary" round>
              Remove Escalate
            </Button>
            <Button onClick={handleDialogEscalateConfirm.bind(this)} color="primary" round>
              Save Escalate
            </Button>
          </DialogActions>
        </Dialog>

      </div>

    </div>
  );
};

export default withRoot(ApplicationStatusComp);
