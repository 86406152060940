import API from 'Api/api.js';
import { commonBackendCall, getAdminConfig } from 'Api/common.js';
import {
  GET_ITEM_ADMIN_REQUEST,
  GET_ITEM_ADMIN_SUCCESS,
  GET_ITEM_ADMIN_FAILURE,
  GET_ITEM_CSV_ADMIN_REQUEST,
  GET_ITEM_CSV_ADMIN_SUCCESS,
  GET_ITEM_CSV_ADMIN_FAILURE,
  POST_ITEM_ADMIN_REQUEST,
  POST_ITEM_ADMIN_SUCCESS,
  POST_ITEM_ADMIN_FAILURE,
  PATCH_ITEM_ADMIN_REQUEST,
  PATCH_ITEM_ADMIN_SUCCESS,
  PATCH_ITEM_ADMIN_FAILURE,
  DELETE_ITEM_SELECT_ADMIN_REQUEST,
  DELETE_ITEM_SELECT_ADMIN_SUCCESS,
  DELETE_ITEM_SELECT_ADMIN_FAILURE,
  GET_ITEM_INTG_ADMIN_REQUEST,
  GET_ITEM_INTG_ADMIN_SUCCESS,
  GET_ITEM_INTG_ADMIN_FAILURE,
  PATCH_ITEM_INTG_ADMIN_REQUEST,
  PATCH_ITEM_INTG_ADMIN_SUCCESS,
  PATCH_ITEM_INTG_ADMIN_FAILURE,
} from 'constants/admin/Item';

export function getItemsWithRelation(searchStr, pageSize, pageNum, orderby, sort, relation) {
  return commonBackendCall(
    GET_ITEM_ADMIN_REQUEST,
    GET_ITEM_ADMIN_SUCCESS,
    GET_ITEM_ADMIN_FAILURE,
    API.get(`admin/admin/items?page=${pageNum}&relation=${relation}&limit=${pageSize}&orderby=${orderby}&sort=${sort}&search=${searchStr}`, getAdminConfig()),
  );
}
export function getItemsCSV() {
  return commonBackendCall(
    GET_ITEM_CSV_ADMIN_REQUEST,
    GET_ITEM_CSV_ADMIN_SUCCESS,
    GET_ITEM_CSV_ADMIN_FAILURE,
    API.get('admin/admin/items/csv', getAdminConfig()),
  );
}
export function createItem(obj) {
  return commonBackendCall(
    POST_ITEM_ADMIN_REQUEST,
    POST_ITEM_ADMIN_SUCCESS,
    POST_ITEM_ADMIN_FAILURE,
    API.post('admin/admin/items', obj, getAdminConfig()),
  );
}
export function updateItem(itemID, obj) {
  return commonBackendCall(
    PATCH_ITEM_ADMIN_REQUEST,
    PATCH_ITEM_ADMIN_SUCCESS,
    PATCH_ITEM_ADMIN_FAILURE,
    API.patch(`admin/admin/items/${itemID}`, obj, getAdminConfig()),
  );
}
export function deleteSelectedItem(obj) {
  return commonBackendCall(
    DELETE_ITEM_SELECT_ADMIN_REQUEST,
    DELETE_ITEM_SELECT_ADMIN_SUCCESS,
    DELETE_ITEM_SELECT_ADMIN_FAILURE,
    API.post(`admin/admin/items/delete-selected`, obj, getAdminConfig()),
  );
}
export function getItemIntgs(itemID) {
  return commonBackendCall(
    GET_ITEM_INTG_ADMIN_REQUEST,
    GET_ITEM_INTG_ADMIN_SUCCESS,
    GET_ITEM_INTG_ADMIN_FAILURE,
    API.get(`admin/admin/items/intg/${itemID}`, getAdminConfig()),
  );
}
export function updateItemIntgFieldValue(itemID, obj) {
  return commonBackendCall(
    PATCH_ITEM_INTG_ADMIN_REQUEST,
    PATCH_ITEM_INTG_ADMIN_SUCCESS,
    PATCH_ITEM_INTG_ADMIN_FAILURE,
    API.patch(`admin/admin/items/intg/${itemID}`, obj, getAdminConfig()),
  );
}


