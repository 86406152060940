import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import * as actionDependencyActions from 'actions/admin/ActionDependencyActions';
import * as actionActions from 'actions/admin/ActionActions';
import * as defaultActions from 'actions/admin/DefaultActions';
import ActionDependencyComp from './ActionDependency/ActionDependencyComp';

const ActionDependency = (props) => {
  const { classes } = props;
  const { action, actionDependency, defaultStates } = props;
  const {
    getActionDependencies,
    createActionDependency,
    updateActionDependency,
    deleteActionDependency,
  } = props.actionDependencyActions;
  const { resetMessage } = props.defaultActions;
  const { getActions } = props.actionActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <ActionDependencyComp
        getActions={getActions}
        resetMessage={resetMessage}
        getActionDependencies={getActionDependencies}
        action_list={action.action_list.list === undefined ? [] : action.action_list.list}
        actionDependency_list={actionDependency.actionDependency_list.list === undefined ? [] : actionDependency.actionDependency_list.list}
        createActionDependency={createActionDependency}
        updateActionDependency={updateActionDependency}
        deleteActionDependency={deleteActionDependency}
        refreshActionDependency={actionDependency.refreshActionDependency}
        requesting={actionDependency.requesting}
        message={actionDependency.message}
        error_core={actionDependency.error_core}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    actionDependency: state.adActionDependency,
    action: state.adAction,
    defaultStates: state.adDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actionDependencyActions: bindActionCreators(actionDependencyActions, dispatch),
    actionActions: bindActionCreators(actionActions, dispatch),
    defaultActions: bindActionCreators(defaultActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(ActionDependency);
