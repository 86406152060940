import React, { useState, useEffect } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import MUIDataTable from "mui-datatables";
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from "components/Card/CardFooter.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.jsx';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import Switch from "@material-ui/core/Switch";
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import FindInPageIcon from '@mui/icons-material/FindInPage';

import extendedFormsStyle from "assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx"

const ErrorEmailsComps = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("Email Logs");
  const [dialogButton, setDialogButton] = useState("");
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [dialogType, setDialogType] = useState("add");
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [emailLogList, setEmailLogList] = useState([]);
  const [changeEmail, setChangeEmail] = useState(false);
  const [newEmail, setNewEmail] = useState("");
  const [sentEmailID, setSentEmailID] = useState("");

  const { getUnsentErrorEmailList } = props;
  useEffect(() => {
    getUnsentErrorEmailList();
  }, [getUnsentErrorEmailList])

  useEffect(() => {
    setMessage(props.message)
    if (props.refreshUnsentErrorEmail) {
      setDialogOpen(false)
      props.getUnsentErrorEmailList();
    }
    var rowItems = [];
    for (let i = 0; i < props.unsent_error_email_list.length; i++) {
      var colItems = [];
      colItems.push(i + 1);
      colItems.push(props.unsent_error_email_list[i].workflow_name);
      colItems.push(props.unsent_error_email_list[i].application_title);
      colItems.push(new Date(props.unsent_error_email_list[i].insert_date).
        toLocaleString('en', {
          weekday: "short", year: "numeric",
          month: "2-digit", day: "numeric", hour: "2-digit", minute: "2-digit"
        }));
      colItems.push(props.unsent_error_email_list[i].to_name);
      colItems.push(props.unsent_error_email_list[i].to_email);
      colItems.push(<div>
        <IconButton onClick={handleShowLogClick.bind(this,
          [props.unsent_error_email_list[i].sent_email_log,
          props.unsent_error_email_list[i].id,
          ])} title="Show Log"> <FindInPageIcon /></IconButton>
      </div>);
      rowItems.push(colItems);
    }
    setData(rowItems)

  }, [props])

  const { classes } = props;
  const columns = [
    "Serial",
    "Workflow",
    "Application Title",
    "Sent At",
    "To Name",
    "To Email",
    ""
  ];
  const options = {
    filterType: "dropdown",
    responsive: "standard",
    filter: true,
    selectableRows: "none",
    rowsPerPage: 100,
    selectToolbarPlacement: "none"
  };

  const onChangeEmailChange = (e) => {
    setChangeEmail(e.target.checked)
  }
  const onNewEmailChange = (e) => {
    setNewEmail(e.target.value)
  }

  const handleShowLogClick = (e) => {
    setEmailLogList(e[0])
    setSentEmailID(e[1])
    setDialogTitle('Email Log')
    setDialogOpen(true)
    setNewEmail("")
  }

  const handleDialogConfirm = (e) => {
    if (!props.requesting) {
      props.resendEmail({
        sent_email_id: sentEmailID.toString(),
        email: newEmail
      })
    }
  }

  const handleDialogClose = (e) => {
    setDialogOpen(false)
    setMessage('')
  }

  const logItems = [];
  emailLogList.forEach((obj) => {
    logItems.push(
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={6}>
            {new Date(obj.insert_date).toLocaleString('en', { weekday: "short", year: "numeric", month: "2-digit", day: "numeric", hour: "2-digit", minute: "2-digit" })}
          </GridItem>
          <GridItem xs={12} sm={12} md={6}>
            {obj.email_log}
          </GridItem>
        </GridContainer>
        <br />
      </div>
    );

  });


  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Error Email List</b>
        </CardHeader>
        <CardBody>
          <MUIDataTable
            data={data}
            columns={columns}
            options={options}
          />
          <Dialog fullWidth={true} open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
              <Card>
                <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                  {dialogTitle}
                </CardHeader>
                <CardBody>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={4}>
                      <InputLabel id="changeEmailLabel" shrink={true}>Change Email</InputLabel>
                      <Grid item={true} component="label" container alignItems="center" spacing={1}>
                        <Grid item>No</Grid>
                        <Grid item>
                          <Switch
                            labelid="changeEmailLabel"
                            checked={changeEmail}
                            onChange={onChangeEmailChange.bind(this)}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                            }}
                          />
                        </Grid>
                        <Grid item>Yes</Grid>
                      </Grid>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={8}>
                      <div style={{ display: changeEmail ? 'block' : 'none' }}>
                        <TextField
                          required={false}
                          fullWidth={true}
                          label="New Email"
                          value={newEmail}
                          onChange={onNewEmailChange.bind(this)}
                        />
                      </div>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <b>Sent At</b>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <b>Log</b>
                    </GridItem>
                  </GridContainer>
                  <br />
                  {logItems}
                </CardBody>
                <CardFooter style={{ textAlign: 'left' }}>
                </CardFooter>
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose.bind(this)} color="primary" round>
                Cancel
              </Button>
              <Button
                onClick={handleDialogConfirm.bind(this)}
                color="primary"
                round
              >
                {props.requesting ? <CircularProgress size={20} color="inherit" /> : ""}
                {changeEmail ? "Resend Message To New Email Address " : "Resend Email"}
              </Button>
            </DialogActions>
          </Dialog>
        </CardBody>
      </Card>
    </div>
  );
}
export default withStyles(extendedFormsStyle)(ErrorEmailsComps);

