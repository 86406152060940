import API from 'Api/api.js';
import { commonBackendCall, getAdminConfig } from 'Api/common.js';
import {
  GET_ACTION_MSG_REQUEST,
  GET_ACTION_MSG_SUCCESS,
  GET_ACTION_MSG_FAILURE,
  POST_ACTION_MSG_REQUEST,
  POST_ACTION_MSG_SUCCESS,
  POST_ACTION_MSG_FAILURE,
  PATCH_ACTION_MSG_REQUEST,
  PATCH_ACTION_MSG_SUCCESS,
  PATCH_ACTION_MSG_FAILURE,
  DELETE_ACTION_MSG_REQUEST,
  DELETE_ACTION_MSG_SUCCESS,
  DELETE_ACTION_MSG_FAILURE,
  GET_ACTION_ACTIONMSG_REQUEST,
  GET_ACTION_ACTIONMSG_SUCCESS,
  GET_ACTION_ACTIONMSG_FAILURE,
  GET_ACTION_MSG_VAR_REQUEST,
  GET_ACTION_MSG_VAR_SUCCESS,
  GET_ACTION_MSG_VAR_FAILURE,
  GET_ACTION_MSG_LINK_DATA_LIST_REQUEST,
  GET_ACTION_MSG_LINK_DATA_LIST_SUCCESS,
  GET_ACTION_MSG_LINK_DATA_LIST_FAILURE,
  GET_ACTION_MSG_WF_ATTR_REQUEST,
  GET_ACTION_MSG_WF_ATTR_SUCCESS,
  GET_ACTION_MSG_WF_ATTR_FAILURE,
  GET_ACTION_MSG_TASK_REQUEST,
  GET_ACTION_MSG_TASK_SUCCESS,
  GET_ACTION_MSG_TASK_FAILURE,
  GET_INTG_EXT_API_EXTERNAL_REQUEST,
  GET_INTG_EXT_API_EXTERNAL_SUCCESS,
  GET_INTG_EXT_API_EXTERNAL_FAILURE,
  GET_ACTION_MSG_TASK_FORM_REQUEST,
  GET_ACTION_MSG_TASK_FORM_SUCCESS,
  GET_ACTION_MSG_TASK_FORM_FAILURE,
  GET_ACTION_MSG_TASK_ACTION_REQUEST,
  GET_ACTION_MSG_TASK_ACTION_SUCCESS,
  GET_ACTION_MSG_TASK_ACTION_FAILURE,
} from 'constants/admin/ActionMsg';

export function getActionMsgTasks(actionID) {
  return commonBackendCall(
    GET_ACTION_MSG_TASK_REQUEST,
    GET_ACTION_MSG_TASK_SUCCESS,
    GET_ACTION_MSG_TASK_FAILURE,
    API.get(`admin/action-message-tasks/${actionID}`, getAdminConfig()),
  );
}

export function getActionMessageWFAttr() {
  return commonBackendCall(
    GET_ACTION_MSG_WF_ATTR_REQUEST,
    GET_ACTION_MSG_WF_ATTR_SUCCESS,
    GET_ACTION_MSG_WF_ATTR_FAILURE,
    API.get('admin/workflows-attr', getAdminConfig()),
  );
}

export function getActionMessageVariables(actionID) {
  return commonBackendCall(
    GET_ACTION_MSG_VAR_REQUEST,
    GET_ACTION_MSG_VAR_SUCCESS,
    GET_ACTION_MSG_VAR_FAILURE,
    API.get(`admin/action-message-variables/${actionID}`, getAdminConfig()),
  );
}

export function getActionMessageLinkDataList(actionID) {
  return commonBackendCall(
    GET_ACTION_MSG_LINK_DATA_LIST_REQUEST,
    GET_ACTION_MSG_LINK_DATA_LIST_SUCCESS,
    GET_ACTION_MSG_LINK_DATA_LIST_FAILURE,
    API.get(`admin/action-message-link-data-list/${actionID}`, getAdminConfig()),
  );
}

export function getActionMessageTaskForm(taskID) {
  return commonBackendCall(
    GET_ACTION_MSG_TASK_FORM_REQUEST,
    GET_ACTION_MSG_TASK_FORM_SUCCESS,
    GET_ACTION_MSG_TASK_FORM_FAILURE,
    API.get(`admin/action-message-task-form/${taskID}`, getAdminConfig()),
  );
}

export function getActionMessageTaskAction(taskID) {
  return commonBackendCall(
    GET_ACTION_MSG_TASK_ACTION_REQUEST,
    GET_ACTION_MSG_TASK_ACTION_SUCCESS,
    GET_ACTION_MSG_TASK_ACTION_FAILURE,
    API.get(`admin/action-message-task-action/${taskID}`, getAdminConfig()),
  );
}

export function getActionActionMessages(actionID) {
  return commonBackendCall(
    GET_ACTION_ACTIONMSG_REQUEST,
    GET_ACTION_ACTIONMSG_SUCCESS,
    GET_ACTION_ACTIONMSG_FAILURE,
    API.get(`admin/actions/action-messages/${actionID}`, getAdminConfig()),
  );
}
export function getActionMsgs() {
  return commonBackendCall(
    GET_ACTION_MSG_REQUEST,
    GET_ACTION_MSG_SUCCESS,
    GET_ACTION_MSG_FAILURE,
    API.get('admin/action-messages', getAdminConfig()),
  );
}
export function createActionMsg(obj) {
  return commonBackendCall(
    POST_ACTION_MSG_REQUEST,
    POST_ACTION_MSG_SUCCESS,
    POST_ACTION_MSG_FAILURE,
    API.post('admin/action-messages', obj, getAdminConfig()),
  );
}
export function updateActionMsg(actionMsgID, obj) {
  return commonBackendCall(
    PATCH_ACTION_MSG_REQUEST,
    PATCH_ACTION_MSG_SUCCESS,
    PATCH_ACTION_MSG_FAILURE,
    API.patch(`admin/action-messages/${actionMsgID}`, obj, getAdminConfig()),
  );
}
export function deleteActionMsg(actionMsgID) {
  return commonBackendCall(
    DELETE_ACTION_MSG_REQUEST,
    DELETE_ACTION_MSG_SUCCESS,
    DELETE_ACTION_MSG_FAILURE,
    API.delete(`admin/action-messages/${actionMsgID}`, getAdminConfig()),
  );
}

export function getIntgExtAPI() {
  return commonBackendCall(
    GET_INTG_EXT_API_EXTERNAL_REQUEST,
    GET_INTG_EXT_API_EXTERNAL_SUCCESS,
    GET_INTG_EXT_API_EXTERNAL_FAILURE,
    API.get('admin/intg-ext-apis/external', getAdminConfig()),
  );
}
