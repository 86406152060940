import {
  GET_CUSTOM_INTG_ADMIN_REQUEST,
  GET_CUSTOM_INTG_ADMIN_SUCCESS,
  GET_CUSTOM_INTG_ADMIN_FAILURE,
} from '../constants/admin/Custom';

const initialState = {
  requesting: false,
  message: '',
  custom_intg_list: [],
};

export default function custom(state = initialState, action) {
  switch (action.type) {
    case GET_CUSTOM_INTG_ADMIN_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        custom_intg_list: [],
      };

    case GET_CUSTOM_INTG_ADMIN_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        custom_intg_list: action.payload.data,
      };

    case GET_CUSTOM_INTG_ADMIN_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    default:
      return state;
  }
}
