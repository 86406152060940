import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import IntgFieldComp from './IntgField/IntgFieldComp';
import * as integratonActions from 'actions/networkadmin/IntegrationActions';

const IntgField = (props) => {
  const { classes } = props;
  const { integration, defaultStates } = props;
  const {
    getUserIntgFields,
    getCustomIntgFields,
    getDeptIntgFields,
    getItemIntgFields,
    createIntgField,
    updateIntgField,
    deleteIntgField,
  } = props.integratonActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <IntgFieldComp
        getUserIntgFields={getUserIntgFields}
        getCustomIntgFields={getCustomIntgFields}
        getItemIntgFields={getItemIntgFields}
        getDeptIntgFields={getDeptIntgFields}
        intg_user_field_list={integration.intg_user_field_list === undefined ? [] : integration.intg_user_field_list}
        intg_custom_field_list={integration.intg_custom_field_list === undefined ? [] : integration.intg_custom_field_list}
        intg_item_field_list={integration.intg_item_field_list === undefined ? [] : integration.intg_item_field_list}
        intg_dept_field_list={integration.intg_dept_field_list === undefined ? [] : integration.intg_dept_field_list}
        createIntgField={createIntgField}
        updateIntgField={updateIntgField}
        deleteIntgField={deleteIntgField}
        refreshIntgField={integration.refreshIntgField}
        requesting={integration.requesting}
        message={integration.message}
        error_core={integration.error_code}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    integration: state.naIntegration,
    defaultStates: state.naDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    integratonActions: bindActionCreators(integratonActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(IntgField);
