import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import withStyles from '@material-ui/core/styles/withStyles';
import loginPageStyle from 'assets/jss/material-dashboard-react/views/loginPageStyle.jsx';
import { useParams } from 'react-router-dom';
import * as loginActions from 'actions/LoginActions';
import * as defaultActions from 'actions/DefaultActions';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import LinkDetailComp from './LinkDetail/LinkDetailComp';

const LinkDetail = (props) => {
  const { token } = useParams();
  const { classes } = props;
  const { login, defaultStates } = props;
  const { onLinkDetail } = props.loginActions;
  var tokenParam = token
  return (
    <div className={classes.content}>
      <div className={classes.container}>
        {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
        <LinkDetailComp
          token={tokenParam}
          requesting={login.requesting}
          message={login.message}
          error_code={login.error_code}
          onLinkDetail={onLinkDetail}
          classes={props}
          loggedIn={login.loggedIn}
        />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    login: state.login,
    defaultStates: state.defaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loginActions: bindActionCreators(loginActions, dispatch),
    defaultActions: bindActionCreators(defaultActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(loginPageStyle)(LinkDetail));
