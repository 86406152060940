import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as lookupActions from 'actions/LookupActions';
import WeekSelectComp from './WeekSelect/WeekSelectComp';

const WeekSelect = (props) => {
  const { classes } = props;
  const { lookup } = props;
  const {
    setWeekSelectField,
  } = props.lookupActions;
  return (
    <WeekSelectComp
      id={props.id}
      label={props.label}
      disabled={props.disabled}
      required={props.required}
      task_id={props.task_id}
      form_id={props.form_id}
      fields={props.fields}
      application_id={props.application_id}
      postApplicationDetail={props.postApplicationDetail}
      value={lookup.weekSelect === '' ? props.value : lookup.weekSelect}
      error={props.error}
      setWeekSelectField={setWeekSelectField}
      weekSelect={lookup.weekSelect}
      classes={classes}

    />
  );
}

function mapStateToProps(state) {
  return {
    lookup: state.lookup,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    lookupActions: bindActionCreators(lookupActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(WeekSelect);
