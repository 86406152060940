/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
//import PerfectScrollbar from 'perfect-scrollbar';
//import 'perfect-scrollbar/css/perfect-scrollbar.css';
import Header from 'components/Header/HeaderAdmin.jsx';
import Footer from 'components/Footer/Footer.jsx';
import Sidebar from 'components/Sidebar/Sidebar.jsx';
import dashboardRoutes from 'routes/adminDashboard.jsx';
import image from 'assets/img/sidebar-2.jpg';
import logo from 'assets/img/logo/molecule-72-192917.png';
import { makeStyles } from '@material-ui/core/styles';
import appStyle from 'assets/jss/material-dashboard-react/layouts/dashboardStyle.jsx';

const useStyles = makeStyles(appStyle);

export default function AdminDashboard({ ...rest }) {
  const classes = useStyles();
  const mainPanel = React.createRef();
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [miniActive, setMiniActive] = React.useState(false);

  const sidebarMinimize = () => {
    setMiniActive(!miniActive);
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const resizeFunction = () => {
    if (window.innerWidth >= 960) {
      setMobileOpen(false);
    }
  };
  const PrivateRoute = () => {
    return localStorage.getItem('admin_token') ? <Outlet /> : <Navigate to="/admin/public/login" />;
  }
  return (
    <div className={classes.wrapper}>
      <Sidebar
        routes={dashboardRoutes}
        logoText={"Workflow Admin"}
        logo={logo}
        image={image}
        handleDrawerToggle={handleDrawerToggle}
        open={mobileOpen}
        color="purple"
        bgColor="black"
        miniActive={miniActive}
        {...rest}
      />
      <div className={miniActive ? classes.mainPanel + " " + classes.mainPanelSidebarMini : classes.mainPanel} ref={mainPanel}>
        <Header
          sidebarMinimize={sidebarMinimize}
          miniActive={miniActive}
          routes={dashboardRoutes}
          handleDrawerToggle={handleDrawerToggle}
          {...rest}
        />
        <div className={classes.content}>
          <div className={classes.container}>
            <PrivateRoute />
          </div>
        </div>
        <Footer fluid />
      </div>
    </div>
  );
}