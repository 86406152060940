export const GET_TASK_REQUEST = 'GET_TASK_REQUEST';
export const GET_TASK_SUCCESS = 'GET_TASK_SUCCESS';
export const GET_TASK_FAILURE = 'GET_TASK_FAILURE';
export const POST_TASK_REQUEST = 'POST_TASK_REQUEST';
export const POST_TASK_SUCCESS = 'POST_TASK_SUCCESS';
export const POST_TASK_FAILURE = 'POST_TASK_FAILURE';
export const PATCH_TASK_REQUEST = 'PATCH_TASK_REQUEST';
export const PATCH_TASK_SUCCESS = 'PATCH_TASK_SUCCESS';
export const PATCH_TASK_FAILURE = 'PATCH_TASK_FAILURE';
export const DELETE_TASK_REQUEST = 'DELETE_TASK_REQUEST';
export const DELETE_TASK_SUCCESS = 'DELETE_TASK_SUCCESS';
export const DELETE_TASK_FAILURE = 'DELETE_TASK_FAILURE';
export const GET_A_TASK_REQUEST = 'GET_A_TASK_REQUEST';
export const GET_A_TASK_SUCCESS = 'GET_A_TASK_SUCCESS';
export const GET_A_TASK_FAILURE = 'GET_A_TASK_FAILURE';
