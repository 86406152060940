/* eslint-disable no-unused-expressions */
import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router';
import MUIDataTable from 'mui-datatables';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.jsx';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import PrintIcon from '@mui/icons-material/Print';
import PrintDisabledIcon from '@mui/icons-material/PrintDisabled';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import AirplayIcon from '@mui/icons-material/Airplay';
import Switch from '@material-ui/core/Switch';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import Typography from '@mui/material/Typography';
import extendedFormsStyle from 'assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx';

import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';

import {
  COMMITTEE,
} from 'constants/admin/FieldTypes';

const FormComp = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogButton, setDialogButton] = useState('');
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [dialogType, setDialogType] = useState('add');
  const [data, setData] = useState([]);
  const [message, setMessage] = useState('');
  const [id, setID] = useState('');
  const [title, setTitle] = useState('');
  const [alias, setAlias] = useState('');
  const [selectedFormID, setSelectedFormID] = useState('');
  const [redirectPreview, setRedirectPreview] = useState(false);
  const [redirectField, setRedirectField] = useState(false);
  const [formCategoryID, setFormCategoryID] = useState('');
  const [dialogAdhocOpen, setDialogAdhocOpen] = useState(false);
  const [dialogAdhocTitle, setDialogAdhocTitle] = useState('');
  const [dialogAdhocButton, setDialogAdhocButton] = useState('');
  const [dialogAdhocType, setDialogAdhocType] = useState('add');
  const [type, setType] = useState('');
  const [status, setStatus] = useState(false);
  const [label, setLabel] = useState('');
  const [formPrint, setFormPrint] = useState('');
  const [commNameID, setCommNameID] = useState('');



  const [uploadType, setUploadType] = useState('AWS');
  const [fileKeyAWS, setFileKeyAWS] = useState('');
  const [dropboxAccessToken, setDropboxAccessToken] = useState('');
  const [dropboxURL1, setDropboxURL1] = useState('');
  const [dropboxURL2, setDropboxURL2] = useState('');
  const [presignedAWSPUTURL, setPresignedAWSPUTURL] = useState('');
  const [presignedAWSGETURL, setPresignedAWSGETURL] = useState('');
  const [filePath, setFilePath] = useState('');
  const [shareFileLocal, setShareFileLocal] = useState(null);
  const [displayUploadProgress, setDisplayUploadProgress] = useState(false);
  const [progress, setProgress] = React.useState(0);
  const [fileUploadError, setFileUploadError] = useState('');



  const onFormSubmit = (e) => {
    e.preventDefault(); // Stop form submit
  };

  const onFileChange = (e) => {
    if (e.target.files.length > 0) {
      setShareFileLocal(e.target.files[0]);
      setDisplayUploadProgress(true);
      setProgress(0);
      awsUploadFileDirect(e.target.files[0])
    }
  };

  function awsUploadFileDirect(localFile) {
    var xhr = new XMLHttpRequest();
    if (localFile === null) {
      return;
    }
    xhr.upload.onprogress = function (evt) {
      var percentComplete = parseInt(100.0 * (evt.loaded / evt.total), 10);
      setProgress(percentComplete * (18 / 20));
    };
    xhr.onload = function () {
      if (xhr.status === 200) {
        setProgress(100);
        props.getFormPrintFile(id)
      } else {
        // console.log(xhr.response)
        setFileUploadError('Unable to upload file');
      }
    };
    xhr.open('PUT', presignedAWSPUTURL);
    xhr.send(localFile);
  }


  const { getFormCategories, getForms, getAllAdhocComms } = props;
  useEffect(() => {
    getFormCategories();
    getForms();
    getAllAdhocComms();
  }, [getFormCategories, getForms, getAllAdhocComms]);

  const handlePreviewClick = (e) => {
    setRedirectPreview(true);
    setSelectedFormID(e[0]);
  };
  const handleFieldsClick = (e) => {
    setRedirectField(true);
    setSelectedFormID(e[0]);
  };

  const handleUpdatePrintClick = (e) => {
    props.updateForm(e[0], {
      title: e[1],
      form_category_id: e[2].toString(),
      alias: e[3],
      form_print: e[4] ? "0" : "1",
    });
  };
  const handleEditClick = (e) => {
    //props.getFormPrintFile(e[0])
    setTitle(e[1]);
    setID(e[0]);
    setFormCategoryID(e[2]);
    setAlias(e[3]);
    setFormPrint(e[4]);
    setMessage('');
    setDialogTitle('Edit');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('update');
    setDialogOpen(true);
    if (e[4]) {
      props.getFormPrintFile(e[0])
    }
    props.resetMessage('');
    setDisplayUploadProgress(false);
    setProgress(0);
  };
  const handleDeleteClick = (e) => {
    setTitle(e[1]);
    setID(e[0]);
    setFormCategoryID(e[2]);
    setAlias(e[3]);
    setFormPrint(e[4]);
    setMessage('');
    setDialogTitle('Delete');
    setDialogButton('Confirm Delete');
    setFieldDisabled(true);
    setDialogType('delete');
    setDialogOpen(true);
    props.resetMessage('');
    setDisplayUploadProgress(false);
    setProgress(0);
  };
  const handleNewClick = (e) => {
    setTitle('');
    setFormCategoryID('');
    setAlias('');
    setMessage('');
    setDialogTitle('Add New');
    setDialogButton('Save');
    setFieldDisabled(false);
    setDialogType('add');
    setDialogOpen(true);
    props.resetMessage('');
    setDisplayUploadProgress(false);
    setProgress(0);
  };
  const handleNewAdhocClick = (e) => {
    setTitle('');
    setFormCategoryID('');
    setLabel('');
    setAlias('');
    setType('');
    setCommNameID('');
    setMessage('');
    setDialogAdhocTitle('Add New');
    setDialogAdhocButton('Save');
    setFieldDisabled(false);
    setDialogAdhocType('add');
    setDialogAdhocOpen(true);
    props.resetMessage('');
  };
  useEffect(() => {
    setMessage(props.message);
    if (props.refreshForm) {
      props.getForms();
      setDialogOpen(false);
      setDialogAdhocOpen(false);
    }
    console.log("props.form_print_file", props.form_print_file)
    if (props.form_print_file !== "") {
      console.log("FORM_PRINT_FILE 1")
      console.log(props.form_print_file)
      setPresignedAWSGETURL(props.form_print_file.presigned_get_url)
      setPresignedAWSPUTURL(props.form_print_file.presigned_put_url)
      console.log("FORM_PRINT_FILE 2")
    }
    var rowItems = [];
    if (props.formCategory_list.length > 0 && props.form_list.length > 0) {
      for (let i = 0; i < props.form_list.length; i++) {
        // var link = "/form-fields/" + props.form_list[i].id
        var colItems = [];
        colItems.push(i + 1);
        colItems.push(props.form_list[i].title);
        colItems.push(props.form_list[i].alias);
        var formCategoryObj = props.formCategory_list.find((o) => o.id === props.form_list[i].form_category_id);
        formCategoryObj === undefined ? colItems.push('') : colItems.push(formCategoryObj.title);
        colItems.push(
          <div>
            <IconButton
              onClick={handleFieldsClick.bind(this,
                [props.form_list[i].id,
                ])}
              title="Fields"
            >
              <Typography variant="body2">{props.form_list[i].field_count}</Typography><ListRoundedIcon />
            </IconButton>
            <IconButton
              onClick={handlePreviewClick.bind(this,
                [props.form_list[i].id])}
              title="Preview Fields"
            >
              <AirplayIcon />
            </IconButton>

            <IconButton
              onClick={handleEditClick.bind(this,
                [props.form_list[i].id,
                props.form_list[i].title,
                props.form_list[i].form_category_id,
                props.form_list[i].alias,
                props.form_list[i].form_print,
                ])}
              title={`Edit Row ID:${props.form_list[i].id}`}
            >
              {' '}
              <EditIcon />
            </IconButton>
            <IconButton
              onClick={handleUpdatePrintClick.bind(this,
                [props.form_list[i].id,
                props.form_list[i].title,
                props.form_list[i].form_category_id,
                props.form_list[i].alias,
                props.form_list[i].form_print,
                ])}
              title={`Print`}
            >
              {' '}
              {props.form_list[i].form_print ? <PrintIcon /> : <PrintDisabledIcon />}
            </IconButton>
            <IconButton
              onClick={handleDeleteClick.bind(this,
                [props.form_list[i].id,
                props.form_list[i].title,
                props.form_list[i].form_category_id,
                props.form_list[i].alias,
                props.form_list[i].form_print,
                  // props.form_list[i].allow_f_a_action,
                ])}
              title="Delete Row"
            >
              {' '}
              <DeleteIcon />
            </IconButton>


          </div>,
        );
        rowItems.push(colItems);
      }
    }
    setData(rowItems);
  }, [props]);

  const handleDialogClose = (e) => {
    setDialogOpen(false);
    setMessage('');
  };
  const handleDialogAdhocClose = (e) => {
    setDialogAdhocOpen(false);
    setMessage('');
  };
  const handleDialogConfirm = (e) => {
    if (dialogType === 'add') {
      props.createForm({
        title,
        form_category_id: formCategoryID.toString(),
        alias: alias,
        form_print: formPrint ? "1" : "0",
        // allow_f_a_action: allowFAAction ? "1" : "0",
      });
    } else if (dialogType === 'update') {
      props.updateForm(id, {
        title,
        form_category_id: formCategoryID.toString(),
        alias: alias,
        form_print: formPrint ? "1" : "0",
      });
    } else if (dialogType === 'delete') {
      props.deleteForm(id);
    }
  };
  const handleDialogAdhocConfirm = (e) => {
    if (dialogAdhocType === 'add') {
      if (commNameID.toString() === '') {
        setMessage('You must select an Adhoc Committee');
        return;
      }
      props.createAdhocForm({
        title,
        alias,
        form_category_id: formCategoryID.toString(),
        type,
        status: status ? '1' : '0',
        label,
        committee_name_id: commNameID.toString(),
      });
    }
  };
  const onTitleChange = (e) => {
    setTitle(e.target.value);
  };
  const onAliasChange = (e) => {
    setAlias(e.target.value);
  };
  const onFormCategoryIDChange = (e) => {
    setFormCategoryID(e.target.value);
  };
  const onTypeChange = (e) => {
    setType(e.target.value);
  };
  const onStatusChange = (e) => {
    setStatus(e.target.checked);
  };
  const onLabelChange = (e) => {
    setLabel(e.target.value);
  };
  const onCommNameIDChange = (e) => {
    setCommNameID(e.target.value);
  };

  const { classes } = props;
  const columns = [
    'Serial',
    'Title',
    'Alias',
    'Form Category',
    '',
  ];
  const options = {
    filterType: 'dropdown',
    responsive: 'standard',
    filter: true,
    selectableRows: 'none',
    rowsPerPage: 100,
    selectToolbarPlacement: 'none',
  };
  if (redirectPreview) {
    return <Navigate push to={{ pathname: `/admin/form-preview/${selectedFormID}` }} />;
  }
  if (redirectField) {
    return <Navigate push to={{ pathname: `/admin/form-fields/${selectedFormID}` }} />;
  }
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>

        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Forms</b>
          <p className={classes.cardCategoryWhite}>
            Here is the list of the forms corresponding to each page in a task. The user can input data in the fields present in the form.
          </p>
        </CardHeader>
        <CardBody>
          <MUIDataTable
            title={(
              <div>
                <Button
                  onClick={handleNewClick.bind(this)}
                  color="primary"
                  title="Create a new Form"
                  disabled={parseInt(localStorage.getItem('workflow_state'), 10) > 0}
                  round
                >
                  New Form
                </Button>
                <Button
                  onClick={handleNewAdhocClick.bind(this)}
                  color="primary"
                  title="Create a new Adhoc Committee Form"
                  disabled={parseInt(localStorage.getItem('workflow_state'), 10) > 0}
                  round
                >
                  New Adhoc Committee Form
                </Button>
              </div>
            )}
            data={data}
            columns={columns}
            options={options}
          />
          <Dialog fullWidth open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              <Card>
                <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                  <b>{dialogTitle}</b>
                </CardHeader>
                <CardBody>
                  <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        required
                        fullWidth
                        label="Title"
                        value={title}
                        onChange={onTitleChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        fullWidth
                        label="Alias"
                        value={alias}
                        onChange={onAliasChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled}>
                        <InputLabel id="formCategoryidLabel">Form Category</InputLabel>
                        <Select
                          labelid="formCategoryidLabel"
                          id="formCategoryidSelect"
                          value={formCategoryID}
                          onChange={onFormCategoryIDChange.bind(this)}
                        >
                          {props.formCategory_list.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {props.formCategory_list.find((p) => p.id === item.id).title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <div style={{ display: formPrint ? 'block' : 'none' }}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={6}>
                        {props.form_print_file.presigned_put_url ?
                          <div>
                            <form onSubmit={onFormSubmit.bind(this)} id="uploadForm">
                              <input
                                type="file"
                                onChange={onFileChange.bind(this)}
                                style={{ display: 'none' }}
                                id={`raised-button-userfile${props.id}`}
                              />
                              <label htmlFor={`raised-button-userfile${props.id}`}>
                                <Button
                                  round
                                  color="primary"
                                  component="span"
                                  id={`button${props.id}`}
                                  key={`button${props.id}`}
                                >
                                  Upload print template file
                                </Button>
                              </label>
                            </form>
                          </div>
                          : ""}
                      </GridItem>
                      <GridItem xs={12} sm={12} md={6}>
                        {props.form_print_file.presigned_get_url ?
                          "Selected Template File"
                          : ""}
                        <br />
                        {presignedAWSGETURL !== "" ? <a href={presignedAWSGETURL} target="_blank" rel="noreferrer">{title}</a> : ""}
                        <br />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <div
                          className={classes.root}
                          style={{ display: displayUploadProgress ? 'block' : 'none' }}
                        >
                          <LinearProgressWithLabel value={progress} />
                        </div>
                      </GridItem>
                    </GridContainer>
                  </div>
                </CardBody>
                <CardFooter style={{ textAlign: 'left' }} />
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose.bind(this)} color="primary" round>
                Cancel
              </Button>
              <Button
                onClick={handleDialogConfirm.bind(this)}
                color="primary"
                round
                disabled={message === '' && dialogButton !== 'Save' && parseInt(localStorage.getItem('workflow_state'), 10) > 0}
              >
                {dialogButton}
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog fullWidth open={dialogAdhocOpen} onClose={handleDialogAdhocClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              <Card>
                <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                  <b>{dialogAdhocTitle}</b>
                </CardHeader>
                <CardBody>
                  <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        required
                        fullWidth
                        label="Title"
                        value={title}
                        onChange={onTitleChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        required
                        fullWidth
                        label="Alias"
                        value={alias}
                        onChange={onAliasChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled}>
                        <InputLabel id="formCategoryidLabel">Form Category</InputLabel>
                        <Select
                          labelid="formCategoryidLabel"
                          id="formCategoryidSelect"
                          value={formCategoryID}
                          onChange={onFormCategoryIDChange.bind(this)}
                        >
                          {props.formCategory_list.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {props.formCategory_list.find((p) => p.id === item.id).title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled}>
                        <InputLabel id="typeLabel">Type</InputLabel>
                        <Select
                          labelid="typeLabel"
                          id="typeSelect"
                          value={type}
                          onChange={onTypeChange.bind(this)}
                        >
                          <MenuItem value={COMMITTEE}>Adhoc Committee</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <InputLabel id="statusLabel" shrink>Status</InputLabel>
                      <Grid item component="label" container alignItems="center" spacing={1}>
                        <Grid item>Inactive</Grid>
                        <Grid item>
                          <Switch
                            labelid="statusLabel"
                            checked={status}
                            onChange={onStatusChange.bind(this)}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled={fieldDisabled || parseInt(localStorage.getItem('workflow_state'), 10) > 0}
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                            }}
                          />
                        </Grid>
                        <Grid item>Active</Grid>
                      </Grid>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={8}>
                      <TextField
                        required={false}
                        fullWidth
                        label="Label"
                        value={label}
                        onChange={onLabelChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <FormControl fullWidth className={classes.formControl} disabled={fieldDisabled}>
                        <InputLabel id="adhocCommLabel">Adhoc Committee</InputLabel>
                        <Select
                          labelid="adhocCommLabel"
                          id="adhocCommSelect"
                          value={commNameID}
                          onChange={onCommNameIDChange.bind(this)}
                        >
                          {props.adhoc_comm_list.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {props.adhoc_comm_list.find((p) => p.id === item.id).title}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                </CardBody>
                <CardFooter style={{ textAlign: 'left' }} />
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogAdhocClose.bind(this)} color="primary" round>
                Cancel
              </Button>
              <Button
                onClick={handleDialogAdhocConfirm.bind(this)}
                color="primary"
                round
                disabled={message === '' && dialogAdhocButton !== 'Save' && parseInt(localStorage.getItem('workflow_state'), 10) > 0}

              >
                {dialogAdhocButton}
              </Button>
            </DialogActions>
          </Dialog>
        </CardBody>
      </Card>
    </div >
  );
};
export default withStyles(extendedFormsStyle)(FormComp);

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">
          {`${Math.round(
            props.value,
          )}%`}
        </Typography>
      </Box>
    </Box>
  );
}
