import {
  GET_WORKFLOW_ATTR_REQUEST,
  GET_WORKFLOW_ATTR_SUCCESS,
  GET_WORKFLOW_ATTR_FAILURE,
  POST_WORKFLOW_ATTR_REQUEST,
  POST_WORKFLOW_ATTR_SUCCESS,
  POST_WORKFLOW_ATTR_FAILURE,
  PATCH_WORKFLOW_ATTR_REQUEST,
  PATCH_WORKFLOW_ATTR_SUCCESS,
  PATCH_WORKFLOW_ATTR_FAILURE,
  DELETE_WORKFLOW_ATTR_REQUEST,
  DELETE_WORKFLOW_ATTR_SUCCESS,
  DELETE_WORKFLOW_ATTR_FAILURE,
} from '../constants/admin/WorkflowAttr';
import {
  RESET_MESSAGE,
} from '../constants/admin/Default';

const initialState = {
  requesting: false,
  message: '',
  workflow_attr_list: '',
  refreshWorkflowAttr: false,
};

export default function workflowAttr(state = initialState, action) {
  switch (action.type) {
    case RESET_MESSAGE:
      return {
        ...state,
        message: action.payload.value,
      };
    case GET_WORKFLOW_ATTR_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshWorkflowAttr: false,
        message: '',
      };

    case GET_WORKFLOW_ATTR_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        workflow_attr_list: action.payload.data,
      };

    case GET_WORKFLOW_ATTR_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case POST_WORKFLOW_ATTR_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshWorkflowAttr: false,
      };

    case POST_WORKFLOW_ATTR_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshWorkflowAttr: true,
      };

    case POST_WORKFLOW_ATTR_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case PATCH_WORKFLOW_ATTR_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshWorkflowAttr: false,
      };

    case PATCH_WORKFLOW_ATTR_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshWorkflowAttr: true,
      };

    case PATCH_WORKFLOW_ATTR_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case DELETE_WORKFLOW_ATTR_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshWorkflowAttr: false,
      };

    case DELETE_WORKFLOW_ATTR_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshWorkflowAttr: true,
      };

    case DELETE_WORKFLOW_ATTR_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
