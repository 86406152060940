export const GET_WORKFLOW_SETTING_REQUEST = 'GET_WORKFLOW_SETTING_REQUEST';
export const GET_WORKFLOW_SETTING_SUCCESS = 'GET_WORKFLOW_SETTING_SUCCESS';
export const GET_WORKFLOW_SETTING_FAILURE = 'GET_WORKFLOW_SETTING_FAILURE';
export const PATCH_WORKFLOW_SETTING_REQUEST = 'PATCH_WORKFLOW_SETTING_REQUEST';
export const PATCH_WORKFLOW_SETTING_SUCCESS = 'PATCH_WORKFLOW_SETTING_SUCCESS';
export const PATCH_WORKFLOW_SETTING_FAILURE = 'PATCH_WORKFLOW_SETTING_FAILURE';
export const GET_WORKFLOW_USER_REQUEST = 'GET_WORKFLOW_USER_REQUEST';
export const GET_WORKFLOW_USER_SUCCESS = 'GET_WORKFLOW_USER_SUCCESS';
export const GET_WORKFLOW_USER_FAILURE = 'GET_WORKFLOW_USER_FAILURE';
export const POST_WORKFLOW_USER_REQUEST = 'POST_WORKFLOW_USER_REQUEST';
export const POST_WORKFLOW_USER_SUCCESS = 'POST_WORKFLOW_USER_SUCCESS';
export const POST_WORKFLOW_USER_FAILURE = 'POST_WORKFLOW_USER_FAILURE';
export const DELETE_WORKFLOW_USER_REQUEST = 'DELETE_WORKFLOW_USER_REQUEST';
export const DELETE_WORKFLOW_USER_SUCCESS = 'DELETE_WORKFLOW_USER_SUCCESS';
export const DELETE_WORKFLOW_USER_FAILURE = 'DELETE_WORKFLOW_USER_FAILURE';
export const SET_SELECTED_USER = 'SET_SELECTED_USER';
export const POST_WORKFLOWUSER_FILE_UPLOAD_REQUEST = 'POST_WORKFLOWUSER_FILE_UPLOAD_REQUEST';
export const POST_WORKFLOWUSER_FILE_UPLOAD_SUCCESS = 'POST_WORKFLOWUSER_FILE_UPLOAD_SUCCESS';
export const POST_WORKFLOWUSER_FILE_UPLOAD_FAILURE = 'POST_WORKFLOWUSER_FILE_UPLOAD_FAILURE';
