import React from 'react';

import Chip from '@material-ui/core/Chip';
import DraftIcon from '@mui/icons-material/Build';
import TestIcon from '@mui/icons-material/PlaylistAddCheck';
import PublishIcon from '@mui/icons-material/Public';
import DiscontinueIcon from '@mui/icons-material/PanTool';
import ArchiveIcon from '@mui/icons-material/HighlightOff';
import StateText from 'components/StateText/StateText.jsx';
import StateUI from 'components/StateUI/StateUI.jsx';

const StateButtonUI = (value) => {
  // eslint-disable-next-line default-case
  switch (value) {
    case 0:
      // return <div><DraftIcon style={{ color: '#fb8c00', paddingLeft: '5px' }} /> {StateText(value)}</div>
      return (
        <div>
          Move to &nbsp;
          {StateUI(value, 'small')}
        </div>
      );
    case 1:
      // return <div><TestIcon style={{ color: '#00acc1', paddingLeft: '5px' }} /> {StateText(value)}</div>
      return (
        <div>
          Move to &nbsp;
          {StateUI(value, 'small')}
        </div>
      );
    case 2:
      // return <div><PublishIcon style={{ color: '#43a047', paddingLeft: '5px' }} /> {StateText(value)}</div>
      return (
        <div>
          Move to &nbsp;
          {StateUI(value, 'small')}
        </div>
      );
      // return StateUI(value, "small")
    case 3:
      return (
        <div>
          <DiscontinueIcon style={{ color: '#e53935', paddingLeft: '5px' }} />
          {' '}
          {StateText(value)}
        </div>
      );
    case 4:
      return (
        <div>
          <ArchiveIcon style={{ color: '#e91e63', paddingLeft: '5px' }} />
          {' '}
          {StateText(value)}
        </div>
      );
  }
};

export default StateButtonUI;
