import React, { useState, useEffect } from 'react';
import InputAdornment from '@mui/material/InputAdornment';
import CircularProgress from '@mui/material/CircularProgress';

import Email from '@mui/icons-material/Email';
import LockOutline from '@mui/icons-material/LockOutlined';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';
import CardFooter from 'components/Card/CardFooter.jsx';

const SetPasswordComp = (props) => {
  const [cardAnimaton, setCardAnimaton] = useState('cardHidden');
  const [email, setEmail] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [message, setMessage] = useState('');
  const [password, setPassword] = useState('');
  const [passwordConfirm, setPasswordConfirm] = useState('');

  useEffect(() => {
    setTimeout(() => {
      setCardAnimaton('');
    }, 2500);
  }, []);

  useEffect(() => {
    setMessage(props.message);
    setEmail(props.email);
    if (props.refreshNetwork) {
      location.reload();
    }
  }, [props]);

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const onPasswordConfirmChange = (e) => {
    setPasswordConfirm(e.target.value);
  };

  const onPasswordSetClick = (e) => {
    if (password !== passwordConfirm) {
      setPasswordError(true);
      setMessage('Password and Confirm Password should be same');
      return;
    }
    if (password.length < 6) {
      setPasswordError(true);
      setMessage('Password should be atleast 6 characters long');
      return;
    }
    setPasswordError(false);
    setMessage('');
    props.onSetPassword({ network_user_token: props.token, password }, props.redirect);
  };

  const { classes } = props;

  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={6} md={4}>
        <form>
          <Card className={classes[cardAnimaton]}>

            <CardHeader color="primary" plain style={{ textAlign: 'center' }}>
              <b>SET PASSWORD</b>
            </CardHeader>

            <CardBody>
              <div style={{ color: 'red' }}>{message}</div>
              <CustomInput
                labelText="Email"
                id="email"
                formControlProps={{
                  fullWidth: true,
                  disabled: true,
                }}
                inputProps={{
                  value: email,
                  endAdornment: (
                    <InputAdornment position="end">
                      <Email className={classes.inputAdornmentIcon} />
                    </InputAdornment>
                  ),
                }}
              />

              <CustomInput
                labelText="Password"
                id="password"
                value={password}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  input_type: 'password',
                  error: passwordError,
                  onChange: onPasswordChange.bind(this),
                  endAdornment: (
                    <InputAdornment position="end">
                      <LockOutline
                        className={classes.inputAdornmentIcon}
                      />
                    </InputAdornment>
                  ),
                }}
              />
              <CustomInput
                labelText="Password Confirm"
                id="password"
                value={passwordConfirm}
                formControlProps={{
                  fullWidth: true,
                }}
                inputProps={{
                  input_type: 'password',
                  onChange: onPasswordConfirmChange.bind(this),
                  endAdornment: (
                    <InputAdornment position="end">
                      <LockOutline
                        className={classes.inputAdornmentIcon}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </CardBody>

            <CardFooter className={classes.justifyContentCenter}>
              <Button
                color="primary"
                round
                block
                onClick={onPasswordSetClick.bind(this)}
              >
                Set Password
              </Button>
              {props.requesting && <CircularProgress size={24} style={{ position: 'absolute', top: '74%', left: '47%' }} />}
            </CardFooter>

          </Card>
        </form>
      </GridItem>
    </GridContainer>
  );
};

export default SetPasswordComp;
