import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import InboundWebhookComp from './InboundWebhook/InboundWebhookComp';
import * as integrationActions from 'actions/networkadmin/IntegrationActions';

const InboundWebhook = (props) => {
  const { classes } = props;
  const { integration, defaultStates } = props;
  const {
    getSystemUser,
  } = props.integrationActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <InboundWebhookComp
        getSystemUser={getSystemUser}
        system_user={integration.system_user === undefined ? [] : integration.system_user}
        requesting={integration.requesting}
        message={integration.message}
        error_core={integration.error_code}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    integration: state.naIntegration,
    defaultStates: state.naDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    integrationActions: bindActionCreators(integrationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InboundWebhook);