import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import * as nameExclusionActions from 'actions/admin/NameExclusionActions';
import * as rolesActions from 'actions/admin/RolesActions';
import * as defaultActions from 'actions/admin/DefaultActions';
import NameExclusionComp from './NameExclusion/NameExclusionComp';

const NameExclusion = (props) => {
  const { classes } = props;
  const { nameExclusion, role, defaultStates } = props;
  const {
    getNameExclusions,
    createNameExclusion,
    updateNameExclusion,
    deleteNameExclusion,
  } = props.nameExclusionActions;
  const { getRoles } = props.rolesActions;
  const { resetMessage } = props.defaultActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <NameExclusionComp
        getRoles={getRoles}
        resetMessage={resetMessage}
        getNameExclusions={getNameExclusions}
        role_list={role.role_list.list === undefined ? [] : role.role_list.list}
        nameExclusion_list={nameExclusion.nameExclusion_list.list === undefined ? [] : nameExclusion.nameExclusion_list.list}
        createNameExclusion={createNameExclusion}
        updateNameExclusion={updateNameExclusion}
        deleteNameExclusion={deleteNameExclusion}
        refreshNameExclusion={nameExclusion.refreshNameExclusion}
        requesting={nameExclusion.requesting}
        message={nameExclusion.message}
        error_core={nameExclusion.error_core}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    nameExclusion: state.adNameExclusion,
    role: state.adRole,
    defaultStates: state.adDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    nameExclusionActions: bindActionCreators(nameExclusionActions, dispatch),
    rolesActions: bindActionCreators(rolesActions, dispatch),
    defaultActions: bindActionCreators(defaultActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NameExclusion);
