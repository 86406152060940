import {
    GET_INTG_EXT_API_REQUEST,
    GET_INTG_EXT_API_SUCCESS,
    GET_INTG_EXT_API_FAILURE,
    POST_INTG_EXT_API_REQUEST,
    POST_INTG_EXT_API_SUCCESS,
    POST_INTG_EXT_API_FAILURE,
    PATCH_INTG_EXT_API_REQUEST,
    PATCH_INTG_EXT_API_SUCCESS,
    PATCH_INTG_EXT_API_FAILURE,
    DELETE_INTG_EXT_API_REQUEST,
    DELETE_INTG_EXT_API_SUCCESS,
    DELETE_INTG_EXT_API_FAILURE,
  } from '../constants/networkadmin/IntgExtAPI';
  
  const initialState = {
    requesting: false,
    message: '',
    intg_ext_api_list: '',
    refreshIntgExtAPI: false,
  };
  
  export default function intgextapi(state = initialState, action) {
    switch (action.type) {
      case GET_INTG_EXT_API_REQUEST:
        return {
          ...state,
          requesting: action.payload.requesting,
          refreshIntgExtAPI: false,
          message: '',
        };
  
      case GET_INTG_EXT_API_SUCCESS:
        return {
          ...state,
          requesting: action.payload.requesting,
          message: action.payload.message,
          intg_ext_api_list: action.payload.data,
        };
  
      case GET_INTG_EXT_API_FAILURE:
        return {
          ...state,
          requesting: action.payload.requesting,
          message: action.payload.message,
        };
      case POST_INTG_EXT_API_REQUEST:
        return {
          ...state,
          requesting: action.payload.requesting,
          message: '',
          refreshIntgExtAPI: false,
        };
  
      case POST_INTG_EXT_API_SUCCESS:
        return {
          ...state,
          requesting: action.payload.requesting,
          message: action.payload.message,
          refreshIntgExtAPI: true,
        };
  
      case POST_INTG_EXT_API_FAILURE:
        return {
          ...state,
          requesting: action.payload.requesting,
          message: action.payload.message,
        };
      case PATCH_INTG_EXT_API_REQUEST:
        return {
          ...state,
          requesting: action.payload.requesting,
          message: '',
          refreshIntgExtAPI: false,
        };
  
      case PATCH_INTG_EXT_API_SUCCESS:
        return {
          ...state,
          requesting: action.payload.requesting,
          message: action.payload.message,
          refreshIntgExtAPI: true,
        };
  
      case PATCH_INTG_EXT_API_FAILURE:
        return {
          ...state,
          requesting: action.payload.requesting,
          message: action.payload.message,
        };
      case DELETE_INTG_EXT_API_REQUEST:
        return {
          ...state,
          requesting: action.payload.requesting,
          message: '',
          refreshIntgExtAPI: false,
        };
  
      case DELETE_INTG_EXT_API_SUCCESS:
        return {
          ...state,
          requesting: action.payload.requesting,
          message: action.payload.message,
          refreshIntgExtAPI: true,
        };
  
      case DELETE_INTG_EXT_API_FAILURE:
        return {
          ...state,
          requesting: action.payload.requesting,
          message: action.payload.message,
        };
  
      default:
        return state;
    }
  }
  
