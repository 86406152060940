import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as applicationStatusActions from 'actions/ApplicationStatusActions';
import AppActiveTaskComp from './AppActiveTask/AppActiveTaskComp';

const AppActiveTask = (props) => {

  const { applicationStatus } = props;
  const {
    getApplicationStatus,
  } = props.applicationStatusActions;

  return (
    <AppActiveTaskComp
      application_id={props.application_id}
      role_id={props.role_id}
      getApplicationStatus={getApplicationStatus}
      application_status_list={applicationStatus.application_status_list === undefined ? [] : applicationStatus.application_status_list}
      classes={props}
    />
  );
}

function mapStateToProps(state) {
  return {
    applicationStatus: state.applicationStatus,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    applicationStatusActions: bindActionCreators(applicationStatusActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(AppActiveTask);
