import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Button from 'components/CustomButtons/Button.jsx';
import IconButton from '@mui/material/IconButton';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import PostAddRounded from '@mui/icons-material/PostAddRounded';
import { Link } from 'react-router-dom'
import Checkbox from '@material-ui/core/Checkbox';
import withStyles from "@material-ui/core/styles/withStyles";
import extendedFormsStyle from "assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx";

const BatchUploadComp = (props) => {

  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [userFile, setUserFile] = useState(null)
  const [userFirstLineHeader, setUserFirstLineHeader] = useState(true)
  const [customFile, setCustomFile] = useState(null)
  const [customFirstLineHeader, setCustomFirstLineHeader] = useState(true)
  const [deptFile, setDeptFile] = useState(null)
  const [deptFirstLineHeader, setDeptFirstLineHeader] = useState(true)
  const [itemFile, setItemFile] = useState(null)
  const [itemFirstLineHeader, setItemFirstLineHeader] = useState(true)
  const [userIntgFields, setUserIntgFields] = useState('')
  const [deptIntgFields, setDeptIntgFields] = useState('')
  const [itemIntgFields, setItemIntgFields] = useState('')
  const [customIntgFields, setCustomIntgFields] = useState('')

  const { getBatchIntgs, getUserIntgFields, getDeptIntgFields, getCustomIntgFields, getItemIntgFields } = props;
  useEffect(() => {
    getBatchIntgs();
    getUserIntgFields();
    getDeptIntgFields();
    getCustomIntgFields();
    getItemIntgFields();
  }, [getBatchIntgs, getUserIntgFields, getDeptIntgFields, getCustomIntgFields, getItemIntgFields])


  useEffect(() => {
    setMessage(props.message)
    if (props.refreshIntgBatch) {
      props.getBatchIntgs()
    }
    var rowItems = [];
    for (let i = 0; i < props.intg_batch_list.length; i++) {
      var colItems = [];
      var link = "/networkadmin/batch-logs/" + props.intg_batch_list[i].id
      var date = new Date(props.intg_batch_list[i].insert_date)
      var completeDate = new Date(props.intg_batch_list[i].update_date)
      colItems.push(props.intg_batch_list[i].id);
      colItems.push(props.intg_batch_list[i].api_name);
      colItems.push(props.intg_batch_list[i].status ? "Completed" : "In Progress");
      colItems.push(date.toDateString() + " " + date.toLocaleTimeString());
      colItems.push(completeDate.toDateString() + " " + completeDate.toLocaleTimeString());
      colItems.push(<div>
        <IconButton title="Logs"><Link to={link}><PostAddRounded /></Link></IconButton>
      </div>);
      rowItems.push(colItems);
    }
    var tempUserIntgField = ''
    var tempDeptIntgField = ''
    var tempCustomIntgField = '['
    var tempItemIntgField = ''
    for (let i = 0; i < props.intg_user_field_list.length; i++) {
      tempUserIntgField = tempUserIntgField + "; " + props.intg_user_field_list[i].field_name;
    }
    for (let i = 0; i < props.intg_dept_field_list.length; i++) {
      tempDeptIntgField = tempDeptIntgField + "; " + props.intg_dept_field_list[i].field_name;
    }
    for (let i = 0; i < props.intg_custom_field_list.length; i++) {
      tempCustomIntgField = tempCustomIntgField + " " + props.intg_custom_field_list[i].field_name;
    }
    for (let i = 0; i < props.intg_item_field_list.length; i++) {
      tempItemIntgField = tempItemIntgField + "; " + props.intg_item_field_list[i].field_name;
    }
    setData(rowItems)
    setUserIntgFields(tempUserIntgField)
    setDeptIntgFields(tempDeptIntgField)
    setItemIntgFields(tempItemIntgField)
    setCustomIntgFields(tempCustomIntgField + ' ]')
  }, [props])

  const onFormSubmit = (e) => {
    e.preventDefault() // Stop form submit
    const formData = new FormData();
    formData.append('user_file', userFile)
    formData.append('user_header', userFirstLineHeader ? "1" : "0")
    formData.append('dept_file', deptFile)
    formData.append('dept_header', deptFirstLineHeader ? "1" : "0")
    props.postFileUpload(formData)
  }
  const onCustomFormSubmit = (e) => {
    e.preventDefault() // Stop form submit
    const formData = new FormData();
    formData.append('custom_file', customFile)
    formData.append('custom_header', customFirstLineHeader ? "1" : "0")
    props.postCustomFileUpload(formData)
  }
  const onItemFormSubmit = (e) => {
    e.preventDefault() // Stop form submit
    const formData = new FormData();
    formData.append('item_file', itemFile)
    formData.append('item_header', itemFirstLineHeader ? "1" : "0")
    props.postItemFileUpload(formData)
  }
  const onUserFileChange = (e) => {
    setUserFile(e.target.files[0])
  }
  const onCustomFileChange = (e) => {
    setCustomFile(e.target.files[0])
  }
  const onDeptFileChange = (e) => {
    setDeptFile(e.target.files[0])
  }
  const handleUserChecboxChange = (e) => {
    setUserFirstLineHeader(e.target.checked)
  }
  const handleCustomChecboxChange = (e) => {
    setCustomFirstLineHeader(e.target.checked)
  }
  const handleDeptChecboxChange = (e) => {
    setDeptFirstLineHeader(e.target.checked)
  }
  const onItemFileChange = (e) => {
    setItemFile(e.target.files[0])
  }
  const handleItemChecboxChange = (e) => {
    setItemFirstLineHeader(e.target.checked)
  }
  const handleClickRefreshBtn = (e) => {
    props.getBatchIntgs()
  }

  const { classes } = props;
  const columns = [
    "Batch ID",
    "API Name",
    "Status",
    "Started At",
    "Completed At",
    ""
  ];
  const options = {
    filterType: "dropdown",
    responsive: "standard",
    filter: true,
    selectableRows: "none",
    rowsPerPage: 100,
    selectToolbarPlacement: "none"
  };

  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Upload User and Department Files</b>
        </CardHeader>
        <CardBody>
          <div style={{ color: props.error_userdept ? 'red' : 'green', textAlign: 'center' }}>{props.message_userdept}</div>
          <Card>
            <CardBody>
              <form onSubmit={onFormSubmit.bind(this)}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <input
                          type="file"
                          onChange={onUserFileChange.bind(this)}
                          style={{ display: 'none' }}
                          id="raised-button-userfile"
                        />
                        <label htmlFor="raised-button-userfile">
                          <Button round color="primary" component="span" >
                            Select a User File
                          </Button>
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={8}>
                        <b>Selected User File:</b>&nbsp;{userFile !== null ? userFile.name : ""}
                        <br />
                        First line in the file is a header:
                        <Checkbox
                          checked={userFirstLineHeader}
                          onChange={handleUserChecboxChange.bind(this)}
                          name="checkedB"
                          color="primary"
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        User Field Order for CSV file (6 Mandatory fields + [additional fields])
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <code>email; department name; user name; status; rank; user type [{userIntgFields}] </code>
                      </GridItem>
                    </GridContainer>
                    <br />
                    <hr />
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <input
                          type="file"
                          onChange={onDeptFileChange.bind(this)}
                          style={{ display: 'none' }}
                          id="raised-button-deptfile"
                        />
                        <label htmlFor="raised-button-deptfile">
                          <Button round color="primary" component="span" >
                            Select a Dept File
                          </Button>
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={8}>
                        <b>Selected Dept File:</b>&nbsp;{deptFile !== null ? deptFile.name : ""}
                        <br />
                        First line in the file is a header:
                        <Checkbox
                          checked={deptFirstLineHeader}
                          onChange={handleDeptChecboxChange.bind(this)}
                          name="checkedB"
                          color="primary"
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        Department Field Order for CSV file (6 Mandatory fields + [additional fields])
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <code>department name; short name; status; Head Email; Parent Dept ID; alias [{deptIntgFields}]</code>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <br />
                <hr />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Button
                      round
                      color="primary"
                      type="submit"
                      style={{ float: 'right' }}
                      disabled={userFile === null || deptFile === null}>
                      Upload Files
                    </Button>
                  </GridItem>
                </GridContainer>

              </form>

            </CardBody>
          </Card>
        </CardBody>
      </Card>
      <br />
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Upload Custom Fields File</b>
        </CardHeader>
        <CardBody>
          <div style={{ color: props.error_custom ? 'red' : 'green', textAlign: 'center' }}>{props.message_custom}</div>
          <Card>
            <CardBody>
              <form onSubmit={onCustomFormSubmit.bind(this)}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <input
                          type="file"
                          onChange={onCustomFileChange.bind(this)}
                          style={{ display: 'none' }}
                          id="raised-button-customfile"
                        />
                        <label htmlFor="raised-button-customfile">
                          <Button round color="primary" component="span" >
                            Select a Custom File
                          </Button>
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={8}>
                        <b>Selected Custom File:</b>&nbsp;{customFile !== null ? customFile.name : ""}
                        <br />
                        First line in the file is a header:
                        <Checkbox
                          checked={customFirstLineHeader}
                          onChange={handleCustomChecboxChange.bind(this)}
                          name="checkedB"
                          color="primary"
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        Custom Field Order for CSV file
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <code>{customIntgFields}; id; value </code>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <br />
                <hr />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Button
                      round
                      color="primary"
                      type="submit"
                      style={{ float: 'right' }}
                      disabled={customFile === null}>
                      Upload Custom File
                    </Button>
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
      <br />
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Upload Item File</b>
        </CardHeader>
        <CardBody>
          <div style={{ color: props.error_item ? 'red' : 'green', textAlign: 'center' }}>{props.message_item}</div>
          <Card>
            <CardBody>
              <form onSubmit={onItemFormSubmit.bind(this)}>
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={4}>
                        <input
                          type="file"
                          onChange={onItemFileChange.bind(this)}
                          style={{ display: 'none' }}
                          id="raised-button-itemfile"
                        />
                        <label htmlFor="raised-button-itemfile">
                          <Button round color="primary" component="span" >
                            Select a Item File
                          </Button>
                        </label>
                      </GridItem>
                      <GridItem xs={12} sm={12} md={8}>
                        <b>Selected Item File:</b>&nbsp;{itemFile !== null ? itemFile.name : ""}
                        <br />
                        First line in the file is a header:
                        <Checkbox
                          checked={itemFirstLineHeader}
                          onChange={handleItemChecboxChange.bind(this)}
                          name="checkedB"
                          color="primary"
                        />
                      </GridItem>
                    </GridContainer>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        Item Field Order for CSV file
                      </GridItem>
                      <GridItem xs={12} sm={12} md={12}>
                        <code>item_sku; name; related_item; relation;  status; item_meta;	search_level; docvalue; intgvalue </code>
                      </GridItem>
                    </GridContainer>
                  </GridItem>
                </GridContainer>
                <br />
                <hr />
                <GridContainer>
                  <GridItem xs={12} sm={12} md={12}>
                    <Button
                      round
                      color="primary"
                      type="submit"
                      style={{ float: 'right' }}
                      disabled={itemFile === null}>
                      Upload Item File
                    </Button>
                  </GridItem>
                </GridContainer>
              </form>
            </CardBody>
          </Card>
        </CardBody>
      </Card>
      <br />
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>Batch Upload Log</b>
          <p className={classes.cardCategoryWhite}>
          </p>
        </CardHeader>
        <CardBody>
          <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
          <MUIDataTable
            title={<div>
              <Button onClick={handleClickRefreshBtn.bind(this)} color="primary" title="Refresh Upload Batch Log" round> Refresh Upload Batch Log</Button>
            </div>}
            data={data}
            columns={columns}
            options={options}
          />
        </CardBody>
      </Card>
    </div >
  );
}

export default withStyles(extendedFormsStyle)(BatchUploadComp)
