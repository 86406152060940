import API from 'Api/api.js';
import { commonBackendCall, getAdminConfig } from 'Api/common.js';
import {
  GET_TASK_REQUEST,
  GET_TASK_SUCCESS,
  GET_TASK_FAILURE,
  POST_TASK_REQUEST,
  POST_TASK_SUCCESS,
  POST_TASK_FAILURE,
  PATCH_TASK_REQUEST,
  PATCH_TASK_SUCCESS,
  PATCH_TASK_FAILURE,
  DELETE_TASK_REQUEST,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_FAILURE,
} from 'constants/admin/Task';

export function getTasks() {
  return commonBackendCall(
    GET_TASK_REQUEST,
    GET_TASK_SUCCESS,
    GET_TASK_FAILURE,
    API.get('admin/tasks', getAdminConfig()),
  );
}
export function createTask(obj) {
  return commonBackendCall(
    POST_TASK_REQUEST,
    POST_TASK_SUCCESS,
    POST_TASK_FAILURE,
    API.post('admin/tasks', obj, getAdminConfig()),
  );
}
export function updateTask(taskID, obj) {
  return commonBackendCall(
    PATCH_TASK_REQUEST,
    PATCH_TASK_SUCCESS,
    PATCH_TASK_FAILURE,
    API.patch(`admin/tasks/${taskID}`, obj, getAdminConfig()),
  );
}
export function deleteTask(taskID) {
  return commonBackendCall(
    DELETE_TASK_REQUEST,
    DELETE_TASK_SUCCESS,
    DELETE_TASK_FAILURE,
    API.delete(`admin/tasks/${taskID}`, getAdminConfig()),
  );
}
