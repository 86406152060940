import React from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import withStyles from '@material-ui/core/styles/withStyles';
import loginPageStyle from 'assets/jss/material-dashboard-react/views/loginPageStyle.jsx';
import { Navigate } from 'react-router-dom';
import * as loginActions from 'actions/admin/LoginActions';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import ChooseWorkflowComp from './ChooseWorkflow/ChooseWorkflowComp';

const ChangeWorkflow = (props) => {
  const { classes } = props;
  const { login, defaultStates } = props;
  const {
    onLogin,
    getNetworkWorkflows,
  } = props.loginActions;

  if (login.login_success) {
    return <Navigate to="/admin/dashboard" />;
  }

  return (
    <div className={classes.content}>
      <div className={classes.container}>
        {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
        <ChooseWorkflowComp
          requesting={login.requesting}
          message={login.message}
          error_code={login.error_code}
          onLogin={onLogin}
          getNetworkWorkflows={getNetworkWorkflows}
          workflow_list={login.workflow_list === undefined ? [] : login.workflow_list}
          classes={classes}
          loggedIn={login.loggedIn}
        />
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    login: state.adLogin,
    defaultStates: state.adDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    loginActions: bindActionCreators(loginActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(loginPageStyle)(ChangeWorkflow));
