import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as lookupActions from 'actions/LookupActions';
import FormPrintComp from './FormPrint/FormPrintComp';

const FormPrint = (props) => {
  const { classes } = props;
  const { lookup } = props;
  const { getFormDataFile } = props.lookupActions;

  return (
    <FormPrintComp
      getFormDataFile={getFormDataFile}
      formdata_file={lookup.formdata_file}
      form_id={props.form_id}
      application_id={props.application_id}
      fields={props.fields}
      form_array={props.form_array}
      form_print={props.form_print}
      classes={classes}
    />
  );
}

function mapStateToProps(state) {
  return {
    lookup: state.lookup,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    lookupActions: bindActionCreators(lookupActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FormPrint);
