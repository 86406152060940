import React, { useState, useEffect } from 'react';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx';
import TextField from '@material-ui/core/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from 'components/Card/CardFooter.jsx';
import Button from 'components/CustomButtons/Button.jsx';


const RuleJSONEditComp = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const [ruleJSONValue, setRuleJSONValue] = useState('');

  useEffect(() => {
    setRuleJSONValue(props.rule_value)
  }, [props]);

  const onRuleJSONValueChange = (e) => {
    setRuleJSONValue(e.target.value);
  };

  const handleDialogClose = (e) => {
    setDialogOpen(false);
  };
  const handleDialogOpen = (e) => {
    setDialogOpen(true);
  };

  const handleDialogConfirm = (e) => {
    switch (props.rule_type) {
      case "REQUIRED_RULE":
        props.updateField(props.field_id, {
          required_rule: ruleJSONValue
        })
        break;
      case "VISIBLE_RULE":
        props.updateField(props.field_id, {
          visible_rule: ruleJSONValue
        })
        break;
      case "VALIDATION_RULE":
        props.updateField(props.field_id, {
          validation_rule: ruleJSONValue
        })
        break;
    }
    setDialogOpen(false);
  }
  const { classes } = props;
  return (
    <div>
      <Button onClick={handleDialogOpen.bind(this)} color="primary" round title={props.field_id}>
        Edit JSON
      </Button>
      <Dialog fullWidth open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
        <DialogContent>
          <Card>
            <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
              <b>Edit {props.rule_label} JSON</b>
            </CardHeader>
            <CardBody>

              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                  <TextField
                    required={false}
                    fullWidth
                    rows={"10"}
                    multiline={true}
                    label={props.rule_label}
                    value={ruleJSONValue}
                    onChange={onRuleJSONValueChange.bind(this)}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                </GridItem>
              </GridContainer>
            </CardBody>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose.bind(this)} color="primary" round>
            Cancel
          </Button>
          <Button
            onClick={handleDialogConfirm.bind(this)}
            color="primary"
            round
            title={props.field_id}
          >
            Save {props.rule_label}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default withStyles(extendedFormsStyle)(RuleJSONEditComp);
