import {
  GET_ACTIONDEPENDENCY_REQUEST,
  GET_ACTIONDEPENDENCY_SUCCESS,
  GET_ACTIONDEPENDENCY_FAILURE,
  POST_ACTIONDEPENDENCY_REQUEST,
  POST_ACTIONDEPENDENCY_SUCCESS,
  POST_ACTIONDEPENDENCY_FAILURE,
  PATCH_ACTIONDEPENDENCY_REQUEST,
  PATCH_ACTIONDEPENDENCY_SUCCESS,
  PATCH_ACTIONDEPENDENCY_FAILURE,
  DELETE_ACTIONDEPENDENCY_REQUEST,
  DELETE_ACTIONDEPENDENCY_SUCCESS,
  DELETE_ACTIONDEPENDENCY_FAILURE,
} from '../constants/admin/ActionDependency';
import {
  RESET_MESSAGE,
} from '../constants/admin/Default';

const initialState = {
  requesting: false,
  message: '',
  actionDependency_list: [],
  refreshActionDependency: false,
};

export default function actionDependency(state = initialState, action) {
  switch (action.type) {
    case RESET_MESSAGE:
      return {
        ...state,
        message: action.payload.value,
      };
    case GET_ACTIONDEPENDENCY_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        actionDependency_list: [],
        refreshActionDependency: false,
        message: '',
      };

    case GET_ACTIONDEPENDENCY_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        actionDependency_list: action.payload.data,
      };

    case GET_ACTIONDEPENDENCY_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case POST_ACTIONDEPENDENCY_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshActionDependency: false,
      };

    case POST_ACTIONDEPENDENCY_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshActionDependency: true,
      };

    case POST_ACTIONDEPENDENCY_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case PATCH_ACTIONDEPENDENCY_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshActionDependency: false,
      };

    case PATCH_ACTIONDEPENDENCY_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshActionDependency: true,
      };

    case PATCH_ACTIONDEPENDENCY_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case DELETE_ACTIONDEPENDENCY_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshActionDependency: false,
      };

    case DELETE_ACTIONDEPENDENCY_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshActionDependency: true,
      };

    case DELETE_ACTIONDEPENDENCY_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
