import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import * as applicationDetailActions from 'actions/ApplicationDetailActions';
import * as applicationStatusActions from 'actions/ApplicationStatusActions';
import * as applicationActions from 'actions/ApplicationActions';
import * as defaultActions from 'actions/DefaultActions';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import ApplicationDetailComp from './ApplicationDetail/ApplicationDetailComp';

const ApplicationDetail = (props) => {
  const { application_id } = useParams();
  const { role_id } = useParams();
  const { task_id } = useParams();
  const { classes } = props;
  const { applicationDetail, application, defaultStates } = props;
  const {
    getApplicationDetailForm,
    getApplicationDetailCompletedTask,
    getApplicationMessages,
    postApplicationDetail,
    postApplicationDetailAction,
    getApplicationRoles,
    setFormSuccessMsg,
    setFormErrorMsg,
    setFields,
    setShowForm,
    resetRequesting,
    postItem,
    postUpdateItemDetail,
    patchItemPercent,
    // setWeekSelectField,
  } = props.applicationDetailActions;

  const { getApplication } = props.applicationActions;
  const { getRole, patchApplicationRemind } = props.applicationStatusActions;

  if (applicationDetail.redirectApplicationDetailAction) {
    var linkStr = `/status/${application_id}/${role_id}`;
    return <Navigate to={linkStr} />;
  }

  return (
    <div>
      {defaultStates.unDefinedError
        ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <ApplicationDetailComp
        application_id={application_id}
        role_id={role_id}
        task_id={task_id}
        getApplication={getApplication}
        getRole={getRole}
        // setWeekSelectField={setWeekSelectField}
        setFormSuccessMsg={setFormSuccessMsg}
        setFormErrorMsg={setFormErrorMsg}
        setFields={setFields}
        setShowForm={setShowForm}
        showForm={applicationDetail.showForm}
        getApplicationRoles={getApplicationRoles}
        getApplicationDetailForm={getApplicationDetailForm}
        getApplicationDetailCompletedTask={getApplicationDetailCompletedTask}
        getApplicationMessages={getApplicationMessages}
        postApplicationDetail={postApplicationDetail}
        postApplicationDetailAction={postApplicationDetailAction}
        application_detail_form={applicationDetail.application_detail_form}
        application_detail_messages={applicationDetail.application_detail_messages}
        application_roles={applicationDetail.application_roles}
        application={application.application}
        refreshApplicationDetailForm={applicationDetail.refreshApplicationDetailForm}
        refreshApplicationRoles={applicationDetail.refreshApplicationRoles}
        showFormSuccessMsg={applicationDetail.showFormSuccessMsg}
        showFormErrorMsg={applicationDetail.showFormErrorMsg}
        fields={applicationDetail.fields}
        requesting={applicationDetail.requesting}
        resetRequesting={resetRequesting}
        postItem={postItem}
        postUpdateItemDetail={postUpdateItemDetail}
        patchItemPercent={patchItemPercent}
        patchApplicationRemind={patchApplicationRemind}
        refreshRelatedItemSearch={applicationDetail.refreshRelatedItemSearch}
        // weekSelect={applicationDetail.weekSelect}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    applicationDetail: state.applicationDetail,
    applicationStatus: state.applicationStatus,
    application: state.application,
    defaultStates: state.defaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    applicationDetailActions: bindActionCreators(applicationDetailActions, dispatch),
    applicationStatusActions: bindActionCreators(applicationStatusActions, dispatch),
    applicationActions: bindActionCreators(applicationActions, dispatch),
    defaultActions: bindActionCreators(defaultActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApplicationDetail);
