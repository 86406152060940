import API from 'Api/api.js';
import { commonBackendCall, getAdminConfig } from 'Api/common.js';
import {
  GET_ACTIONDEPENDENCY_REQUEST,
  GET_ACTIONDEPENDENCY_SUCCESS,
  GET_ACTIONDEPENDENCY_FAILURE,
  POST_ACTIONDEPENDENCY_REQUEST,
  POST_ACTIONDEPENDENCY_SUCCESS,
  POST_ACTIONDEPENDENCY_FAILURE,
  PATCH_ACTIONDEPENDENCY_REQUEST,
  PATCH_ACTIONDEPENDENCY_SUCCESS,
  PATCH_ACTIONDEPENDENCY_FAILURE,
  DELETE_ACTIONDEPENDENCY_REQUEST,
  DELETE_ACTIONDEPENDENCY_SUCCESS,
  DELETE_ACTIONDEPENDENCY_FAILURE,
} from 'constants/admin/ActionDependency';

export function getActionDependencies() {
  return commonBackendCall(
    GET_ACTIONDEPENDENCY_REQUEST,
    GET_ACTIONDEPENDENCY_SUCCESS,
    GET_ACTIONDEPENDENCY_FAILURE,
    API.get('admin/action-dependency', getAdminConfig()),
  );
}
export function createActionDependency(obj) {
  return commonBackendCall(
    POST_ACTIONDEPENDENCY_REQUEST,
    POST_ACTIONDEPENDENCY_SUCCESS,
    POST_ACTIONDEPENDENCY_FAILURE,
    API.post('admin/action-dependency', obj, getAdminConfig()),
  );
}
export function updateActionDependency(actionDependencyID, obj) {
  return commonBackendCall(
    PATCH_ACTIONDEPENDENCY_REQUEST,
    PATCH_ACTIONDEPENDENCY_SUCCESS,
    PATCH_ACTIONDEPENDENCY_FAILURE,
    API.patch(`admin/action-dependency/${actionDependencyID}`, obj, getAdminConfig()),
  );
}
export function deleteActionDependency(actionDependencyID) {
  return commonBackendCall(
    DELETE_ACTIONDEPENDENCY_REQUEST,
    DELETE_ACTIONDEPENDENCY_SUCCESS,
    DELETE_ACTIONDEPENDENCY_FAILURE,
    API.delete(`admin/action-dependency/${actionDependencyID}`, getAdminConfig()),
  );
}
