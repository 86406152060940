import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import DepartmentComp from './Department/DepartmentComp';
import * as departmentActions from 'actions/networkadmin/DepartmentActions';
import * as userActions from 'actions/UserActions';

const Department = (props) => {
  const { classes } = props;
  const { department, defaultStates, user } = props;
  const {
    getDepartments,
    getDepartmentsCSV,
    createDepartment,
    updateDepartment,
    deleteDepartment,
  } = props.departmentActions;
  const { getUsers, findUsers, setSelectedUser } = props.userActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <DepartmentComp
        getDepartments={getDepartments}
        getDepartmentsCSV={getDepartmentsCSV}
        getUsers={getUsers}
        findUsers={findUsers}
        createDepartment={createDepartment}
        updateDepartment={updateDepartment}
        deleteDepartment={deleteDepartment}
        setSelectedUser={setSelectedUser}
        selected_user_id={user.selected_user_id}
        selected_user={user.selected_user}
        department_list={department.department_list.list === undefined ? [] : department.department_list.list}
        department_list_csv={department.department_list_csv === undefined ? [] : department.department_list_csv}
        refreshDepartment={department.refreshDepartment}
        requesting={department.requesting}
        message={department.message}
        error_core={department.error_core}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    department: state.naDepartment,
    user: state.user,
    defaultStates: state.naDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    departmentActions: bindActionCreators(departmentActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Department);
