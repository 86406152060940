import API from 'Api/api.js';
import { commonBackendCall, getNetworkAdminConfig } from 'Api/common.js';
import {
  GET_MESSAGE_TEMPLATE_REQUEST,
  GET_MESSAGE_TEMPLATE_SUCCESS,
  GET_MESSAGE_TEMPLATE_FAILURE,
  POST_MESSAGE_TEMPLATE_REQUEST,
  POST_MESSAGE_TEMPLATE_SUCCESS,
  POST_MESSAGE_TEMPLATE_FAILURE,
  PATCH_MESSAGE_TEMPLATE_REQUEST,
  PATCH_MESSAGE_TEMPLATE_SUCCESS,
  PATCH_MESSAGE_TEMPLATE_FAILURE,
  DELETE_MESSAGE_TEMPLATE_REQUEST,
  DELETE_MESSAGE_TEMPLATE_SUCCESS,
  DELETE_MESSAGE_TEMPLATE_FAILURE,
} from 'constants/networkadmin/MessageTemplate';

export function getMessageTemplates() {
  return commonBackendCall(
    GET_MESSAGE_TEMPLATE_REQUEST,
    GET_MESSAGE_TEMPLATE_SUCCESS,
    GET_MESSAGE_TEMPLATE_FAILURE,
    API.get('admin/message-templates', getNetworkAdminConfig()),
  );
}
export function createMessageTemplate(obj) {
  return commonBackendCall(
    POST_MESSAGE_TEMPLATE_REQUEST,
    POST_MESSAGE_TEMPLATE_SUCCESS,
    POST_MESSAGE_TEMPLATE_FAILURE,
    API.post('admin/message-templates', obj, getNetworkAdminConfig()),
  );
}
export function updateMessageTemplate(workspaceID, obj) {
  return commonBackendCall(
    PATCH_MESSAGE_TEMPLATE_REQUEST,
    PATCH_MESSAGE_TEMPLATE_SUCCESS,
    PATCH_MESSAGE_TEMPLATE_FAILURE,
    API.patch(`admin/message-templates/${workspaceID}`, obj, getNetworkAdminConfig()),
  );
}
export function deleteMessageTemplate(workspaceID) {
  return commonBackendCall(
    DELETE_MESSAGE_TEMPLATE_REQUEST,
    DELETE_MESSAGE_TEMPLATE_SUCCESS,
    DELETE_MESSAGE_TEMPLATE_FAILURE,
    API.delete(`admin/message-templates/${workspaceID}`, getNetworkAdminConfig()),
  );
}
