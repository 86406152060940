import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import * as taskDependencyActions from 'actions/admin/TaskDependencyActions';
import * as taskActions from 'actions/admin/TaskActions';
import * as defaultActions from 'actions/admin/DefaultActions';
import TaskDependencyComp from './TaskDependency/TaskDependencyComp';

const TaskDependency = (props) => {
  const { classes } = props;
  const { task, taskDependency, defaultStates } = props;
  const {
    getTaskDependencies,
    createTaskDependency,
    updateTaskDependency,
    deleteTaskDependency,
  } = props.taskDependencyActions;
  const { resetMessage } = props.defaultActions;
  const { getTasks } = props.taskActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <TaskDependencyComp
        getTasks={getTasks}
        resetMessage={resetMessage}
        getTaskDependencies={getTaskDependencies}
        task_list={task.task_list.list === undefined ? [] : task.task_list.list}
        taskDependency_list={taskDependency.taskDependency_list.list === undefined ? [] : taskDependency.taskDependency_list.list}
        createTaskDependency={createTaskDependency}
        updateTaskDependency={updateTaskDependency}
        deleteTaskDependency={deleteTaskDependency}
        refreshTaskDependency={taskDependency.refreshTaskDependency}
        requesting={taskDependency.requesting}
        message={taskDependency.message}
        error_core={taskDependency.error_core}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    taskDependency: state.adTaskDependency,
    task: state.adTask,
    defaultStates: state.adDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    taskDependencyActions: bindActionCreators(taskDependencyActions, dispatch),
    taskActions: bindActionCreators(taskActions, dispatch),
    defaultActions: bindActionCreators(defaultActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(TaskDependency);
