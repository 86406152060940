import {
  GET_ADMIN_DASH_REQUEST,
  GET_ADMIN_DASH_SUCCESS,
  GET_ADMIN_DASH_FAILURE,
  PATCH_WORKFLOW_STATE_REQUEST,
  PATCH_WORKFLOW_STATE_SUCCESS,
  PATCH_WORKFLOW_STATE_FAILURE,
  GET_SENT_EMAIL_ADMIN_REQUEST,
  GET_SENT_EMAIL_ADMIN_SUCCESS,
  GET_SENT_EMAIL_ADMIN_FAILURE,
} from 'constants/admin/Dashboard';

import {
  GET_WORKFLOW_REQUEST,
} from '../constants/admin/Workflow';

const initialState = {
  requesting: false,
  message: '',
  admin_dashboard: '',
  refreshDashboard: false,
  patchCompleted: false,
  sanityProblems: [],
  sent_email_admin_list: [],

};

export default function dashboard(state = initialState, action) {
  switch (action.type) {
    case GET_ADMIN_DASH_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshDashboard: false,
        message: '',
      };

    case GET_ADMIN_DASH_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        admin_dashboard: action.payload.data,
      };

    case GET_ADMIN_DASH_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case PATCH_WORKFLOW_STATE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        patchCompleted: false,
      };

    case PATCH_WORKFLOW_STATE_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        sanityProblems: action.payload.data,
        patchCompleted: true,
      };

    case PATCH_WORKFLOW_STATE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case GET_WORKFLOW_REQUEST:
      return {
        ...state,
        patchCompleted: false,
      };
    case GET_SENT_EMAIL_ADMIN_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshUnsentErrorEmail: false,
        message: '',
      };

    case GET_SENT_EMAIL_ADMIN_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        sent_email_admin_list: action.payload.data,
      };

    case GET_SENT_EMAIL_ADMIN_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    /*
    case POST_REFRESH_ADMIN_TOKEN_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        error_code: '',
        refreshWorkflowToken: false,
      };

    case POST_REFRESH_ADMIN_TOKEN_SUCCESS:
      window.location.reload();
      return {
        ...state,
        requesting: action.payload.requesting,
      };

    case POST_REFRESH_ADMIN_TOKEN_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        error_code: action.payload.error_code,
      };
      */
    default:
      return state;
  }
}
