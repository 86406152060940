import {
  GET_PERMCOMM_REQUEST,
  GET_PERMCOMM_SUCCESS,
  GET_PERMCOMM_FAILURE,
  POST_PERMCOMM_REQUEST,
  POST_PERMCOMM_SUCCESS,
  POST_PERMCOMM_FAILURE,
  PATCH_PERMCOMM_REQUEST,
  PATCH_PERMCOMM_SUCCESS,
  PATCH_PERMCOMM_FAILURE,
  DELETE_PERMCOMM_REQUEST,
  DELETE_PERMCOMM_SUCCESS,
  DELETE_PERMCOMM_FAILURE,
  GET_PERMCOMM_ROLE_REQUEST,
  GET_PERMCOMM_ROLE_SUCCESS,
  GET_PERMCOMM_ROLE_FAILURE,
  GET_COMM_PERMCOMM_REQUEST,
  GET_COMM_PERMCOMM_SUCCESS,
  GET_COMM_PERMCOMM_FAILURE,
} from '../constants/admin/PermComm';
import {
  RESET_MESSAGE,
} from '../constants/admin/Default';

const initialState = {
  requesting: false,
  message: '',
  permComm_list: [],
  permComm_role_list: [],
  commPermComm_list: [],
  refreshPermComm: false,
};

export default function form(state = initialState, action) {
  switch (action.type) {
    case RESET_MESSAGE:
      return {
        ...state,
        message: action.payload.value,
      };
    case GET_COMM_PERMCOMM_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshPermComm: false,
        message: '',
      };

    case GET_COMM_PERMCOMM_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        commPermComm_list: action.payload.data,
      };

    case GET_COMM_PERMCOMM_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case GET_PERMCOMM_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshPermComm: false,
        message: '',
      };

    case GET_PERMCOMM_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        permComm_list: action.payload.data,
      };

    case GET_PERMCOMM_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case GET_PERMCOMM_ROLE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshPermComm: false,
        message: '',
      };

    case GET_PERMCOMM_ROLE_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        permComm_role_list: action.payload.data,
      };

    case GET_PERMCOMM_ROLE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case POST_PERMCOMM_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshPermComm: false,
      };

    case POST_PERMCOMM_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshPermComm: true,
      };

    case POST_PERMCOMM_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case PATCH_PERMCOMM_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshPermComm: false,
      };

    case PATCH_PERMCOMM_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshPermComm: true,
      };

    case PATCH_PERMCOMM_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case DELETE_PERMCOMM_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshPermComm: false,
      };

    case DELETE_PERMCOMM_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshPermComm: true,
      };

    case DELETE_PERMCOMM_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
