/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Outlet } from 'react-router-dom';

// @material-ui/core components
import withStyles from '@material-ui/core/styles/withStyles';

// core components
import HeaderPublic from 'components/Header/HeaderPublic';
import Footer from 'components/Footer/Footer';
//import pagesRoutes from 'routes/public';
import pagesStyle from 'assets/jss/material-dashboard-react/layouts/pagesStyle';
import logo from 'assets/img/logo/molecule-72-192917.png';

const Pages = (props) => {
  const bgImage = localStorage.getItem('network_bg_image');
  const { classes, ...rest } = props;
  return (
    <div>
      <HeaderPublic logo={logo} {...rest} />
      <div className={classes.wrapper}>
        <div className={classes.fullPage}>
          <Outlet />
          <Footer white />
          <div
            className={classes.fullPageBackground}
            style={{ backgroundImage: `url(${bgImage})` }}
          />
        </div>
      </div>
    </div>
  );
}


Pages.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(pagesStyle)(Pages);
