import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import * as commNameActions from 'actions/admin/CommNameActions';
import CommNameComp from './CommName/CommNameComp';

const CommName = (props) => {
  const { classes } = props;
  const { commName, defaultStates } = props;
  const {
    getCommNames,
    createCommName,
    updateCommName,
    deleteCommName,
    getNormalTasks,
    getPhases,
  } = props.commNameActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <CommNameComp
        getCommNames={getCommNames}
        getNormalTasks={getNormalTasks}
        getPhases={getPhases}
        commName_list={commName.commName_list.list === undefined ? [] : commName.commName_list.list}
        commName_task_list={commName.commName_task_list === undefined ? [] : commName.commName_task_list}
        commName_phase_list={commName.commName_phase_list.list === undefined ? [] : commName.commName_phase_list.list}
        createCommName={createCommName}
        updateCommName={updateCommName}
        deleteCommName={deleteCommName}
        refreshCommName={commName.refreshCommName}
        requesting={commName.requesting}
        message={commName.message}
        error_core={commName.error_core}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    commName: state.adCommName,
    defaultStates: state.adDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    commNameActions: bindActionCreators(commNameActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CommName);
