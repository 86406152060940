import {
  GET_MESSAGE_TEMPLATE_REQUEST,
  GET_MESSAGE_TEMPLATE_SUCCESS,
  GET_MESSAGE_TEMPLATE_FAILURE,
  POST_MESSAGE_TEMPLATE_REQUEST,
  POST_MESSAGE_TEMPLATE_SUCCESS,
  POST_MESSAGE_TEMPLATE_FAILURE,
  PATCH_MESSAGE_TEMPLATE_REQUEST,
  PATCH_MESSAGE_TEMPLATE_SUCCESS,
  PATCH_MESSAGE_TEMPLATE_FAILURE,
  DELETE_MESSAGE_TEMPLATE_REQUEST,
  DELETE_MESSAGE_TEMPLATE_SUCCESS,
  DELETE_MESSAGE_TEMPLATE_FAILURE,
} from '../constants/networkadmin/MessageTemplate';

const initialState = {
  requesting: false,
  message: '',
  message_template_list: [],
  refreshMessageTemplate: false,
};

export default function messageTemplate(state = initialState, action) {
  switch (action.type) {
    case GET_MESSAGE_TEMPLATE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshMessageTemplate: false,
        message: '',
      };

    case GET_MESSAGE_TEMPLATE_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        message_template_list: action.payload.data,
      };

    case GET_MESSAGE_TEMPLATE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case POST_MESSAGE_TEMPLATE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshMessageTemplate: false,
      };

    case POST_MESSAGE_TEMPLATE_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshMessageTemplate: true,
      };

    case POST_MESSAGE_TEMPLATE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case PATCH_MESSAGE_TEMPLATE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshMessageTemplate: false,
      };

    case PATCH_MESSAGE_TEMPLATE_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshMessageTemplate: true,
      };

    case PATCH_MESSAGE_TEMPLATE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case DELETE_MESSAGE_TEMPLATE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshMessageTemplate: false,
      };

    case DELETE_MESSAGE_TEMPLATE_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshMessageTemplate: true,
      };

    case DELETE_MESSAGE_TEMPLATE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
