import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import BatchUploadComp from './BatchUpload/BatchUploadComp';
import * as integrationActions from 'actions/networkadmin/IntegrationActions';

const BatchUpload = (props) => {
  const { classes } = props;
  const { integration, defaultStates } = props;
  const {
    getBatchIntgs,
    postFileUpload,
    postCustomFileUpload,
    postItemFileUpload,
    getUserIntgFields,
    getCustomIntgFields,
    getDeptIntgFields,
    getItemIntgFields,
  } = props.integrationActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <BatchUploadComp
        getUserIntgFields={getUserIntgFields}
        getCustomIntgFields={getCustomIntgFields}
        getDeptIntgFields={getDeptIntgFields}
        getItemIntgFields={getItemIntgFields}
        getBatchIntgs={getBatchIntgs}
        postFileUpload={postFileUpload}
        postCustomFileUpload={postCustomFileUpload}
        postItemFileUpload={postItemFileUpload}
        intg_batch_list={integration.intg_batch_list === undefined ? [] : integration.intg_batch_list}
        intg_user_field_list={integration.intg_user_field_list === undefined ? [] : integration.intg_user_field_list}
        intg_dept_field_list={integration.intg_dept_field_list === undefined ? [] : integration.intg_dept_field_list}
        intg_custom_field_list={integration.intg_custom_field_list === undefined ? [] : integration.intg_custom_field_list}
        intg_item_field_list={integration.intg_item_field_list === undefined ? [] : integration.intg_item_field_list}
        refreshIntgBatch={integration.refreshIntgBatch}
        requesting={integration.requesting}
        message={integration.message}
        error_core={integration.error_code}
        message_userdept={integration.message_userdept}
        message_custom={integration.message_custom}
        message_item={integration.message_item}
        error_userdept={integration.error_userdept}
        error_custom={integration.error_custom}
        error_item={integration.error_item}
        classes={classes}
      />
    </div>
  );
}


function mapStateToProps(state) {
  return {
    integration: state.naIntegration,
    defaultStates: state.naDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    integrationActions: bindActionCreators(integrationActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(BatchUpload);
