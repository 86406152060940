import {
  GET_ALLOW_APP_CREATE_REQUEST,
  GET_ALLOW_APP_CREATE_SUCCESS,
  GET_ALLOW_APP_CREATE_FAILURE,
  POST_ALLOW_APP_CREATE_REQUEST,
  POST_ALLOW_APP_CREATE_SUCCESS,
  POST_ALLOW_APP_CREATE_FAILURE,
  DELETE_ALLOW_APP_CREATE_REQUEST,
  DELETE_ALLOW_APP_CREATE_SUCCESS,
  DELETE_ALLOW_APP_CREATE_FAILURE,
  GET_ROLES_EXCLUDE_REQUEST,
  GET_ROLES_EXCLUDE_SUCCESS,
  GET_ROLES_EXCLUDE_FAILURE,
} from '../constants/admin/AllowAppCreate';

const initialState = {
  requesting: false,
  message: '',
  allow_app_create_list: '',
  role_exclude_list: [],
  refreshAllowAppCreate: false,
  selectedUserID: '',
  selectedUser: '',
};

export default function adAllowAppCreate(state = initialState, action) {
  switch (action.type) {
    case GET_ROLES_EXCLUDE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
      };

    case GET_ROLES_EXCLUDE_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        role_exclude_list: action.payload.data,
      };

    case GET_ROLES_EXCLUDE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_ALLOW_APP_CREATE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshAllowAppCreate: false,
        message: '',
      };

    case GET_ALLOW_APP_CREATE_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        allow_app_create_list: action.payload.data,
      };

    case GET_ALLOW_APP_CREATE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case POST_ALLOW_APP_CREATE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshAllowAppCreate: false,
      };

    case POST_ALLOW_APP_CREATE_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshAllowAppCreate: true,
      };

    case POST_ALLOW_APP_CREATE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case DELETE_ALLOW_APP_CREATE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshAllowAppCreate: false,
      };

    case DELETE_ALLOW_APP_CREATE_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshAllowAppCreate: true,
      };

    case DELETE_ALLOW_APP_CREATE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
