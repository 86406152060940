import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import * as applicationActions from 'actions/admin/ApplicationActions';
import * as taskActions from 'actions/admin/TaskActions';
import ApplicationComp from './Application/ApplicationComp';

const Applications = (props) => {
  const { classes } = props;
  const { application, task, defaultStates } = props;
  const {
    getAllApplications,
    deleteApplication,
    getApplicationTrackReport,
    getApplicationTrackFormDataReport,
    adminRestoreArchiveApplication,
  } = props.applicationActions;
  const { getTasks } = props.taskActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <ApplicationComp
        getTasks={getTasks}
        getAllApplications={getAllApplications}
        adminRestoreArchiveApplication={adminRestoreArchiveApplication}
        getApplicationTrackReport={getApplicationTrackReport}
        getApplicationTrackFormDataReport={getApplicationTrackFormDataReport}
        deleteApplication={deleteApplication}
        application_list={application.application_list === undefined ? [] : application.application_list}
        application_track_report={application.application_track_report === undefined ? [] : application.application_track_report}
        application_track_form_data_report={application.application_track_form_data_report === undefined ? [] : application.application_track_form_data_report}
        task_list={task.task_list.list === undefined ? [] : task.task_list.list}
        refreshApplication={application.refreshApplication}
        requesting={application.requesting}
        message={application.message}
        error_code={application.error_code}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    task: state.adTask,
    application: state.adApplication,
    defaultStates: state.adDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    applicationActions: bindActionCreators(applicationActions, dispatch),
    taskActions: bindActionCreators(taskActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Applications);
