import {
  GET_APPLICATION_FORM_REQUEST,
  GET_APPLICATION_FORM_SUCCESS,
  GET_APPLICATION_FORM_FAILURE,
  GET_APPLICATION_ACTION_REQUEST,
  GET_APPLICATION_ACTION_SUCCESS,
  GET_APPLICATION_ACTION_FAILURE,
  POST_APPLICATION_ACTION_REQUEST,
  POST_APPLICATION_ACTION_SUCCESS,
  POST_APPLICATION_ACTION_FAILURE,
  POST_APPLICATION_REQUEST,
  POST_APPLICATION_SUCCESS,
  POST_APPLICATION_FAILURE,
  POST_APPLICATION_COMMITTEE_REQUEST,
  POST_APPLICATION_COMMITTEE_SUCCESS,
  POST_APPLICATION_COMMITTEE_FAILURE,
  GET_APPLICATION_MESSAGES_REQUEST,
  GET_APPLICATION_MESSAGES_SUCCESS,
  GET_APPLICATION_MESSAGES_FAILURE,
  GET_APPLICATION_ROLES_REQUEST,
  GET_APPLICATION_ROLES_SUCCESS,
  GET_APPLICATION_ROLES_FAILURE,
  SET_FORM_SUCCESS_MSG,
  SET_FORM_ERROR_MSG,
  SET_FIELDS,
  SET_SHOW_FORM,
  RESET_APPLICATION_DETAIL,
  POST_APP_ITEM_INBOUND_REQUEST,
  POST_APP_ITEM_INBOUND_SUCCESS,
  POST_APP_ITEM_INBOUND_FAILURE,
  PATCH_APP_ITEM_PERCENT_REQUEST,
  PATCH_APP_ITEM_PERCENT_SUCCESS,
  PATCH_APP_ITEM_PERCENT_FAILURE,
  POST_APP_ITEM_DETAIL_REQUEST,
  POST_APP_ITEM_DETAIL_SUCCESS,
  POST_APP_ITEM_DETAIL_FAILURE,
} from '../constants/ApplicationDetail';
import {
  SET_CURRENT_ROLE_TITLE,
} from '../constants/ApplicationStatus';
import {
  GET_ACTIVE_APPLICATION_REQUEST,
} from '../constants/ApplicationsList';

import {
  GET_APPLICATION_STATUS_REQUEST,
  PATCH_APPLICATION_REMIND_SUCCESS,
} from '../constants/ApplicationStatus';
import {
  FIND_RELATED_ITEM_REQUEST,
  FIND_RELATED_ITEM_SUCCESS,
  FIND_RELATED_ITEM_FAILURE,
} from '../constants/Item';



const initialState = {
  requesting: false,
  message: '',
  application_detail_form: '',
  application_roles: [],
  application_detail_action: '',
  application_detail_messages: '',
  refreshApplicationDetailForm: false,
  refreshRelatedItemSearch: false,
  showFormSuccessMsg: false,
  showFormErrorMsg: false,
  refreshApplicationRoles: false,
  redirectApplicationDetailAction: false,
  headerRoleTitle: '',
  headerTaskTitle: '',
  fields: [],
  showForm: { type: '', form_id: '' },
};

export default function applicationDetail(state = initialState, action) {
  switch (action.type) {


    case PATCH_APPLICATION_REMIND_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        // requesting: action.payload.requesting,
        requesting: false,
        refreshApplicationDetailForm: true,
        showFormSuccessMsg: true,
      };

    case FIND_RELATED_ITEM_REQUEST:
      return {
        ...state,
        refreshRelatedItemSearch: false,
      };
    case FIND_RELATED_ITEM_SUCCESS:
      return {
        ...state,
        refreshRelatedItemSearch: false,
      };
    case FIND_RELATED_ITEM_FAILURE:
      return {
        ...state,
        refreshRelatedItemSearch: false,
      };

    case GET_APPLICATION_STATUS_REQUEST:
      return {
        ...state,
        redirectApplicationDetailAction: false,
      };

    case RESET_APPLICATION_DETAIL:
      return {
        ...initialState,
      };
    case GET_ACTIVE_APPLICATION_REQUEST:
      return {
        ...state,
        showForm: { type: '', form_id: '' },
      };
    case SET_SHOW_FORM:
      return {
        ...state,
        showForm: { type: action.payload.type, form_id: action.payload.form_id },
      };

    case SET_CURRENT_ROLE_TITLE:
      return {
        ...state,
        headerRoleTitle: action.payload.value,
        headerTaskTitle: '',
      };

    case SET_FORM_SUCCESS_MSG:
      return {
        ...state,
        showFormSuccessMsg: action.payload.value,
      };

    case SET_FORM_ERROR_MSG:
      return {
        ...state,
        showFormErrorMsg: action.payload.value,
      };

    case SET_FIELDS:
      return {
        ...state,
        fields: action.payload.value,
      };

    case GET_APPLICATION_ROLES_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshApplicationRoles: false,
      };

    case GET_APPLICATION_ROLES_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        requesting: action.payload.requesting,
        application_roles: action.payload.data,
      };

    case GET_APPLICATION_ROLES_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_APPLICATION_MESSAGES_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshApplicationDetailForm: false,
      };

    case GET_APPLICATION_MESSAGES_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        requesting: action.payload.requesting,
        application_detail_messages: action.payload.data,
      };

    case GET_APPLICATION_MESSAGES_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case GET_APPLICATION_FORM_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        application_detail_form: '',
        refreshApplicationDetailForm: false,
      };

    case GET_APPLICATION_FORM_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        requesting: action.payload.requesting,
        application_detail_form: action.payload.data,
        headerRoleTitle: action.payload.data.role_alias === '' ? action.payload.data.role_title : action.payload.data.role_alias,
        headerTaskTitle: action.payload.data.alias === '' ? action.payload.data.title : action.payload.data.alias,
      };

    case GET_APPLICATION_FORM_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_APPLICATION_ACTION_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
      };

    case GET_APPLICATION_ACTION_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        requesting: action.payload.requesting,
        application_detail_action: action.payload.data,
      };

    case GET_APPLICATION_ACTION_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case POST_APPLICATION_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        showFormSuccessMsg: false,
        refreshRelatedItemSearch: false,
      };

    case POST_APPLICATION_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        // requesting: action.payload.requesting,
        requesting: true,
        refreshApplicationDetailForm: true,
        showFormSuccessMsg: true,
      };

    case POST_APPLICATION_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        showFormErrorMsg: true,
      };



    case POST_APPLICATION_COMMITTEE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
      };

    case POST_APPLICATION_COMMITTEE_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        // requesting: action.payload.requesting,
        requesting: true,
        refreshApplicationDetailForm: true,
        refreshApplicationRoles: true,
        showFormSuccessMsg: true,
      };

    case POST_APPLICATION_COMMITTEE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        showFormErrorMsg: true,
      };
    case POST_APPLICATION_ACTION_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        redirectApplicationDetailAction: false,
      };

    case POST_APPLICATION_ACTION_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        // requesting: action.payload.requesting,
        requesting: true,
        redirectApplicationDetailAction: true,
      };

    case POST_APPLICATION_ACTION_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        showFormErrorMsg: true,
      };

    case POST_APP_ITEM_INBOUND_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        showFormSuccessMsg: false,
        refreshRelatedItemSearch: false,
      };

    case POST_APP_ITEM_INBOUND_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        requesting: action.payload.requesting,
        refreshRelatedItemSearch: true,
        showFormSuccessMsg: true,
      };

    case POST_APP_ITEM_INBOUND_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        showFormErrorMsg: true,
      };

    case PATCH_APP_ITEM_PERCENT_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        showFormSuccessMsg: false,
        refreshApplicationDetailForm: false,
      };

    case PATCH_APP_ITEM_PERCENT_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        requesting: action.payload.requesting,
        refreshApplicationDetailForm: true,
        showFormSuccessMsg: true,
      };

    case PATCH_APP_ITEM_PERCENT_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        showFormErrorMsg: true,
      };



    case POST_APP_ITEM_DETAIL_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        showFormSuccessMsg: false,
        refreshApplicationDetailForm: false,
      };

    case POST_APP_ITEM_DETAIL_SUCCESS:
      return {
        ...state,
        message: action.payload.message,
        requesting: action.payload.requesting,
        refreshApplicationDetailForm: true,
        showFormSuccessMsg: true,
      };

    case POST_APP_ITEM_DETAIL_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        showFormErrorMsg: true,
      };
    default:
      return state;
  }
}
