import API from '../Api/api.js';

import {
  commonBackendCall, getConfig, getNetworkAdminConfig, getAdminConfig,
} from '../Api/common.js';
import {
  SEARCH_ITEM_REQUEST,
  SEARCH_ITEM_SUCCESS,
  SEARCH_ITEM_FAILURE,
  FIND_RELATED_ITEM_REQUEST,
  FIND_RELATED_ITEM_SUCCESS,
  FIND_RELATED_ITEM_FAILURE,
  SET_SELECTED_ITEM,
  SET_SELECTED_PARENT_ITEMS,

} from '../constants/Item';

export function SearchItem(itemType, searchStr, pageSize, pageNum, orderby, sort, itemRelationLevel, level) {
  if (itemType === 'networkadmin') {
    return commonBackendCall(
      SEARCH_ITEM_REQUEST,
      SEARCH_ITEM_SUCCESS,
      SEARCH_ITEM_FAILURE,
      API.get(`admin/networkadmin/items?page=${pageNum}&limit=${pageSize}&orderby=${orderby}&sort=${sort}&search=${searchStr}&relation=${itemRelationLevel}`, getNetworkAdminConfig()),
    );
  } else if (itemType === 'admin') {
    return commonBackendCall(
      SEARCH_ITEM_REQUEST,
      SEARCH_ITEM_SUCCESS,
      SEARCH_ITEM_FAILURE,
      API.get(`admin/admin/items?page=${pageNum}&limit=${pageSize}&orderby=${orderby}&sort=${sort}&search=${searchStr}&relation=${itemRelationLevel}`, getAdminConfig()),
    );
  } else {
    return commonBackendCall(
      SEARCH_ITEM_REQUEST,
      SEARCH_ITEM_SUCCESS,
      SEARCH_ITEM_FAILURE,
      API.get(`applications/items?page=${pageNum}&limit=${pageSize}&orderby=${orderby}&sort=${sort}&search=${searchStr}&relation=${itemRelationLevel}&level=${level}`, getConfig()),
    );
  }
}

export function FindRelatedItems(pageSize, pageNum, orderby, sort, itemRelationLevel, relatedItemIds) {
  return commonBackendCall(
    FIND_RELATED_ITEM_REQUEST,
    FIND_RELATED_ITEM_SUCCESS,
    FIND_RELATED_ITEM_FAILURE,
    API.get(`applications/related/items?page=${pageNum}&limit=${pageSize}&orderby=${orderby}&sort=${sort}&relation=${itemRelationLevel}&relateditemids=${relatedItemIds}`, getConfig()),
  );
}



export function setSelectedItem(item, itemID, fieldID) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_ITEM,
      payload: {
        item, itemID, fieldID
      },
    });
  };
}

export function setSelectedParentItems(items) {
  return (dispatch) => {
    dispatch({
      type: SET_SELECTED_PARENT_ITEMS,
      payload: {
        items
      },
    });
  };
}

export function ResetSeachItemList() {
  var requesting = false
  var message = ""
  var data = []
  return (dispatch) => {
    dispatch({
      type: SEARCH_ITEM_SUCCESS,
      payload: {
        requesting, message, data
      },
    });
  };
}
