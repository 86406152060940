import React, { useState, useEffect } from 'react';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import withStyles from '@material-ui/core/styles/withStyles';
import extendedFormsStyle from 'assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx';
import TextField from '@material-ui/core/TextField';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

import {
  INTEGER_NUMBER,
  DECIMAL_NUMBER,
  STRING,
  DATE_STRING,
  NONE,
} from 'constants/admin/FieldDataTypes';

const ValidationRuleComp = (props) => {
  const [condition, setCondition] = useState('');
  const [ruleValue, setRuleValue] = useState('');
  const [errorMsg, setErrorMsg] = useState('');
  const [disabled, setDisabled] = useState(false);
  const [message, setMessage] = useState('');
  const [fieldDataType, setFieldDataType] = useState('NONE');
  const [rules, setRules] = useState([]);

  const {
    setValidateRule,
  } = props;
  useEffect(() => {
    setValidateRule([]);
  }, [setValidateRule]);

  useEffect(() => {
    if (props.field_validate_rule) {
      var rules = JSON.parse(props.field_validate_rule);
      setRules(rules);
      props.setValidateRule(rules);
    }
    setDisabled(props.disabled);
  }, []);

  useEffect(() => {
    //setSelectedFields(props.field_aggregate_rule.field_ids.map((v) => parseInt(v, 10)));
    //setAggregateType(props.field_aggregate_rule.type);
    setFieldDataType(props.field_data_type)
  }, [props]);

  /*
  const onAggregateTypeChange = (e) => {
    setAggregateType(e.target.value);
    props.setAggregateRule({ type: e.target.value, field_ids: selectedFields });
  };
  */
  /*
   const onHandleFieldChange = (e) => {
     setSelectedFields(e.target.value);
     props.setAggregateRule({ type: aggregateType, field_ids: e.target.value.map((e) => e.toString()) });
   };
   */
  function isIntegerNumeric(value) {
    return /^\d+$/.test(value);
  }
  function isDecimalNumeric(value) {
    return /^\d+\.\d+$/.test(value);
  }
  const onRuleValueChange = (e) => {
    setRuleValue(e.target.value);
  };
  const onConditionChange = (e) => {
    setCondition(e.target.value);
  };
  const onErrorMsgChange = (e) => {
    setErrorMsg(e.target.value);
  };

  const handleAddClick = (e) => {
    setMessage('');
    if (fieldDataType === 'NONE') {
      setMessage('Please Select Field Data type');
      return;
    }
    if (condition === '') {
      setMessage('Please Select Condition');
      return;
    }
    if (ruleValue === '') {
      setMessage('Please Select Value');
      return;
    }
    console.log("FidlDatatype", fieldDataType)
    if ((fieldDataType !== INTEGER_NUMBER) && (fieldDataType !== STRING) && (fieldDataType !== DECIMAL_NUMBER)) {
      setMessage('Please Select Integer, Decimal or String Field Data Type');
      return;
    }
    if (condition !== '' && ruleValue !== '') {
      if (fieldDataType === INTEGER_NUMBER) {
        if (!isIntegerNumeric(ruleValue)) {
          setMessage('Invalid Rule Value. Must be an Integer number');
          return;
        }
      } else if (fieldDataType === DECIMAL_NUMBER) {
        if (!isDecimalNumeric(ruleValue)) {
          setMessage('Invalid Rule Value. Must be Decimal number');
          return;
        }
      } else if (fieldDataType === STRING) {
        if (!isIntegerNumeric(ruleValue)) {
          setMessage('Invalid Rule Value. Must be an Integer number');
          return;
        }
      }


      var rule = {
        condition: condition,
        value: ruleValue,
        error_message: errorMsg,
      };
      setRules([...rules, rule]);
      setMessage('');
      setRuleValue('');
      setErrorMsg('');
      setCondition('');
      props.setValidateRule([...rules, rule]);
    }
  }

  const handleDeleteClick = (index, e) => {
    const tempRules = rules.slice();
    tempRules.splice(index, 1);
    setRules(tempRules);
    setMessage('');
    props.setValidateRule(tempRules);
  }

  const { classes } = props;
  const ruleItems = [];
  rules.forEach((rule, index) => {
    ruleItems.push(
      <div>
        <GridContainer>
          <GridItem xs={12} sm={12} md={4}>
            <FormControl fullWidth className={classes.formControl} disabled>
              <InputLabel id="typelabel">Condition</InputLabel>
              <Select
                labelId="typelabel"
                id="typeselect"
                value={rule.condition}
              >
                <MenuItem key="==" value="=="> equal to</MenuItem>
                <MenuItem key="!=" value="!="> not equal to</MenuItem>
                <MenuItem key=">" value=">"> greater than </MenuItem>
                <MenuItem key=">=" value=">="> greater than equal </MenuItem>
                <MenuItem key="<" value="<"> less than </MenuItem>
                <MenuItem key="<=" value="<="> less than equal </MenuItem>
              </Select>
            </FormControl>
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            <TextField
              required={false}
              fullWidth
              label={fieldDataType === STRING ? "Length" : "Value"}
              value={rule.value}
              disabled
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={4}>
            <TextField
              required={false}
              fullWidth
              label="Error Message"
              value={rule.error_message}
              disabled
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={2}>
            {disabled ? '' : (
              <IconButton onClick={handleDeleteClick.bind(this, index)}>
                <DeleteIcon />
              </IconButton>
            )}
          </GridItem>
        </GridContainer>
      </div>,
    );
    ruleItems.push(<br key={`linebreak${index}`} />);
  });
  return (
    <div>
      {ruleItems}
      <br />
      <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
      <GridContainer key="new validation_rule">
        <GridItem xs={12} sm={12} md={4}>
          <FormControl fullWidth className={classes.formControl} disabled={disabled}>
            <InputLabel id="typeLabel">Condition</InputLabel>
            <Select
              labelId="conditionLabel"
              id="conditionLabelID"
              value={condition}
              onChange={onConditionChange.bind(this)}
            >
                <MenuItem key="==" value="=="> equal to</MenuItem>
                <MenuItem key="!=" value="!="> not equal to</MenuItem>
                <MenuItem key=">" value=">"> greater than </MenuItem>
                <MenuItem key=">=" value=">="> greater than equal </MenuItem>
                <MenuItem key="<" value="<"> less than </MenuItem>
                <MenuItem key="<=" value="<="> less than equal </MenuItem>
            </Select>
          </FormControl>
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          <TextField
            required={false}
            fullWidth
            label={fieldDataType === STRING ? "Length" : "Value"}
            value={ruleValue}
            onChange={onRuleValueChange.bind(this)}
            disabled={disabled}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={4}>
          <TextField
            required={false}
            fullWidth
            label="Error Message"
            value={errorMsg}
            onChange={onErrorMsgChange.bind(this)}
            disabled={disabled}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={2}>
          {disabled ? '' : (
            <IconButton onClick={handleAddClick.bind(this)}>
              <AddIcon />
            </IconButton>
          )}
        </GridItem>
      </GridContainer>
    </div>
  );
};
export default withStyles(extendedFormsStyle)(ValidationRuleComp);
