import API from 'Api/api.js';
import { commonBackendCall, getAdminConfig } from 'Api/common.js';
import {
  GET_DEPARTMENT_REQUEST,
  GET_DEPARTMENT_SUCCESS,
  GET_DEPARTMENT_FAILURE,
  GET_DEPT_INTG_REQUEST,
  GET_DEPT_INTG_SUCCESS,
  GET_DEPT_INTG_FAILURE,
} from 'constants/admin/Department';

export function getDepartments() {
  return commonBackendCall(
    GET_DEPARTMENT_REQUEST,
    GET_DEPARTMENT_SUCCESS,
    GET_DEPARTMENT_FAILURE,
    API.get('admin/departments', getAdminConfig()),
  );
}

export function getDeptIntgs(deptID) {
  return commonBackendCall(
    GET_DEPT_INTG_REQUEST,
    GET_DEPT_INTG_SUCCESS,
    GET_DEPT_INTG_FAILURE,
    API.get(`admin/departments/intg-admin/${deptID}`, getAdminConfig()),
  );
}
