import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as userActions from 'actions/UserActions';
import UserSearchComp from './UserSearchComp';

const UserSearch = (props) => {
  const { classes } = props;
  const { user } = props;
  const { SearchUser, setSelectedUser } = props.userActions;

  return (
    <UserSearchComp
      SearchUser={SearchUser}
      setSelectedUser={setSelectedUser}
      user_type={props.user_type}
      application_id={props.application_id}
      search_user_list={user.search_user_list.list === undefined ? [] : user.search_user_list.list}
      search_user_count={user.search_user_list.count === undefined ? [] : user.search_user_list.count}
      classes={classes}
    />
  );
}

function mapStateToProps(state) {
  return {
    user: state.user,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSearch);
