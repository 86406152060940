import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';

import BlurOff from '@mui/icons-material/BlurOff';
import BlurOn from '@mui/icons-material/BlurOn';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';

import { useTranslation } from 'react-i18next';
import withRoot from 'withRoot';
import StateUI from 'components/StateUI/StateUI.jsx';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import IconButton from '@mui/material/IconButton';
import { CopyToClipboard } from 'react-copy-to-clipboard';


const WorkflowItemComp = (props) => {
  const [message, setMessage] = useState('');
  const [cardAnimaton, setCardAnimaton] = useState('cardHidden');
  const [workflowList, setWorkflowList] = useState([]);
  const [variableCopied, setVariableCopied] = useState('');

  const { t } = useTranslation();

  /*
  const { getNetworkWorkflows } = props;
  useEffect(() => {
    getNetworkWorkflows();
    setTimeout(() => {
      setCardAnimaton('');
    }, 500);
  }, [getNetworkWorkflows]);
  */

  useEffect(() => {
    //setMessage(props.message);
    //setWorkflowList(props.workflow_list);
  }, [props]);

  const handleListItemClick = (event, index, state) => {
    if (state > 0) {
      if (localStorage.getItem('token')) {
        props.onLogin(
          { user_token: localStorage.getItem('token'), workflow_title: index },
        );
      } else {
        props.onLogin(
          { network_user_token: localStorage.getItem('network_user_token'), workflow_title: index },
        );
      }
    }
  };

  const handleVariableClick = (e) => {
    setVariableCopied(`System Link Copied`);
  };
  const handleRoleItemClick = (event, index, roleID) => {
    if (localStorage.getItem('token')) {
      props.onLogin(
        { user_token: localStorage.getItem('token'), workflow_title: index.toString(), role_id: roleID.toString() },
      );
    } else {
      props.onLogin(
        { network_user_token: localStorage.getItem('network_user_token'), workflow_title: index.toString(), role_id: roleID.toString() },
      );
    }
  };

  const handleMouseEnter = (event, index) => {
    document.getElementById(`loginAsRole${index}`).style.display = 'block';
  };

  const handleLogout = (e) => {
    // localStorage.clear();
    localStorage.removeItem('network_user_token');
    localStorage.removeItem('token');
    window.location.href = '/public/login';
  };

  var deputyList = [];
  props.workflow.roles.forEach((role) => {
    deputyList.push(
      <div>
        <GridContainer>
          <GridItem xs={12} sm={6} md={12}>
            <Card>

              <CardActionArea>
                <CardContent onClick={(event) => handleRoleItemClick(event, props.workflow.title, role.id)}>
                  <div style={{ display: 'inline-block' }}>
                    <Typography variant="caption">
                      {role.alias === "" ? role.title : role.alias}
                    </Typography>
                  </div>
                </CardContent>
              </CardActionArea>
            </Card>
          </GridItem>
        </GridContainer>
        <br />
      </div>,
    );
  });



  const { classes } = props;

  return (
    <GridItem xs={12} sm={12} md={4} key={props.workflow.title}>
      <Card>
        {/*
      <CardMedia
        component="img"
        alt="Contemplative Reptile"
        height="40"
        image={workflow.image}
      />
      */}

        <CardActionArea>
          <CardContent onMouseEnter={(event) => handleMouseEnter(event, props.workflow.title)} onClick={(event) => handleListItemClick(event, props.workflow.title, props.workflow.state)}>

            <GridContainer>
              <GridItem xs={12} sm={6} md={2}>
                {props.workflow.image === ''
                  ? <Avatar>{(props.workflow.name).substring(0, 2).toUpperCase()}</Avatar>
                  : <Avatar src={props.workflow.image} />}
              </GridItem>
              <GridItem xs={12} sm={6} md={7}>
                <Typography variant="body1">
                  {props.workflow.alias === "" ? props.workflow.name : props.workflow.alias}

                </Typography>
              </GridItem>
              <GridItem xs={12} sm={6} md={3}>
                {props.workflow.accessible_count > 0 || props.workflow.in_accessible_count > 0 ?
                  <GridContainer>
                    <GridItem xs={12} sm={6} md={6}>
                      {props.workflow.in_accessible_count > 0 ?
                        <Badge badgeContent={props.workflow.in_accessible_count} color="error" title="Inaccessible">
                          <BlurOff color="error" />
                        </Badge>
                        : ""}
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                      {props.workflow.accessible_count > 0 ?
                        <Badge badgeContent={props.workflow.accessible_count} color="primary" title="Accessible">
                          <BlurOn color="primary" />
                        </Badge>
                        : ""}
                    </GridItem>
                  </GridContainer>
                  : ""}
                <GridContainer>
                  <GridItem xs={12} sm={6} md={12}>
                    {StateUI(props.workflow.state, 'small')}
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
            <br />
            <GridContainer>
              <GridItem xs={12} sm={6} md={12}>
                <Typography style={{ display: 'block' }} variant="caption">
                  {props.workflow.short_desc}
                </Typography>
              </GridItem>
            </GridContainer>
            {/* workflow.state === 0 ? <Typography style={{ color: 'red' }} variant="caption" >Access disabled due to maintenance</Typography> : "" */}
          </CardContent>
        </CardActionArea>
        {/*
        <Card>
          <CardContent>
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <div style={{ display: 'inline-block' }}>
                  <IconButton size="small" onClick={handleVariableClick.bind(this, ['link'])}>
                    <CopyToClipboard text={localStorage.getItem("network_frontend_url") + "/public/login/" + props.workflow.title}>
                      <FileCopyIcon style={{ height: '20px', width: '20px' }} />
                    </CopyToClipboard>
                  </IconButton>
                  <Typography variant="caption">Copy System Link</Typography>
                </div>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <div style={{ color: 'blue', textAlign: 'left' }}>{variableCopied}</div>
              </GridItem>
            </GridContainer>
          </CardContent>
        </Card >
        */}
      </Card >
      <div >
        <div style={{ display: 'inline-block' }}>
          <IconButton size="small" onClick={handleVariableClick.bind(this, ['link'])}>
            <CopyToClipboard text={localStorage.getItem("network_frontend_url") + "/public/login/" + props.workflow.title}>
              <FileCopyIcon style={{ height: '20px', width: '20px' }} />
            </CopyToClipboard>
            <div style={{ color: 'blue', textAlign: 'left' }}>{variableCopied}</div>
          </IconButton>
        </div>
      </div>
      <div id={`loginAsRole${props.workflow.title}`} style={{ display: 'none' }}>
        <br /><div>{deputyList.length > 0 ? "You can login as a deputy for the following role(s):" : ""}</div><br />
        {deputyList}
      </div>
      <br />
    </GridItem >

  );
};

export default withRoot(WorkflowItemComp);
