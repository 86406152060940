import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { useTranslation } from 'react-i18next';

const AppRolesComp = (props) => {
  const { t } = useTranslation();
  const { getApplicationRoles, application_id, role_id } = props;
  useEffect(() => {
    getApplicationRoles(application_id, role_id);
  }, [getApplicationRoles, application_id, role_id]);

  useEffect(() => {
    if (props.refreshApplicationDetailForm) {
      props.getApplicationRoles(props.application_id, props.role_id);
    }
  }, [props]);

  const rowItems = [];
  var i = 1;
  props.application_roles.forEach((element) => {
    rowItems.push(
      <TableRow key={element.id}>
        <TableCell>{i}</TableCell>
        <TableCell>
          {element.user_name}
          <br />
          {element.user_rank}
          <br />
          {element.user_dept}
          <br />
        </TableCell>
        <TableCell>
          {element.role_alias === '' ? element.role_title : element.role_alias}
        </TableCell>
      </TableRow>,
    );
    i++;
  });
  return (
    <div>
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <Typography variant="h5" gutterBottom>{t('app_detail_roles_title')}</Typography>
        </CardHeader>
        <CardBody>
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>{t('app_detail_roles_serial')}</TableCell>
                <TableCell>{t('app_detail_roles_name')}</TableCell>
                <TableCell>{t('app_detail_roles_role')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowItems}
            </TableBody>
          </Table>
        </CardBody>
      </Card>
    </div>
  );
};
export default AppRolesComp;
