import {
  GET_TASK_REQUEST,
  GET_TASK_SUCCESS,
  GET_TASK_FAILURE,
  POST_TASK_REQUEST,
  POST_TASK_SUCCESS,
  POST_TASK_FAILURE,
  PATCH_TASK_REQUEST,
  PATCH_TASK_SUCCESS,
  PATCH_TASK_FAILURE,
  DELETE_TASK_REQUEST,
  DELETE_TASK_SUCCESS,
  DELETE_TASK_FAILURE,
  GET_A_TASK_REQUEST,
  GET_A_TASK_SUCCESS,
  GET_A_TASK_FAILURE,
} from '../constants/admin/Task';
import {
  GET_TASKDEPENDENCY_REQUEST,
} from '../constants/admin/TaskDependency';

const initialState = {
  requesting: false,
  message: '',
  task_list: '',
  task_taskforms_list: '',
  task: '',
  refreshTask: false,
};

export default function task(state = initialState, action) {
  switch (action.type) {
    case GET_A_TASK_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
      };

    case GET_A_TASK_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        task: action.payload.data,
      };

    case GET_A_TASK_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_TASK_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshTask: false,
        message: '',
      };

    case GET_TASK_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        task_list: action.payload.data,
      };

    case GET_TASK_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case POST_TASK_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshTask: false,
      };

    case POST_TASK_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshTask: true,
      };

    case POST_TASK_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case PATCH_TASK_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshTask: false,
      };

    case PATCH_TASK_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshTask: true,
      };

    case PATCH_TASK_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case DELETE_TASK_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshTask: false,
      };

    case DELETE_TASK_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshTask: true,
      };

    case DELETE_TASK_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_TASKDEPENDENCY_REQUEST:
      return {
        ...state,
        task_list: [],
      };

    default:
      return state;
  }
}
