import React from 'react';

import AccountTree from '@mui/icons-material/AccountTree';
import Dashboard from '@mui/icons-material/Dashboard';
import EmojiPeople from '@mui/icons-material/EmojiPeople';
import Input from '@mui/icons-material/Input';
import Grain from '@mui/icons-material/Grain';
import CloudUpload from '@mui/icons-material/CloudUpload';
import TextFields from '@mui/icons-material/TextFields';
import Assignment from '@mui/icons-material/Assignment';
import Extension from '@mui/icons-material/Extension';
import ListAlt from '@mui/icons-material/ListAlt';
import PermDataSettingIcon from '@mui/icons-material/PermDataSetting';
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import ItemIcon from '@mui/icons-material/Bookmarks';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

import DashboardPage from 'views/networkadmin/Dashboard.jsx';
import Workflow from 'views/networkadmin/Workflow.jsx';
import Admin from 'views/networkadmin/Admin.jsx';
import ErrorEmails from 'views/networkadmin/ErrorEmails.jsx';
import SentEmail from 'views/networkadmin/SentEmail.jsx';
import User from 'views/networkadmin/User.jsx';
import UserIntg from 'views/networkadmin/UserIntg.jsx';
import DeptIntg from 'views/networkadmin/DeptIntg.jsx';
import ItemIntg from 'views/networkadmin/ItemIntg.jsx';
import Department from 'views/networkadmin/Department.jsx';
import CustomIntg from 'views/networkadmin/CustomIntg.jsx';
import Item from 'views/networkadmin/Item.jsx';
import IntgField from 'views/networkadmin/IntgField.jsx';
import IntgExtAPI from 'views/networkadmin/IntgExtAPI.jsx';
import BatchUpload from 'views/networkadmin/BatchUpload.jsx';
import BatchLogs from 'views/networkadmin/BatchLogs.jsx';
import InboundWebhook from 'views/networkadmin/InboundWebhook.jsx';
import NetworkSetting from 'views/networkadmin/NetworkSetting.jsx';
import MessageTemplate from 'views/networkadmin/MessageTemplate.jsx';

const dashboardRoutes = [
  {
    path: '/networkadmin/dashboard',
    sidebarName: 'Dashboard',
    mini: 'AB',
    navbarName: 'Material Dashboard',
    icon: Dashboard,
    component: <DashboardPage />,
    hidden: false,
  },
  {
    path: '/networkadmin/workflows',
    sidebarName: 'Workflow',
    mini: 'WO',
    navbarName: 'Workflow',
    icon: AccountTree,
    component: <Workflow />,
    hidden: false,
  },
  {
    path: '/networkadmin/admins',
    sidebarName: 'Admin',
    mini: 'AD',
    navbarName: 'Admin',
    icon: Assignment,
    component: <Admin />,
    hidden: false,
  },
  {
    path: '/networkadmin/error-emails',
    sidebarName: 'Unsent Error Email',
    mini: 'AD',
    navbarName: 'Unsent Error Email',
    icon: ReportProblemIcon,
    component: <ErrorEmails />,
    hidden: false,
  },
  {
    path: '/networkadmin/sent-email',
    sidebarName: 'Sent Email',
    mini: 'SE',
    navbarName: 'Sent Email',
    icon: MarkEmailReadIcon,
    component: <SentEmail />,
    hidden: false,
  },
  {
    path: '/networkadmin/users',
    sidebarName: 'Users',
    mini: 'AD',
    navbarName: 'Admin',
    icon: EmojiPeople,
    component: <User />,
    hidden: false,
  },
  {
    path: '/networkadmin/department',
    sidebarName: 'Department',
    mini: 'AD',
    navbarName: 'Department',
    icon: Grain,
    component: <Department />,
    hidden: false,
  },
  {
    path: '/networkadmin/custom',
    sidebarName: 'Custom',
    mini: 'AD',
    navbarName: 'Custom',
    icon: ListAlt,
    component: <CustomIntg />,
    hidden: false,
  },
  {
    path: '/networkadmin/item',
    sidebarName: 'Item',
    mini: 'IT',
    navbarName: 'Item',
    icon: ItemIcon,
    component: <Item />,
    hidden: false,
  },
  {
    path: '/networkadmin/integration-fields',
    sidebarName: 'Integration Fields',
    mini: 'AD',
    navbarName: 'Integration Fields',
    icon: TextFields,
    component: <IntgField />,
    hidden: false,
  },
  {
    path: '/networkadmin/intgextapi',
    sidebarName: 'Integration Ext. API',
    mini: 'IEA',
    navbarName: 'Integration Ext. API',
    icon: Extension,
    component: <IntgExtAPI />,
    hidden: false,
  },
  {
    path: '/networkadmin/batch-data-upload',
    sidebarName: 'Batch Data Upload',
    mini: 'AD',
    navbarName: 'Batch Data Upload',
    icon: CloudUpload,
    component: <BatchUpload />,
    hidden: false,
  },
  {
    path: '/networkadmin/bot-request',
    sidebarName: 'BOT Requests',
    mini: 'AD',
    navbarName: 'BOT Requests',
    icon: Input,
    component: <InboundWebhook />,
    hidden: false,
  },
  {
    path: '/networkadmin/network-settings',
    sidebarName: 'Network Settings',
    mini: 'AD',
    navbarName: 'Outbound Webhook',
    icon: PermDataSettingIcon,
    component: <NetworkSetting />,
    hidden: false,
  },
  {
    path: '/networkadmin/message-template',
    sidebarName: 'Message Templates',
    mini: 'MT',
    navbarName: 'Outbound Webhook',
    icon: SettingsApplicationsIcon,
    component: <MessageTemplate />,
    hidden: false,
  },
  {
    path: '/networkadmin/batch-logs/:id',
    sidebarName: 'Batch Logs',
    mini: 'AD',
    navbarName: 'Batch Data Upload',
    icon: Grain,
    component: <BatchLogs />,
    hidden: true,
  },
  {
    path: '/networkadmin/user-intg/:id',
    sidebarName: 'User Intg',
    mini: 'AD',
    navbarName: 'Batch Data Upload',
    icon: Grain,
    component: <UserIntg />,
    hidden: true,
  },
  {
    path: '/networkadmin/dept-intg/:id',
    sidebarName: 'Dept Intg',
    mini: 'AD',
    navbarName: 'Batch Data Upload',
    icon: Grain,
    component: <DeptIntg />,
    hidden: true,
  },
  {
    path: '/networkadmin/item-intg/:id',
    sidebarName: 'Item Intg',
    mini: 'AD',
    navbarName: 'Batch Data Upload',
    icon: Grain,
    component: <ItemIntg />,
    hidden: true,
  },
];

export default dashboardRoutes;
