import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import NetworkSettingComp from './NetworkSetting/NetworkSettingComp';
import * as networkSettingActions from 'actions/networkadmin/NetworkSettingActions';

const NetworkSetting = (props) => {
  const { classes } = props;
  const { networkSetting, defaultStates } = props;
  const {
    getNetworkSettings,
    updateNetworkSettings,
  } = props.networkSettingActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <NetworkSettingComp
        getNetworkSettings={getNetworkSettings}
        networkSetting={networkSetting.networkSetting === undefined ? [] : networkSetting.networkSetting}
        updateNetworkSettings={updateNetworkSettings}
        refreshNetworkSettings={networkSetting.refreshNetworkSettings}
        requesting={networkSetting.requesting}
        message={networkSetting.message}
        error_code={networkSetting.error_code}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    networkSetting: state.naNetworkSetting,
    defaultStates: state.naDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    networkSettingActions: bindActionCreators(networkSettingActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(NetworkSetting);
