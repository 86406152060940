import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import * as userActions from 'actions/admin/UserActions';
import UserComp from './User/UserComp';

const Users = (props) => {
  const { classes } = props;
  const { user, defaultStates } = props;
  const {
    getUsers,
    ResetPassword,
  } = props.userActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <UserComp
        getUsers={getUsers}
        ResetPassword={ResetPassword}
        user_list={user.user_list.list === undefined ? [] : user.user_list.list}
        user_count={user.user_list.count === undefined ? 0 : user.user_list.count}
        refreshUser={user.refreshUser}
        requesting={user.requesting}
        message={user.message}
        error_code={user.error_code}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    user: state.adUser,
    defaultStates: state.adDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    userActions: bindActionCreators(userActions, dispatch),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Users);
