import API from 'Api/api.js';
import { commonBackendCall, getNetworkAdminConfig } from 'Api/common.js';
import {
  GET_ITEM_NETWORKADMIN_REQUEST,
  GET_ITEM_NETWORKADMIN_SUCCESS,
  GET_ITEM_NETWORKADMIN_FAILURE,
  GET_ITEM_CSV_NETWORKADMIN_REQUEST,
  GET_ITEM_CSV_NETWORKADMIN_SUCCESS,
  GET_ITEM_CSV_NETWORKADMIN_FAILURE,
  POST_ITEM_NETWORKADMIN_REQUEST,
  POST_ITEM_NETWORKADMIN_SUCCESS,
  POST_ITEM_NETWORKADMIN_FAILURE,
  PATCH_ITEM_NETWORKADMIN_REQUEST,
  PATCH_ITEM_NETWORKADMIN_SUCCESS,
  PATCH_ITEM_NETWORKADMIN_FAILURE,
  DELETE_ITEM_SELECT_NETWORKADMIN_REQUEST,
  DELETE_ITEM_SELECT_NETWORKADMIN_SUCCESS,
  DELETE_ITEM_SELECT_NETWORKADMIN_FAILURE,
  GET_ITEM_INTG_NETWORKADMIN_REQUEST,
  GET_ITEM_INTG_NETWORKADMIN_SUCCESS,
  GET_ITEM_INTG_NETWORKADMIN_FAILURE,
  PATCH_ITEM_INTG_NETWORKADMIN_REQUEST,
  PATCH_ITEM_INTG_NETWORKADMIN_SUCCESS,
  PATCH_ITEM_INTG_NETWORKADMIN_FAILURE,
} from 'constants/networkadmin/Item';

export function getItemsWithRelation(searchStr, pageSize, pageNum, orderby, sort, relation) {
  return commonBackendCall(
    GET_ITEM_NETWORKADMIN_REQUEST,
    GET_ITEM_NETWORKADMIN_SUCCESS,
    GET_ITEM_NETWORKADMIN_FAILURE,
    API.get(`admin/networkadmin/items?page=${pageNum}&relation=${relation}&limit=${pageSize}&orderby=${orderby}&sort=${sort}&search=${searchStr}`, getNetworkAdminConfig()),
  );
}
export function getItemsCSV() {
  return commonBackendCall(
    GET_ITEM_CSV_NETWORKADMIN_REQUEST,
    GET_ITEM_CSV_NETWORKADMIN_SUCCESS,
    GET_ITEM_CSV_NETWORKADMIN_FAILURE,
    API.get('admin/networkadmin/items/csv', getNetworkAdminConfig()),
  );
}
export function createItem(obj) {
  return commonBackendCall(
    POST_ITEM_NETWORKADMIN_REQUEST,
    POST_ITEM_NETWORKADMIN_SUCCESS,
    POST_ITEM_NETWORKADMIN_FAILURE,
    API.post('admin/networkadmin/items', obj, getNetworkAdminConfig()),
  );
}
export function updateItem(itemID, obj) {
  return commonBackendCall(
    PATCH_ITEM_NETWORKADMIN_REQUEST,
    PATCH_ITEM_NETWORKADMIN_SUCCESS,
    PATCH_ITEM_NETWORKADMIN_FAILURE,
    API.patch(`admin/networkadmin/items/${itemID}`, obj, getNetworkAdminConfig()),
  );
}
export function deleteSelectedItem(obj) {
  return commonBackendCall(
    DELETE_ITEM_SELECT_NETWORKADMIN_REQUEST,
    DELETE_ITEM_SELECT_NETWORKADMIN_SUCCESS,
    DELETE_ITEM_SELECT_NETWORKADMIN_FAILURE,
    API.post(`admin/networkadmin/items/delete-selected`, obj, getNetworkAdminConfig()),
  );
}
export function getItemIntgs(itemID) {
  return commonBackendCall(
    GET_ITEM_INTG_NETWORKADMIN_REQUEST,
    GET_ITEM_INTG_NETWORKADMIN_SUCCESS,
    GET_ITEM_INTG_NETWORKADMIN_FAILURE,
    API.get(`admin/networkadmin/items/intg/${itemID}`, getNetworkAdminConfig()),
  );
}
export function updateItemIntgFieldValue(itemID, obj) {
  return commonBackendCall(
    PATCH_ITEM_INTG_NETWORKADMIN_REQUEST,
    PATCH_ITEM_INTG_NETWORKADMIN_SUCCESS,
    PATCH_ITEM_INTG_NETWORKADMIN_FAILURE,
    API.patch(`admin/networkadmin/items/intg/${itemID}`, obj, getNetworkAdminConfig()),
  );
}

