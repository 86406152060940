import React from 'react';
import ApplicationsList from 'views/ApplicationsList.jsx';
import ApplicationDetail from 'views/ApplicationDetail.jsx';
import ApplicationStatus from 'views/ApplicationStatus.jsx';

const applicationRoutes = [
  {
    path: '/list',
    component: <ApplicationsList />,
  },
  {
    path: '/detail/:application_id/:role_id',
    component: <ApplicationDetail />,
  },
  {
    path: '/completed/:application_id/:role_id/:task_id',
    component: <ApplicationDetail />,
  },
  {
    path: '/status/:application_id/:role_id',
    component: <ApplicationStatus />,
  },
];

export default applicationRoutes;
