import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Select from '@material-ui/core/Select';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';

import Email from '@mui/icons-material/Email';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import GridContainer from 'components/Grid/GridContainer.jsx';
import GridItem from 'components/Grid/GridItem.jsx';
import CustomInput from 'components/CustomInput/CustomInput.jsx';
import Button from 'components/CustomButtons/Button.jsx';
import Card from 'components/Card/Card.jsx';
import CardHeader from 'components/Card/CardHeader.jsx';
import CardBody from 'components/Card/CardBody.jsx';

import GoogleLogin from 'react-google-login';
import withRoot from 'withRoot'
import { makeStyles } from '@material-ui/core/styles';
import { validateEmail } from 'Api/common.js';
import { useTranslation } from 'react-i18next';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

const LoginComp = (props) => {
  const [cardAnimaton, setCardAnimaton] = useState('cardHidden');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [lang, setLang] = useState(localStorage.getItem('network_lang'));
  const [showPassword, setShowPassword] = useState(false);
  const [showLangOption, setShowLangOption] = useState(false);
  const [langSupports, setLangSupports] = useState([]);
  const { t } = useTranslation();
  useEffect(() => {
    setTimeout(() => {
      setCardAnimaton('');
    }, 2500);
  }, []);

  useEffect(() => {
    var tempLangSupport = JSON.parse(localStorage.getItem("network_lang_support"))
    if (tempLangSupport === null) {
      window.location.href = '/public/network';
    }
    setLangSupports(tempLangSupport)
    if (tempLangSupport.length > 1) {
      setShowLangOption(true)
    }
    setMessage(props.message);

    if (props.error_code === '1004' || props.error_code === '1005') {
      setEmailError(false);
      setPasswordError(false);
    }
    if (props.error_code === '1006') {
      setEmailError(true);
      setPasswordError(true);
    }
    if (props.error_code === '1091') {
      setEmailError(true);
      setPasswordError(false);
    }
    if (props.error_code === '1092') {
      setEmailError(false);
      setPasswordError(true);
    }
    if (props.error_code === '1093' || props.error_code === '1115') {
      setEmailError(false);
      setPasswordError(true);
    }
    if (props.error_code === '1114') {
      setEmailError(true);
      setPasswordError(false);
    }
    if (props.error_code === '') {
      setEmailError(false);
      setPasswordError(false);
    }
  }, [props]);

  const onEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };
  const onKeyPressChange = (e) => {
    if (e.key === "Enter") {
      onLogin()
    }
  };
  const resetNetwork = (e) => {
    localStorage.clear();
    window.location.reload();
  };
  const gotoAdminLogin = (e) => {
    window.location.href = '/admin';
  };
  const onRegisterClick = (e) => {
    window.location.href = '/public/register';
  };
  const onForgotPasswordClick = (e) => {
    window.location.href = '/public/forgot-password';
  };

  const onLoginClick = (e) => {
    onLogin()
  }

  const onLogin = () => {

    var tempLangSupport = JSON.parse(localStorage.getItem("network_lang_support"))
    if (tempLangSupport === null) {
      window.location.href = '/public/network';
      return;
    }
    if (password === '' && email === '') {
      setMessage(t('err_invalid_email_password'));
      return;
    }

    if (email === '' || validateEmail(email.trim())) {
      setMessage(t('err_invalid_email'));
      return;
    }
    if (password === '') {
      setMessage(t('err_invalid_password'));
      return;
    }
    props.onLogin({
      email,
      workflow_title: props.workflow_title,
      password,
    }, props.redirect, props.application_id);
  };

  const responseGoogle = (response) => {
    localStorage.setItem('email', response.getBasicProfile().getEmail());
    props.onLoginSSO({
      email: response.getBasicProfile().getEmail(),
      name: response.getBasicProfile().getName(),
      workflow_title: props.workflow_title,
      id_token: response.tokenId,
    }, props.redirect);
  };

  const onLangChange = (e) => {
    setLang(e.target.value);
    localStorage.setItem('network_lang', e.target.value)
    window.location.reload();
  };

  const handleClickShowPassword = () => {
    setShowPassword(showPassword ? false : true);
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  var langSupportItems = []
  langSupports.forEach((langSupport, index) => {
    langSupportItems.push(
      <MenuItem key={langSupport.lang_code} id={langSupport.lang_code} value={langSupport.lang_code}>{langSupport.lang}</MenuItem>
    )
  })

  const { classes } = props;
  const classesMUI = useStyles();
  return (
    <GridContainer justify="center">
      <GridItem xs={12} sm={6} md={5}>
        <Card className={classes[cardAnimaton]}>

          <CardHeader onClick={gotoAdminLogin} color="primary" plain style={{ textAlign: 'center' }}>
            <b>{t('log_in_title')}</b>
          </CardHeader>

          <CardBody>
            <div className={classesMUI.root}>
              {message !== "" ? <div style={{ color: 'red' }}>{message}</div> : ""}
            </div>
            <CustomInput
              labelText={t('email')}
              id="email"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                onChange: onEmailChange.bind(this),
                onKeyPress: onKeyPressChange.bind(this),
                error: emailError,
                endAdornment: (
                  <InputAdornment position="end">
                    <Email className={classes.inputAdornmentIcon} onClick={resetNetwork} />
                  </InputAdornment>
                ),
              }}
            />

            <CustomInput
              labelText={t('password')}
              id="password"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                input_type: showPassword ? 'text' : 'password',
                error: passwordError,
                onChange: onPasswordChange.bind(this),
                onKeyPress: onKeyPressChange.bind(this),
                endAdornment: (
                  < InputAdornment position="end" >
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </CardBody>

          <div style={{
            padding: '0',
            paddingTop: '10px',
            margin: '0 15px 10px',
            borderRadius: '0',
            justifyContent: 'space-between',
            alignItems: 'center',
            // display: "flex",
            backgroundColor: 'transparent',
            border: '0',
          }}
          >
            <Button
              color="primary"
              round
              block
              onClick={onLoginClick.bind(this)}
            >
              {t('log_in_button')}
            </Button>
          </div>
          {localStorage.getItem('network_google_key') !== "" ?
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <GoogleLogin
                // clientId="667353648066-dd6dk9870a4vhdllrkf4io4r0lt4196d.apps.googleusercontent.com"
                clientId={localStorage.getItem('network_google_key')}
                onSuccess={responseGoogle.bind(this)}
                onFailure={responseGoogle.bind(this)}
                theme="dark"
                buttonText={t('google_continue')}
              />
              {/* <FacebookLogin
                    clientId="539201553456107"
                    onSuccess={this.responseFacebook}
                    onFailure={this.responseFacebook}
                  /> */}
              {props.requesting && <CircularProgress size={24} style={{ position: 'absolute', top: '74%', left: '47%' }} />}
            </div>
            : ""}
          <div style={{
            padding: '0',
            paddingTop: '10px',
            margin: '0 15px 10px',
            borderRadius: '0',
            justifyContent: 'space-between',
            alignItems: 'center',
            display: 'flex',
            backgroundColor: 'transparent',
            border: '0',
          }}
          >
            <Button
              color="primary"
              round
              block
              onClick={onRegisterClick.bind(this)}
            >
              {t('register_button')}
            </Button>
            <Button
              color="primary"
              round
              block
              onClick={onForgotPasswordClick.bind(this)}
            >
              {t('forgot_pswd_button')}
            </Button>
            <br />
          </div>
          <div style={{ display: showLangOption ? 'block' : 'none' }}>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              <Select
                id='lang'
                value={lang}
                onChange={onLangChange.bind(this)}
                autoWidth
              >
                {langSupportItems}
              </Select>
              <br />
            </div>
          </div>
        </Card>
      </GridItem>
    </GridContainer>
  );
};

export default withRoot(LoginComp);
