import React, { Component } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import UndefinedErrorSnackbar from 'components/Snackbar/UndefinedErrorSnackbar';
import * as customActions from 'actions/admin/CustomActions';
import CustomIntgComp from './CustomIntg/CustomIntgComp';

const CustomIntg = (props) => {
  const { classes } = props;
  const { custom, defaultStates } = props;
  const {
    getCustomIntgs,
  } = props.customActions;
  return (
    <div>
      {defaultStates.unDefinedError ? <UndefinedErrorSnackbar error_code={defaultStates.error} /> : ''}
      <CustomIntgComp
        getCustomIntgs={getCustomIntgs}
        custom_intg_list={custom.custom_intg_list === undefined ? [] : custom.custom_intg_list}
        requesting={custom.requesting}
        message={custom.message}
        error_code={custom.error_code}
        classes={classes}
      />
    </div>
  );
}

function mapStateToProps(state) {
  return {
    custom: state.adCustom,
    defaultStates: state.adDefaultStates,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    customActions: bindActionCreators(customActions, dispatch),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CustomIntg);
