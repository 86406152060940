import React, { useState, useEffect } from 'react';
import Card from '@mui/material/Card';
import CardBody from 'components/Card/CardBody';
import MUIDataTable from 'mui-datatables';
import { TableFooter, TableCell, TableRow } from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import Paper from "@material-ui/core/Paper";
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import CardHeader from 'components/Card/CardHeader';
import CardFooter from 'components/Card/CardFooter.jsx';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.jsx';
import DeleteIcon from '@mui/icons-material/Delete';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from 'components/Grid/GridItem.jsx';
import GridContainer from 'components/Grid/GridContainer.jsx';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import Switch from '@material-ui/core/Switch';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { SaveFormToAPI } from 'Api/common.js';


const ItemDatatableComp = (props) => {
	const [data, setData] = useState([]);
	const [subData, setSubData] = useState([]);
	const [tableColumn, setTableColumn] = useState([]);
	const [subTableColumn, setSubTableColumn] = useState([]);

	const [mulTransTotal, setMulTransTotal] = useState(0);
	const [mulTransLabel, setMulTransLabel] = useState("");
	const [mulValue, setMulValue] = useState(0.0);
	const [mulLabel, setMulLabel] = useState("");
	const [radioTransType, setRadioTransType] = useState(1);
	const [mulTransTotalResult, setMulTransTotalResult] = useState(0);
	const [mulTransArrayHistory, setMulTransArrayHistory] = useState([])
	const [mulTransSelectedTotal, setMulTransSelectedTotal] = useState(0)
	const [mulTransSelectedIndex, setMulTransSelectedIndex] = useState([])


	const [dialogOpen, setDialogOpen] = useState(false);
	const [description, setDescription] = useState('');
	const [historyTotal, setHistoryTotal] = useState([]);
	const [remainingTotal, setRemainingTotal] = useState([]);
	const [numOfSelectedRecord, setNumOfSelectedRecord] = useState(0);
	const [message, setMessage] = useState('');
	const [initialValue, setIntitialValue] = useState("");
	const [enteredAmount, setEnteredAmount] = useState(0.00);
	const [calculatedAmount, setCalculatedAmount] = useState(0.00);
	const [newRemainingAmount, setNewRemainingAmount] = useState(0.00);
	const [selectedItemID, setSelectedItemID] = useState(0);
	const [selectedIntgFieldID, setSelectedIntgFieldID] = useState(0);
	const [percentage, setPercentage] = useState('');

	useEffect(() => {
		if (props.datatable_rule) {
			var rules = JSON.parse(props.datatable_rule);
			var tempLabel = []
			rules.forEach((rule, i) => {
				if (rule.label.slice(0, 6) === "trans_") {
					tempLabel.push({
						name: rule.label.slice(6), label: rule.label.slice(6),
						options: {
							setCellProps: () => ({
								align: "right"
							}),
							setCellHeaderProps: (value) => ({
								style: { textAlign: 'right' }
							})
						}
					})
				} else if (rule.label.slice(0, 10) === "mul_trans_") {
				} else if (rule.label.slice(0, 4) === "mul_") {
				} else {
					tempLabel.push({ name: rule.label, label: rule.label })
				}
			})
			setTableColumn(tempLabel)
		}

		if (props.field_value !== "") {
			var fieldValue = JSON.parse(props.field_value)
			setNumOfSelectedRecord(fieldValue.length)
			var rowItems = [];
			var rules = JSON.parse(props.datatable_rule);
			for (let j = 0; j < fieldValue.length; j++) {
				var colItems = [];
				rules.forEach((rule, i) => {
					if (rule.label.slice(0, 6) === "trans_") {
						var transFieldValue = JSON.parse(fieldValue[j][rule.label]);
						if (transFieldValue.trans_value === "") {
							colItems.push(transFieldValue.initial_value)
						} else {
							colItems.push(transFieldValue.trans_value)
						}
					} else if (rule.label.slice(0, 10) === "mul_trans_") {
					} else if (rule.label.slice(0, 4) === "mul_") {
					} else {
						colItems.push(fieldValue[j][rule.label])
					}
				})
				rowItems.push(colItems)
			}

			var colItemsTotal = [];
			rules.forEach((rule, i) => {
				if (rule.label.slice(0, 6) === "trans_") {
					colItemsTotal.push(props.item_total)
				} else {
					colItemsTotal.push("")
				}
			})
			rowItems.push(colItemsTotal)
			setData(rowItems);
		}
	}, [props]);

	const handleEditClick = (e) => {
		setDialogOpen(true);

		setIntitialValue(e[0])
		setCalculatedAmount(e[1])
		setSelectedItemID(e[2])
		setSelectedIntgFieldID(e[3])
		setRadioTransType(e[4])
		setPercentage(e[5])
		setDescription(e[6])
		setHistoryTotal(e[7])


		setMulTransLabel(e[8])
		setMulValue(e[9])
		setMulLabel(e[10])

		setMulTransArrayHistory(e[11])
		setMulTransTotal(e[12])

		var lclMulTransHistoryArray = e[11]
		var lclMulTransTotal = e[12]


		setSubTableColumn(["Date", "Description", "Tag", e[8]])

		var rowItems = [];
		for (let j = 0; j < lclMulTransHistoryArray.length; j++) {
			var colItems = [];
			colItems.push(new Date(lclMulTransHistoryArray[j].insert_date).toLocaleString(localStorage.getItem('network_lang'), { year: "numeric", month: "2-digit", day: "numeric" }))
			colItems.push(lclMulTransHistoryArray[j].description)
			colItems.push(lclMulTransHistoryArray[j].tag)
			colItems.push(lclMulTransHistoryArray[j].trans_value)
			rowItems.push(colItems)
		}

		var colItemsTotal = [];
		colItemsTotal.push("")
		colItemsTotal.push("")
		colItemsTotal.push(lclMulTransTotal)
		rowItems.push(colItemsTotal)
		setSubData(rowItems);


		var tempInitialAmount = parseFloat(e[0]).toFixed(2)
		var tempHistoryAmount = parseFloat(e[7]).toFixed(2)
		var tempRemainingTotal = (tempInitialAmount - tempHistoryAmount).toFixed(2)
		setRemainingTotal(tempRemainingTotal)

		if (radioTransType == 0) {

		} else if (radioTransType == 1) {

			if (tempSelectedValue === 'NaN') {
				setNewRemainingAmount(tempRemainingTotal)
				setCalculatedAmount(0.00)
			} else {
				setEnteredAmount(tempSelectedValue)
				setCalculatedAmount(tempSelectedValue)
				setNewRemainingAmount((tempRemainingTotal - tempSelectedValue).toFixed(2))
			}
		} else if (radioTransType == 2) {

			var tempPercentage = parseFloat(e[5]).toFixed(2)
			var tempCalc = (tempPercentage * tempRemainingTotal) / 100
			setCalculatedAmount(tempCalc.toFixed(2))
			setNewRemainingAmount((tempRemainingTotal - tempCalc).toFixed(2))
		}
		/*
		if (e[4] === '' || e[4] === 'false') {
			setPercentageBln(false)
			var tempSelectedValue = parseFloat(e[1]).toFixed(2)
			if (tempSelectedValue === 'NaN') {
				setNewRemainingAmount(tempRemainingTotal)
				setCalculatedAmount(0.00)
			} else {
				setEnteredAmount(tempSelectedValue)
				setCalculatedAmount(tempSelectedValue)
				setNewRemainingAmount((tempRemainingTotal - tempSelectedValue).toFixed(2))
			}
		} else {
			setPercentageBln(true)
			var tempPercentage = parseFloat(e[5]).toFixed(2)
			var tempCalc = (tempPercentage * tempRemainingTotal) / 100
			setCalculatedAmount(tempCalc.toFixed(2))
			setNewRemainingAmount((tempRemainingTotal - tempCalc).toFixed(2))
		}
		*/
	};
	const handleDialogClose = (e) => {
		setDialogOpen(false);
		setMessage('');
	};
	const handleDialogConfirm = (e) => {

		var tempInitialAmount = parseFloat(initialValue).toFixed(2)
		var tempHistoryAmount = parseFloat(historyTotal).toFixed(2)
		var tempCalculatedAmount = parseFloat(calculatedAmount).toFixed(2)
		var tempRemainingTotal = (tempInitialAmount - tempHistoryAmount).toFixed(2)

		if (fp_greater_than(tempCalculatedAmount, tempRemainingTotal)) {
			setMessage("Invalid amount")
			return
		}
		setDialogOpen(false);
		setMessage('');

		var rtnField = []
		var tempFields = props.fields;
		var foundIndex = tempFields.findIndex((x) => x.id === props.field_id);
		if (tempFields[foundIndex].valueArray.length > 1) {
			rtnField.push(tempFields[foundIndex].valueArray[0])
			var aryField = JSON.parse(tempFields[foundIndex].valueArray[1])
			var tempAryField = aryField
			for (let x = 0; x < tempAryField.length; x++) {
				for (var key in tempAryField[x]) {
					if (key.slice(0, 6) === "trans_") {
						var aryTrans = JSON.parse(tempAryField[x][key])
						var tempAryTrans = aryTrans
						if (tempAryTrans.item_id === selectedItemID && tempAryTrans.intg_field_id == selectedIntgFieldID) {
							tempAryTrans.trans_value = calculatedAmount
							tempAryTrans.description = description
							tempAryTrans.trans_type = radioTransType.toString()
							tempAryTrans.percentage = percentage
						}
						tempAryField[x][key] = JSON.stringify(tempAryTrans)
					}
				}
			}
			tempFields[foundIndex].valueArray[1] = JSON.stringify(tempAryField)
		}
		//console.log("tempFields:", tempFields)
		SaveFormToAPI(props.form_id, props.requesting, tempFields, [], [], "", "", props.postApplicationDetail, props.application_id, props.task_id)
	};

	/*
	const onPercentageBlnChange = (e) => {
		var tempInitialAmount = parseFloat(initialValue).toFixed(2)
		var tempHistoryAmount = parseFloat(historyTotal).toFixed(2)
		var tempRemainingTotal = (tempInitialAmount - tempHistoryAmount).toFixed(2)
		setPercentageBln(e.target.checked);
		setEnteredAmount(0.0)
		setPercentage(0.0)
		setCalculatedAmount(0.0)
		setNewRemainingAmount(tempRemainingTotal)
	};
	*/
	const onPercentageChange = (e) => {
		setMessage("")
		setPercentage(e.target.value)
		var tempInitialAmount = parseFloat(initialValue).toFixed(2)
		var tempHistoryAmount = parseFloat(historyTotal).toFixed(2)
		var tempRemainingTotal = (tempInitialAmount - tempHistoryAmount).toFixed(2)

		if (!isNumber(e.target.value.trim())) {
			setMessage("Invalid Percentage Number")
			setNewRemainingAmount(tempRemainingTotal)
			setCalculatedAmount(0.00)
			return
		}

		var tempNum = parseFloat(e.target.value.trim()).toFixed(2);
		if (tempNum === 'NaN') {
			setNewRemainingAmount(tempRemainingTotal)
			setCalculatedAmount(0.00)
			return
		}
		if (tempNum < 0.01 || tempNum > 100) {
			setMessage("Percentage Number should be between 0.01 and 100")
			setNewRemainingAmount(tempRemainingTotal)
			setCalculatedAmount(0.00)
			return
		}

		var tempCalc = (tempNum * tempRemainingTotal) / 100
		setCalculatedAmount(tempCalc.toFixed(2))
		setNewRemainingAmount((tempRemainingTotal - tempCalc).toFixed(2))
	};


	const onRadioValueChange = (e) => {
		setRadioTransType(e.target.value)
		var tempInitialAmount = parseFloat(initialValue).toFixed(2)
		var tempHistoryAmount = parseFloat(historyTotal).toFixed(2)
		var tempRemainingTotal = (tempInitialAmount - tempHistoryAmount).toFixed(2)
		setEnteredAmount(0.0)
		setPercentage(0.0)
		setCalculatedAmount(0.0)
		setNewRemainingAmount(tempRemainingTotal)
	};
	const onEnteredAmountChange = (e) => {
		setMessage("")
		setEnteredAmount(e.target.value)
		var tempInitialAmount = parseFloat(initialValue).toFixed(2)
		var tempHistoryAmount = parseFloat(historyTotal).toFixed(2)
		var tempRemainingTotal = (tempInitialAmount - tempHistoryAmount).toFixed(2)

		if (!isNumber(e.target.value.trim())) {
			setMessage("Invalid Amount")
			setNewRemainingAmount(tempRemainingTotal)
			setCalculatedAmount(0.00)
			return
		}

		var tempNum = parseFloat(e.target.value.trim()).toFixed(2);
		if (tempNum === 'NaN') {
			setNewRemainingAmount(tempRemainingTotal)
			setCalculatedAmount(0.00)
			return
		}

		setCalculatedAmount(tempNum)
		setNewRemainingAmount((tempRemainingTotal - tempNum).toFixed(2))
	};
	const onDescriptionChange = (e) => {
		setDescription(e.target.value);
	};



	const { classes } = props;

	const subTableOptions = {
		filterType: 'dropdown',
		responsive: 'standard',
		filter: false,
		download: false,
		print: false,
		search: false,
		viewColumns: false,
		sort: false,
		pagination: false,
		rowsSelected: mulTransSelectedIndex,
		selectableRowsHeader: false,
		rowsPerPage: 100,
		selectableRows: 'multiple',
		selectToolbarPlacement: 'none',
		expandableRows: false,
		expandableRowsHeader: false,
		expandableRowsOnClick: false,
		onRowSelectionChange: (rowsSelected, allRows, onlySelectedIndex) => {
			if (radioTransType == 0) {
				setMulTransSelectedIndex(onlySelectedIndex)
				var tempSelectedValue = 0.0
				allRows.forEach(element => {
					if (element.dataIndex < mulTransArrayHistory.length) {
						tempSelectedValue = tempSelectedValue + parseFloat(mulTransArrayHistory[element.dataIndex].trans_value)
					}
				})
				tempSelectedValue = tempSelectedValue.toFixed(2)
				setMulTransSelectedTotal(tempSelectedValue)
				var tempMulValue = parseFloat(mulValue).toFixed(2)
				var tempCalc = 0.0
				tempCalc = (tempSelectedValue * tempMulValue).toFixed(2)
				console.log("tempSelectedValue:", tempSelectedValue)
				console.log("tempMulValue:", tempMulValue)
				console.log("tempCalc:", tempCalc)
				setMulTransTotalResult(tempCalc)

				setCalculatedAmount(tempCalc)

				var tempInitialAmount = parseFloat(initialValue).toFixed(2)
				var tempHistoryAmount = parseFloat(historyTotal).toFixed(2)
				var tempRemainingTotal = (tempInitialAmount - tempHistoryAmount).toFixed(2)

				setNewRemainingAmount((tempRemainingTotal - tempCalc).toFixed(2))

			}

		},
		isRowSelectable: (dataIndex, selectedRows) => {
			if (dataIndex < mulTransArrayHistory.length) {
				return true
			}
			return false
		}
		//function(dataIndex: number, selectedRows: object(lookup: {dataindex: boolean}, data: arrayOfObjects: {index, dataIndex})) => boolean
		//selectableRowsOnClick Enable/disable select toggle when row is clicked. When False, only checkbox will trigger this action.
	}
	const options = {
		filterType: 'dropdown',
		responsive: 'standard',
		filter: false,
		download: false,
		print: false,
		search: false,
		viewColumns: false,
		sort: false,
		pagination: false,
		selectableRows: 'none',
		rowsPerPage: 100,
		selectToolbarPlacement: 'none',
		expandableRows: true,
		expandableRowsHeader: false,
		expandableRowsOnClick: true,
		isRowExpandable: (dataIndex, expandedRows) => {
			if (numOfSelectedRecord === dataIndex) {
				return false
			}
			/*
			if (expandedRows.data.length > 0) {
				if (expandedRows.data[0].dataIndex === dataIndex) {
					return true
				} else {
					return false
				}
			}
			*/
			return true
		},
		renderExpandableRow: (rowData, rowMeta) => {
			var lclInitialValue = ""
			var lclSelectedValue = ""
			var lclSelectedValueDesc = ""
			var lclSelectedItemID = 0
			var lclSelectedIntgFieldID = 0
			var lclTransType = 1
			var lclTag = false
			var lclSelectedPercentage = 0
			var lclHistory = []
			var lclHistoryUI = []
			var lclHistoryTotal = ""

			var lclHistoryTotalUI = []
			var lclMulTransJSON = ""
			var lclMulTransLabel = ""
			var lclMulValue = ""
			var lclMulLabel = ""
			var lclMulTransJSONArrayHistory = []
			var lclMulTransJSONArrayTotal = []





			const colSpan = rowData.length + 1;
			var fieldValue = JSON.parse(props.field_value)
			var rules = JSON.parse(props.datatable_rule);
			for (let j = 0; j < fieldValue.length; j++) {
				if (j === rowMeta.rowIndex) {
					rules.forEach((rule, i) => {
						if (rule.label.slice(0, 6) === "trans_") {
							var transFieldValue = JSON.parse(fieldValue[j][rule.label]);
							lclInitialValue = transFieldValue.initial_value
							lclSelectedValue = transFieldValue.trans_value
							lclSelectedValueDesc = transFieldValue.description
							lclSelectedItemID = transFieldValue.item_id
							lclSelectedIntgFieldID = transFieldValue.intg_field_id
							lclTransType = transFieldValue.trans_type
							lclSelectedPercentage = transFieldValue.percentage
							lclTag = transFieldValue.tag
							lclHistory = transFieldValue.history
							lclHistoryTotal = transFieldValue.item_history_total
						} else if (rule.label.slice(0, 10) === "mul_trans_") {
							lclMulTransJSON = fieldValue[j][rule.label]
							var lclMulTransJSONArray = JSON.parse(lclMulTransJSON);
							lclMulTransJSONArrayHistory = lclMulTransJSONArray.history
							//setMulTransArrayHistory(lclMulTransJSONArray.history)
							lclMulTransJSONArrayTotal = lclMulTransJSONArray.item_history_total
							//setMulTransTotal(lclMulTransJSONArray.item_history_total)
							lclMulTransLabel = rule.label.slice(10)
						} else if (rule.label.slice(0, 4) === "mul_") {
							lclMulValue = fieldValue[j][rule.label]
							lclMulLabel = rule.label.slice(4)
						}
					})
				}
			}




			const tempTransHistoryItems = [];
			lclHistory.forEach((element, i) => {
				tempTransHistoryItems.push(
					<TableRow>
						<TableCell>{new Date(element.insert_date).toLocaleString(localStorage.getItem('network_lang'), { year: "numeric", month: "2-digit", day: "numeric" })}</TableCell>
						<TableCell>{element.tag}</TableCell>
						<TableCell>{element.description}</TableCell>
						<TableCell>{element.trans_value}</TableCell>
					</TableRow>
				)
			})
			lclHistoryUI = tempTransHistoryItems
			lclHistoryTotalUI.push(<TableRow>
				<TableCell>{ }</TableCell>
				<TableCell>{ }</TableCell>
				<TableCell>{ }</TableCell>
				<TableCell>{lclHistoryTotal}</TableCell>
			</TableRow>)

			/*
			console.log("intialValue:", lclInitialValue)
			console.log("selectedValue:", lclSelectedValue)
			console.log("selectedValueDesc:", lclSelectedValueDesc)
			console.log("selectedItemID ", lclSelectedItemID)
			console.log("selectedIntgFieldID ", lclSelectedIntgFieldID)
			*/
			var fltInitialAmount = parseFloat(lclInitialValue).toFixed(2)
			var fltSelectedValue = parseFloat(lclSelectedValue).toFixed(2)
			var fltHistoryValue = parseFloat(lclHistoryTotal).toFixed(2)
			var fltRemainingTotal = (fltInitialAmount - fltHistoryValue).toFixed(2)
			var fltNewRemaining = (fltRemainingTotal - fltSelectedValue).toFixed(2)
			if (fltNewRemaining === 'NaN') {
				fltNewRemaining = fltInitialAmount
			}

			return (
				<React.Fragment>
					<tr  >
						<td colSpan={colSpan} >
							<TableContainer component={Paper} style={{ paddingLeft: 150, paddingRight: 150 }} >
								<div style={{ padding: 10, fontWeight: 'bold' }}> New Payment</div>
								<Table style={{ minWidth: "250" }} aria-label="simple table">
									<TableBody>
										<TableRow>
											<TableCell>Actual Amount</TableCell>
											<TableCell>{lclInitialValue}</TableCell>
											<TableCell></TableCell>
										</TableRow>
										<TableRow>
											<TableCell>History Total</TableCell>
											<TableCell>{lclHistoryTotal}</TableCell>
											<TableCell></TableCell>
										</TableRow>
										<TableRow>
											<TableCell>Remaining Total</TableCell>
											<TableCell>{fltRemainingTotal}</TableCell>
											<TableCell></TableCell>
										</TableRow>
										<TableRow>
											<TableCell>New Payment Amount</TableCell>
											<TableCell>{lclSelectedValue}</TableCell>
											<TableCell>
												<div style={{
													cursor: 'pointer'
												}} onClick={handleEditClick.bind(this, [lclInitialValue, lclSelectedValue, lclSelectedItemID, lclSelectedIntgFieldID, lclTransType, lclSelectedPercentage, lclSelectedValueDesc, lclHistoryTotal, lclMulTransLabel, lclMulValue, lclMulLabel, lclMulTransJSONArrayHistory, lclMulTransJSONArrayTotal])} >
													<IconButton onClick={handleEditClick.bind(this, [lclInitialValue, lclSelectedValue, lclSelectedItemID, lclSelectedIntgFieldID, lclTransType, lclSelectedPercentage, lclSelectedValueDesc, lclHistoryTotal, lclMulTransLabel, lclMulValue, lclMulLabel, lclMulTransJSONArrayHistory, lclMulTransJSONArrayTotal])} title="Edit Payment" > {' '}
														<EditIcon />
													</IconButton>
												</div>
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>Balance After New Payment</TableCell>
											<TableCell>{fltNewRemaining}</TableCell>
											<TableCell></TableCell>
										</TableRow>
										<TableRow>
											<TableCell>Description</TableCell>
											<TableCell>{lclSelectedValueDesc}</TableCell>
											<TableCell>
												<div style={{
													cursor: 'pointer'
												}} onClick={handleEditClick.bind(this, [lclInitialValue, lclSelectedValue, lclSelectedItemID, lclSelectedIntgFieldID, lclTransType, lclSelectedPercentage, lclSelectedValueDesc, lclHistoryTotal, lclMulTransLabel, lclMulValue, lclMulLabel, lclMulTransJSONArrayHistory, lclMulTransJSONArrayTotal])} >
													<IconButton onClick={handleEditClick.bind(this, [lclInitialValue, lclSelectedValue, lclSelectedItemID, lclSelectedIntgFieldID, lclTransType, lclSelectedPercentage, lclSelectedValueDesc, lclHistoryTotal, lclMulTransLabel, lclMulValue, lclMulLabel, lclMulTransJSONArrayHistory, lclMulTransJSONArrayTotal])} title="Edit Payment" > {' '}
														<EditIcon />
													</IconButton>
												</div>
											</TableCell>
										</TableRow>
									</TableBody>
								</Table>
							</TableContainer>
							<br />
							<TableContainer component={Paper} style={{ paddingLeft: 150, paddingRight: 150 }} >
								<div style={{ padding: 10, fontWeight: 'bold' }}>Payment History</div>
								<Table style={{ minWidth: "650" }} aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>Date</TableCell>
											<TableCell>Tag</TableCell>
											<TableCell>Description</TableCell>
											<TableCell>Amount</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{lclHistoryUI}
										{lclHistoryTotalUI}
									</TableBody>
								</Table>
							</TableContainer>

						</td>
					</tr>
				</React.Fragment>
			);


		},

	};
	return (
		<div >
			<MUIDataTable
				data={data}
				columns={tableColumn}
				options={options}
			/>
			<Dialog fullWidth open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
				<DialogContent>
					<Card>
						<CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
							<b>Edit New Payment Amount</b>
						</CardHeader>
						<CardBody>
							<div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
							<br />
							<RadioGroup
								aria-labelledby="demo-radio-buttons-group-label"
								//defaultValue="female"
								name="radio-buttons-group"
								value={radioTransType}
								onChange={onRadioValueChange.bind(this)}
							>
								{mulTransArrayHistory.length > 0 ?
									<GridContainer>
										<GridItem xs={12} sm={12} md={2}>
											<FormControlLabel value={0} control={<Radio />} />
										</GridItem>
										<GridItem xs={12} sm={12} md={10}>
											<MUIDataTable
												data={subData}
												columns={subTableColumn}
												options={subTableOptions}
											/>
											<br />
											<div style={{ display: (radioTransType == 0) ? 'block' : 'none' }}>
												{mulLabel} ({mulValue})  X  Selected {mulTransLabel} ({mulTransSelectedTotal}) = {mulTransTotalResult}
											</div>
										</GridItem>
									</GridContainer>
									: ""}
								<br />
								<GridContainer>
									<GridItem xs={12} sm={12} md={2}>
										<FormControlLabel value={1} control={<Radio />} />
									</GridItem>
									<GridItem xs={12} sm={12} md={10}>
										<TextField
											required={false}
											fullWidth
											label="Entered Amount"
											value={enteredAmount}
											onChange={onEnteredAmountChange.bind(this)}
											disabled={radioTransType != 1}
										/>
									</GridItem>
								</GridContainer>
								<br />
								<GridContainer>
									<GridItem xs={12} sm={12} md={2}>
										<FormControlLabel value={2} control={<Radio />} />
									</GridItem>
									<GridItem xs={12} sm={12} md={10}>
										<TextField
											required={false}
											fullWidth
											label="Percentage"
											value={percentage}
											onChange={onPercentageChange.bind(this)}
											disabled={radioTransType != 2}
										/>
									</GridItem>
								</GridContainer>
							</RadioGroup>
							<br />
							<GridContainer>
								<GridItem xs={12} sm={12} md={6}>
									Actual Amount
								</GridItem>
								<GridItem xs={12} sm={12} md={6}>
									{initialValue}
								</GridItem>
							</GridContainer>
							<br />
							<GridContainer>
								<GridItem xs={12} sm={12} md={6}>
									History Total
								</GridItem>
								<GridItem xs={12} sm={12} md={6}>
									{historyTotal}
								</GridItem>
							</GridContainer>
							<br />
							<GridContainer>
								<GridItem xs={12} sm={12} md={6}>
									Remaining Total
								</GridItem>
								<GridItem xs={12} sm={12} md={6}>
									{remainingTotal}
								</GridItem>
							</GridContainer>
							<br />
							<GridContainer>
								<GridItem xs={12} sm={12} md={6}>
									{(radioTransType == 0 || radioTransType == 2) ? "New Calculated " : "New Entered "} {radioTransType == 2 && percentage > 0 ? percentage + "%" : ""} Amount
								</GridItem>
								<GridItem xs={12} sm={12} md={6}>
									{calculatedAmount}
								</GridItem>
							</GridContainer>
							<br />
							<GridContainer>
								<GridItem xs={12} sm={12} md={6}>
									Balance After New Payment
								</GridItem>
								<GridItem xs={12} sm={12} md={6}>
									{newRemainingAmount}
								</GridItem>
							</GridContainer>
							<br />
							<GridContainer>
								<GridItem xs={12} sm={12} md={12}>
									<TextField
										required={false}
										fullWidth
										label="Description"
										value={description}
										onChange={onDescriptionChange.bind(this)}
										disabled={false}
									/>
								</GridItem>
							</GridContainer>
						</CardBody>
						<CardFooter style={{ textAlign: 'left' }} />
					</Card>
				</DialogContent>
				<DialogActions>
					<Button onClick={handleDialogClose.bind(this)} color="primary" round>
						Cancel
					</Button>
					<Button onClick={handleDialogConfirm.bind(this)} color="primary" round>
						Confirm Change
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
export default ItemDatatableComp;

function isNumber(value) {
	return value.match(/^-?\d*(\.\d+)?$/)
}
var EPSILON = 0.000001;

function fp_less_than(A, B, Epsilon) {
	Epsilon = Epsilon || EPSILON;
	return (A - B < Epsilon) && (Math.abs(A - B) > Epsilon);
};

function fp_greater_than(A, B, Epsilon) {
	Epsilon = Epsilon || EPSILON;
	return (A - B > Epsilon) && (Math.abs(A - B) > Epsilon);
};