import React, { useState, useEffect } from 'react';
import MUIDataTable from "mui-datatables";
import Card from '@mui/material/Card';
import CardHeader from 'components/Card/CardHeader';
import CardBody from 'components/Card/CardBody';
import CardFooter from "components/Card/CardFooter.jsx";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from 'components/CustomButtons/Button.jsx';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CircularProgress from '@mui/material/CircularProgress';
import GridItem from "components/Grid/GridItem.jsx";
import GridContainer from "components/Grid/GridContainer.jsx";
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select'
import Grid from '@material-ui/core/Grid';
import Switch from "@material-ui/core/Switch";
import withStyles from "@material-ui/core/styles/withStyles";
import extendedFormsStyle from "assets/jss/material-dashboard-react/views/extendedFormsStyle.jsx";

import {
  EMAIL_SMTP,
  FILE_SHARING_AWS,
} from 'constants/networkadmin/APITypes';


const IntgExtAPIComp = (props) => {

  const [dialogOpen, setDialogOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState("");
  const [dialogButton, setDialogButton] = useState("");
  const [fieldDisabled, setFieldDisabled] = useState(false);
  const [dialogType, setDialogType] = useState("add");
  const [data, setData] = useState([]);
  const [message, setMessage] = useState("");
  const [id, setID] = useState("");
  const [apiName, setApiName] = useState('')
  const [type, setType] = useState('')
  const [method, setMethod] = useState('GET')
  const [apiEndPoint1, setApiEndPoint1] = useState('')
  const [apiEndPoint2, setApiEndPoint2] = useState('')
  const [apiEndPoint3, setApiEndPoint3] = useState('')
  const [setting1, setSetting1] = useState('')
  const [setting2, setSetting2] = useState('')
  const [setting3, setSetting3] = useState('')
  const [setting4, setSetting4] = useState('')
  const [setting5, setSetting5] = useState('')
  const [apiKey, setApiKey] = useState('')
  const [apiSecret, setApiSecret] = useState('')
  const [token, setToken] = useState('')
  const [status, setStatus] = useState(true)

  const [showMethod, setShowMethod] = useState(false)
  const [showApiEndPoint1, setShowApiEndPoint1] = useState(false)
  const [showApiEndPoint2, setShowApiEndPoint2] = useState(false)
  const [showApiEndPoint3, setShowApiEndPoint3] = useState(false)
  const [showApiKey, setShowApiKey] = useState(false)
  const [showApiSecret, setShowApiSecret] = useState(false)
  const [showToken, setShowToken] = useState(false)
  const [showSetting1, setShowSetting1] = useState(false)
  const [showSetting2, setShowSetting2] = useState(false)
  const [showSetting3, setShowSetting3] = useState(false)
  const [showSetting4, setShowSetting4] = useState(false)
  const [showSetting5, setShowSetting5] = useState(false)

  const [labelMethod, setLabelMethod] = useState('')
  const [labelApiEndPoint1, setLabelApiEndPoint1] = useState('API Endpoint 1')
  const [labelApiEndPoint2, setLabelApiEndPoint2] = useState('API Endpoint 2')
  const [labelApiEndPoint3, setLabelApiEndPoint3] = useState('API Endpoint 3')
  const [labelApiKey, setLabelApiKey] = useState('API Key')
  const [labelApiSecret, setLabelApiSecret] = useState('API Secret')
  const [labelToken, setLabelToken] = useState('Token')
  const [labelSetting1, setLabelSetting1] = useState('Setting 1')
  const [labelSetting2, setLabelSetting2] = useState('Setting 2')
  const [labelSetting3, setLabelSetting3] = useState('Setting 3')
  const [labelSetting4, setLabelSetting4] = useState('Setting 4')
  const [labelSetting5, setLabelSetting5] = useState('Setting 5')

  const hideShowComponents = (value) => {
    switch (value) {
      case EMAIL_SMTP:
        setMessage('');
        setType(value);
        setShowMethod(false);
        setShowApiEndPoint1(true);
        setLabelApiEndPoint1("SMTP Host E.g. email-smtp.us-east-2.amazonaws.com");
        setShowApiEndPoint2(false);
        setShowApiEndPoint3(false);
        setShowApiKey(true);
        setLabelApiKey("Access Key ID");
        setShowApiSecret(true);
        setLabelApiSecret("Secret Access Key");
        setShowToken(false);
        setShowSetting1(true);
        setLabelSetting1("Sender Name");
        setShowSetting2(true);
        setLabelSetting2("Sender Email");
        setShowSetting3(true);
        setLabelSetting3("SMTP Port E.g. 587");
        setShowSetting4(false);
        setShowSetting5(false);
        break;
      case FILE_SHARING_AWS:
        setMessage('');
        setType(value);
        setShowMethod(false);
        setShowApiEndPoint1(false);
        setShowApiEndPoint2(false);
        setShowApiEndPoint3(false);
        setShowApiKey(true);
        setLabelApiKey("AWS Access Key ID");
        setShowApiSecret(true);
        setLabelApiSecret("AWS Secret Access Key");
        setShowToken(false);
        setShowSetting1(true);
        setLabelSetting1("S3 Region");
        setShowSetting2(true);
        setLabelSetting2("S3 Bucket Name");
        setShowSetting3(false);
        setShowSetting4(false);
        setShowSetting5(false);
        break;
    }
  }



  const { getIntgExtAPIs } = props;
  useEffect(() => {
    getIntgExtAPIs();
  }, [getIntgExtAPIs])


  useEffect(() => {
    setMessage(props.message)
    if (props.refreshIntgExtAPI) {
      props.getIntgExtAPIs();
      setDialogOpen(false)
    }
    var rowItems = [];
    for (let i = 0; i < props.intg_ext_api_list.length; i++) {
      var colItems = [];
      colItems.push(i + 1);
      colItems.push(props.intg_ext_api_list[i].type);
      colItems.push(props.intg_ext_api_list[i].api_name);
      colItems.push(props.intg_ext_api_list[i].status ? 'Active' : 'Inactive');
      if (localStorage.getItem("intg_ext_api_id") !== props.intg_ext_api_list[i].id.toString()) {
        colItems.push(<div><IconButton
          onClick={handleEditClick.bind(this,
            [props.intg_ext_api_list[i].id,
            props.intg_ext_api_list[i].api_name,
            props.intg_ext_api_list[i].api_endpoint1,
            props.intg_ext_api_list[i].api_endpoint2,
            props.intg_ext_api_list[i].api_endpoint3,
            props.intg_ext_api_list[i].api_key,
            props.intg_ext_api_list[i].api_secret,
            props.intg_ext_api_list[i].status,
            props.intg_ext_api_list[i].type,
            props.intg_ext_api_list[i].token,
            props.intg_ext_api_list[i].setting1,
            props.intg_ext_api_list[i].setting2,
            props.intg_ext_api_list[i].setting3,
            props.intg_ext_api_list[i].setting4,
            props.intg_ext_api_list[i].setting5,
            props.intg_ext_api_list[i].method,
            ])}
          title="Edit row"> <EditIcon /></IconButton>
          <IconButton onClick={handleDeleteClick.bind(this,
            [props.intg_ext_api_list[i].id,
            props.intg_ext_api_list[i].api_name,
            props.intg_ext_api_list[i].api_endpoint1,
            props.intg_ext_api_list[i].api_endpoint2,
            props.intg_ext_api_list[i].api_endpoint3,
            props.intg_ext_api_list[i].api_key,
            props.intg_ext_api_list[i].api_secret,
            props.intg_ext_api_list[i].status,
            props.intg_ext_api_list[i].type,
            props.intg_ext_api_list[i].token,
            props.intg_ext_api_list[i].setting1,
            props.intg_ext_api_list[i].setting2,
            props.intg_ext_api_list[i].setting3,
            props.intg_ext_api_list[i].setting4,
            props.intg_ext_api_list[i].setting5,
            props.intg_ext_api_list[i].method,
            ])}
            title="Delete row"> <DeleteIcon /></IconButton>
        </div>);
        rowItems.push(colItems);
      }
    }
    setData(rowItems)
  }, [props])



  const handleEditClick = (e) => {
    setID(e[0])
    setApiName(e[1])
    setApiEndPoint1(e[2])
    setApiEndPoint2(e[3])
    setApiEndPoint3(e[4])
    setApiKey(e[5])
    setApiSecret(e[6])
    setStatus(e[7])
    setType(e[8])
    setToken(e[9])
    setSetting1(e[10])
    setSetting2(e[11])
    setSetting3(e[12])
    setSetting4(e[13])
    setSetting5(e[14])
    setMethod(e[15])
    setMessage('')
    setDialogTitle('Edit')
    setDialogButton('Save')
    setFieldDisabled(false)
    setDialogType('update')
    setDialogOpen(true)

    hideShowComponents(e[8]);
  }
  const handleDeleteClick = (e) => {
    setID(e[0])
    setApiName(e[1])
    setApiEndPoint1(e[2])
    setApiEndPoint2(e[3])
    setApiEndPoint3(e[4])
    setApiKey(e[5])
    setApiSecret(e[6])
    setStatus(e[7])
    setType(e[8])
    setToken(e[9])
    setSetting1(e[10])
    setSetting2(e[11])
    setSetting3(e[12])
    setSetting4(e[13])
    setSetting5(e[14])
    setMethod(e[15])
    setMessage('')
    setDialogTitle('Delete')
    setDialogButton('Confirm Delete')
    setFieldDisabled(true)
    setDialogType('delete')
    setDialogOpen(true)
    hideShowComponents(e[8]);
  }
  const handleNewClick = (e) => {
    setApiName('')
    setApiEndPoint1('')
    setApiEndPoint2('')
    setApiEndPoint3('')
    setSetting1('')
    setSetting2('')
    setSetting3('')
    setSetting4('')
    setSetting5('')
    setMethod('')
    setApiKey('')
    setApiSecret('')
    setStatus('')
    setType(EMAIL_SMTP)
    hideShowComponents(EMAIL_SMTP);
    setToken('')
    setMessage('')
    setDialogTitle('Add New')
    setDialogButton('Save')
    setFieldDisabled(false)
    setDialogType('add')
    setDialogOpen(true)
  }
  const handleDialogClose = (e) => {
    setDialogOpen(false)
    setMessage('')
  }
  const handleDialogConfirm = (e) => {
    if (dialogType === 'add') {
      props.createIntgExtAPI({
        type: type,
        api_name: apiName,
        api_endpoint1: apiEndPoint1,
        api_endpoint2: apiEndPoint2,
        api_endpoint3: apiEndPoint3,
        setting1: setting1,
        setting2: setting2,
        setting3: setting3,
        setting4: setting4,
        setting5: setting5,
        method: method,
        api_key: apiKey,
        api_secret: apiSecret,
        token: token,
        status: status ? "1" : "0",
      })
    } else if (dialogType === 'update') {
      props.updateIntgExtAPI(id, {
        type: type,
        api_name: apiName,
        api_endpoint1: apiEndPoint1,
        api_endpoint2: apiEndPoint2,
        api_endpoint3: apiEndPoint3,
        setting1: setting1,
        setting2: setting2,
        setting3: setting3,
        setting4: setting4,
        setting5: setting5,
        method: method,
        api_key: apiKey,
        api_secret: apiSecret,
        token: token,
        status: status ? "1" : "0",
      })
    } else if (dialogType === 'delete') {
      props.deleteIntgExtAPI(id)
    }
  }
  const onAPINameChange = (e) => {
    setApiName(e.target.value)
  }
  const onMethodChange = (e) => {
    setMethod(e.target.value)
  }
  const onAPIEndPoint1Change = (e) => {
    setApiEndPoint1(e.target.value)
  }
  const onAPIEndPoint2Change = (e) => {
    setApiEndPoint2(e.target.value)
  }
  const onAPIEndPoint3Change = (e) => {
    setApiEndPoint3(e.target.value)
  }
  const onSetting1Change = (e) => {
    setSetting1(e.target.value)
  }
  const onSetting2Change = (e) => {
    setSetting2(e.target.value)
  }
  const onSetting3Change = (e) => {
    setSetting3(e.target.value)
  }
  const onSetting4Change = (e) => {
    setSetting4(e.target.value)
  }
  const onSetting5Change = (e) => {
    setSetting5(e.target.value)
  }
  const onTokenChange = (e) => {
    setToken(e.target.value)
  }
  const onTypeChange = (e) => {
    setType(e.target.value)
    hideShowComponents(e.target.value);
  }
  const onAPIKeyChange = (e) => {
    setApiKey(e.target.value)
  }
  const onAPISecretChange = (e) => {
    setApiSecret(e.target.value)
  }
  const onStatusChange = (e) => {
    setStatus(e.target.checked)
  }

  const { classes } = props;
  const columns = [
    "Serial",
    "Type",
    "API Name",
    "Status",
    ""
  ];
  const options = {
    filterType: "dropdown",
    responsive: "standard",
    filter: true,
    selectableRows: "none",
    rowsPerPage: 100,
    selectToolbarPlacement: "none"
  };
  return (
    <div>
      {props.requesting && <CircularProgress size={44} style={{ position: 'absolute', top: '50%', left: '50%' }} />}
      <Card>
        <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
          <b>External APIs</b>
          <p className={classes.cardCategoryWhite}>
            External APIs used in the system.
          </p>
        </CardHeader>
        <CardBody>
          <MUIDataTable
            title={<Button onClick={handleNewClick.bind(this)} color="primary" title="Create a new External API" round > New External API </Button>}
            data={data}
            columns={columns}
            options={options}
          />
          <Dialog fullWidth={true} open={dialogOpen} onClose={handleDialogClose.bind(this)} aria-labelledby="form-dialog-title">
            <DialogContent>
              <Card>
                <CardHeader color="primary" style={{ textAlign: 'left', paddingTop: '30px' }}>
                  <b>{dialogTitle}</b>
                </CardHeader>
                <CardBody>
                  <div style={{ color: 'red', textAlign: 'center' }}>{message}</div>
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={12}>
                      <TextField
                        required={true}
                        fullWidth={true}
                        label="API Name"
                        value={apiName}
                        onChange={onAPINameChange.bind(this)}
                        disabled={fieldDisabled}
                      />
                    </GridItem>
                  </GridContainer>
                  <br />
                  <GridContainer>
                    <GridItem xs={12} sm={12} md={6}>
                      <InputLabel id="statusLabel" shrink={true}>Status</InputLabel>
                      <Grid item={true} component="label" container alignItems="center" spacing={1}>
                        <Grid item>Inactive</Grid>
                        <Grid item>
                          <Switch
                            labelid="statusLabel"
                            checked={status}
                            onChange={onStatusChange.bind(this)}
                            color="primary"
                            inputProps={{ 'aria-label': 'primary checkbox' }}
                            disabled={fieldDisabled}
                            classes={{
                              switchBase: classes.switchBase,
                              checked: classes.switchChecked,
                              //icon: classes.switchIcon,
                              //iconChecked: classes.switchIconChecked,
                              //bar: classes.switchBar
                            }}
                          />
                        </Grid>
                        <Grid item>Active</Grid>
                      </Grid>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={6}>
                      <FormControl fullWidth={true} className={classes.formControl} disabled={fieldDisabled}>
                        <InputLabel id="typeLabel">Type</InputLabel>
                        <Select
                          labelid="typeLabel"
                          id="typeSelect"
                          value={type}
                          onChange={onTypeChange.bind(this)}
                        >
                          <MenuItem value={EMAIL_SMTP}>Email SMTP</MenuItem>
                          <MenuItem value={FILE_SHARING_AWS}>File Sharing AWS</MenuItem>
                        </Select>
                      </FormControl>
                    </GridItem>
                  </GridContainer>
                  <br />
                  <div style={{ display: showMethod ? 'block' : 'none' }}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          required={true}
                          fullWidth={true}
                          label={labelMethod}
                          value={method}
                          onChange={onMethodChange.bind(this)}
                          disabled={fieldDisabled}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                  </div>
                  <div style={{ display: showApiEndPoint1 ? 'block' : 'none' }}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          required={true}
                          fullWidth={true}
                          label={labelApiEndPoint1}
                          value={apiEndPoint1}
                          onChange={onAPIEndPoint1Change.bind(this)}
                          disabled={fieldDisabled}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                  </div>
                  <div style={{ display: showApiEndPoint2 ? 'block' : 'none' }}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          required={true}
                          fullWidth={true}
                          label={labelApiEndPoint2}
                          value={apiEndPoint2}
                          onChange={onAPIEndPoint2Change.bind(this)}
                          disabled={fieldDisabled}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                  </div>
                  <div style={{ display: showApiEndPoint3 ? 'block' : 'none' }}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          required={true}
                          fullWidth={true}
                          label={labelApiEndPoint3}
                          value={apiEndPoint3}
                          onChange={onAPIEndPoint3Change.bind(this)}
                          disabled={fieldDisabled}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                  </div>
                  <div style={{ display: showApiKey ? 'block' : 'none' }}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          required={true}
                          fullWidth={true}
                          label={labelApiKey}
                          value={apiKey}
                          onChange={onAPIKeyChange.bind(this)}
                          disabled={fieldDisabled}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                  </div>
                  <div style={{ display: showApiSecret ? 'block' : 'none' }}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          required={true}
                          fullWidth={true}
                          label={labelApiSecret}
                          value={apiSecret}
                          onChange={onAPISecretChange.bind(this)}
                          disabled={fieldDisabled}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                  </div>
                  <div style={{ display: showToken ? 'block' : 'none' }}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          required={true}
                          fullWidth={true}
                          label={labelToken}
                          value={token}
                          onChange={onTokenChange.bind(this)}
                          disabled={fieldDisabled}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                  </div>
                  <div style={{ display: showSetting1 ? 'block' : 'none' }}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          required={true}
                          fullWidth={true}
                          label={labelSetting1}
                          value={setting1}
                          onChange={onSetting1Change.bind(this)}
                          disabled={fieldDisabled}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                  </div>
                  <div style={{ display: showSetting2 ? 'block' : 'none' }}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          required={true}
                          fullWidth={true}
                          label={labelSetting2}
                          value={setting2}
                          onChange={onSetting2Change.bind(this)}
                          disabled={fieldDisabled}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                  </div>
                  <div style={{ display: showSetting3 ? 'block' : 'none' }}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          required={true}
                          fullWidth={true}
                          label={labelSetting3}
                          value={setting3}
                          onChange={onSetting3Change.bind(this)}
                          disabled={fieldDisabled}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                  </div>
                  <div style={{ display: showSetting4 ? 'block' : 'none' }}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          required={true}
                          fullWidth={true}
                          label={labelSetting4}
                          value={setting4}
                          onChange={onSetting4Change.bind(this)}
                          disabled={fieldDisabled}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                  </div>
                  <div style={{ display: showSetting5 ? 'block' : 'none' }}>
                    <GridContainer>
                      <GridItem xs={12} sm={12} md={12}>
                        <TextField
                          required={true}
                          fullWidth={true}
                          label={labelSetting5}
                          value={setting5}
                          onChange={onSetting5Change.bind(this)}
                          disabled={fieldDisabled}
                        />
                      </GridItem>
                    </GridContainer>
                    <br />
                  </div>

                  <GridContainer>
                  </GridContainer>
                </CardBody>
                <CardFooter style={{ textAlign: 'left' }}>
                </CardFooter>
              </Card>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleDialogClose.bind(this)} color="primary" round>
                Cancel
              </Button>
              <Button onClick={handleDialogConfirm.bind(this)} color="primary" round>
                {dialogButton}
              </Button>
            </DialogActions>
          </Dialog>
        </CardBody>
      </Card>
    </div>
  );
}
export default withStyles(extendedFormsStyle)(IntgExtAPIComp)
