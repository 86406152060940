import {
  GET_COMMNAME_REQUEST,
  GET_COMMNAME_SUCCESS,
  GET_COMMNAME_FAILURE,
  POST_COMMNAME_REQUEST,
  POST_COMMNAME_SUCCESS,
  POST_COMMNAME_FAILURE,
  PATCH_COMMNAME_REQUEST,
  PATCH_COMMNAME_SUCCESS,
  PATCH_COMMNAME_FAILURE,
  DELETE_COMMNAME_REQUEST,
  DELETE_COMMNAME_SUCCESS,
  DELETE_COMMNAME_FAILURE,
  GET_COMMNAME_TASK_REQUEST,
  GET_COMMNAME_TASK_SUCCESS,
  GET_COMMNAME_TASK_FAILURE,
  GET_COMMNAME_PHASE_REQUEST,
  GET_COMMNAME_PHASE_SUCCESS,
  GET_COMMNAME_PHASE_FAILURE,

} from '../constants/admin/CommName';

const initialState = {
  requesting: false,
  message: '',
  commName_list: '',
  commName_task_list: [],
  commName_phase_list: [],
  refreshCommName: false,
};

export default function commName(state = initialState, action) {
  switch (action.type) {
    case GET_COMMNAME_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshCommName: false,
        message: '',
      };

    case GET_COMMNAME_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        commName_list: action.payload.data,
      };

    case GET_COMMNAME_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case GET_COMMNAME_TASK_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
      };

    case GET_COMMNAME_TASK_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        commName_task_list: action.payload.data,
      };

    case GET_COMMNAME_TASK_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_COMMNAME_PHASE_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
      };

    case GET_COMMNAME_PHASE_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        commName_phase_list: action.payload.data,
      };

    case GET_COMMNAME_PHASE_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case POST_COMMNAME_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshCommName: false,
      };
    case POST_COMMNAME_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshCommName: true,
      };

    case POST_COMMNAME_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case PATCH_COMMNAME_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshCommName: false,
      };

    case PATCH_COMMNAME_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshCommName: true,
      };

    case PATCH_COMMNAME_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case DELETE_COMMNAME_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshCommName: false,
      };

    case DELETE_COMMNAME_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshCommName: true,
      };

    case DELETE_COMMNAME_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    default:
      return state;
  }
}
