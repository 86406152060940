import {
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_FAILURE,
  GET_USER_INTG_REQUEST,
  GET_USER_INTG_SUCCESS,
  GET_USER_INTG_FAILURE,
  POST_USER_REQUEST,
  POST_USER_SUCCESS,
  POST_USER_FAILURE,
  PATCH_USER_REQUEST,
  PATCH_USER_SUCCESS,
  PATCH_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  PATCH_USER_INTG_REQUEST,
  PATCH_USER_INTG_SUCCESS,
  PATCH_USER_INTG_FAILURE,
  GET_USER_CSV_REQUEST,
  GET_USER_CSV_SUCCESS,
  GET_USER_CSV_FAILURE,
  POST_NA_SET_PASSWORD_REQUEST,
  POST_NA_SET_PASSWORD_SUCCESS,
  POST_NA_SET_PASSWORD_FAILURE,
} from '../constants/networkadmin/User';


const initialState = {
  requesting: false,
  message: '',
  user_list: [],
  user_intg_list: [],
  refreshUser: false,
  refreshUserIntg: false,
  user_list_csv: []
};

export default function user(state = initialState, action) {
  switch (action.type) {
    case GET_USER_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        refreshUser: false,
        message: '',
        user_list: [],
      };

    case GET_USER_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        user_list: action.payload.data,
      };

    case GET_USER_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case GET_USER_INTG_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshUserIntg: false,
        user_intg_list: [],
      };

    case GET_USER_INTG_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        user_intg_list: action.payload.data,
      };

    case GET_USER_INTG_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case POST_USER_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshUser: false,
      };

    case POST_USER_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshUser: true,
      };

    case POST_USER_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case PATCH_USER_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshUser: false,
      };

    case PATCH_USER_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshUser: true,
      };

    case PATCH_USER_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    case DELETE_USER_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshUser: false,
      };

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshUser: true,
      };

    case DELETE_USER_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case PATCH_USER_INTG_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshUserIntg: false,
      };

    case PATCH_USER_INTG_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshUserIntg: true,
      };

    case PATCH_USER_INTG_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };
    case GET_USER_CSV_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
      };

    case GET_USER_CSV_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        user_list_csv: action.payload.data,
      };

    case GET_USER_CSV_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };


    case POST_NA_SET_PASSWORD_REQUEST:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: '',
        refreshUser: false,
      };

    case POST_NA_SET_PASSWORD_SUCCESS:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
        refreshUser: true,
      };

    case POST_NA_SET_PASSWORD_FAILURE:
      return {
        ...state,
        requesting: action.payload.requesting,
        message: action.payload.message,
      };

    default:
      return state;
  }




}
